import React, { useEffect, useState } from "react"
import { Col, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"

const CostVariables = ({ activeTab, scenario }) => {
  const currentUser = userService.getLoggedInUser()
  const [isLoading, setIsLoading] = useState(false)
  const [costPoolTypes, setCostPoolTypes] = useState([])
  const [costPools, setCostPools] = useState([])
  const [costVariables, setCostVariables] = useState([])
  const [selectedCostVariables, setSelectedCostVariables] = useState([])
  const [selectedCostPoolId, setSelectedCostPoolId] = useState(undefined)
  const [selectedCostPoolTypeId, setSelectedCostPoolTypeId] =
    useState(undefined)
  const [search, setSearch] = useState("")

  useEffect(() => {
    if (activeTab == "CostVariables" && scenario !== null) {
      loadData()
    }
  }, [activeTab, scenario])

  useEffect(() => {
    if (
      selectedCostPoolId !== undefined ||
      selectedCostPoolTypeId !== undefined
    ) {
      loadPotentialCostVariables()
    }
  }, [selectedCostPoolId, selectedCostPoolTypeId])

  useEffect(() => {
    if (selectedCostPoolTypeId !== undefined) {
      loadCostPools()
    }
  }, [selectedCostPoolTypeId])

  const loadData = async () => {
    try {
      setIsLoading(true)
      let types = await api.getList(currentUser.userID, "CostPoolTypes")
      setCostPoolTypes(types)
      await loadSelectedCostVariables()
      await loadCostPools()
      setIsLoading(false)
      await loadPotentialCostVariables()
    } catch (err) {
      setIsLoading(false)
    }
  }

  const loadCostPools = async () => {
    let pools = await api.getScenarioCostPools(
      scenario.scenarioID,
      selectedCostPoolTypeId ?? 0
    )
    setCostPools(pools)
  }

  const loadSelectedCostVariables = async () => {
    let data = await api.getCostVariables(scenario.scenarioID)
    setSelectedCostVariables(data)
  }

  const loadPotentialCostVariables = async () => {
    let data = await api.getPotentialCostVarialbes(
      scenario.scenarioID,
      selectedCostPoolTypeId ?? 0,
      selectedCostPoolId ?? 0
    )
    setCostVariables(data)
  }

  const addSelectedItems = async () => {
    let itemsToUpdate = costVariables.filter(
      x => x.isDirty === true && x.selected === true
    )
    if (itemsToUpdate.length > 0) {
      let itemsToUpdateTasks = itemsToUpdate.map(x => async () => {
        return await api.createCostVariable(
          scenario.scenarioID,
          x.bcReportItemID
        )
      })
      await Promise.all(itemsToUpdateTasks.map(t => t()))
      setCostVariables(
        costVariables.map(x => {
          x.isDirty = false
          return x
        })
      )
      loadSelectedCostVariables()
    }
  }

  const removeSelectedItems = async () => {
    let itemsToUpdate = selectedCostVariables.filter(
      x => x.isDirty === true && x.selected === true
    )
    if (itemsToUpdate.length > 0) {
      let itemsToUpdateTasks = itemsToUpdate.map(x => async () => {
        return await api.deleteCostVariables(x.costVariableID)
      })
      await Promise.all(itemsToUpdateTasks.map(t => t()))
      loadSelectedCostVariables()
      loadPotentialCostVariables()
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col md="4">
          <h4>Cost Items</h4>
          <div className="mb-3">
            <Label className="form-label">Segment</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={selectedCostPoolTypeId || 0}
              onChange={e => {
                setSelectedCostPoolTypeId(e.target.value)
              }}
            >
              <option value={-1}>Other Items</option>
              <option value={0}>All</option>
              {costPoolTypes.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemID}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Item</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={selectedCostPoolId || 0}
              onChange={e => {
                setSelectedCostPoolId(e.target.value)
              }}
            >
              <option value={-1}>Other Items</option>
              <option value={0}>All</option>
              {costPools.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.bcLineItemID}`}>
                    {a.costPoolName}
                  </option>
                )
              })}
            </select>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col md="4">
          <InputGroup>
            <InputGroupText>
              <i className="fas fa-search"></i>
            </InputGroupText>
            <Input
              placeholder="Search ..."
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </InputGroup>
          <table
            className="table table-bordered low-padding-table"
            style={{ backgroundColor: "white" }}
          >
            <thead>
              <th style={{ width: "5%" }}></th>
              <th style={{ width: "95%" }}>Item</th>
            </thead>
            <tbody>
              {costVariables
                .filter(
                  x =>
                    search == null ||
                    search == "" ||
                    x.bcReportItemName
                      .toLowerCase()
                      .indexOf(search.toLowerCase()) > -1
                )
                .map((c, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <Input
                          type="checkbox"
                          checked={c.selected}
                          onClick={() => {
                            let copy = [...costVariables]
                            let cost = copy.find(
                              x => x.bcReportItemID == c.bcReportItemID
                            )
                            cost.selected = !cost.selected
                            cost.isDirty = true
                            setCostVariables(copy)
                          }}
                        />
                      </td>
                      <td>{c.bcReportItemName}</td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </Col>
        <Col md="4">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "25%",
              marginRight: "25%",
            }}
          >
            <button className="btn btn-primary" onClick={addSelectedItems}>
              <i className="fas fa-plus"></i> Add Cost Variable(s)
            </button>
            <button
              className="btn btn-outline-danger"
              style={{ marginTop: "15px" }}
              onClick={removeSelectedItems}
            >
              <i className="fas fa-times"></i> Remove Cost Variable(s)
            </button>
          </div>
        </Col>
        <Col md="4">
          <h4>Cost Variables</h4>
          <table
            className="table table-bordered low-padding-table"
            style={{ backgroundColor: "white" }}
          >
            <thead>
              <th style={{ width: "5%" }}></th>
              <th style={{ width: "95%" }}>Item</th>
            </thead>
            <tbody>
              {selectedCostVariables.map((c, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <Input
                        type="checkbox"
                        checked={c.selected}
                        onClick={() => {
                          let copy = [...selectedCostVariables]
                          let cost = copy.find(
                            x => x.costVariableID == c.costVariableID
                          )
                          cost.selected = !cost.selected
                          cost.isDirty = true
                          setSelectedCostVariables(copy)
                        }}
                      />
                    </td>
                    <td>{c.costVariableName}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CostVariables
