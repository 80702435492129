import { LOADED_PAGE_ITEM, LOAD_PAGE_ITEM } from "./actionTypes"

export const loadPageItem = payload => {
  return {
    type: LOAD_PAGE_ITEM,
    payload: payload,
  }
}

export const loadPageItemSuccess = pageItem => {
  return {
    type: LOADED_PAGE_ITEM,
    payload: pageItem,
  }
}
