const displayDropdownList = [
  { text: "Group Ratings", value: "Verbal" },
  { text: "Mean Scores", value: "Scores" },
  { text: "Numeric Ratings", value: "Ratings" },
  { text: "My Ratings", value: "Verbal_My" },
  { text: "My Mean Scores", value: "Scores_My" },
  { text: "My Numeric Ratings", value: "Ratings_My" },
]

export default displayDropdownList
