import React, { useEffect, useState } from "react"
import download from "downloadjs"
import Loader from "react-loaders"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const RunTelerikUserReportModal = ({
  isOpen,
  toggleModal,
  telerikUserReportId,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [report, setReport] = useState(null)

  useEffect(() => {
    if (isOpen && telerikUserReportId !== undefined) {
      setReport(null)
      loadData()
    }
  }, [isOpen, telerikUserReportId])

  const loadData = async () => {
    let r = await api.getTelerikUserReport(telerikUserReportId)
    if (r != null) {
      r.name = r.name.replace(".trdp", "")
    }
    setReport(r)
  }

  const runReport = outputType => {
    setIsLoading(true)
    api.runTelerikUserReport(telerikUserReportId, outputType).then(blob => {
      
      download(blob, report.name + "." + (outputType == "pdf" ? "pdf" : "xls"))
      setIsLoading(false)
    })
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          User Report
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col>
            {report !== null && (
              <>
                <div>
                  <Label className="form-label">{report.name}</Label>
                  <p>{report.description}</p>
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
      <div
        className="modal-footer"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <button
          onClick={() => {
            toggleModal()
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        <div>
          <button className="btn btn-primary" onClick={() => runReport("pdf")}>
            Generate PDF
          </button>
          <button
            className="btn btn-primary save-user"
            onClick={() => runReport("excel")}
          >
            Generate XLS
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default RunTelerikUserReportModal
