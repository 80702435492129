import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { Input, Label, Modal } from "reactstrap"
import api from "services/api.service"
import colors from "utils/colors"
import { currencyFormatter } from "utils/formatters"
import anychart from "anychart"

const ParetoChartModal = ({ scenario, isOpen, toggleModal }) => {
  const [isLoading, setIsLoading] = useState([])
  const [chartData, setChartData] = useState([])

  useEffect(() => {
    if (isOpen && scenario !== null) {
      loadData()
    }
  }, [isOpen, scenario])

  const loadData = async () => {
    try {
      setIsLoading(true)
      let paretoChart = await api.getParetoChart(scenario.currentOptModelID)
      //   setChartData(chart)
      anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
      var chart = anychart.line() // set chart padding
      chart.padding([10, 20, 5, 20]) // turn on chart animation
      chart.animation(true) // turn on the crosshair
      chart.crosshair(true) // set chart title text settings //chart.title('Customers Activity during the Week');             // set y axis title //chart.yAxis().title('<%=HiddenScoreName.Value%>');             // set y axis title //chart.xAxis().title('<%=HiddenCostName.Value%>');
      chart.xAxis().title({
        text: "Cost",
        fontSize: 16,
        fontWeight: "bold",
        align: "center",
        padding: "10px",
      })
      chart.yAxis().title({
        text: "Score",
        fontSize: 16,
        fontWeight: "bold",
        align: "center",
      })
      var xLabels = chart.xAxis().labels() // xLabels.format("$"  + anychart.format.number(this.value, 2, ".", ","));
      xLabels.format(function () {
        return "$" + anychart.format.number(this.value, 2, ".", ",")
      }) // create logarithmic scale //var logScale = anychart.scales.log(); //logScale.minimum(1) //        .maximum(45000);             // set scale for the chart, this scale will be used in all scale dependent entries such axes, grids, etc //chart.yScale(logScale);             // create data set on our data,also we can pud data directly to series
      var dataSet = anychart.data.set(
        paretoChart.chartTable
          .sort((a, b) => a.constraintAmount - b.constraintAmount)
          .map(x => {
            return [x.constraintAmount, x.score, x.tooltip]
          })
      )
      var dataSetPri = anychart.data.set(
        paretoChart.chartTable
          .sort((a, b) => a.constraintAmount - b.constraintAmount)
          .map(x => {
            return [x.constraintAmount, x.priScore]
          })
      )
      var dataSetAdHoc = anychart.data.set(
        paretoChart.chartTable
          .sort((a, b) => a.constraintAmount - b.constraintAmount)
          .map(x => {
            return [x.constraintAmount, x.adHocScore]
          })
      ) // map data for the first series,take value from first column of data set
      var seriesData_1 = dataSet.mapAs({ x: 0, value: 1, tooltip: 2 })
      var seriesData_Pri = dataSetPri.mapAs({ x: 0, value: 1 })
      var seriesData_AdHoc = dataSetAdHoc.mapAs({ x: 0, value: 1 }) // temp variable to store series instance
      var series
      var priseries
      var adhocseries

      // setup first series
      series = chart.line(seriesData_1)
      series.name("Optimized Benefit vs. Cost") // enable series data labels
      series.labels().enabled(true).anchor("left-bottom").padding(5) // enable series markers
      series.markers(true)
      series.stroke("#406ab0")

      priseries = chart.line(seriesData_Pri)
      priseries.name("Prioritized Benefit vs. Cost") // enable series data labels
      priseries.labels().enabled(true).anchor("left-bottom").padding(5) // enable series markers
      priseries.markers(true)
      priseries.stroke("#359444")
      priseries.enabled(false)

      adhocseries = chart.line(seriesData_AdHoc)
      adhocseries.name("Unstructured Benefit vs. Cost") // enable series data labels
      adhocseries.labels().enabled(true).anchor("left-bottom").padding(5) // enable series markers
      adhocseries.markers(true)
      adhocseries.stroke("#FF6C2F")
      adhocseries.enabled(false)

      chart.tooltip().useHtml(true)
      var tooltip = chart.tooltip()
      tooltip.titleFormat("{%value}")
      tooltip.format("{%ToolTip}") // turn the legend on
      chart.legend().enabled(true).fontSize(13).padding([0, 0, 20, 0]) // set container for the chart and define padding
      chart.container("chart") // initiate chart drawing
      chart.credits().enabled(false)
      chart.draw()
      chart.refresh()

      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      fullscreen={true}
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Pareto Chart
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      {/* {!isLoading && ( */}
      <div className="modal-body">
        <div id="chart" style={{ height: "90%" }} />
      </div>
      {/* )} */}
      <div className="modal-footer">
        <div>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ParetoChartModal
