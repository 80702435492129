import { TextareaAutosize } from "@material-ui/core"
import {
  swalWithConfirmAndCancelButtons,
  swalWithConfirmButton,
} from "components/custom/swal"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

export const getYears = () => {
  let arr = []
  let endYear = new Date().getFullYear() + 1
  for (let i = 2010; i <= endYear; i++) {
    arr.push(i)
  }
  return arr
}

const EditEntityModal = ({
  isOpen,
  toggleModal,
  entityToEdit,
  setEntityToEdit,
  reloadData,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [isLoading, setIsLoading] = useState(false)
  const [entityFields, setEntityFields] = useState([])
  const [entityFieldsData, setEntityFieldsData] = useState([])
  const [selectedEntityFieldsData, setSelectedEntityFieldsData] = useState([])
  const [selectedYear, setSelectedYear] = useState(0)
  const [updatedEntityFieldsData, setUpdatedEntityFieldsData] = useState([])
  const years = getYears()

  useEffect(() => {
    if (isOpen) {
      setUpdatedEntityFieldsData([])
      setEntityFieldsData([])
      setEntityFields([])
      setSelectedEntityFieldsData([])
      setSelectedYear(0)
      setIsLoading(true)
      setTimeout(() => {
        loadData(0)
      }, 1000)
    }
  }, [isOpen])

  const loadData = async year => {
    let f = await api.getEntityFields(entityToEdit.entityTypeID)
    setEntityFields(f)
    await getAllFieldsData(year)

    setIsLoading(false)
  }

  const getAllFieldsData = async year => {
    let tasks = [0, ...years].map(x => async () => {
      return await api.getEntityFieldData(entityToEdit.entityID, x)
    })
    let data = await Promise.all(tasks.map(t => t()))
    let selected = data.find(
      x => x.find(d => d.dataYearID == year) !== undefined
    )
    setSelectedEntityFieldsData(selected)
    setEntityFieldsData(data)
  }

  const changeEntityFieldsData = (prop, value, dataYearID, entityFieldID) => {
    let copy = [...selectedEntityFieldsData]
    let item = copy.find(
      x => x.dataYearID == dataYearID && x.entityFieldID == entityFieldID
    )
    item[prop] = value
    item.isDirty = true
    let updatedItems = [...updatedEntityFieldsData]
    let updatedItem = updatedItems.find(x=>x.dataYearID == dataYearID && x.entityFieldID == entityFieldID)
    if(updatedItem !== undefined){
        updatedItem[prop] = value
    }else{
        updatedItems.push({...item})
    }
    setUpdatedEntityFieldsData(updatedItems)
    setSelectedEntityFieldsData(copy)
  }

  const save = async () => {
    if (entityToEdit.isDirty) {
      await api.updateEntity(currentUser.userID, entityToEdit)
    }

    if (updatedEntityFieldsData.length > 0) {
      let tasks = updatedEntityFieldsData.map(x => async () => {
        return await api.addUpdateEntityFieldData(currentUser.userID, x)
      })
      await Promise.all(tasks.map(t => t()))
    }
    setUpdatedEntityFieldsData([])

    reloadData(entityToEdit.entityTypeID)
    toggleModal()
  }

  const inactivate = () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete item?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.updateEntity(currentUser.userID, {
            ...entityToEdit,
            inactive: true,
          })
          reloadData()
          toggleModal()
        }
      })
  }

  const renderCell = (fieldsData, field, idx) => {
    if (field.dataType == "Integer") {
      return (
        <td className="text-right" key={idx}>
          {fieldsData.find(dd => dd.entityFieldID == field.entityFieldID).value ==
          null
            ? ""
            : parseFloat(
                fieldsData.find(dd => dd.entityFieldID == field.entityFieldID)
                  .value
              ).toLocaleString()}
        </td>
      )
    } else if (field.dataType == "Text" || field.dataType == "LongText") {
      return (
        <td key={idx}>
          {fieldsData.find(dd => dd.entityFieldID == field.entityFieldID)
            .value || ""}
        </td>
      )
    } else if (field.dataType == "YesNo") {
      return (
        <td key={idx}>
          {fieldsData.find(dd => dd.entityFieldID == field.entityFieldID)
            .value || "0" == "0"
            ? "No"
            : "Yes"}
        </td>
      )
    }
  }

  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {entityToEdit != null && entityToEdit.entityName} Data
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        {!isLoading && (
          <>
            <Row>
              <Col>
                {entityToEdit != null && (
                  <>
                    <div className="mb-3">
                      <Label>Institution Name</Label>
                      <Input
                        type="text"
                        onChange={e =>
                          setEntityToEdit({
                            ...entityToEdit,
                            entityName: e.target.value,
                            isDirty: true,
                          })
                        }
                        value={entityToEdit.entityName}
                      />
                    </div>
                    <div className="mb-3">
                      <Label>Abbreviation</Label>
                      <Input
                        type="text"
                        onChange={e =>
                          setEntityToEdit({
                            ...entityToEdit,
                            entityAbbreviation: e.target.value,
                            isDirty: true,
                          })
                        }
                        value={entityToEdit.entityAbbreviation}
                      />
                    </div>
                  </>
                )}
                <div className="mb-3">
                  <Label className="form-label">Years</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={selectedYear}
                    onChange={e => {
                      setSelectedYear(e.target.value)
                      setSelectedEntityFieldsData(
                        entityFieldsData.find(
                          x =>
                            x.find(d => d.dataYearID == e.target.value) !==
                            undefined
                        )
                      )
                    }}
                  >
                    <option value="0">General Data</option>
                    {years.map((x, idx) => (
                      <option key={idx} value={x}>
                        {x}
                      </option>
                    ))}
                  </select>
                </div>
                {selectedEntityFieldsData.map((d, idx) => (
                  <div key={idx} className="mb-3">
                    <Label>{d.entityFieldName}</Label>
                    {d.dataType == "Integer" && (
                      <Input
                        type="number"
                        onChange={e => {
                          changeEntityFieldsData(
                            "value",
                            e.target.value,
                            d.dataYearID,
                            d.entityFieldID
                          )
                        }}
                        value={d.value || ""}
                      />
                    )}
                    {d.dataType == "Text" && (
                      <Input
                        type="text"
                        onChange={e => {
                          changeEntityFieldsData(
                            "value",
                            e.target.value,
                            d.dataYearID,
                            d.entityFieldID
                          )
                        }}
                        value={d.value || ""}
                      />
                    )}
                    {d.dataType == "LongText" && (
                      <TextareaAutosize
                        minRows={2}
                        className="form-control"
                        onChange={e =>
                          changeEntityFieldsData(
                            "value",
                            e.target.value,
                            d.dataYearID,
                            d.entityFieldID
                          )
                        }
                        value={d.value || ""}
                      />
                    )}
                    {d.dataType == "YesNo" && (
                      <div>
                        <div>
                          <input
                            className="form-check-input"
                            name="anon1"
                            id="anon1"
                            type="radio"
                            value="0"
                            checked={d.value == "0"}
                            onClick={e =>
                              changeEntityFieldsData(
                                "value",
                                "0",
                                d.dataYearID,
                                d.entityFieldID
                              )
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="anon1"
                            style={{ marginLeft: "10px" }}
                          >
                            No
                          </label>
                        </div>
                        <div>
                          <input
                            className="form-check-input"
                            id="anon2"
                            name="anon2"
                            type="radio"
                            value="1"
                            checked={d.value == "1"}
                            onClick={e =>
                              changeEntityFieldsData(
                                "value",
                                "1",
                                d.dataYearID,
                                d.entityFieldID
                              )
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="anon2"
                            style={{ marginLeft: "10px" }}
                          >
                            Yes
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </Col>
            </Row>
            <Row>
              <Col>
                {entityToEdit != null && (
                  <table
                    className="table table-bordered low-padding-table"
                    style={{ backgroundColor: "white" }}
                  >
                    <thead>
                      <tr>
                        <th>Year</th>
                        {entityFields
                          .filter(x => x.annual)
                          .map((x, idx) => (
                            <th key={idx}>{x.entityFieldName}</th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {entityFieldsData
                        .filter(x => x[0].dataYearID !== 0)
                        .map((d, idx) => (
                          <tr key={idx}>
                            <td>{d[0].dataYearID}</td>
                            {entityFields
                              .filter(x => x.annual)
                              .map((x, idx) => renderCell(d, x, idx))}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </Col>
            </Row>
          </>
        )}
      </div>
      <div
        className="modal-footer"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <button
            onClick={() => {
              toggleModal()
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            className="btn btn-outline-danger save-user"
            onClick={inactivate}
          >
            Delete
          </button>
        </div>
        <button className="btn btn-primary" onClick={save}>
          Save
        </button>
      </div>
    </Modal>
  )
}

export default EditEntityModal
