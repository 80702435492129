import React, { useEffect } from "react"
import * as msTeams from "@microsoft/teams-js"

export const SignInStart = () => {
  // AuthHelper.StartSignIn();
  return <h1>Starting sign in...</h1>
}

const Configure = () => {
  useEffect(() => {
    // msTeams.app.initialize().then(() => {

    // })
    // getAccessToken()
  }, [])

  const getAccessToken = async () => {
    var promise = new Promise((resolve, reject) => {
      msTeams.authentication
        .authenticate({
          url: `${window.location.origin}/login/teams/startsignin`,
          width: 600,
          height: 535,
          isExternal: false,
        })
        .then(resolve)
        .catch(reject)
    })
    promise.then(
      r => {
        console.log(r)
      },
      err => {
        console.log(err)
      }
    )
  }

  return (
    <React.Fragment>
      <p>Logging in</p>
    </React.Fragment>
  )
}

export default Configure
