import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { logoutUser } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { useOktaAuth } from "@okta/okta-react"

const Logout = props => {
  const dispatch = useDispatch()
  const { oktaAuth, authState } = useOktaAuth()

  useEffect(() => {
    logout()
  }, [dispatch])

  const logout = async () => {
    dispatch(logoutUser(props.history))
    localStorage.removeItem("currentData")
    localStorage.removeItem("optobjfin")
    oktaAuth && (await oktaAuth.signOut())
  }

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)
