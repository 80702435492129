import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { useDispatch, useSelector } from "react-redux"
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { changeNavbarParams, loadPageItem } from "store/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import EditPreferenceWeightsModal from "./EditPreferenceWeightsModal"
import anychart from "anychart"
import colors from "utils/colors"
import Loader from "react-loaders"

const PwcResults = props => {
  const scenarioId = props.match.params.scenarioId
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  let [scenario, setScenario] = useState(null)
  let [objectives, setObjectives] = useState([])
  let [scenarioObjectivesDropdownList, setScenarioObjectivesDropdownList] =
    useState([])
  let [dropdownSelection, setDropdownSelection] = useState(null)
  let [measures, setMeasures] = useState([])
  let [showEditModal, setShowEditModal] = useState(false)
  let [ahpWeightsChecked, setAphWeightsChecked] = useState(true)
  let [preferenceWeightsChecked, setPreferenceWeightsChecked] = useState(false)
  let [inconsistencyRatioChecked, setInconsistencyRatioChecked] =
    useState(false)
  let [optionsSeries, setOptionsSeries] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "pwcResults",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        viewName: "pwcResult",
      })
    )
    setIsLoading(true)
    anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
  }, [])

  useEffect(() => {
    if (pageItem?.viewName !== undefined && pageItem?.viewName !== null) {
      loadData()
    }
  }, [pageItem])

  useEffect(() => {
    if (dropdownSelection != null) {
      loadDataByDropdownSelection()
    }
  }, [dropdownSelection, ahpWeightsChecked, preferenceWeightsChecked])

  const loadDataByDropdownSelection = async () => {
    if (
      document.getElementById("chartdiv") !== undefined &&
      document.getElementById("chartdiv") !== null
    ) {
      document.getElementById("chartdiv").innerHTML = ""
    }
    if (dropdownSelection.value == 0) {
      if (ahpWeightsChecked || preferenceWeightsChecked) {
        let objs = await loadObjectives()
        let achart = anychart.bar()
        let anyseries = null
        if (ahpWeightsChecked && !preferenceWeightsChecked) {
          anyseries = achart.bar(
            objs.map(o => [o.objectiveName, o.evPercent.toFixed(2)])
          )
        } else if (!ahpWeightsChecked && preferenceWeightsChecked) {
          anyseries = achart.bar(
            objs.map(o => [o.objectiveName, o.evFinal.toFixed(2)])
          )
        } else {
          //both checked
          anyseries = achart.bar(
            objs.map(o => [o.objectiveName, o.evPercent.toFixed(2)])
          )
          let series2 = achart.bar(
            objs.map(o => [o.objectiveName, o.evFinal.toFixed(2)])
          )
          series2.labels().format("{%y}%")
          series2.labels(true)
        }
        anyseries.labels().format("{%y}%")
        anyseries.labels(true)
        var tooltip = achart.tooltip()
        tooltip.format("{%value}%")
        achart.container("chartdiv")
        achart.credits().enabled(false)
        achart.yScale().minimum(0)

        achart.draw()
      }
    } else {
      loadMeasuresChart()
    }
  }

  const loadMeasuresChart = async () => {
    if (ahpWeightsChecked || preferenceWeightsChecked) {
      let measureList = await loadMeasures(dropdownSelection.value)
        let achart = anychart.bar()
        let anyseries = null
        if (ahpWeightsChecked && !preferenceWeightsChecked) {
          anyseries = achart.bar(
            measureList.map(o => [o.measureName, o.measurePercent.toFixed(2)])
          )
        } else if (!ahpWeightsChecked && preferenceWeightsChecked) {
          anyseries = achart.bar(
            measureList.map(o => [o.measureName, o.measureFinalPerc.toFixed(2)])
          )
        } else {
          //both checked
          anyseries = achart.bar(
            measureList.map(o => [o.measureName, o.measurePercent.toFixed(2)])
          )
          let series2 = achart.bar(
            measureList.map(o => [o.measureName, o.measureFinalPerc.toFixed(2)])
          )
          series2.labels().format("{%y}%")
          series2.labels(true)
        }
        anyseries.labels().format("{%y}%")
        anyseries.labels(true)
        var tooltip = achart.tooltip()
        tooltip.format("{%value}%")
        achart.container("chartdiv")
        achart.credits().enabled(false)
        achart.yScale().minimum(0)

        achart.draw()
    }
  }

  const sortObjectivesDesc = objs => {
    const prop = ahpWeightsChecked ? "evPercent" : "evFinal"
    function compare(a, b) {
      if (b[prop] < a[prop]) {
        return -1
      }
      if (b[prop] > a[prop]) {
        return 1
      }
      return 0
    }
    return objs.sort(compare)
  }

  const sortMeasuresDesc = mes => {
    const prop = ahpWeightsChecked ? "measurePercent" : "measureFinalPerc"
    function compare(a, b) {
      if (b[prop] < a[prop]) {
        return -1
      }
      if (b[prop] > a[prop]) {
        return 1
      }
      return 0
    }
    return mes.sort(compare)
  }

  const loadData = async () => {
    let scen = await loadScenario()
    let objs = await loadObjectives()
    let list = [
      {
        value: 0,
        text: scen.scenarioName,
      },
      ...objs
        .filter(x => x.measures && x.measures.length > 0)
        .map(o => {
          return {
            value: o.objectiveID,
            text: o.objectiveName,
          }
        }),
    ]

    setScenarioObjectivesDropdownList(list)
    setDropdownSelection({
      value: 0,
      text: scen.scenarioName,
    })
    setIsLoading(false)
  }

  const loadScenario = async () => {
    try {
      var scenario = await api.getScenario(scenarioId)
      setScenario(scenario)
      return scenario
    } catch (err) {
      console.log(err)
    }
  }

  const loadObjectives = async () => {
    try {
      var obj = await api.getObjectives(scenarioId)
      setObjectives(sortObjectivesDesc(obj))
      return sortObjectivesDesc(obj)
    } catch (err) {
      console.log(err)
    }
  }

  const loadMeasures = async objectiveId => {
    try {
      var meas = await api.getMeasures(objectiveId)
      setMeasures(sortMeasuresDesc(meas))
      return sortMeasuresDesc(meas)
    } catch (err) {
      console.log(err)
    }
  }

  const toggleEditModal = () => {
    setShowEditModal(!showEditModal)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Row>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <button className="btn btn-primary" onClick={toggleEditModal}>
                <i className="fas fa-edit"></i> Edit preference weights
              </button>
              <button
                className="btn btn-primary save-user"
                onClick={async () => {
                  await api.setPreferenceWeightsToAHP(
                    scenarioId,
                    dropdownSelection.value
                  )
                  await loadDataByDropdownSelection()
                }}
              >
                <i className="fas fa-save"></i> Set preference weights to AHP
                weights
              </button>
              {scenario != null && (
                <div className="form-check" style={{ marginLeft: "20px" }}>
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck5"
                    defaultChecked={scenario.makeCalcScoresFinal}
                    onChange={async e => {
                      setScenario({
                        ...scenario,
                        makeCalcScoresFinal: !scenario.makeCalcScoresFinal,
                      })
                      await api.updateScenario(currentUser.userID, {
                        ...scenario,
                        makeCalcScoresFinal: !scenario.makeCalcScoresFinal,
                      })
                    }}
                  />
                  <Label className="form-check-label" for="customCheck5">
                    Update preference weights when AHP weights change
                  </Label>
                </div>
              )}
            </div>
          </Row>
          {scenarioObjectivesDropdownList.length > 0 && (
            <Row style={{ marginTop: "20px" }}>
              <div style={{ width: "40%" }}>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={dropdownSelection.value}
                  onChange={e => {
                    setDropdownSelection(
                      scenarioObjectivesDropdownList.find(
                        x => x.value == e.target.value
                      )
                    )
                  }}
                >
                  {scenarioObjectivesDropdownList.map((r, idx) => {
                    return (
                      <option key={idx} value={`${r.value}`}>
                        {r.text}
                      </option>
                    )
                  })}
                </select>
              </div>
            </Row>
          )}
          <Row style={{ marginTop: "20px" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <p>
                <b>Show: </b>
              </p>
              <div className="form-check" style={{ marginLeft: "20px" }}>
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="customCheck1"
                  defaultChecked={ahpWeightsChecked}
                  onChange={e => {
                    setAphWeightsChecked(!ahpWeightsChecked)
                  }}
                />
                <Label className="form-check-label" for="customCheck1">
                  AHP Weights
                </Label>
              </div>
              <div className="form-check" style={{ marginLeft: "20px" }}>
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="customCheck2"
                  defaultChecked={preferenceWeightsChecked}
                  onChange={e => {
                    setPreferenceWeightsChecked(!preferenceWeightsChecked)
                  }}
                />
                <Label className="form-check-label" for="customCheck2">
                  Preference Weights
                </Label>
              </div>
              <div className="form-check" style={{ marginLeft: "20px" }}>
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="customCheck3"
                  defaultChecked={inconsistencyRatioChecked}
                  onChange={e => {
                    setInconsistencyRatioChecked(!inconsistencyRatioChecked)
                  }}
                />
                <Label className="form-check-label" for="customCheck3">
                  Inconsistency Ratio
                </Label>
              </div>
              {inconsistencyRatioChecked && (
                <h5 style={{ marginLeft: "20px" }}>
                  <b>
                    Inconsistency Ratio:{" "}
                    {Math.round(scenario.cr * 100 * 100) / 100}%; Lambda:{" "}
                    {scenario.eigenValue}
                  </b>
                </h5>
              )}
            </div>
          </Row>
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          <Row style={{ marginTop: "20px" }}>
            <div id="chartdiv" style={{ width: "100%", height: "600px" }}></div>
          </Row>
        </Container>
      </div>
      <EditPreferenceWeightsModal
        scenarioId={scenarioId}
        isOpen={showEditModal}
        toggleModal={toggleEditModal}
        reloadData={loadDataByDropdownSelection}
        scenario={scenario}
      />
    </React.Fragment>
  )
}

export default PwcResults
