import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { changeNavbarParams } from "store/actions"
import { loadPageItem } from "store/pageItem/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { enrichWithPhotoBase64, loadUsersAvatars } from "utils/avatar"
import ModelGroupsGrid from "./ModelGroupsGrid"
import ModelGroupsCards from "./ModelGroupsCards"
import useModal from "hooks/useModalHook"
import AddEditModelGroupModal from "./Modals/AddEditModelGroupModal"

const ModelGroups = props => {
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [scenarioGroups, setScenarioGroups] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showList, setShowList] = useState(true)

  const [allModels, setAllModels] = useState([])
  const [modelGroupToEdit, setModelGroupToEdit] = useState(null)
  const { show: showModelGroupModal, toggle: toggleModelGroupModal } =
    useModal()
  const newModelGroup = {
    scenarioGroupID: -1,
    scenarioGroupName: "",
    description: "",
    inactive: false,
    scenarioIds: [],
    userID: currentUser.userID,
  }

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: 0,
        alternativeId: 0,
        viewName: "scenarioGroups",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: 0,
        viewName: "scenarioGroups",
      })
    )
    loadData()
  }, [])

  const loadData = async () => {
    setIsLoading(true)

    let sg = await api.getScenarioGroups()
    sg = await enrichWithPhotoBase64(sg, "userID")
    setScenarioGroups(sg)

    let scenarios = await api.getScenariosByUser(currentUser.userID, 0)
    setAllModels(scenarios)

    setIsLoading(false)
  }

  const addScenarioGroup = () => {
    setModelGroupToEdit({ ...newModelGroup })
    toggleModelGroupModal()
  }

  const editScenarioGroup = (modelGroup) => {
    setModelGroupToEdit({ ...modelGroup })
    toggleModelGroupModal()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Row style={{ marginBottom: "10px" }}>
            <Col xs="12">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {currentUser.roleID >= 10 && (
                    <button
                      className="btn btn-primary"
                      onClick={addScenarioGroup}
                    >
                      <i className="fas fa-plus"></i> Add Scenario Group
                    </button>
                  )}
                </div>
                <div>
                  <i
                    className="mdi mdi-format-list-bulleted"
                    style={{
                      fontSize: "20px",
                      marginRight: "10px",
                      color: showList ? colors.primary : "initial",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowList(true)
                    }}
                  ></i>
                  <i
                    className="mdi mdi-card-bulleted-outline"
                    style={{
                      fontSize: "20px",
                      marginRight: "10px",
                      color: !showList ? colors.primary : "initial",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowList(false)
                    }}
                  ></i>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {showList && (
                <>
                  {isLoading && (
                    <Loader
                      type="line-scale-pulse-out"
                      color={colors.primary}
                      style={{ textAlign: "center" }}
                    />
                  )}
                  {!isLoading && (
                    <ModelGroupsGrid
                      modelGroups={scenarioGroups}
                      props={props}
                      reloadData={loadData}
                      editModelGroup={editScenarioGroup}
                      //   scenarioTypes={scenarioTypes}
                    />
                  )}
                </>
              )}
              {!showList && (
                <>
                  {isLoading && (
                    <Loader
                      type="line-scale-pulse-out"
                      color={colors.primary}
                      style={{ textAlign: "center" }}
                    />
                  )}
                  <ModelGroupsCards
                    modelGroups={scenarioGroups}
                    props={props}
                    editModelGroup={editScenarioGroup}
                    allModels={allModels}
                  />
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      {modelGroupToEdit != null && (
        <AddEditModelGroupModal
          isOpen={showModelGroupModal}
          toggleModal={toggleModelGroupModal}
          modelGroupToEdit={modelGroupToEdit}
          setModelGroupToEdit={setModelGroupToEdit}
          reload={loadData}
        />
      )}
    </React.Fragment>
  )
}

export default ModelGroups
