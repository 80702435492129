import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { changeNavbarParams, loadPageItem } from "store/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import EditCalculatedFieldModal from "./EditCalculatedFieldModal"

const CalculatedFields = props => {
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [isLoading, setIsLoading] = useState(false)
  const currentUser = userService.getLoggedInUser()
  const [fields, setFields] = useState([])
  const [calculatedFieldToEdit, setCalculatedFieldToEdit] = useState(null)
  const [showEditModal, setShowEditModal] = useState(false)

  const toggleEditModal = () => setShowEditModal(!showEditModal)

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: 0,
        alternativeId: 0,
        viewName: "CalculatedFields",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: 0,
        viewName: "CalculatedFields",
      })
    )
    loadData()
  }, [])

  const loadData = async () => {
    setIsLoading(true)
    await loadFields()
    setIsLoading(false)
  }

  const loadFields = async () => {
    let list = await api.getCalcFields(currentUser.userID)
    
    list = list.map(f => {
      f.calcFieldItems = f.calcFieldItems.map(c => {
        if (c.bcField && c.bcField.fieldName.indexOf("[YR]") > -1) {
          c.bcField.fieldName = c.bcField.fieldName.replace(
            "[YR]",
            c.itemYearID
          )
        }
        return c
      })

      return f
    })

    setFields(list)
  }

  const addCalcField = () => {
    setCalculatedFieldToEdit({
      calcFieldID: -1,
      calcFieldName: "",
      userID: currentUser.userID,
      calcFieldItems: [],
    })
    toggleEditModal()
  }

  const editCalcField = calcField => {
    setCalculatedFieldToEdit({
      ...calcField,
    })
    toggleEditModal()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Card>
            <CardBody>
              <Row className="mb-3">
                <Col>
                  <button className="btn btn-primary" onClick={addCalcField}>
                    <i className="fas fa-plus"></i> Add Calculated Field
                  </button>
                </Col>
              </Row>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              {!isLoading && (
                <table
                  className="table table-bordered low-padding-table"
                  style={{ backgroundColor: "white" }}
                >
                  <thead>
                    <tr>
                      <th>Calculated field</th>
                      <th>Additions</th>
                      <th>Subtractions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map((f, idx) => (
                      <tr key={idx}>
                        <td>
                          <a
                            href="#"
                            onClick={e => {
                              e && e.preventDefault()
                              editCalcField(f)
                            }}
                          >
                            {f.calcFieldName}
                          </a>
                        </td>
                        <td>
                          <div
                            dangerouslySetInnerHTML={{ __html: f.additions }}
                          ></div>
                        </td>
                        <td>
                          {" "}
                          <div
                            dangerouslySetInnerHTML={{ __html: f.subtractions }}
                          ></div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <EditCalculatedFieldModal
        calculatedFieldToEdit={calculatedFieldToEdit}
        setCalculatedFieldToEdit={setCalculatedFieldToEdit}
        isOpen={showEditModal}
        toggleModal={toggleEditModal}
        reloadData={loadData}
      />
    </React.Fragment>
  )
}

export default CalculatedFields
