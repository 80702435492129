import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { connect, useDispatch, useSelector } from "react-redux"
import { Row, Col, Alert } from "reactstrap"
import { Link } from "react-router-dom"

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import megamenuImg from "../../assets/images/megamenu-img.png"

// import images
import github from "../../assets/images/brands/github.png"
import bitbucket from "../../assets/images/brands/bitbucket.png"
import dribbble from "../../assets/images/brands/dribbble.png"
import dropbox from "../../assets/images/brands/dropbox.png"
import mail_chimp from "../../assets/images/brands/mail_chimp.png"
import slack from "../../assets/images/brands/slack.png"

import logo from "../../assets/images/logo.svg"
import logoLightSvg from "../../assets/images/logo-light.svg"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  loadPageItem,
} from "../../store/actions"
import api from "services/api.service"
import { userService } from "services/user.service"
import { useUserRight } from "services/role.service"

const Header = props => {
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  useUserRight(pageItem, props.otherProps)
  const [search, setsearch] = useState(false)
  const [megaMenu, setmegaMenu] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)

  const [helpVisible, setHelpVisible] = useState(true)

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  useEffect(() => {
    if (pageItem !== undefined && pageItem != null) {
      setTimeout(() => {
        if (pageItem.pageHelpStatus == "Show" && pageItem.pageHelp != "") {
          let height = window
            .getComputedStyle(document.getElementById("helpBanner"), null)
            .getPropertyValue("height")
            .replace("px", "")
          if (document.getElementsByClassName("page-content").length > 0) {
            document.getElementsByClassName("page-content")[0].style[
              "padding-top"
            ] = parseFloat(parseFloat(height) + 88) + "px"
          }
        } else {
          if (document.getElementsByClassName("page-content").length > 0) {
            document.getElementsByClassName("page-content")[0].style[
              "padding-top"
            ] = "88px"
          }
        }
      }, 300)
    }
  }, [pageItem])

  const showHelpBanner = async () => {
    setHelpVisible(true)
    await api.setHelpStatus(currentUser.userID, pageItem.viewName, "show")
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: pageItem.scenarioId,
        alternativeId: pageItem.alternativeId ?? 0,
        viewName: pageItem.viewName,
      })
    )
  }

  const onHelpDismiss = async () => {
    setHelpVisible(false)
    await api.setHelpStatus(currentUser.userID, pageItem.viewName, "hide")
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: pageItem.scenarioId,
        alternativeId: pageItem.alternativeId ?? 0,
        viewName: pageItem.viewName,
      })
    )
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            <h4
              className="mb-sm-0 font-size-18"
              style={{ alignSelf: "center" }}
            >
              <b>{pageItem != undefined ? pageItem.pageTitle : ""}</b>
            </h4>
          </div>
          <div className="d-flex">
            {pageItem.pageHelpStatus == "Hide" && pageItem.pageHelp != "" && (
              <div
                onClick={() => {
                  showHelpBanner()
                }}
                className="dropdown d-inline-block"
              >
                <button
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle "
                >
                  <i className="bx bx-help-circle" />
                </button>
              </div>
            )}
            
            <ProfileMenu />
          </div>
        </div>
        {pageItem.pageHelpStatus == "Show" && pageItem.pageHelp != "" && (
          <Alert
            id="helpBanner"
            variant="light"
            isOpen={helpVisible}
            toggle={onHelpDismiss}
            style={{ marginBottom: "0px" }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: pageItem.pageHelp,
              }}
            ></div>
          </Alert>
        )}
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
