import React from "react";
import { Col, Label } from "reactstrap";

export const DynamicFormLabel = React.memo(function dynamicFormLabel({
  label, col, fullWidth,
}) {
  return (
    <Col md={fullWidth ? "12" : col}>
      <div className="mb-3">
        <Label className="form-label">{label}</Label>
      </div>
    </Col>
  );
});
