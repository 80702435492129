import { TextareaAutosize, Tooltip } from "@material-ui/core"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import Constants from "utils/constants"
import OpenaiService, { openaiService } from "services/openai.service"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"

const ObjectivesChatGPTDrawer = ({
  isOpen,
  toggle,
  scope,
  itemId,
  field,
  addAsOptions,
  selectedAddAsOption,
  setSelectedAddAsOption,
  onAdd,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [prompt, setPrompt] = useState("")
  const [response, setResponse] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [savedPrompts, setSavedPrompts] = useState([])

  const [objectivesResponse, setObjectivesResponse] = useState([])

  useEffect(() => {
    if (isOpen) {
      setPrompt("")
      setResponse("")
      loadData()
    }
  }, [isOpen])

  const loadData = async e => {
    let r = await api.getAIPrompt(scope, itemId, field)
    setPrompt(r)
  }

  const openaiService = new OpenaiService()

  const processAI = async () => {
    try {
      setIsLoading(true)

      let finalPrompt =
        "In 2000 characters or less. " +
        prompt +
        '. Do not add numbers for each section. After each section, append "<separator>". Return the list of criteria in the following format: “Criteria Name” | “Criteria Description”'

      let resp = await openaiService.askAssistantAsync(finalPrompt)

      let objs = resp.split("<separator>")
      setObjectivesResponse(
        objs
          .map(r => {
            let parts = r.split("|")
            if (parts.length >= 2) {
              return {
                name: parts[0].replace("*", ""),
                description: parts[1].replace("*", ""),
              }
            }
            return null
          })
          .filter(x => x !== null)
      )

      setResponse(resp)
      setIsLoading(false)
    } catch {
      setIsLoading(false)
    }
  }

  return (
    <div
      style={{
        height: "100%",
        padding: "10px",
        boxShadow: "-5px 0 10px 0px lightgrey",
        borderLeft: "1px solid lightgray",
      }}
    >
      <Row>
        <Col>
          <div className="always-enabled">
            <button
              type="button "
              onClick={() => {
                toggle()
              }}
              className="close"
              aria-label="Close"
              style={{ color: "black", padding: "5px", marginRight: "10px" }}
            >
              <span style={{ color: "black" }} aria-hidden="true">
                &times;
              </span>
            </button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">Prompt</Label>
            <Input
              name="desc"
              type="textarea"
              rows={5}
              value={prompt}
              onChange={e => setPrompt(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <button
              type="button"
              className="btn btn-primary save-user"
              onClick={processAI}
            >
              Ask AI
            </button>
          </div>
        </Col>
      </Row>
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      

      {!isLoading && objectivesResponse.length > 0 && (
        <>
          <div className="mb-3">
            <Label className="form-label">Add As</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={selectedAddAsOption}
              onChange={e => {
                setSelectedAddAsOption(e.target.value)
              }}
            >
              {addAsOptions.map((x, idx) => (
                <option key={idx} value={x.objectiveId}>
                  {x.objectiveName}
                </option>
              ))}
            </select>
          </div>

          <table
            className="table table-bordered low-padding-table"
            style={{ backgroundColor: "white" }}
          >
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {objectivesResponse.map((s, idx) => (
                <tr key={idx}>
                  <td>
                    <button
                      onClick={() => onAdd(s.name, s.description)}
                      className="btn btn-sm btn-outline-primary"
                    >
                      Add
                    </button>
                  </td>
                  <td>
                    <div className="one-line-elipsis">
                      <Tooltip title={<h5>{s.name}</h5>}>
                        <span>{s.name}</span>
                      </Tooltip>
                    </div>
                  </td>
                  <td>
                    <div className="one-line-elipsis">
                      <Tooltip title={<h5>{s.description}</h5>}>
                        <span>{s.description}</span>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  )
}

export default ObjectivesChatGPTDrawer
