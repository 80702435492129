import PropTypes from "prop-types"
import Widgets from "components/Widgets"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { Col, Container, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { loadPageItem } from "store/actions"
import { changeNavbarParams } from "store/navbar/actions"
import colors from "utils/colors"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AddEditUserModal from "./AddEditUserModal"
import UsersGrid from "./UsersGrid"
import { useUserRight } from "services/role.service"
import FormDesignerModal from "components/custom/DynamicForm/Manage/FormDesignerModal"
import useModal from "hooks/useModalHook"
import { enrichWithPhotoBase64 } from "utils/avatar"

const Users = props => {
  //meta title
  document.title = "Definitive Pro"

  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  useUserRight(pageItem, props)
  const newUser = {
    userID: -1,
    email: "",
  }
  let [users, setUsers] = useState([])
  let [isLoading, setIsLoading] = useState(false)
  let [user, setUser] = useState(null)
  let [roles, setRoles] = useState([])
  let [enterprises, setEnterprises] = useState([])
  let [showEditModal, setShowEditModal] = useState(false)
  let [widgets, setWidgets] = useState([])

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: 0,
        alternativeId: 0,
        viewName: "users",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: 0,
        viewName: "users",
      })
    )
    loadUsers(true)
    loadRoles()
    loadEnterprises()
    loadWidgets()
  }, [])

  const addUser = () => {
    setUser(newUser)
    toggleEditModal()
  }

  const toggleEditModal = () => {
    if (showEditModal) {
      setUser(newUser)
    }
    setShowEditModal(!showEditModal)
  }

  const loadUsers = async toggleLoading => {
    try {
      if (toggleLoading) setIsLoading(true)
      let userList = await api.getUsersByUserId(currentUser.userID, true)
      userList = await enrichWithPhotoBase64(userList, "userID", true)
      setUsers(
        userList.map(x => {
          x.fullName = x.firstName + " " + x.lastName
          return x
        })
      )
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const loadWidgets = async () => {
    try {
      let w = await api.getWidgetsByView("Users", currentUser.userID)
      setWidgets(w)
    } catch (error) {
      console.log(error)
    }
  }

  const loadRoles = async () => {
    try {
      let roleList = await api.getList(currentUser.userID, "Role")
      setRoles(roleList)
    } catch (error) {
      console.log(error)
    }
  }

  const loadEnterprises = async () => {
    try {
      let list = await api.getEnterprises(currentUser.userID)
      setEnterprises(list)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : "Users"}
          />

          <Widgets widgets={widgets} marginTop="0px" />
          <Row style={{ marginBottom: "10px", marginTop: "10px" }}>
            <Col xs="12">
              <button className="btn btn-primary" onClick={addUser}>
                <i className="fas fa-plus"></i> Add User
              </button>
            </Col>
          </Row>
          <UsersGrid
            users={users}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setUser={setUser}
            toggleEditModal={toggleEditModal}
            reloadData={() => {
              loadUsers()
              loadWidgets()
            }}
          />

          <AddEditUserModal
            user={user}
            setUser={setUser}
            isOpen={showEditModal}
            toggleModal={toggleEditModal}
            roles={roles}
            enterprises={enterprises}
            reloadGrid={loadUsers}
            props={props}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Users
