import React from "react";
import { Col, Label } from "reactstrap";
import CurrencyInput from "react-currency-input-field";

export const DynamicFormCurrency = React.memo(function dynamicFormCurrency({
  formFieldId, label, value, placeholder, changeFormFieldValue, prefix, suffix, col, fullWidth, alwaysEditable, alwaysReadOnly, hasError, requiredMessage,
}) {
  return (
    <Col md={fullWidth ? "12" : col}>
      <div className="mb-3">
        <Label className="form-label">{label}</Label>
        <CurrencyInput
          className="form-control"
          value={value}
          onValueChange={(value, name, values) => {
            changeFormFieldValue(formFieldId, value);
          }}
          placeholder={placeholder || ""}
          suffix={suffix || ""}
          prefix={prefix || ""}
          decimalScale={2}
          decimalsLimit={2}
          disabled={alwaysReadOnly} />
        {hasError && (
          <div className="invalid-feedback" style={{ display: "block" }}>
            {requiredMessage}
          </div>
        )}
      </div>
    </Col>
  );
});
