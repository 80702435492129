import { useEffect, useState } from "react"

const useFetcher = (apiCall, defaultValue = []) => {
  const [result, setResult] = useState(defaultValue)
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    const executeCall = async () => {
      setIsLoading(true)
      let result = await apiCall()
      setResult(result)
      setIsLoading(false)
    }

    if (apiCall != undefined) {
      executeCall()
    }
  }, [])

  return [result, isLoading]
}

export default useFetcher
