import React, { useEffect, useRef, useState } from "react"
import Loader from "react-loaders"
import { ReactTabulator } from "react-tabulator"
import colors from "utils/colors"
import * as ReactDOMClient from "react-dom/client"
import api from "services/api.service"
import { userService } from "services/user.service"
import { Card, CardBody } from "reactstrap"
import { customFormatter } from "utils/tabulator"

const EnterprisesGrid = ({ userEnterprise, enterprises, props }) => {
  var tabulatorRef = useRef(null)

  const handleEnterprisePress = (e, enterprise) => {
    e && e.preventDefault()
    props.history.push(`/editEnterprise/${enterprise.enterpriseID}`)
  }

  const columns = [
    {
      title: "Enterprise",
      field: "enterpriseName",
      headerSort: true,
      widthGrow: 5,
      formatter: customFormatter(({ rowData, cellValue }) => (
        <a href="#" onClick={e => handleEnterprisePress(e, rowData)}>
          {cellValue}
        </a>
      )),
    },
    {
      title: "# of Users",
      field: "numActiveUsers",
      widthGrow: 1,
    },
    {
      title: `# of ${userEnterprise != null && userEnterprise.modelLabel}s`,
      field: "numScenarios",
      widthGrow: 1,
    },
  ]
  return (
    <Card>
      <CardBody>
        {enterprises.length > 0 && (
          <>
            {/* <button
              onClick={toggleFilters}
              className="btn btn-outline-primary"
              style={{ float: "right" }}
            >
              {showFilters ? "Hide" : "Show"} <i className="fas fa-search"></i>{" "}
              <i className="fas fa-filter"></i>
            </button> */}
            <ReactTabulator
              onRef={r => (tabulatorRef.current = r.current)}
              columns={columns}
              data={enterprises}
              options={{
                pagination: "local",
                paginationSize: 30,
                paginationSizeSelector: [5, 10, 20, 30, 50, 100, 200, 300],
                layout: "fitColumns",
                cssClass: "table-borderless",
              }}
            />
          </>
        )}
      </CardBody>
    </Card>
  )
}

export default EnterprisesGrid
