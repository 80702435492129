import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Modal } from "reactstrap"
import api from "services/api.service"
import colors from "utils/colors"
import anychart from "anychart"

const BarChartModal = ({
  isOpen,
  toggleModal,
  scenarioId,
  objectiveId,
  alternativeList,
  optObjFin,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isOpen) {
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    setIsLoading(true)
    let chartResponse = await api.getAlternativesBarChart(
      scenarioId,
      objectiveId,
      alternativeList.join(","),
      optObjFin != null && optObjFin.obj != null ? optObjFin.obj.objModelID : 0
    )
    let dataArray1 = JSON.parse(
      "[" + chartResponse.chartArray.replaceAll("'", '"') + "]"
    )
    let dataArray2 = JSON.parse(
      "[" + chartResponse.chartArray2.replaceAll("'", '"') + "]"
    )
    var dataSet = anychart.data.set(dataArray1)
    var numrows = dataSet.getRowsCount()
    var bht = numrows * 40
    if (bht < 250) {
      bht = 250
    }
    var bd = document.getElementById("chartdiv")
    bd.style.height = String(bht) + "px"

    var chart = anychart.bar()
    var colors = [
      "#5b9bd5",
      "#ed7d31",
      "#a5a5a5",
      "#ffc009",
      "#4472c4",
      "#75ad47",
      "#264478",
      "#9e480e",
      "#636363",
      "#997300",
    ]
    var objectives = dataArray2
    var numseries = parseInt(chartResponse.chartData, 10)
    var j = 0
    for (var i = 0; i < numseries; i++) {
      j = i + 1
      chart.addSeries(
        dataSet.mapAs({
          x: 0,
          value: j,
          total: numseries + 1,
        })
      )
      chart.getSeriesAt(i).name(objectives[i])
      chart.getSeriesAt(i).color(colors[i])
      chart.getSeriesAt(i).stroke("3 #fff 1")
      if (j == numseries) {
        chart.getSeriesAt(i).labels(true)
        chart.getSeriesAt(i).labels().format("{%total}%")
        chart.getSeriesAt(i).labels().enabled(true)
      }
    }

    chart.animation(true)
    chart.padding([10, 20, 5, 20])
    chart.yScale().stackMode("value")
    //chart.yAxis(0).title().fontWeight("bold")
    chart.yAxis(0).title().fontSize(12)
    var ctitle = chart.title()
    ctitle.fontSize(12)
    ctitle.fontWeight("bold")
    chart.interactivity().hoverMode("by-x")
    chart.yAxis().overlapMode("allow-overlap")
    chart.xAxis().overlapMode("allow-overlap")
    chart.xAxis().staggerMode(false)
    var xLabels = chart.xAxis().labels()
    xLabels.fontSize(12)
    //xLabels.fontWeight("bold")
    chart.labels().fontSize(12)
    chart.labels().fontFamily("Inter")
    //chart.labels().fontWeight("bold")
    var yLabels = chart.yAxis().labels()
    yLabels.fontSize(12)
    //yLabels.fontWeight("bold")
    yLabels.format("{%value}%")
    chart.xScale().ticks().interval(1)
    chart.tooltip().displayMode("union")
    chart
      .legend()
      .enabled(true)
      .inverted(false)
      .fontSize(12)
      .fontFamily("Inter")
      //.fontWeight("bold")
      .padding([0, 0, 20, 0])
    chart.container("chartdiv")
    chart.credits().enabled(false)

    yLabels.fontFamily("Inter")
    xLabels.fontFamily("Inter")
    chart.title().fontFamily("Inter")
    chart.legend().fontFamily("Inter")

    chart.draw()

    setIsLoading(false)
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      fullscreen={true}
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Bar Chart
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        <div
          id="chartdiv"
          style={{ width: "100%", minHeight: "600px", height: "100%" }}
        ></div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
      </div>
    </Modal>
  )
}

export default BarChartModal
