import NameAvatar from "components/custom/nameAvatar"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import UploadModal from "components/GlobalModals.js/UploadModal"
import useModal from "hooks/useModalHook"
import React, { useEffect, useRef, useState } from "react"
import { createRoot } from "react-dom/client"
import { userService } from "services/user.service"
import Loader from "react-loaders"
import { ReactTabulator, reactFormatter } from "react-tabulator"
import { currencyFormatter } from "utils/formatters"
import { toast } from "react-toastify"
import moment from "moment"
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap"
import api from "services/api.service"
import colors from "utils/colors"
import { extractInnerTextFromHtml } from "utils/formatters"
import {
  getSelectHtmlFilterValues,
  getSelectedVisibleRows,
} from "utils/tabulatorFilter"
import { customFormatter } from "utils/tabulator"

const ContractGrid = ({
  contracts,
  isLoading,
  enterprise,
  setContract,
  toggleEditModal,
  reloadData,
}) => {
  const currentUser = userService.getLoggedInUser()
  var tabulatorRef = useRef(null)
  let [showFilters, setShowFilters] = useState(true)
  const [showActionsDropdown, setShowActionsDropdown] = useState(false)
  const { show: showUploadUsersModal, toggle: toggleUploadUsersModal } =
    useModal()
  const [columns, setColumns] = useState([])
  const [selectedContracts, setSelectedContracts] = useState([])

  useEffect(() => {
    if (
      contracts != undefined &&
      contracts != null &&
      contracts.length > 0 &&
      enterprise != null
    ) {
      setColumns(loadColumns())
      setSelectedContracts([])
    }
  }, [contracts, enterprise])

  const handleNamePress = (e, contract) => {
    e && e.preventDefault()
    setContract(contract)
    toggleEditModal()
  }

  const toggleFilters = () => {
    tabulatorRef.current.element.classList.remove("show-filters")
    tabulatorRef.current.element.classList.remove("hide-filters")
    tabulatorRef.current.element.classList.add(
      !showFilters ? "show-filters" : "hide-filters"
    )
    setShowFilters(!showFilters)
  }

  const loadColumns = () => {
    return [
      {
        formatter: "rowSelection",
        titleFormatter: "rowSelection",
        hozAlign: "center",
        headerSort: false,
        cellClick: function (e, cell) {
          cell.getRow().toggleSelect()
        },
        widthGrow: 1,
        width: "5%",
      },
      {
        title: enterprise.contractLabel + " #",
        field: "contractNumber",
        headerFilter: "input",
        width: "10%",
        widthGrow: 2,
        formatter: customFormatter(({ rowData, cellValue }) => (
          <a href="#" onClick={e => handleNamePress(e, rowData)}>
            {cellValue}
          </a>
        ))
      },
      {
        title: enterprise.contractLabel + " Name",
        field: "contractName",
        headerFilter: "input",
        widthGrow: 3,
      },
      {
        title: "Year",
        field: "contractYearID",
        headerFilter: "select",
        headerFilterFunc: "in",
        headerFilterParams: {
          values: true,
          sortValuesList: "asc",
          multiselect: true,
        },
        widthGrow: 2,
        width: "10%",
      },
      {
        title: "Fund",
        field: "fundName",
        // headerFilter: "input",
        headerFilter: "select",
        headerFilterFunc: "in",
        headerFilterParams: {
          // values: [...new Set(users.map(u => u.role))],
          values: getSelectHtmlFilterValues(
            new Set(contracts.map(u => u.fundName))
          ),
          sortValuesList: "asc",
          multiselect: true,
        },
        widthGrow: 2,
        width: "15%",
        formatter: (cell, formatterParams, onRendered) => {
          return cell.getValue()
        },
      },
      {
        title: "Appropriation Amount",
        field: "contractAmount",
        headerFilter: "input",
        widthGrow: 2,
        width: "15%",
        formatter: (cell, formatterParams, onRendered) => {
          return currencyFormatter.format(cell.getValue())
        },
      },
      {
        title: "Allocation Amount",
        field: "contractAllocation",
        headerFilter: "input",
        widthGrow: 2,
        width: "15%",
        formatter: (cell, formatterParams, onRendered) => {
          return currencyFormatter.format(cell.getValue())
        },
      },
      {
        title: "Reversion Date",
        field: "reversionDate",
        headerFilter: "input",
        widthGrow: 2,
        width: "15%",
        formatter: (cell, formatterParams, onRendered) => {
          return moment(cell.getValue()).isValid()
            ? moment(cell.getValue()).format("MM/DD/YYYY")
            : ""
        },
      },
    ]
  }

  const deleteSelected = () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete the selected contracts?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          let selected = [...selectedContracts]
          if (selected.length > 0) {
            let copy = [...selected]
            let tasks = copy.map(x => async () => {
              return await api.deleteContract(currentUser.userID, x.contractID)
            })
            await Promise.all(tasks.map(t => t()))
            reloadData()
            toast.success("Contracts deleted successfuly")
          }
        }
      })
  }

  return (
    <React.Fragment>
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      {!isLoading && contracts.length > 0 && (
        <>
          <Row>
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
              }}
            >
              <button
                onClick={toggleFilters}
                className="btn btn-sm btn-outline-primary"
                style={{ float: "right" }}
              >
                {showFilters ? "Hide" : "Show"}{" "}
                <i className="fas fa-search"></i>{" "}
                <i className="fas fa-filter"></i>
              </button>
              <Dropdown
                className="save-user"
                isOpen={showActionsDropdown}
                toggle={() => setShowActionsDropdown(!showActionsDropdown)}
              >
                <DropdownToggle
                  tag="button"
                  className="btn btn-sm btn-outline-primary"
                >
                  Actions <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => deleteSelected()}>
                    <i className="fas fa-trash-alt"></i> Delete Contracts
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
          <div className={showFilters ? "show-filters" : "hide-filters"}>
            {columns.length > 0 && (
              <ReactTabulator
                onRef={r => (tabulatorRef.current = r.current)}
                columns={columns}
                data={contracts}
                options={{
                  pagination: "local",
                  paginationSize: 30,
                  paginationSizeSelector: [5, 10, 20, 30, 50, 100, 200, 300],
                  layout: "fitColumns",
                  cssClass: "table-borderless",
                }}
                events={{
                  rowSelectionChanged: function (data, rows) {
                    let selectedVisibleData =
                      getSelectedVisibleRows(tabulatorRef)

                    setSelectedContracts(selectedVisibleData)
                  },
                }}
              />
            )}
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default ContractGrid
