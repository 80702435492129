import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import SelectAlternativeModal from "pages/AdminAlternativeScore/SelectAlternativeModal"
import { getAlternativeScoresChartOptionsSeries } from "pages/_AlternativeShared/alternativeChartFunctions"
import AlternativeRatingTable from "pages/_AlternativeShared/AlternativeRatingTable"
import React, { useEffect, useRef, useState } from "react"
import ReactApexChart from "react-apexcharts"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import {
  Container,
  Row,
  Label,
  Input,
  DropdownToggle,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { loadPageItem } from "store/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { toast } from "react-toastify"
import { loadUsersAvatars } from "utils/avatar"

const AdminAlternativeFacilitate = props => {
  const scenarioId = props.match.params.scenarioId
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  let [nextAltScore, setNextAltScore] = useState(null)
  let [userScores, setUserScores] = useState([])
  let [scenarioRatingScale, setScenarioRatingScale] = useState([])
  let [isLoading, setIsLoading] = useState(false)
  let [scenario, setScenario] = useState(null)
  let [showAltModal, setShowAltModal] = useState(false)
  const [usersPhotos, setUsersPhotos] = useState([])

  let [showActionsDropdown, setShowActionsDropdown] = useState(false)
  let [awaitingParticipants, setAwaitingParticipants] = useState(false)
  let [showGroupRating, setShowGroupRating] = useState(false)
  let [showMeanScore, setShowMeanScore] = useState(false)
  let [showStandardDev, setShowStandardDev] = useState(false)
  let [showParticipants, setShowParticipants] = useState(false)
  let [showChart, setShowChart] = useState(false)
  let [showGrid, setShowGrid] = useState(false)
  let [showBenefitScore, setShowBenefitScore] = useState(false)
  let [chartData, setChartData] = useState(null)
  let [currentAltScoreId, setCurrentAltScoreId] = useState(-1)
  let [currentLevelId, setCurrentLevelId] = useState(-1)
  let [isPooling, setIsPooling] = useState(false)
  let [locked, setLocked] = useState(false)

  const poolingInterval = useRef(null)

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "adminAlternativeFacilitate",
      })
    )
    loadNextData(0, 1, "alt", true)
    loadScenario()
    loadScenarioRatingScale()

    return () => {
      if (poolingInterval !== null) clearInterval(poolingInterval.current)
    }
  }, [])

  useEffect(() => {
    clearInterval(poolingInterval.current)
    if (isPooling) {
      poolingInterval.current = setInterval(async () => {
        await loadCurrentData()
      }, 4000)
    }
  }, [isPooling])

  useEffect(() => {
    if (
      scenario != null &&
      userScores != null &&
      scenarioRatingScale != null &&
      scenarioRatingScale.length > 0
    ) {
      setChartData(
        getAlternativeScoresChartOptionsSeries(userScores, scenarioRatingScale)
      )
    }
  }, [scenario, userScores, scenarioRatingScale])

  const loadNextData = async (
    altScoreId,
    levelId,
    altOrObj,
    loadUsersPhotos = false
  ) => {
    clearInterval(poolingInterval.current)
    setIsPooling(false)
    handleCheckboxSelection()
    let altScore = await loadNextAltScore(altScoreId, levelId, altOrObj)
    await api.setCurrentGroupAltScoreId(
      scenarioId,
      altScore.altScoreID,
      altScore.levelID
    )

    setNextAltScore(altScore)
    setCurrentAltScoreId(altScore.altScoreID)
    setCurrentLevelId(altScore.levelID)

    let scores = await loadUserScores(altScore.altScoreID, altScore.levelID)
    if (loadUsersPhotos) {
      let avatars = await loadUsersAvatars(scores.map(x => x.userID))
      setUsersPhotos(avatars)
    }
    setUserScores(scores)
    setIsPooling(true)
  }

  const loadPrevData = async (altScoreId, levelId, altOrObj) => {
    clearInterval(poolingInterval.current)
    setIsPooling(false)
    handleCheckboxSelection()
    let altScore = await loadPrevAltScore(altScoreId, levelId, altOrObj)
    await api.setCurrentGroupAltScoreId(
      scenarioId,
      altScore.altScoreID,
      altScore.levelID
    )
    setNextAltScore(altScore)
    setCurrentAltScoreId(altScore.altScoreID)
    setCurrentLevelId(altScore.levelID)

    let scores = await loadUserScores(altScore.altScoreID, altScore.levelID)
    setUserScores(scores)
    setIsPooling(true)
  }

  const handleCheckboxSelection = () => {
    if (!locked) {
      setAwaitingParticipants(false)
      setShowGroupRating(false)
      setShowMeanScore(false)
      setShowStandardDev(false)
      setShowParticipants(false)
      setShowChart(false)
      setShowGrid(false)
      setShowBenefitScore(false)
    }
  }

  const loadCurrentData = async () => {
    if (currentLevelId > -1 && currentAltScoreId > -1) {
      setIsLoading(true)
      let altScore = await api.getAltScore(currentAltScoreId, currentLevelId)
      setNextAltScore(
        nextAltScore ? { ...nextAltScore, ...altScore } : altScore
      )

      let scores = await loadUserScores(currentAltScoreId, currentLevelId)
      setUserScores(scores)
      setIsLoading(false)
    }
  }

  const loadAlternativeFromModal = async alternativeId => {
    try {
      setIsLoading(true)
      clearInterval(poolingInterval.current)
      setIsPooling(false)

      let altScore = await api.getAltScoreByAltId(
        scenarioId,
        alternativeId,
        nextAltScore.objMeasID,
        nextAltScore.levelID
      )
      setNextAltScore(altScore)
      setCurrentAltScoreId(altScore.altScoreID)
      setCurrentLevelId(altScore.levelID)

      let userScores = await loadUserScores(
        altScore.altScoreID,
        altScore.levelID
      )
      setUserScores(userScores)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  const loadNextAltScore = async (altScoreId, levelId, altOrObj) => {
    try {
      setIsLoading(true)
      let altScore = await api.getNextAltScore(
        altScoreId,
        levelId,
        scenarioId,
        altOrObj
      )
      setIsLoading(false)

      return altScore
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }

  const loadPrevAltScore = async (altScoreId, levelId, altOrObj) => {
    try {
      setIsLoading(true)
      let altScore = await api.getPrevAltScore(
        altScoreId,
        levelId,
        scenarioId,
        altOrObj
      )
      setIsLoading(false)
      return altScore
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }

  const loadUserScores = async (altScoreId, levelId) => {
    try {
      let scores = await api.getAltUserScores(altScoreId, levelId)
      return scores
    } catch (err) {
      console.log(err)
    }
  }

  const loadScenario = async () => {
    try {
      var scenario = await api.getScenario(scenarioId)
      setScenario(scenario)
    } catch (err) {
      console.log(err)
    }
  }

  const loadScenarioRatingScale = async () => {
    try {
      var scenarioRatings = await api.getScenarioInputRatings(scenarioId)
      setScenarioRatingScale(
        scenarioRatings.sort(
          (a, b) => parseFloat(a.inputValue) - parseFloat(b.inputValue)
        )
      )
    } catch (err) {
      console.log(err)
    }
  }

  const resetAllScores = async () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to reset all judgments?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          setIsLoading(true)
          await api.resetAlternativeScores(scenarioId, currentUser.userID)
          await loadCurrentData()
          toast.success("Scores reset successfully")
          setIsLoading(false)
        }
      })
  }

  const resetCurrentAlternativeScores = async () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to reset jusgments for this ${scenario.alternativeName}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          setIsLoading(true)
          await api.resetAlternativeScoreSingle(
            scenarioId,
            currentUser.userID,
            currentAltScoreId,
            currentLevelId
          )
          await loadCurrentData()
          toast.success("Score reset successfully")
          setIsLoading(false)
        }
      })
  }

  const toggleAltModal = () => {
    setShowAltModal(!showAltModal)
  }

  const updateScenarioAlternativeStatus = async statusId => {
    await api.updateScenario(currentUser.userID, {
      ...scenario,
      alternativeStatusID: statusId,
    })
    toast.success("Status set successfully")
    await loadScenario()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <div className="card" style={{ padding: "10px" }}>
            <Row>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <button
                    type="button"
                    className={
                      isPooling
                        ? "btn btn-outline-primary"
                        : "btn btn-outline-danger"
                    }
                    onClick={() => setIsPooling(!isPooling)}
                  >
                    {isPooling ? "Polling" : "Not Polling"}
                  </button>
                  <button
                    className="btn btn-primary save-user"
                    onClick={loadCurrentData}
                  >
                    <i className="fas fa-sync"></i> Refresh
                  </button>
                </div>
                <div>
                  <Dropdown
                    isOpen={showActionsDropdown}
                    toggle={() => setShowActionsDropdown(!showActionsDropdown)}
                  >
                    <DropdownToggle
                      tag="button"
                      className="btn btn-outline-danger"
                    >
                      Actions <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={resetAllScores}>
                        <i className="fas fa-redo"></i> Reset Judgments for all{" "}
                        {scenario && scenario.alternativeName}
                      </DropdownItem>
                      <DropdownItem onClick={resetCurrentAlternativeScores}>
                        <i className="fas fa-redo"></i> Reset Judgments
                      </DropdownItem>
                      {scenario != null && scenario.alternativeStatusID == 1 && (
                        <DropdownItem
                          onClick={() => updateScenarioAlternativeStatus(110)}
                          style={{ color: "red" }}
                        >
                          <i className="fas fa-times"></i> Close Scoring
                        </DropdownItem>
                      )}
                      {scenario != null && scenario.alternativeStatusID == 110 && (
                        <DropdownItem
                          onClick={() => updateScenarioAlternativeStatus(1)}
                          style={{ color: "green" }}
                        >
                          <i className="fas fa-star"></i> Open Scoring
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </Dropdown>
                  {/* <button
                  className="btn btn-outline-danger save-user"
                  onClick={resetAllScores}
                >
                  <i className="fas fa-redo"></i> Reset Judgments for all
                  Projects
                </button>
                <button
                  className="btn btn-outline-danger save-user"
                  onClick={resetCurrentAlternativeScores}
                >
                  <i className="fas fa-redo"></i> Reset Judgments
                </button> */}
                </div>
              </div>
            </Row>
            {awaitingParticipants && (
              <Row style={{ marginTop: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color:
                        userScores.filter(x => x.userScored).length ==
                        userScores.length
                          ? "green"
                          : "red",
                    }}
                  >
                    Participants submitted:{" "}
                    {userScores.filter(x => x.userScored).length} of{" "}
                    {userScores.length}
                  </span>
                  <span style={{ marginLeft: "10px", fontStyle: "italic" }}>
                    {userScores.filter(x => !x.userScored).length > 0
                      ? "Awaiting: " +
                        userScores
                          .filter(x => !x.userScored)
                          .map(x => x.firstName + " " + x.lastName)
                          .join(", ")
                      : "All have scored"}
                  </span>
                </div>
              </Row>
            )}
            <Row style={{ marginTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                }}
              >
                <div className="form-check">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck1"
                    checked={awaitingParticipants}
                    onClick={e => {
                      setAwaitingParticipants(!awaitingParticipants)
                    }}
                  />
                  <Label className="form-check-label" for="customCheck1">
                    Awaiting Participants
                  </Label>
                </div>
                <div className="form-check" style={{ marginLeft: "10px" }}>
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck2"
                    checked={showGroupRating}
                    onClick={e => {
                      setShowGroupRating(!showGroupRating)
                    }}
                  />
                  <Label className="form-check-label" for="customCheck2">
                    Group Rating
                  </Label>
                </div>
                <div className="form-check" style={{ marginLeft: "10px" }}>
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck3"
                    checked={showMeanScore}
                    onClick={e => {
                      setShowMeanScore(!showMeanScore)
                    }}
                  />
                  <Label className="form-check-label" for="customCheck3">
                    Mean Score
                  </Label>
                </div>
                <div className="form-check" style={{ marginLeft: "10px" }}>
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck4"
                    checked={showStandardDev}
                    onClick={e => {
                      setShowStandardDev(!showStandardDev)
                    }}
                  />
                  <Label className="form-check-label" for="customCheck4">
                    Consensus
                  </Label>
                </div>
                <div className="form-check" style={{ marginLeft: "10px" }}>
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck5"
                    checked={showParticipants}
                    onClick={e => {
                      setShowParticipants(!showParticipants)
                    }}
                  />
                  <Label className="form-check-label" for="customCheck5">
                    Participant List
                  </Label>
                </div>
                <div className="form-check" style={{ marginLeft: "10px" }}>
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck6"
                    checked={showChart}
                    onClick={e => {
                      setShowChart(!showChart)
                    }}
                  />
                  <Label className="form-check-label" for="customCheck6">
                    Response Graph
                  </Label>
                </div>
                <div className="form-check" style={{ marginLeft: "10px" }}>
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck7"
                    checked={showGrid}
                    onClick={e => {
                      setShowGrid(!showGrid)
                    }}
                  />
                  <Label className="form-check-label" for="customCheck7">
                    Response Grid
                  </Label>
                </div>
                <div className="form-check" style={{ marginLeft: "10px" }}>
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck8"
                    checked={showBenefitScore}
                    onClick={e => {
                      setShowBenefitScore(!showBenefitScore)
                    }}
                  />
                  <Label className="form-check-label" for="customCheck8">
                    Benefit Score
                  </Label>
                </div>
                <div className="form-check" style={{ marginLeft: "10px" }}>
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="lockcheck"
                    checked={locked}
                    onClick={e => {
                      setLocked(!locked)
                    }}
                  />
                  <Label className="form-check-label" for="lockcheck">
                    Lock
                  </Label>
                </div>
              </div>
            </Row>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginLeft: "15%",
                marginRight: "1%",
                marginTop: "10px",
              }}
            >
              {nextAltScore != null && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-primary "
                    style={{ marginRight: "10px" }}
                    onClick={async () =>
                      await loadPrevData(
                        nextAltScore.altScoreID,
                        nextAltScore.levelID,
                        "alt"
                      )
                    }
                  >
                    <i className="fas fa-arrow-left"></i> Previous{" "}
                    {scenario && scenario.alternativeName}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary  mt-2"
                    style={{ marginRight: "10px" }}
                    onClick={async () =>
                      await loadPrevData(
                        nextAltScore.altScoreID,
                        nextAltScore.levelID,
                        "obj"
                      )
                    }
                  >
                    <i className="fas fa-arrow-left"></i> Previous{" "}
                    {scenario && scenario.objectiveName}
                  </button>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                }}
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={async () => toggleAltModal()}
                >
                  Go To {scenario && scenario.alternativeName}
                </button>
                {isLoading && (
                  <Loader
                    type="line-scale-pulse-out"
                    color={colors.primary}
                    style={{ textAlign: "center", height: "15px" }}
                  />
                )}
              </div>
              {nextAltScore != null && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={async () =>
                      await loadNextData(
                        nextAltScore.altScoreID,
                        nextAltScore.levelID,
                        "alt"
                      )
                    }
                  >
                    Next {scenario && scenario.alternativeName}{" "}
                    <i className="fas fa-arrow-right"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary  mt-2"
                    onClick={async () =>
                      await loadNextData(
                        nextAltScore.altScoreID,
                        nextAltScore.levelID,
                        "obj"
                      )
                    }
                  >
                    Next {scenario && scenario.objectiveName}{" "}
                    <i className="fas fa-arrow-right"></i>
                  </button>
                </div>
              )}
            </div>

            <Row style={{ marginLeft: "14%", marginRight: "0%" }}>
              {nextAltScore != null && (
                <GroupRating
                  nextAltScore={nextAltScore}
                  scenario={scenario}
                  showMeanScore={showMeanScore}
                  showStandardDev={showStandardDev}
                  showGroupRating={showGroupRating}
                  showBenefitScore={showBenefitScore}
                />
              )}
            </Row>

            {scenario != null &&
              userScores != null &&
              userScores.length > 0 &&
              scenarioRatingScale != null &&
              scenarioRatingScale.length > 0 && (
                <>
                  {/* <Row style={{ marginTop: "10px" }}>
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={recalculate}
                    >
                      <i className="fas fa-calculator"></i> Recalculate
                    </button>
                  </div>
                </Row> */}
                  {showGrid && (
                    <Row
                      style={{
                        marginTop: "10px",
                        marginLeft: !showParticipants ? "15%" : "initial",
                      }}
                    >
                      <AlternativeRatingTable
                        scenarioRatingScale={scenarioRatingScale}
                        userScores={userScores}
                        hasParticipants={showParticipants}
                        usersPhotos={usersPhotos}
                      />
                    </Row>
                  )}
                  {showChart && chartData != null && (
                    <BarChart
                      options={chartData.options}
                      series={chartData.series}
                    />
                  )}
                </>
              )}
          </div>
        </Container>
        <SelectAlternativeModal
          isOpen={showAltModal}
          toggleModal={toggleAltModal}
          scenarioId={scenarioId}
          scenario={scenario}
          onSelect={loadAlternativeFromModal}
        />
      </div>
    </React.Fragment>
  )
}

const BarChart = React.memo(function barChart({ options, series }) {
  return (
    <Row
      style={{
        marginTop: "10px",
        marginLeft: "13%",
      }}
    >
      <ReactApexChart
        options={options}
        series={series}
        style={{ backgroundColor: "white" }}
        type="bar"
        height="250"
      />
    </Row>
  )
})

const GroupRating = React.memo(
  function groupRating({
    nextAltScore,
    scenario,
    showMeanScore,
    showStandardDev,
    showGroupRating,
    showBenefitScore,
  }) {
    return (
      <div>
        <div
          style={{
            textAlign: "center",
            marginTop: "20px",
            //backgroundColor: "lightyellow",
            borderRadius: "10px",
            padding: "15px",
          }}
        >
          <h5>
            <u>
              <b>Score {nextAltScore.comparison}</b>
            </u>
          </h5>
          <h5>
            To what degree does the{" "}
            <span style={{ color: "maroon", textAlign: "center"  }}>
              <b>{nextAltScore.alternativeName}</b>
            </span>{" "}
            project
          </h5>
          <h5>
            contribute to the{" "}
            {nextAltScore.levelID == 2
              ? scenario.objectiveName.toLowerCase() + " / " + scenario.subCriteriaName.toLowerCase()
              : scenario.objectiveName.toLowerCase()}{" "}
            <span style={{ color: "maroon", textAlign: "center"  }}>
              <b>{nextAltScore.objMeasName}</b>
            </span>
          </h5>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          {(showMeanScore ||
            showStandardDev ||
            showGroupRating ||
            showBenefitScore) && (
            <div
              style={{
                textAlign: "center",
                backgroundColor: "white",
                borderRadius: "10px",
                border: "1px solid gray",
                padding: "15px",
              }}
            >
              {showMeanScore && (
                <p style={{ marginBottom: "0px" }}>
                  <b>The mean score is: {nextAltScore.score}</b>
                </p>
              )}
              {showStandardDev && (
                <p style={{ marginBottom: "0px" }}>
                  <b>The standard deviation is: {nextAltScore.standDev}</b>
                </p>
              )}
              {showGroupRating && (
                <p style={{ marginBottom: "0px" }}>
                  <b>The group rating is: {nextAltScore.verbalScore}</b>
                </p>
              )}
              {showBenefitScore && (
                <p style={{ marginBottom: "0px" }}>
                  <b>The overall {scenario.alternativeName.toLowerCase()} benefit score is: {nextAltScore.saaScore}</b>
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    )
  },
  (prevProps, nextProps) => {
    return (
      JSON.stringify(prevProps.nextAltScore) ==
        JSON.stringify(nextProps.nextAltScore) &&
      prevProps.showMeanScore == nextProps.showMeanScore &&
      prevProps.showStandardDev == nextProps.showStandardDev &&
      prevProps.showGroupRating == nextProps.showGroupRating &&
      prevProps.showBenefitScore == nextProps.showBenefitScore
    )
  }
)

export default AdminAlternativeFacilitate
