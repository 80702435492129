import React, { useEffect, useState } from "react"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import Diff from "react-stylable-diff"
import Loader from "react-loaders"
import colors from "utils/colors"

const AlternativeCompareModal = ({ isOpen, toggleModal, alternative }) => {
  const [compareItems, setCompareItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setCompareItems([])
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    setIsLoading(true)
    let items = await api.getCompareItems(
      alternative.revisionAltID,
      alternative.alternativeID
    )
    setCompareItems(items)
    setIsLoading(false)
  }

  const save = async () => {}

  return (
    <Modal
      isOpen={isOpen}
      size="xl"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Baseline and Revision Comparison
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          {!isLoading &&
            compareItems.map((x, idx) => <CompareItem key={idx} compareItem={x} />)}
        </Row>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
        {/* <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button> */}
      </div>
    </Modal>
  )
}

export const CompareItem = ({ compareItem }) => {
  if (compareItem.compareType == "TextCompare")
    return (
      <Col md="12">
        <div className="mb-3 mt-1">
          <Label className="form-label">{compareItem.labelName}</Label>
          <Diff
            inputA={compareItem.fieldText1}
            inputB={compareItem.fieldText2}
            type="words"
          />
        </div>
        <div className="divider"></div>
      </Col>
    )
  else
    return (
      <Col md="12">
        <div className="mb-3 mt-1">
          <Label className="form-label">{compareItem.labelName}</Label>
          <div
            style={{ marginLeft: "10px" }}
            dangerouslySetInnerHTML={{
              __html: compareItem.comparisonText,
            }}
          ></div>
        </div>
        <div className="divider"></div>
      </Col>
    )
}

export default AlternativeCompareModal
