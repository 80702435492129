export const getSelectHtmlFilterValues = distinctFilterOptions => {
  let roleFilterValues = {}
  distinctFilterOptions.forEach(r => {
    let span = document.createElement("span")
    span.innerHTML = r
    roleFilterValues[r] = span.textContent || span.innerText
  })
  return roleFilterValues
}

export const getSelectedVisibleRows = tabulatorRef => {
  let visible = tabulatorRef.current.getData("active")
  let selected = tabulatorRef.current.getSelectedData()

  let list = selected.filter(
    x => visible.find(v => JSON.stringify(v) == JSON.stringify(x)) !== undefined
  )
  return list
}
