import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"

const EditDocumentModal = ({
  isOpen,
  toggleModal,
  documentToEdit,
  setDocumentToEdit,
  reloadData,
  showType,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [file, setFile] = useState(null)

  useEffect(() => {
    if (isOpen) {
      setFile(null)
    }
  }, [isOpen])

  const onFileChange = event => {
    let f = event.target.files[0]
    setFile(f)
  }

  const saveDocument = async () => {
    if (documentToEdit.documentID > -1) {
      await api.updateDocument(currentUser.userID, documentToEdit)
      toggleModal()
      reloadData()
    } else {
      if (file == null) {
        toast.error("You can not save a document without a file")
      } else {
        await api.createDocument(currentUser.userID, file, documentToEdit)
        toggleModal()
        reloadData()
      }
    }
  }

  const deleteDocument = document => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this document?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteDocument(document.alternativeID, document.documentID)
          toggleModal()
          reloadData()
        }
      })
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      {documentToEdit !== null && (
        <>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {documentToEdit.documentID == -1 ? "Add " : "Edit "} Document
            </h5>
            <button
              type="button"
              onClick={() => {
                toggleModal()
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {documentToEdit.documentID > -1 && (
              <div className="mb-3">
                <a
                  href={documentToEdit.docPath}
                  download=""
                  target="_blank"
                  rel="noreferrer"
                >
                  {documentToEdit.docTitle}
                </a>
              </div>
            )}
            {(showType == undefined || showType == true) && (
              <div className="mb-3">
                <Label className="form-label">Document Type</Label>
                <Input
                  name="note"
                  type="text"
                  onChange={e =>
                    setDocumentToEdit({
                      ...documentToEdit,
                      docType: e.target.value,
                    })
                  }
                  value={documentToEdit.docType}
                />
              </div>
            )}
            {documentToEdit.documentID == -1 && (
              <div className="mb-3">
                <Label
                  className="btn btn-primary"
                  style={{ marginBottom: "0px", fontWeight: "bold" }}
                >
                  <input
                    type="file"
                    name="myFile"
                    onChange={onFileChange}
                    style={{ display: "none" }}
                  />
                  Upload File
                </Label>
                {file != null && (
                  <span style={{ marginLeft: "10px" }}>{file.name}</span>
                )}
                {file == null && documentToEdit.documentID > -1 && (
                  <span style={{ marginLeft: "10px" }}>
                    {documentToEdit.docTitle}
                  </span>
                )}
              </div>
            )}
          </div>
        </>
      )}
      <div
        className="modal-footer"
        style={{
          justifyContent:
            documentToEdit !== null && documentToEdit.documentID > -1
              ? "space-between"
              : "flex-end",
        }}
      >
        {documentToEdit !== null && documentToEdit.documentID > -1 && (
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => deleteDocument(documentToEdit)}
          >
            Delete
          </button>
        )}
        <div>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={saveDocument}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default EditDocumentModal
