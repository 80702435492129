import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const VideosModal = ({ isOpen, toggleModal }) => {
  const currentUser = userService.getLoggedInUser()
  const [isLoading, setIsLoading] = useState(false)
  const [videos, setVideos] = useState([])

  useEffect(() => {
    if (isOpen) {
      loadVideos()
    }
  }, [isOpen])

  const loadVideos = async () => {
    setIsLoading(true)
    let v = await api.getList(currentUser.userID, "HelpVideo")
    setVideos(v)
    setIsLoading(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Help Videos
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        <div className="mb-3">
          {videos.map((v, i) => (
            <>
              <a key={i} href={v.listItemID} target="_blank" rel="noreferrer">
                {v.listItemName}
              </a>
              <br />
            </>
          ))}
        </div>
      </div>

      <div style={{ textAlign: "center" }}>
      <iframe src="https://vimeo.com/showcase/10849902/embed" width="720" height="405" allowFullScreen frameBorder="0"></iframe>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>       
      </div>
    </Modal>
  )
}

export default VideosModal
