import { TextareaAutosize } from "@material-ui/core"
import HelpIconWithModal from "components/custom/helpIconWithModal"
import ChatGPTIconWithModal from "components/custom/chatGPTIconWithModal"
import React, { useEffect } from "react"
import { Col, Input, Label, Row } from "reactstrap"
import { userService } from "services/user.service"
import moment from "moment"
import { AlternativeConfigField } from "./GovernanceSection"
import DynamicForm from "components/custom/DynamicForm/View/DynamicForm"
import useFetcher from "hooks/useFetcher"
import api from "services/api.service"

const GeneralNeedSection = ({
  activeTab,
  alternative,
  changeAlternativeProp,
  governanceOptions,
  planningOptions,
  sponsoringOptions,
  priorityOptions,
  errorProps,
  fieldConfigItems,
  entities,
  formId,
  triggerFormSave,
  setTriggerFormSave,
  showToastChangesCallback,
  scenario,
  sbuLabel,
  governanceBoardLabel,
}) => {
  const currentUser = userService.getLoggedInUser()
  // useEffect(() => {
  //   if (formId && formId > 0) {
  //     loadForm(formId)
  //   }
  // }, [formId])

  // const loadForm = async formId => {}

  return (
    <React.Fragment>
      {formId != undefined &&
        formId != null &&
        formId > 0 &&
        activeTab == "General_Need" && (
          <DynamicForm
            formId={formId}
            itemId={alternative.alternativeID}
            scenarioId={alternative.scenarioID}
            setTriggerFormSave={setTriggerFormSave}
            triggerFormSave={triggerFormSave}
            showToastChangesCallback={showToastChangesCallback}
          />
        )}
      {(!formId || formId <= 0) && (
        <>
          <Row className="mb-3">
            <Col sm="6">
              <div
                className={
                  !alternative.ideaOnly &&
                  currentUser.roleID < 10 &&
                  alternative.alternativeID > 0
                    ? "always-disabled"
                    : ""
                }
              >
                <Label
                  className="form-label"
                  hidden={alternative.alternativeID <= 0}
                >
                  ID
                </Label>
                <Input
                  name="alternativeNum"
                  type="text"
                  onChange={e =>
                    changeAlternativeProp("alternativeNum", e.target.value)
                  }
                  value={alternative.alternativeNum}
                  hidden={alternative.alternativeID <= 0}
                />
              </div>
            </Col>
            <Col
              sm="6"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Label className="form-label">
                <b>
                  Submitted by{" "}
                  {alternative.alternativeID > 0
                    ? alternative.submitter +
                      " - " +
                      moment(alternative.createdDT).format("MM/DD/YYYY")
                    : `${currentUser.firstName} ${currentUser.lastName}`}
                </b>
              </Label>
              {/* <HelpIconWithModal itemId={1} /> */}
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <AlternativeConfigField
                label={governanceBoardLabel}
                value={alternative.governanceBoardID}
                fieldConfigItems={fieldConfigItems}
                onChange={changeAlternativeProp}
                options={governanceOptions}
                prop={"governanceBoardID"}
                type="select"
              />
              {/* <div className="mb-3">
            <Label className="form-label">Governance Board</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={alternative.governanceBoardID || -1}
              onChange={e => {
                changeAlternativeProp("governanceBoardID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {governanceOptions.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemID}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div> */}
              {/*           <div className="mb-3">
            <Label className="form-label">Planning Category</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={alternative.projectCategoryID || -1}
              onChange={e => {
                changeAlternativeProp("projectCategoryID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {planningOptions.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemID}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div> */}
              <div className="mb-3">
                <Label className="form-label">Business Priority</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={alternative.businessPriority || -1}
                  onChange={e => {
                    changeAlternativeProp("businessPriority", e.target.value)
                  }}
                >
                  <option value={-1}>Select...</option>
                  {priorityOptions.map((a, idx) => {
                    return (
                      <option key={idx} value={`${a.listItemName}`}>
                        {a.listItemName}
                      </option>
                    )
                  })}
                </select>
              </div>
            </Col>
            <Col sm="6">
              <AlternativeConfigField
                label={sbuLabel}
                value={alternative.sponsoringUnitID}
                fieldConfigItems={fieldConfigItems}
                onChange={changeAlternativeProp}
                options={sponsoringOptions}
                prop={"sponsoringUnitID"}
                type="select"
              />
              {entities != null && entities.length > 0 && (
                <div className="mb-3">
                  <Label className="form-label">{entities[0].entityType} *</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={alternative.entityID}
                    onChange={e => {
                      changeAlternativeProp("entityID", e.target.value)
                    }}
                  >
                    <option value={-1}>Select...</option>
                    {entities.map((a, idx) => {
                      return (
                        <option key={idx} value={`${a.entityID}`}>
                          {a.entityName}
                        </option>
                      )
                    })}
                  </select>
                  {errorProps.find(x => x == "entityID") && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Required
                    </div>
                  )}
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <div
                className={`mb-3 ${
                  !alternative.ideaOnly &&
                  currentUser.roleID < 10 &&
                  alternative.alternativeID > 0
                    ? "always-disabled"
                    : ""
                }`}
              >
                <Label className="form-label">Title *</Label>
                <Input
                  name="alternativeName"
                  type="text"
                  onChange={e => {
                    if (
                      e.target.value !== undefined &&
                      e.target.value !== null &&
                      e.target.value.length <= 120
                    ) {
                      changeAlternativeProp("alternativeName", e.target.value)
                    }
                  }}
                  value={alternative.alternativeName}
                />
                {errorProps.find(x => x == "alternativeName") && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    Required
                  </div>
                )}
                {alternative.alternativeName != undefined &&
                  alternative.alternativeName != null &&
                  alternative.alternativeName.length == 120 && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Title can only be 120 characters
                    </div>
                  )}
              </div>
              <div className="mb-3">
                <Label className="form-label">Description *</Label>
                <TextareaAutosize
                  className="form-control"
                  placeholder="Provide an executive summary of the following fields: customer, business problem, and business outcomes."
                  minRows={2}
                  onChange={e =>
                    changeAlternativeProp("description", e.target.value)
                  }
                  value={alternative.description}
                />
                {errorProps.find(x => x == "description") && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    Required
                  </div>
                )}
              </div>
              <div className="mb-3">
                <Label className="form-label">Customer</Label>
                <ChatGPTIconWithModal
                  scope="Alternative"
                  itemId={alternative.alternativeID}
                  field="Customer"
                  appendToField={text =>
                    changeAlternativeProp(
                      "customer",
                      alternative.customer + "\n\n" + text
                    )
                  }
                  replaceField={text => changeAlternativeProp("customer", text)}
                />
                <TextareaAutosize
                  className="form-control"
                  placeholder="Describe the end customer (organizations, roles, # employees, # contractors, etc.)."
                  minRows={2}
                  onChange={e =>
                    changeAlternativeProp("customer", e.target.value)
                  }
                  value={alternative.customer}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Business Problem</Label>
                <ChatGPTIconWithModal
                  scope="Alternative"
                  itemId={alternative.alternativeID}
                  field="BusinessProblem"
                  appendToField={text =>
                    changeAlternativeProp(
                      "businessProblem",
                      alternative.businessProblem + "\n\n" + text
                    )
                  }
                  replaceField={text =>
                    changeAlternativeProp("businessProblem", text)
                  }
                />
                <TextareaAutosize
                  minRows={2}
                  className="form-control"
                  placeholder="Describe the business problem from a business perspective, and be technology and solution agnostic."
                  onChange={e =>
                    changeAlternativeProp("businessProblem", e.target.value)
                  }
                  value={alternative.businessProblem}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Business Requirements</Label>
                <ChatGPTIconWithModal
                  scope="Alternative"
                  itemId={alternative.alternativeID}
                  field="BusinessRequirements"
                  appendToField={text =>
                    changeAlternativeProp(
                      "businessRequirements",
                      alternative.customer + "\n\n" + text
                    )
                  }
                  replaceField={text =>
                    changeAlternativeProp("businessRequirements", text)
                  }
                />
                <TextareaAutosize
                  minRows={2}
                  className="form-control"
                  placeholder="Describe what the business needs. Do not describe the: user requirements (what the users need to do); functional requirements (what the system should do); characteristics of the system (non-functional requirements); or how the system should be built (system requirements)."
                  onChange={e =>
                    changeAlternativeProp(
                      "businessRequirements",
                      e.target.value
                    )
                  }
                  value={alternative.businessRequirements}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Business Outcomes</Label>
                <ChatGPTIconWithModal
                  scope="Alternative"
                  itemId={alternative.alternativeID}
                  field="BusinessOutcomes"
                  appendToField={text =>
                    changeAlternativeProp(
                      "businessOutcomes",
                      alternative.customer + "\n\n" + text
                    )
                  }
                  replaceField={text =>
                    changeAlternativeProp("businessOutcomes", text)
                  }
                />
                <TextareaAutosize
                  minRows={2}
                  className="form-control"
                  placeholder="Describe the expected results of implementing the change. To the greatest extent possible, they should be specific and measurable."
                  onChange={e =>
                    changeAlternativeProp("businessOutcomes", e.target.value)
                  }
                  value={alternative.businessOutcomes}
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">Business Value</Label>
                <ChatGPTIconWithModal
                  scope="Alternative"
                  itemId={alternative.alternativeID}
                  field="BusinessValue"
                  appendToField={text =>
                    changeAlternativeProp(
                      "businessValue",
                      alternative.customer + "\n\n" + text
                    )
                  }
                  replaceField={text =>
                    changeAlternativeProp("businessValue", text)
                  }
                />
                <TextareaAutosize
                  minRows={2}
                  className="form-control"
                  placeholder="Describe the benefits, which should encompass a wide range of factors that can positively impact the organization and its stakeholders. When assessing the business value, consider the following: financial impact, customer satisfaction and experience, operational efficiency, innovation and differentiation, risk management and compliance, and employee engagement and satisfaction."
                  onChange={e =>
                    changeAlternativeProp("businessValue", e.target.value)
                  }
                  value={alternative.businessValue}
                />
              </div>

              <div className="mb-3">
                <Label className="form-label">Business Risk</Label>
                <ChatGPTIconWithModal
                  scope="Alternative"
                  itemId={alternative.alternativeID}
                  field="BusinessRisk"
                  appendToField={text =>
                    changeAlternativeProp(
                      "businessRisk",
                      alternative.customer + "\n\n" + text
                    )
                  }
                  replaceField={text =>
                    changeAlternativeProp("businessRisk", text)
                  }
                />
                <TextareaAutosize
                  minRows={2}
                  className="form-control"
                  placeholder="Describe the risks to the organization or to the mission should this investment not be approved."
                  onChange={e =>
                    changeAlternativeProp("businessRisk", e.target.value)
                  }
                  value={alternative.businessRisk}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Strategic Rationale</Label>
                <TextareaAutosize
                  minRows={2}
                  className="form-control"
                  placeholder="Summarize the strategic aligment of this investment."
                  onChange={e =>
                    changeAlternativeProp("strategicRationale", e.target.value)
                  }
                  value={alternative.strategicRationale}
                />
              </div>
            </Col>
          </Row>
        </>
      )}
    </React.Fragment>
  )
}

export default GeneralNeedSection
