import {
  swalWithConfirmAndCancelButtons,
  swalWithConfirmButton,
} from "components/custom/swal"
import moment from "moment"
import { Tooltip } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { loadPageItem } from "store/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import anychart from "anychart"
import { toast } from "react-toastify"

const ResourceCharts = props => {
  const currentUser = userService.getLoggedInUser()
  const scenarioId = props.match.params.scenarioId
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [isLoading, setIsLoading] = useState(false)
  const [isChartLoading, setIsChartLoading] = useState(false)
  const [isDrilldownLoading, setIsDrilldownLoading] = useState(false)
  const [alternatives, setAlternatives] = useState([])
  const [scenario, setScenario] = useState(null)
  const [decisions, setDecisions] = useState([])
  const [resourceClasses, setResourceClasses] = useState([])
  const [selectedResourceClass, setSelectedResourceClass] = useState(0)
  const [resourceGroups, setResourceGroups] = useState([])
  const [selectedDecision, setSelectedDecision] = useState("-1")
  const [alternativesVisible, setAlternativesVisible] = useState(true)
  const [selectedChart, setSelectedChart] = useState("0")
  const [classesVisible, setClassesVisible] = useState(true)
  const [allAltsSelected, setAllAltsSelected] = useState(false)
  const [allClassesSelected, setAllClassesSelected] = useState(false)

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "resourceCharts",
      })
    )
    loadScenario()
    loadData()
    anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
  }, [])

  const loadData = async () => {
    setIsLoading(true)
    await loadAlternatives()
    await loadResourceGroups(0)
    let items = await api.getList(currentUser.userID, "Decisions")
    setDecisions(items)
    let rc = await api.getEnterpriseList(
      currentUser.enterpriseID,
      "ResourceClass"
    )
    setResourceClasses(rc)
    setIsLoading(false)
  }

  const loadResourceGroups = async rgId => {
    let rg = await api.getResourceGroups(scenarioId, rgId)
    setResourceGroups(rg)
  }

  const loadAlternatives = async () => {
    let alts = await api.getAlternatives(currentUser.userID, scenarioId)
    setAlternatives(alts)
  }

  const loadScenario = async () => {
    let sc = await api.getScenario(scenarioId)
    setScenario(sc)
  }

  const updateActualDates = async () => {
    let itemsToUpdate = alternatives.filter(x => x.isDirty)
    if (itemsToUpdate.length > 0) {
      let itemsToUpdateTasks = itemsToUpdate.map(x => async () => {
        return await api.updateAlternative(currentUser.userID, {
          ...x,
          desiredStart: x.tempStartDate,
        })
      })
      await Promise.all(itemsToUpdateTasks.map(t => t()))
      toast.success("Alternatives saved successfuly")
    }
  }

  const changeAltProp = (alt, prop, value, markDirty) => {
    let copy = [...alternatives]
    let item = copy.find(x => x.alternativeID == alt.alternativeID)
    item[prop] = value
    if (markDirty) {
      item.isDirty = true
    }
    setAlternatives(copy)
  }

  const changeResourceGroupProp = (rg, prop, value) => {
    let copy = [...resourceGroups]
    let item = copy.find(x => x.resourceGroupID == rg.resourceGroupID)
    item[prop] = value
    setResourceGroups(copy)
  }

  const loadChart = async chartType => {
    setIsChartLoading(true)
    if (
      alternatives.filter(x => x.isSelected).length == 0 ||
      resourceGroups.filter(x => x.isSelected).length == 0
    ) {
      swalWithConfirmButton.fire({
        title: `You have to select at least one project and at least one resource.`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    } else {
      document.getElementById("chartdiv").innerHTML = ""
      var chartResponse = null
      if (chartType == "0") {
        chartResponse = await api.getResourceColumnChart(
          scenarioId,
          scenario.resourceStartDT,
          scenario.resourceEndDT,
          alternatives
            .filter(x => x.isSelected)
            .map(x => x.alternativeID)
            .join(","),
          resourceGroups
            .filter(x => x.isSelected)
            .map(x => x.resourceGroupID)
            .join(",")
        )
        let chartData = JSON.parse(
          chartResponse.chartArray.replaceAll("'", '"')
        )
        var dataSet = anychart.data.set(chartData)

        // map data for the second series, take x from the zero column and value from the second column of data set
        var firstSeriesData = dataSet.mapAs({ x: 0, value: 1 })

        // map data for the second series, take x from the zero column and value from the third column of data set
        var secondSeriesData = dataSet.mapAs({ x: 0, value: 3 })

        // map data for the second series, take x from the zero column and value from the third column of data set
        var thirdSeriesData = dataSet.mapAs({ x: 0, value: 4 })
        var setupSeriesLabels = function (series, name) {
          series.name(name).stroke("3 #fff 1")
          series.hovered().stroke("3 #fff 1")
        }
        var chart = anychart.column()
        chart.animation(true)
        let series = chart.column(firstSeriesData)
        setupSeriesLabels(series, "Excess Capacity")

        function coloringFunction() {
          // color the maximal value
          if (this.value > 0) return "#3ba158"
          if (this.value < 0) return "#fa6b71"

          return "#3ba158"
        }

        series.fill(coloringFunction)
        series = chart.column(thirdSeriesData)
        setupSeriesLabels(series, "New")
        series.fill("#ADD8E6")

        // create second series with mapped data
        series = chart.column(secondSeriesData)
        setupSeriesLabels(series, "Approved")
        series.fill("#00008B")

        // force chart to stack values by Y scale.
        chart.yScale().stackMode("value")

        // set chart title text settings
        chart.title(chartResponse.chartTitle)
        chart.title().padding([0, 0, 5, 0])

        var xAxisLabels = chart.xAxis().labels()
        xAxisLabels.rotation(90)

        chart.barGroupsPadding(0.5)
        chart.barsPadding(0.5)

        // turn on legend
        chart.legend().enabled(true).fontSize(13).padding([0, 0, 20, 0])
        // set yAxis labels formatter
        chart.yAxis().labels().format("{%Value}{groupsSeparator: }")

        // set titles for axes
        chart.xAxis().title("Months")
        chart.yAxis().title("Units")

        chart.xScroller().enabled(true)
        chart.xZoom().setToPointsCount(18)

        var tooltip = chart.tooltip()
        tooltip.displayMode("union")

        // set interactivity hover
        chart.interactivity().hoverMode("byX")

        // set container id for the chart
        chart.container("chartdiv")
        chart.credits().enabled(false)
        chart.draw()
      } else {
        if (chartType == "1") {
          chartResponse = await api.getResourceAreaRgChart(
            scenarioId,
            scenario.resourceStartDT,
            scenario.resourceEndDT,
            alternatives
              .filter(x => x.isSelected)
              .map(x => x.alternativeID)
              .join(","),
            resourceGroups
              .filter(x => x.isSelected)
              .map(x => x.resourceGroupID)
              .join(",")
          )
        } else {
          let aggregate = chartType == "3"
          chartResponse = await api.getResourceAreaAltChart(
            scenarioId,
            scenario.resourceStartDT,
            scenario.resourceEndDT,
            alternatives
              .filter(x => x.isSelected)
              .map(x => x.alternativeID)
              .join(","),
            resourceGroups
              .filter(x => x.isSelected)
              .map(x => x.resourceGroupID)
              .join(","),
            aggregate
          )
        }
        let obj1 = eval(
          "(" + chartResponse.chartArray.replaceAll("'", '"') + ")"
        )
        let json1 = JSON.stringify(obj1)
        let chartData = JSON.parse(json1)
        // let obj2 = eval("(" + chartResponse.chartArray2.replaceAll("'", '"') + ")")
        // let json2 = JSON.stringify(obj2)
        // let altData = JSON.parse(json2)
        var dataSet = anychart.data.set(chartData)

        var maxSet = anychart.data.set(chartData)

        var maxSeriesData = maxSet.mapAs({ x: 0, value: 1 })

        // helper function to setup label settings for all series
        var setupSeriesLabels = function (series, name) {
          series.name(name).stroke("3 #fa6b71 1")
          series.hovered().stroke("3 #fa6b71 1")
        }

        // create bar chart
        var chart = anychart.area()

        var alts = chartResponse.chartArray2.split(",").filter(x => x !== "")

        var numseries = parseInt(chartResponse.chartData, 10)
        var j = 0

        for (var i = 0; i < numseries; i++) {
          j = i + 1

          chart.addSeries(
            dataSet.mapAs({
              x: 0,
              value: j + 1, //,
              //'tooltip': 4
            })
          )

          chart.getSeriesAt(i).name(alts[i])
        }

        var tooltip = chart.tooltip()
        tooltip.displayMode("single")
        // set X scale mode to continuous
        chart.xScale().mode("continuous")

        let series = chart.line(maxSeriesData)
        setupSeriesLabels(series, "Capacity")

        // turn on chart animation
        chart.animation(true)

        // force chart to stack values by Y scale.
        chart.yScale().stackMode("value")

        // set chart title text settings
        chart.title(chartResponse.chartTitle)
        chart.title().padding([0, 0, 5, 0])

        var xAxisLabels = chart.xAxis().labels()
        xAxisLabels.rotation(90)

        // turn on legend
        chart.legend().enabled(true).fontSize(13).padding([0, 0, 20, 0])
        // set yAxis labels formatter
        chart.yAxis().labels().format("{%Value}{groupsSeparator: }")

        // set titles for axes
        chart.xAxis().title("Months")
        chart.yAxis().title("Units")

        // set interactivity hover
        chart.interactivity().hoverMode("bySpot")
        chart.interactivity().spotRadius(10)

        chart.xScroller().enabled(true)
        chart.xZoom().setToPointsCount(12)
        chart.container("chartdiv")
        chart.credits().enabled(false)
        chart.draw()
      }
    }
    setIsChartLoading(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Card>
            <CardBody>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              {!isLoading && (
                <>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Start Date</Label>
                        {scenario !== null && (
                          <Input
                            name="startDate"
                            type="date"
                            onChange={e => {
                              setScenario({
                                ...scenario,
                                resourceStartDT: moment(
                                  e.target.value,
                                  "YYYY-MM-DD"
                                ).format("YYYY-MM-DDThh:mm:ss"),
                              })
                            }}
                            value={moment(scenario.resourceStartDT).format(
                              "YYYY-MM-DD"
                            )}
                          />
                        )}
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>End Date</Label>
                        {scenario !== null && (
                          <Input
                            name="endDate"
                            type="date"
                            onChange={e => {
                              setScenario({
                                ...scenario,
                                resourceEndDT: moment(
                                  e.target.value,
                                  "YYYY-MM-DD"
                                ).format("YYYY-MM-DDThh:mm:ss"),
                              })
                            }}
                            value={moment(scenario.resourceEndDT).format(
                              "YYYY-MM-DD"
                            )}
                          />
                        )}
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label style={{ marginBottom: "0px" }}>Chart</Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={selectedChart}
                          onChange={e => {
                            setSelectedChart(e.target.value)
                            loadChart(e.target.value)
                          }}
                        >
                          <option value="0">Column Chart - Resources</option>
                          <option value="1">Area Chart - By Resource</option>
                          <option value="2">Area Chart - By Project</option>
                          <option value="3">
                            Area Chart - By Project (Approved Aggregated)
                          </option>
                        </select>
                      </div>
                    </Col>
                    <Col md="3" style={{ display: "flex" }}>
                      <button
                        className="btn btn-primary"
                        style={{ alignSelf: "center" }}
                        onClick={() => loadChart(selectedChart)}
                      >
                        <i className="fas fa-chart-bar"></i> View / Refresh
                        Chart
                      </button>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <button
                        className="btn btn-primary"
                        onClick={() =>
                          setAlternativesVisible(!alternativesVisible)
                        }
                      >
                        <i className="fas fa-exchange-alt"></i>{" "}
                        {alternativesVisible ? "Hide " : "Show "} Alternatives
                      </button>
                      <button
                        className="btn btn-primary save-user"
                        onClick={() => setClassesVisible(!classesVisible)}
                      >
                        <i className="fas fa-exchange-alt"></i>{" "}
                        {classesVisible ? "Hide " : "Show "} Classes
                      </button>
                      {alternativesVisible && (
                        <button
                          className="btn  btn-primary save-user"
                          onClick={() => updateActualDates()}
                        >
                          <i className="fas fa-save"></i> Update Actual Start
                          Dates
                        </button>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    {alternativesVisible && (
                      <Col md="3">
                        <div className="mb-3">
                          <Label style={{ marginBottom: "0px" }}>Include</Label>
                          <select
                            className="form-control form-select select2 mb-xxl-0"
                            value={selectedDecision}
                            onChange={e => {
                              setSelectedDecision(e.target.value)
                            }}
                          >
                            <option value="-1">All</option>
                            {decisions.map((r, idx) => {
                              return (
                                <option key={idx} value={`${r.listItemID}`}>
                                  {r.listItemName}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                        <table
                          className="table table-bordered lower-padding-table"
                          style={{ backgroundColor: "white" }}
                        >
                          <thead>
                            <tr style={{ fontSize: "12px" }}>
                              <th>
                                <Input
                                  type="checkbox"
                                  checked={allAltsSelected}
                                  onClick={() => {
                                    let copy = [...alternatives]
                                    copy.forEach(c => {
                                      c.isSelected = !allAltsSelected
                                    })
                                    setAlternatives(copy)
                                    setAllAltsSelected(!allAltsSelected)
                                  }}
                                />
                              </th>
                              <th>Business Need</th>
                              <th>Version</th>
                              <th>Start</th>
                            </tr>
                          </thead>
                          <tbody>
                            {alternatives
                              .filter(
                                x =>
                                  selectedDecision == "-1" ||
                                  x.decisionID == selectedDecision
                              )
                              .map((a, idx) => (
                                <tr key={idx}>
                                  <td>
                                    <Input
                                      type="checkbox"
                                      checked={a.isSelected}
                                      onClick={() => {
                                        changeAltProp(
                                          a,
                                          "isSelected",
                                          !a.isSelected
                                        )
                                      }}
                                    />
                                  </td>
                                  {/*                                   <td>
                                    {a.alternativeNum} - {a.alternativeName}
                                  </td> */}
                                  <td className="one-line-elipsis">
                                    <Tooltip
                                      title={
                                        <h6>
                                          {a.alternativeNum} -{" "}
                                          {a.alternativeName}
                                        </h6>
                                      }
                                    >
                                      <span>
                                        <b>
                                          {a.alternativeNum} -{" "}
                                          {a.alternativeName}
                                        </b>
                                      </span>
                                    </Tooltip>
                                  </td>
                                  <td>
                                    <div
                                      style={{ textAlign: "center" }}
                                      dangerouslySetInnerHTML={{
                                        __html: a.version,
                                      }}
                                    ></div>
                                  </td>
                                  <td>
                                    <Input
                                      id={a.alternativeID}
                                      name={a.alternativeID}
                                      type="date"
                                      onChange={e => {
                                        changeAltProp(
                                          a,
                                          "tempStartDate",
                                          moment(
                                            e.target.value,
                                            "YYYY-MM-DD"
                                          ).format("YYYY-MM-DDThh:mm:ss"),
                                          true
                                        )
                                      }}
                                      value={
                                        a.tempStartDate == null
                                          ? ""
                                          : moment(a.tempStartDate).format(
                                              "YYYY-MM-DD"
                                            )
                                      }
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </Col>
                    )}
                    {classesVisible && (
                      <Col md="2">
                        <div className="mb-3">
                          <Label style={{ marginBottom: "0px" }}>
                            Show Classes
                          </Label>
                          <select
                            className="form-control form-select select2 mb-xxl-0"
                            value={selectedResourceClass}
                            onChange={e => {
                              setSelectedResourceClass(e.target.value)
                            }}
                          >
                            <option value="0">All</option>
                            {resourceClasses.map((r, idx) => {
                              return (
                                <option key={idx} value={`${r.listItemID}`}>
                                  {r.listItemName}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                        <table
                          className="table table-bordered lower-padding-table"
                          style={{ backgroundColor: "white" }}
                        >
                          <thead>
                            <tr style={{ fontSize: "12px" }}>
                              <th>
                                <Input
                                  type="checkbox"
                                  checked={allClassesSelected}
                                  onClick={() => {
                                    let copy = [...resourceGroups]
                                    copy.forEach(c => {
                                      c.isSelected = !allClassesSelected
                                    })
                                    setResourceGroups(copy)
                                    setAllClassesSelected(!allClassesSelected)
                                  }}
                                />
                              </th>
                              <th>Resource</th>
                            </tr>
                          </thead>
                          <tbody>
                            {resourceGroups
                              .filter(
                                x =>
                                  selectedResourceClass <= 0 ||
                                  x.resourceClasses.find(
                                    rcl =>
                                      rcl.resourceClassID ==
                                      selectedResourceClass
                                  ) != undefined
                              )
                              .map((r, idx) => (
                                <tr key={idx}>
                                  <td>
                                    <Input
                                      type="checkbox"
                                      checked={r.isSelected}
                                      onClick={() => {
                                        changeResourceGroupProp(
                                          r,
                                          "isSelected",
                                          !r.isSelected
                                        )
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <b>{r.resourceGroupName}</b>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </Col>
                    )}
                    <Col
                      md={
                        alternativesVisible
                          ? classesVisible
                            ? "6"
                            : "9"
                          : classesVisible
                          ? "10"
                          : "12"
                      }
                    >
                      {isChartLoading && (
                        <Loader
                          type="line-scale-pulse-out"
                          color={colors.primary}
                          style={{ textAlign: "center" }}
                        />
                      )}
                      <div
                        id="chartdiv"
                        style={{
                          minHeight: "600px",
                          height: "100%",
                          width: "100%",
                        }}
                      ></div>
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ResourceCharts
