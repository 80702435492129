import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import React, { useEffect, useRef, useState } from "react"
import { createRoot } from "react-dom/client"
import Loader from "react-loaders"
import { ReactTabulator } from "react-tabulator"
import { toast } from "react-toastify"
import { Col, FormFeedback, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import colors from "utils/colors"
import { customFormatter } from "utils/tabulator"

const SimpleListSection = ({ enterpriseId, listName, listHeader }) => {
  const tabulatorRef = useRef()
  const [listItems, setListItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [newItemName, setNewItemName] = useState("")
  const [hasNameError, setHasNameError] = useState(false)

  useEffect(() => {
    if (enterpriseId > -1) {
      loadData()
    }
  }, [enterpriseId])

  const loadData = async () => {
    try {
      let list = await api.getEnterpriseList(enterpriseId, listName)
      setListItems(list)
    } catch (err) {
      //   setIsLoading(false)
    }
  }

  const addListItem = () => {
    setNewItemName("")
    setHasNameError(false)
    toggleModal()
  }

  const createListItem = async () => {
    if (newItemName == "") {
      setHasNameError(true)
    } else {
      await api.createEnterpriseListItem(enterpriseId, listName, {
        listItemName: newItemName,
      })
      toggleModal()
      await loadData()
    }
  }

  const updateListItem = async listItem => {
    try {
      await api.updateEnterpriseListItem(enterpriseId, listName, listItem)
      await loadData()
    } catch (err) {
      console.log(err)
    }
  }

  const deleteListItem = item => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this item?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          let ret = await api.deleteEnterpriseListItem(
            item.listItemID,
            listName,
            item
          )
          toast.success(
            ret == -1
              ? "Item deleted successfully"
              : "The item was inactivated because it is in use"
          )
          await loadData()
        }
      })
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const columns = [
    {
      title: listHeader,
      field: "listItemName",
      headerSort: false,
      editor: "input",
    },
    {
      title: "State",
      field: "inactive",
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getValue() == null || cell.getValue() == false
          ? "Active"
          : "Inactive"
      },
      width: 150,
      headerSort: false,
      editor: "select",
      editorParams: {
        values: { false: "Active", true: "Inactive" },
      },
    },
    {
      field: "",
      headerSort: false,
      width: 50,
      formatter: customFormatter(({ rowData, cellValue }) => (
        <i
          className="fas fa-trash-alt"
          style={{ color: "red" }}
          onClick={() => deleteListItem(rowData)}
        ></i>
      )),
    },
  ]

  return (
    <React.Fragment>
      <button
        onClick={() => addListItem()}
        className="btn btn-primary"
        style={{ marginBottom: "10px" }}
      >
        <i className="fas fa-plus"></i> Add {listHeader}
      </button>
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      <Row>
        <Col sm="8">
          {!isLoading && listItems.length > 0 && (
            <ReactTabulator
              onRef={r => (tabulatorRef.current = r.current)}
              columns={columns}
              data={listItems}
              options={{
                pagination: "local",
                paginationSize: 30,
                paginationSizeSelector: [5, 10, 20, 30, 50, 100, 200, 300],
                layout: "fitColumns",
                cssClass: "table-borderless",
              }}
              events={{
                cellEdited: function (cell) {
                  updateListItem(cell.getData())
                },
              }}
            />
          )}
        </Col>
      </Row>
      <Modal
        backdrop="static"
        isOpen={isModalOpen}
        size="md"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add {listHeader}
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <Label className="form-label">Name</Label>
            <Input
              name="name"
              type="text"
              value={newItemName}
              onChange={e => {
                setNewItemName(e.target.value)
                setHasNameError(false)
              }}
              invalid={hasNameError}
            />
            {hasNameError ? (
              <FormFeedback type="invalid">Name is required</FormFeedback>
            ) : null}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={createListItem}
          >
            Save
          </button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default SimpleListSection
