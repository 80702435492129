import React, { useEffect, useRef, useState } from "react"
import { Col, Container, Input, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { changeNavbarParams, loadPageItem } from "store/actions"
import * as ReactDOMClient from "react-dom/client"
import Loader from "react-loaders"
import colors from "utils/colors"
import useModal from "hooks/useModalHook"
import moment from "moment"
import { ReactTabulator } from "react-tabulator"
import { TextareaAutosize } from "@material-ui/core"
import { toast } from "react-toastify"
import { currencyFormatter } from "utils/formatters"
import {
  swalWithConfirmAndCancelButtons,
  swalWithConfirmButton,
} from "components/custom/swal"
import { customFormatter } from "utils/tabulator"

const EditPlan = props => {
  const scenarioId = props.match.params.scenarioId
  const planId = props.match.params.planId
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [isLoading, setIsLoading] = useState(false)
  const [plan, setPlan] = useState(null)
  const [objModelOptions, setObjModelOptions] = useState([])
  const [finModelOptions, setFinModelOptions] = useState([])
  const [alternatives, setAlternatives] = useState([])
  const [showExcluded, setShowExcluded] = useState(false)
  const [decisions, setDecisions] = useState([])

  var tabulatorRef = useRef(null)

  useEffect(() => {
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        viewName: "editPlan",
      })
    )
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "editPlan",
      })
    )
    loadData()
  }, [])

  useEffect(() => {
    loadPlan()
  }, [planId])

  const loadPlan = async () => {
    setIsLoading(true)

    if (planId == -1) {
      setPlan({
        planID: -1,
        planName: "",
        description: "",
        objModelID: -1,
        finModelID: -1,
        scenarioID: scenarioId,
      })
    } else {
      let p = await api.getPlan(planId)
      setPlan(p)
      await loadAlternatives(showExcluded)
    }

    setIsLoading(false)
  }

  const loadAlternatives = async showExcluded => {
    if (alternatives.find(x => x.isDirty)) {
      await saveAlternatives()
    }
    let a = await api.getAlternativesPlan(scenarioId, planId, false)

    setAlternatives(enrichWithRunningCostAndBenefitFields(a))
  }

  const enrichWithRunningCostAndBenefitFields = alts => {
    for (let i = 0; i < alts.length; i++) {
      if (i == 0) {
        alts[i].runningCost = alts[i].cost
        alts[i].runningBenefit = alts[i].saaScore3
      } else {
        let prev = alts[i - 1]
        alts[i].runningCost = prev.runningCost + alts[i].cost
        alts[i].runningBenefit = prev.runningBenefit + alts[i].saaScore3
      }
    }
    return alts
  }

  const loadData = () => {
    api.getObjModels(scenarioId).then(data => {
      setObjModelOptions([
        // { objModelID: 0, objModelName: scenario.scenarioName },
        ...data,
      ])
    })
    api.getFinModels(scenarioId).then(data => {
      setFinModelOptions(data)
    })
    api.getList(currentUser.userID, "Decisions").then(d => {
      setDecisions(d)
    })
  }

  const save = async () => {
    if (plan.planID == -1) {
      let newPlanId = await api.createPlan(currentUser.userID, plan)
      props.history.replace({
        pathname: `/editPlan/${scenarioId}/${newPlanId}`,
      })
    } else {
      await api.updatePlan(currentUser.userID, plan)
      await saveAlternatives()
    }
    toast.success("Plan saved")
  }

  const saveAlternatives = async () => {
    let altsToUpdate = alternatives.filter(x => x.isDirty)
    let tasks = altsToUpdate.map(x => async () => {
      return await api.addUpdatePlanAlternative(currentUser.userID, {
        planID: planId,
        alternativeID: x.alternativeID,
        exclude: x.exclude == undefined ? false : x.exclude,
        decisionID: x.decisionID,
        updateDT: moment().format("YYYY-MM-DDThh:mm:ss"),
        updateUserID: currentUser.userID,
      })
    })
    if (tasks.length > 0) {
      await Promise.all(tasks.map(t => t()))
    }
  }

  const changePlanProp = (prop, val) => setPlan({ ...plan, [prop]: val })

  const columns = [
    // {
    //   formatter: "rowSelection",
    //   titleFormatter: "rowSelection",
    //   hozAlign: "center",
    //   headerSort: false,
    //   cellClick: function (e, cell) {
    //     cell.getRow().toggleSelect()
    //   },
    //   width: 50,
    // },

    {
      title: "ID",
      field: "alternativeNum",
      width: 70,
    },
    {
      title: "Project",
      field: "alternative",
      minWidth: 250,
      formatter: (cell, formatterParams, onRendered) => {
        return cell.getValue()
      },
    },
    {
      title: "Version",
      field: "version",
      width: 100,
      formatter: (cell, formatterParams, onRendered) => {
        return cell.getValue()
      },
    },
    {
      title: "Benefit Score",
      field: "saaScore",
      width: 190,
      formatter: (cell, formatterParams, onRendered) => {
        return cell.getValue()
      },
    },
    {
      title: "Running Benefit",
      field: "runningBenefit",
      hozAlign: "end",
      width: 150,
      formatter: (cell, formatterParams, onRendered) => {
        return Math.round(cell.getValue(), 2)
      },
    },
    {
      title: "Rating",
      field: "saaRating",
      width: 100,
      formatter: (cell, formatterParams, onRendered) => {
        return cell.getValue()
      },
    },
    {
      title: "Cost",
      field: "cost",
      width: 120,
      hozAlign: "end",
      formatter: (cell, formatterParams, onRendered) => {
        return currencyFormatter.format(cell.getValue())
      },
    },
    {
      title: "Running Cost",
      field: "runningCost",
      hozAlign: "end",
      width: 160,
      formatter: (cell, formatterParams, onRendered) => {
        return currencyFormatter.format(cell.getValue())
      },
    },
    {
      title: "Dependencies",
      field: "dependencies",
      minWidth: 150,
    },
    {
      title: "Decision",
      field: "decisionID",
      minWidth: 200,
      formatter: customFormatter(({ rowData, cellValue }) => (
        <select
          className="form-control form-select select2 mb-xxl-0"
          value={cellValue}
          style={{ padding: "1px", fontSize: "12px", width: "150px" }}
          onChange={e => {
            let copy = [...alternatives]
            let item = copy.find(x => x.alternativeID == rowData.alternativeID)
            item.decisionID = e.target.value
            item.isDirty = true
            setAlternatives(copy)
          }}
        >
          {decisions.map((a, idx) => {
            return (
              <option key={idx} value={`${a.listItemID}`}>
                {a.listItemName}
              </option>
            )
          })}
        </select>
      ))
    },
  ]

  const deletePlan = () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this plan?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deletePlan(currentUser.userID, planId)
          props.history.goBack()
        }
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <button
            type="button"
            className="btn btn-primary always-enabled"
            style={{ marginRight: "10px" }}
            onClick={() => props.history.goBack()}
          >
            <i className="fas fa-chevron-left"></i>
            {"  "}
            Back to Plans
          </button>
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          {!isLoading && plan != null && (
            <>
              <Row className="mb-3">
                <Col>
                  <div className="d-flex-row ai-center" style={{ gap: "20px" }}>
                    <div className="mb-3">
                      <Label>Name</Label>
                      <Input
                        type="text"
                        onChange={e =>
                          changePlanProp("planName", e.target.value)
                        }
                        value={plan.planName}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Criteria Scenario</Label>
                      <select
                        className="form-control form-select select2 mb-xxl-0"
                        value={plan.objModelID || 0}
                        style={{ width: "250px" }}
                        onChange={e => {
                          changePlanProp("objModelID", e.target.value)
                        }}
                      >
                        <option value="-1">Select...</option>
                        {objModelOptions.map((a, idx) => {
                          return (
                            <option key={idx} value={`${a.objModelID}`}>
                              {a.objModelName}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Financial Scenario</Label>
                      <select
                        className="form-control form-select select2 mb-xxl-0"
                        value={plan.finModelID || 0}
                        style={{ width: "250px" }}
                        onChange={e => {
                          changePlanProp("finModelID", e.target.value)
                        }}
                      >
                        <option value="-1">Select...</option>
                        {finModelOptions.map((a, idx) => {
                          return (
                            <option key={idx} value={`${a.finModelID}`}>
                              {a.finModelName}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Description</Label>
                    <TextareaAutosize
                      minRows={2}
                      className="form-control"
                      placeholder="Description"
                      onChange={e =>
                        changePlanProp("description", e.target.value)
                      }
                      value={plan.description}
                    />
                  </div>
                  <div className="d-flex-row jc-space-between ai-center">
                    <button className="btn btn-primary" onClick={save}>
                      Save
                    </button>
                    {planId > 0 && (
                      <button className="btn btn-danger" onClick={deletePlan}>
                        Delete
                      </button>
                    )}
                  </div>
                </Col>
              </Row>
              {alternatives.length > 0 && (
                <>
                  {/*                   <Row>
                    <Col>
                      <div>
                        <Input
                          id="cbshowExcluded"
                          type="checkbox"
                          className="form-check-input"
                          checked={showExcluded}
                          onClick={() => {
                            let newVal = !showExcluded
                            setShowExcluded(newVal)
                            loadAlternatives(newVal)
                          }}
                        />
                        <Label
                          for="cbshowExcluded"
                          className="form-check-label"
                          style={{ marginLeft: "10px" }}
                        >
                          Show Excluded
                        </Label>
                      </div>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col>
                      <ReactTabulator
                        onRef={r => (tabulatorRef.current = r.current)}
                        columns={columns}
                        data={alternatives}
                        options={{
                          pagination: "local",
                          paginationSize: 30,
                          paginationSizeSelector: [
                            5, 10, 20, 30, 50, 100, 200, 300,
                          ],
                          layout: "fitColumns",
                          cssClass: "table-borderless",
                        }}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditPlan
