import Swal from "sweetalert2"
import 'animate.css'

export const swalWithConfirmAndCancelButtons = Swal.mixin({
  allowOutsideClick: false,
  customClass: {
    confirmButton: "btn btn-primary save-user",
    cancelButton: "btn btn-outline-secondary",
  },
  buttonsStyling: false,
  showClass: {
    popup: 'animate__animated animate__fadeInDown'
  },
  hideClass: {
    popup: 'animate__animated animate__fadeOutUp'
  }
})

export const swalWith3Buttons = Swal.mixin({
  allowOutsideClick: false,
  customClass: {
    confirmButton: "btn btn-primary button-swal-3-buttons",
    cancelButton: "btn btn-outline-secondary button-swal-3-buttons",
    denyButton: "btn btn-outline-secondary button-swal-3-buttons",
  },
  buttonsStyling: false,
  showClass: {
    popup: 'animate__animated animate__fadeInDown'
  },
  hideClass: {
    popup: 'animate__animated animate__fadeOutUp'
  }
})

export const swalWithConfirmButton = Swal.mixin({
  allowOutsideClick: false,
  customClass: {
    confirmButton: "btn btn-primary",
  },
  buttonsStyling: false,
  showClass: {
    popup: 'animate__animated animate__fadeInDown'
  },
  hideClass: {
    popup: 'animate__animated animate__fadeOutUp'
  }
})
