import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const ChartDrilldownModal = ({ isOpen, toggleModal, dataTable }) => {
  const currentUser = userService.getLoggedInUser()
  const [file, setFile] = useState(null)

  useEffect(() => {
    if (isOpen) {
    }
  }, [isOpen])

  const save = async () => {}

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Drilldown
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          {dataTable != undefined && dataTable != null && dataTable.length > 0 && (
            <table
              className="table table-bordered"
              style={{ backgroundColor: "white" }}
            >
              <thead>
                <tr>
                  {Object.keys(dataTable[0]).map((t, idx) => (
                    <th key={idx}>{t.charAt(0).toUpperCase() + t.slice(1)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dataTable.map((t, idx) => (
                  <tr key={idx}>
                    {Object.keys(t).map((tt, idx2) => (
                      <td key={idx2}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t[tt],
                          }}
                        ></div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
        {/* <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button> */}
      </div>
    </Modal>
  )
}

export default ChartDrilldownModal
