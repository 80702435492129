import { CHANGE_RESOURCEBUDGETING } from "./actionTypes"

const INIT_STATE = {
  resourceBudgeting:
    localStorage.getItem("resourceBudgetingDefaults") != null
      ? JSON.parse(localStorage.getItem("resourceBudgetingDefaults"))
      : {
          yearOption: 0,
          showOption: 0,
          showExcluded: false,
          alternativeFilter: "",
          businessCaseFilter: "",
          fyPlannedFilter: "",
          pmFilter: "",
          projectCategoryFilter: "",
        },
}

const resourceBudgeting = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_RESOURCEBUDGETING:
      localStorage.removeItem("resourceBudgetingDefaults")
      localStorage.setItem(
        "resourceBudgetingDefaults",
        JSON.stringify({ ...state.resourceBudgeting, ...action.payload })
      )
      
      return {
        ...state,
        resourceBudgeting: { ...state.resourceBudgeting, ...action.payload },
      }

    default:
      return state
  }
}

export default resourceBudgeting
