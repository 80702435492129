import React, { useEffect, useState } from "react"
import { Container, Input, Label, Row } from "reactstrap"
import api from "services/api.service"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Tree, TreeNode } from "react-organizational-chart"
import colors from "utils/colors"
import Loader from "react-loaders"
import { userService } from "services/user.service"
import { useDispatch, useSelector } from "react-redux"
import { loadPageItem } from "store/actions"
import useFetcher from "hooks/useFetcher"

const PwcHierarchy = props => {
  const scenarioId = props.match.params.scenarioId
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  let [objectives, loadingObjectives] = useFetcher(
    () => api.getObjectives(scenarioId),
    []
  )
  let [scenario, loadingScenario] = useFetcher(
    () => api.getScenario(scenarioId),
    null
  )
  let [showPercentage, setShowPercentage] = useState(false)
  let [showGlobal, setShowGlobal] = useState(false)

  const isLoading = loadingScenario || loadingScenario

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "pwcHierarchy",
      })
    )
  }, [])

  const style = {
    padding: "10px",
    borderRadius: "8px",
    display: "inline-block",
    border: "1px solid grey",
    boxShadow: "3px 3px 0px 0px grey",
    // backgroundColor: "#FCF4A3",
    maxWidth: "150px",
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Row>
            <div>
              <div className="form-check">
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="customCheck5"
                  defaultChecked={showPercentage}
                  onChange={e => {
                    setShowPercentage(!showPercentage)
                  }}
                />
                <Label className="form-check-label" for="customCheck5">
                  Show Weighting Factors
                </Label>
              </div>
            </div>
            {showPercentage && (
              <div>
                <div className="form-check">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="globalCheck"
                    defaultChecked={showGlobal}
                    onChange={e => {
                      setShowGlobal(!showGlobal)
                    }}
                  />
                  <Label className="form-check-label" for="globalCheck">
                    Show Global Weights
                  </Label>
                </div>
              </div>
            )}
          </Row>
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          {!isLoading && scenario != null && (
            <Row
              style={{
                overflowY: "auto",
                marginBottom: "30px",
              }}
            >
              <div style={{ marginBottom: "110px" }}>
                <Tree
                  lineWidth={"2px"}
                  lineColor={colors.primary}
                  lineBorderRadius={"10px"}
                  label={
                    <div
                      style={{
                        ...style,
                        maxWidth: "650px",
                        width: "650px",
                        backgroundColor: "#FFFFCC",
                      }}
                    >
                      <h5>
                        {" "}
                        <u>Decision goal</u>
                      </h5>
                      <span> {scenario.decisionGoal}</span>
                    </div>
                  }
                >
                  {objectives.length > 0 &&
                    objectives.map((o, idx) => {
                      if (o.measures && o.measures.length > 0) {
                        return (
                          <TreeNode
                            key={idx}
                            label={
                              <div
                                style={{ ...style, backgroundColor: "#CCFFCC" }}
                              >
                                {showPercentage && (
                                  <p
                                    style={{
                                      marginBottom: "2px",
                                      color: "red",
                                    }}
                                  >
                                    <b>{o.evFinal.toFixed(2)}%</b>
                                  </p>
                                )}
                                <span> {o.objectiveName}</span>
                              </div>
                            }
                          >
                            {o.measures.map((m, idx) => {
                              return (
                                <TreeNode
                                  key={idx}
                                  label={
                                    <div
                                      style={{
                                        ...style,
                                        backgroundColor: "#CCECFF",
                                      }}
                                    >
                                      {showPercentage && !showGlobal && (
                                        <p
                                          style={{
                                            marginBottom: "2px",
                                            color: "red",
                                          }}
                                        >
                                          <b>
                                            {m.measureFinalPerc.toFixed(2)}%
                                          </b>
                                        </p>
                                      )}
                                      {showGlobal && (
                                        <p
                                          style={{
                                            marginBottom: "2px",
                                            color: "red",
                                          }}
                                        >
                                          <b>
                                            {(
                                              (parseFloat(m.measureFinalPerc) /
                                                100.0) *
                                              (parseFloat(o.evFinal) / 100.0) *
                                              100.0
                                            ).toFixed(2)}
                                            %
                                          </b>
                                        </p>
                                      )}
                                      <span>{m.measureName}</span>
                                    </div>
                                  }
                                />
                              )
                            })}
                          </TreeNode>
                        )
                      } else {
                        return (
                          <TreeNode
                            key={idx}
                            label={
                              <div
                                style={{ ...style, backgroundColor: "#CCFFCC" }}
                              >
                                {showPercentage && (
                                  <p
                                    style={{
                                      marginBottom: "2px",
                                      color: "red",
                                    }}
                                  >
                                    <b>{o.evFinal.toFixed(2)}%</b>
                                  </p>
                                )}
                                <span> {o.objectiveName}</span>
                              </div>
                            }
                          />
                        )
                      }
                    })}
                </Tree>
              </div>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PwcHierarchy
