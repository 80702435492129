import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap"
import { userService } from "services/user.service"
import { changeNavbarParams, loadPageItem } from "store/actions"
import api from "services/api.service"
import colors from "utils/colors"
import Loader from "react-loaders"
import EditEntityModal from "./EditEntityModal"
import EditDocumentsModal from "./EditDocumentsModal"
import UploadDocumentModal from "./UploadDocumentModal"
import AddEntityModal from "./AddEntityModal"
import EntityReportModal from "./EntityReportModal"

const Entities = props => {
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [entityTypes, setEntityTypes] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedEntityType, setSelectedEntityType] = useState(0)
  const [entities, setEntities] = useState([])

  const [showEditModal, setShowEditModal] = useState(false)
  const toggleEditModal = () => setShowEditModal(!showEditModal)
  const [entityToEdit, setEntityToEdit] = useState(null)
  const [showDocumentsModal, setShowDocumentsModal] = useState(false)
  const toggleDocumentsModal = () => setShowDocumentsModal(!showDocumentsModal)
  const [showAddEntityModal, setShowAddEntityModal] = useState(false)
  const toggleAddEntityModal = () => setShowAddEntityModal(!showAddEntityModal)
  const [showReportModal, setShowReportModal] = useState(false)
  const toggleReportModal = () => setShowReportModal(!showReportModal)
  const [search, setSearch] = useState("")

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: 0,
        alternativeId: 0,
        viewName: "entities",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: 0,
        viewName: "entities",
      })
    )
    loadData()
  }, [])

  const loadData = async () => {
    let t = await api.getEnterpriseList(currentUser.enterpriseID, "EntityType")
    setEntityTypes(t)
    if (t.length > 0) {
      setSelectedEntityType(t[0].listItemID)
      await loadEntities(t[0].listItemID)
    }
  }

  const loadEntities = async entityTypeId => {
    let e = await api.getEntities(currentUser.userID, entityTypeId)
    setEntities(e)
  }

  const onDocumentsClick = (event, entity) => {
    event && event.preventDefault()
    setEntityToEdit(entity)
    toggleDocumentsModal()
  }

  const onEntityClick = (event, entity) => {
    event && event.preventDefault()
    setEntityToEdit(entity)
    toggleEditModal()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Card>
            <CardBody>
              <Row className="mb-3">
                <Col>
                  {selectedEntityType == "2" && (
                    <button
                      className="btn btn-primary"
                      style={{ marginRight: "10px" }}
                      onClick={toggleAddEntityModal}
                    >
                      + Add Building
                    </button>
                  )}
                  <button
                    className="btn btn-primary"
                    onClick={toggleReportModal}
                  >
                    Run Reports
                  </button>
                </Col>
              </Row>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              {!isLoading && (
                <>
                  <div className="mb-3">
                    <Label className="form-label">Entity Type</Label>
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      value={selectedEntityType}
                      onChange={e => {
                        setSelectedEntityType(e.target.value)
                        loadEntities(e.target.value)
                      }}
                    >
                      {entityTypes.map((x, idx) => (
                        <option key={idx} value={x.listItemID}>
                          {x.listItemName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <table
                    className="table table-bordered low-padding-table"
                    style={{ backgroundColor: "white" }}
                  >
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Documents</th>
                        <th>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            Name{" "}
                            <InputGroup
                              style={{ maxWidth: "250px", marginLeft: "20px" }}
                            >
                              <InputGroupText>
                                <i className="fas fa-search"></i>
                              </InputGroupText>
                              <Input
                                placeholder="Search ..."
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                              />
                            </InputGroup>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {entities
                        .filter(
                          x =>
                            search == "" ||
                            x.entityName
                              .toLowerCase()
                              .indexOf(search.toLowerCase()) > -1
                        )
                        .map((e, idx) => (
                          <tr key={idx}>
                            <td>
                              {
                                entityTypes.find(
                                  x => x.listItemID == e.entityTypeID
                                ).listItemName
                              }
                            </td>
                            <td>
                              <a
                                href="#"
                                onClick={event => onDocumentsClick(event, e)}
                              >
                                ({e.numDocs}) Documents
                              </a>
                            </td>
                            <td>
                              <a
                                href="#"
                                onClick={event => onEntityClick(event, e)}
                              >
                                {e.entityName}
                              </a>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <EditEntityModal
        isOpen={showEditModal}
        toggleModal={toggleEditModal}
        entityToEdit={entityToEdit}
        setEntityToEdit={setEntityToEdit}
        reloadData={loadEntities}
      />
      <EditDocumentsModal
        isOpen={showDocumentsModal}
        toggleModal={toggleDocumentsModal}
        entityToEdit={entityToEdit}
      />
      <AddEntityModal
        isOpen={showAddEntityModal}
        toggleModal={toggleAddEntityModal}
        entityTypeId={selectedEntityType}
        reloadData={() => loadEntities(selectedEntityType)}
      />
      <EntityReportModal
        isOpen={showReportModal}
        toggleModal={toggleReportModal}
      />
    </React.Fragment>
  )
}

export default Entities
