import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import useModal from "hooks/useModalHook"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row, Alert } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import { ReactTabulator } from "react-tabulator"
import { createRoot } from "react-dom/client"

const EditWorkflowStepModal = ({
  isOpen,
  toggleModal,
  workflowStepToEdit,
  setWorkflowStepToEdit,
  taskStatuses,
  taskTypes,
  workflowId,
  workflowType,
  reload,
  workflowSteps,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [taskEditRules, setTaskEditRules] = useState([])
  const [usersAndRoles, setUsersAndRoles] = useState([])
  const [workflowActions, setWorkflowActions] = useState([])

  const [taskStatusWorkflowActions, setTaskStatusWorkflowActions] = useState([])
  const [workflowStatusActionOptions, setWorkflowStatusActionOptions] =
    useState([])

  useEffect(() => {
    api.getList(currentUser.userID, "TaskEditRule").then(r => {
      setTaskEditRules(r)
    })
    api
      .getTaskTypeGeneralListItems(
        currentUser.enterpriseID,
        0,
        "UsersPlusRoles"
      )
      .then(r => {
        setUsersAndRoles(r)
      })
    api.getList(currentUser.userID, "WorkflowStatusAction").then(s => {
      setWorkflowStatusActionOptions(s)
    })
  }, [])

  useEffect(() => {
    if (isOpen) {
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    if (workflowStepToEdit.workflowTaskTypeID == -1) {
      let taskStatusesAndWorkflowActions = taskStatuses.map(t => {
        t.workflowActionID = -1
        t.triggerStatusID = t.listItemID
        t.workflowActionType = -1
        return t
      })
      setTaskStatusWorkflowActions(taskStatusesAndWorkflowActions)
    } else {
      let actions = await api.getWorkflowActions(
        workflowStepToEdit.workflowTaskTypeID
      )
      let taskStatusesAndWorkflowActions = taskStatuses.map(t => {
        let action = actions.find(x => x.triggerStatusID == t.listItemID)
        t.workflowActionID = action !== undefined ? action.workflowActionID : -1
        t.triggerStatusID = t.listItemID
        t.workflowActionType =
          action !== undefined ? action.workflowActionType : -1
        return t
      })
      setTaskStatusWorkflowActions(taskStatusesAndWorkflowActions)
    }
  }

  const changeWorkflowStepProp = (prop, val) =>
    setWorkflowStepToEdit({ ...workflowStepToEdit, [prop]: val })

  const save = async () => {
    let workflowTaskTypeId = ""
    if (workflowStepToEdit.workflowTaskTypeID == -1) {
      workflowTaskTypeId = await api.createWorkflowTaskType(
        currentUser.userID,
        {
          ...workflowStepToEdit,
          workflowID: workflowId,
        }
      )
    } else {
      workflowTaskTypeId = workflowStepToEdit.workflowTaskTypeID
      await api.updateWorkflowTaskType(currentUser.userID, {
        ...workflowStepToEdit,
        workflowID: workflowId,
      })
    }

    let newWorkflowActions = taskStatusWorkflowActions
      .filter(x => x.workflowActionID == -1 && x.isDirty)
      .map(x => async () => {
        return await api.createWorkflowAction(currentUser.userID, {
          ...x,
          workflowTaskTypeID: workflowTaskTypeId,
        })
      })
    let updatedWorkflowActions = taskStatusWorkflowActions
      .filter(
        x =>
          x.workflowActionID !== -1 && x.isDirty && x.workflowActionType !== -1
      )
      .map(x => async () => {
        return await api.updateWorkflowAction(currentUser.userID, {
          ...x,
          workflowTaskTypeID: workflowTaskTypeId,
        })
      })
    let deletedWorkflowActions = taskStatusWorkflowActions
      .filter(
        x =>
          x.workflowActionID !== -1 && x.isDirty && x.workflowActionType == -1
      )
      .map(x => async () => {
        return await api.deleteWorkflowAction(
          currentUser.userID,
          x.workflowActionID
        )
      })
    let tasks = [
      ...newWorkflowActions,
      ...updatedWorkflowActions,
      ...deletedWorkflowActions,
    ].filter(x => x != undefined && x != null)

    if (tasks.length > 0) {
      await Promise.all(tasks.map(t => t()))
    }

    reload && reload()
    toggleModal()
    // onSave()
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        size="lg"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Edit Workflow Step
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {workflowStepToEdit != null && (
          <div className="modal-body">

<Row>
              <Alert
                id="helpView"
                variant="light"
                className="always-enabled"
                style={{ marginBottom: "10px", paddingBottom: "0px" }}
              >
                <div>
                  Give the step a name and provide the details of how the step should behave.
                  <ol>
                    <li>
                      "Starting Status" is the status the step should be set to when it is triggered by the workflow.
                    </li>
                    <li>
                      "Due Days" this sets the due date this many days after the workflow is trriggered.
                    </li>
                    <li>
                      "Locking Rule" If the item this workflow pertains to is in this step of the workflow, the locking rule determines who can edit it.
                    </li>      
                    <li>
                      "Owner" Select the role or person that is responsible for this step.
                    </li> 
                    <li>
                      "Predecessor" Which step is before this one in the workflow. The first step as no predecessor.
                    </li>      
                    <li>
                      When the step is set to a certain status, it can trigger actions such as moving to the next step.  use the status table to select the action that shoudl occur during each status.
                    </li>                      
                  </ol>
                </div>
              </Alert>
            </Row>
            <Row>
              <Col md="6" >
              
              <div className="mb-3">
              <Label className="form-label">Name</Label>
              <Input
                type="text"
                onChange={e =>
                  changeWorkflowStepProp("taskName", e.target.value)
                }
                value={workflowStepToEdit.taskName}
              />
            </div>

            <div className="mb-3">
              <Label className="form-label">Due Days</Label>
              <Input
                type="text"
                onChange={e =>
                  changeWorkflowStepProp("dueDays", e.target.value)
                }
                value={workflowStepToEdit.dueDays}
              />
            </div>

              </Col>

              <Col md="6" >

              <div className="mb-3">
              <Label className="form-label">Starting Status</Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={workflowStepToEdit.startStatusID}
                onChange={e => {
                  changeWorkflowStepProp("startStatusID", e.target.value)
                }}
              >
                <option value="-1">Select status...</option>
                {taskStatuses.map((s, i) => (
                  <option key={i} value={s.listItemID}>
                    {s.listItemName}
                  </option>
                ))}
              </select>
            </div>

            {workflowType == "Task List" && (
              <div className="mb-3">
                <Label className="form-label">Start Days</Label>
                <Input
                  type="text"
                  onChange={e =>
                    changeWorkflowStepProp("startDTDays", e.target.value)
                  }
                  value={workflowStepToEdit.startDTDays}
                />
              </div>
            )}
              
              </Col>
            </Row>





            {workflowType == "Workflow" && (
              <>
              <Row>
<Col md="6">
    <div className="mb-3">
                      <Label className="form-label">Locking Rule</Label>
                      <select
                        className="form-control form-select select2 mb-xxl-0"
                        value={workflowStepToEdit.scopeLockRule}
                        onChange={e => {
                          changeWorkflowStepProp("scopeLockRule", e.target.value)
                        }}
                      >
                        <option value="-1">Select rule...</option>
                        {taskEditRules.map((s, i) => (
                          <option key={i} value={s.listItemID}>
                            {s.listItemName}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mb-3">
                  <Label className="form-label">Predecessor</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={workflowStepToEdit.predID}
                    onChange={e => {
                      changeWorkflowStepProp("predID", e.target.value)
                    }}
                  >
                    <option value="-1">Select predecessor...</option>
                    {workflowSteps.map((s, i) => (
                      <option key={i} value={s.workflowTaskTypeID}>
                        {s.taskName}
                      </option>
                    ))}
                  </select>
                </div>
    </Col>

<Col md="6">


<div className="mb-3">
                  <Label className="form-label">Owner</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={workflowStepToEdit.startOwnerID}
                    onChange={e => {
                      changeWorkflowStepProp("startOwnerID", e.target.value)
                    }}
                  >
                    <option value="-1">Select owner...</option>
                    {usersAndRoles.map((s, i) => (
                      <option key={i} value={s.listItemID}>
                        {s.listItemName}
                      </option>
                    ))}
                  </select>
                </div>
</Col>

<div className="mb-3">
                  <table
                    className="table table-bordered low-padding-table"
                    style={{ backgroundColor: "white" }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "50%" }}>Status</th>
                        <th style={{ width: "50%" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {taskStatusWorkflowActions.map((t, idx) => (
                        <tr key={idx}>
                          <td>{t.listItemName}</td>
                          <td>
                            <select
                              className="form-control form-select select2 mb-xxl-0"
                              value={t.workflowActionType}
                              onChange={e => {
                                let copy = [...taskStatusWorkflowActions]
                                let item = copy.find(
                                  x => x.listItemID == t.listItemID
                                )
                                item.workflowActionType = e.target.value
                                item.isDirty = true
                                setTaskStatusWorkflowActions(copy)
                              }}
                            >
                              <option value="-1">Select action...</option>
                              {workflowStatusActionOptions.map((s, i) => (
                                <option key={i} value={s.listItemName}>
                                  {s.listItemName}
                                </option>
                              ))}
                            </select>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

              </Row>    
              </>
            )}
          </div>
        )}
        <div
          className="modal-footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-primary" onClick={save}>
            Save
          </button>
        </div>
      </Modal>
    </>
  )
}

export default EditWorkflowStepModal
