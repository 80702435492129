import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import { getSum } from "pages/PwcResults/EditPreferenceWeightsModal"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const EditObjModelModal = ({
  isOpen,
  toggleModal,
  objModelId,
  scenarioId,
  reloadData,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [objModel, setObjModel] = useState(null)
  const [objectives, setObjectives] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isOpen && objModelId != undefined && objModelId != null) {
      loadData()
    }
  }, [isOpen, objModelId])

  const loadData = async () => {
    setIsLoading(true)
    let o = await api.getObjectives(scenarioId)
    let om =
      objModelId == -1
        ? {
            objModelWeights: [],
            objModelID: -1,
            objModelName: "",
            scenarioID: scenarioId,
          }
        : await api.getObjModel(objModelId)
    setObjModel(om)
    setObjectives(
      objModelId == -1
        ? o
        : o.map(obj => {
            let w = om.objModelWeights.find(
              x => x.objMeasID == obj.objectiveID && x.level == 1
            )
            obj.scenarioWeight = w == undefined ? 0 : w.ev
            if (obj.measures.length > 0) {
              obj.measures = obj.measures.map(m => {
                let mw = om.objModelWeights.find(
                  x => x.objMeasID == m.measureID && x.level == 2
                )
                m.scenarioWeight = mw == undefined ? 0 : mw.ev
                return m
              })
            }
            return obj
          })
    )
    setIsLoading(false)
  }

  const changeObjectiveProp = (prop, value, objectiveId) => {
    let copy = [...objectives]
    let item = copy.find(x => x.objectiveID == objectiveId)
    item[prop] = value
    item.isDirty = true
    setObjectives(copy)
  }

  const changeMeasureProp = (prop, value, measureId) => {
    let copy = [...objectives]
    let item = copy.find(
      x => x.measures.find(m => m.measureID == measureId) != undefined
    )
    let measure = item.measures.find(x => x.measureID == measureId)
    measure[prop] = value
    measure.isDirty = true
    setObjectives(copy)
  }

  const save = async () => {
    let objModelWeights = []
    objectives.forEach(o => {
      if (o.isDirty) {
        objModelWeights.push({
          ev: o.scenarioWeight,
          objModelID: objModel.objModelID,
          objMeasID: o.objectiveID,
          level: 1
        })
      }
      o.measures.forEach(m => {
        if (m.isDirty) {
          objModelWeights.push({
            ev: m.scenarioWeight,
            objModelID: objModel.objModelID,
            objMeasID: m.measureID,
            level: 2
          })
        }
      })
    })
    if (objModel.objModelID > -1) {
      await api.updateObjModel(currentUser.userID, {
        ...objModel,
        objModelWeights: objModelWeights,
      })
      toast.success("Model updated")
    } else {
      await api.createObjModel(currentUser.userID, {
        ...objModel,
        objModelWeights: objModelWeights,
      })
      toast.success("Model created")
    }
    reloadData()
    toggleModal()
  }

  const deleteObjModel = () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this model?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteObjModel(currentUser.userID, objModelId)
          toast.success("Model deleted")
          reloadData()
          toggleModal()
        }
      })
  }

  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Scenario
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      {!isLoading && objModel != null && (
        <div className="modal-body">
          <div className="mb-3">
            <Label className="form-label">Scenario</Label>
            <Input
              name="note"
              type="text"
              onChange={e =>
                setObjModel({
                  ...objModel,
                  objModelName: e.target.value,
                })
              }
              value={objModel.objModelName}
            />
          </div>
          <table
            className="table table-bordered low-padding-table"
            style={{ backgroundColor: "white" }}
          >
            <thead>
              <tr>
                <th style={{ width: "50%" }}>Criteria</th>
                <th style={{ width: "25%" }}>AHP Weights</th>
                <th style={{ width: "25%" }}>Scenario Weights</th>
              </tr>
            </thead>
            <tbody>
              {objectives.map((o, idx) => (
                <>
                  <tr key={idx}>
                    <td>{o.objectiveName}</td>
                    <td className="text-right">{o.evFinal}</td>
                    <td>
                      <CurrencyInput
                        className="form-control align-end"
                        style={{ padding: "1px", fontSize: "12px" }}
                        value={o.scenarioWeight}
                        onValueChange={(value, name, values) => {
                          changeObjectiveProp(
                            "scenarioWeight",
                            value,
                            o.objectiveID
                          )
                        }}
                        prefix={""}
                        decimalScale={2}
                        decimalsLimit={2}
                      />
                    </td>
                  </tr>

                  {o.measures.length > 0 && (
                    <tr>
                      <td colSpan={3} style={{ padding: "0px" }}>
                        <table
                          className="table table-bordered low-padding-table"
                          style={{ backgroundColor: "white" }}
                        >
                          <thead>
                            <tr>
                              <th style={{ width: "50%" }}>Measure</th>
                              <th style={{ width: "25%" }}>AHP Weights</th>
                              <th style={{ width: "25%" }}>Scenario Weights</th>
                            </tr>
                          </thead>
                          <tbody>
                            {o.measures.map((m, idx2) => (
                              <tr key={idx2}>
                                <td>{m.measureName}</td>
                                <td className="text-right">
                                  {m.measureFinalPerc}
                                </td>
                                <td>
                                  <CurrencyInput
                                    className="form-control align-end"
                                    style={{ padding: "1px", fontSize: "12px" }}
                                    value={m.scenarioWeight}
                                    onValueChange={(value, name, values) => {
                                      changeMeasureProp(
                                        "scenarioWeight",
                                        value,
                                        m.measureID
                                      )
                                    }}
                                    prefix={""}
                                    decimalScale={2}
                                    decimalsLimit={2}
                                  />
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td
                                colSpan={2}
                                className="text-right"
                                style={{ fontWeight: "bold" }}
                              >
                                {getSum(o.measures, "measureFinalPerc")}
                              </td>
                              <td
                                className="text-right"
                                style={{ fontWeight: "bold" }}
                              >
                                {getSum(o.measures, "scenarioWeight")}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </>
              ))}
              <tr>
                <td
                  colSpan={2}
                  className="text-right"
                  style={{ fontWeight: "bold" }}
                >
                  {getSum(objectives, "evFinal")}
                </td>
                <td className="text-right" style={{ fontWeight: "bold" }}>
                  {getSum(objectives, "scenarioWeight")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>
          {objModelId > -1 && (
            <button
              className="btn btn-outline-danger save-user"
              onClick={deleteObjModel}
            >
              Delete
            </button>
          )}
        </div>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          {objModelId > -1
            ? "Save Scenario Weights"
            : "Create Model and Save Weights"}
        </button>
      </div>
    </Modal>
  )
}

export default EditObjModelModal
