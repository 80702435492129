import React, { useEffect, useState } from "react"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import { ChromePicker } from "react-color"
import api from "services/api.service"
import { userService } from "services/user.service"
import Loader from "react-loaders"
import colors from "utils/colors"
import useModal from "hooks/useModalHook"
import { TextareaAutosize, Tooltip } from "@material-ui/core"
import { toast } from "react-toastify"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import { currencyFormatter } from "utils/formatters"
import CurrencyInput from "react-currency-input-field"

const FundsSection = ({ activeTab, enterprise }) => {
  const currentUser = userService.getLoggedInUser()
  const [funds, setFunds] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { show: showFundModal, toggle: toggleFundModal } = useModal()
  const [fundToEdit, setFundToEdit] = useState(null)

  useEffect(() => {
    if (activeTab == "9") {
      loadData()
    }
  }, [activeTab])

  const loadData = async () => {
    setIsLoading(true)
    let f = await api.getFunds(enterprise.enterpriseID)
    setFunds(f)
    setIsLoading(false)
  }

  const addFund = () => {
    setFundToEdit({ fundID: -1, enterpriseID: enterprise.enterpriseID })
    toggleFundModal()
  }

  const changeFundToEditProp = (prop, value) => {
    setFundToEdit({ ...fundToEdit, [prop]: value })
  }

  const saveFund = async () => {
    if (fundToEdit.fundName != "") {
      if (fundToEdit.fundID > -1) {
        await api.updateFund(currentUser.userID, fundToEdit)
      } else {
        await api.createFund(currentUser.userID, fundToEdit)
      }
      toggleFundModal()
      loadData()
    } else {
      toast.error("Fund name cannot be empty")
    }
  }

  const deleteFund = async fund => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this fund?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          let ret = await api.deleteFund(fundToEdit.fundID)
          toast.success("Fund deleted successfully")
          toggleFundModal()
          await loadData()
        }
      })
  }

  return (
    <React.Fragment>
      <button
        onClick={() => addFund()}
        className="btn btn-primary"
        style={{ marginBottom: "10px" }}
      >
        <i className="fas fa-plus"></i> Add Fund
      </button>
      {enterprise != null && (
        <Row>
          <Col sm="12">
            {isLoading && (
              <Loader
                type="line-scale-pulse-out"
                color={colors.primary}
                style={{ textAlign: "center" }}
              />
            )}
            {!isLoading && (
              <table
                className="table table-bordered low-padding-table"
                style={{ backgroundColor: "white" }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "25%" }}>Fund Name</th>
                    <th style={{ width: "25%" }}>Fund Abb</th>
                    <th style={{ width: "20%" }}>Description</th>
                    <th style={{ width: "20%" }}>Amount</th>
                    <th style={{ width: "10%" }}>Inactive</th>
                  </tr>
                </thead>
                <tbody>
                  {funds.map((p, idx) => (
                    <tr key={idx}>
                      <td>
                        <a
                          href="#"
                          onClick={e => {
                            e && e.preventDefault()
                            setFundToEdit(p)
                            toggleFundModal()
                          }}
                        >
                          {p.fundName}
                        </a>
                      </td>

                      <td>{p.fundAbb}</td>
                      <td className="one-line-elipsis">
                        <Tooltip title={<h6>{p.fundDescription}</h6>}>
                          <span>{p.fundDescription}</span>
                        </Tooltip>
                      </td>
                      <td>{currencyFormatter.format(p.fundAmount)}</td>
                      <td>{p.inactive ? "Yes" : "No"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Col>
        </Row>
      )}

      <Modal
        backdrop="static"
        isOpen={showFundModal}
        size="md"
        toggle={() => {
          toggleFundModal()
        }}
      >
        {fundToEdit != null && (
          <>
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Fund
              </h5>
              <button
                type="button"
                onClick={() => {
                  toggleFundModal()
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="mb-3">
                <Label className="form-label">Fund Name</Label>
                <Input
                  type="text"
                  onChange={e =>
                    changeFundToEditProp("fundName", e.target.value)
                  }
                  value={fundToEdit.fundName}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Fund Abb</Label>
                <Input
                  type="text"
                  onChange={e =>
                    changeFundToEditProp("fundAbb", e.target.value)
                  }
                  value={fundToEdit.fundAbb}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Description</Label>
                <TextareaAutosize
                  minRows={2}
                  className="form-control"
                  placeholder="Description"
                  onChange={e =>
                    changeFundToEditProp("fundDescription", e.target.value)
                  }
                  value={fundToEdit.fundDescription}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Amount</Label>
                <CurrencyInput
                  className="form-control"
                  value={fundToEdit.fundAmount}
                  onValueChange={(value, name, values) => {
                    setFundToEdit({
                      ...fundToEdit,
                      fundAmount: value,
                    })
                  }}
                  prefix="$"
                  suffix=""
                  decimalScale={0}
                  decimalsLimit={0}
                />
              </div>
              <div className="form-check" style={{ marginLeft: "10px" }}>
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="customCheck3"
                  checked={fundToEdit.inactive}
                  onClick={e => {
                    changeFundToEditProp("inactive", !fundToEdit.inactive)
                  }}
                />
                <Label className="form-check-label" for="customCheck3">
                  Inactive
                </Label>
              </div>
            </div>
            <div
              className="modal-footer"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={toggleFundModal}
              >
                Cancel
              </button>
              <div>
                {fundToEdit.fundID > -1 && (
                  <button
                    type="button"
                    className="btn btn-danger save-user"
                    onClick={() => deleteFund(fundToEdit)}
                  >
                    Delete
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-primary save-user"
                  onClick={saveFund}
                >
                  Save
                </button>
              </div>
            </div>
          </>
        )}
      </Modal>
    </React.Fragment>
  )
}

export default FundsSection
