import {
  swalWithConfirmAndCancelButtons,
  swalWithConfirmButton,
} from "components/custom/swal"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const EditFinModelModal = ({
  isOpen,
  toggleModal,
  finModelId,
  scenarioId,
  reloadData,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [finModel, setFinModel] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isOpen && finModelId != undefined && finModelId != null) {
      loadData()
    }
  }, [isOpen, finModelId])

  const loadData = async () => {
    setIsLoading(true)
    let fm =
      finModelId == -1
        ? {
            finModelID: -1,
            finModelName: "",
            scenarioID: scenarioId,
            costFactor: 1,
            yearFactor1: 1,
            yearFactor2: 1,
            yearFactor3: 1,
            yearFactor4: 1,
          }
        : await api.getFinModel(finModelId)
    setFinModel(fm)
    setIsLoading(false)
  }

  const save = async () => {
    if (
      finModel.costFactor == null ||
      finModel.costFactor == "" ||
      finModel.costFactor == 0 ||
      finModel.yearFactor1 == null ||
      finModel.yearFactor1 == "" ||
      finModel.yearFactor1 == 0 ||
      finModel.yearFactor2 == null ||
      finModel.yearFactor2 == "" ||
      finModel.yearFactor2 == 0 ||
      finModel.yearFactor3 == null ||
      finModel.yearFactor3 == "" ||
      finModel.yearFactor3 == 0 ||
      finModel.yearFactor4 == null ||
      finModel.yearFactor4 == "" ||
      finModel.yearFactor4 == 0
    ) {
      swalWithConfirmButton.fire({
        title: `You cannot save the scenario with empty or 0 values`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    } else {
      if (finModelId > -1) {
        await api.updateFinModel(currentUser.userID, finModel)
        toast.success("Model updated")
      } else {
        await api.createFinModel(currentUser.userID, finModel)
        toast.success("Model created")
      }
      reloadData()
      toggleModal()
    }
  }

  const deleteFinModel = () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this model?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteFinModel(currentUser.userID, finModelId)
          toast.success("Model deleted")
          reloadData()
          toggleModal()
        }
      })
  }

  const changeFinModelProp = (prop, value) => {
    setFinModel({
      ...finModel,
      [prop]: value,
    })
  }

  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Scenario
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      {!isLoading && finModel != null && (
        <div className="modal-body">
          <div className="mb-3">
            <Label className="form-label">Scenario</Label>
            <Input
              name="note"
              type="text"
              onChange={e => changeFinModelProp("finModelName", e.target.value)}
              value={finModel.finModelName}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Overall Cost Factor</Label>
            <CurrencyInput
              className="form-control "
              style={{ padding: "1px", fontSize: "12px" }}
              value={finModel.costFactor}
              onValueChange={(value, name, values) => {
                changeFinModelProp("costFactor", value)
              }}
              prefix={""}
              decimalScale={2}
              decimalsLimit={3}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Discount Factor Year 1</Label>
            <CurrencyInput
              className="form-control"
              style={{ padding: "1px", fontSize: "12px" }}
              value={finModel.yearFactor1}
              onValueChange={(value, name, values) => {
                changeFinModelProp("yearFactor1", value)
              }}
              prefix={""}
              decimalScale={2}
              decimalsLimit={3}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Discount Factor Year 2</Label>
            <CurrencyInput
              className="form-control "
              style={{ padding: "1px", fontSize: "12px" }}
              value={finModel.yearFactor2}
              onValueChange={(value, name, values) => {
                changeFinModelProp("yearFactor2", value)
              }}
              prefix={""}
              decimalScale={2}
              decimalsLimit={3}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Discount Factor Year 3</Label>
            <CurrencyInput
              className="form-control "
              style={{ padding: "1px", fontSize: "12px" }}
              value={finModel.yearFactor3}
              onValueChange={(value, name, values) => {
                changeFinModelProp("yearFactor3", value)
              }}
              prefix={""}
              decimalScale={2}
              decimalsLimit={3}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Discount Factor Year 4</Label>
            <CurrencyInput
              className="form-control"
              style={{ padding: "1px", fontSize: "12px" }}
              value={finModel.yearFactor4}
              onValueChange={(value, name, values) => {
                changeFinModelProp("yearFactor4", value)
              }}
              prefix={""}
              decimalScale={2}
              decimalsLimit={3}
            />
          </div>
        </div>
      )}
      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>
          {finModelId > -1 && (
            <button
              className="btn btn-outline-danger save-user"
              onClick={deleteFinModel}
            >
              Delete
            </button>
          )}
        </div>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save Scenario
        </button>
      </div>
    </Modal>
  )
}

export default EditFinModelModal
