import React from "react"
import { UncontrolledAlert } from "reactstrap"

const OptimizationAlert = ({optModel}) => {
  if (optModel == undefined || optModel == null) return null
  else
    return (
      <UncontrolledAlert color="info">
        <div>Optimization Model Name: {optModel.optModelName}</div>
        {optModel.objModelID > 0 && (
          <div>Objective Model: {optModel.objModelName}</div>
        )}
        {optModel.finModelID > 0 && (
          <div>Financial Model: {optModel.finModelName}</div>
        )}
      </UncontrolledAlert>
    )
}

export default OptimizationAlert
