import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import {
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  Row,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const ShareTelerikReportModal = ({ isOpen, toggleModal }) => {
  const currentUser = userService.getLoggedInUser()
  const [users, setUsers] = useState([])
  const [reports, setReports] = useState([])
  const [selectedReportId, setSelectedReportId] = useState(-1)
  const [search, setSearch] = useState("")

  useEffect(() => {
    if (isOpen) {
      setSearch("")
      setSelectedReportId(-1)
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    let r = await api.getTelerikUserReports(currentUser.userID)
    setReports(r)
    let u = await api.getUsersByUserId(currentUser.userID, false)
    setUsers(u)
  }

  const changeSelectedReport = async telerikReportId => {
    setSelectedReportId(telerikReportId)
    if (telerikReportId == -1) {
      setUsers(
        users.map(x => {
          x.selected = false
          return x
        })
      )
    } else {
      let associatedUserIds = await api.getTelerikUserReportAssociatedUserIds(
        telerikReportId
      )
      setUsers(
        users.map(x => {
          if (
            associatedUserIds == null ||
            associatedUserIds.length == 0 ||
            associatedUserIds.find(id => id == x.userID) == undefined
          ) {
            x.selected = false
          } else {
            x.selected = true
          }
          return x
        })
      )
    }
  }

  const save = async () => {
    if (selectedReportId != -1) {
      await api.shareTelerikUserReport(
        selectedReportId,
        users.filter(x => x.selected).map(x => x.userID)
      )
      toggleModal()
    }
  }

  const addSelectedItems = () => {
    let copy = [...users]
    setUsers(
      copy.map(u => {
        if (u.isSelected) {
          u.isSelected = false
          u.selected = true
        }
        return u
      })
    )
  }

  const removeSelectedItems = () => {
    let copy = [...users]
    setUsers(
      copy.map(u => {
        if (u.isSelected) {
          u.isSelected = false
          u.selected = false
        }
        return u
      })
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Add Entity
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <Label>Report</Label>
          <select
            className="form-control form-select select2 mb-xxl-0"
            value={selectedReportId}
            onChange={e => {
              changeSelectedReport(e.target.value)
            }}
          >
            <option value="-1">Select</option>
            {reports.map((r, idx) => {
              return (
                <option key={idx} value={`${r.id}`}>
                  {r.name}
                </option>
              )
            })}
          </select>
        </div>
        <div className="mb-3">
          <Row style={{ marginTop: "10px" }}>
            <Col md="4">
              <InputGroup>
                <InputGroupText>
                  <i className="fas fa-search"></i>
                </InputGroupText>
                <Input
                  placeholder="Search ..."
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                />
              </InputGroup>
              <div style={{ overflowX: "auto", height: "350px" }}>
                <table
                  className="table table-bordered low-padding-table"
                  style={{ backgroundColor: "white" }}
                >
                  <thead>
                    <tr>
                      <th>Users</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.filter(x => !x.selected)
                      .filter(
                        x =>
                          search == null ||
                          search == "" ||
                          x.firstName
                            .toLowerCase()
                            .indexOf(search.toLowerCase()) > -1 ||
                          x.lastName
                            .toLowerCase()
                            .indexOf(search.toLowerCase()) > -1
                      )
                      .map((u, idx) => {
                        return (
                          <tr key={idx}>
                            <td
                              style={{
                                cursor: "pointer",
                                backgroundColor: u.isSelected
                                  ? "#9abcea"
                                  : "unset",
                              }}
                              onClick={() => {
                                let copy = [...users]
                                let item = copy.find(x => x.userID == u.userID)
                                item.isSelected = !item.isSelected
                                setUsers(copy)
                              }}
                            >
                              {u.firstName} {u.lastName}
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </Col>
            <Col md="4">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "25%",
                  marginRight: "25%",
                }}
              >
                <button className="btn btn-primary" onClick={addSelectedItems}>
                  <i className="fas fa-plus"></i> Add User(s)
                </button>
                <button
                  className="btn btn-outline-danger"
                  style={{ marginTop: "15px" }}
                  onClick={removeSelectedItems}
                >
                  <i className="fas fa-times"></i> Remove User(s)
                </button>
              </div>
            </Col>
            <Col md="4">
              <div style={{ overflowX: "auto", height: "360px" }}>
                <table
                  className="table table-bordered low-padding-table"
                  style={{ backgroundColor: "white" }}
                >
                  <thead>
                    <th>Access to Report</th>
                  </thead>
                  <tbody>
                    {users
                      .filter(x => x.selected)
                      .map((u, idx) => {
                        return (
                          <tr key={idx}>
                            <td
                              style={{
                                cursor: "pointer",
                                backgroundColor: u.isSelected
                                  ? "#9abcea"
                                  : "unset",
                              }}
                              onClick={() => {
                                let copy = [...users]
                                let item = copy.find(x => x.userID == u.userID)
                                item.isSelected = !item.isSelected
                                setUsers(copy)
                              }}
                            >
                              {u.firstName} {u.lastName}
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default ShareTelerikReportModal
