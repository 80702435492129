import { createRoot } from "react-dom/client"

export const customFormatter = jsxFn => (cell, formatterParams, onRendered) => {
  const renderFn = () => {
    const cellEl = cell.getElement()
    if (cellEl) {
      let root = cellEl._reactRoot
      const container = cellEl.querySelector(".formatterCell")

      if (container) {
        if (!root) {
          root = createRoot(container)
          cellEl._reactRoot = root
        }
        const rowData = cell.getRow().getData()
        const cellValue = cell.getValue()

        // Call the passed JSX function with cell and rowData as arguments
        root.render(jsxFn({ cell, cellValue, rowData }))
      }
    }
  }

  onRendered(renderFn)

  setTimeout(() => {
    renderFn()
  }, 0)
  return '<div class="formatterCell"></div>'
}
