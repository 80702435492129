import api from "services/api.service"
import isNullOrEmpty from "./isNullOrEmpty"

export const loadUsersAvatars = async userIds => {
  let distinctUserIds = [...new Set(userIds)].filter(x=>x !== undefined && x !== null)
  let tasks = distinctUserIds.map(x => async () => {
    return await api.getUserPhotoBase64(x)
  })
  let photosBase64 = await Promise.all(tasks.map(t => t()))
  let ret = []
  for (var i = 0; i < photosBase64.length; i++) {
    ret.push({
      userID: distinctUserIds[i],
      photoBase64: isNullOrEmpty(photosBase64[i]) ? "" : photosBase64[i],
    })
  }

  return ret
}

export const enrichWithPhotoBase64 = async (
  listToEnrich,
  userIdProp,
  includeOnlyUsersWithProfilePhoto = false,
  photoBase64Prop = "photoBase64"
) => {
  let userIds = listToEnrich.filter(x => !includeOnlyUsersWithProfilePhoto || x.hasProfilePhoto).map(x => x[userIdProp])
  let distinctUserIds = [...new Set(userIds)].filter(
    x => x !== undefined && x !== null && x != 0
  )

  let tasks = distinctUserIds.map(x => async () => {
    return await api.getUserPhotoBase64(x)
  })
  let photosBase64 = await Promise.all(tasks.map(t => t()))
  let ret = []
  for (var i = 0; i < photosBase64.length; i++) {
    ret.push({
      userID: distinctUserIds[i],
      photoBase64: isNullOrEmpty(photosBase64[i]) ? "" : photosBase64[i],
    })
  }

  listToEnrich = listToEnrich.map(l => {
    let avatar = ret.find(x => x.userID == l[userIdProp])
    l[photoBase64Prop] = avatar == undefined ? "" : avatar.photoBase64
    return l
  })

  return listToEnrich
}
