export const mapObjPropsToLowercase = data => {
  return data.map(item => {
    for (var key in item) {
      if (key != "saaScore" && key != "saaRating" && key.indexOf(" ") > -1) {
        var lower = key.toLowerCase()
        if (lower !== key) {
          item[lower] = item[key]
          delete item[key]
        }
      }
    }
    return item
  })
}

export const mapAllPropsToLowercase = data => {
  return data.map(item => {
    for (var key in item) {
      var lower = key.toLowerCase()
      if (lower !== key) {
        item[lower] = item[key]
        delete item[key]
      }
    }
    return item
  })
}
