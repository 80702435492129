import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import download from "downloadjs"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const BusinessCaseReportModal = ({
  isOpen,
  toggleModal,
  alternative, //either alternative
  scenarioId, //or scenarioId
  onSave, // optional
}) => {
  const currentUser = userService.getLoggedInUser()
  const [isLoading, setIsLoading] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (isOpen) {
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    setIsLoading(true)

    let data = {}
    if (scenarioId == undefined) {
      data = await api.getBcReportOptions(
        alternative.alternativeID,
        currentUser.userID
      )
    } else {
      data = await api.getBcReportScenarioOptions(
        scenarioId,
        currentUser.userID
      )
    }
    setOptions(data)
    setIsLoading(false)
  }

  const save = async () => {
    setIsDownloading(true)

    if (onSave) {
      await onSave(options, () => setIsDownloading(false))
    } else {
      api
        .getBusinessCaseReport(
          currentUser.userID,
          alternative.businessCaseID,
          options
        )
        .then(
          blob => {
            setIsDownloading(false)
            download(
              blob,
              `${alternative.alternativeNum} - ${alternative.alternativeName} - Business Case Report.pdf`
            )
          },
          err => setIsDownloading(false)
        )
    }
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Business Case Report
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      {!isLoading && (
        <div className="modal-body">
          {options.map((o, idx) => (
            <div className="mb-3" key={idx}>
              <Input
                id={o.listItemName}
                type="checkbox"
                style={{ marginLeft: "10px" }}
                checked={o.listItemID == 1}
                onClick={() => {
                  let copy = [...options]
                  let item = copy.find(x => x.listItemName == o.listItemName)
                  item.listItemID = item.listItemID == 0 ? 1 : 0
                  setOptions(copy)
                }}
              />
              <Label
                className="form-check-label"
                for={o.listItemName}
                style={{ marginLeft: "10px" }}
              >
                {o.listItemName}
              </Label>
            </div>
          ))}
        </div>
      )}

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
        {isDownloading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
          disabled={isDownloading}
        >
          Run Report
        </button>
      </div>
    </Modal>
  )
}

export default BusinessCaseReportModal
