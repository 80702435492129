import { CHANGE_CHATGPT_SETTINGS } from "./actionTypes"

const INIT_STATE = {
  isGptDrawerOpen: false,
}

const chatGptSettings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_CHATGPT_SETTINGS:
      return {
        ...state,
        isGptDrawerOpen: action.payload,
      }

    default:
      return state
  }
}

export default chatGptSettings
