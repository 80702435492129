import { CHANGE_OPTOBJFIN } from "./actionTypes"

const INIT_STATE = {
  optObjFin:
    localStorage.getItem("optobjfin") != null
      ? JSON.parse(localStorage.getItem("optobjfin"))
      : {
          opt: null,
          obj: null,
          fin: null,
        },
}

const optObjFin = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_OPTOBJFIN:
      localStorage.removeItem("optobjfin")
      localStorage.setItem("optobjfin", JSON.stringify(action.payload))
      return {
        ...state,
        optObjFin: action.payload,
      }

    default:
      return state
  }
}

export default optObjFin
