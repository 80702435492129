import React, { useEffect, useRef, useState } from "react"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import Geocode from "react-geocode"

const EditPolygonLocation = ({ addressLocationRef, setMarkers }) => {
  useEffect(() => {
    addressLocationRef.current = {
      onMapClick,
    }
  }, [])

  const onMapClick = e => {
    setMarkers(markers => [
      ...markers,
      { lat: e.latLng.lat(), lng: e.latLng.lng() },
    ])
  }

  return <React.Fragment></React.Fragment>
}

export default EditPolygonLocation
