import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import useModal from "hooks/useModalHook"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Button, Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import {
  HtmlEditor,
  Image,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
  FontFamily,
} from "@syncfusion/ej2-react-richtexteditor"
import { Inject } from "@syncfusion/ej2-react-gantt"
import { ToolbarSettings } from "@syncfusion/ej2-react-dropdowns"

const EditDashboardInfoSectionModal = ({
  isOpen,
  toggleModal,
  infoSectionToEdit,
  setInfoSectionToEdit,
  reload,
}) => {
  const currentUser = userService.getLoggedInUser()
  const textEditorRef = useRef()

  const save = async () => {
    const text = textEditorRef.current.value

    if (infoSectionToEdit.infoSectionID > -1) {
      await api.updateInfoSection(currentUser.userID, {
        ...infoSectionToEdit,
        sectionText: text,
      })
    } else {
      await api.createInfoSection(currentUser.userID, {
        ...infoSectionToEdit,
        sectionText: text,
      })
    }
    toggleModal()
    reload && reload()
  }

  const toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "StrikeThrough",
      "FontName",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "LowerCase",
      "UpperCase",
      "|",
      "Formats",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Outdent",
      "Indent",
      "|",
      "CreateLink",
      "Image",
      "|",
      "ClearFormat",
      "Print",
      "SourceCode",
      "FullScreen",
      "|",
      "Undo",
      "Redo",
    ],
    type: "Expand",
  }

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {infoSectionToEdit && infoSectionToEdit.infoSectionID > -1
            ? "Edit "
            : "Add "}{" "}
          Section
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <Label className="form-label">Section Name</Label>
          <Input
            type="text"
            onChange={e =>
              setInfoSectionToEdit({
                ...infoSectionToEdit,
                sectionName: e.target.value,
              })
            }
            value={infoSectionToEdit.sectionName}
          />
        </div>
        <div className="mb-3">
          <Label className="form-label">Section Text</Label>
          <RichTextEditorComponent
            ref={r => (textEditorRef.current = r)}
            toolbarSettings={toolbarSettings}
            value={infoSectionToEdit.sectionText}
          >
            <Inject
              services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]}
            />
          </RichTextEditorComponent>
        </div>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default EditDashboardInfoSectionModal
