import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { Col, Container, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { changeNavbarParams, loadPageItem } from "store/actions"
import colors from "utils/colors"
import useModal from "hooks/useModalHook"
import EditRateModal from "./Modals/EditRateModal"
import CurrencyInput from "react-currency-input-field"
import { toast } from "react-toastify"

const EnterpriseRatesSection = ({ enterpriseId, activeTab }) => {
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  let [bcRates, setBcRates] = useState([])
  let [bcRateYears, setBcRateYears] = useState([])

  const [fromYear, setFromYear] = useState(new Date().getFullYear())
  const [toYear, setToYear] = useState(new Date().getFullYear() + 10)
  const [years, setYears] = useState([])

  let [isLoading, setIsLoading] = useState(false)

  const [rateToEdit, setRateToEdit] = useState(null)
  const { show: showAddRateModal, toggle: toggleAddRateModal } = useModal()

  const newRate = {
    bcRateID: -1,
    enterpriseID: enterpriseId,
    rateType: "-1",
    bcRateName: "",
  }

  useEffect(() => {
    if (fromYear > 0 && toYear > 0 && activeTab == '10') {
      let lst = []
      for (let i = fromYear; i <= toYear; i++) {
        lst.push(i)
      }
      setYears(lst)
    }
  }, [fromYear, toYear, activeTab])

  useEffect(() => {
    if (years.length > 0 && activeTab == '10') loadData()
  }, [years, activeTab])

  const loadData = async () => {
    try {
      setIsLoading(true)

      let rates = await api.getEnterpriseBcRates(enterpriseId)
      let rateYears = await api.getEnterpriseBcRateYears(
        enterpriseId,
        fromYear,
        toYear
      )
      rates.forEach(rate => {
        rate.bcRateYears = rateYears.filter(x => x.bcRateID == rate.bcRateID)
      })

      setBcRates(rates)
      setBcRateYears(rateYears)

      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  const changeRate = (bcRateId, yearId, rate) => {
    let copy = [...bcRates]
    let bcRate = copy.find(x => x.bcRateID == bcRateId)
    if (bcRate != undefined) {
      let rateYear = bcRate.bcRateYears?.find(x => x.yearID == yearId)
      if (rateYear != undefined) {
        rateYear.rate = rate
        rateYear.isDirty = true
      } else {
        if (bcRate.bcRateYears == undefined || bcRate.bcRateYears == null)
          bcRate.bcRateYears = []
        bcRate.bcRateYears.push({
          bcRateID: bcRateId,
          yearID: yearId,
          rate,
          isDirty: true,
        })
      }

      setBcRates(copy)
    }
  }

  const save = async () => {
    let updatedBcRateYears = []
    bcRates.forEach(r => {
      let updated = r.bcRateYears?.filter(x => x.isDirty)
      if (updated?.length > 0) {
        updatedBcRateYears = [...updatedBcRateYears, ...updated]
      }
    })

    if (updatedBcRateYears.length > 0) {
      let tasks = updatedBcRateYears.map(x => async () => {
        return await api.addOrUpdateEnterpriseBcRateYear(currentUser.userID, x)
      })
      await Promise.all(tasks.map(t => t()))
      toast.success("Changes saved successfuly")
    }
  }

  return (
    <React.Fragment>
      <Row style={{ marginBottom: "20px" }}>
        <Col xs="12">
          <div className="d-flex-row ai-center jc-space-between">
            <button
              className="btn btn-primary"
              onClick={() => {
                setRateToEdit({ ...newRate })
                toggleAddRateModal()
              }}
            >
              <i className="fas fa-plus"></i> Add Rate
            </button>
            <button className="btn btn-primary" onClick={save}>
              <i className="fas fa-save"></i> Save Changes
            </button>
          </div>
        </Col>
      </Row>
      <Row style={{ marginBottom: "20px" }}>
        <Col>
          <div className="d-flex-row ai-center" style={{ gap: "15px" }}>
            <Label className="form-label">From</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={fromYear}
              onChange={e => {
                if (e.target.value <= toYear) setFromYear(e.target.value)
              }}
              style={{ width: "150px" }}
            >
              {Array.from({ length: 30 }, (_, index) => index + 1).map(i => (
                <option key={i} value={new Date().getFullYear() - 15 + i}>
                  {new Date().getFullYear() - 15 + i}
                </option>
              ))}
            </select>
            <Label className="form-label">To</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={toYear}
              onChange={e => {
                if (e.target.value >= fromYear) setToYear(e.target.value)
              }}
              style={{ width: "150px" }}
            >
              {Array.from({ length: 30 }, (_, index) => index + 1).map(i => (
                <option key={i} value={new Date().getFullYear() - 15 + i}>
                  {new Date().getFullYear() - 15 + i}
                </option>
              ))}
            </select>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          {!isLoading && (
            <>
              <table
                className="table table-bordered lower-padding-table"
                style={{ backgroundColor: "white", fontSize: "12px" }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "20%" }}></th>
                    <th style={{ width: "15%" }}></th>
                    {years.map((y, idx) => (
                      <th key={idx}>{y}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {bcRates.map((r, idx) => (
                    <tr key={idx}>
                      <td>
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault()
                            setRateToEdit(r)
                            toggleAddRateModal()
                          }}
                        >
                          {r.bcRateName}
                        </a>
                      </td>
                      <td>{r.rateType}</td>
                      {years.map((y, idx2) => {
                        let rate =
                          r.bcRateYears?.find(x => x.yearID == y)?.rate ?? 0
                        return (
                          <td key={idx2}>
                            <CurrencyInput
                              className="form-control align-end"
                              style={{ padding: "1px", fontSize: "12px" }}
                              value={rate}
                              onValueChange={(value, name, values) => {
                                changeRate(r.bcRateID, y, value)
                              }}
                              // prefix={"$"}
                              suffix="%"
                              decimalsLimit={2}
                              allowDecimals={true}
                            />
                          </td>
                        )
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </Col>
      </Row>
      <EditRateModal
        isOpen={showAddRateModal}
        toggleModal={toggleAddRateModal}
        rateToEdit={rateToEdit}
        setRateToEdit={setRateToEdit}
        reload={loadData}
      />
    </React.Fragment>
  )
}

export default EnterpriseRatesSection
