import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import useModal from "hooks/useModalHook"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Button, Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import EditDashboardInfoSectionModal from "./EditDashboardInfoSectionModal"

const DashboardInfoSectionsModal = ({ isOpen, toggleModal, dashboardId }) => {
  const currentUser = userService.getLoggedInUser()
  const [infoSections, setInfoSections] = useState([])
  const [infoSectionToEdit, setInfoSectionToEdit] = useState(null)
  const { show: showEditModal, toggle: toggleEditModal } = useModal()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isOpen && dashboardId != undefined) {
      loadData()
    }
  }, [isOpen, dashboardId])

  const loadData = async () => {
    setIsLoading(true)
    let is = await api.getInfoSections(dashboardId, "Dashboard")
    setInfoSections(is)
    setIsLoading(false)
  }

  const addInfoSection = () => {
    setInfoSectionToEdit({
      infoSectionID: -1,
      itemID: dashboardId,
      sectionName: "",
      sectionText: "",
      scope: "Dashboard",
    })
    toggleEditModal()
  }

  const editInfoSection = infoSection => {
    setInfoSectionToEdit(infoSection)
    toggleEditModal()
  }

  const deleteInfoSection = infoSection => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this info section?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteInfoSection(infoSection.infoSectionID)
          loadData()
        }
      })
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        size="md"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Info Sections
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <Button color="primary" onClick={addInfoSection}>
              Add Info Section
            </Button>
          </div>
          <div className="mb-3">
            {isLoading && (
              <Loader
                type="line-scale-pulse-out"
                color={colors.primary}
                style={{ textAlign: "center" }}
              />
            )}
            {!isLoading && (
              <table
                className="table table-bordered low-padding-table"
                style={{ backgroundColor: "white" }}
              >
                <thead>
                  <th style={{ width: "95%" }}>Section Name</th>
                  <th style={{ width: "5%" }}></th>
                </thead>
                <tbody>
                  {infoSections.map((c, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          <a
                            href="#"
                            onClick={e => {
                              e.preventDefault()
                              editInfoSection(c)
                            }}
                          >
                            {c.sectionName}
                          </a>
                        </td>
                        <td>
                          <i
                            className="fas fa-trash"
                            style={{
                              color: "red",
                              cursor: "pointer",
                            }}
                            onClick={() => deleteInfoSection(c)}
                          ></i>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>

        <div
          className="modal-footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
      {infoSectionToEdit != null && (
        <EditDashboardInfoSectionModal
          infoSectionToEdit={infoSectionToEdit}
          setInfoSectionToEdit={setInfoSectionToEdit}
          isOpen={showEditModal}
          toggleModal={toggleEditModal}
          reload={loadData}
        />
      )}
    </>
  )
}

export default DashboardInfoSectionsModal
