export const getAlternativeScoresChartOptionsSeries = (
  userScores,
  scenarioRatingScale
) => {
  let options = {
    chart: {
      id: `basic-bar${Math.random()}`, // should be a different one every re-render
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
      formatter: function (value) {
        return (
          value +
          ` (${(
            (value / userScores.filter(x => x.userScored == true).length) *
            100
          ).toFixed(2)}%)`
        )
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return (
            value +
            ` (${(
              (value / userScores.filter(x => x.userScored == true).length) *
              100
            ).toFixed(2)}%)`
          )
        },
      },
    },
  }

  let data = scenarioRatingScale.map(sc => {
    return {
      x: sc.ratingName,
      y: userScores.filter(x => x.userScored && x.userScore == sc.inputValue)
        .length,
    }
  })
  let series = [
    {
      name: "Votes",
      data: data,
    },
  ]
  return { options, series }
}
