import {
  swalWithConfirmAndCancelButtons,
  swalWithConfirmButton,
} from "components/custom/swal"
import useModal from "hooks/useModalHook"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row, Alert } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import EditListItemModal from "./EditListItemModal"
import isNullOrEmpty from "utils/isNullOrEmpty"

const EditTaskTemplateFieldModal = ({
  isOpen,
  toggleModal,
  taskTypeFields,
  fieldToEdit,
  setFieldToEdit,
  taskTypeId,
  onFieldSave,
}) => {
  const currentUser = userService.getLoggedInUser()
  //   const [fieldToEdit, setFieldToEdit] = useState(null)
  const [listItems, setListItems] = useState([])

  const [listItemToEdit, setListItemToEdit] = useState(null)

  const { show: showListItemModal, toggle: toggleListItemModal } = useModal()
  const [hasLabelError, setHasLabelError] = useState(false)

  const nonEditableLists = [
    {
      listName: "AlternativePhase",
    },
    {
      listName: "ScenarioUserList",
    },
    {
      listName: "UserList",
    },
    {
      listName: "UsersPlusRoles",
    },
  ]
  const editableLists = [
    {
      listName: "RequestType",
      get: taskTypeId => api.getTaskTypeListItems(taskTypeId, "RequestType"),
    },
    {
      listName: "TaskCategory",
      get: taskTypeId => api.getTaskTypeListItems(taskTypeId, "TaskCategory"),
    },
    {
      listName: "TaskImpact",
      get: taskTypeId => api.getTaskTypeListItems(taskTypeId, "TaskImpact"),
    },
    {
      listName: "TaskProbability",
      get: taskTypeId =>
        api.getTaskTypeListItems(taskTypeId, "TaskProbability"),
    },
    {
      listName: "TaskStatus",
      get: taskTypeId => api.getTaskTypeListItems(taskTypeId, "TaskStatus"),
    },
  ]

  useEffect(() => {
    if (isOpen) {
      setHasLabelError(false)
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {}

  const loadListItems = async taskTypeField => {
    let editableList = editableLists.find(
      x => x.listName == taskTypeField.listName
    )

    if (editableList != undefined && taskTypeId > 0) {
      let items = await editableList.get(taskTypeId)
      setFieldToEdit({
        ...fieldToEdit,
        fieldProperty: taskTypeField.fieldName,
        fieldType: taskTypeField.fieldType,
        defaultValue: "",
        listName: taskTypeField.listName,
        listItems: items,
      })
      // setListItems(items)
    } else {
      setFieldToEdit({
        ...fieldToEdit,
        fieldProperty: taskTypeField.fieldName,
        fieldType: taskTypeField.fieldType,
        defaultValue: "",
        listName: taskTypeField.listName,
        listItems: [],
      })

      // setListItems([])
    }
  }

  const addListItem = () => {
    let newId = -1
    if (fieldToEdit.listItems.length > 0) {
      let minId = Math.min(...fieldToEdit.listItems.map(x => x.listItemID))
      if (minId < 0) {
        newId = minId - 1
      }
    }
    setListItemToEdit({ listItemID: newId, listItemName: "" })
    toggleListItemModal()
  }

  const editListItem = (e, listItem) => {
    e && e.preventDefault()
    setListItemToEdit(listItem)
    toggleListItemModal()
  }

  const deleteListItem = listItemId => {
    let copy = [...fieldToEdit.listItems]
    let item = copy.find(x => x.listItemID == listItemId)
    item.isDeleted = true
    // copy.splice(copy.indexOf(item), 1)
    setFieldToEdit({ ...fieldToEdit, listItems: copy })
    // setListItems(copy)
  }

  const onListItemSave = () => {
    let copy = [...fieldToEdit.listItems]
    if (listItemToEdit.listItemID < 0) {
      copy.push({ ...listItemToEdit, isDirty: true })
    } else {
      let existing = copy.find(x => x.listItemID == listItemToEdit.listItemID)
      copy.splice(copy.indexOf(existing), 1, {
        ...listItemToEdit,
        isDirty: true,
      })
    }
    setFieldToEdit({ ...fieldToEdit, listItems: copy })
    // setListItems(copy)
    toggleListItemModal()
  }

  const changeFieldProp = (prop, val) =>
    setFieldToEdit({ ...fieldToEdit, [prop]: val })

  const save = async () => {
    if (
      fieldToEdit.fieldLabel == undefined ||
      fieldToEdit.fieldLabel == null ||
      fieldToEdit.fieldLabel == ""
    ) {
      setHasLabelError(true)
    } else if (
      fieldToEdit.fieldType == "DDL" &&
      editableLists.find(x => x.listName == fieldToEdit.listName) !=
        undefined &&
      (fieldToEdit.listItems == undefined ||
        fieldToEdit.listItems == null ||
        fieldToEdit.listItems.length == 0)
    ) {
      swalWithConfirmButton.fire({
        title:
          "You need to add at least 1 item to the list of options for this field",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    } else {
      setHasLabelError(false)
      onFieldSave()
    }
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        size="md"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Edit Field
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {fieldToEdit != null && (
          <div className="modal-body">
            <Row>
              <Alert
                id="helpView"
                variant="light"
                className="always-enabled"
                style={{ marginBottom: "10px", paddingBottom: "0px" }}
              >
                <div>
                  To add a field to the task template, select from the list of
                  Fields adn then give it an order and the label that users will
                  see.
                  <ol>
                    <li>
                      The Field Name is not shown to the user, only the label
                      that you enter.
                    </li>
                    <li>
                      If the template uses more than 1 column, the "Full Row"
                      option indicates that this field will use up the total
                      width of the task popup.
                    </li>
                    <li>
                      Some dropdowns will require you to provide the options
                      (List Items) for the dropdown. Click "Add List Item", to
                      add them if needed.
                    </li>
                  </ol>
                </div>
              </Alert>
            </Row>

            <div className="mb-3">
              <Label className="form-label">Field</Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={fieldToEdit.fieldProperty}
                onChange={e => {
                  if (e.target.value == -1) {
                    setFieldToEdit({
                      ...fieldToEdit,
                      fieldProperty: "",
                      fieldType: "",
                      defaultValue: "",
                      listName: "",
                      listItems: [],
                    })
                    // setListItems([])
                  } else {
                    let taskTypeField = taskTypeFields.find(
                      x => x.fieldName == e.target.value
                    )
                    // setFieldToEdit({
                    //   ...fieldToEdit,
                    //   fieldProperty: taskTypeField.fieldName,
                    //   fieldType: taskTypeField.fieldType,
                    //   defaultValue: "",
                    //   listName: taskTypeField.listName,
                    // })
                    loadListItems(taskTypeField)
                  }
                }}
              >
                <option value="-1">Select field...</option>
                {taskTypeFields.map((s, i) => (
                  <option key={i} value={s.fieldName}>
                    {s.taskTypeFieldOptionName} - {s.fieldType}
                  </option>
                ))}
              </select>
            </div>

            {fieldToEdit.fieldType == "DDL" &&
              editableLists.find(x => x.listName == fieldToEdit.listName) !=
                undefined && (
                <div className="mb-3">
                  <button
                    type="button"
                    className="btn btn-primary mb-3"
                    onClick={addListItem}
                  >
                    Add List Item
                  </button>
                  <table
                    className="table table-bordered low-padding-table"
                    style={{ backgroundColor: "white" }}
                  >
                    <thead>
                      <tr>
                        <th>Item</th>
                        {fieldToEdit.listName == "TaskStatus" && (
                          <th>Completed</th>
                        )}
                        {(fieldToEdit.listName == "TaskProbability" ||
                          fieldToEdit.listName == "TaskImpact") && (
                          <th>Score</th>
                        )}
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {fieldToEdit.listItems != undefined &&
                        fieldToEdit.listItems
                          .filter(x => !x.isDeleted)
                          .map((l, idx) => (
                            <tr key={idx}>
                              <td
                                style={{
                                  backgroundColor: isNullOrEmpty(l.backColor)
                                    ? "white"
                                    : l.backColor,
                                }}
                              >
                                <a
                                  href="#"
                                  onClick={e => editListItem(e, l)}
                                  style={{
                                    color: isNullOrEmpty(l.foreColor)
                                      ? "white"
                                      : l.foreColor,
                                  }}
                                >
                                  {l.listItemName}
                                </a>
                              </td>
                              {fieldToEdit.listName == "TaskStatus" && (
                                <td>
                                  {l.completed == true ? "Completed" : ""}
                                </td>
                              )}
                              {(fieldToEdit.listName == "TaskProbability" ||
                                fieldToEdit.listName == "TaskImpact") && (
                                <td>{l.itemScore}</td>
                              )}
                              <td>
                                <i
                                  className="fas fa-trash"
                                  style={{ color: "red", cursor: "pointer" }}
                                  onClick={() => deleteListItem(l.listItemID)}
                                ></i>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              )}

            <div className="mb-3">
              <Label className="form-label">Order</Label>
              <Input
                type="number"
                onChange={e => changeFieldProp("orderID", e.target.value)}
                value={fieldToEdit.orderID}
              />
            </div>
            <div className="form-check mb-3">
              <Input
                type="checkbox"
                className="form-check-input"
                id="cv-fullRow"
                checked={fieldToEdit.fullRow}
                onClick={e => {
                  changeFieldProp("fullRow", !fieldToEdit.fullRow)
                }}
              />
              <Label className="form-check-label" for="cv-fullRow">
                Full Row
              </Label>
            </div>
            <div className="form-check mb-3">
              <Input
                type="checkbox"
                className="form-check-input"
                id="cv-readOnly"
                checked={fieldToEdit.readOnly}
                onClick={e => {
                  changeFieldProp("readOnly", !fieldToEdit.readOnly)
                }}
              />
              <Label className="form-check-label" for="cv-readOnly">
                Read Only
              </Label>
            </div>
            <div className="mb-3">
              <Label className="form-label">Field Label</Label>
              <Input
                type="text"
                onChange={e => changeFieldProp("fieldLabel", e.target.value)}
                value={fieldToEdit.fieldLabel}
              />
              {hasLabelError && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Label is required
                </div>
              )}
            </div>
            {(fieldToEdit.fieldType == "ShortText" ||
              fieldToEdit.fieldType == "LongText") && (
              <div className="mb-3">
                <Label className="form-label">Placeholder</Label>
                <Input
                  type="text"
                  onChange={e => changeFieldProp("placeholder", e.target.value)}
                  value={fieldToEdit.placeholder}
                />
              </div>
            )}
            {/* <Input
              type="text"
              onChange={e => changeFieldProp("defaultValue", e.target.value)}
              value={fieldToEdit.defaultValue}
            /> */}
            <DefaultInput
              field={fieldToEdit}
              onChange={val => changeFieldProp("defaultValue", val)}
            />
          </div>
        )}
        <div
          className="modal-footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={save}
          >
            Save
          </button>
        </div>
      </Modal>
      {listItemToEdit != null && fieldToEdit != null && (
        <EditListItemModal
          isOpen={showListItemModal}
          toggleModal={toggleListItemModal}
          listItemToEdit={listItemToEdit}
          setListItemToEdit={setListItemToEdit}
          onSave={onListItemSave}
          listName={fieldToEdit.listName}
        />
      )}
    </>
  )
}

const DefaultInput = ({ field, onChange }) => {
  if (field.fieldType == "Date" || field.fieldType == "ReadOnlyDate") {
    return (
      <div className="mb-3">
        <Label className="form-label">Default Value</Label>
        <Input
          type="date"
          onChange={e => onChange(moment(e.target.value).format("YYYY-MM-DD"))}
          value={moment(field.defaultValue).format("YYYY-MM-DD")}
        />
      </div>
    )
  } else if (field.fieldType == "LongText" || field.fieldType == "ShortText") {
    return (
      <div className="mb-3">
        <Label className="form-label">Default Value</Label>
        <Input
          type="text"
          onChange={e => onChange(e.target.value)}
          value={field.defaultValue}
        />
      </div>
    )
  } else if (field.fieldType == "Duration") {
    return (
      <div className="mb-3">
        <Label className="form-label">Default Value</Label>
        <Input
          type="number"
          onChange={e => onChange(e.target.value)}
          value={field.defaultValue}
        />
      </div>
    )
  } else {
    return null
  }
}

export default EditTaskTemplateFieldModal
