import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const UploadDocumentModal = ({
  isOpen,
  toggleModal,
  reloadData,
  entityId,
  yearId,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [document, setDocument] = useState({
    id: 1,
    docType: "",
    isLink: false,
    docPath: "",
    docTitle: "",
  })
  const [file, setFile] = useState(null)

  useEffect(() => {
    if (isOpen) {
      setDocument({
        id: 1,
        docType: "",
        isLink: false,
        docPath: "",
      })
      setFile(null)
    }
  }, [isOpen])

  const saveDocument = async () => {
    if (document.isLink) {
      if (!document.docPath.toLowerCase().startsWith("http")) {
        toast.error("The link should start with 'http' or 'https'.")
      } else {
        await api.createEntityDocument(currentUser.userID, file, {
          ...document,
          entityID: entityId,
          yearID: yearId,
          docType: 'link'
        })
        toggleModal()
        reloadData()
      }
    } else {
      if (file == null) {
        toast.error("You can not save a document without a file")
      } else {
        await api.createEntityDocument(currentUser.userID, file, {
          ...document,
          entityID: entityId,
          yearID: yearId,
        })
        toggleModal()
        reloadData()
      }
    }
  }

  const onFileChange = event => {
    let f = event.target.files[0]
    setFile(f)
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Add Document
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="form-check mb-3">
          <Input
            type="checkbox"
            className="form-check-input"
            id="customCheck7"
            checked={document.isLink}
            onClick={e => {
              setDocument({
                ...document,
                isLink: !document.isLink,
              })
            }}
          />
          <Label className="form-check-label" for="customCheck7">
            Link to website or document
          </Label>
        </div>
        {document.isLink && (
          <>
            <div className="mb-3">
              <Label className="form-label">Link Name</Label>
              <Input
                name="note"
                type="text"
                onChange={e =>
                  setDocument({
                    ...document,
                    docTitle: e.target.value,
                  })
                }
                value={document.docTitle}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Link</Label>
              <Input
                name="note"
                type="text"
                onChange={e =>
                  setDocument({
                    ...document,
                    docPath: e.target.value,
                  })
                }
                value={document.docPath}
              />
            </div>
          </>
        )}
        {!document.isLink && (
          <>
            <div className="mb-3">
              <Label className="form-label">Document Type</Label>
              <Input
                name="note"
                type="text"
                onChange={e =>
                  setDocument({
                    ...document,
                    docType: e.target.value,
                  })
                }
                value={document.docType}
              />
            </div>
            <div className="mb-3">
              <Label
                className="btn btn-primary"
                style={{ marginBottom: "0px", fontWeight: "bold" }}
              >
                <input
                  type="file"
                  name="myFile"
                  onChange={onFileChange}
                  style={{ display: "none" }}
                />
                Upload File
              </Label>
              {file != null && (
                <span style={{ marginLeft: "10px" }}>{file.name}</span>
              )}
            </div>
          </>
        )}
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={saveDocument}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default UploadDocumentModal
