import ObjectiveRatingTable from "pages/_ObjectiveShared/ObjectiveRatingTable"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { Container, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { loadPageItem } from "store/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Tooltip } from "@material-ui/core"
import ObjectiveInfoContainer from "pages/_ObjectiveShared/ObjectiveInfoContainer"
import { loadUsersAvatars } from "utils/avatar"

const AdminObjectiveScore = props => {
  const scenarioId = props.match.params.scenarioId
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  let [nextObjectivePwcScore, setNextObjectivePwcScore] = useState(null)
  let [pwcUserScores, setPwcUserScores] = useState([])
  let [isLoading, setIsLoading] = useState(false)
  let [scenario, setScenario] = useState(null)
  const [usersPhotos, setUsersPhotos] = useState([])

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "adminObjectiveScore",
      })
    )
    loadNextData(0, 1, true)
    loadScenario()
  }, [])

  const loadNextData = async (pwcScoreId, levelId, loadUsersPhotos = false) => {
    let objScore = await loadNextObjectivePwcScore(pwcScoreId, levelId)
    setNextObjectivePwcScore(objScore)

    let userScores = await loadPwcUserScores(
      objScore.pwcScoreID,
      objScore.levelID
    )

    if (loadUsersPhotos) {
      let avatars = await loadUsersAvatars(userScores.map(x => x.userID))
      setUsersPhotos(avatars)
    }

    setPwcUserScores(userScores)
  }

  const loadPrevData = async (pwcScoreId, levelId) => {
    let objScore = await loadPrevObjectivePwcScore(pwcScoreId, levelId)
    setNextObjectivePwcScore(objScore)

    let userScores = await loadPwcUserScores(
      objScore.pwcScoreID,
      objScore.levelID
    )
    setPwcUserScores(userScores)
  }

  const loadNextObjectivePwcScore = async (pwcScoreId, levelId) => {
    try {
      setIsLoading(true)
      let objScore = await api.getNextPwcScore(pwcScoreId, levelId, scenarioId)
      setIsLoading(false)

      return objScore
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }

  const loadPrevObjectivePwcScore = async (pwcScoreId, levelId) => {
    try {
      setIsLoading(true)
      let objScore = await api.getPrevPwcScore(pwcScoreId, levelId, scenarioId)
      setIsLoading(false)
      return objScore
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }

  const loadPwcUserScores = async (pwcScoreId, levelId) => {
    try {
      let scores = await api.getPwcUserScores(pwcScoreId, levelId)
      return scores
    } catch (err) {
      console.log(err)
    }
  }

  const reloadObjScore = async (pwcScoreId, levelId) => {
    try {
      setIsLoading(true)
      let pwcScore = await api.getPwcScore(pwcScoreId, levelId)
      setNextObjectivePwcScore(pwcScore)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }

  const loadScenario = async () => {
    try {
      var scenario = await api.getScenario(scenarioId)
      setScenario(scenario)
    } catch (err) {
      console.log(err)
    }
  }

  const recalculate = async () => {
    setIsLoading(true)
    await api.recalculateObjective(currentUser.userID, scenarioId)
    await loadNextData(0, 1)
    setIsLoading(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginLeft: "20%",
              marginRight: "0%",
            }}
          >
            {nextObjectivePwcScore != null && (
              <button
                type="button"
                className="btn btn-primary save-user"
                // style={{ marginRight: "10px" }}
                onClick={async () =>
                  await loadPrevData(
                    nextObjectivePwcScore.pwcScoreID,
                    nextObjectivePwcScore.levelID
                  )
                }
              >
                <i className="fas fa-arrow-left"></i> Prev
              </button>
            )}
            {isLoading && (
              <Loader
                type="line-scale-pulse-out"
                color={colors.primary}
                style={{ textAlign: "center" }}
              />
            )}
            {nextObjectivePwcScore != null && (
              <button
                type="button"
                className="btn btn-primary save-user"
                onClick={async () =>
                  await loadNextData(
                    nextObjectivePwcScore.pwcScoreID,
                    nextObjectivePwcScore.levelID
                  )
                }
              >
                Next <i className="fas fa-arrow-right"></i>
              </button>
            )}
          </div>

          <Row style={{ marginLeft: "20%", marginRight: "0%" }}>
            <ObjectiveInfoContainer
              nextObjectivePwcScore={nextObjectivePwcScore}
              scenario={scenario}
              showDefinitions={false}
              showStandardDev={true}
              showMeanScore={true}
            />
          </Row>

          {scenario != null &&
            nextObjectivePwcScore != null &&
            pwcUserScores != null &&
            pwcUserScores.length > 0 && (
              <>
                <Row style={{ marginTop: "10px" }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "20%" }}>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={recalculate}
                      >
                        <i className="fas fa-calculator"></i> Recalculate
                      </button>
                    </div>
                    <div style={{ width: "80%" }}>
                      {nextObjectivePwcScore.avgVerbal.toLowerCase() ==
                      "equal" ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {nextObjectivePwcScore.avgVerbal}
                        </div>
                      ) : nextObjectivePwcScore.avgUserRanking >= 1 ? (
                        <div
                          style={{
                            width: "100%",
                            paddingLeft: "9px",
                            paddingRight: "9px",
                            fontWeight: "bold",
                          }}
                        >
                          {nextObjectivePwcScore.avgVerbal}
                        </div>
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "end",
                            paddingLeft: "9px",
                            paddingRight: "9px",
                            fontWeight: "bold",
                          }}
                        >
                          {nextObjectivePwcScore.avgVerbal}
                        </div>
                      )}
                    </div>
                  </div>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <ObjectiveRatingTable
                    scenario={scenario}
                    userScores={pwcUserScores}
                    setUserScores={setPwcUserScores}
                    pwcScore={nextObjectivePwcScore}
                    hasWeight={true}
                    canChangeWeight={true}
                    canChangeRating={true}
                    reloadObjScore={reloadObjScore}
                    usersPhotos={usersPhotos}
                  />
                </Row>
              </>
            )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AdminObjectiveScore
