import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { userService } from "services/user.service"
import { changeNavbarParams, loadPageItem } from "store/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Loader from "react-loaders"
import useModal from "hooks/useModalHook"
import EditRoadmapModal from "./Modals/EditRoadmapModal"
import api from "services/api.service"
import colors from "utils/colors"
import RoadmapChartModal from "./Modals/RoadmapChartModal"
import isNullOrEmpty from "utils/isNullOrEmpty"

const Roadmap = props => {
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [isLoading, setIsLoading] = useState(false)
  const [roadmapToEdit, setRoadmapToEdit] = useState(null)
  const { show: showRoadmapModal, toggle: toggleRoadmapModal } = useModal()
  
  const [roadmaps, setRoadmaps] = useState([])

  const { show: showChartModal, toggle: toggleChartModal } = useModal()
  const [selectedRoadmapIdForChart, setSelectedRoadmapIdForChart] = useState(-1)

  const newRoadmap = {
    roadmapID: -1,
    roadmapName: "",
    userID: currentUser.userID,
    description: "",
    inactive: false,
    timelineSetting: 'YQ',
    roadmapTypeID: 40,
    roadmapCategories: [],
    roadmapAlternatives: [],
  }

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: 0,
        alternativeId: 0,
        viewName: "Roadmap",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: 0,
        viewName: "Roadmap",
      })
    )
    loadData()
  }, [])

  const loadData = async () => {
    setIsLoading(true)
   
    await loadRoadmaps()

    if(!isNullOrEmpty(props.location.search) && props.location.search.indexOf('roadmapId=') > -1){
      let id = props.location.search.split('=')[1]
      await editRoadmap({roadmapID: id})
    }

    setIsLoading(false)
  }

  const loadRoadmaps = async () => {
    let rm = await api.getRoadmaps(currentUser.userID)
    setRoadmaps(rm)
  }

  const createRoadmap = () => {
    setRoadmapToEdit({ ...newRoadmap })
    toggleRoadmapModal()
  }

  const editRoadmap = async roadmap => {
    let r = await api.getRoadmap(roadmap.roadmapID)
    setRoadmapToEdit(r)
    toggleRoadmapModal()
  }

  const showChart = roadmapId => {
    setSelectedRoadmapIdForChart(roadmapId)
    toggleChartModal()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Card>
            <CardBody>
              <Row className="mb-3">
                <Col>
                  <button className="btn btn-primary" onClick={createRoadmap}>
                    <i className="fas fa-plus"></i> Create Roadmap
                  </button>
                </Col>
              </Row>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              {!isLoading && (
                <table
                  className="table table-bordered low-padding-table"
                  style={{ backgroundColor: "white" }}
                >
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Categories</th>
                      <th>Projects</th>
                      <th>Inactive</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {roadmaps.map((r, idx) => (
                      <tr key={idx}>
                        <td>
                          <a
                            href="#"
                            onClick={e => {
                              e.preventDefault()
                              editRoadmap(r)
                            }}
                          >
                            {r.roadmapName}
                          </a>
                        </td>
                        <td>{r.description}</td>
                        <td>{r.roadmapCategoryList}</td>
                        <td>{r.roadmapAlternativeList}</td>
                        <td>
                          {r.inactive ? (
                            <span style={{ color: "black" }}>
                              <b>Inactive</b>
                            </span>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            style={{ marginBottom: "5px", float: "right" }}
                            onClick={() => showChart(r.roadmapID)}
                          >
                            <i className="mdi mdi-chart-timeline"></i>&nbsp;
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <EditRoadmapModal
        isOpen={showRoadmapModal}
        toggleModal={toggleRoadmapModal}
        roadmapToEdit={roadmapToEdit}
        setRoadmapToEdit={setRoadmapToEdit}
        reload={loadRoadmaps}
        showChart={showChart}
      />

      <RoadmapChartModal
        isOpen={showChartModal}
        toggleModal={toggleChartModal}
        roadmapId={selectedRoadmapIdForChart}
      />
    </React.Fragment>
  )
}

export default Roadmap
