import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { ChromePicker } from "react-color"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const EditCategoryModal = ({
  isOpen,
  toggleModal,
  categoryToEdit,
  setCategoryToEdit,
  saveCategory,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [isCategoryValid, setIsCategoryValid] = useState(true)

  useEffect(() => {
    if (isOpen) {
    }
  }, [isOpen])

  const save = async () => {
    if (
      categoryToEdit.listItemName == undefined ||
      categoryToEdit.listItemName == null ||
      categoryToEdit.listItemName == ""
    ) {
      setIsCategoryValid(false)
    } else {
      toggleModal()
      saveCategory()
    }
  }

  const handlePickerButtonClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  }

  const handleClose = () => {
    setDisplayColorPicker(false)
  }

  const handleColorChange = col => {
    setCategoryToEdit({ ...categoryToEdit, color: col.hex })
  }

  const popover = {
    position: "absolute",
    zIndex: "2",
  }
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  }
  const colorStyle = {
    width: "100%",
    height: "23px",
    borderRadius: "2px",
    backgroundColor:
      categoryToEdit != null &&
      categoryToEdit.color != undefined &&
      categoryToEdit.color != null &&
      categoryToEdit.color != ""
        ? categoryToEdit.color
        : "white",
  }
  const swatch = {
    padding: "5px",
    background: "#fff",
    borderRadius: "1px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "inline-block",
    cursor: "pointer",
  }

  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Category
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {categoryToEdit != null && (
        <div className="modal-body">
          <div className="mb-3">
            <Label className="form-label">Category</Label>
            <Input
              name="category"
              type="text"
              onChange={e =>
                setCategoryToEdit({
                  ...categoryToEdit,
                  listItemName: e.target.value,
                })
              }
              value={categoryToEdit.listItemName}
            />
            {!isCategoryValid && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                Category name is required
              </div>
            )}
          </div>
          <div className="mb-3">
            <Label className="form-label">Color</Label>
            <div
              style={swatch}
              className="form-control"
              onClick={handlePickerButtonClick}
            >
              <div style={colorStyle} />
            </div>
            {displayColorPicker ? (
              <div style={popover}>
                <div style={cover} onClick={handleClose} />
                <ChromePicker
                  color={categoryToEdit.color}
                  onChangeComplete={handleColorChange}
                />
              </div>
            ) : null}
          </div>
        </div>
      )}
      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default EditCategoryModal
