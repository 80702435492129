import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"

const ApplyTaskTypeModal = ({ isOpen, toggleModal, taskType, scenario }) => {
 
  const save = async () => {
    await api.createWorkflowTasksScenario(
      scenario.scenarioID,
      taskType.workflowID
    )
    toggleModal()
    toast.success('The workflow has been applied to the portfolio')
  }

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Apply Workflow
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <p>
            The workflow gets automatically added to any new{" "}
            {scenario != null && scenario.alternativeName}s when they are
            created. Running this process will add the workflow to any existing
            projects and/or will update any existing projects in case the workflow
            has been modified.
          </p>
        </div>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Apply
        </button>
      </div>
    </Modal>
  )
}

export default ApplyTaskTypeModal
