import { TextareaAutosize } from "@material-ui/core"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const CopyPlanModal = ({
  isOpen,
  toggleModal,
  planToCopy,
  setPlanToCopy,
  reload,
}) => {
  const currentUser = userService.getLoggedInUser()

  const save = async () => {
    await api.copyPlan(currentUser.userID, planToCopy)
    reload && reload()
    toggleModal()
  }

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Add Entity
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {planToCopy != null && (
        <div className="modal-body">
          <div className="mb-3">
            <Label className="form-label">Name</Label>
            <Input
              type="text"
              onChange={e =>
                setPlanToCopy({
                  ...planToCopy,
                  planName: e.target.value,
                })
              }
              value={planToCopy.planName}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Description</Label>
            <TextareaAutosize
              minRows={2}
              className="form-control"
              placeholder="Description"
              onChange={e =>
                setPlanToCopy({
                  ...planToCopy,
                  description: e.target.value,
                })
              }
              value={planToCopy.description}
            />
          </div>
        </div>
      )}
      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default CopyPlanModal
