import { mapObjPropsToLowercase } from "helpers/objPropsToLowercaseMapper"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { Col, Container, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { loadPageItem } from "store/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import SelectableAlternativeRatingsGrid from "../_AlternativeShared/SelectableAlternativeRatingsGrid"
import AltGroupRatingModal from "./AltGroupRatingModal"

const AltScoreRatings = props => {
  const scenarioId = props.match.params.scenarioId
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  let [objectives, setObjectives] = useState([])
  let [objectivesAndMeasures, setObjectivesAndMeasures] = useState([])
  let [objectiveDropdownSelection, setObjectiveDropdownSelection] =
    useState(null)
  let [displayDropdownSelection, setDisplayDropdownSelection] = useState({
    text: "Group Ratings",
    value: "Verbal",
  })
  const displayDropdownList = [
    { text: "Numeric Ratings", value: "Ratings" },
    { text: "Mean Scores", value: "Scores" },
    { text: "Group Ratings", value: "Verbal" },
  ]
  let [isLoading, setIsLoading] = useState(false)
  let [gridData, setGridData] = useState([])
  let [scenario, setScenario] = useState(null)
  let [participantsDropdownList, setParticipantsDropdownList] = useState([])
  let [participantsDropdownSelection, setParticipantsDropdownSelection] =
    useState({ text: "Group", value: 0 })
  let [projectScoresDropdownSelection, setProjectScoresDropdownSelection] =
    useState({ text: "Group", value: 0 })

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "AltScoreRatings",
      })
    )
    loadData()
  }, [])

  useEffect(() => {
    if (
      objectiveDropdownSelection != null &&
      displayDropdownSelection != null &&
      participantsDropdownSelection != null &&
      projectScoresDropdownSelection != null
    ) {
      loadGrid()
    }
  }, [
    projectScoresDropdownSelection,
    participantsDropdownSelection,
    objectiveDropdownSelection,
    displayDropdownSelection,
  ])

  const loadData = async () => {
    try {
      setIsLoading(true)
      let scen = await api.getScenario(scenarioId)
      setScenario(scen)
      let objs = await api.getObjectives(scenarioId)
      setObjectives(objs)
      let part = await api.getParticipantsByScenarioId(scenarioId, true)
      setParticipantsDropdownList([
        { text: "Group", value: 0 },
        ...part.map(x => {
          return {
            text: x.user.firstName + " " + x.user.lastName,
            value: x.participantID,
          }
        }),
      ])

      let objsAndMeasures = []
      objs.forEach(o => {
        objsAndMeasures.push(o)
        if (o.measures && o.measures.length > 0) {
          o.measures.forEach(m => {
            objsAndMeasures.push(m)
          })
        }
      })
      setObjectivesAndMeasures(objsAndMeasures)

      setObjectiveDropdownSelection({
        value: 0,
        text: scen.scenarioName,
      })
      // setObjectivesDropdownList([
      //   {
      //     value: 0,
      //     text: scen.scenarioName,
      //   },
      //   {
      //     value: -99,
      //     text: scen.scenarioName + " with Measures",
      //   },
      //   ...objs
      //     .filter(x => x.measures && x.measures.length > 0)
      //     .map(x => {
      //       return {
      //         value: x.objectiveID,
      //         text: x.objectiveName,
      //       }
      //     }),
      // ])
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  const loadGrid = async () => {
    try {
      setIsLoading(true)
      let data = await api.getAlternativeRatingsUsers(
        objectiveDropdownSelection.value,
        scenarioId,
        displayDropdownSelection.value,
        participantsDropdownSelection.value,
        projectScoresDropdownSelection.value
      )
      setGridData(
        mapObjPropsToLowercase(
          data.map(d => {
            d.isSelected = false
            d.isVisible = true
            return d
          })
        )
      )
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Row>
            {participantsDropdownList.length > 0 &&
              participantsDropdownSelection != null && (
                <div className="col-4">
                  <Label style={{ marginBottom: "0px" }}>PWC Weights</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={participantsDropdownSelection.value}
                    onChange={e => {
                      setParticipantsDropdownSelection(
                        participantsDropdownList.find(
                          x => x.value == e.target.value
                        )
                      )
                    }}
                  >
                    {participantsDropdownList.map((r, idx) => {
                      return (
                        <option key={idx} value={`${r.value}`}>
                          {r.text}
                        </option>
                      )
                    })}
                  </select>
                </div>
              )}
            {participantsDropdownList.length > 0 &&
              participantsDropdownSelection != null && (
                <div className="col-4">
                  <Label style={{ marginBottom: "0px" }}>Project Scores</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={projectScoresDropdownSelection.value}
                    onChange={e => {
                      setProjectScoresDropdownSelection(
                        participantsDropdownList.find(
                          x => x.value == e.target.value
                        )
                      )
                    }}
                  >
                    {participantsDropdownList.map((r, idx) => {
                      return (
                        <option key={idx} value={`${r.value}`}>
                          {r.text}
                        </option>
                      )
                    })}
                  </select>
                </div>
              )}

            <div className="col-4">
              <Label style={{ marginBottom: "0px" }}>Show</Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={displayDropdownSelection.value}
                onChange={e => {
                  setDisplayDropdownSelection(
                    displayDropdownList.find(x => x.value == e.target.value)
                  )
                }}
              >
                {displayDropdownList.map((r, idx) => {
                  return (
                    <option key={idx} value={`${r.value}`}>
                      {r.text}
                    </option>
                  )
                })}
              </select>
            </div>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              {!isLoading &&
                gridData != undefined &&
                gridData != null &&
                gridData.length > 0 && (
                  <SelectableAlternativeRatingsGrid
                    data={gridData}
                    setData={setGridData}
                    dropdownSelection={objectiveDropdownSelection}
                    objectives={objectives}
                    objectivesAndMeasures={objectivesAndMeasures}
                    ratingModal={AltGroupRatingModal}
                  />
                )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AltScoreRatings
