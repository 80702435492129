import { TextareaAutosize } from "@material-ui/core"
import { Field, Formik, useFormik } from "formik"
import React, { useEffect, useState } from "react"
import ChatGPTIconWithModal from "components/custom/chatGPTIconWithModal"
import { toast } from "react-toastify"
import { Col, Form, FormFeedback, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import * as Yup from "yup"
import CustomColorPicker from "components/custom/CustomColorPicker"

const AddEditObjectiveModal = ({
  objective,
  isOpen,
  toggleModal,
  scenarioInputRatings,
  reloadGrid,
  scenario,
  addAnotherMeasure,
  addAnotherObjective,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [addAnother, setAddAnother] = useState(false)

  useEffect(() => {
    validation.resetForm()
  }, [objective])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      objectiveName: (objective && objective.objectiveName) || "",
      objShortName: (objective && objective.objShortName) || "",
      description: (objective && objective.description) || "",
      ratingNotes1: (objective && objective.ratingNotes1) || "",
      ratingNotes2: (objective && objective.ratingNotes2) || "",
      ratingNotes3: (objective && objective.ratingNotes3) || "",
      ratingNotes4: (objective && objective.ratingNotes4) || "",
      ratingNotes5: (objective && objective.ratingNotes5) || "",
      ratingNotes6: (objective && objective.ratingNotes6) || "",
      ratingNotes7: (objective && objective.ratingNotes7) || "",
      objColor: (objective && objective.objColor) || "",
    },
    validationSchema: Yup.object({
      objectiveName: Yup.string().required("Please Enter Your Objective Name"),
      description: Yup.string().required(
        "Please Enter Your Objective Description"
      ),
      objShortName: Yup.string(),
      ratingNotes1: Yup.string(),
      ratingNotes2: Yup.string(),
      ratingNotes3: Yup.string(),
      ratingNotes4: Yup.string(),
      ratingNotes5: Yup.string(),
      ratingNotes6: Yup.string(),
      ratingNotes7: Yup.string(),
    }),
    onSubmit: async values => {
      console.log(values)
      let updatedObj = {
        ...objective,
        ...values,
      }
      try {
        if (updatedObj.isMeasure) {
          updatedObj = {
            ...updatedObj,
            measureName: updatedObj.objectiveName,
          }
          if (updatedObj.measureID > 0) {
            await api.updateMeasure(currentUser.userID, updatedObj)
            toast.success(`${measureName} updated successfuly`)
          } else {
            await api.createMeasure(currentUser.userID, updatedObj)
            toast.success(`${measureName} updated successfuly`)
          }
        } else {
          if (updatedObj.objectiveID > 0) {
            await api.updateObjective(currentUser.userID, updatedObj)
            toast.success(`${objectiveName} updated successfuly`)
          } else {
            await api.createObjective(currentUser.userID, updatedObj)
            toast.success(`${objectiveName} created successfuly`)
          }
        }
        if (!addAnother) {
          toggleModal()
        } else {
          if (objective.isMeasure) {
            addAnotherMeasure(objective.objectiveID)
          } else {
            addAnotherObjective()
          }
        }
        reloadGrid(false)
      } catch (err) {
        console.log(err)
        alert(err)
      }
    },
  })

  const deleteObjOrMeasure = async () => {
    if (objective.isMeasure) {
      await api.deleteMeasure(objective.measureID, currentUser.userID)
    } else {
      await api.deleteObjective(objective.objectiveID, currentUser.userID)
    }

    toggleModal()
    reloadGrid(false)
  }

  const saveAndAddAnother = async () => {
    setAddAnother(true)
    await validation.handleSubmit()
  }

  const objectiveName = scenario && scenario.objectiveName
  const measureName = scenario && scenario.subCriteriaName

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      {objective && (
        <>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {objective.isMeasure
                ? objective.measureID > 0
                  ? `Edit ${measureName}`
                  : `Add ${measureName}`
                : objective.objectiveID > 0
                ? `Edit ${objectiveName}`
                : `Add ${objectiveName}`}
            </h5>
            <button
              type="button"
              onClick={() => {
                toggleModal()
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
            >
              <Row form="true">
                <Col xs={12}>
                  <div className="mb-3">
                    <Label className="form-label">
                      {objective.isMeasure ? "Measure" : "Objective"}
                    </Label>
                    <Input
                      name="objectiveName"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.objectiveName || ""}
                      invalid={
                        validation.touched.objectiveName &&
                        validation.errors.objectiveName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.objectiveName &&
                    validation.errors.objectiveName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.objectiveName}
                      </FormFeedback>
                    ) : null}
                  </div>
                  {!objective.isMeasure && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        // justifyContent: "space-between",
                      }}
                    >
                      <div className="mb-3" style={{ marginRight: "30px" }}>
                        <Label className="form-label">Short Name</Label>
                        <Input
                          name="objShortName"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.objShortName}
                        />
                      </div>
                      <CustomColorPicker
                        color={validation.values.objColor}
                        handleColorChange={colorObj => {
                          validation.setFieldValue("objColor", colorObj.hex)
                        }}
                      />
                    </div>
                  )}
                  <div className="mb-3">
                    <Label className="form-label">Description</Label>
                    <ChatGPTIconWithModal
                      scope={objective.isMeasure ? "Measure" : "Objective"}
                      itemId={
                        objective.isMeasure
                          ? objective.measureID
                          : objective.objectiveID
                      }
                      field="Description"
                      appendToField={text =>
                        validation.setFieldValue(
                          "description",
                          objective.description + "\n\n" + text
                        )
                      }
                      replaceField={text =>
                        validation.setFieldValue("description", text)
                      }
                    />
                    <TextareaAutosize
                      name="description"
                      className="form-control"
                      //type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.description || ""}
                      invalid={
                        validation.touched.description &&
                        validation.errors.description
                          ? true
                          : false
                      }
                    />

                    {validation.touched.description &&
                      validation.errors.description != "" && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {validation.errors.description}
                        </div>
                      )}
                  </div>
                  <div className="mb-3">
                    <Label
                      className="form-label"
                      // style={{ marginBottom: "10px" }}
                    >
                      <b>Definition of Rating Scales</b>
                    </Label>
                  </div>
                  {scenarioInputRatings &&
                    scenarioInputRatings.length > 0 &&
                    scenarioInputRatings.map((s, idx) => (
                      <div key={idx} className="mb-3">
                        <Label className="form-label">
                          <div
                            style={{
                              fontSize: "13px",
                              backgroundColor: s.backColor,
                              padding: "4px 15px",
                              width: "160px",
                              borderRadius: "15px",
                              color: s.foreColor,
                              textAlign: "center",
                            }}
                          >
                            {s.ratingName}
                          </div>
                        </Label>
                        <TextareaAutosize
                          name={`ratingNotes${idx + 1}`}
                          minRows={2}
                          className="form-control"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={
                            validation.values[`ratingNotes${idx + 1}`] || ""
                          }
                        />
                      </div>
                    ))}
                </Col>
              </Row>
              <Row>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  <div>
                    {objective &&
                      (objective.objectiveID > 0 ||
                        objective.measureID > 0) && (
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={deleteObjOrMeasure}
                        >
                          Delete
                        </button>
                      )}
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={toggleModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary save-user"
                      onClick={saveAndAddAnother}
                    >
                      Save and Add Another
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary save-user"
                      onClick={() => {
                        setAddAnother(false)
                        validation.handleSubmit()
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
                {/* <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary save-user">
                      Save
                    </button>
                  </div>
                </Col> */}
              </Row>
            </Form>
          </div>
        </>
      )}
      {/* <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>
        <button type="button" className="btn btn-primary ">
          Save changes
        </button>
      </div> */}
    </Modal>
  )
}

export default AddEditObjectiveModal
