import scoringColumns from "pages/AdminObjectiveScore/ScoringColumns"

export const getObjectiveScoresChartOptionsSeries = (pwcUserScores, scen) => {
  const scored = pwcUserScores.filter(x => x.userScored === true).length
 
  let options = {
    chart: {
      id: `basic-bar${Math.random()}`, // should be a different one every re-render
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
      formatter: value => {
        const percentage = parseFloat(
          (parseFloat(value) / scored) * 100
        ).toFixed(2)
        return value + ` (${percentage}%)`
      },
    },
    tooltip: {
      y: {
        formatter: value => {
          const percentage = parseFloat(
            (parseFloat(value) / scored) * 100
          ).toFixed(2)
          return value + ` (${percentage}%)`
        },
      },
    },
  }

  let data = scoringColumns
    .filter(x => scen.ahpScaleID == 2 || x.ahpScaleID == scen.ahpScaleID)
    .map(sc => {
      return {
        x: sc.name,
        y: pwcUserScores.filter(x => x.userScored && x.userRanking == sc.value)
          .length,
      }
    })
  let series = [
    {
      name: "Votes",
      data: data,
    },
  ]
  return { options, series }
}
