import Breadcrumbs from "../../components/Common/Breadcrumb"
import AddEditParticipantModal from "pages/Participants/AddEditParticipantModal"
import React, { useEffect, useState } from "react"
import { Col, Container, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import ParticipantsGrid from "./ParticipantsGrid"
import { useDispatch, useSelector } from "react-redux"
import { changeNavbarParams, loadPageItem } from "store/actions"
import { useUserRight } from "services/role.service"
import { enrichWithPhotoBase64, loadUsersAvatars } from "utils/avatar"

const Participants = props => {
  const scenarioId = props.match.params.scenarioId
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  useUserRight(pageItem, props)
  const [scenario, setScenario] = useState(null)
  let [participants, setParticipants] = useState([])
  let [participant, setParticipant] = useState(null)
  let [scenarioRoles, setScenarioRoles] = useState([])
  let [reviewerRoles, setReviewerRoles] = useState([])
  let [changeRequestRoles, setChangeRequestRoles] = useState([])
  let [criteriaAlternativeModes, setCriteriaAlternativeModes] = useState([])
  let [isLoading, setIsLoading] = useState(false)
  let [showEditModal, setShowEditModal] = useState(false)
  const newParticipant = {
    user: {
      userID: -1,
      email: "",
    },
    userID: -1,
    participantID: -1,
  }

  useEffect(() => {
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        viewName: "participants",
      })
    )
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "participants",
      })
    )
    loadScenario()
    loadParticipants(true)
    loadReviewerRoles()
    loadScenarioRoles()
    loadCriteriaAlternativeModes()
    loadChangeRequestRoles()
  }, [])

  const loadScenario = async () => {
    let s = await api.getScenario(scenarioId)
    setScenario(s)
  }

  const loadParticipants = async toggleLoading => {
    try {
      if (toggleLoading) setIsLoading(true)
      let users = await api.getParticipantsByScenarioId(scenarioId, true)
      users = users.map(x => {
        x.hasProfilePhoto = x.user?.hasProfilePhoto
        return x
      })
      users = await enrichWithPhotoBase64(users, "userID", true)
      setParticipants(
        users.map(u => {
          u.user.fullName = u.user.firstName + " " + u.user.lastName
          return u
        })
      )
      // setParticipants(users)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  const loadReviewerRoles = async () => {
    try {
      let roles = await api.getEnterpriseList(
        currentUser.enterpriseID,
        "ReviewerRole"
      )
      setReviewerRoles(roles)
    } catch (err) {
      console.log(err)
    }
  }

  const loadScenarioRoles = async () => {
    try {
      let roles = await api.getList(currentUser.userID, "ScenarioRole")
      setScenarioRoles(roles)
    } catch (err) {
      console.log(err)
    }
  }

  const loadCriteriaAlternativeModes = async () => {
    try {
      let roles = await api.getList(currentUser.userID, "InteractionTypes")
      setCriteriaAlternativeModes(roles)
    } catch (err) {
      console.log(err)
    }
  }

  const loadChangeRequestRoles = async () => {
    try {
      let roles = await api.getList(currentUser.userID, "CRApprover")
      setChangeRequestRoles(roles)
    } catch (err) {
      console.log(err)
    }
  }

  const toggleEditModal = () => {
    if (showEditModal) {
      setParticipant(newParticipant)
    }
    setShowEditModal(!showEditModal)
  }

  const addParticipant = () => {
    setParticipant(newParticipant)
    toggleEditModal()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Row style={{ marginBottom: "20px" }}>
            <Col
              xs="12"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <button className="btn btn-primary" onClick={addParticipant}>
                + Create New Team Member
              </button>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                }}
              >
                <p style={{ marginBottom: "5px" }}>
                  <b>Group Mode Session Code:</b> M{scenarioId}1
                </p>
                <p style={{ marginBottom: "5px" }}>
                  <b>Individual Mode Session Code:</b> M{scenarioId}2
                </p>
              </div>
            </Col>
          </Row>

          <ParticipantsGrid
            participants={participants}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setParticipant={setParticipant}
            toggleEditModal={toggleEditModal}
            reloadData={loadParticipants}
            scenarioId={scenarioId}
            scenario={scenario}
          />

          <AddEditParticipantModal
            participant={participant}
            setParticipant={setParticipant}
            isOpen={showEditModal}
            toggleModal={toggleEditModal}
            scenarioRoles={scenarioRoles}
            reviewerRoles={reviewerRoles}
            criteriaAlternativeModes={criteriaAlternativeModes}
            changeRequestRoles={changeRequestRoles}
            reloadGrid={loadParticipants}
            scenarioId={scenarioId}
            scenario={scenario}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Participants
