import React from "react"
import {  Input, Label, Modal, Row } from "reactstrap"

const ColumnSelectionModal = ({
  isOpen,
  toggleModal,
  columnsToShow,
  setColumnsToShow,
  tableWidth,
  setTableWidth
}) => {

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Edit Columns
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body"></div>
      <Row>
        {Object.keys(columnsToShow).map((prop, idx) => (
          <div key={idx} className="simple-check-label">
            <Input
              id={`labl-${idx}`}
              name={`labl-${idx}`}
              type="checkbox"
              checked={columnsToShow[prop].show}
              onClick={e => {
                setColumnsToShow({
                  ...columnsToShow,
                  [prop]: {
                    ...columnsToShow[prop],
                    show: !columnsToShow[prop].show,
                  },
                })
                setTableWidth(
                  tableWidth -
                    (prop == "exclude" || prop == "locked" || prop == "complete"
                      ? 50
                      : 250)
                )
              }}
            />
            <Label className="form-check-label" for={`labl-${idx}`}>
              {columnsToShow[prop].header}
            </Label>
          </div>
        ))}
      </Row>
      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
      </div>
    </Modal>
  )
}

export default ColumnSelectionModal
