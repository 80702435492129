import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import ObjectiveScore from "pages/Mobile/ObjectiveScore"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const PwcScoreModal = ({ isOpen, toggleModal, data, reload }) => {
  const currentUser = userService.getLoggedInUser()
  const [error, setError] = useState("")
  const [objectiveScoreProps, setObjectiveScoreProps] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isOpen && data != null && data.participantId > -1) {
      loadData()
    }
  }, [isOpen, data])

  const loadData = async () => {
    setIsLoading(true)
    let participant = await api.getParticipant(data.participantId)
    if (participant != null) {
      let scenario = await api.getScenario(participant.scenarioID)
      if (scenario.pairwiseStatusID == 1) {
        setObjectiveScoreProps({
          scenarioId: scenario.scenarioID,
          mode: participant.pwcTypeID,
        })
      } else {
        setError("Pairwise Status not enabled")
      }
      setIsLoading(false)
    } else {
      setError("Participant not found")
      setIsLoading(false)
    }
  }

  const save = async () => {}

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
        reload && reload()
      }}
      backdrop="static"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Perform PWC
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
            reload && reload()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {error != "" && <p>{error}</p>}
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        {error == "" && objectiveScoreProps != null && (
          <div className="no-background">
            <ObjectiveScore
              col="12"
              height="100%"
              {...objectiveScoreProps}
              selectedPwcScoreId={data?.pwcScoreId}
              selectedLevel={data?.level}
            />
          </div>
        )}
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={() => {
            toggleModal()
            reload && reload()
          }}
        >
          Close
        </button>
      </div>
    </Modal>
  )
}

export default PwcScoreModal
