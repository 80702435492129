import NameAvatar from "components/custom/nameAvatar"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { createRoot } from "react-dom/client"
import Loader from "react-loaders"
import { ReactTabulator } from "react-tabulator"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { enrichWithPhotoBase64 } from "utils/avatar"
import colors from "utils/colors"
import { customFormatter } from "utils/tabulator"

const DocumentDrawer = ({ activeDrawer, alternative, renderAsTable }) => {
  var tabulatorRef = useRef(null)

  const currentUser = userService.getLoggedInUser()
  const [documents, setDocuments] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [documentToEdit, setDocumentToEdit] = useState(null)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [file, setFile] = useState(null)
  const [docTypeOptions, setDocTypeOptions] = useState([])
  const [selectedDocType, setSelectedDocType] = useState("")

  useEffect(() => {
    if (alternative != null && activeDrawer == "Document") {
      loadData()
    }
  }, [activeDrawer, alternative])

  const loadData = async () => {
    try {
      setIsLoading(true)
      await loadDocuments()
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  const loadDocuments = async () => {
    let docs = await api.getDocuments(
      alternative.alternativeID,
      alternative.scenarioID
    )
    let types = [...new Set(docs.map(x => x.docType))].filter(
      x => x !== null && x !== ""
    )
    setDocTypeOptions(types)
    docs = await enrichWithPhotoBase64(docs, "userID")
    setDocuments(docs)
  }

  const toggleEditModal = () => setIsEditModalVisible(!isEditModalVisible)

  const addDocument = () => {
    setFile(null)
    setDocumentToEdit({
      documentID: -1,
      alternativeID: alternative.alternativeID,
      scenarioID: alternative.scenarioID,
      userID: currentUser.userID,
      isLink: false,
      docTitle: "",
      docPath: "",
    })
    toggleEditModal()
  }

  const editDocument = (e, doc) => {
    e && e.preventDefault()
    setFile(null)
    setDocumentToEdit(doc)
    toggleEditModal()
  }

  const saveDocument = async () => {
    if (documentToEdit.documentID > -1) {
      await api.updateDocument(currentUser.userID, documentToEdit)
      loadDocuments()
      toggleEditModal()
    } else {
      if (documentToEdit.isLink) {
        if (!documentToEdit.docPath.toLowerCase().startsWith("http")) {
          toast.error("The link should start with 'http' or 'https'.")
        } else {
          await api.createDocument(currentUser.userID, null, {
            ...documentToEdit,
            docType: "link",
          })
          toggleEditModal()
          loadDocuments()
        }
      } else {
        if (file == null) {
          toast.error("You can not save a document without a file")
        } else {
          await api.createDocument(currentUser.userID, file, documentToEdit)
          toggleEditModal()
          loadDocuments()
        }
      }
    }
  }

  const deleteDocument = document => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this document?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteDocument(document.alternativeID, document.documentID)
          loadDocuments()
        }
      })
  }

  const onFileChange = event => {
    let f = event.target.files[0]
    setFile(f)
  }

  const columns = [
    // {
    //   formatter: "rowSelection",
    //   titleFormatter: "rowSelection",
    //   hozAlign: "center",
    //   headerSort: false,
    //   cellClick: function (e, cell) {
    //     cell.getRow().toggleSelect()
    //   },
    //   width: 70,
    // },

    {
      title: "Document",
      field: "docTitle",
      minWidth: 400,
      headerFilter: "input",
      formatter: customFormatter(({ rowData, cellValue }) => {
        const path = rowData.docPath
        const title = rowData.docTitle
        const isLink = rowData.isLink
        return (
          <a href={path} download="" target="_blank" rel="noreferrer">
            {isLink && (
              <>
                <i className="fas fa-external-link-alt"></i>{" "}
              </>
            )}{" "}
            <b>{title}</b>
          </a>
        )
      }),
    },
    {
      title: "Document Type",
      field: "docType",
      headerFilter: "select",
      headerFilterParams: {
        values: true,
        sortValuesList: "asc",
      },
    },

    {
      title: "User",
      field: "firstName",
      headerFilter: "select",
      headerFilterParams: {
        values: true,
        sortValuesList: "asc",
      },
      formatter: cell => {
        return (
          cell.getRow().getData().firstName +
          " " +
          cell.getRow().getData().lastName
        )
      },
    },
    {
      title: "Date",
      field: "docDT",
      formatter: cell => {
        let d = moment(cell.getRow().getData().docDT).format("MM/DD/YYYY")
        return d
      },
    },
    {
      title: "Delete",
      field: "",
      formatter: customFormatter(({ rowData, cellValue }) => (
        <i
          className="fas fa-trash"
          style={{ color: "red", cursor: "pointer" }}
          onClick={e => deleteDocument(rowData)}
        ></i>
      ))
    },
  ]

  const renderTable = () => {
    return (
      <ReactTabulator
        onRef={r => (tabulatorRef.current = r.current)}
        columns={columns}
        data={documents}
        options={{
          pagination: "local",
          paginationSize: 30,
          paginationSizeSelector: [5, 10, 20, 30, 50, 100, 200, 300],
          layout: columns.length > 6 ? "fitDataStretch" : "fitColumns",
        }}
      />
    )
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          <button
            className="btn btn-primary mb-3"
            style={{ marginTop: "10px" }}
            onClick={addDocument}
          >
            + Add Document or Link
          </button>
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          {docTypeOptions.length > 0 && !renderAsTable && (
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={selectedDocType}
              style={{ marginTop: "15px" }}
              onChange={e => {
                setSelectedDocType(e.target.value)
              }}
            >
              <option value="">All Document Types</option>
              {docTypeOptions.map((r, idx) => {
                return (
                  <option key={idx} value={`${r}`}>
                    {r}
                  </option>
                )
              })}
            </select>
          )}
          {!isLoading && renderAsTable && documents.length > 0 && renderTable()}
          {!isLoading &&
            !renderAsTable &&
            documents.length > 0 &&
            documents
              .filter(
                x => selectedDocType == "" || x.docType == selectedDocType
              )
              .map((d, idx) => {
                return (
                  <div
                    key={idx}
                    style={{
                      width: "100%",
                      borderBottom: "1px solid lightgray",
                      marginTop: "15px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {d.isLink && (
                        <a
                          className="always-enabled"
                          href={"#"}
                          onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            window.open(
                              d.docPath,
                              "_blank",
                              "noopener,noreferrer"
                            )
                          }}
                        >
                          <i className="fas fa-external-link-alt"></i>{" "}
                          <b>{d.docTitle}</b>
                        </a>
                      )}
                      {!d.isLink && (
                        <>
                          <a
                            href={d.docPath}
                            download=""
                            target="_blank"
                            rel="noreferrer"
                            className="always-enabled"
                          >
                            <b>{d.docTitle}</b>
                          </a>
                          {d.docType != "" && (
                            <p style={{ marginBottom: "0px" }}>{d.docType}</p>
                          )}
                        </>
                      )}
                      <p>
                        {moment(d.docDT).format("MM/DD/YYYY")} -{" "}
                        <NameAvatar
                          fullName={`${d.firstName} ${d.lastName}`}
                          showName={true}
                          base64Image={d.photoBase64}
                        />
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "center",
                      }}
                    >
                      <a href="#" onClick={e => editDocument(e, d)}>
                        <i
                          className="fas fa-edit"
                          style={{
                            color: colors.primary,
                          }}
                        ></i>
                      </a>
                      <i
                        className="fas fa-trash"
                        style={{
                          color: "red",
                          cursor: "pointer",
                          marginTop: "4px",
                        }}
                        onClick={() => deleteDocument(d)}
                      ></i>
                    </div>
                  </div>
                )
              })}
        </Col>
      </Row>

      <Modal
        backdrop="static"
        isOpen={isEditModalVisible}
        size="md"
        toggle={() => {
          toggleEditModal()
        }}
      >
        {documentToEdit !== null && (
          <>
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                {documentToEdit.documentID == -1 ? "Add " : "Edit "} Document or
                Link
              </h5>
              <button
                type="button"
                onClick={() => {
                  toggleEditModal()
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-check mb-3">
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="customCheck7"
                  checked={documentToEdit.isLink}
                  onClick={e => {
                    setDocumentToEdit({
                      ...documentToEdit,
                      isLink: !documentToEdit.isLink,
                    })
                  }}
                />
                <Label className="form-check-label" for="customCheck7">
                  Link to website or document
                </Label>
              </div>
              {documentToEdit.isLink &&
                alternative != null &&
                alternative.alternativeID > 0 && (
                  <div className="form-check mb-3">
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      id="projectPlanLink"
                      checked={documentToEdit.projectPlanLink}
                      onClick={e => {
                        setDocumentToEdit({
                          ...documentToEdit,
                          projectPlanLink: !documentToEdit.projectPlanLink,
                        })
                      }}
                    />
                    <Label className="form-check-label" for="projectPlanLink">
                      Link to external project document
                    </Label>
                  </div>
                )}
              {documentToEdit.documentID > -1 && !documentToEdit.isLink && (
                <div className="mb-3">
                  <Label>{documentToEdit.docTitle}</Label>
                </div>
              )}

              {documentToEdit.isLink && (
                <>
                  <div className="mb-3">
                    <Label className="form-label">Link Name</Label>
                    <Input
                      name="note"
                      type="text"
                      onChange={e =>
                        setDocumentToEdit({
                          ...documentToEdit,
                          docTitle: e.target.value,
                        })
                      }
                      value={documentToEdit.docTitle}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Link</Label>
                    <Input
                      name="note"
                      type="text"
                      onChange={e =>
                        setDocumentToEdit({
                          ...documentToEdit,
                          docPath: e.target.value,
                        })
                      }
                      value={documentToEdit.docPath}
                    />
                  </div>
                </>
              )}

              {!documentToEdit.isLink && (
                <>
                  <div className="mb-3">
                    <Label className="form-label">Document Type</Label>
                    <Input
                      name="note"
                      type="text"
                      onChange={e =>
                        setDocumentToEdit({
                          ...documentToEdit,
                          docType: e.target.value,
                        })
                      }
                      value={documentToEdit.docType}
                    />
                  </div>
                  {documentToEdit.documentID == -1 && (
                    <div className="mb-3">
                      <Label
                        className="btn btn-primary"
                        style={{ marginBottom: "0px", fontWeight: "bold" }}
                      >
                        <input
                          type="file"
                          name="myFile"
                          onChange={onFileChange}
                          style={{ display: "none" }}
                        />
                        Upload File
                      </Label>
                      {file != null && (
                        <span style={{ marginLeft: "10px" }}>{file.name}</span>
                      )}
                      {file == null && documentToEdit.documentID > -1 && (
                        <span style={{ marginLeft: "10px" }}>
                          {documentToEdit.docTitle}
                        </span>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )}
        <div
          className="modal-footer"
          style={{
            justifyContent:
              documentToEdit !== null && documentToEdit.documentID > -1
                ? "space-between"
                : "flex-end",
          }}
        >
          {documentToEdit !== null && documentToEdit.documentID > -1 && (
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => deleteDocument(documentToEdit)}
            >
              Delete
            </button>
          )}
          <div>
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={toggleEditModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary save-user"
              onClick={saveDocument}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default DocumentDrawer
