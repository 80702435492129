import React from "react"
import { Avatar } from "@material-ui/core"
import colors from "utils/colors"

//size - xs (default), lg, xl
const NameAvatar = ({
  fullName,
  showName,
  size,
  aiUser,
  onClick,
  base64Image,
}) => {
  const words = fullName != undefined ? fullName.split(" ") : []
  const letters =
    words.length > 0
      ? words[0].charAt(0).toUpperCase() +
        (words.length > 1 ? words[1].charAt(0).toUpperCase() : "")
      : ""
  const isNormalColor = aiUser == undefined || aiUser == false // otherwise Orange

  const actualSize = size == undefined ? "xs" : size
  const fontSize =
    actualSize == "xs" ? "10px" : actualSize == "lg" ? "14px" : "30px"
  const circleSize =
    actualSize == "xs" ? "20px" : actualSize == "lg" ? "30px" : "60px"

  if (fullName != undefined && fullName != "") {
    if (showName) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Avatar
            src={base64Image || ""}
            style={{
              backgroundColor: isNormalColor ? colors.primary : colors.orange,
              fontSize: fontSize,
              height: circleSize,
              width: circleSize,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {letters}
          </Avatar>
          <span style={{ display: "inline-block", marginLeft: "10px" }}>
            {fullName}
          </span>
        </div>
      )
    } else {
      return (
        <Avatar
          style={{
            backgroundColor: colors.primary,
            fontSize: fontSize,
            height: circleSize,
            width: circleSize,
          }}
          onClick={() => {
            onClick && onClick()
          }}
          src={base64Image || ""}
        >
          {letters}
        </Avatar>
      )
    }
  } else {
    return null
  }
}

export default NameAvatar
