import React, { useEffect, useState } from "react"
import { Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import Loader from "react-loaders"
import colors from "utils/colors"
import { swalWithConfirmButton } from "components/custom/swal"
import { CompareItem } from "./AlternativeCompareModal"

const BaselineComparisonsModal = ({ isOpen, toggleModal, alternative }) => {
  const [alternatives, setAlternatives] = useState([])
  const [compareItems, setCompareItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [alt1, setAlt1] = useState(-1)
  const [alt2, setAlt2] = useState(-1)

  useEffect(() => {
    if (isOpen) {
      setAlt1(-1)
      setAlt2(-1)
      setAlternatives([])
      setCompareItems([])
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    setIsLoading(true)
    let items = await api.getAlternativeBaselines(alternative.alternativeID)
    setAlternatives(items)

    let baseline = items.find(x => x.listItemName == "Current Baseline")
    if (baseline !== undefined) {
      setAlt1(baseline.listItemID)
    }
    let revision = items.find(x => x.listItemName == "Revision")
    if (revision !== undefined) {
      setAlt2(revision.listItemID)
    }

    setIsLoading(false)
  }

  const compare = async () => {
    if (alt1 == -1 || alt2 == -1) {
      swalWithConfirmButton.fire({
        title: "Please select the versions to compare.",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    } else if (alt1 == alt2) {
      swalWithConfirmButton.fire({
        title: "You cannot compare the same version with itself.",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    } else {
      let min = Math.min(parseInt(alt1), parseInt(alt2))
      let max = Math.max(parseInt(alt1), parseInt(alt2))
      let items = await api.getCompareItems(min, max)
      setCompareItems(items)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      size="xl"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Baseline Comparisons
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          {!isLoading && alternatives.length <= 1 && (
            <p>There are no baselines to compare</p>
          )}
          {!isLoading && alternatives.length >= 2 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>Compare</span>
              <select
                style={{ width: "230px" }}
                className="form-control form-select select2 mb-xxl-0"
                value={alt1}
                onChange={e => {
                  setAlt1(e.target.value)
                }}
              >
                <option value="-1">Select Option</option>
                {alternatives.map((s, i) => (
                  <option key={i} value={s.listItemID}>
                    {s.listItemName}
                  </option>
                ))}
              </select>
              <span>to</span>
              <select
                style={{ width: "230px" }}
                className="form-control form-select select2 mb-xxl-0"
                value={alt2}
                onChange={e => {
                  setAlt2(e.target.value)
                }}
              >
                <option value="-1">Select Option</option>
                {alternatives.map((s, i) => (
                  <option key={i} value={s.listItemID}>
                    {s.listItemName}
                  </option>
                ))}
              </select>
              <button
                type="button"
                className="btn btn-primary save-user"
                onClick={compare}
              >
                Show Comparison
              </button>
            </div>
          )}
          {compareItems.map((x, idx) => (
            <CompareItem key={idx} compareItem={x} />
          ))}
        </Row>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
      </div>
    </Modal>
  )
}

export default BaselineComparisonsModal
