import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import { getObjectiveScoresChartOptionsSeries } from "pages/_ObjectiveShared/objectiveChartFunctions"
import ObjectiveRatingTable from "pages/_ObjectiveShared/ObjectiveRatingTable"
import React, { useEffect, useRef, useState } from "react"
import ReactApexChart from "react-apexcharts"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import {
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Row,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { loadPageItem } from "store/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { toast } from "react-toastify"
import ObjectiveInfoContainer from "pages/_ObjectiveShared/ObjectiveInfoContainer"
import { loadUsersAvatars } from "utils/avatar"

const AdminObjectiveFacilitate = props => {
  const scenarioId = props.match.params.scenarioId
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  let [nextObjectivePwcScore, setNextObjectivePwcScore] = useState(null)
  let [pwcUserScores, setPwcUserScores] = useState([])
  let [isLoading, setIsLoading] = useState(false)
  let [scenario, setScenario] = useState(null)

  let [showActionsDropdown, setShowActionsDropdown] = useState(false)
  let [awaitingParticipants, setAwaitingParticipants] = useState(false)
  let [showGroupRating, setShowGroupRating] = useState(false)
  let [showMeanScore, setShowMeanScore] = useState(false)
  let [showStandardDev, setShowStandardDev] = useState(false)
  let [showParticipants, setShowParticipants] = useState(false)
  let [showChart, setShowChart] = useState(false)
  let [showGrid, setShowGrid] = useState(false)
  let [showDefinitions, setShowDefinitions] = useState(false)
  let [chartData, setChartData] = useState(null)
  let [currentPwcScoreId, setCurrentPwcScoreId] = useState(-1)
  let [currentLevelId, setCurrentLevelId] = useState(-1)
  let [isPooling, setIsPooling] = useState(false)
  let [locked, setLocked] = useState(false)
  const poolingInterval = useRef(null)

  const [usersPhotos, setUsersPhotos] = useState([])

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "adminObjectiveFacilitate",
      })
    )
    loadNextData(0, 1, true)
    loadScenario()

    return () => clearInterval(poolingInterval.current)
  }, [])

  useEffect(() => {
    clearInterval(poolingInterval.current)
    if (isPooling) {
      poolingInterval.current = setInterval(async () => {
        await loadCurrentData()
      }, 4000)
    }
  }, [isPooling])

  useEffect(() => {
    if (scenario != null && pwcUserScores != null) {
      setChartData(
        getObjectiveScoresChartOptionsSeries(pwcUserScores, scenario)
      )
    }
  }, [scenario, pwcUserScores])

  const loadNextData = async (pwcScoreId, levelId, loadUsersPhotos = false) => {
    clearInterval(poolingInterval.current)
    handleCheckboxSelection()
    setIsPooling(false)
    let objScore = await loadNextObjectivePwcScore(pwcScoreId, levelId)
    await api.setCurrentGroupPwcScoreId(
      scenarioId,
      objScore.pwcScoreID,
      objScore.levelID
    )

    setNextObjectivePwcScore(objScore)
    setCurrentPwcScoreId(objScore.pwcScoreID)
    setCurrentLevelId(objScore.levelID)

    let userScores = await loadPwcUserScores(
      objScore.pwcScoreID,
      objScore.levelID
    )
    
    if (loadUsersPhotos) {
      let avatars = await loadUsersAvatars(userScores.map(x => x.userID))
      setUsersPhotos(avatars)
    }

    setPwcUserScores(userScores)
    setIsPooling(true)
  }

  const loadPrevData = async (pwcScoreId, levelId) => {
    clearInterval(poolingInterval.current)
    handleCheckboxSelection()
    setIsPooling(false)
    let objScore = await loadPrevObjectivePwcScore(pwcScoreId, levelId)
    await api.setCurrentGroupPwcScoreId(
      scenarioId,
      objScore.pwcScoreID,
      objScore.levelID
    )
    setNextObjectivePwcScore(objScore)
    setCurrentPwcScoreId(objScore.pwcScoreID)
    setCurrentLevelId(objScore.levelID)

    let userScores = await loadPwcUserScores(
      objScore.pwcScoreID,
      objScore.levelID
    )
    setPwcUserScores(userScores)
    setIsPooling(true)
  }

  const handleCheckboxSelection = () => {
    if (!locked) {
      setAwaitingParticipants(false)
      setShowGroupRating(false)
      setShowMeanScore(false)
      setShowStandardDev(false)
      setShowParticipants(false)
      setShowChart(false)
      setShowGrid(false)
      setShowDefinitions(false)
    }
  }

  const loadCurrentData = async () => {
    if (currentLevelId > -1 && currentPwcScoreId > -1) {
      setIsLoading(true)
      let objScore = await api.getPwcScore(currentPwcScoreId, currentLevelId)
      setNextObjectivePwcScore(objScore)

      let userScores = await loadPwcUserScores(
        currentPwcScoreId,
        currentLevelId
      )
      setPwcUserScores(userScores)
      setIsLoading(false)
    }
  }

  const loadNextObjectivePwcScore = async (pwcScoreId, levelId) => {
    try {
      setIsLoading(true)
      let objScore = await api.getNextPwcScore(pwcScoreId, levelId, scenarioId)
      setIsLoading(false)

      return objScore
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }

  const loadPrevObjectivePwcScore = async (pwcScoreId, levelId) => {
    try {
      setIsLoading(true)
      let objScore = await api.getPrevPwcScore(pwcScoreId, levelId, scenarioId)
      setIsLoading(false)
      return objScore
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }

  const loadPwcUserScores = async (pwcScoreId, levelId) => {
    try {
      let scores = await api.getPwcUserScores(pwcScoreId, levelId)
      return scores
    } catch (err) {
      console.log(err)
    }
  }

  const loadScenario = async () => {
    try {
      var scenario = await api.getScenario(scenarioId)
      setScenario(scenario)
    } catch (err) {
      console.log(err)
    }
  }

  const resetScores = async () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to reset judgments?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          setIsLoading(true)
          await api.resetObjectiveScores(scenarioId, currentUser.userID)
          await loadCurrentData()
          toast.success("Scores reset successfully")
          setIsLoading(false)
        }
      })
  }

  const updateScenarioPairwiseStatus = async statusId => {
    await api.updateScenario(currentUser.userID, {
      ...scenario,
      pairwiseStatusID: statusId,
    })
    toast.success("Status set successfully")
    await loadScenario()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <div className="card" style={{ padding: "10px" }}>
            <Row>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <button
                    type="button"
                    className={
                      isPooling
                        ? "btn btn-outline-primary"
                        : "btn btn-outline-danger"
                    }
                    onClick={() => setIsPooling(!isPooling)}
                  >
                    {isPooling ? "Polling" : "Not Polling"}
                  </button>
                  <button
                    className="btn btn-primary save-user"
                    onClick={loadCurrentData}
                  >
                    <i className="fas fa-sync"></i> Refresh
                  </button>
                </div>
                <Dropdown
                  isOpen={showActionsDropdown}
                  toggle={() => setShowActionsDropdown(!showActionsDropdown)}
                >
                  <DropdownToggle
                    tag="button"
                    className="btn btn-outline-danger"
                  >
                    Actions <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={resetScores}>
                      <i className="fas fa-redo"></i> Reset Judgments
                    </DropdownItem>
                    {scenario != null && scenario.pairwiseStatusID == 1 && (
                      <DropdownItem
                        onClick={() => updateScenarioPairwiseStatus(110)}
                        style={{ color: "red" }}
                      >
                        <i className="fas fa-times"></i> Close Scoring
                      </DropdownItem>
                    )}
                    {scenario != null && scenario.pairwiseStatusID == 110 && (
                      <DropdownItem
                        onClick={() => updateScenarioPairwiseStatus(1)}
                        style={{ color: "green" }}
                      >
                        <i className="fas fa-star"></i> Open Scoring
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </Dropdown>
                {/* <button
                className="btn btn-outline-danger save-user"
                onClick={resetScores}
              >
                Reset Judgments
              </button> */}
              </div>
            </Row>
            {awaitingParticipants && (
              <Row style={{ marginTop: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color:
                        pwcUserScores.filter(x => x.userScored).length ==
                        pwcUserScores.length
                          ? "green"
                          : "red",
                    }}
                  >
                    Participants submitted:{" "}
                    {pwcUserScores.filter(x => x.userScored).length} of{" "}
                    {pwcUserScores.length}
                  </span>
                  <span style={{ marginLeft: "10px", fontStyle: "italic" }}>
                    {pwcUserScores.filter(x => !x.userScored).length > 0
                      ? "Awaiting: " +
                        pwcUserScores
                          .filter(x => !x.userScored)
                          .map(x => x.firstName + " " + x.lastName)
                          .join(", ")
                      : "All have scored"}
                  </span>
                </div>
              </Row>
            )}
            <Row style={{ marginTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                }}
              >
                <div className="form-check">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck1"
                    checked={awaitingParticipants}
                    onClick={e => {
                      setAwaitingParticipants(!awaitingParticipants)
                    }}
                  />
                  <Label className="form-check-label" for="customCheck1">
                    Awaiting Participants
                  </Label>
                </div>
                <div className="form-check" style={{ marginLeft: "10px" }}>
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck2"
                    checked={showGroupRating}
                    onClick={e => {
                      setShowGroupRating(!showGroupRating)
                    }}
                  />
                  <Label className="form-check-label" for="customCheck2">
                    Group Rating
                  </Label>
                </div>
                <div className="form-check" style={{ marginLeft: "10px" }}>
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck3"
                    checked={showMeanScore}
                    onClick={e => {
                      setShowMeanScore(!showMeanScore)
                    }}
                  />
                  <Label className="form-check-label" for="customCheck3">
                    Mean Score
                  </Label>
                </div>
                <div className="form-check" style={{ marginLeft: "10px" }}>
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck4"
                    checked={showStandardDev}
                    onClick={e => {
                      setShowStandardDev(!showStandardDev)
                    }}
                  />
                  <Label className="form-check-label" for="customCheck4">
                    Consensus
                  </Label>
                </div>
                <div className="form-check" style={{ marginLeft: "10px" }}>
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck5"
                    checked={showParticipants}
                    onClick={e => {
                      setShowParticipants(!showParticipants)
                    }}
                  />
                  <Label className="form-check-label" for="customCheck5">
                    Participant List
                  </Label>
                </div>
                <div className="form-check" style={{ marginLeft: "10px" }}>
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck6"
                    checked={showChart}
                    onClick={e => {
                      setShowChart(!showChart)
                    }}
                  />
                  <Label className="form-check-label" for="customCheck6">
                    Response Graph
                  </Label>
                </div>
                <div className="form-check" style={{ marginLeft: "10px" }}>
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck7"
                    checked={showGrid}
                    onClick={e => {
                      if (!showGrid) {
                        setShowParticipants(true)
                      }
                      setShowGrid(!showGrid)
                    }}
                  />
                  <Label className="form-check-label" for="customCheck7">
                    Response Grid
                  </Label>
                </div>
                <div className="form-check" style={{ marginLeft: "10px" }}>
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck8"
                    checked={showDefinitions}
                    onClick={e => {
                      setShowDefinitions(!showDefinitions)
                    }}
                  />
                  <Label className="form-check-label" for="customCheck8">
                    Definitions
                  </Label>
                </div>
                <div className="form-check" style={{ marginLeft: "10px" }}>
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="lockcheck"
                    checked={locked}
                    onClick={e => {
                      setLocked(!locked)
                    }}
                  />
                  <Label className="form-check-label" for="lockcheck">
                    Lock
                  </Label>
                </div>
              </div>
            </Row>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginLeft: "13%",
              marginRight: "0%",
              marginTop: "10px",
            }}
          >
            {nextObjectivePwcScore != null && (
              <button
                type="button"
                className="btn btn-primary save-user"
                // style={{ marginRight: "10px" }}
                onClick={async () =>
                  await loadPrevData(
                    nextObjectivePwcScore.pwcScoreID,
                    nextObjectivePwcScore.levelID
                  )
                }
              >
                <i className="fas fa-arrow-left"></i> Prev
              </button>
            )}
            {isLoading && (
              <Loader
                type="line-scale-pulse-out"
                color={colors.primary}
                style={{ textAlign: "center", height: "15px" }}
              />
            )}
            {nextObjectivePwcScore != null && (
              <button
                type="button"
                className="btn btn-primary save-user"
                onClick={async () =>
                  await loadNextData(
                    nextObjectivePwcScore.pwcScoreID,
                    nextObjectivePwcScore.levelID
                  )
                }
              >
                Next <i className="fas fa-arrow-right"></i>
              </button>
            )}
          </div>

          <Row style={{ marginLeft: "13%", marginRight: "0%" }}>
            <ObjectiveInfoContainer
              nextObjectivePwcScore={nextObjectivePwcScore}
              scenario={scenario}
              showDefinitions={showDefinitions}
              showStandardDev={showStandardDev}
              showMeanScore={showMeanScore}
            />
          </Row>

          {scenario != null &&
            pwcUserScores != null &&
            pwcUserScores.length > 0 && (
              <>
                {showGroupRating && (
                  <GroupRating
                    avgVerbal={nextObjectivePwcScore?.avgVerbal}
                    avgUserRanking={nextObjectivePwcScore?.avgUserRanking}
                  />
                )}

                {showGrid && (
                  <Row
                    style={{
                      marginTop: "10px",
                      marginLeft: !showParticipants ? "14%" : "initial",
                    }}
                  >
                    <ObjectiveRatingTable
                      scenario={scenario}
                      userScores={pwcUserScores}
                      // setUserScores={setPwcUserScores}
                      pwcScore={nextObjectivePwcScore}
                      hasParticipants={showParticipants}
                      usersPhotos={usersPhotos}
                    />
                  </Row>
                )}
                {showChart && chartData != null && (
                  <BarChart
                    options={chartData.options}
                    series={chartData.series}
                  />
                  // <Row
                  //   style={{
                  //     marginTop: "10px",
                  //     marginLeft: "10%",
                  //   }}
                  // >
                  //   <ReactApexChart
                  //     options={chartData.options}
                  //     series={chartData.series}
                  //     style={{ backgroundColor: "white" }}
                  //     type="bar"
                  //     height="250"
                  //   />
                  // </Row>
                )}
              </>
            )}
        </Container>
      </div>
    </React.Fragment>
  )
}

const BarChart = React.memo(function barChart({ options, series }) {
  return (
    <Row
      style={{
        marginTop: "10px",
        marginLeft: "10%",
      }}
    >
      <ReactApexChart
        options={options}
        series={series}
        style={{ backgroundColor: "white" }}
        type="bar"
        height="250"
      />
    </Row>
  )
})

const GroupRating = React.memo(function groupRating({
  avgVerbal,
  avgUserRanking,
}) {
  return (
    <Row
      style={{
        marginTop: "10px",
        marginLeft: "13%",
        fontWeight: "bold",
      }}
    >
      {avgVerbal.toLowerCase() == "equal" ? (
        <div style={{ width: "100%", textAlign: "center" }}>{avgVerbal}</div>
      ) : avgUserRanking >= 1 ? (
        <div
          style={{
            width: "100%",
            paddingLeft: "9px",
            paddingRight: "9px",
          }}
        >
          {avgVerbal}
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            paddingLeft: "9px",
            paddingRight: "9px",
          }}
        >
          {avgVerbal}
        </div>
      )}
    </Row>
  )
})

export default AdminObjectiveFacilitate
