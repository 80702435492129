import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import api from "services/api.service"
import Loader from "react-loaders"
import colors from "utils/colors"
import RunViewModal from "components/GlobalModals.js/RunViewModal"
import RunReportModal from "components/GlobalModals.js/RunReportModal"
import SelectScenariosModal from "components/GlobalModals.js/SelectScenariosModal"
import RunTelerikUserReportModal from "components/GlobalModals.js/RunTelerikUserReportModal"

const SidebarContent = props => {
  const ref = useRef()
  const { navbarParams } = useSelector(state => ({
    navbarParams: state.navbar.navbarParams,
  }))

  let [menuItems, setMenuItems] = useState([])
  let [selectedMenuItemId, setSelectedMenuItemId] = useState(null)
  let [selectedParentMenuItemId, setSelectedParentMenuItemId] = useState(null)

  const [runReportReportData, setRunReportReportData] = useState(null)
  const [showRunReportModal, setShowRunReportModal] = useState(false)
  const toggleRunReportModal = () => setShowRunReportModal(!showRunReportModal)
  const [runViewScenarioId, setRunViewScenarioId] = useState(0)
  const [runViewViewId, setRunViewViewId] = useState(0)
  const [runScenarioModalId, setRunScenarioModalId] = useState(0)
  const [runTelerikUserReportId, setRunTelerikUserReportId] = useState(0)
  const [showTelerikUserReportModal, setShowTelerikUserReportModal] =
    useState(false)
  const toggleTelerikUserReportModal = () =>
    setShowTelerikUserReportModal(!showTelerikUserReportModal)
  const [showRunViewModal, setShowRunViewModal] = useState(false)
  const toggleRunViewModal = () => setShowRunViewModal(!showRunViewModal)
  const [showRunScenarioModal, setShowRunScenarioModal] = useState(false)
  const toggleRunScenarioModal = () =>
    setShowRunScenarioModal(!showRunScenarioModal)

  useEffect(() => {
    let params
    if (navbarParams.userId == 0) {
      params = JSON.parse(localStorage.getItem("navbarParams"))
    } else {
      params = navbarParams
    }
    handleMenuChange(params)
  }, [navbarParams])

  const handleMenuChange = async navbarParams => {
    let menuItems = await api.getMenuItems(
      navbarParams.userId,
      navbarParams.scenarioId,
      navbarParams.viewName
    )

    menuItems.forEach(item => {
      for (var i = 0; i < menuItems.length; i++) {
        if (
          item.menuItemID !== menuItems[i].menuItemID &&
          item.menuItemID == menuItems[i].parentID
        ) {
          if (item.children == undefined) {
            item.children = [menuItems[i]]
          } else {
            item.children.push(menuItems[i])
          }
        }
      }
    })
    menuItems = menuItems
      .filter(x => x.parentID == 0)
      .filter(
        x => x.children != undefined || (x.linkView != null && x.linkView != "")
      )
    setMenuItems(menuItems)
  }

  const handleSubMenuClick = (e, menuItem) => {
    e && e.preventDefault()
    if (menuItem.linkView.indexOf("Modal_RunView") > -1) {
      let parts = menuItem.linkView.split("/")
      setRunViewScenarioId(parts[1])
      if (parts.length == 3) {
        setRunViewViewId(parts[2])
      }
      toggleRunViewModal()
    } else if (menuItem.linkView.indexOf("Modal_Report") > -1) {
      let parts = menuItem.linkView.split("/")
      setRunReportReportData({
        reportId: parts[1],
        scenarioId: parts[2],
      })
      toggleRunReportModal()
    } else if (menuItem.linkView.indexOf("Modal_SetScenario") > -1) {
      let parts = menuItem.linkView.split("/")
      setRunScenarioModalId(parts[1])
      toggleRunScenarioModal()
    } else if (menuItem.linkView.indexOf("Modal_UserReport") > -1) {
      let parts = menuItem.linkView.split("/")
      setRunTelerikUserReportId(parts[1])
      toggleTelerikUserReportModal()
    } else {
      props.history.push(`/${menuItem.linkView}`)
    }
    setSelectedMenuItemId(menuItem.menuItemID)
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          {(!menuItems || menuItems.length == 0) && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{props.t("Menu")} </li> */}
            {menuItems &&
              menuItems.length > 0 &&
              menuItems.map((m, idx) => (
                <li key={idx}>
                  {m.header && (
                    <Link
                      style={{ backgroundColor: "gray", height: "100%" }}
                      to={`#`}
                      onClick={e => {
                        e && e.preventDefault()
                      }}
                    >
                      <span>{m.name}</span>
                    </Link>
                  )}
                  {!m.header && (
                    <Link
                      id={m.menuItemID}
                      to="#"
                      className={
                        !m.children || m.children.length == 0
                          ? ""
                          : m.children &&
                            m.children.find(
                              x => x.menuItemID == selectedMenuItemId
                            ) != undefined
                          ? "has-arrow mm-active"
                          : selectedParentMenuItemId == m.menuItemID
                          ? "has-arrow"
                          : "has-arrow mm-collapse"
                      }
                      aria-expanded={
                        selectedParentMenuItemId == m.menuItemID
                          ? "true"
                          : "false"
                      }
                      onClick={() => {
                        setSelectedParentMenuItemId(
                          selectedParentMenuItemId == m.menuItemID
                            ? null
                            : m.menuItemID
                        )

                        if (m.linkView != null && m.linkView != "") {
                          let reload = false
                          try {
                            if (
                              m.refresh &&
                              `/${m.linkView.toLowerCase()}`.indexOf(
                                window.location.pathname.toLowerCase()
                              ) > -1
                            ) {
                              reload = true
                            }
                          } catch {
                            reload = false
                          }

                          props.history.push(`/${m.linkView}`)
                          if (reload) {
                            window.location.reload()
                          }
                        }
                      }}
                    >
                      {m.icon !== null && m.icon !== "" && (
                        <i className={m.icon}></i>
                      )}
                      <span>{m.name}</span>
                    </Link>
                  )}
                  {m.children && m.children.length > 0 && (
                    <ul
                      className={
                        selectedParentMenuItemId == m.menuItemID
                          ? "sub-menu mm-show"
                          : "sub-menu mm-collapse"
                      }
                    >
                      {m.children.map((ch, chIdx) => (
                        <li
                          key={chIdx * 100}
                          className={
                            selectedMenuItemId == ch.menuItemID
                              ? "mm-active"
                              : ""
                          }
                        >
                          <Link
                            to={`#`}
                            id={ch.menuItemID}
                            onClick={e => {
                              handleSubMenuClick(e, ch)
                            }}
                            className={
                              selectedMenuItemId == ch.menuItemID
                                ? "active"
                                : ""
                            }
                          >
                            {ch.icon !== null && ch.icon !== "" && (
                              <i className={ch.icon}></i>
                            )}
                            {ch.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
          </ul>
        </div>
      </SimpleBar>
      <RunViewModal
        isOpen={showRunViewModal}
        toggleModal={toggleRunViewModal}
        scenarioId={runViewScenarioId}
        viewId={runViewViewId}
      />
      <RunReportModal
        isOpen={showRunReportModal}
        toggleModal={toggleRunReportModal}
        reportData={runReportReportData}
      />
      <SelectScenariosModal
        isOpen={showRunScenarioModal}
        toggleModal={toggleRunScenarioModal}
        scenarioId={runScenarioModalId}
      />
      <RunTelerikUserReportModal
        isOpen={showTelerikUserReportModal}
        toggleModal={toggleTelerikUserReportModal}
        telerikUserReportId={runTelerikUserReportId}
      />
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
