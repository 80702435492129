export const disableAll = () => {
  let div = document.getElementsByClassName("page-content")
  div[0].classList.add("read-only-access")

  let inputs = document
    .getElementsByClassName("page-content")[0]
    .getElementsByTagName("input")
  let buttons = document
    .getElementsByClassName("page-content")[0]
    .getElementsByTagName("button")
  let selects = document
    .getElementsByClassName("page-content")[0]
    .getElementsByTagName("select")
  let textareas = document
    .getElementsByClassName("page-content")[0]
    .getElementsByTagName("textarea")
  let buttonsFinal = [...buttons].filter(
    x => !x.parentElement.classList.contains("always-enabled")
  )
  let list = [...inputs, ...buttonsFinal, ...selects, ...textareas].filter(
    x => x.checkVisibility() && !x.classList.contains("always-enabled")
  )
  for (let i = 0; i < list.length; i++) {
    list[i].disabled = true
  }
}

export const enableAll = () => {
  let inputs = document
    .getElementsByClassName("page-content")[0]
    .getElementsByTagName("input")
  let buttons = document
    .getElementsByClassName("page-content")[0]
    .getElementsByTagName("button")
  let selects = document
    .getElementsByClassName("page-content")[0]
    .getElementsByTagName("select")
  let textareas = document
    .getElementsByClassName("page-content")[0]
    .getElementsByTagName("textarea")

  let list = [...inputs, ...buttons, ...selects, ...textareas]
  list = list.filter(x => x.className.indexOf("always-disabled") == -1)
  for (let i = 0; i < list.length; i++) {
    list[i].disabled = false
  }
}
