import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const CriteriaScenarioModal = ({ isOpen, toggleModal, onSave }) => {
  const [scenarioName, setScenarioName] = useState("")

  useEffect(() => {
    if (isOpen) {
      setScenarioName("")
    }
  }, [isOpen])

  const save = async () => {
    await onSave(scenarioName)
    toggleModal()
  }

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Criteria Scenario
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <Label className="form-label">Scenario Name</Label>
          <Input
            name="note"
            type="text"
            onChange={e => setScenarioName(e.target.value)}
            value={scenarioName}
          />
        </div>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default CriteriaScenarioModal
