import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import useFetcher from "hooks/useFetcher"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const EditRateModal = ({
  isOpen,
  toggleModal,
  rateToEdit,
  setRateToEdit,
  reload,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [rateTypes] = useFetcher(
    () => api.getList(currentUser.userID, "RateType"),
    []
  )

  const deleteRate = async()=>{
    await api.deleteEnterpriseBcRate(currentUser.userID, rateToEdit.bcRateID)
    toggleModal()
    reload && reload()
  }

  const save = async () => {
    if (rateToEdit.bcRateID == -1) {
      await api.createEnterpriseBcRate(currentUser.userID, rateToEdit)
    } else {
      await api.updateEnterpriseBcRate(currentUser.userID, rateToEdit)
    }
    toast.success("Rate saved")
    toggleModal()
    reload && reload()
  }

  if (rateToEdit == null) return
  else
    return (
      <Modal
        isOpen={isOpen}
        size="md"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Rate
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <Label className="form-label">Name</Label>
            <Input
              name="note"
              type="text"
              onChange={e =>
                setRateToEdit({
                  ...rateToEdit,
                  bcRateName: e.target.value,
                })
              }
              value={rateToEdit.bcRateName}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Type</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={rateToEdit.rateType}
              onChange={e => {
                setRateToEdit({
                  ...rateToEdit,
                  rateType: e.target.value,
                })
              }}
            >
              <option value="-1">Select type...</option>
              {rateTypes?.map((r, idx) => (
                <option key={idx} value={r.listItemName}>
                  {r.listItemName}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div
          className="modal-footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>
          {rateToEdit.bcRateID != -1 && (
            <button
              type="button"
              className="btn btn-danger"
              style={{ marginRight: "auto" }}
              onClick={deleteRate}
            >
              Delete
            </button>
          )}
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={save}
            disabled={
              rateToEdit == null ||
              rateToEdit.bcRateName == "" ||
              rateToEdit.rateType == "-1"
            }
          >
            Save
          </button>
        </div>
      </Modal>
    )
}

export default EditRateModal
