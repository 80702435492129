import { TextareaAutosize } from "@material-ui/core"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const AddEntityModal = ({ isOpen, toggleModal, entityTypeId, reloadData }) => {
  const currentUser = userService.getLoggedInUser()
  const [entities, setEntities] = useState([])
  const [entity, setEntity] = useState({
    entityID: -1,
    parentID: 0,
    entityAbbreviation: "",
    entityName: "",
    addReason: "",
    parentID: -1,
  })

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    if (isOpen) {
      setEntity({
        entityID: -1,
        parentID: 0,
        entityAbbreviation: "",
        entityName: "",
        addReason: "",
        parentID: entities.length > 0 ? entities[0].entityID : -1,
      })
    }
  }, [isOpen])

  const loadData = async () => {
    let e = await api.getEntities(currentUser.userID, entityTypeId)
    setEntities(e)
  }

  const save = async () => {
    if (entity.parentID == -1) {
      toast.error("You can not save an Entity without a parent")
    } else {
      await api.createEntity(currentUser.userID, {
        ...entity,
        enterpriseID: currentUser.enterpriseID,
        entityTypeID: entityTypeId,
      })
      reloadData()
      toggleModal()
    }
  }

  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Add Entity
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <Label className="form-label">Parent</Label>
          <select
            className="form-control form-select select2 mb-xxl-0"
            value={entity.parentID}
            onChange={e => {
              setEntity({
                ...entity,
                parentID: e.target.value,
              })
            }}
          >
            <option value="-1">Select Parent</option>
            {entities.map((x, idx) => (
              <option key={idx} value={x.entityID}>
                {x.entityName}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <Label className="form-label">Name</Label>
          <Input
            name="note"
            type="text"
            onChange={e =>
              setEntity({
                ...entity,
                entityName: e.target.value,
              })
            }
            value={entity.entityName}
          />
        </div>
        <div className="mb-3">
          <Label className="form-label">Abbreviation</Label>
          <Input
            name="note"
            type="text"
            onChange={e =>
              setEntity({
                ...entity,
                entityAbbreviation: e.target.value,
              })
            }
            value={entity.entityAbbreviation}
          />
        </div>
        <div className="mb-3">
          <Label className="form-label">Reason for Addition</Label>
          <TextareaAutosize
            minRows={2}
            className="form-control"
            onChange={e =>
              setEntity({
                ...entity,
                addReason: e.target.value,
              })
            }
            value={entity.addReason}
          />
        </div>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default AddEntityModal
