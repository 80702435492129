import React, { useEffect, useRef, useState } from "react"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import Geocode from "react-geocode"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import api from "services/api.service"

const EditAddressLocation = ({
  locationToEdit,
  changeLocationProp,
  setLocationToEdit,
  addressLocationRef,
  setMarkers,
  setAddressValidated,
}) => {

  useEffect(() => {
    addressLocationRef.current = {
      onMapClick,
    }
  }, [])

  const onMapClick = e => {
    Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
      resp => {
        updateLocationToEdit(resp, e.latLng.lat(), e.latLng.lng())
        setMarkers([{ lat: e.latLng.lat(), lng: e.latLng.lng() }])
      },
      err => {
        console.log(err)
      }
    )
  }

  const validateAddress = () => {
    setAddressValidated(true)
    if (locationToEdit.lat.isDirty || locationToEdit.lon.isDirty) {
      Geocode.fromLatLng(
        locationToEdit.lat.value,
        locationToEdit.lon.value
      ).then(resp => {
        const { lat, lng } = resp.results[0].geometry.location
        setMarkers([{ lat: lat, lng: lng }])
        updateLocationToEdit(resp, lat, lng)
      })
    } else {
      let add =
        locationToEdit.address.value +
        " " +
        locationToEdit.city.value +
        " " +
        locationToEdit.state.value +
        " " +
        locationToEdit.zip.value +
        " USA"
      Geocode.fromAddress(add).then(
        resp => {
          const { lat, lng } = resp.results[0].geometry.location
          setMarkers([{ lat: lat, lng: lng }])
          updateLocationToEdit(resp, lat, lng)
        },
        error => {
          console.error(error)
        }
      )
    }
  }

  const updateLocationToEdit = (resp, lat, lng) => {
    let zip = resp.results[0].address_components.find(
      x => x.types.find(t => t == "postal_code") !== undefined
    )
    let state = resp.results[0].address_components.find(
      x => x.types.find(t => t == "administrative_area_level_1") !== undefined
    )
    let city = resp.results[0].address_components.find(
      x => x.types.find(t => t == "locality") !== undefined
    )
    let addr =
      resp.results[0].address_components.find(
        x => x.types.find(t => t == "street_number") !== undefined
      )?.short_name +
      " " +
      resp.results[0].address_components.find(
        x => x.types.find(t => t == "route") !== undefined
      )?.short_name
    setLocationToEdit({
      ...locationToEdit,
      address: { value: addr || "", isDirty: false },
      city: {
        value: city != undefined ? city.long_name : "",
        isDirty: false,
      },
      zip: { value: zip != undefined ? zip.long_name : "", isDirty: false },
      state: {
        value: state != undefined ? state.short_name : "",
        isDirty: false,
      },
      lat: { value: lat, isDirty: false },
      lon: { value: lng, isDirty: false },
    })
  }

  const deleteLocation = async () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this location?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteAlternativeLocation(locationToEdit.locationID)
          toggleModal()
          reloadLocations()
        }
      })
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
         
          <div className="mb-3">
            <Label className="form-label">City</Label>
            <Input
              name="city"
              type="text"
              onChange={e => changeLocationProp("city", e.target.value)}
              value={locationToEdit.city.value}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <div className="mb-3">
            <Label className="form-label">State</Label>
            <Input
              name="state"
              type="text"
              onChange={e => changeLocationProp("state", e.target.value)}
              value={locationToEdit.state.value}
            />
          </div>
        </Col>
        <Col sm="6">
          <div className="mb-3">
            <Label className="form-label">Zip</Label>
            <Input
              name="zip"
              type="text"
              onChange={e => changeLocationProp("zip", e.target.value)}
              value={locationToEdit.zip.value}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <div className="mb-3">
            <Label className="form-label">Latitute</Label>
            <Input
              name="lat"
              type="text"
              onChange={e => changeLocationProp("lat", e.target.value)}
              value={locationToEdit.lat.value}
            />
          </div>
        </Col>
        <Col sm="6">
          <div className="mb-3">
            <Label className="form-label">Longitude</Label>
            <Input
              name="lon"
              type="text"
              onChange={e => changeLocationProp("lon", e.target.value)}
              value={locationToEdit.lon.value}
            />
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <p>
            You must validate the address or enter a Lat and Lon to save the
            location.
          </p>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={validateAddress}
            >
              Validate Address
            </button>

           
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default EditAddressLocation
