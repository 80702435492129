import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import CreateProjectListModal from "./CreateProjectListModal"

const SelectProjectListModal = ({
  isOpen,
  toggleModal,
  scenarioId,
  saveCallback,
  isViewList,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [lists, setLists] = useState([])
  const [selectedListId, setSelectedListId] = useState(-1)
  const [showCreateListModal, setShowCreateListModal] = useState(false)
  const toggleCreateListModal = () =>
    setShowCreateListModal(!showCreateListModal)

  useEffect(() => {
    if (isOpen) {
      setSelectedListId(-1)
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    let l = await api.getAlternativeLists(currentUser.userID, scenarioId)
    if (l.length == 0) {
      toggleCreateListModal()
    }
    setLists(l)
  }

  const save = async () => {
    if (selectedListId > -1) {
      saveCallback(lists.find(x => x.alternativeListID == selectedListId))
    }
  }

  const createCallback = async altListId => {
    if (altListId !== undefined && altListId !== null && altListId > -1) {
      let l = await api.getAlternativeLists(currentUser.userID, scenarioId)
      await saveCallback(l.find(x => x.alternativeListID == altListId))
    }
  }

  return (
    <>
      <Modal
      backdrop="static"
        isOpen={isOpen}
        size="md"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Select List
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <Label className="form-label">List</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={selectedListId}
              onChange={e => {
                setSelectedListId(e.target.value)
              }}
            >
              <option value="-1">Select List</option>
              {lists.map((x, idx) => (
                <option key={idx} value={x.alternativeListID}>
                  {x.alternativeListName}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div
          className="modal-footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>

          <div>
            {!isViewList && (
              <button
                type="button"
                className="btn btn-primary save-user"
                onClick={toggleCreateListModal}
              >
                Create New List
              </button>
            )}
            <button
              type="button"
              className="btn btn-primary save-user"
              onClick={save}
            >
              {isViewList ? "View List" : "Add to List"}
            </button>
          </div>
        </div>
      </Modal>
      <CreateProjectListModal
        scenarioId={scenarioId}
        createCallback={createCallback}
        isOpen={showCreateListModal}
        toggleModal={toggleCreateListModal}
        listTypeId={1}
      />
    </>
  )
}

export default SelectProjectListModal
