import moment from "moment"
import EditTaskModal, {
  TaskTypes,
} from "pages/Projects/EditProject/Modals/EditTaskModal"
import React, { useEffect, useState } from "react"
import api from "services/api.service"
import { userService } from "services/user.service"
import {
  KanbanComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-kanban"
import isNullOrEmpty from "utils/isNullOrEmpty"
import NameAvatar from "components/custom/nameAvatar"
import useModal from "hooks/useModalHook"

const KanbanTaskTable = ({
  tasks,
  setTasks,
  loadTasks,
  displayAs,
  editTask,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [columns, setColumns] = useState([])

  const [taskStatuses, setTaskStatuses] = useState([])

  useEffect(() => {
    if (displayAs == 2) {
      loadColumns()
    }
  }, [displayAs])

  const loadColumns = async () => {
    let statuses = await api.getTaskList(TaskTypes.projectTask, "TaskStatus")
    setTaskStatuses(statuses)
    setColumns(["Other / Unknown", ...statuses.map(x => x.listItemName)])

    let copy = [...tasks]
    copy.forEach(t => {
      let sts = statuses.find(x => x.listItemID == t.taskStatusID)
      if (sts == undefined) {
        t.taskStatusText = "Other / Unknown"
      } else {
        t.taskStatusText = sts.listItemName
      }
    })
    setTasks(copy)
  }

  const cardTemplate = props => {
    return (
      <div className="card-template">
        <div className="e-card-content">
          <p>{props.taskName}</p>

          <p>
            <NameAvatar
              fullName={props.ownerUser}
              showName={true}
              base64Image={props.ownerUserPhotoBase64}
            />
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>
              {isNullOrEmpty(props.startDT)
                ? ""
                : moment(props.startDT).format("MM-DD-YYYY")}
            </span>
            <span>
              {isNullOrEmpty(props.endDT)
                ? ""
                : moment(props.endDT).format("MM-DD-YYYY")}
            </span>
          </div>
        </div>
      </div>
    )
  }

  const cardDoubleClick = async e => {
    e.cancel = true
    editTask(undefined, e.data)
    // let task = await api.getTask(e.data.taskID)
    // setTaskToEdit({
    //   ...task,
    //   subtasks: tasks.filter(x => x.parentTaskID == task.taskNum),
    // })
    // toggleEditModal()
  }

  const dragStop = async e => {
    let copy = [...tasks]
    let item = copy.find(x => x.taskID == e.data[0].taskID)
    let index = copy.indexOf(item)
    if (item != undefined) {
      let itemCopy = { ...item }
      let sts = taskStatuses.find(
        x => x.listItemName == e.data[0].taskStatusText
      )
      if (sts == undefined) {
        itemCopy.taskStatusID = 0
        itemCopy.taskStatusText = "Other / Unknown"
      } else {
        itemCopy.taskStatusID = sts.listItemID
        itemCopy.taskStatusText = sts.listItemName
      }
      await api.updateTask(currentUser.userID, { ...itemCopy })
      copy.splice(index, 1, { ...itemCopy })
      setTasks(copy)
    }
  }

  return (
    <React.Fragment>
      {columns.length > 0 && (
        <KanbanComponent
          id="kanban"
          keyField="taskStatusText"
          dataSource={tasks}
          //   cardSettings={{ contentField: "taskName", headerField: "taskNum" }}
          cardSettings={{ headerField: "taskNum", template: cardTemplate }}
          cardDoubleClick={cardDoubleClick}
          dragStop={dragStop}
        >
          <ColumnsDirective>
            {columns.map((c, idx) => (
              <ColumnDirective key={idx} headerText={c} keyField={c} />
            ))}
          </ColumnsDirective>
        </KanbanComponent>
      )}
    </React.Fragment>
  )
}

export default KanbanTaskTable
