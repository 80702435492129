import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Col, Input, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const CostPoolsSection = ({ enterpriseId, activeTab }) => {
  const currentUser = userService.getLoggedInUser()
  const [costPools, setCostPools] = useState([])
  const [dropdownList, setDropdownList] = useState([])
  const [selectedDropdownItem, setSelectedDropdownItem] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [inflationRates, setInflationRates] = useState([])

  useEffect(() => {
    if (enterpriseId > -1 && activeTab == "7") {
      loadData()
    }
  }, [enterpriseId, activeTab])

  useEffect(() => {
    if (selectedDropdownItem != null && selectedDropdownItem != -1) {
      loadItems()
    }
  }, [selectedDropdownItem])

  const loadData = async () => {
    try {
      let list = await api.getList(currentUser.userID, "CostPoolTemplates")
      setDropdownList(list)
      setSelectedDropdownItem(list[0])

      let bcRates = await api.getEnterpriseBcRates(enterpriseId)
      setInflationRates(bcRates.filter(x => x.rateType == "Inflation"))
    } catch (err) {}
  }

  const loadItems = async () => {
    try {
      setIsLoading(true)
      let list = await api.getCostPools(
        enterpriseId,
        selectedDropdownItem.listItemID
      )
      setCostPools(list)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  const updateCostPoolUseCost = async costPool => {
    await api.updateCostPool(
      currentUser.userID,
      costPool.costPoolID == 0 ? false : true,
      {
        ...costPool,
        costPoolID:
          costPool.costPoolID == Number.MAX_SAFE_INTEGER
            ? 0
            : costPool.costPoolID,
      }
    )
  }

  const updateCostPoolFlowThrough = costPool => {}

  const updateListItem = async listItem => {
    try {
      await api.updateEnterpriseListItem(
        enterpriseId,
        "ResourceClass",
        listItem
      )
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <React.Fragment>
      {dropdownList.length > 0 && (
        <Row>
          <Col sm="8">
            <div className="mb-3">
              <Label className="form-label">Template</Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={
                  selectedDropdownItem == null
                    ? -1
                    : selectedDropdownItem.listItemID
                }
                onChange={e => {
                  setSelectedDropdownItem(
                    dropdownList.find(x => x.listItemID == e.target.value)
                  )
                }}
              >
                <option value={-1}>Select Cost Pool Template</option>
                {dropdownList.map((a, idx) => {
                  return (
                    <option key={idx} value={`${a.listItemID}`}>
                      {a.listItemName}
                    </option>
                  )
                })}
              </select>
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col sm="10">
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          {!isLoading &&
            selectedDropdownItem != null &&
            selectedDropdownItem != -1 &&
            costPools.length > 0 && (
              <table
                className="table table-bordered low-padding-table"
                style={{ backgroundColor: "white" }}
              >
                <thead>
                  <tr>
                    <th className="col-2">Section</th>
                    <th className="col-3">Cost Pool</th>
                    <th className="col-1">Use Cost Pool</th>
                    <th className="col-2">Inflation Rate</th>
                    <th className="col-1">Resource Flow Through</th>
                  </tr>
                </thead>
                <tbody>
                  {costPools.map((x, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{x.costPoolType}</td>
                        <td>{x.costPoolName}</td>
                        <td>
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            id={`chb1-${idx}`}
                            checked={x.costPoolID > 0}
                            value={x.costPoolID > 0}
                            onClick={e => {
                              let costPoolsCopy = [...costPools]
                              let toUpdate = costPoolsCopy.find(
                                c => c.bcLineItemID == x.bcLineItemID
                              )
                              toUpdate.costPoolID =
                                toUpdate.costPoolID > 0
                                  ? 0
                                  : Number.MAX_SAFE_INTEGER
                              toUpdate.flowThrough =
                                toUpdate.costPoolID == 0
                                  ? false
                                  : toUpdate.flowThrough
                              toUpdate.enterpriseID = enterpriseId
                              updateCostPoolUseCost(toUpdate)
                              setCostPools(costPoolsCopy)
                            }}
                          />
                        </td>
                        <td>
                          <select
                            className="form-control form-select select2 mb-xxl-0"
                            value={x.inflationRateID}
                            onChange={e => {
                              let costPoolsCopy = [...costPools]
                              let toUpdate = costPoolsCopy.find(
                                c => c.bcLineItemID == x.bcLineItemID
                              )
                              toUpdate.inflationRateID = e.target.value
                              toUpdate.enterpriseID = enterpriseId
                              updateCostPoolUseCost(toUpdate)
                              setCostPools(costPoolsCopy)
                            }}
                          >
                            <option value={0}>None</option>
                            {inflationRates.map((r, idx) => (
                              <option key={idx} value={r.bcRateID}>
                                {r.bcRateName}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            id={`chb2-${idx}`}
                            checked={x.flowThrough}
                            // value={x.flowThrough}
                            onClick={e => {
                              let costPoolsCopy = [...costPools]
                              let toUpdate = costPoolsCopy.find(
                                c => c.bcLineItemID == x.bcLineItemID
                              )
                              toUpdate.flowThrough = !toUpdate.flowThrough
                              toUpdate.enterpriseID = enterpriseId
                              updateCostPoolUseCost(toUpdate)
                              setCostPools(costPoolsCopy)
                            }}
                            disabled={x.costPoolID == 0}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CostPoolsSection
