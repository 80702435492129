import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Container, Input, Label, Row } from "reactstrap"
import api from "services/api.service"
import colors from "utils/colors"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ReactApexChart from "react-apexcharts"
import AlternativeRatingTable from "pages/_AlternativeShared/AlternativeRatingTable"
import SelectAlternativeModal from "pages/AdminAlternativeScore/SelectAlternativeModal"
import { getAlternativeScoresChartOptionsSeries } from "pages/_AlternativeShared/alternativeChartFunctions"
import { userService } from "services/user.service"
import { useDispatch, useSelector } from "react-redux"
import { loadPageItem } from "store/actions"
import { loadUsersAvatars } from "utils/avatar"

const AltGroupResponses = props => {
  const scenarioId = props.match.params.scenarioId
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  let [alternativeScores, setAlternativeScores] = useState([])
  let [alternativeId, setAlternativeId] = useState(0)
  let [isLoading, setIsLoading] = useState(false)
  let [showGrids, setShowGrids] = useState(false)
  let [showCharts, setShowCharts] = useState(true)
  let [scenario, setScenario] = useState(null)
  let [scenarioRatingScale, setScenarioRatingScale] = useState([])
  let [showAltModal, setShowAltModal] = useState(false)
  const [usersPhotos, setUsersPhotos] = useState([])

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "altGroupResponses",
      })
    )
    loadScenarioRatingScale()
  }, [])

  useEffect(() => {
    if (scenarioRatingScale && scenarioRatingScale.length > 0) {
      loadNextAlt(true)
    }
  }, [scenarioRatingScale])

  const loadNextAlt = async (loadUsersPhotos = false) => {
    await loadNextOrPrevAltData(api.getNextAlternativeId, loadUsersPhotos)
  }

  const loadPrevAlt = async () => {
    await loadNextOrPrevAltData(api.getPrevAlternativeId)
  }

  const loadSelectedAltData = async altId => {
    try {
      setIsLoading(true)
      setAlternativeId(altId)
      await loadAltData(altId)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  const loadNextOrPrevAltData = async (
    getNextOrPrevAltIdApiCall,
    loadUsersPhotos = false
  ) => {
    try {
      setIsLoading(true)
      let altId = await getNextOrPrevAltIdApiCall(alternativeId, scenarioId)
      setAlternativeId(altId)
      await loadAltData(altId, loadUsersPhotos)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  const loadAltData = async (altId, loadUsersPhotos = false) => {
    let altScores = await api.getAltScores(altId, scenarioId)
    let userScoreTasks = altScores.map(s => async () => {
      return await api.getAltUserScores(s.altScoreID, s.levelID)
    })
    let userScores = await Promise.all(userScoreTasks.map(t => t()))

    if (loadUsersPhotos) {
      let flatUserScores = userScores.flat()
      let avatars = await loadUsersAvatars(flatUserScores.map(x => x.userID))
      setUsersPhotos(avatars)
    }

    for (var i = 0; i < altScores.length; i++) {
      altScores[i].userScores = userScores[i]
      altScores[i].chartData = getAlternativeScoresChartOptionsSeries(
        userScores[i],
        scenarioRatingScale
      )
    }

    setAlternativeScores(altScores)
  }

  const loadScenario = async () => {
    try {
      var sc = await api.getScenario(scenarioId)
      setScenario(sc)
      return sc
    } catch (err) {
      console.log(err)
    }
  }

  const loadScenarioRatingScale = async () => {
    try {
      let scenarioRatings = await api.getScenarioInputRatings(scenarioId)
      let scenarioRatingsSorted = scenarioRatings.sort(
        (a, b) => parseFloat(a.inputValue) - parseFloat(b.inputValue)
      )
      setScenarioRatingScale(scenarioRatingsSorted)
      return scenarioRatingsSorted
    } catch (err) {
      console.log(err)
    }
  }

  const toggleAltModal = () => {
    setShowAltModal(!showAltModal)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />

          <Row>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
              }}
            >
              <div className="form-check">
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="customCheck1"
                  defaultChecked={showGrids}
                  onChange={e => {
                    setShowGrids(!showGrids)
                  }}
                />
                <Label className="form-check-label" for="customCheck1">
                  Show responses grids
                </Label>
              </div>
              <div className="form-check" style={{ marginLeft: "20px" }}>
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="customCheck5"
                  defaultChecked={showCharts}
                  onChange={e => {
                    setShowCharts(!showCharts)
                  }}
                />
                <Label className="form-check-label" for="customCheck5">
                  Show responses charts
                </Label>
              </div>
            </div>
          </Row>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginLeft: "15%",
              marginRight: "15%",
              marginTop: "20px",
            }}
          >
            <div>
              <button
                type="button"
                className="btn btn-primary "
                style={{ marginRight: "10px" }}
                onClick={async () => await loadNextAlt()}
              >
                <i className="fas fa-arrow-left"></i> Previous Project
              </button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
              }}
            >
              <button
                type="button"
                className="btn btn-primary"
                onClick={async () => toggleAltModal()}
              >
                Go To Project
              </button>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary "
                onClick={async () => await loadPrevAlt()}
              >
                Next Project <i className="fas fa-arrow-right"></i>
              </button>
            </div>
          </div>

          {!isLoading &&
            alternativeScores != undefined &&
            alternativeScores != null &&
            alternativeScores.length > 0 &&
            scenarioRatingScale != null &&
            scenarioRatingScale.length > 0 &&
            alternativeScores.map((a, idx) => {
              return (
                <Row key={idx}>
                  {a != null && (
                    <div>
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "20px",
                          backgroundColor: "lightyellow",
                          borderRadius: "10px",
                          padding: "15px",
                          marginLeft: "15%",
                          marginRight: "15%",
                        }}
                      >
                        <h5>
                          <u>Score {a.comparison}</u>
                        </h5>
                        <h5>To what degree does</h5>
                        <h5 style={{ color: "maroon", textAlign: "center"  }}>{a.alternativeName}</h5>
                        <h5>project contribute to</h5>
                        <h5 style={{ color: "maroon", textAlign: "center"  }}>{a.objMeasName}</h5>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "20px",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            backgroundColor: "white",
                            borderRadius: "10px",
                            border: "1px solid gray",
                            padding: "15px",
                          }}
                        >
                          <p style={{ marginBottom: "0px" }}>
                            <b>The mean score is: {a.score}</b>
                          </p>
                          <p style={{ marginBottom: "0px" }}>
                            <b>The standard deviation is: {a.standDev}</b>
                          </p>
                          <p style={{ marginBottom: "0px" }}>
                            <b>The group rating is: {a.verbalScore}</b>
                          </p>
                          <p style={{ marginBottom: "0px" }}>
                            <b>The benefit score is: {a.saaScore}</b>
                          </p>
                        </div>
                      </div>
                      {showGrids && (
                        <div style={{ marginTop: "20px" }}>
                          <AlternativeRatingTable
                            scenarioRatingScale={scenarioRatingScale}
                            userScores={a.userScores}
                            usersPhotos={usersPhotos}
                          />
                        </div>
                      )}
                      {showCharts && (
                        <div style={{ marginTop: "20px" }}>
                          <ReactApexChart
                            options={a.chartData.options}
                            series={a.chartData.series}
                            style={{ backgroundColor: "white" }}
                            type="bar"
                            height="250"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </Row>
              )
            })}
        </Container>
      </div>
      <SelectAlternativeModal
        isOpen={showAltModal}
        toggleModal={toggleAltModal}
        scenarioId={scenarioId}
        scenario={scenario}
        onSelect={loadSelectedAltData}
      />
    </React.Fragment>
  )
}

export default AltGroupResponses
