import PropTypes from "prop-types"
import Widgets from "components/Widgets"
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Container, Input, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { loadPageItem } from "store/actions"
import { changeNavbarParams } from "store/navbar/actions"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import CurrencyInput from "react-currency-input-field"
import { toast } from "react-toastify"
import { Tooltip } from "@material-ui/core"
import { useUserRight } from "services/role.service"
import Loader from "react-loaders"
import colors from "utils/colors"
import PaginatedTable from "components/custom/PaginatedTable"
import useModal from "hooks/useModalHook"
import ColumnSelectionModal from "./Modals/ColumnSelectionModal"

const newColumsToShow = {
  locked: { show: true, header: "Locked", width: "50px" },
  exclude: { show: true, header: "Exclude", width: "50px" },
  complete: { show: true, header: "Complete", width: "50px" },
  bcStatus: { show: true, header: "Business Case Status" },
  lead: { show: true, header: "Lead Business Representative" },
  businessPriority: { show: true, header: "Business Priority" },
  brm: { show: true, header: "Business Relationship Manager" },
  gov: { show: true, header: "Governance Board" },
  ldo: { show: true, header: "Lead Delivery Organization" },
  ldopoc: { show: true, header: "Lead Delivery Organization POC" },
  pm: { show: true, header: "Project Manager" },
  projectState: { show: true, header: "Project State" },
  sponsor: { show: true, header: "Sponsor" },
  workflowStatus: { show: true, header: "Workflow Status" },
  planningCategory: { show: true, header: "Planning Category" },
  investmentCategory: { show: true, header: "Investment Category" },
  cost: { show: true, header: "Cost $" },
  benefit: { show: true, header: "Benefit $" },
  advancedPlanning: { show: true, header: "Advanced Planning" },
  cpPhase: { show: true, header: "CPIC Phase" },
}

const EditProjectsLists = props => {
  const dispatch = useDispatch()
  const scenarioId = props.match.params.scenarioId
  const currentUser = userService.getLoggedInUser()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [configItems, setConfigItems] = useState([])
  const [bcStatusOptions, setBcStatusOptions] = useState([])
  const [completeOptions, setCompleteOptions] = useState([])
  const [brmOptions, setBrmOptions] = useState([])
  const [sponsorOptions, setSponsorOptions] = useState([])
  const [ldoOptions, setLdoOptions] = useState([])
  const [ldoListOptions, setLdoListOptions] = useState([])
  const [pmOptions, setPmOptions] = useState([])
  const [deliveryOptions, setDeliveryOptions] = useState([])
  const [submitterOptions, setSubmitterOptions] = useState([])
  const [workflowOptions, setWorkflowOptions] = useState([])
  const [planningOptions, setPlanningOptions] = useState([])
  const [investmentOptions, setInvestmentOptions] = useState([])
  const [decisionOptions, setDecisionOptions] = useState([])
  const [priorityOptions, setPriorityOptions] = useState([])
  const [governanceOptions, setGovernanceOptions] = useState([])
  const [userOptions, setUserOptions] = useState([])
  const [advancedPlanningOptions, setAdvancedPlanningOptions] = useState([])
  const [cpPhaseOptions, setCpPhaseOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [alternatives, setAlternatives] = useState([])
  const [hasAlternativesInRevision, setHasAlternativesInRevision] =
    useState(false)

  const [tableWidth, setTableWidth] = useState(3900)
  const [columnsToShow, setColumnsToShow] = useState({...newColumsToShow})
  const { show: showColumnModal, toggle: toggleColumnModal } = useModal()

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "editProjects",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "editProjects",
      })
    )
    loadData()
    loadLists()
  }, [])

  const loadData = async () => {
    setIsLoading(true)
    let alts = await api.getAlternatives(currentUser.userID, scenarioId)
    setAlternatives(alts)
    setHasAlternativesInRevision(alts.find(x => x.inRevision) !== undefined)

    let scenario = await api.getScenario(scenarioId)

    let brmLabel = await api.getEnterpriseLabel(scenario.enterpriseID, 'Business Relationship Manager')
    let advancedPlanningLabel = await api.getEnterpriseLabel(scenario.enterpriseID, 'Advanced Planning')
    let governanceBoardLabel = await api.getEnterpriseLabel(scenario.enterpriseID, 'Governance Board')
    let investmentCategoryLabel = await api.getEnterpriseLabel(scenario.enterpriseID, 'Investment Category')
    let leadBusinessRepresentativeLabel = await api.getEnterpriseLabel(scenario.enterpriseID,  "Lead Business Representative")
    let planningCategoryLabel = await api.getEnterpriseLabel(scenario.enterpriseID,  "Planning Category")
    let ldoLabel = await api.getEnterpriseLabel(scenario.enterpriseID,  "Lead Delivery Organization")
    let colsToShow = {...newColumsToShow}
    colsToShow.brm.header = brmLabel
    colsToShow.advancedPlanning.header = advancedPlanningLabel
    colsToShow.gov.header = governanceBoardLabel
    colsToShow.investmentCategory.header = investmentCategoryLabel
    colsToShow.lead.header = leadBusinessRepresentativeLabel
    colsToShow.planningCategory.header = planningCategoryLabel
    colsToShow.ldo.header = ldoLabel

    let fieldConfigItems = await api.getFieldConfigItems(scenarioId)
    let cols = {}
    for (let prop in colsToShow) {
      let header = colsToShow[prop].header.toLowerCase()
      let fieldConfigItem = fieldConfigItems.find(
        x => x.fieldLabel.toLowerCase() == header
      )
      if (
        fieldConfigItem == undefined ||
        (fieldConfigItem.fieldStatus !== "read only" &&
          fieldConfigItem.fieldStatus !== "hide")
      ) {
        cols[prop] = colsToShow[prop]
      }
    }
    setColumnsToShow(cols)

    setIsLoading(false)
  }

  const loadLists = () => {
    api.getList(currentUser.userID, "BCStatus").then(bc => {
      setBcStatusOptions(bc)
    })
    api.getList(currentUser.userID, "Decisions").then(dec => {
      setDecisionOptions(dec)
    })
    api.getList(currentUser.userID, "Complete").then(comp => {
      setCompleteOptions(comp)
    })
    api.getList(currentUser.userID, "Priorities").then(pr => {
      setPriorityOptions(pr)
    })
    api.getScenarioList(scenarioId, "BRMList").then(brm => {
      setBrmOptions(brm)
    })
    api.getScenarioList(scenarioId, "SponsoringUnit").then(su => {
      setSponsorOptions(su)
    })
    api.getScenarioList(scenarioId, "LDO").then(ldo => {
      setLdoOptions(ldo)
    })
    api.getScenarioList(scenarioId, "LDOList").then(ldol => {
      setLdoListOptions(ldol)
    })
    api.getScenarioList(scenarioId, "PMList").then(pm => {
      setPmOptions(pm)
    })
    api.getScenarioList(scenarioId, "DeliveryPOCList").then(poc => {
      setDeliveryOptions(poc)
    })
    api.getScenarioList(scenarioId, "SubmitterList").then(sub => {
      setSubmitterOptions(sub)
    })
    api.getScenarioList(scenarioId, "WorkflowStatus").then(wf => {
      setWorkflowOptions(wf)
    })
    api.getScenarioList(scenarioId, "PlanningCategory").then(pl => {
      setPlanningOptions(pl)
    })
    api.getScenarioList(scenarioId, "GovernanceBoard").then(gv => {
      setGovernanceOptions(gv)
    })
    api.getScenarioList(scenarioId, "InvestmentCategory").then(inv => {
      setInvestmentOptions(inv)
    })
    api.getScenarioList(scenarioId, "UserList").then(us => {
      setUserOptions(us)
    })
    api.getList(currentUser.userID, "CPICPhase").then(cp => {
      setCpPhaseOptions(cp)
    })
    api.getScenarioList(scenarioId, "AdvancedPlanning").then(ap => {
      setAdvancedPlanningOptions(ap)
    })
  }

  const save = async () => {
    let updated = alternatives.filter(x => x.isDirty)
    if (updated.length > 0) {
      let tasks = updated.map(x => async () => {
        x.alternativeName = null
        return await api.updateAlternative(currentUser.userID, x)
      })
      await Promise.all(tasks.map(t => t()))
      toast.success("Projects saved successfuly")
      loadData()
    }
  }

  const onUpdate = useCallback(
    alternative => {
      setAlternatives(prev =>
        prev.map(a =>
          a.alternativeID == alternative.alternativeID
            ? { ...alternative, isDirty: true }
            : a
        )
      )
    },
    [setAlternatives]
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Row>
            <Col>
              <div style={{ float: "right" }}>
                <button className="btn btn-primary" onClick={save}>
                  <i className="fas fa-save"></i> Update
                </button>
                <button
                  className="btn btn-primary save-user"
                  onClick={toggleColumnModal}
                >
                  <i className="mdi mdi-table-column-remove"></i> Change Columns
                </button>
              </div>
            </Col>
          </Row>
          {hasAlternativesInRevision && (
            <Row>
              <Col>
                <h5 style={{ color: "red" }}>
                  <u>Warning:</u> Business cases that are in revision are
                  highlighted. Any changes made to a baseline version should
                  also be made in the revision.
                </h5>
              </Col>
            </Row>
          )}

          <Row style={{ marginTop: "10px" }}>
            <Col style={{ overflowY: "auto" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "70vh",
                }}
              >
                {isLoading && (
                  <Loader
                    type="line-scale-pulse-out"
                    color={colors.primary}
                    style={{ textAlign: "center" }}
                  />
                )}
                {/* {!isLoading && ( */}
                <PaginatedTable
                  isSticky={true}
                  items={alternatives}
                  width={tableWidth}
                  rowRenderer={(a, idx) => (
                    <EditableRow
                      key={idx}
                      a={a}
                      onUpdate={onUpdate}
                      bcStatusOptions={bcStatusOptions}
                      sponsorOptions={sponsorOptions}
                      ldoOptions={ldoOptions}
                      workflowOptions={workflowOptions}
                      planningOptions={planningOptions}
                      investmentOptions={investmentOptions}
                      decisionOptions={decisionOptions}
                      priorityOptions={priorityOptions}
                      governanceOptions={governanceOptions}
                      userOptions={userOptions}
                      cpPhaseOptions={cpPhaseOptions}
                      advancedPlanningOptions={advancedPlanningOptions}
                      columnsToShow={columnsToShow}
                    />
                  )}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          width: "50px",
                          position: "sticky",
                          left: 0,
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        ID
                      </th>
                      <th
                        style={{
                          width: "300px",
                          position: "sticky",
                          left: 50,
                          backgroundColor: "#f2f2f2",
                        }}
                      >
                        Project
                      </th>
                      <th
                        style={{
                          width: "70px",
                          position: "sticky",
                          left: 350,
                          backgroundColor: "#f2f2f2",
                          zIndex: 3,
                        }}
                      >
                        Version
                      </th>
                      {Object.keys(columnsToShow).map((c, idx) => (
                        <Fragment key={idx}>
                          {columnsToShow[c].show && (
                            <th
                              style={{
                                minWidth: columnsToShow[c].width
                                  ? columnsToShow[c].width
                                  : "250px",
                              }}
                            >
                              {columnsToShow[c].header}
                            </th>
                          )}
                        </Fragment>
                      ))}
                    </tr>
                  </thead>
                </PaginatedTable>
                {/* )} */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ColumnSelectionModal
        isOpen={showColumnModal}
        toggleModal={toggleColumnModal}
        columnsToShow={columnsToShow}
        setColumnsToShow={setColumnsToShow}
        setTableWidth={setTableWidth}
        tableWidth={tableWidth}
      />
    </React.Fragment>
  )
}

const EditableRow = React.memo(function editableRow({
  a,
  onUpdate,
  bcStatusOptions,
  sponsorOptions,
  ldoOptions,
  workflowOptions,
  planningOptions,
  investmentOptions,
  decisionOptions,
  priorityOptions,
  governanceOptions,
  userOptions,
  cpPhaseOptions,
  advancedPlanningOptions,
  columnsToShow,
}) {
  return (
    <tr>
      <td>{a.alternativeNum}</td>
      <td
        style={{
          cursor: "pointer",
          left: 50,
        }}
      >
        <div className="one-line-elipsis " style={{ textAlign: "left", width: 300}}>
          <Tooltip title={<h6>{a.alternativeName}</h6>}>
            <span>
              <b>{a.alternativeName}</b>
            </span>
          </Tooltip>
        </div>
      </td>
      <td style={{ left: 350, position: "sticky", zIndex: 1, background: 'white' }}>
        <div
          className="one-line-elipsis"
          dangerouslySetInnerHTML={{
            __html: a.version,
          }}
        ></div>
      </td>
      {columnsToShow.locked && columnsToShow.locked.show && (
        <td>
          <EditableCell
            type="checkbox"
            value={a.locked}
            onChange={val => onUpdate({ ...a, locked: val })}
          />
        </td>
      )}
      {columnsToShow.exclude && columnsToShow.exclude.show && (
        <td>
          <EditableCell
            type="checkbox"
            value={a.exclude}
            onChange={val => onUpdate({ ...a, exclude: val })}
          />
        </td>
      )}
      {columnsToShow.complete && columnsToShow.complete.show && (
        <td>
          <EditableCell
            type="checkbox"
            value={a.inactive}
            onChange={val => onUpdate({ ...a, inactive: val })}
          />
        </td>
      )}
      {columnsToShow.bcStatus && columnsToShow.bcStatus.show && (
        <td>
          <EditableCell
            type="select"
            options={bcStatusOptions}
            optionsDisplayProp="listItemName"
            optionsValueProp="listItemName"
            value={a.bcState}
            onChange={val => onUpdate({ ...a, bcState: val })}
          />
        </td>
      )}
      {columnsToShow.lead && columnsToShow.lead.show && (
        <td>
          <EditableCell
            type="select"
            options={userOptions}
            optionsDisplayProp="listItemName"
            optionsValueProp="listItemID"
            value={a.leadUserID}
            onChange={val => onUpdate({ ...a, leadUserID: val })}
          />
        </td>
      )}
      {columnsToShow.businessPriority && columnsToShow.businessPriority.show && (
        <td>
          <EditableCell
            type="select"
            options={priorityOptions}
            optionsDisplayProp="listItemName"
            optionsValueProp="listItemName"
            value={a.businessPriority}
            onChange={val => onUpdate({ ...a, businessPriority: val })}
          />
        </td>
      )}
      {columnsToShow.brm && columnsToShow.brm.show && (
        <td>
          <EditableCell
            type="select"
            options={userOptions}
            optionsDisplayProp="listItemName"
            optionsValueProp="listItemID"
            value={a.brmUserID}
            onChange={val => onUpdate({ ...a, brmUserID: val })}
          />
        </td>
      )}
      {columnsToShow.gov && columnsToShow.gov.show && (
        <td>
          <EditableCell
            type="select"
            options={governanceOptions}
            optionsDisplayProp="listItemName"
            optionsValueProp="listItemID"
            value={a.governanceBoardID}
            onChange={val => onUpdate({ ...a, governanceBoardID: val })}
          />
        </td>
      )}
      {columnsToShow.ldo && columnsToShow.ldo.show && (
        <td>
          <EditableCell
            type="select"
            options={ldoOptions}
            optionsDisplayProp="listItemName"
            optionsValueProp="listItemID"
            value={a.leadDeliveryOrganizationID}
            onChange={val =>
              onUpdate({ ...a, leadDeliveryOrganizationID: val })
            }
          />
        </td>
      )}
      {columnsToShow.ldopoc && columnsToShow.ldopoc.show && (
        <td>
          <EditableCell
            type="select"
            options={userOptions}
            optionsDisplayProp="listItemName"
            optionsValueProp="listItemID"
            value={a.deliveryPOCID}
            onChange={val => onUpdate({ ...a, deliveryPOCID: val })}
          />
        </td>
      )}
      {columnsToShow.pm && columnsToShow.pm.show && (
        <td>
          <EditableCell
            type="select"
            options={userOptions}
            optionsDisplayProp="listItemName"
            optionsValueProp="listItemID"
            value={a.pmUserID}
            onChange={val => onUpdate({ ...a, pmUserID: val })}
          />
        </td>
      )}
      {columnsToShow.projectState && columnsToShow.projectState.show && (
        <td>
          <EditableCell
            type="select"
            options={decisionOptions}
            optionsDisplayProp="listItemName"
            optionsValueProp="listItemID"
            value={a.decisionID}
            onChange={val => onUpdate({ ...a, decisionID: val })}
          />
        </td>
      )}
      {columnsToShow.sponsor && columnsToShow.sponsor.show && (
        <td>
          <EditableCell
            type="select"
            options={sponsorOptions}
            optionsDisplayProp="listItemName"
            optionsValueProp="listItemID"
            value={a.sponsoringUnitID}
            onChange={val => onUpdate({ ...a, sponsoringUnitID: val })}
          />
        </td>
      )}
      {columnsToShow.workflowStatus && columnsToShow.workflowStatus.show && (
        <td>
          <EditableCell
            type="select"
            options={workflowOptions}
            optionsDisplayProp="listItemName"
            optionsValueProp="listItemID"
            value={a.workflowStatusID}
            onChange={val => onUpdate({ ...a, workflowStatusID: val })}
          />
        </td>
      )}
      {columnsToShow.planningCategory && columnsToShow.planningCategory.show && (
        <td>
          <EditableCell
            type="select"
            options={planningOptions}
            optionsDisplayProp="listItemName"
            optionsValueProp="listItemID"
            value={a.projectCategoryID}
            onChange={val => onUpdate({ ...a, projectCategoryID: val })}
          />
        </td>
      )}
      {columnsToShow.investmentCategory &&
        columnsToShow.investmentCategory.show && (
          <td>
            <EditableCell
              type="select"
              options={investmentOptions}
              optionsDisplayProp="listItemName"
              optionsValueProp="listItemID"
              value={a.investmentCategoryID}
              onChange={val => onUpdate({ ...a, investmentCategoryID: val })}
            />
          </td>
        )}
      {columnsToShow.cost && columnsToShow.cost.show && (
        <td>
          <EditableCell
            type="currency"
            value={a.cost}
            onChange={val => onUpdate({ ...a, cost: val })}
          />
        </td>
      )}
      {columnsToShow.benefit && columnsToShow.benefit.show && (
        <td>
          <EditableCell
            type="currency"
            value={a.benefit}
            onChange={val => onUpdate({ ...a, benefit: val })}
          />
        </td>
      )}
      {columnsToShow.advancedPlanning && columnsToShow.advancedPlanning.show && (
        <td>
          <EditableCell
            type="select"
            options={advancedPlanningOptions}
            optionsDisplayProp="listItemName"
            optionsValueProp="listItemID"
            value={a.advancedPlanningID}
            onChange={val => onUpdate({ ...a, advancedPlanningID: val })}
          />
        </td>
      )}
      {columnsToShow.cpPhase && columnsToShow.cpPhase.show && (
        <td>
          <EditableCell
            type="select"
            options={cpPhaseOptions}
            optionsDisplayProp="listItemName"
            optionsValueProp="listItemID"
            value={a.cpicPhaseID}
            onChange={val => onUpdate({ ...a, cpicPhaseID: val })}
          />
        </td>
      )}
    </tr>
  )
})

const EditableCell = React.memo(function EditableCell({
  value,
  onChange,
  options,
  type,
  optionsValueProp,
  optionsDisplayProp,
}) {
  if (type == "select") {
    return (
      <select
        className="form-control form-select select2 mb-xxl-0"
        value={value}
        onChange={e => {
          onChange(e.target.value)
        }}
      >
        <option value={-1}>Select Option...</option>
        {options.map((uo, idx) => {
          return (
            <option key={idx} value={`${uo[optionsValueProp]}`}>
              {uo[optionsDisplayProp]}
            </option>
          )
        })}
      </select>
    )
  } else if (type == "checkbox") {
    return (
      <Input type="checkbox" checked={value} onClick={() => onChange(!value)} />
    )
  } else if (type == "currency") {
    return (
      <CurrencyInput
        className="form-control"
        value={value}
        onValueChange={(value, name, values) => {
          onChange(values.float)
        }}
        placeholder=""
        prefix={"$"}
        decimalScale={0}
        decimalsLimit={0}
      />
    )
  } else {
    return (
      <input
        type="text"
        value={value}
        onChange={e => onChange(e.target.value)}
      />
    )
  }
})

export default EditProjectsLists
