import React, { useEffect, useState } from "react"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import { ChromePicker } from "react-color"
import api from "services/api.service"
import { userService } from "services/user.service"
import Loader from "react-loaders"
import colors from "utils/colors"
import useModal from "hooks/useModalHook"
import { TextareaAutosize, Tooltip } from "@material-ui/core"
import { toast } from "react-toastify"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"

const ProgramsSection = ({ activeTab, enterprise }) => {
  const currentUser = userService.getLoggedInUser()
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [displayBackColorPicker, setDisplayBackColorPicker] = useState(false)
  const [programs, setPrograms] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { show: showProgramModal, toggle: toggleProgramModal } = useModal()
  const [programToEdit, setProgramToEdit] = useState(null)

  useEffect(() => {
    if (activeTab == "8") {
      loadData()
    }
  }, [activeTab])

  const loadData = async () => {
    setIsLoading(true)
    let p = await api.getPrograms(currentUser.enterpriseID)
    setPrograms(p)
    setIsLoading(false)
  }

  const addProgram = () => {
    setProgramToEdit({ programID: -1, enterpriseID: currentUser.enterpriseID })
    toggleProgramModal()
  }

  const changeProgramToEditProp = (prop, value) => {
    setProgramToEdit({ ...programToEdit, [prop]: value })
  }

  const saveProgram = async () => {
    if (programToEdit.programName != "") {
      if (programToEdit.programID > -1) {
        await api.updatePrograms(currentUser.userID, programToEdit)
      } else {
        await api.createPrograms(currentUser.userID, programToEdit)
      }
      toggleProgramModal()
      loadData()
    } else {
      toast.error("Program name cannot be empty")
    }
  }

  const deleteProgram = async program => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this program?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          let ret = await api.deleteProgram(program.programID)
          toast.success("Item deleted successfully")
          toggleProgramModal()
          await loadData()
        }
      })
  }

  const popover = {
    position: "absolute",
    zIndex: "2",
  }
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  }

  const colorStyle = {
    width: "100%",
    height: "23px",
    borderRadius: "2px",
  }

  const swatch = {
    padding: "5px",
    background: "#fff",
    borderRadius: "1px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "inline-block",
    cursor: "pointer",
  }

  return (
    <React.Fragment>
      <button
        onClick={() => addProgram()}
        className="btn btn-primary"
        style={{ marginBottom: "10px" }}
      >
        <i className="fas fa-plus"></i> Add Program
      </button>
      {enterprise != null && (
        <Row>
          <Col sm="12">
            {isLoading && (
              <Loader
                type="line-scale-pulse-out"
                color={colors.primary}
                style={{ textAlign: "center" }}
              />
            )}
            {!isLoading && (
              <table
                className="table table-bordered low-padding-table"
                style={{ backgroundColor: "white" }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "25%" }}>Program Name</th>
                    <th style={{ width: "25%" }}>Description</th>
                    <th style={{ width: "20%" }}>Fore Color</th>
                    <th style={{ width: "20%" }}>Back Color</th>
                    <th style={{ width: "10%" }}>Inactive</th>
                  </tr>
                </thead>
                <tbody>
                  {programs.map((p, idx) => (
                    <tr key={idx}>
                      <td>
                        <a
                          href="#"
                          onClick={e => {
                            e && e.preventDefault()
                            setProgramToEdit(p)
                            toggleProgramModal()
                          }}
                        >
                          {p.programName}
                        </a>
                      </td>
                      <td className="one-line-elipsis">
                        <Tooltip title={<h6>{p.description}</h6>}>
                          <span>{p.description}</span>
                        </Tooltip>
                      </td>
                      <td
                        style={{
                          backgroundColor: p.foreColor,
                        }}
                      ></td>
                      <td
                        style={{
                          backgroundColor: p.backColor,
                        }}
                      ></td>
                      <td>{p.inactive ? "Yes" : "No"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Col>
        </Row>
      )}

      <Modal
      backdrop="static"
        isOpen={showProgramModal}
        size="md"
        toggle={() => {
          toggleProgramModal()
        }}
      >
        {programToEdit != null && (
          <>
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Program
              </h5>
              <button
                type="button"
                onClick={() => {
                  toggleProgramModal()
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="mb-3">
                <Label className="form-label">Program Name</Label>
                <Input
                  name="welcomeMessage"
                  type="text"
                  onChange={e =>
                    changeProgramToEditProp("programName", e.target.value)
                  }
                  value={programToEdit.programName}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Description</Label>
                <TextareaAutosize
                  minRows={2}
                  className="form-control"
                  placeholder="Description"
                  onChange={e =>
                    changeProgramToEditProp("description", e.target.value)
                  }
                  value={programToEdit.description}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Fore Color</Label>
                <div
                  style={swatch}
                  className="form-control"
                  onClick={() => setDisplayColorPicker(!displayColorPicker)}
                >
                  <div
                    style={{
                      ...colorStyle,
                      backgroundColor:
                        programToEdit.foreColor != null &&
                        programToEdit.foreColor != ""
                          ? programToEdit.foreColor
                          : "white",
                    }}
                  />
                </div>
                {displayColorPicker ? (
                  <div style={popover}>
                    <div
                      style={cover}
                      onClick={() => setDisplayColorPicker(false)}
                    />
                    <ChromePicker
                      color={programToEdit.foreColor}
                      onChangeComplete={col =>
                        changeProgramToEditProp("foreColor", col.hex)
                      }
                    />
                  </div>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">Back Color</Label>
                <div
                  style={swatch}
                  className="form-control"
                  onClick={() =>
                    setDisplayBackColorPicker(!displayBackColorPicker)
                  }
                >
                  <div
                    style={{
                      ...colorStyle,
                      backgroundColor:
                        programToEdit.backColor != null &&
                        programToEdit.backColor != ""
                          ? programToEdit.backColor
                          : "white",
                    }}
                  />
                </div>
                {displayBackColorPicker ? (
                  <div style={popover}>
                    <div
                      style={cover}
                      onClick={() => setDisplayBackColorPicker(false)}
                    />
                    <ChromePicker
                      color={programToEdit.backColor}
                      onChangeComplete={col => {
                        changeProgramToEditProp("backColor", col.hex)
                      }}
                    />
                  </div>
                ) : null}
              </div>
              <div className="form-check" style={{ marginLeft: "10px" }}>
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="customCheck3"
                  checked={programToEdit.inactive}
                  onClick={e => {
                    changeProgramToEditProp("inactive", !programToEdit.inactive)
                  }}
                />
                <Label className="form-check-label" for="customCheck3">
                  Inactive
                </Label>
              </div>
            </div>
            <div
              className="modal-footer"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={toggleProgramModal}
              >
                Cancel
              </button>
              <div>
                {programToEdit.programID > -1 && (
                  <button
                    type="button"
                    className="btn btn-danger save-user"
                    onClick={() => deleteProgram(programToEdit)}
                  >
                    Delete
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-primary save-user"
                  onClick={saveProgram}
                >
                  Save
                </button>
              </div>
            </div>
          </>
        )}
      </Modal>
    </React.Fragment>
  )
}

export default ProgramsSection
