import { swalWithConfirmButton } from "components/custom/swal"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import { Col, Input, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import OptimizationAlert from "./Components/optimizationAlert"
import CustomTable from "components/custom/customTable"

const ObjectiveConstraints = ({
  activeTab,
  scenario,
  triggerSave,
  setTriggerSave,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [objectives, setObjectives] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [optModel, setOptModel] = useState(null)
  const { optObjFin } = useSelector(state => ({
    optObjFin: state.optObjFin.optObjFin,
  }))
  useEffect(() => {
    if (activeTab == "ObjectiveConstraints" && scenario !== null) {
      loadData()
    }
  }, [activeTab, scenario])

  useEffect(() => {
    if (triggerSave) {
      save()
    }
  }, [triggerSave])

  const loadData = async () => {
    try {
      setIsLoading(true)
      let om = await api.getOptModel(
        optObjFin != null && optObjFin.opt != null
          ? optObjFin.opt.optModelID
          : scenario.currentOptModelID
      )
      setOptModel(om)
      await loadObjectives()
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  const loadObjectives = async () => {
    let obj = await api.getOptModelObjectives(
      optObjFin != null && optObjFin.opt != null
        ? optObjFin.opt.optModelID
        : scenario.currentOptModelID,
      scenario.scenarioID,
      optObjFin != null && optObjFin.obj != null ? optObjFin.obj.objModelID : 0
    )
    setObjectives(obj)
  }

  const save = async () => {
    setTriggerSave(false)
    try {
      let itemsWithError = objectives.filter(
        x =>
          x.optMinPercent !== null &&
          x.optMinPercent !== "" &&
          parseFloat(x.optMinPercent) > parseFloat(x.maxPercent)
      )
      if (itemsWithError.length > 0) {
        swalWithConfirmButton.fire({
          title: `The Minimum Constraint may not exceed the Upper Limit for any Objective`,
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Ok",
        })
      } else {
        let itemsToUpdate = objectives.filter(x => x.isDirty)
        if (itemsToUpdate.length > 0) {
          let itemsToUpdateTasks = itemsToUpdate.map(x => async () => {
            return await api.updateOptModelObjective(currentUser.userID, x)
          })
          await Promise.all(itemsToUpdateTasks.map(t => t()))
          loadObjectives()
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const resetMinimums = async () => {
    let itemsToUpdateTasks = objectives.map(x => async () => {
      return await api.updateOptModelObjective(currentUser.userID, {
        ...x,
        optMinPercent: 0,
      })
    })
    await Promise.all(itemsToUpdateTasks.map(t => t()))
    loadObjectives()
  }

  const columns = [
    {
      title: "Objective",
      field: "objectiveName",
      thStyle: { width: "30%" },
      sort: true,
    },
    {
      title: "Preference Weight",
      field: "preferenceWeight",
      thStyle: { textAlign: "center", width: "10%" },
      tdStyle: { textAlign: "center" },
      sort: true,
    },
    {
      title: "Mix % (for all projects)",
      field: "allAltPercent",
      thStyle: { textAlign: "center", width: "10%" },
      tdStyle: { textAlign: "center" },
      sort: true,
    },
    {
      title: 'Mix % (for all projects, except "Must Nots")',
      field: "allAltPercentMustNot",
      thStyle: { textAlign: "center", width: "15%" },
      tdStyle: { textAlign: "center" },
      sort: true,
    },
    {
      title: "Upper Limit (for all projects)",
      field: "maxPercent",
      thStyle: { textAlign: "center", width: "10%" },
      tdStyle: { textAlign: "center" },
      sort: true,
      formatter: row => {
        return (
          <div
            style={{
              width: "100%",
              height: "100%",
              textAlign: "center",

              backgroundColor:
                row.optMinPercent != "" && row.optMinPercent > row.maxPercent
                  ? "red"
                  : "initial",
              color:
                row.optMinPercent != "" && row.optMinPercent > row.maxPercent
                  ? "white"
                  : "initial",
            }}
          >
            {row.maxPercent}
          </div>
        )
      },
    },
    {
      title: 'Upper Limit (for all projects, except "Must Nots")',
      field: "maxPercentMustNot",
      thStyle: { textAlign: "center", width: "15%" },
      tdStyle: { textAlign: "center" },
      sort: true,
    },
    {
      title: " Minimum Constraint",
      thStyle: { textAlign: "center", width: "10%" },
      tdStyle: { textAlign: "center" },
      sort: true,
      formatter: o => {
        return (
          <Input
            type="number"
            value={o.optMinPercent}
            onChange={e => {
              let copy = [...objectives]
              let obj = copy.find(x => x.objectiveID == o.objectiveID)
              obj.optMinPercent = e.target.value
              obj.isDirty = true
              setObjectives(copy)

              toast.success(
                <div>
                  <p className="mb-0">
                    You have unsaved changes, don't forget to save them.
                  </p>
                  <button
                    className="btn btn-primary"
                    style={{ width: "100%" }}
                    onClick={() => {
                      document.getElementById("saveOptimizations").click()
                    }}
                  >
                    <i className="fas fa-save"></i> Save
                  </button>
                </div>,
                {
                  toastId: "unsavedChanges",
                  autoClose: false,
                }
              )
            }}
          />
        )
      },
    },
  ]

  return (
    <React.Fragment>
      {isLoading && (
        <Row>
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        </Row>
      )}
      <OptimizationAlert optModel={optModel} />
      {!isLoading && objectives.length > 0 && (
        <>
          <Row>
            <Col>
              <button
                className="btn btn-outline-danger"
                onClick={resetMinimums}
                style={{ float: "right" }}
              >
                <i className="fas fa-times" style={{ color: "red" }}></i> Remove
                Minimums
              </button>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col>
              <CustomTable
                items={objectives}
                setItems={setObjectives}
                columns={columns}
              />
            </Col>
          </Row>
        </>
      )}
    </React.Fragment>
  )
}

export default ObjectiveConstraints
