import React, { useEffect, useState } from "react"
import useModal from "hooks/useModalHook"
import { Col, Container, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { useDispatch, useSelector } from "react-redux"
import { changeNavbarParams, loadPageItem } from "store/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import EditTaskTemplateModal from "./TaskTemplate/EditTaskTemplateModal"
import EditWorkflowModal from "./Workflow/EditWorkflowModal"
import isNullOrEmpty from "utils/isNullOrEmpty"

const TaskTemplatesAndWorkflows = props => {
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [taskTemplates, setTaskTemplates] = useState([])
  const [workflows, setWorkflows] = useState([])

  const [taskTemplateIdToEdit, setTaskTemplateIdToEdit] = useState(-1)
  const { show: showTaskTemplateModal, toggle: toggleTaskTemplateModal } =
    useModal()

  const [workflowType, setWorkflowType] = useState("")
  const [workflowIdToEdit, setWorkflowIdToEdit] = useState(-1)
  const { show: showWorkflowModal, toggle: toggleWorkflowModal } = useModal()

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: 0,
        alternativeId: 0,
        viewName: "taskTemplatesAndWorkflows",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: 0,
        viewName: "taskTemplatesAndWorkflows",
      })
    )

    loadData()
  }, [])

  const loadData = async () => {
    await loadWorkflows()
    await loadTaskTypes()
  }

  const loadWorkflows = async () => {
    let w = await api.getWorkflowsForEnterprise(currentUser.enterpriseID)
    setWorkflows(w)
  }

  const loadTaskTypes = async () => {
    let t = await api.getTaskTypesForEnterprise(currentUser.enterpriseID)
    setTaskTemplates(t)
  }

  const editTaskTemplate = taskTemplate => {
    setTaskTemplateIdToEdit(taskTemplate.taskTypeID)
    toggleTaskTemplateModal()
  }

  const editWorkflow = workflow => {
    setWorkflowIdToEdit(workflow.workflowID)
    toggleWorkflowModal()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Task templates" />
          <button
            className="btn btn-primary"
            onClick={() => {
              setTaskTemplateIdToEdit(-1)
              toggleTaskTemplateModal()
            }}
          >
            <i className="fas fa-plus"></i> Create Task Template
          </button>
          <Row className="mb-3">
            {taskTemplates.map((t, idx) => (
              <TaskTemplateItem
                key={idx}
                taskTemplate={t}
                onEdit={editTaskTemplate}
              />
            ))}
          </Row>
          <Breadcrumbs title="Workflows" />
          <button
            className="btn btn-primary"
            onClick={() => {
              setWorkflowIdToEdit(-1)
              setWorkflowType('Workflow')
              toggleWorkflowModal()
            }}
          >
            <i className="fas fa-plus"></i> Create Workflow
          </button>
          <Row className="mb-3">
            {workflows
              .filter(x => x.workflowType == "Workflow")
              .map((w, idx) => (
                <WorkflowItem key={idx} workflow={w} onEdit={editWorkflow} />
              ))}
          </Row>
          <Breadcrumbs title="Task Lists" />
          <button
            className="btn btn-primary"
            onClick={() => {
              setWorkflowIdToEdit(-1)
              setWorkflowType('Task List')
              toggleWorkflowModal()
            }}
          >
            <i className="fas fa-plus"></i> Create Task List
          </button>
          <Row className="mb-3">
            {workflows
              .filter(x => x.workflowType == "Task List")
              .map((w, idx) => (
                <WorkflowItem key={idx} workflow={w} onEdit={editWorkflow} />
              ))}
          </Row>
        </Container>
      </div>
      <EditTaskTemplateModal
        isOpen={showTaskTemplateModal}
        toggleModal={toggleTaskTemplateModal}
        taskTypeIdToEdit={taskTemplateIdToEdit}
        reload={loadTaskTypes}
      />
      <EditWorkflowModal
        isOpen={showWorkflowModal}
        toggleModal={toggleWorkflowModal}
        workflowIdToEdit={workflowIdToEdit}
        reload={loadWorkflows}
        workflowType={workflowType}
      />
    </React.Fragment>
  )
}

const TaskTemplateItem = ({ taskTemplate, onEdit }) => {
  return (
    <Col style={{ maxWidth: "450px" }}>
      <div
        style={{
          border: "1px solid lightgray",
          backgroundColor: "white",
          marginTop: "20px",
          marginRight: "10px",
          marginLeft: "10px",
          borderRadius: "8px",
          width: "400px",
          //boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
          cursor: "pointer",
        }}
        onClick={() => onEdit(taskTemplate)}
      >
        <div
          style={{
            padding: "10px",
            backgroundColor: "rgb(18, 52, 86)",
            color: "white",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
        >
          {taskTemplate.taskTypeName}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              margin: "10px",
            }}
          >
            {!isNullOrEmpty(taskTemplate.iconClass) && (
              <p>
                <i className={taskTemplate.iconClass} />
              </p>
            )}
            <p>
              Scope: <b>{taskTemplate.scope}</b>
            </p>
            <p style={{ color: taskTemplate.inactive ? "red" : "green" }}>
              <b>{taskTemplate.inactive ? "Inactive" : "Active"}</b>
            </p>
          </div>
          <div
            style={{
              margin: "10px",
            }}
          >
            {!isNullOrEmpty(taskTemplate.workflowType) && (
              <p>Used in: {taskTemplate.workflowType}</p>
            )}
          </div>
        </div>
      </div>
    </Col>
  )
}

const WorkflowItem = ({ workflow, onEdit }) => {
  return (
    <Col style={{ maxWidth: "450px" }}>
      <div
        style={{
          border: "1px solid lightgray",
          backgroundColor: "white",
          marginTop: "20px",
          marginRight: "10px",
          marginLeft: "10px",
          borderRadius: "8px",
          width: "400px",
          //boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
          cursor: "pointer",
        }}
        onClick={() => onEdit(workflow)}
      >
        <div
          style={{
            padding: "10px",
            backgroundColor: "rgb(18, 52, 86)",
            color: "white",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
        >
          {workflow.workflowName}
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              width: "56%",
              margin: "auto",
              marginTop: "5px",
              marginBottom: "10px",
            }}
          >
            <p>
              Scope: <b>{workflow.scope}</b>
            </p>

            <p>
              Task Template: <b>{workflow.taskType}</b>
            </p>

            <p>
              Project Template: <b>{workflow.projectTemplate}</b>
            </p>

            <p style={{ color: workflow.inactive ? "red" : "green" }}>
              <b>{workflow.inactive ? "Inactive" : "Active"}</b>
            </p>
          </div>
          <div
            style={{
              width: "36%",
              margin: "auto",
              marginTop: "5px",
              marginBottom: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <h4>Steps</h4>
              <h1>
                <b>{workflow.numSteps ?? 0}</b>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </Col>
  )
}

export default TaskTemplatesAndWorkflows
