import React from "react"
import { Col, Label } from "reactstrap"
import Multiselect from "multiselect-react-dropdown"

export const DynamicFormMultiSelect = React.memo(
  function dynamicFormMultiSelect({
    formFieldId,
    label,
    value,
    placeholder,
    changeFormFieldValue,
    formFieldListItems,
    col,
    fullWidth,
    alwaysEditable,
    alwaysReadOnly,
    hasError,
    requiredMessage,
  }) {
    function setEntitySelectedOptions(selectedOptions) {
      changeFormFieldValue(
        formFieldId,
        selectedOptions.map(x => x.id).join(",")
      )
    }

    if (formFieldListItems == undefined || formFieldListItems == null) {
      return <span>Loading</span>
    } else {
      const selectedValues =
        (!value || value == "")
          ? []
          : formFieldListItems
              .filter(
                x =>
                  value.split(",").find(v => v == x.listItemValue) !== undefined
              )
              .map(x => {
                return { name: x.listItemName, id: x.listItemValue }
              })

      return (
        <Col md={fullWidth ? "12" : col}>
          <div className="mb-3">
            <Label className="form-label">{label}</Label>
            <Multiselect
              options={formFieldListItems.map(x => {
                return {
                  name: x.listItemName,
                  id: x.listItemValue,
                }
              })}
              selectedValues={selectedValues}
              onSelect={setEntitySelectedOptions}
              onRemove={setEntitySelectedOptions}
              displayValue="name"
              showCheckbox={true}
            />
            {hasError && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {requiredMessage}
              </div>
            )}
          </div>
        </Col>
      )
    }
  },
  (prevProps, nextProps) => {
    return (
      JSON.stringify(prevProps.formFieldListItems) ==
        JSON.stringify(nextProps.formFieldListItems) &&
      prevProps.value == nextProps.value &&
      prevProps.label == nextProps.label &&
      prevProps.col == nextProps.col &&
      prevProps.requiredMessage == nextProps.requiredMessage &&
      prevProps.hasError == nextProps.hasError &&
      prevProps.placeholder == nextProps.placeholder &&
      prevProps.formFieldId == nextProps.formFieldId &&
      prevProps.fullWidth == nextProps.fullWidth
    )
  }
)
