import React from "react";
import { Col, Label } from "reactstrap";
import { TextareaAutosize } from "@material-ui/core";

export const DynamicFormTextarea = React.memo(function dynamicFormTextarea({
  formFieldId, label, value, placeholder, changeFormFieldValue, rows, col, fullWidth, alwaysEditable, alwaysReadOnly, hasError, requiredMessage,
}) {
  return (
    <Col md={fullWidth ? "12" : col}>
      <div className="mb-3">
        <Label className="form-label">{label}</Label>
        <TextareaAutosize
          minRows={rows || 3}
          className="form-control"
          placeholder={placeholder || ""}
          onChange={e => changeFormFieldValue(formFieldId, e.target.value)}
          value={value}
          disabled={alwaysReadOnly} />
        {hasError && (
          <div className="invalid-feedback" style={{ display: "block" }}>
            {requiredMessage}
          </div>
        )}
      </div>
    </Col>
  );
});
