import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { changeOptObjFin } from "store/actions"

const SelectScenariosModal = ({ isOpen, toggleModal, scenarioId }) => {
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const [finModelId, setFinModelId] = useState(-1)
  const [optModelId, setOptModelId] = useState(-1)
  const [objModelId, setObjModelId] = useState(-1)

  const { optObjFin } = useSelector(state => ({
    optObjFin: state.optObjFin.optObjFin,
  }))

  const [objModelList, setObjModelList] = useState([])
  const [finModelList, setFinModelList] = useState([])
  const [optModelList, setOptModelList] = useState([])

  useEffect(() => {
    if (isOpen) {
      if (optObjFin != undefined && optObjFin != null) {
        let opt = optObjFin.opt
        let obj = optObjFin.obj
        let fin = optObjFin.fin

        setOptModelId(opt != undefined && opt != null ? opt.optModelID : -1)
        setObjModelId(obj != undefined && obj != null ? obj.objModelID : -1)
        setFinModelId(fin != undefined && fin != null ? fin.finModelID : -1)
      } else {
        setFinModelId(-1)
        setOptModelId(-1)
        setObjModelId(-1)
      }
      loadData()
    }
  }, [isOpen])

  const loadData = () => {
    api.getObjModels(scenarioId).then(data => {
      setObjModelList(data)
    })
    api.getFinModels(scenarioId).then(data => {
      setFinModelList(data)
    })
    api.getOptModels(scenarioId).then(data => {
      setOptModelList(data)
    })
  }

  const save = async () => {
    dispatch(
      changeOptObjFin({
        opt:
          optModelId > -1
            ? optModelList.find(x => x.optModelID == optModelId)
            : null,
        obj:
          objModelId > -1
            ? objModelList.find(x => x.objModelID == objModelId)
            : null,
        fin:
          finModelId > -1
            ? finModelList.find(x => x.finModelID == finModelId)
            : null,
      })
    )
    toggleModal()
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Select Scenario(s)
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <Label className="form-label">Criteria Scenario</Label>
          <select
            className="form-control form-select select2 mb-xxl-0"
            value={objModelId || 0}
            onChange={e => {
              setObjModelId(e.target.value)
            }}
          >
            <option value="-1">None</option>
            {objModelList.map((a, idx) => {
              return (
                <option key={idx} value={`${a.objModelID}`}>
                  {a.objModelName}
                </option>
              )
            })}
          </select>
        </div>
        <div className="mb-3">
          <Label className="form-label">Financial Scenario</Label>
          <select
            className="form-control form-select select2 mb-xxl-0"
            value={finModelId || 0}
            onChange={e => {
              setFinModelId(e.target.value)
            }}
          >
            <option value="-1">None</option>
            {finModelList.map((a, idx) => {
              return (
                <option key={idx} value={`${a.finModelID}`}>
                  {a.finModelName}
                </option>
              )
            })}
          </select>
        </div>
        <div className="mb-3">
          <Label className="form-label">Optimization Scenario</Label>
          <select
            className="form-control form-select select2 mb-xxl-0"
            value={optModelId || 0}
            onChange={e => {
              setOptModelId(e.target.value)
            }}
          >
            {/* <option value="-1">None</option> */}
            {optModelList.map((a, idx) => {
              return (
                <option key={idx} value={`${a.optModelID}`}>
                  {a.optModelName}
                </option>
              )
            })}
          </select>
        </div>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default SelectScenariosModal
