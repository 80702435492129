import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Modal } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const SelectAlternativeModal = ({
  isOpen,
  toggleModal,
  scenarioId,
  scenario,
  onSelect,
}) => {
  const currentUser = userService.getLoggedInUser()
  let [alternatives, setAlternatives] = useState([])
  let [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isOpen && (!alternatives || alternatives.length == 0)) {
      loadAlternatives()
    }
  }, [isOpen])

  const loadAlternatives = async () => {
    try {
      setIsLoading(true)
      var alts = await api.getAlternatives(currentUser.userID, scenarioId)
      setAlternatives(alts)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Select {scenario && scenario.alternativeName}
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        {!isLoading && alternatives.length > 0 && (
          <table
            className="table table-bordered low-padding-table"
            style={{ backgroundColor: "white" }}
          >
            <thead>
              <tr>
                <th className="col-1">ID</th>
                <th className="col-5">
                  {scenario && scenario.alternativeName}
                </th>
                <th className="col-1"></th>
              </tr>
            </thead>
            <tbody>
              {alternatives.length > 0 &&
                alternatives.map((a, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{a.alternativeNum}</td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "left",
                          }}
                        >
                          <a
                            href="#"
                            onClick={async e => {
                              e && e.preventDefault()
                              toggleModal()
                              onSelect(a.alternativeID)
                            }}
                          >
                            {a.alternativeName}
                          </a>
                        </div>
                      </td>
                      <td>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: a.version,
                          }}
                        ></div>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        )}
      </div>
    </Modal>
  )
}

export default SelectAlternativeModal
