import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import api from "services/api.service"
import { CostPoolEditableCell, getColorsByTableName } from "./ForecastSection"
import { currencyFormatter } from "utils/formatters"
import { TextareaAutosize } from "@material-ui/core"
import Loader from "react-loaders"
import colors from "utils/colors"
import isNullOrEmpty from "utils/isNullOrEmpty"
import { toast } from "react-toastify"
import { userService } from "services/user.service"

const FinancingSection = ({
  activeTab,
  alternativeId,
  enterpriseId,
  triggerSave,
  setTriggerSave,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [isLoading, setIsLoading] = useState()
  const [costTable1, setCostTable1] = useState([])
  const [costTable2, setCostTable2] = useState([])
  const [costTable3, setCostTable3] = useState([])
  //[{ yearID}]
  const [costPoolYears, setCostPoolYears] = useState([])
  const [interestRates, setInterestRates] = useState([])

  useEffect(() => {
    if (activeTab == "Finance") {
      loadData(true)
    }
  }, [activeTab])

  useEffect(() => {
    if (triggerSave) {
      saveData()
    }
  }, [triggerSave])

  const loadData = async toggleLoading => {
    if (toggleLoading === undefined || toggleLoading === true)
      setIsLoading(true)
    let costs = await api.getBCCostPools(alternativeId, "cost", false)
    let costWithYears = costs.find(
      x => x.bcCostPoolYears !== null && x.bcCostPoolYears.length > 0
    )
    if (costWithYears !== undefined) {
      setCostPoolYears(
        costWithYears.bcCostPoolYears.map(y => {
          return { yearID: y.yearID }
        })
      )
    }

    await loadInterestRates()

    let ct1 = []
    let ct2 = []
    let ct3 = []
    for (let i = 0; i < costs.length; i++) {
      if (i == 0) {
        ct1.push(costs[i])
        continue
      }
      if (ct1.filter(x => x.bcCostPoolID < 0).length !== 2) {
        ct1.push(costs[i])
        continue
      } else {
        if (ct2.filter(x => x.bcCostPoolID < 0).length !== 2) {
          ct2.push(costs[i])
          continue
        } else {
          if (ct3.filter(x => x.bcCostPoolID < 0).length !== 2) {
            ct3.push(costs[i])
            continue
          }
        }
      }
    }
    setCostTable1(ct1)
    setCostTable2(ct2)
    setCostTable3(ct3)

    setIsLoading(false)
  }

  const saveData = async () => {
    try {
      let rowsToUpdate = []
      rowsToUpdate = rowsToUpdate.concat(costTable1.filter(x => x.isDirty))
      rowsToUpdate = rowsToUpdate.concat(costTable2.filter(x => x.isDirty))
      rowsToUpdate = rowsToUpdate.concat(costTable3.filter(x => x.isDirty))

      if (rowsToUpdate.length > 0) {
        let rowsToUpdateTasks = rowsToUpdate.map(x => async () => {
          return await api.updateBCCostPool(currentUser.userID, x)
        })
        await Promise.all(rowsToUpdateTasks.map(t => t()))
        await api.updateAlternativeMetrics(alternativeId)
        // reload && reload()
        setTriggerSave(false)
        toast.success(`Finance updated successfuly`)
      } else {
        setTriggerSave(false)
      }
      await loadData(false)
    } catch (err) {
      setTriggerSave(false)
    }
  }

  const loadInterestRates = async () => {
    let bcRates = await api.getEnterpriseBcRates(enterpriseId)
    let intRates = bcRates.filter(x => x.rateType == "Interest")

    let tasks = intRates.map(x => async () => {
      return await api.getFinancingSummary(alternativeId, x.bcRateID)
    })

    if (tasks.length > 0) {
      let responses = await Promise.all(tasks.map(t => t()))
      responses.forEach((r, idx) => {
        intRates[idx].interestRatesTable = r
      })
    }

    setInterestRates(intRates)
  }

  const changeProp = (tableNameToModify, yearID, value, bcCostPoolId) => {
    let list = [...getListByTableName(tableNameToModify)]
    let setter = getSetterByTableName(tableNameToModify)
    let itemToModify = list.find(x => x.bcCostPoolID == bcCostPoolId)
    let yearToModify = itemToModify.bcCostPoolYears.find(
      x => x.yearID == yearID
    )
    if (yearToModify !== undefined) yearToModify.percFinance = value
    itemToModify.isDirty = true

    setter(list)

    toast.success(
      <div>
        <p className="mb-0">
          You have unsaved changes, don't forget to save them.
        </p>
        <div style={{ textAlign: "center" }}>
          <button
            className="btn btn-primary"
            onClick={() => {
              document.getElementById("saveAlternative").click()
            }}
          >
            <i className="fas fa-save"></i> Save
          </button>
        </div>
      </div>,
      {
        toastId: "unsavedChanges",
        autoClose: false,
      }
    )
  }

  const changeCostPoolProp = (
    tableNameToModify,
    propToModify,
    value,
    bcCostPoolId
  ) => {
    let list = [...getListByTableName(tableNameToModify)]
    let setter = getSetterByTableName(tableNameToModify)
    let itemToModify = list.find(x => x.bcCostPoolID == bcCostPoolId)
    itemToModify[propToModify] = value
    itemToModify.isDirty = true

    setter(list)

    toast.success(
      <div>
        <p className="mb-0">
          You have unsaved changes, don't forget to save them.
        </p>
        <div style={{ textAlign: "center" }}>
          <button
            className="btn btn-primary"
            onClick={() => {
              document.getElementById("saveAlternative").click()
            }}
          >
            <i className="fas fa-save"></i> Save
          </button>
        </div>
      </div>,
      {
        toastId: "unsavedChanges",
        autoClose: false,
      }
    )
  }

  const getSetterByTableName = tableName => {
    if (tableName == "costTable1") {
      return setCostTable1
    }
    if (tableName == "costTable2") {
      return setCostTable2
    }
    if (tableName == "costTable3") {
      return setCostTable3
    }
  }

  const getListByTableName = tableName => {
    if (tableName == "costTable1") {
      return costTable1
    }
    if (tableName == "costTable2") {
      return costTable2
    }
    if (tableName == "costTable3") {
      return costTable3
    }
  }

  const getTableRowsToRender = (list, listName) => {
    return (
      <React.Fragment>
        {list.length > 0 &&
          list.map((c, i) => {
            if (i == 0 || c.bcCostPoolID > 0)
              return (
                <tr
                  key={i}
                  style={{
                    backgroundColor:
                      i == 0
                        ? getColorsByTableName(listName).backgroundColor
                        : "inherit",
                    color:
                      i == 0 ? getColorsByTableName(listName).color : "inherit",
                  }}
                >
                  <td
                    style={{
                      verticalAlign: "middle",
                      fontWeight: i == 0 ? 600 : "inherit",
                      backgroundColor:
                        getColorsByTableName(listName).backgroundColor,
                      color: getColorsByTableName(listName).color,
                    }}
                  >
                    {c.costPoolName}
                  </td>
                  {costPoolYears.length > 0 &&
                    costPoolYears.map((y, idx) => (
                      <td key={idx}>
                        {i !== 0 && (
                          <CostPoolEditableCell
                            costPool={c}
                            costPoolYear={y}
                            changeProp={changeProp}
                            listName={listName}
                            propName="percFinance"
                            suffix="%"
                            key={idx}
                          />
                        )}
                      </td>
                    ))}

                  {/* <td
                    className="align-end"
                    style={{
                      backgroundColor: i !== 0 ? "lightgray" : "transparent",
                      fontWeight: "bold",
                    }}
                  >
                    {i !== 0 && <span>{getRowTotals(c, "percFinance")}%</span>}
                  </td> */}
                  {/* {!showResourceEstimates && ( */}
                  <td style={{ padding: "1px" }}>
                    {i == 0 ? (
                      c.notes
                    ) : c.showTextarea ? (
                      <TextareaAutosize
                        className="form-control"
                        onChange={e =>
                          changeCostPoolProp(
                            listName,
                            "notes",
                            e.target.value,
                            c.bcCostPoolID
                          )
                        }
                        value={c.notes}
                        disabled={c.flowThrough == true}
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            maxWidth: "600px",
                            marginBottom: "0px",
                          }}
                        >
                          {c.flowThrough == true
                            ? "This is a flow through cost pool. See the Resource Estimate tab. "
                            : ""}{" "}
                          {c.notes}
                        </p>
                        {c.flowThrough != true && (
                          <i
                            className="fas fa-edit"
                            style={{ alignSelf: "center", cursor: "pointer" }}
                            //   onClick={() => editNote(c, list, listName)}
                          ></i>
                        )}
                      </div>
                    )}
                  </td>
                  {/* )} */}
                </tr>
              )
          })}
        {/* {list.length > 0 && (
          <tr
            style={{
              backgroundColor: getColorsByTableName(listName).backgroundColor,
              color: getColorsByTableName(listName).color,
              fontWeight: 600,
            }}
          >
            <td style={{ textAlign: "right" }}>
              {list[list.length - 1].costPoolName}
            </td>
            {costPoolYears.length > 0 &&
              costPoolYears.map((y, idx) => (
                <td key={idx} className="align-end">
                  {getYearTotals(list, y)}%
                </td>
              ))}
            <td className="align-end" style={{ fontWeight: "bold" }}>
              {getTableYearsTotals(list, "percFinance")}%
            </td>
            <td></td>
          </tr>
        )} */}
      </React.Fragment>
    )
  }

  const getYearTotals = (table, year) => {
    return table.reduce((acc, item) => {
      const existingYear = item.bcCostPoolYears.find(
        x => x.yearID == year.yearID
      )
      return acc + (existingYear !== undefined ? existingYear.percFinance : 0)
    }, 0)
  }

  const getTotals = (table, propToSum) => {
    return table.reduce((acc, item) => {
      return acc + item[propToSum]
    }, 0)
  }

  const getRowTotals = (row, prop) => {
    return row.bcCostPoolYears.reduce((acc, item) => {
      return acc + item[prop]
    }, 0)
  }

  const getTableYearsTotals = (table, prop) => {
    let total = 0
    table.forEach(item => {
      item.bcCostPoolYears.forEach(y => {
        total += y.percFinance
      })
    })

    return total
  }

  return (
    <React.Fragment>
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      {!isLoading && (
        <>
          <Row className="mb-3">
            <Col>
              <h3>
                <b>Costs</b>
              </h3>
              {costPoolYears.length > 0 && (
                <table
                  className="table table-bordered lower-padding-table"
                  style={{ backgroundColor: "white", fontSize: "12px" }}
                >
                  <thead>
                    <tr>
                      <th style={{ width: "18%" }}></th>
                      {costPoolYears.map((y, idx) => (
                        <th
                          key={idx}
                          style={{ width: `${35 / costPoolYears.length}%` }}
                        >
                          FY {y.yearID}
                        </th>
                      ))}

                      {/* <th style={{ width: "7%" }}>Total</th> */}
                      {/* {!showResourceEstimates && ( */}
                      <th style={{ width: "40%" }}>Notes</th>
                      {/* )} */}
                    </tr>
                  </thead>
                  <tbody>
                    {getTableRowsToRender(costTable1, "costTable1")}
                    {getTableRowsToRender(costTable2, "costTable2")}
                    {getTableRowsToRender(costTable3, "costTable3")}
                  </tbody>
                </table>
              )}
            </Col>
          </Row>
          {interestRates.length > 0 && costPoolYears.length > 0 && (
            <Row>
              <Col>
                <h3 className="mb-3">
                  <b>Interest Rates</b>
                </h3>
                {interestRates
                  .filter(
                    x =>
                      !isNullOrEmpty(x.interestRatesTable) &&
                      x.interestRatesTable.length > 0
                  )
                  .map((r, idx) => (
                    <div key={idx} className="mb-3">
                      <h5>{r.bcRateName}</h5>
                      <table
                        className="table table-bordered lower-padding-table"
                        style={{ backgroundColor: "white", fontSize: "12px" }}
                      >
                        <thead>
                          <tr>
                            {Object.keys(r.interestRatesTable[0])
                              .filter(x => x.startsWith("c-"))
                              .map((k, idx) => (
                                <th
                                  style={{
                                    textAlign:
                                      parseInt(k.replace("c-", "")) !== NaN
                                        ? "center"
                                        : "start",
                                  }}
                                  key={idx}
                                >
                                  {k.replace("c-", "") == "Cost Pool"
                                    ? ""
                                    : k.replace("c-", "")}
                                </th>
                              ))}
                          </tr>
                        </thead>
                        <tbody>
                          {r.interestRatesTable.map((f, i) => {
                            return (
                              <tr
                                key={i}
                                style={{
                                  backgroundColor: f.backColor,
                                }}
                              >
                                {Object.keys(f)
                                  .filter(x => x.startsWith("c-"))
                                  .map((k, idx2) => (
                                    <td
                                      key={idx2}
                                      className={
                                        !isNaN(f[k]) ? "align-end" : ""
                                      }
                                      style={{
                                        fontWeight:
                                          idx2 == 0 || f.bold == "1"
                                            ? 600
                                            : 100,
                                      }}
                                    >
                                      {idx2 == 0
                                        ? f[k]
                                        : f.format == "$"
                                        ? currencyFormatter.format(f[k])
                                        : f.format == "%"
                                        ? `${f[k]}%`
                                        : f[k]}
                                    </td>
                                  ))}
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  ))}
              </Col>
            </Row>
          )}
        </>
      )}
    </React.Fragment>
  )
}

export default FinancingSection
