export const userService = {
  getLoggedInUser,
  updateUser,
}

function getLoggedInUser() {
  let data = JSON.parse(localStorage.getItem("currentData"))
  if (data === "undefined" || data == null) return {}
  let user = data.user
  if (user === "undefined" || user == null) return {}
  return JSON.parse(user)
}

function updateUser(user) {
  let data = JSON.parse(localStorage.getItem("currentData"))
  if (data === "undefined" || data == null) return
  data.user = JSON.stringify(user)
  localStorage.setItem("currentData", JSON.stringify(data))
}
