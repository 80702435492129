import React, { useState } from "react"
import Loader from "react-loaders"
import { Modal, PopoverBody, UncontrolledPopover } from "reactstrap"
import api from "services/api.service"
import colors from "utils/colors"
import { Popover } from "@material-ui/core"

const HelpIconWithModal = ({ itemId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [helpItem, setHelpItem] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const toggleModal = () => setShowModal(!showModal)
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const loadData = async e => {
    setIsLoading(true)
    let data = await api.getHelpItem(itemId, 0)
    setHelpItem(data)
    setIsLoading(false)
    setOpen(true)
    setAnchorEl(document.getElementById("helpButton12345"))
  }


  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  return (
    <React.Fragment>
      <button
        id="helpButton12345"
        type="button"
        className="btn always-enabled"
        onClick={loadData}
      >
        <i className="bx bx-help-circle" style={{ fontSize: "18px" }} />
      </button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {helpItem !== null && (
          <div
            style={{ background: "#d8f4ec", fontFamily: "Inter", padding: "10px", maxWidth: '700px' }}
          >
            <div
              // style={{ marginLeft: "10px" }}
              dangerouslySetInnerHTML={{
                __html: helpItem.helpItemText,
              }}
            ></div>
          </div>
        )}
      </Popover>
      
      <Modal
      backdrop="static"
        isOpen={showModal}
        size="md"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Help
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          {!isLoading && helpItem != null && (
            <div
              style={{ marginLeft: "10px" }}
              dangerouslySetInnerHTML={{
                __html: helpItem.helpItemText,
              }}
            ></div>
          )}
        </div>

        <div
          className="modal-footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Close
          </button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default HelpIconWithModal
