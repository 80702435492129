import { swalWithConfirmButton } from "components/custom/swal"
import scoringColumns from "pages/AdminObjectiveScore/ScoringColumns"
import ObjectiveRatingTable from "pages/_ObjectiveShared/ObjectiveRatingTable"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { Col, Container, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { loadPageItem } from "store/actions"
import colors from "utils/colors"
import logo from "../../../assets/images/defprologo1_sm.png"
import { toast } from "react-toastify"

const ObjectiveScore = props => {
  const scenarioId = props.match?.params?.scenarioId ?? props.scenarioId
  const mode = props.match?.params?.mode ?? props.mode
  //only populated when used in the web version of the page
  const selectedPwcScoreId = props.selectedPwcScoreId
  const selectedLevel = props.selectedLevel
  const currentUser = userService.getLoggedInUser()

  let [nextObjectivePwcScore, setNextObjectivePwcScore] = useState(null)
  let [pwcUserScore, setPwcUserScore] = useState(null)
  let [isLoading, setIsLoading] = useState(false)
  let [scenario, setScenario] = useState(null)
  const [posNegEq, setPosNegEq] = useState(2) // -1, 0, 1 ...>1

  useEffect(() => {
    if (mode == 1) {
      loadNextDataGroup()
    } else {
      if (selectedPwcScoreId !== undefined && selectedPwcScoreId != -1) {
        loadCurrentData(
          selectedPwcScoreId,
          selectedLevel != undefined ? selectedLevel : 1
        )
      } else {
        loadNextData(0, 1)
      }
    }
    loadScenario()
  }, [])

  const loadCurrentData = async (pwcScoreId, levelId) => {
    let objScore = await api.getPwcScore(pwcScoreId, levelId, scenarioId)
    setNextObjectivePwcScore(objScore)
    if (objScore.mobileComplete) {
      handleMobileComplete()
      return
    }

    let userScore = await loadPwcUserScores(
      objScore.pwcScoreID,
      objScore.levelID
    )
    setPwcUserScore(userScore)
  }

  const loadNextData = async (pwcScoreId, levelId) => {
    let objScore = await loadNextObjectivePwcScore(pwcScoreId, levelId)
    setNextObjectivePwcScore(objScore)
    if (objScore.mobileComplete) {
      handleMobileComplete()
      return
    }

    let userScore = await loadPwcUserScores(
      objScore.pwcScoreID,
      objScore.levelID
    )
    setPwcUserScore(userScore)
  }

  const handleMobileComplete = () => {
    if (props.history != undefined) {
      if (mode == 1) {
        props.history.push("/mobile/exit")
        return
      } else {
        props.history.push(`/mobile/abcobjective/overview/${scenarioId}`)
        return
      }
    } else {
      swalWithConfirmButton
        .fire({
          title:
            "Congratulations, you provided all the judgements for the decision!",
          icon: "success",
          showCancelButton: false,
          confirmButtonText: "Ok",
        })
        .then(r => {})
    }
  }

  const loadPrevData = async (pwcScoreId, levelId) => {
    let objScore = await loadPrevObjectivePwcScore(pwcScoreId, levelId)
    setNextObjectivePwcScore(objScore)

    let userScore = await loadPwcUserScores(
      objScore.pwcScoreID,
      objScore.levelID
    )
    setPwcUserScore(userScore)
  }

  const loadNextObjectivePwcScore = async (pwcScoreId, levelId) => {
    try {
      setIsLoading(true)
      let objScore = await api.getNextPwcScore(pwcScoreId, levelId, scenarioId)
      setIsLoading(false)

      return objScore
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }

  const loadPrevObjectivePwcScore = async (pwcScoreId, levelId) => {
    try {
      setIsLoading(true)
      let objScore = await api.getPrevPwcScore(pwcScoreId, levelId, scenarioId)
      setIsLoading(false)
      return objScore
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }

  const loadPwcUserScores = async (pwcScoreId, levelId) => {
    try {
      let scores = await api.getPwcUserScores(pwcScoreId, levelId)
      let score = scores.find(x => x.userID == currentUser.userID)
      if (score != undefined && score != null && score.userScored) {
        setPosNegEq(score.userRanking == 0 ? 0 : score.userRanking > 0 ? 1 : -1)
      } else {
        setPosNegEq(100)
      }
      return score
    } catch (err) {
      console.log(err)
    }
  }

  /*   const loadPwcUserScore = async (pwcScoreId, levelId, userId) => {
    try {
      let score = await api.getPwcUserScore(pwcScoreId, levelId, userId)
      if (score != undefined && score != null && score.userScored) {
        setPosNegEq(score.userRanking == 0 ? 0 : score.userRanking > 0 ? 1 : -1)
      } else {
        setPosNegEq(100)
      }
      return score
    } catch (err) {
      console.log(err)
    }
  }  */

  const reloadObjScore = async (pwcScoreId, levelId) => {
    try {
      setIsLoading(true)
      let pwcScore = await api.getPwcScore(pwcScoreId, levelId)
      setNextObjectivePwcScore(pwcScore)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }

  const loadScenario = async () => {
    try {
      var scenario = await api.getScenario(scenarioId)
      setScenario(scenario)
    } catch (err) {
      console.log(err)
    }
  }

  const getObjBackgroundColor = getComparisonFunc => {
    return pwcUserScore != undefined &&
      pwcUserScore != null &&
      //   pwcUserScore.userScored &&
      getComparisonFunc(pwcUserScore)
      ? colors.primary
      : "lightblue"
  }
  const getObjColor = getComparisonFunc => {
    return pwcUserScore != undefined &&
      pwcUserScore != null &&
      //   pwcUserScore.userScored &&
      getComparisonFunc(pwcUserScore)
      ? "white"
      : "black"
  }

  const onObjSelect = positiveNegativeEqual => {
    setPosNegEq(positiveNegativeEqual)
  }

  const changeUserScore = score => {
    let copy = { ...pwcUserScore }
    copy.userRanking = score
    copy.userScored = true
    setPwcUserScore(copy)
    api.updatePwcUserScore(
      pwcUserScore.levelID,
      nextObjectivePwcScore.pwcScoreID,
      {
        ...copy,
      }
    )
  }

  const submit = async () => {
    if (
      pwcUserScore != undefined &&
      pwcUserScore != null &&
      pwcUserScore.userScored
    ) {
      await api.updatePwcUserScore(
        pwcUserScore.levelID,
        nextObjectivePwcScore.pwcScoreID,
        {
          ...pwcUserScore,
        }
      )
      toast.success("Your data has been submitted")
    } else {
      swalWithConfirmButton.fire({
        title: `Please select a rating before submitting`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    }
  }

  const loadNextDataGroup = async () => {
    let pwcGroup = await api.getPwcScoreGroup(scenarioId)
    let nextScoreID = 0
    if (nextObjectivePwcScore !== null) {
      nextScoreID = nextObjectivePwcScore.pwcScoreID
    }

    if (pwcGroup.pwcScoreID == nextScoreID) {
      swalWithConfirmButton.fire({
        title: `The score was submitted, please wait of the facilitator to move forward`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    } else {
      if (pwcGroup.mobileComplete) {
        handleMobileComplete()
        return
      }
      setNextObjectivePwcScore(pwcGroup)

      let userScore = await loadPwcUserScores(
        pwcGroup.pwcScoreID,
        pwcGroup.levelID
      )
      setPwcUserScore(userScore)

      console.log(nextObjectivePwcScore)
      console.log(userScore)
    }
  }

  const onNext = async () => {
    if (
      pwcUserScore != undefined &&
      pwcUserScore != null &&
      pwcUserScore.userScored
    ) {
      if (mode == 1) {
        await loadNextDataGroup()
      } else {
        await loadNextData(
          nextObjectivePwcScore.pwcScoreID,
          nextObjectivePwcScore.levelID
        )
      }
    } else {
      swalWithConfirmButton.fire({
        title: `Please select a rating before moving on`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    }
  }

  return (
    <React.Fragment>
      <div
        style={{
          overflow: "hidden",
          color: "white",
        }}
      >
        <Row
          className="background-gradient"
          style={{
            height: props.height ? props.height : "100vh",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              alignSelf: "start",
              height: "70px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "whitesmoke",
              paddingRight: "50px",
              paddingLeft: "50px",
            }}
          >
            <img src={logo} style={{ width: "30px" }}></img>
            <h5 style={{ color: colors.primary }}>
              <b>
                Comparison{" "}
                <u>
                  {nextObjectivePwcScore && nextObjectivePwcScore.comparison}
                </u>
              </b>
            </h5>
            <i
              className="fas fa-power-off"
              style={{
                cursor: "pointer",
                color: colors.primary,
                fontSize: "16px",
              }}
              onClick={() => props.history.push("/mobile/exit")}
            ></i>
          </div>
          <Col
            md={props.col ? props.col : "6"}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              textAlign: "center",
              height: "100%",
              gap: props.height == "100%" ? "30px" : "unset",
            }}
          >
            {/* <img src={logo} alt="" style={{ width: "70%", height: "auto" }} /> */}

            <Row style={{ margin: "0%" }}>
              {nextObjectivePwcScore != null && pwcUserScore != null && (
                <div>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      borderRadius: "10px",
                      padding: "5px",
                    }}
                  >
                    <h5>
                      With respect to the{" "}
                      {nextObjectivePwcScore.levelID == 1 && (
                        <span style={{ color: "maroon", fontWeight: "600" }}>
                          decision goal
                        </span>
                      )}
                      {nextObjectivePwcScore.levelID == 2 && (
                        <span style={{ color: "maroon", fontWeight: "600" }}>
                          {scenario != null &&
                            scenario.objectiveName.toLowerCase()}
                          : {nextObjectivePwcScore.subHeader}
                        </span>
                      )}
                      ,<br /> which{" "}
                      {nextObjectivePwcScore.levelID == 1 && (
                        <span>
                          {scenario != null &&
                            scenario.objectiveName.toLowerCase()}
                        </span>
                      )}
                      {nextObjectivePwcScore.levelID == 2 && (
                        <span>
                          {scenario != null &&
                            scenario.subCriteriaName.toLowerCase()}
                        </span>
                      )}
                      &nbsp;is more important?
                    </h5>
                  </div>
                  <Row style={{ margin: "0px" }}>
                    <Col>
                      <div
                        className="btn mb-3 vote-button"
                        style={{
                          backgroundColor: getObjBackgroundColor(
                            score => posNegEq == 1
                          ),
                          color: getObjColor(score => posNegEq == 1),
                          width: "90%",
                        }}
                        onClick={() => {
                          onObjSelect(1)
                          setPwcUserScore({
                            ...pwcUserScore,
                            userRanking: undefined,
                            userScored: false,
                          })
                        }}
                      >
                        {nextObjectivePwcScore.item1}
                      </div>
                      <div
                        className="btn mb-3 vote-button"
                        style={{
                          backgroundColor: getObjBackgroundColor(
                            score => posNegEq == 0
                          ),
                          color: getObjColor(score => posNegEq == 0),
                          width: "90%",
                        }}
                        onClick={() => {
                          onObjSelect(0)
                          changeUserScore(0)
                        }}
                      >
                        They are equal
                      </div>
                      <div
                        className="btn mb-3 vote-button"
                        style={{
                          backgroundColor: getObjBackgroundColor(
                            score => posNegEq == -1
                          ),
                          color: getObjColor(score => posNegEq == -1),
                          width: "90%",
                        }}
                        onClick={() => {
                          onObjSelect(-1)
                          setPwcUserScore({
                            ...pwcUserScore,
                            userRanking: undefined,
                            userScored: false,
                          })
                        }}
                      >
                        {nextObjectivePwcScore.item2}
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </Row>

            {scenario != null &&
              nextObjectivePwcScore != null &&
              pwcUserScore != undefined &&
              pwcUserScore != null &&
              (posNegEq == -1 || posNegEq == 1) && (
                <Row
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "80%",
                  }}
                >
                  {posNegEq != 0 && (
                    <h5>
                      <b>Select a rating to indicate the degree.</b>
                    </h5>
                  )}
                  {scoringColumns
                    .filter(
                      x =>
                        scenario.ahpScaleID == 2 ||
                        x.ahpScaleID == scenario.ahpScaleID
                    )
                    .filter(x =>
                      posNegEq == 1
                        ? x.value > 0
                        : posNegEq == 0
                        ? false
                        : x.value < 0
                    )
                    .sort((a, b) =>
                      posNegEq == 1 ? b.value - a.value : a.value - b.value
                    )
                    .map((s, idx) => {
                      return (
                        <div
                          key={idx}
                          className="vote-button mb-3"
                          style={{
                            color: s.textColor,
                            backgroundColor: s.color,
                            fontWeight:
                              s.value == pwcUserScore.userRanking
                                ? "bold"
                                : "normal",
                            padding: "5px",
                            // width: "400px",
                            height: "30px",
                          }}
                          onClick={() => changeUserScore(s.value)}
                        >
                          {s.name}{" "}
                          {s.value == pwcUserScore.userRanking && (
                            <i className="fas fa-check"></i>
                          )}
                        </div>
                      )
                    })}
                </Row>
              )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                paddingLeft: "5%",
                paddingRight: "5%",
              }}
            >
              {nextObjectivePwcScore != null && (
                <button
                  type="button"
                  className="btn btn-primary btn-lg "
                  // style={{ marginRight: "10px" }}
                  onClick={async () =>
                    mode == 1
                      ? await submit()
                      : await loadPrevData(
                          nextObjectivePwcScore.pwcScoreID,
                          nextObjectivePwcScore.levelID
                        )
                  }
                >
                  {mode == 1 ? (
                    "Submit"
                  ) : (
                    <>
                      <i className="fas fa-arrow-left"></i> Prev
                    </>
                  )}
                </button>
              )}
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              {nextObjectivePwcScore != null && (
                <button
                  type="button"
                  className="btn btn-primary btn-lg "
                  onClick={onNext}
                >
                  Next <i className="fas fa-arrow-right"></i>
                </button>
              )}
            </div>
            <div>
              <p className="mb-0">
                U.S. Patent Nos. 10,268,977; 10,366,361; 10,417,590
              </p>
              <p>All Rights Reserved</p>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default ObjectiveScore
