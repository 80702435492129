import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"

const ChangeCostPoolsModal = ({ isOpen, toggleModal, alternativeId }) => {
  const currentUser = userService.getLoggedInUser()
  const [selectedBcTemplateId, setSelectedBcTemplateId] = useState(-1)
  let [costPoolTemplates, setCostPoolTemplates] = useState([])

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    if (isOpen) {
      setSelectedBcTemplateId(-1)
    }
  }, [isOpen])

  const loadData = () => {
    api.getList(currentUser.userID, "CostPoolTemplate").then(cpt => {
      setCostPoolTemplates(cpt)
    })
  }

  const save = async () => {
    if (selectedBcTemplateId > -1) {
      await api.applyAlternativeCostPools(alternativeId, selectedBcTemplateId)
      toast.success("Cost pools changed successfuly")
      toggleModal()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Change Cost Pools
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <Label className="form-label">Cost Pool Template</Label>
          <select
            className="form-control form-select select2 mb-xxl-0"
            value={selectedBcTemplateId}
            onChange={e => {
              setSelectedBcTemplateId(e.target.value)
            }}
          >
            <option value={-1}>Select...</option>
            {costPoolTemplates.map((a, idx) => {
              return (
                <option key={idx} value={`${a.listItemID}`}>
                  {a.listItemName}
                </option>
              )
            })}
          </select>
        </div>
        <p>
          Performing this action will replace the cost pools for this project.
          All Forecast data entered will be lost. Are you sure
        </p>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          disabled={selectedBcTemplateId == -1}
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default ChangeCostPoolsModal
