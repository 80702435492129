import { LOADED_PAGE_ITEM, LOAD_PAGE_ITEM } from "./actionTypes"

const INIT_STATE = {
  pageItem: {
    pageSubTitle: "",
    pageTitle: "",
    pageHelpStatus: "",
    pageHelp: "",
    userRight: "",
  },
}

const pageItem = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOADED_PAGE_ITEM:
      return {
        ...state,
        pageItem: action.payload,
      }

    default:
      return state
  }
}

export default pageItem
