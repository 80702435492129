import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import useFetcher from "hooks/useFetcher"
import useModal from "hooks/useModalHook"
import React, { useEffect, useRef, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import { createRoot } from "react-dom/client"
import Loader from "react-loaders"
import { ReactTabulator } from "react-tabulator"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import { customFormatter } from "utils/tabulator"

const PlanningStagesSection = ({ activeTab, scenarioId, planningStageLabel }) => {
  const currentUser = userService.getLoggedInUser()
  const [phases, setPhases] = useState([])
  const [phaseToEdit, setPhaseToEdit] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { show: showEditModal, toggle: toggleEditModal } = useModal()
  const tabulatorRef = useRef()

  useEffect(() => {
    if (activeTab == "7") {
      loadData()
    }
  }, [activeTab])

  const loadData = async toggleLoading => {
    if (toggleLoading == undefined || toggleLoading !== false)
      setIsLoading(true)
    let ph = await api.getAlternativePhases(scenarioId)
    setPhases(ph)
    await loadChart()
    setIsLoading(false)
  }

  const loadChart = async () => {
    anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
    if (
      document.getElementById("chartdiv") !== undefined &&
      document.getElementById("chartdiv") !== null
    ) {
      document.getElementById("chartdiv").innerHTML = ""
    }
    let chartResp = await api.getAlternativePhasesChart(scenarioId, 0)
    var dataStr = chartResp.chartArray.replaceAll("\\", "")
    var data = JSON.parse(dataStr)
    var data2 = JSON.parse(chartResp.chartArray2.replaceAll("\\", ""))
    var data3 = JSON.parse(chartResp.chartData.replaceAll("\\", ""))

    var chart = anychart.area()

    // create a range spline area series and set the data
    var series1 = chart.rangeSplineArea(data)
    series1.name("Uncertainty Range")

    var series2 = chart.line(data2).size(2).hovered({ size: 5 })
    series2.name("Contingency")

    // create third series with mapped data
    var series3 = chart.spline(data3).markers(false)
    series3.name("Actual Cost")

    chart.legend().enabled(true).fontSize(14).padding([0, 0, 15, 0])

    chart.title("Cone of Uncertainty")
    // set the container id
    chart.container("chartdiv")

    var xLabels = chart.xAxis().labels()
    xLabels.width(150)
    xLabels.wordWrap("break-word")
    //xLabels.wordBreak("break-all");

    chart.credits().enabled(false)
    // initiate drawing the chart
    chart.draw()
  }

  const addPhase = () => {
    setPhaseToEdit({
      alternativePhaseID: -1,
      scenarioId: scenarioId,
      coneUpperPercent: 0,
      coneLowerPercent: 0,
      estimateAdder: 0,
      alternativePhaseName: "",
      orderID:
        !phases || phases.length == 0
          ? 0
          : Math.max(...phases.map(x => x.orderID)),
    })
    toggleEditModal()
  }

  const handleReorder = async reorderedList => {
    // let oldIndex = phases.indexOf(
    //   phases.find(x => x.alternativePhaseID == originalPhase.alternativePhaseID)
    // )
    // if (newIndex == oldIndex) {
    //   return
    // }

    // let itemsToUpdate = []

    // let itemsCopy = [...phases]
    // itemsCopy.splice(oldIndex, 1)
    // itemsCopy.splice(newIndex, 0, originalPhase)
    // if (newIndex < oldIndex) {
    //   itemsToUpdate.push({
    //     ...originalPhase,
    //     orderID: itemsCopy[newIndex + 1].orderID,
    //   })

    //   for (let i = newIndex + 1; i <= oldIndex; i++) {
    //     if (i == oldIndex) {
    //       itemsCopy[i].orderID = originalPhase.orderID
    //     } else {
    //       itemsCopy[i].orderID = itemsCopy[i + 1].orderID
    //     }
    //     itemsToUpdate.push(itemsCopy[i])
    //   }
    // } else {
    //   itemsToUpdate.push({
    //     ...originalPhase,
    //     orderID: itemsCopy[newIndex - 1].orderID,
    //   })
    //   for (let i = newIndex - 1; i >= oldIndex; i--) {
    //     if (i == oldIndex) {
    //       itemsCopy[i].orderID = originalPhase.orderID
    //     } else {
    //       itemsCopy[i].orderID = itemsCopy[i - 1].orderID
    //     }
    //     itemsToUpdate.push(itemsCopy[i])
    //   }
    // }
    let itemsToUpdate = reorderedList.filter(x => x.isDirty)
    if (itemsToUpdate.length > 0) {
      let itemsToUpdateTasks = itemsToUpdate.map(x => async () => {
        return await api.updateAlternativePhase(currentUser.userID, x)
      })
      await Promise.all(itemsToUpdateTasks.map(t => t()))
      await loadData(false)
    }
  }

  const handleNamePress = async (e, phase) => {
    e && e.preventDefault()
    setPhaseToEdit({ ...phase })
    toggleEditModal()
  }

  const columns = [
    {
      rowHandle: true,
      formatter: "handle",
      headerSort: false,
      frozen: true,
      width: 50,
    },
    {
      title: planningStageLabel,
      field: "alternativePhaseName",
      headerSort: false,
      formatter: customFormatter(({ rowData, cellValue }) => (
        <a href="#" onClick={e => handleNamePress(e, rowData)}>
          {cellValue}
        </a>
      )),
    },
    {
      title: "Upper Range",
      field: "coneUpperPercent",
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getValue() == null || cell.getValue() == ""
          ? ""
          : cell.getValue() + "%"
      },
      width: 200,
      headerSort: true,
    },
    {
      title: "Lower Range",
      field: "coneLowerPercent",
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getValue() == null || cell.getValue() == ""
          ? ""
          : cell.getValue() + "%"
      },
      width: 200,
      headerSort: true,
    },
    {
      title: "Contingency",
      field: "estimateAdder",
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getValue() == null || cell.getValue() == ""
          ? ""
          : cell.getValue() + "%"
      },
      width: 200,
      headerSort: true,
    },
  ]

  return (
    <React.Fragment>
      <Row>
        <Col>
          <button onClick={addPhase} className="btn btn-primary mb-3">
            <i className="fas fa-plus"></i> Add {planningStageLabel}
          </button>

          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          {!isLoading && (
            <ReactTabulator
              onRef={r => (tabulatorRef.current = r.current)}
              columns={columns}
              data={phases}
              options={{
                pagination: "local",
                paginationSize: 30,
                paginationSizeSelector: [5, 10, 20, 30, 50, 100, 200, 300],
                movableRows: true,
                layout: "fitColumns",
                cssClass: "table-borderless",
              }}
              events={{
                rowMoved: function (row) {
                  let list = row.getTable().getData()
                  list = list.map((l, idx) => {
                    if (l.orderId != idx + 1) {
                      l.orderID = idx + 1
                      l.isDirty = true
                    }
                    return l
                  })
                  handleReorder(list)
                },
              }}
            />
          )}
          <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
        </Col>
      </Row>
      <EditPhaseModal
        isOpen={showEditModal}
        toggleModal={toggleEditModal}
        phaseToEdit={phaseToEdit}
        setPhaseToEdit={setPhaseToEdit}
        reload={loadData}
      />
    </React.Fragment>
  )
}

const EditPhaseModal = ({
  isOpen,
  toggleModal,
  phaseToEdit,
  setPhaseToEdit,
  reload,
}) => {
  const currentUser = userService.getLoggedInUser()

  const save = async () => {
    if (phaseToEdit.alternativePhaseID > 0) {
      await api.updateAlternativePhase(currentUser.userID, { ...phaseToEdit })
    } else {
      await api.createAlternativePhase(currentUser.userID, { ...phaseToEdit })
    }
    reload()
    toggleModal()
  }

  const deletePhase = () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this Stage?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteAlternativePhase(
            currentUser.userID,
            phaseToEdit.alternativePhaseID
          )
          reload()
          toggleModal()
        }
      })
  }

  if (phaseToEdit == null) return
  return (
    <Modal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {phaseToEdit.alternativePhaseID > 0 ? "Edit " : "Add "} Stage
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <Label className="form-label">Stage</Label>
          <Input
            name="note"
            type="text"
            onChange={e =>
              setPhaseToEdit({
                ...phaseToEdit,
                alternativePhaseName: e.target.value,
              })
            }
            value={phaseToEdit.alternativePhaseName}
          />
        </div>
        <div className="mb-3">
          <Label className="form-label">Upper Range</Label>
          <CurrencyInput
            className="form-control"
            value={phaseToEdit.coneUpperPercent}
            onValueChange={(value, name, values) => {
              setPhaseToEdit({
                ...phaseToEdit,
                coneUpperPercent: value,
              })
            }}
            prefix={""}
            suffix="%"
            decimalScale={0}
            decimalsLimit={0}
          />
        </div>
        <div className="mb-3">
          <Label className="form-label">Lower Range</Label>
          <CurrencyInput
            className="form-control"
            value={phaseToEdit.coneLowerPercent}
            onValueChange={(value, name, values) => {
              setPhaseToEdit({
                ...phaseToEdit,
                coneLowerPercent: value,
              })
            }}
            prefix={""}
            suffix="%"
            decimalScale={0}
            decimalsLimit={0}
          />
        </div>
        <div className="mb-3">
          <Label className="form-label">Contingency</Label>
          <CurrencyInput
            className="form-control"
            value={phaseToEdit.estimateAdder}
            onValueChange={(value, name, values) => {
              setPhaseToEdit({
                ...phaseToEdit,
                estimateAdder: value,
              })
            }}
            prefix={""}
            suffix="%"
            decimalScale={0}
            decimalsLimit={0}
          />
        </div>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>
          {phaseToEdit.alternativePhaseID > 0 && (
            <button
              type="button"
              className="btn btn-danger save-user"
              onClick={deletePhase}
            >
              Delete
            </button>
          )}
        </div>

        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default PlanningStagesSection
