export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

export const extractInnerTextFromHtml = htmlString => {
  const tempElement = document.createElement("div")
  tempElement.innerHTML = htmlString

  let innerElement = tempElement
  while (innerElement.firstChild) {
    innerElement = innerElement.firstChild
  }

  return innerElement.textContent
}
