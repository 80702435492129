import { TextareaAutosize, Tooltip } from "@material-ui/core"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import { currencyFormatter } from "utils/formatters"
import ResourceManagerModal from "./Modals/ResourceManagerModal"
import { getAlternativeYears } from "utils/alternativeHelper"
import ResourceEstimateRequestNotesModal from "./Modals/ResourceEstimateRequestNotesModal"
import ResourceEstimateLogModal from "./Modals/ResourceEstimateLogModal"
import useModal from "hooks/useModalHook"
import ClearEstimatesModal from "./Modals/ClearEstimatesModal"
import TShirtHoursAllocationModal from "./Modals/TShirtHoursAllocationModal"

const ResourceBudgetingSection = ({
  activeTab,
  alternative,
  alternativeId,
  triggerSave,
  setTriggerSave,
  showToastChanges,
  changeAlternativeProp,
  setAlternative,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [isEditMode, setIsEditMode] = useState(true)

  const [resourceGroups, setResourceGroups] = useState([])
  const [selectedResourceGroupId, setSelectedResourceGroupId] = useState(-1)
  const [selectedBCCostPoolId, setSelectedBCCostPoolId] = useState(-99)
  // const [bcCostPools, setBcCostPools] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [altResourceGroups, setAltResourceGroups] = useState([])
  const [bcLineItems, setBcLineItems] = useState([])
  const [selectedBcLineItemId, setSelectedBcLineItemId] = useState(-99)
  const [altResourceGroupsView, setAltResourceGroupsView] = useState([])
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false)
  const [emailNotes, setEmailNotes] = useState("")
  const [showManagerModal, setShowManagerModal] = useState(false)
  const toggleManagerModal = () => setShowManagerModal(!showManagerModal)

  const {
    show: showResourceEstimateLogModal,
    toggle: toggleResourceEstimateLogModal,
  } = useModal()
  const [resourceEstimateLogModel, setResourceEstimateLogModel] = useState(null)

  const { show: showClearEstimatesModal, toggle: toggleClearEstimatesModal } =
    useModal()
  const { show: showTShirtModal, toggle: toggleTShirtModal } = useModal()

  const toggleEmailModal = () => setIsEmailModalVisible(!isEmailModalVisible)

  const [
    resourceGroupCostPoolsForTShirtModal,
    setResourceGroupCostPoolsForTShirtModal,
  ] = useState(null)
  const [
    resourceGroupBundleIdForTShirtModal,
    setResourceGroupBundleIdForTShirtModal,
  ] = useState(null)

  useEffect(() => {
    if (activeTab == "ResourceBudget") {
      loadData()
    }
  }, [activeTab, alternativeId])

  useEffect(() => {
    if (triggerSave) {
      saveAltRg()
    }
  }, [triggerSave])

  const loadData = async () => {
    setIsLoading(true)
    let rg = await api.getResourceGroups(alternative.scenarioID, 0)
    setResourceGroups(rg)
    // let bcp = await api.getBCCostPools(alternative.alternativeID, "cost", false)
    // setBcCostPools(bcp)
    let bcitems = await api.getScenarioList(
      alternative.scenarioID,
      "BCLineItems"
    )
    setBcLineItems(bcitems)
    loadTableData(selectedResourceGroupId, selectedBCCostPoolId, rg)
    //loadChart2()
  }

  const loadViewTableData = async (rgId, lineItemId) => {
    if (altResourceGroupsView.length == 0) {
      setIsLoading(true)
    }
    let summary = await api.getCostPoolSummary(
      currentUser.userID,
      alternativeId,
      rgId
    )
    let data = await api.getAlternativeResourceBudgetsView(
      currentUser.userID,
      alternativeId,
      rgId,
      lineItemId
    )
    setAltResourceGroupsView([
      ...summary.map(s => {
        s.backgroundColor = "lightyellow"
        s.fontWeight = "bold"
        s.formatAsCurrency = true
        return s
      }),
      ...data,
    ])
    setIsLoading(false)
  }

  const getAltRGTotal = altRG => {
    let sum = 0
    alternativeYears.forEach(y => {
      sum += altRG[`c-${y}`]
    })
    return sum
  }

  const loadTableData = async (rgId, cpId, allResourceGroups) => {
    try {
      setIsLoading(true)
      let data = await api.getAlternativeResourceBudgetsGrid(
        alternativeId,
        rgId,
        cpId,
        currentUser.userID
      )

      setAltResourceGroups(
        data.map((d, i) => {
          d.id = i

          let rg = allResourceGroups.find(
            x => x.resourceGroupID == d.resourceGroupID
          )
          if (rg !== undefined && d["c-Cost Pool"].startsWith("Project -")) {
            d.hasTShirts = rg.hasTShirts
          }

          return d
        })
      )
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  const saveAltRg = async () => {
    await handleSave(altResourceGroups)
  }

  const handleSave = async listToSave => {
    toast.dismiss()
    let itemsToUpdate = []
    listToSave.forEach(alt => {
      if (alt.yearsToUpdate !== undefined && alt.yearsToUpdate.length > 0) {
        alt.yearsToUpdate.forEach(i => {
          itemsToUpdate.push({
            resourceGroupID: alt.resourceGroupID,
            alternativeID: alternativeId,
            costPoolID: alt.costPoolID,
            yearID: i,
            numUnits: alt[`c-${i}`],
          })
        })
      }
    })

    if (itemsToUpdate.length > 0) {
      let itemsToUpdateTasks = itemsToUpdate.map(x => async () => {
        return await api.updateAlternativeResourceBudget(currentUser.userID, x)
      })
      await Promise.all(itemsToUpdateTasks.map(t => t()))
    }
    let rowsToUpdate = listToSave
      .filter(x => x.isDirty)
      .map(x => {
        return {
          resourceGroupID: x.resourceGroupID,
          costPoolID: x.costPoolID,
          alternativeID: alternativeId,
          budgetNotes: x["c-Estimation Notes"],
          budgetStatusID: x.budgetStatusID,
          altRGValue: x["c-Total"],
        }
      })
    if (rowsToUpdate.length > 0) {
      let rowsToUpdateTasks = rowsToUpdate.map(x => async () => {
        return await api.updateAlternativeResourceFromBudget(
          currentUser.userID,
          x
        )
      })
      await Promise.all(rowsToUpdateTasks.map(t => t()))
    }

    if (alternative.useFlowThrough) {
      await api.updateAlternativeFlowThrough(alternativeId)
    }

    toast.success(`Budgets saved successfuly`)
    loadTableData(selectedResourceGroupId, selectedBCCostPoolId, resourceGroups)

    setTriggerSave(false)
  }

  const changeAltResourceGroupProp = (id, prop, value, updateTotal) => {
    let copy = [...altResourceGroups]
    let alt = copy.find(x => x.id == id)
    alt[prop] = value
    if (updateTotal === true) {
      alt["c-Total"] = getAltRGTotal(alt)
      if (alt.yearsToUpdate == undefined) {
        alt.yearsToUpdate = [prop.replace("c-", "")]
      } else {
        let found = alt.yearsToUpdate.find(x => x == prop.replace("c-", ""))
        if (found == undefined) alt.yearsToUpdate.push(prop.replace("c-", ""))
      }
    }
    alt.isDirty = true
    showToastChanges()
    setAltResourceGroups(copy)
  }

  const applyTShirtHoursAllocation = updatedCostPools => {
    let copy = [...altResourceGroups]
    copy.forEach(c => {
      let updatedItem = updatedCostPools.find(x => x.rgeid == c.rgeid)
      if (updatedItem != undefined) {
        alternativeYears.forEach(y => {
          c["c-" + y] = parseInt(updatedItem["updated-" + y])
        })
        c["c-Total"] = getAltRGTotal(c)
        c.yearsToUpdate = [...alternativeYears]
      }
    })

    showToastChanges()
    setAltResourceGroups(copy)
    handleSave(copy)
  }

  const alternativeYears = getAlternativeYears(alternative)

  const hasResourceWithTShirt =
    altResourceGroups.find(x => x.hasTShirts) != undefined

  return (
    <React.Fragment>
      <div>
        <Row>
          <Col md="3">
            <div>
              <div
                className="form-check"
                style={{ display: "inline-block" }}
                onClick={e => {
                  setIsEditMode(!isEditMode)
                  loadTableData(
                    selectedResourceGroupId,
                    selectedBCCostPoolId,
                    resourceGroups
                  )
                }}
              >
                <input
                  className="form-check-input always-enabled"
                  type="radio"
                  name="anon111"
                  id="anon111"
                  value="0"
                  checked={isEditMode}
                />
                <label
                  className="form-check-label always-enabled"
                  style={{ marginLeft: "10px" }}
                >
                  Edit Data
                </label>
              </div>

              <div
                className="form-check"
                style={{ display: "inline-block" }}
                onClick={e => {
                  setIsEditMode(!isEditMode)
                  loadViewTableData(
                    selectedResourceGroupId,
                    selectedBcLineItemId
                  )
                }}
              >
                <input
                  className="form-check-input always-enabled"
                  type="radio"
                  name="anon222"
                  id="anon222"
                  value="1"
                  style={{ marginLeft: "10px" }}
                  checked={!isEditMode}
                />
                <label
                  className="form-check-label always-enabled"
                  style={{ marginLeft: "10px" }}
                >
                  View Results
                </label>
              </div>
            </div>
          </Col>
          <Col md="3">
            <Label className="form-label">
              Planned Project Start:{" "}
              {moment(alternative.desiredStart).format("MM/DD/YYYY")}
            </Label>
          </Col>
          <Col md="3">
            <Label className="form-label">
              Planned Project End:{" "}
              {moment(alternative.plannedFinish).format("MM/DD/YYYY")}
            </Label>
          </Col>
          <Col md="3">
            {currentUser.roleID > 8 && (
              <div>
                <Input
                  id="flowCb"
                  type="checkbox"
                  className="form-check-input"
                  checked={alternative.useFlowThrough}
                  onClick={() => {
                    changeAlternativeProp(
                      "useFlowThrough",
                      !alternative.useFlowThrough
                    )
                  }}
                />

                <Label
                  for="flowCb"
                  className="form-check-label"
                  style={{ marginLeft: "10px" }}
                >
                  Use Flow Through
                </Label>
              </div>
            )}
          </Col>
        </Row>
        <div style={{ paddingTop: "10px" }}></div>
        <Row>
          <Col md="3">
            <div className="mb-3 always-enabled">
              <Label className="form-label">Show Resource(s)</Label>
              <select
                className="form-control form-select select2 mb-xxl-0 always-enabled"
                value={selectedResourceGroupId}
                onChange={e => {
                  setSelectedResourceGroupId(e.target.value)
                  if (isEditMode) {
                    loadTableData(
                      e.target.value,
                      selectedBCCostPoolId,
                      resourceGroups
                    )
                  } else {
                    loadViewTableData(e.target.value, selectedBcLineItemId)
                  }
                }}
              >
                <option value={-1}>All My</option>
                <option value={-99}>All</option>
                {resourceGroups.map((a, idx) => {
                  return (
                    <option key={idx} value={`${a.resourceGroupID}`}>
                      {a.resourceGroupName}
                    </option>
                  )
                })}
              </select>
            </div>
          </Col>
          <Col md="3">
            {isEditMode && (
              <div className="mb-3 always-enabled">
                <Label className="form-label">Show Cost Pool(s)</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0 always-enabled"
                  value={selectedBCCostPoolId}
                  onChange={e => {
                    setSelectedBCCostPoolId(e.target.value)
                    loadTableData(
                      selectedResourceGroupId,
                      e.target.value,
                      resourceGroups
                    )
                  }}
                >
                  <option value="-99">All</option>
                  {bcLineItems.map((a, idx) => {
                    return (
                      <option key={idx} value={`${a.listItemID}`}>
                        {a.listItemName}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}
            {!isEditMode && (
              <div className="mb-3 always-enabled">
                <Label className="form-label">Show Cost Pool(s)</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0 always-enabled"
                  value={selectedBCCostPoolId}
                  onChange={e => {
                    setSelectedBCCostPoolId(e.target.value)
                    loadViewTableData(selectedResourceGroupId, e.target.value)
                  }}
                >
                  <option value="-99">All</option>
                  <option value="0">General</option>
                  {bcLineItems.map((a, idx) => {
                    return (
                      <option key={idx} value={`${a.listItemID}`}>
                        {a.listItemName}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}
          </Col>
          <Col md="6" style={{ display: "flex", alignItems: "center" }}>
            {(currentUser.roleID > 1 ||
              alternative.brmUserID == currentUser.userID ||
              alternative.pmUserID == currentUser.userID) && (
              <button
                className="btn btn-primary"
                onClick={() => {
                  setEmailNotes("")
                  toggleEmailModal()
                }}
              >
                <i className="fas fa-envelope"></i> Request Estimate
              </button>
            )}
            <button
              className="btn btn-primary save-user"
              onClick={() => {
                toggleManagerModal()
              }}
            >
              View Resource Managers
            </button>
            {(currentUser.roleID > 1 ||
              alternative.pmUserID == currentUser.userID) && (
              <button
                className="btn btn-primary save-user"
                onClick={() => {
                  toggleClearEstimatesModal()
                }}
              >
                <i className="fas fa-eraser"></i> Clear Estimates
              </button>
            )}
          </Col>
        </Row>
        <Row>
          <Col style={{ overflowX: "auto" }}>
            {isLoading && (
              <Loader
                type="line-scale-pulse-out"
                color={colors.primary}
                style={{ textAlign: "center" }}
              />
            )}
            {isEditMode && (
              <table
                className="table table-bordered lower-padding-table"
                style={{ backgroundColor: "white" }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "200" }}>Resource</th>
                    <th style={{ width: "300" }}>Cost Pool</th>
                    {hasResourceWithTShirt && (
                      <th style={{ width: "200" }}></th>
                    )}
                    <th style={{ width: "100" }}>Unit</th>
                    <th style={{ width: "100" }}>Complete</th>
                    {alternativeYears.map((y, idx) => (
                      <th
                        key={idx}
                        style={{ width: `${25 / alternativeYears.length}%` }}
                      >
                        FY {y}
                      </th>
                    ))}
                    <th style={{ width: "5%" }}>Total</th>
                    <th style={{ width: "10%" }}>Last Update</th>
                    <th style={{ width: "15%" }}>Estimation Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {altResourceGroups.map((r, idx) => (
                    <tr key={idx}>
                      <td>
                        <div className="one-line-elipsis">
                          <Tooltip title={<h6>{r["c-Resource"]}</h6>}>
                            <span>
                              <b>{r["c-Resource"]}</b>
                            </span>
                          </Tooltip>
                        </div>
                      </td>
                      <td>
                        <div className="one-line-elipsis">
                          <Tooltip title={<h6>{r["c-Cost Pool"]}</h6>}>
                            <span>
                              <b>{r["c-Cost Pool"]}</b>
                            </span>
                          </Tooltip>
                        </div>
                      </td>
                      {hasResourceWithTShirt && (
                        <td>
                          {r.hasTShirts && (
                            <div
                              style={{
                                margin: "0",
                                textAlign: "center",
                                display: "inline-block",
                                padding: "4px 10px",
                                borderRadius: "15px",
                                borderColor: "lightgray",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                color: "blue",
                                cursor: "pointer",
                              }}
                              onClick={e => {
                                e.preventDefault()
                                let rg = resourceGroups.find(
                                  x => x.resourceGroupID == r.resourceGroupID
                                )
                                if (rg != undefined) {
                                  setResourceGroupBundleIdForTShirtModal({
                                    resourceGroupId: r.resourceGroupID,
                                    tShirtBundleId: rg.tShirtBundleID,
                                  })
                                  toggleTShirtModal()
                                }
                              }}
                            >
                              <i className="fas fa-tshirt"></i>&nbsp;T-Shirt
                            </div>
                          )}
                        </td>
                      )}
                      <td>
                        <div className="one-line-elipsis">
                          <Tooltip title={<h6>{r["c-Unit"]}</h6>}>
                            <span>{r["c-Unit"]}</span>
                          </Tooltip>
                        </div>
                      </td>
                      <td className="text-center">
                        <Input
                          type="checkbox"
                          disabled={r.userEdit == "0" ? true : false}
                          checked={r.budgetStatusID}
                          onClick={() =>
                            changeAltResourceGroupProp(
                              r.id,
                              "budgetStatusID",
                              !r.budgetStatusID
                            )
                          }
                        />
                      </td>
                      {alternativeYears.map((y, idx2) => (
                        <td key={idx2} style={{ padding: "0px" }}>
                          <CurrencyInput
                            className="form-control"
                            disabled={r.userEdit == "2" ? false : true}
                            value={r[`c-${y}`]}
                            style={{
                              textAlign: "right",
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            }}
                            onValueChange={(value, name, values) => {
                              changeAltResourceGroupProp(
                                r.id,
                                `c-${y}`,
                                values.float,
                                true
                              )
                            }}
                            placeholder=""
                            prefix={""}
                          />
                        </td>
                      ))}

                      <td className="text-right">{r["c-Total"]}</td>
                      <td>
                        <a
                          href="#"
                          onClick={e => {
                            e && e.preventDefault()
                            setResourceEstimateLogModel({
                              alternativeId: alternativeId,
                              costPoolId: r.costPoolID,
                              resourceGroupId: r.resourceGroupID,
                            })
                            toggleResourceEstimateLogModal()
                          }}
                        >
                          {r.lastUpdate}
                        </a>
                      </td>
                      <td>
                        <TextareaAutosize
                          className="form-control"
                          disabled={r.userEdit == "0" ? true : false}
                          minRows={1}
                          maxRows={3}
                          onChange={e =>
                            changeAltResourceGroupProp(
                              r.id,
                              "c-Estimation Notes",
                              e.target.value
                            )
                          }
                          value={r["c-Estimation Notes"]}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {!isEditMode && (
              <table
                className="table table-bordered lower-padding-table"
                style={{ backgroundColor: "white" }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "13%" }}>Resource</th>
                    <th style={{ width: "13%" }}>Cost Pool</th>
                    <th style={{ width: "6%" }}>Complete</th>
                    {alternativeYears.map((y, idx) => (
                      <th
                        key={idx}
                        style={{ width: `${50 / alternativeYears.length}%` }}
                      >
                        FY {y}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {altResourceGroupsView.map((a, idx) => (
                    <tr
                      key={idx}
                      style={{
                        backgroundColor: a.backgroundColor
                          ? a.backgroundColor
                          : "initial",
                        fontWeight: a.fontWeight ? a.fontWeight : "unset",
                      }}
                    >
                      <td>{a["c-Resource"]}</td>
                      <td>{a["c-Cost Pool"]}</td>
                      <td className="text-center">
                        {a.budgetStatusID !== undefined && (
                          <Input
                            type="checkbox"
                            checked={a.budgetStatusID}
                            readOnly={true}
                          />
                        )}
                      </td>
                      {alternativeYears.map((y, idx) => (
                        <td
                          key={idx}
                          className="text-right"
                          style={{
                            backgroundColor:
                              !a.formatAsCurrency && a[`c-${y}`] == "0"
                                ? "#ededed"
                                : "initial",
                          }}
                        >
                          {a.formatAsCurrency
                            ? currencyFormatter.format(a[`c-${y}`])
                            : a[`c-${y}`] == "0"
                            ? "-"
                            : a[`c-${y}`]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Col>
        </Row>
        <ResourceEstimateRequestNotesModal
          isOpen={isEmailModalVisible}
          toggleModal={toggleEmailModal}
          scenarioId={alternative.scenarioID}
          alternative={alternative}
        />
        {/* <Modal
          backdrop="static"
          isOpen={isEmailModalVisible}
          size="md"
          toggle={() => {
            toggleEmailModal()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Send Resource Estimate Request
            </h5>
            <button
              type="button"
              onClick={() => {
                toggleEmailModal()
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <Label className="form-label">
                Resource Estimate Request Notes
              </Label>
              <TextareaAutosize
                className="form-control"
                minRows={3}
                onChange={e => setEmailNotes(e.target.value)}
                placeholder="You may want to request that the resource estimate be provided by a specific date, as needed. You may also want to identify who a resource manager should contact if there are any questions"
                value={emailNotes}
              />
            </div>
          </div>
          <div className="modal-footer">
            <div>
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={toggleEmailModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary save-user"
                onClick={sendReminder}
              >
                Send Request(s)
              </button>
            </div>
          </div>
        </Modal> */}
        <ClearEstimatesModal
          isOpen={showClearEstimatesModal}
          toggleModal={toggleClearEstimatesModal}
          alternativeId={alternativeId}
          alternativeYears={alternativeYears}
          reload={loadData}
        />
        <ResourceManagerModal
          isOpen={showManagerModal}
          toggleModal={toggleManagerModal}
          scenarioId={alternative.scenarioID}
        />
        <ResourceEstimateLogModal
          isOpen={showResourceEstimateLogModal}
          toggleModal={toggleResourceEstimateLogModal}
          resourceEstimateLogModel={resourceEstimateLogModel}
        />
        <TShirtHoursAllocationModal
          isOpen={showTShirtModal}
          toggleModal={toggleTShirtModal}
          // resourceGroupCostPools={resourceGroupCostPoolsForTShirtModal}
          resourceGroupBundleId={resourceGroupBundleIdForTShirtModal}
          alternativeYears={alternativeYears}
          applyTShirtHoursAllocation={applyTShirtHoursAllocation}
          alternativeId={alternativeId}
          alternativeName={alternative.alternativeName}
          scenarioId={alternative.scenarioID}
        />
      </div>
    </React.Fragment>
  )
}

export default ResourceBudgetingSection
