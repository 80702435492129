import React from "react"
import { ReactTabulator } from "react-tabulator"
import { customFormatter } from "utils/tabulator"

const FormFieldTable = ({ formFields, editFormField, updateFormFields }) => {
  const deleteFormField = formField => {
    let copy = [...formFields]
    let existingFormField = copy.find(
      x => x.formFieldId == formField.formFieldId
    )
    copy.splice(copy.indexOf(existingFormField), 1)
    updateFormFields(copy)
  }

  const columns = [
    {
      rowHandle: true,
      formatter: "handle",
      headerSort: false,
      frozen: true,
      width: 50,
    },
    {
      title: "Control Label",
      field: "formFieldLabel",
      headerSort: false,
      formatter: customFormatter(({ rowData, cellValue }) => (
        <a
          href="#"
          onClick={e => {
            e.preventDefault()
            editFormField(rowData)
          }}
        >
          {cellValue}
        </a>
      )),
    },
    {
      title: "Type",
      field: "formFieldType.type",
      headerSort: false,
    },
    {
      title: "Order",
      field: "orderId",
      headerSort: false,
    },
    {
      field: "",
      headerSort: false,
      width: 50,
      formatter: customFormatter(({ rowData, cellValue }) => (
        <i
          className="fas fa-trash-alt"
          style={{ color: "red" }}
          onClick={() => deleteFormField(rowData)}
        ></i>
      )),
    },
  ]

  return (
    <>
      {formFields.length > 0 && (
        <ReactTabulator
          //   onRef={r => (tabulatorRef.current = r.current)}
          columns={columns}
          data={formFields}
          options={{
            movableRows: true,
            layout: "fitColumns",
            cssClass: "table-borderless",
          }}
          events={{
            rowMoved: function (row) {
              let list = row.getTable().getData()
              list = list.map((l, idx) => {
                l.orderId = idx + 1
                return l
              })
              updateFormFields(list)
            },
          }}
        />
      )}
    </>
  )
}

export default FormFieldTable
