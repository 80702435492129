import NameAvatar from "components/custom/nameAvatar"
import useModal from "hooks/useModalHook"
import React, { useCallback, useEffect, useRef, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import { createRoot } from "react-dom/client"
import Loader from "react-loaders"
import { ReactTabulator, reactFormatter } from "react-tabulator"
import { toast } from "react-toastify"
import {
  ButtonGroup,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Row,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { enrichWithPhotoBase64, loadUsersAvatars } from "utils/avatar"
import colors from "utils/colors"
import isNullOrEmpty from "utils/isNullOrEmpty"
import {
  getSelectHtmlFilterValues,
  getSelectedVisibleRows,
} from "utils/tabulatorFilter"

const ParticipantsGrid = ({ scenarioId, triggerSave, isSecondary }) => {
  const currentUser = userService.getLoggedInUser()

  const [participants, setParticipants] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [criteriaPrioritizationStates, setCriteriaPrioritizationStates] =
    useState([])
  const [scenario, setScenario] = useState(null)
  const [scenarioRoles, setScenarioRoles] = useState([])
  const [criteriaAlternativeModes, setCriteriaAlternativeModes] = useState([])

  useEffect(() => {
    if (scenarioId > 0) {
      loadData()
    }
  }, [scenarioId])

  useEffect(() => {
    if (triggerSave) {
      save()
    }
  }, [triggerSave])

  const loadData = async () => {
    setIsLoading(true)
    let s = await api.getScenario(scenarioId)
    setScenario(s)

    let roles = await api.getList(currentUser.userID, "ScenarioRole")
    setScenarioRoles(roles)
    let critAltModes = await api.getList(currentUser.userID, "InteractionTypes")
    setCriteriaAlternativeModes(critAltModes)

    let users = await api.getParticipantsByScenarioId(scenarioId, true)

    let crits = await api.getList(currentUser.userID, "ScoreState")
    setCriteriaPrioritizationStates(crits)

    users = await enrichWithPhotoBase64(users, "userID")
    setParticipants(
      users.map(u => {
        u.user.fullName = u.user.firstName + " " + u.user.lastName
       
        return u
      })
    )
    setIsLoading(false)
  }

  const changeParticipantPropCallback = useCallback(
    (participantId, prop, val) => {
      showChangesToast()
      setParticipants(prev => {
        let copy = JSON.parse(JSON.stringify(prev))
        let item = copy.find(x => x.participantID == participantId)
        item[prop] = val
        item.isDirty = true
        return copy
      })
    },
    []
  )

  const save = async () => {
    if (scenario.isDirty) {
      await api.updateScenario(currentUser.userID, scenario)
    }

    let participantsToUpdate = participants.filter(x => x.isDirty)
    if (participantsToUpdate.length > 0) {
      let tasks = participantsToUpdate.map(x => async () => {
        return await api.updateParticipant(currentUser.userID, x)
      })
      await Promise.all(tasks.map(t => t()))
    }

    loadData()
    toast.success(
      `${isSecondary ? "Secondary" : ""} Decision updated successfully`
    )
  }

  const showChangesToast = () => {
    toast.success(
      <div>
        <p className="mb-0">
          You have unsaved changes, don't forget to save them.
        </p>
        <button
          className="btn btn-primary"
          style={{ width: "100%" }}
          onClick={() => {
            document.getElementById("saveManageScoring").click()
          }}
        >
          <i className="fas fa-save"></i> Save
        </button>
      </div>,
      {
        toastId: "unsavedChanges",
        autoClose: false,
      }
    )
  }

  return (
    <React.Fragment>
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}

      {!isLoading && (
        <div style={{ padding: "0 10px" }}>
          {scenario != null && (
            <>
              <Row>
                <Col>
                  <h3>
                    <b>
                      {/*                     {isSecondary
                      ? scenario.secondaryScoreName
                      : scenario.scoreName} */}
                      {scenario.scoreName}
                    </b>
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Label>{scenario.objectiveName} Prioritization State</Label>
                  <br />
                  <ButtonGroup>
                    {criteriaPrioritizationStates.map((c, idx) => (
                      <button
                        key={idx}
                        style={{ marginRight: "10px" }}
                        className={`btn  ${
                          scenario.pairwiseStatusID == c.listItemID && idx == 0
                            ? " btn-primary btn-green"
                            : scenario.pairwiseStatusID == c.listItemID &&
                              idx == 1
                            ? " btn-primary btn-red"
                            : " btn-outline-secondary"
                        }`}
                        onClick={() => {
                          showChangesToast()
                          setScenario({
                            ...scenario,
                            isDirty: true,
                            pairwiseStatusID: c.listItemID,
                          })
                        }}
                      >
                        {c.listItemName}
                      </button>
                    ))}
                  </ButtonGroup>
                </Col>
                <Col md="6">
                  <Label>{scenario.alternativeName} Scoring State</Label>
                  <br />

                  <ButtonGroup>
                    {criteriaPrioritizationStates.map((c, idx) => (
                      <button
                        key={idx}
                        style={{ marginRight: "10px" }}
                        className={`btn  ${
                          scenario.alternativeStatusID == c.listItemID &&
                          idx == 0
                            ? " btn-primary btn-green"
                            : scenario.alternativeStatusID == c.listItemID &&
                              idx == 1
                            ? " btn-primary btn-red"
                            : " btn-outline-secondary"
                        }`}
                        onClick={() => {
                          showChangesToast()
                          setScenario({
                            ...scenario,
                            isDirty: true,
                            alternativeStatusID: c.listItemID,
                          })
                        }}
                      >
                        {c.listItemName}
                      </button>
                    ))}
                  </ButtonGroup>
                </Col>
              </Row>
            </>
          )}
          <table
            className="table table-bordered low-padding-table"
            style={{ backgroundColor: "white", marginTop: "20px" }}
          >
            <thead>
              <tr>
                <th style={{ width: "25%" }}>Name</th>
                <th style={{ width: "20%" }}>Decision Role</th>
                <th style={{ width: "20%" }}>Criteria Mode</th>
                <th style={{ width: "20%" }}>Alternative Mode</th>
                <th style={{ width: "15%" }}>Weight</th>
              </tr>
            </thead>
            <tbody>
              {participants.map((p, idx) => (
                <tr key={idx}>
                  <td>
                    <NameAvatar
                      fullName={p.user.fullName}
                      showName={true}
                      aiUser={p.user.aiUser}
                      base64Image={p.photoBase64}
                    />
                  </td>
                  <td>
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      value={p.modelRoleID}
                      onChange={e => {
                        changeParticipantPropCallback(
                          p.participantID,
                          "modelRoleID",
                          e.target.value
                        )
                      }}
                    >
                      <option value={0}>Select role</option>
                      {scenarioRoles.map((r, idx) => {
                        return (
                          <option key={idx} value={`${r.listItemID}`}>
                            {r.listItemName}
                          </option>
                        )
                      })}
                    </select>
                  </td>
                  <td>
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      value={p.pwcTypeID}
                      onChange={e => {
                        changeParticipantPropCallback(
                          p.participantID,
                          "pwcTypeID",
                          e.target.value
                        )
                      }}
                    >
                      {criteriaAlternativeModes.map((r, idx) => {
                        return (
                          <option key={idx} value={`${r.listItemID}`}>
                            {r.listItemName}
                          </option>
                        )
                      })}
                    </select>
                  </td>
                  <td>
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      value={p.altTypeID}
                      onChange={e => {
                        changeParticipantPropCallback(
                          p.participantID,
                          "altTypeID",
                          e.target.value
                        )
                      }}
                    >
                      {criteriaAlternativeModes.map((r, idx) => {
                        return (
                          <option key={idx} value={`${r.listItemID}`}>
                            {r.listItemName}
                          </option>
                        )
                      })}
                    </select>
                  </td>
                  <td>
                    <CurrencyInput
                      className="form-control"
                      value={p.votingWeight}
                      onValueChange={(value, name, values) => {
                        changeParticipantPropCallback(
                          p.participantID,
                          "votingWeight",
                          value
                        )
                      }}
                      placeholder=""
                      prefix=""
                      decimalScale={2}
                      decimalsLimit={2}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </React.Fragment>
  )
}

export default ParticipantsGrid
