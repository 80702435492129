const scoringColumns = [
  {
    name: "Extremely",
    value: 9,
    color: "red",
    textColor: "white",
    ahpScaleID: 1,
  },
  {
    name: "",
    value: 8,
    color: "mediumpurple",
    textColor: "white",
    ahpScaleID: 2,
  },
  {
    name: "Strongly",
    value: 7,
    color: "purple",
    textColor: "white",
    ahpScaleID: 1,
  },
  {
    name: "",
    value: 6,
    color: "darkblue",
    textColor: "white",
    ahpScaleID: 2,
  },
  {
    name: "Moderately",
    value: 5,
    color: "blue",
    textColor: "white",
    ahpScaleID: 1,
  },
  {
    name: "",
    value: 4,
    color: "lightblue",
    textColor: "white",
    ahpScaleID: 2,
  },
  {
    name: "Slightly",
    value: 3,
    color: "green",
    textColor: "white",
    ahpScaleID: 1,
  },
  {
    name: "",
    value: 2,
    color: "lightgreen",
    textColor: "white",
    ahpScaleID: 2,
  },
  {
    name: "Equal",
    value: 0,
    color: "lightyellow",
    textColor: "black",
    ahpScaleID: 1,
  },

  {
    name: "",
    value: -2,
    color: "lightgreen",
    textColor: "white",
    ahpScaleID: 2,
  },

  {
    name: "Slightly",
    value: -3,
    color: "green",
    textColor: "white",
    ahpScaleID: 1,
  },
  {
    name: "",
    value: -4,
    color: "lightblue",
    textColor: "white",
    ahpScaleID: 2,
  },
  {
    name: "Moderately",
    value: -5,
    color: "blue",
    textColor: "white",
    ahpScaleID: 1,
  },

  {
    name: "",
    value: -6,
    color: "darkblue",
    textColor: "white",
    ahpScaleID: 2,
  },

  {
    name: "Strongly",
    value: -7,
    color: "purple",
    textColor: "white",
    ahpScaleID: 1,
  },

  {
    name: "",
    value: -8,
    color: "mediumpurple",
    textColor: "white",
    ahpScaleID: 2,
  },

  {
    name: "Extremely",
    value: -9,
    color: "red",
    textColor: "white",
    ahpScaleID: 1,
  },
]

export default scoringColumns
