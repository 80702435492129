import { TextareaAutosize } from "@material-ui/core"
import moment from "moment"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import { currencyFormatter } from "utils/formatters"
import DocumentsModal from "./Modals/DocumentsModal"
import EditBuildingModal from "./Modals/EditBuildingModal"
import EditDocumentModal from "./Modals/EditDocumentModal"
import EditMajorModal from "./Modals/EditMajorModal"
import EditPhaseModal from "./Modals/EditPhaseModal"
import HelpIconWithModal from "components/custom/helpIconWithModal"
import InstructionDoc from "assets/docs/NMHEDGuide_FY26.pdf"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"

const NmSection = ({
  activeTab,
  alternative,
  alternativeId,
  triggerSave,
  setTriggerSave,
  showToastChanges,
  scenario,
  loadAlternative,
  props,
}) => {
  const currentUser = userService.getLoggedInUser()
  const today = new Date()
  const [entities, setEntities] = useState([])
  const [entitiesByParent, setEntitiesByParent] = useState([])
  const [constructionTypes, setConstructionTypes] = useState([])
  const [nm, setNm] = useState(null)
  const [nmFteData, setNmFteData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showBuildingModal, setShowBuildingModal] = useState(false)
  const toggleBuildingModal = () => setShowBuildingModal(!showBuildingModal)
  const [buildingToEdit, setBuildingToEdit] = useState(null)
  const [phaseToEdit, setPhaseToEdit] = useState(null)
  const [majorToEdit, setMajorToEdit] = useState(null)
  const [showPhaseModal, setShowPhaseModal] = useState(false)
  const togglePhaseModal = () => setShowPhaseModal(!showPhaseModal)
  const [showMajorModal, setShowMajorModal] = useState(false)
  const toggleMajorModal = () => setShowMajorModal(!showMajorModal)
  const years = Array.from(Array(20).keys()).map(
    k => today.getFullYear() - 10 + k
  )
  const [inputRatings, setInputRatings] = useState([])
  const [nmStatuses, setNmStatuses] = useState([])
  const [documentToEdit, setDocumentToEdit] = useState(null)
  const [isDocModalVisible, setIsDocModalVisible] = useState(false)
  const toggleDocModal = () => setIsDocModalVisible(!isDocModalVisible)
  const [isDocumentsModalVisible, setIsDocumentsModalVisible] = useState(false)
  const toggleDocumentsModal = () =>
    setIsDocumentsModalVisible(!isDocumentsModalVisible)

  const [documents, setDocuments] = useState([])
  const [selectedDocType, setSelectedDocType] = useState("")

  useEffect(() => {
    if (activeTab == "NM_Need") {
      setNm({ nmhedid: -1, scenarioID: alternative.scenarioID })
      loadData()
    }
  }, [activeTab, alternativeId])

  useEffect(() => {
    if (triggerSave) {
      save()
    }
  }, [triggerSave])

  const loadData = async () => {
    setIsLoading(true)
    let e = await api.getEntities(currentUser.userID, 1)
    setEntities(e)
    let ss = await api.getNmStatuses(
      currentUser.userID,
      alternativeId
    )
    setNmStatuses(ss)
    let ir = await api.getScenarioInputRatings(alternative.scenarioID)
    setInputRatings(ir)
    let ct = await api.getList(currentUser.userID, "ConstructionType")
    setConstructionTypes(ct)
    let ndata = await api.getNmhedFteData(alternativeId)
    setNmFteData(ndata)
    await loadNm(alternativeId)
    await loadDocuments()
    setIsLoading(false)
  }

  const loadNm = async altId => {
    if (altId > -1) {
      let n = await api.getNmhed(altId)
      setNm(n == null ? { nmhedid: -1, scenarioID: scenario.scenarioID } : n)
      if (n != null) {
        loadEntitiesByParent(n.institutionEntityID)
      }
    } else {
      setNm({ nmhedid: -1, scenarioID: scenario.scenarioID })
    }
  }

  const loadDocuments = async () => {
    let doc = await api.getDocuments(
      alternativeId,
      alternative.scenarioID
    )
    setDocuments(doc)
  }

  const loadEntitiesByParent = async parentEntityId => {
    let ep = await api.getEntitiesByParent(currentUser.userID, parentEntityId)
    setEntitiesByParent(ep)
  }

  const changeNmProp = (prop, value) => {
    showToastChanges()
    setNm({ ...nm, [prop]: value })
  }

  const save = async () => {
    setTriggerSave(false)
    if (nm.nmhedid == -1) {
      if (nm.institutionEntityID > -1) {
        if (alternativeId > 0) {
          await api.createNmhed(currentUser.userID, alternative.scenarioID, {
            ...nm,
            userID: currentUser.userID,
          })
          loadNm(alternativeId)
        } else {
          swalWithConfirmAndCancelButtons
            .fire({
              title: `Do you want to submit a ${scenario.alternativeName.toLowerCase()} or do you want to create an idea that only you can see and edit?
          Later, you can promote the idea to a ${scenario.alternativeName.toLowerCase()}.`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Idea for now",
              cancelButtonText: `Create ${scenario.alternativeName}`,
              reverseButtons: true,
            })
            .then(async result => {
              let altId = await api.createNmhed(
                currentUser.userID,
                alternative.scenarioID,
                {
                  ...nm,
                  userID: currentUser.userID,
                  ideaOnly: result.isConfirmed,
                }
              )

              window.location.pathname = `/editProject/${scenario.scenarioID}/${altId}`
            })
        }
      }
    } else {
      await api.updateNmhed(currentUser.userID, nm)
      loadNm(alternativeId)
    }
  }

  const deleteBuilding = building => {
    let copy = [...nm.nmhedEntities]
    let item = copy.find(x => x.nmhedEntityID == building.nmhedEntityID)
    copy.splice(copy.indexOf(item), 1)
    changeNmProp("nmhedEntities", copy)
  }

  const deletePhase = phase => {
    let copy = [...nm.nmhedPhases]
    let item = copy.find(x => x.nmheD_PhaseID == phase.nmheD_PhaseID)
    copy.splice(copy.indexOf(item), 1)
    changeNmProp("nmhedPhases", copy)
  }

  const deleteMajor = major => {
    let copy = [...nm.nmhedMajors]
    let item = copy.find(x => x.nmhedMajorID == major.nmhedMajorID)
    copy.splice(copy.indexOf(item), 1)
    changeNmProp("nmhedMajors", copy)
  }

  const editBuilding = building => {
    if (building == undefined || building == null) {
      let min =
        (nm.nmhedEntities != null && nm.nmhedEntities.length > 0
          ? nm.nmhedEntities.map(x => x.nmhedEntityID).sort()[0] < 0
            ? nm.nmhedEntities.map(x => x.nmhedEntityID).sort()[0]
            : -1
          : -1) - 1
      setBuildingToEdit({
        nmhedEntityID: min,
        nmhedid: nm.nmhedid,
        nmhedEntityID: min,
        entityID: -1,
        entityTypeID: 2,
        enterpriseID: currentUser.enterpriseID,
        entityName: "",
        entityAge: 0,
        entityGSF: 0,
      })
    } else {
      setBuildingToEdit(building)
    }
    toggleBuildingModal()
  }

  const changeBuildingProp = (prop, value) => {
    if (prop.indexOf(".") > -1) {
      let props = prop.split(".")
      let copy = { ...buildingToEdit }
      props.forEach((p, idx) => {
        copy[p] = value[idx]
      })
      setBuildingToEdit(copy)
    } else {
      setBuildingToEdit({
        ...buildingToEdit,
        [prop]: value,
      })
    }
  }

  const editPhase = phase => {
    if (phase == undefined || phase == null) {
      let min =
        (nm.nmhedPhases != null && nm.nmhedPhases.length > 0
          ? nm.nmhedPhases.map(x => x.nmheD_PhaseID).sort()[0] < 0
            ? nm.nmhedPhases.map(x => x.nmheD_PhaseID).sort()[0]
            : -1
          : -1) - 1

      setPhaseToEdit({
        nmheD_PhaseID: min,
        startDate: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
        endDate: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
        phaseAmount: 0,
        phaseDescription: "",
        phaseNumber: 0,
        nmhedid: nm.nmhedid,
      })
    } else {
      setPhaseToEdit(phase)
    }
    togglePhaseModal()
  }

  const changePhaseProp = (prop, value) => {
    setPhaseToEdit({
      ...phaseToEdit,
      [prop]: value,
    })
  }

  const editMajor = major => {
    if (major == undefined || major == null) {
      let min =
        (nm.nmhedMajors != null && nm.nmhedMajors.length > 0
          ? nm.nmhedMajors.map(x => x.nmhedMajorID).sort()[0] < 0
            ? nm.nmhedMajors.map(x => x.nmhedMajorID).sort()[0]
            : -1
          : -1) - 1
      setMajorToEdit({
        nmhedMajorID: min,
        nmhedid: nm.nmhedid,
        yearGrowth: 0,
        majorName: "",
        headCount: 0,
        fte: 0,
        avgGrowth: 0,
      })
    } else {
      setMajorToEdit(major)
    }
    toggleMajorModal()
  }

  const changeMajorProp = (prop, value) => {
    setMajorToEdit({
      ...majorToEdit,
      [prop]: value,
    })
  }

  const renderScore = (
    scoreProp,
    infoProp,
    labelScore,
    labelInfo,
    title,
    description,
    addDocumentFunc,
    addDocumentLabel,
    otherPropsJsxFunc
  ) => {
    return (
      <Row>
        {title && <Label>{title}</Label>}
        {description && <span className="smallHelp">{description}</span>}

        {scoreProp && (
          <Col md="6">
            <div className="mb-3">
              <Label className="form-label">{labelScore}</Label>
              <HelpIconWithModal itemId={11} />
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={nm[scoreProp]}
                onChange={e => {
                  changeNmProp(scoreProp, e.target.value)
                }}
              >
                {inputRatings
                  .sort((a, b) => (a.inputValue > b.inputValue ? 1 : -1))
                  .map((a, idx) => {
                    return (
                      <option key={idx} value={`${a.inputValue}`}>
                        {a.ratingName}
                      </option>
                    )
                  })}
              </select>
            </div>
          </Col>
        )}
        {addDocumentFunc && (
          <Col md="6">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <Label>{addDocumentLabel}</Label>
              <div
                style={{
                  marginLeft: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {documents.find(x => x.docType == addDocumentLabel) !=
                  undefined && (
                  <a
                    href="#"
                    onClick={e => {
                      e && e.preventDefault()
                      setSelectedDocType(addDocumentLabel)
                      toggleDocumentsModal()
                    }}
                  >
                    {addDocumentLabel} Documents
                  </a>
                )}
                <button className="btn btn-primary" onClick={addDocumentFunc}>
                  <i className="fas fa-plus">&nbsp;</i>Add Document
                </button>
              </div>
            </div>
          </Col>
        )}
        {otherPropsJsxFunc && otherPropsJsxFunc()}
        <div className="mb-3">
          <Label>{labelInfo}</Label>
          <TextareaAutosize
            minRows={2}
            className="form-control"
            onChange={e => changeNmProp(infoProp, e.target.value)}
            value={nm[infoProp]}
          />
        </div>
      </Row>
    )
  }

  const addDocument = docType => {
    setDocumentToEdit({
      documentID: -1,
      alternativeID: alternativeId,
      scenarioID: alternative.scenarioID,
      userID: currentUser.userID,
      docType: docType,
    })
    toggleDocModal()
  }

  return (
    <React.Fragment>
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      {nm != null && (
        <>
          <Row>
            <Col md="6">
              <div>
                <i className="fas fa-file-pdf"></i>&nbsp;
                <a href={InstructionDoc} target="_blank" rel="noreferrer">
                  NMHED Instruction Guide
                </a>
                <br></br>
                <br></br>
              </div>
              <div className="mb-3">
                <Label className="form-label">Institution</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={nm.institutionEntityID}
                  onChange={e => {
                    changeNmProp("institutionEntityID", e.target.value)
                    loadEntitiesByParent(e.target.value)
                  }}
                >
                  <option value={-1}>Select...</option>
                  {entities.map((a, idx) => {
                    return (
                      <option key={idx} value={`${a.entityID}`}>
                        {a.entityName}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className="mb-3">
                <Label className="form-label">Project Name</Label>
                <Input
                  type="text"
                  onChange={e => changeNmProp("projectTitle", e.target.value)}
                  value={nm.projectTitle}
                />
              </div>
            </Col>
          </Row>

          {nm.nmhedid > -1 && (
            <>
              <Row>
                <div className="divider" />
                <Col md="6">
                  <div className="mb-3">
                    <Label>Buildings</Label>
                  </div>
                  <div className="mb-3">
                    <button
                      className="btn btn-primary mb-3"
                      onClick={() => editBuilding()}
                    >
                      <i className="fas fa-plus"></i>&nbsp;Add Building
                    </button>
                    <table
                      className="table table-bordered low-padding-table"
                      style={{ backgroundColor: "white" }}
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "40%" }}>Building</th>
                          <th style={{ width: "25%" }}>Building Age</th>
                          <th style={{ width: "25%" }}>Building GSF</th>
                          <th style={{ width: "10%" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {nm.nmhedEntities != null &&
                          nm.nmhedEntities.map((entity, idx) => (
                            <tr key={idx}>
                              <td>{entity.entityName}</td>
                              <td>{entity.entityAge}</td>
                              <td className="text-right">
                                {parseFloat(
                                  entity.entityGSF || 0
                                ).toLocaleString()}
                              </td>
                              <td>
                                <i
                                  className="fas fa-trash"
                                  style={{ color: "red" }}
                                  onClick={() => deleteBuilding(entity)}
                                ></i>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
              <Row>
                <div className="divider" />
                <Col md="6">
                  <div
                    className="mb-3"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Input
                      type="checkbox"
                      id="5year"
                      checked={nm.exclude}
                      onClick={() => {
                        changeNmProp("exclude", !nm.exclude)
                      }}
                    />
                    <Label style={{ marginLeft: "10px" }} for="5year">
                      For 5 year planning only
                    </Label>
                    <HelpIconWithModal itemId={36} />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">FY Priority</Label>
                    <Input
                      type="number"
                      onChange={e =>
                        changeNmProp("fyPriorityNumber", e.target.value)
                      }
                      value={nm.fyPriorityNumber}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Total Project Cost</Label>
                    <CurrencyInput
                      className="form-control"
                      value={nm.totalProjectCost}
                      readOnly="true"
                      onValueChange={(value, name, values) => {
                        changeNmProp("totalProjectCost", value)
                      }}
                      placeholder=""
                      prefix={"$"}
                      decimalScale={0}
                      decimalsLimit={0}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Commited Match Funding</Label>
                    <CurrencyInput
                      className="form-control"
                      value={nm.committedMatchFunding}
                      onValueChange={(value, name, values) => {
                        changeNmProp("committedMatchFunding", value)
                      }}
                      placeholder=""
                      prefix={"$"}
                      decimalScale={0}
                      decimalsLimit={0}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Construction Type</Label>
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      value={nm.constructionType}
                      onChange={e => {
                        changeNmProp("constructionType", e.target.value)
                      }}
                    >
                      {constructionTypes.map((a, idx) => {
                        return (
                          <option key={idx} value={`${a.listItemName}`}>
                            {a.listItemName}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </Col>
                <Col md="6">
                  <div style={{ height: "32px" }} className="mb-3">
                    &nbsp;
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">FY Planned</Label>
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      value={nm.fyPlanned}
                      onChange={e => {
                        changeNmProp("fyPlanned", e.target.value)
                      }}
                    >
                      {years.map((a, idx) => {
                        return (
                          <option key={idx} value={`${a}`}>
                            {a}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Master Plan Priority #</Label>
                    <Input
                      type="number"
                      onChange={e =>
                        changeNmProp("masterPlanPriorityNumber", e.target.value)
                      }
                      value={nm.masterPlanPriorityNumber}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">State Funding Request</Label>
                    <CurrencyInput
                      className="form-control"
                      value={nm.stateFundingRequest}
                      onValueChange={(value, name, values) => {
                        changeNmProp("stateFundingRequest", value)
                      }}
                      placeholder=""
                      prefix={"$"}
                      decimalScale={0}
                      decimalsLimit={0}
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Match Funding Source</Label>
                    <Input
                      type="text"
                      onChange={e =>
                        changeNmProp("matchFundingDescription", e.target.value)
                      }
                      value={nm.matchFundingDescription}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div
                    className="mb-3"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div style={{ width: "25%" }}>
                      <Label>Previous Request Summary</Label>
                      <br></br>
                      <span className="smallHelp">
                        If funding was requested in the past for this project,
                        provide a brief summary to include when it was
                        presented, amount of funding received and any changes
                        from original request(s) (Cost, Scope, etc.):
                      </span>
                    </div>
                    <div style={{ width: "75%" }}>
                      <TextareaAutosize
                        minRows={4}
                        className="form-control"
                        onChange={e =>
                          changeNmProp("pastFundingDescription", e.target.value)
                        }
                        value={nm.pastFundingDescription}
                      />
                    </div>
                  </div>
                  <div
                    className="mb-3"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div style={{ width: "25%" }}>
                      <Label>History</Label>
                      <br></br>
                      <span className="smallHelp">
                        Provide a brief history and general description of the
                        facility:
                      </span>
                    </div>
                    <div style={{ width: "75%" }}>
                      <TextareaAutosize
                        minRows={4}
                        className="form-control"
                        onChange={e =>
                          changeNmProp("facilityHistory", e.target.value)
                        }
                        value={nm.facilityHistory}
                      />
                    </div>
                  </div>
                  <div
                    className="mb-3"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div style={{ width: "25%" }}>
                      <Label>Current Condition</Label>
                      <br></br>
                      <span className="smallHelp">
                        What is the current condition of the facility and
                        supporting infrastructure?
                      </span>
                    </div>
                    <div style={{ width: "75%" }}>
                      <TextareaAutosize
                        minRows={4}
                        className="form-control"
                        onChange={e =>
                          changeNmProp("facilityCondition", e.target.value)
                        }
                        value={nm.facilityCondition}
                      />
                    </div>
                  </div>
                  <div
                    className="mb-3"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div style={{ width: "25%" }}>
                      <Label>Renovation Information</Label>
                      <br></br>
                      <span className="smallHelp">
                        What was the last major renovation completed? When was
                        the renovation completed? What funding sources were
                        used? A major Renovation is anything over $500K.
                      </span>
                    </div>
                    <div style={{ width: "75%" }}>
                      <TextareaAutosize
                        minRows={4}
                        className="form-control"
                        onChange={e =>
                          changeNmProp("lastRenovation", e.target.value)
                        }
                        value={nm.lastRenovation}
                      />
                    </div>
                  </div>
                  <div
                    className="mb-3"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div style={{ width: "25%" }}>
                      <Label>Scope of Work</Label>
                      <br></br>
                      <span className="smallHelp">
                        Provide a detailed scope of work to be completed if the
                        project is funded. Make sure to specify any deficiencies
                        being remedied:
                      </span>
                    </div>
                    <div style={{ width: "75%" }}>
                      <TextareaAutosize
                        minRows={4}
                        className="form-control"
                        onChange={e =>
                          changeNmProp("scopeOfWork", e.target.value)
                        }
                        value={nm.scopeOfWork}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <div className="divider" />
                <Col md="12">
                  <div className="mb-3">
                    <Label>Phases</Label>
                    <br></br>
                    <span className="smallHelp">
                      Complete the table if this project request contains
                      multiple projects or if the project can be phased:
                    </span>
                  </div>
                  <div className="mb-3">
                    <button
                      className="btn btn-primary mb-3"
                      onClick={() => editPhase()}
                    >
                      <i className="fas fa-plus"></i>&nbsp;Add Phase
                    </button>
                    <table
                      className="table table-bordered low-padding-table"
                      style={{ backgroundColor: "white" }}
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "10%" }}>Phase #</th>
                          <th style={{ width: "40%" }}>Description</th>
                          <th style={{ width: "10%" }}>Part of Request</th>
                          <th style={{ width: "10%" }}>Amount</th>
                          <th style={{ width: "10%" }}>Start Date</th>
                          <th style={{ width: "10%" }}>End Date</th>
                          <th style={{ width: "10%" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {nm.nmhedPhases != null &&
                          nm.nmhedPhases.map((p, idx) => (
                            <tr key={idx}>
                              <td>{p.phaseNumber}</td>
                              <td>{p.phaseDescription}</td>
                              <td>{p.partOfRequest ? "Yes" : "No"}</td>
                              <td className="text-right">
                                {currencyFormatter.format(p.phaseAmount)}
                              </td>
                              <td>
                                {moment(p.startDate).format("MM/DD/YYYY")}
                              </td>
                              <td>{moment(p.endDate).format("MM/DD/YYYY")}</td>
                              <td>
                                <i
                                  className="fas fa-trash"
                                  style={{ color: "red" }}
                                  onClick={() => deletePhase(p)}
                                ></i>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <div className="mb-3">
                    <Label>Students Impacted</Label>
                    <br></br>
                    <span className="smallHelp">
                      Provide the instructional program majors being served by
                      this project:
                    </span>
                  </div>
                  <div className="mb-3">
                    <button
                      className="btn btn-primary mb-3"
                      onClick={() => editMajor()}
                    >
                      <i className="fas fa-plus"></i>&nbsp;Add Impact
                    </button>
                    <table
                      className="table table-bordered low-padding-table"
                      style={{ backgroundColor: "white" }}
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "30%" }}>Major</th>
                          <th style={{ width: "15%" }}>Headcount</th>
                          <th style={{ width: "15%" }}>FTE</th>
                          <th style={{ width: "15%" }}>% Growth Last Year</th>
                          <th style={{ width: "15%" }}>% Growth Average</th>
                          <th style={{ width: "10%" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {nm.nmhedMajors != null &&
                          nm.nmhedMajors.map((p, idx) => (
                            <tr key={idx}>
                              <td>{p.majorName}</td>
                              <td className="text-right">
                                {parseFloat(p.headCount).toLocaleString()}
                              </td>
                              <td className="text-right">
                                {parseFloat(p.fte).toLocaleString()}
                              </td>
                              <td className="text-right">{p.yearGrowth}</td>
                              <td className="text-right">{p.avgGrowth}</td>
                              <td>
                                <i
                                  className="fas fa-trash"
                                  style={{ color: "red" }}
                                  onClick={() => deleteMajor(p)}
                                ></i>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
              <Row></Row>
              <Row>
                <Col md="6">
                  <div
                    className="mb-3"
                    // style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div>
                      <Label>Enrollment</Label>
                      <br></br>
                      <span className="smallHelp">
                        Data provided is READ ONLY. To update this information
                        go to Institution Data within the Manage System section
                        of the application. Update based on the latest NMHED
                        website/eDEAR Fall enrollment data:
                      </span>
                    </div>
                    <div>
                      <table
                        className="table table-bordered low-padding-table"
                        style={{ backgroundColor: "white" }}
                      >
                        <thead>
                          <tr>
                            <th style={{ width: "25%" }}>Year</th>
                            <th style={{ width: "25%" }}>
                              Enrollment Headcount
                            </th>
                            <th style={{ width: "25%" }}>FTE</th>
                            <th style={{ width: "25%" }}>OFTE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {nmFteData.map((x, idx) => (
                            <tr key={idx}>
                              <td>{x.dataYearID}</td>
                              <td className="text-right">
                                {parseFloat(
                                  x.enrollmentHeadcount
                                ).toLocaleString()}
                              </td>
                              <td className="text-right">
                                {parseFloat(x.fte).toLocaleString()}
                              </td>
                              <td className="text-right">
                                {parseFloat(x.ofte).toLocaleString()}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="divider" />
              <div>
                <h5>B. Project Rationale and Need</h5>
              </div>
              {renderScore(
                "b1NeedScore",
                "b1NeedInfo",
                "B1 Score",
                "B1 Explanation",
                "Measure B1: Projects Promotion of Enrollment Growth, Retention, and Degree Production",
                ""
              )}
              {renderScore(
                "b2ImpactScore",
                "b2ImpactInfo",
                "B2 Score",
                "B2 Explanation",
                "Measure B2: Projects Impact on Education and Workforce Needs in Local and Regional Economies",
                ""
              )}
              {renderScore(
                "b3StrategicScore",
                "b3StrategicInfo",
                "B3 Score",
                "B3 Explanation",
                "Measure B3: Projects Support of HEI Strategic Plan or Facility Master Plan",
                "Demonstrate project alignment with institutional mission and how project advances the institution’s strategic or facility master plan.",
                () => {
                  setSelectedDocType("Master Plan")
                  addDocument("Master Plan")
                },
                "Master Plan"
              )}
              {renderScore(
                "b4AssessmentScore",
                "b4AssessmentInfo",
                "B4 Level of Study Completed",
                "B4 Explanation",
                "Measure B4: Facilities Assessment",
                "Provide the facility’s most recent condition score and summarize the major structural and systems conditions that resulted in that score. Provide selected supporting documentation in appendices and reference them in the body of the proposal.",
                () => {
                  setSelectedDocType("Study")
                  addDocument("Study")
                },
                "Study",
                () => {
                  return (
                    <>
                      <Col md="6">
                        <div className="mb-3">
                          <Label className="form-label">Cost to Repair</Label>
                          <HelpIconWithModal itemId={12} />
                          <CurrencyInput
                            className="form-control"
                            value={nm.b4CostToRepair}
                            onValueChange={(value, name, values) => {
                              changeNmProp("b4CostToRepair", values.float)
                            }}
                            placeholder=""
                            prefix={"$"}
                            decimalScale={0}
                            decimalsLimit={0}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label className="form-label">Cost to Replace</Label>
                          <CurrencyInput
                            className="form-control"
                            value={nm.b4CostToReplace}
                            onValueChange={(value, name, values) => {
                              changeNmProp("b4CostToReplace", values.float)
                            }}
                            placeholder=""
                            prefix={"$"}
                            decimalScale={0}
                            decimalsLimit={0}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label className="form-label">
                            Replacement Cost Basis ($ per SF)
                          </Label>
                          <CurrencyInput
                            className="form-control"
                            value={nm.b4ReplacementBasis}
                            onValueChange={(value, name, values) => {
                              changeNmProp("b4ReplacementBasis", values.float)
                            }}
                            placeholder=""
                            prefix={"$"}
                            decimalScale={0}
                            decimalsLimit={0}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label className="form-label">
                            Cost to Repair AFTER Project
                          </Label>
                          <CurrencyInput
                            className="form-control"
                            value={nm.b4CostToRepairAfter}
                            onValueChange={(value, name, values) => {
                              changeNmProp("b4CostToRepairAfter", values.float)
                            }}
                            placeholder=""
                            prefix={"$"}
                            decimalScale={0}
                            decimalsLimit={0}
                          />
                        </div>
                      </Col>
                    </>
                  )
                }
              )}
              {renderScore(
                "b5InstructionScore",
                "b5InstructionInfo",
                "B5 Score",
                "B5 Explanation",
                "Measure B5: Projects Impact on On–Campus and Off-Campus Instruction",
                "Provide information on how this project request will support both on-campus and off-campus instruction."
              )}
              <div>
                <div className="divider" />
                <h5>C. Green Screen for Buildings</h5>
              </div>
              {renderScore(
                "c1EnergyScore",
                "c1EnergyInfo",
                "C1 Score",
                "C1 Explanation",
                "Measure C1: Energy Audit or Similar Energy Assessment",
                "Document details of the audit to include who performed the audit, when it was completed, level of audit/assessment, improvements proposed, and benefits to this project.",
                () => {
                  setSelectedDocType("Energy Audit")
                  addDocument("Energy Audit")
                },
                "Energy Audit",
                () => (
                  <Col md="6">
                    <div
                      className="mb-3"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Input
                        type="checkbox"
                        id="eaudit"
                        checked={nm.c1EnergyAuditComplete == 1}
                        onClick={() => {
                          changeNmProp(
                            "c1EnergyAuditComplete",
                            !nm.c1EnergyAuditComplete ? 1 : 0
                          )
                        }}
                      />
                      <Label style={{ marginLeft: "10px" }} for="eaudit">
                        Energy Audit Completed
                      </Label>
                    </div>
                  </Col>
                )
              )}
              {renderScore(
                "c2EnergyReductionScore",
                "c2EnergyReductionInfo",
                "C2 Score",
                "C2 Explanation",
                "Measure C2: Projects Impact on Energy / Utility Cost Reduction",
                "Explain the impact of this project to the net energy / utility costs. Provide a justification if no operating budget impact is anticipated.",
                undefined,
                undefined,
                () => (
                  <Col md="6">
                    <div className="mb-3">
                      <Label className="form-label">Current Energy Cost</Label>
                      <CurrencyInput
                        className="form-control"
                        value={nm.c2EnergyCurrent}
                        onValueChange={(value, name, values) => {
                          changeNmProp("c2EnergyCurrent", values.float)
                        }}
                        placeholder=""
                        prefix={"$"}
                        decimalScale={0}
                        decimalsLimit={0}
                      />
                    </div>
                  </Col>
                )
              )}
              {renderScore(
                "c3EOScore",
                "c3EOInfo",
                "C3 Score",
                "C3 Explanation",
                "Measure C3: Executive Order (EO) 2019-003",
                "Provide detailed information on how this project will address the goal of reducing Green House Gas (GHG) emissions by 45% as called for in the EO. Explain the steps taken to reduce the buildings energy demands."
              )}
              <div>
                <div className="divider" />
                <h5>
                  D. Stewardship - Detail how the HEI provides stewardship for
                  its assets.
                </h5>
              </div>
              {renderScore(
                "d1StewardshipScore",
                "d1StewardshipInfo",
                "D1 Score",
                "D1 Explanation",
                "Measure D1: Project Estimates",
                "Describe how this projects cost estimates were developed. Provide the total dollars attributed to inflation. Percentage increases MUST be defended in the narrative portion of the document, or 0% inflation will be assumed.",
                () => {
                  setSelectedDocType("Estimate")
                  addDocument("Estimate")
                },
                "Estimate",
                () => (
                  <>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">
                          Base Project Estimate
                        </Label>
                        <HelpIconWithModal itemId={25} />
                        <CurrencyInput
                          className="form-control"
                          value={nm.d1Estimate}
                          onValueChange={(value, name, values) => {
                            changeNmProp("d1Estimate", values.float)
                          }}
                          placeholder=""
                          prefix={"$"}
                          decimalScale={0}
                          decimalsLimit={0}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">
                          Dollars related to Inflation
                        </Label>
                        <CurrencyInput
                          className="form-control"
                          value={nm.d1DollarsToInflation}
                          onValueChange={(value, name, values) => {
                            changeNmProp("d1DollarsToInflation", values.float)
                          }}
                          placeholder=""
                          prefix={"$"}
                          decimalScale={0}
                          decimalsLimit={0}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div
                        className="mb-3"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Input
                          type="checkbox"
                          id="D1EstimateProvided"
                          checked={nm.d1EstimateProvided}
                          onClick={() => {
                            changeNmProp(
                              "d1EstimateProvided",
                              !nm.d1EstimateProvided
                            )
                          }}
                        />
                        <Label
                          style={{ marginLeft: "10px" }}
                          for="D1EstimateProvided"
                        >
                          Formal Estimate Provided
                        </Label>
                      </div>
                    </Col>
                  </>
                )
              )}
              {renderScore(
                undefined,
                "d2MaintenanceInfo",
                undefined,
                "D2 Explanation",
                "Measure D2: Describe how this project addresses/reduces total deferred maintenance backlog on campus.  If the total campus backlog is not known, what is the project reduction?",
                "",
                undefined,
                undefined,
                () => (
                  <>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">
                          Campus wide Deferred Maintenance Backlog
                        </Label>
                        <CurrencyInput
                          className="form-control"
                          value={nm.d2DeferredMaintenance}
                          onValueChange={(value, name, values) => {
                            changeNmProp("d2DeferredMaintenance", values.float)
                          }}
                          placeholder=""
                          prefix={"$"}
                          decimalScale={0}
                          decimalsLimit={0}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">
                          Campus wide Deferred Maintenance AFTER Completion
                        </Label>
                        <CurrencyInput
                          className="form-control"
                          value={nm.d2DeferredMaintenanceAfter}
                          onValueChange={(value, name, values) => {
                            changeNmProp(
                              "d2DeferredMaintenanceAfter",
                              values.float
                            )
                          }}
                          placeholder=""
                          prefix={"$"}
                          decimalScale={0}
                          decimalsLimit={0}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">
                          Project Deferred Maintenance
                        </Label>
                        <CurrencyInput
                          className="form-control"
                          value={nm.d2ProjectDeferredMaintenance}
                          onValueChange={(value, name, values) => {
                            changeNmProp(
                              "d2ProjectDeferredMaintenance",
                              values.float
                            )
                          }}
                          placeholder=""
                          prefix={"$"}
                          decimalScale={0}
                          decimalsLimit={0}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">
                          Project Deferred Maintenance AFTER Completion
                        </Label>
                        <CurrencyInput
                          className="form-control"
                          value={nm.d2ProjectDeferredMaintenanceAfter}
                          onValueChange={(value, name, values) => {
                            changeNmProp(
                              "d2ProjectDeferredMaintenanceAfter",
                              values.float
                            )
                          }}
                          placeholder=""
                          prefix={"$"}
                          decimalScale={0}
                          decimalsLimit={0}
                        />
                      </div>
                    </Col>
                  </>
                )
              )}
              {renderScore(
                "d3AssetScore",
                "d3AssetInfo",
                "D3 Score",
                "D3 Explanation",
                "Measure D3: Provide information on how the HEI supports the ongoing operational and maintenance needs of current and proposed assets.",
                "",
                () => {
                  setSelectedDocType("BRR Plan")
                  addDocument("BRR Plan")
                },
                "BRR Plan"
              )}
              {renderScore(
                undefined,
                "d4CostReductionInfo",
                undefined,
                "D4 Explanation",
                "Measure D4: Maintenance Cost Reduction",
                "Describe in detail how this project will affect operating appropriations for the current year and all out-years. Provide a justification if no operating budget impact is anticipated.",
                undefined,
                undefined,
                () => (
                  <>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">
                          Total Annual O&M Budget
                        </Label>
                        <CurrencyInput
                          className="form-control"
                          value={nm.d4OM}
                          onValueChange={(value, name, values) => {
                            changeNmProp("d4OM", values.float)
                          }}
                          placeholder=""
                          prefix={"$"}
                          decimalScale={0}
                          decimalsLimit={0}
                        />
                      </div>
                    </Col>
                  </>
                )
              )}
              {renderScore(
                "d5HSSScore",
                "d5HSSInfo",
                "D5 Score",
                "D5 Explanation",
                "Measure D5: Health, safety, and security",
                "Describe how this project will address major health and safety issues/concerns on campus, including how it will improve physical safety and cybersecurity on campus. Provide selected supporting documentation and reference them in the body of the proposal.",
                () => {
                  setSelectedDocType("HSS Plan")
                  addDocument("HSS Plan")
                },
                "HSS Plan"
              )}
              <Row>
                <div className="divider" />
                <Col>
                  <div
                    className="mb-3"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div style={{ width: "25%" }}>
                      <Label>Appropriation Language</Label>
                    </div>
                    <div style={{ width: "75%" }}>
                      <TextareaAutosize
                        minRows={4}
                        className="form-control"
                        onChange={e =>
                          changeNmProp("appropriationLanguage", e.target.value)
                        }
                        value={nm.appropriationLanguage}
                      />
                    </div>
                  </div>
                  <div
                    className="mb-3"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div style={{ width: "25%" }}>
                      <Label>Follow-up Questions</Label>
                    </div>
                    <div style={{ width: "75%" }}>
                      <TextareaAutosize
                        minRows={4}
                        className="form-control"
                        onChange={e =>
                          changeNmProp("questions", e.target.value)
                        }
                        value={nm.questions}
                      />
                    </div>
                  </div>
                  <div
                    className="mb-3"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div style={{ width: "25%" }}>
                      <Label>Follow-up Answers</Label>
                    </div>
                    <div style={{ width: "75%" }}>
                      <TextareaAutosize
                        minRows={4}
                        className="form-control"
                        onChange={e => changeNmProp("answers", e.target.value)}
                        value={nm.answers}
                      />
                    </div>
                  </div>
                  <div
                    className="mb-3"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div style={{ width: "25%" }}>
                      <Label>Capital Request State</Label>
                      <br></br>
                      <span className="smallHelp">
                        You should keep this capital request in draft status
                        until you are ready to submit it. Once it's submitted,
                        the data is locked until a question is submitted by
                        NMHED.
                      </span>
                    </div>
                    <div style={{ width: "75%" }}>
                      <select
                        className="form-control form-select select2 mb-xxl-0"
                        value={nm.nmhedStatusID}
                        onChange={e => {
                          changeNmProp("nmhedStatusID", e.target.value)
                        }}
                      >
                        {nmStatuses.map((a, idx) => {
                          return (
                            <option key={idx} value={`${a.listItemID}`}>
                              {a.listItemName}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
          <EditBuildingModal
            isOpen={showBuildingModal}
            toggleModal={toggleBuildingModal}
            buildingToEdit={buildingToEdit}
            changeBuildingProp={changeBuildingProp}
            reloadData={loadNm}
            nm={nm}
            changeNmProp={changeNmProp}
            entitiesByParent={entitiesByParent}
          />
          <EditPhaseModal
            isOpen={showPhaseModal}
            toggleModal={togglePhaseModal}
            phaseToEdit={phaseToEdit}
            changePhaseProp={changePhaseProp}
            nm={nm}
            changeNmProp={changeNmProp}
          />
          <EditMajorModal
            isOpen={showMajorModal}
            toggleModal={toggleMajorModal}
            majorToEdit={majorToEdit}
            changeMajorProp={changeMajorProp}
            nm={nm}
            changeNmProp={changeNmProp}
          />
          <EditDocumentModal
            isOpen={isDocModalVisible}
            toggleModal={toggleDocModal}
            documentToEdit={documentToEdit}
            setDocumentToEdit={setDocumentToEdit}
            reloadData={loadDocuments}
            showType={true}
          />
          <DocumentsModal
            isOpen={isDocumentsModalVisible}
            toggleModal={toggleDocumentsModal}
            docType={selectedDocType}
            documents={documents}
            reloadData={loadDocuments}
          />
        </>
      )}
    </React.Fragment>
  )
}

export default NmSection
