import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const RedirectModal = ({ isOpen, toggleModal, link1, link2, history }) => {
  return (
    <Modal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Go to the Project
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">

      <div
              className="smallHelp"
              style={{
                marginBottom: "15px",
              }}
            >
              Where do you want to go?.
            </div>

        <div className="mb-3">
          <button
            type="button"
            className="btn btn-primary "
            onClick={() => {
              const location = {
                pathname: link1,
                state: { fromDashboard: true },
              }
              history.push(location)
            }}
          >
            Go to the Business Case in Definitive Pro
          </button>
        </div>
        <div className="mb-3">
          <button
            type="button"
            className="btn btn-primary mb-3"
            onClick={() => {
              window.open(link2, "_blank")
            }}
          >
            Go to the External Project Document
          </button>
        </div>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
      </div>
    </Modal>
  )
}

export default RedirectModal
