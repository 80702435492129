import { TextareaAutosize } from "@material-ui/core"
import { QueryBuilderComponent } from "@syncfusion/ej2-react-querybuilder"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import isNullOrEmpty from "utils/isNullOrEmpty"

const AddEditReportDataSourceModal = ({
  isOpen,
  toggleModal,
  reportDataSourceToEdit,
  setReportDataSourceToEdit,
  reload,
}) => {
  const currentUser = userService.getLoggedInUser()
  const queryBuilderRef = useRef(null)
  let [reportViews, setReportViews] = useState([])
  const [reportViewColumns, setReportViewColumns] = useState([])
  const [columns, setColumns] = useState([])
  let separator = "."
  let fieldMode = "DropdownTree"

  useEffect(() => {
    loadReportViews()
  }, [])

  useEffect(() => {
    if (isOpen) {
      if (reportDataSourceToEdit.reportViewID > 0) {
        loadReportViewColumns(reportDataSourceToEdit.reportViewID)
      }
    }
  }, [isOpen])

  const loadReportViews = async () => {
    let rv = await api.getReportViews()
    setReportViews(rv)
  }

  const loadReportViewColumns = async reportViewId => {
    let rv = await api.getReportViewColumns(reportViewId)
    setReportViewColumns(rv)

    let cols = reportViewColumns.map(c => {
      let ret = {
        type: c.dataType,
        field: c.columnName,
        label: c.columnName,
      }
      if (
        c.reportViewColumnOption != null &&
        c.reportViewColumnOption.listItems != null &&
        c.reportViewColumnOption.listItems.length > 0
      ) {
        ret.values = c.reportViewColumnOption.listItems.map(x => x.listItemName)
        ret.operators = [
          { key: "In", value: "in" },
          { key: "Not in", value: "notin" },
        ]
      }

      return ret
    })
    setColumns(cols)
  }

  const save = async () => {
    if (reportDataSourceToEdit.id > 0) {
      await api.updateReportDataSource({
        id: reportDataSourceToEdit.id,
        reportViewID: reportDataSourceToEdit.reportViewID,
        name: reportDataSourceToEdit.name,
        description: reportDataSourceToEdit.description,
        enterpriseID: currentUser.enterpriseID,
        ruleModel: isNullOrEmpty(queryBuilderRef?.current)
          ? ""
          : JSON.stringify(queryBuilderRef.current.getRules()),
        sqlCondition: isNullOrEmpty(queryBuilderRef?.current)
          ? ""
          : queryBuilderRef.current.getSqlFromRules(),
        inactive: reportDataSourceToEdit.inactive,
      })
    } else {
      await api.createReportDataSource({
        id: -1,
        reportViewID: reportDataSourceToEdit.reportViewID,
        name: reportDataSourceToEdit.name,
        description: reportDataSourceToEdit.description,
        enterpriseID: currentUser.enterpriseID,
        ruleModel: isNullOrEmpty(queryBuilderRef?.current)
          ? ""
          : JSON.stringify(queryBuilderRef.current.getRules()),
        sqlCondition: isNullOrEmpty(queryBuilderRef?.current)
          ? ""
          : queryBuilderRef.current.getSqlFromRules(),
        inactive: reportDataSourceToEdit.inactive,
      })
    }
    reload && reload()
    toggleModal()
  }

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Report Data Source
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {reportDataSourceToEdit != null && (
        <div className="modal-body">
          <div className="mb-3">
            <Label className="form-label">Name</Label>
            <Input
              name="note"
              type="text"
              onChange={e =>
                setReportDataSourceToEdit({
                  ...reportDataSourceToEdit,
                  name: e.target.value,
                })
              }
              value={reportDataSourceToEdit.name}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Description</Label>
            <TextareaAutosize
              className="form-control"
              minRows={3}
              onChange={e =>
                setReportDataSourceToEdit({
                  ...reportDataSourceToEdit,
                  description: e.target.value,
                })
              }
              value={reportDataSourceToEdit.description}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Report View</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={reportDataSourceToEdit.reportViewID}
              onChange={e => {
                let id = e.target.value
                setReportDataSourceToEdit({
                  ...reportDataSourceToEdit,
                  reportViewID: id,
                })
                if (id > 0) {
                  loadReportViewColumns(id)
                } else {
                  setReportViewColumns([])
                }
              }}
            >
              <option value="-1">Select...</option>
              {reportViews.map((r, idx) => (
                <option key={idx} value={r.id}>
                  {r.viewName}
                </option>
              ))}
            </select>
          </div>
          {reportDataSourceToEdit.reportViewID > 0 && columns.length > 0 && (
            <div className="mb-3">
              <QueryBuilderComponent
                id="querybuilder"
                columns={columns}
                rule={reportDataSourceToEdit.rule}
                fieldMode={fieldMode}
                separator={separator}
                ref={queryBuilderRef}
              ></QueryBuilderComponent>
            </div>
          )}
        </div>
      )}
      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondayry"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default AddEditReportDataSourceModal
