import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const ClearEstimatesModal = ({
  isOpen,
  toggleModal,
  alternativeYears,
  alternativeId,
  reload
}) => {
  const currentUser = userService.getLoggedInUser()
  const [selectedYearOption, setSelectedYearOption] = useState(-1)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (isOpen) {
      resetData()
    }
  }, [isOpen])

  const resetData = () => {
    setSelectedYearOption(-1)
  }

  const save = async () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `This will clear resource estimate data for ${
          selectedYearOption <= 0 ? "All Years" : selectedYearOption
        }.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          try {
            setIsSubmitting(true)
            await api.clearResourceEstimates(
              alternativeId,
              selectedYearOption,
              currentUser.userID
            )
            toggleModal()
            reload()
            setIsSubmitting(false)
          } catch {
            setIsSubmitting(false)
          }
        }
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Clear Estimates
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <Label className="form-label">Clear the estimates for year:</Label>
          <select
            className="form-control form-select select2 mb-xxl-0"
            value={selectedYearOption}
            onChange={e => {
              setSelectedYearOption(e.target.value)
            }}
          >
            <option value="-1">All Years</option>
            {alternativeYears.map((y, i) => (
              <option key={i} value={y}>
                FY {y}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="modal-footer">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Close
          </button>
          <button type="button" className="btn btn-primary" onClick={save}>
            Save
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ClearEstimatesModal
