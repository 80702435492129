const altGroupRatingList = [
    {
        text: 'High',
        color: 'white',
        backgroundColor: 'blue',
        minValue: 2.750,
        minScore: 91.6670
    },
    {
        text: 'Medium-High',
        color: 'black',
        backgroundColor: 'lightblue',
        minValue: 2.250,
        minScore: 75.0000
    },
    {
        text: 'Medium',
        color: 'white',
        backgroundColor: 'green',
        minValue: 1.750,
        minScore: 58.3330
    },
    {
        text: 'Medium-Low',
        color: 'black',
        backgroundColor: 'lightgreen',
        minValue: 1.350,
        minScore: 41.6670
    },
    {
        text: 'Low',
        color: 'black',
        backgroundColor: 'yellow',
        minValue: 0.750,
        minScore: 25.0000
    },
    {
        text: 'Very Low',
        color: 'black',
        backgroundColor: '#fecdfc',
        minValue: 0.250,
        minScore: 8.3330
    },
    {
        text: 'Negligible',
        color: 'white',
        backgroundColor: '#ff649a',
        minValue: 0.0000,
        minScore: 0.0000
    },
]

export default altGroupRatingList