import { UPDATE_SELECTED_ALTERNATIVES } from "./actionTypes"

const INIT_STATE = {
  alternatives: [],
}

const gridSelection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_SELECTED_ALTERNATIVES:
      return {
        ...state,
        alternatives: action.payload,
      }

    default:
      return state
  }
}

export default gridSelection
