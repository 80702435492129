import React, { useEffect, useRef, useState } from "react"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { QueryBuilderComponent } from "@syncfusion/ej2-react-querybuilder"
import useModal from "hooks/useModalHook"
import EditActionModal from "./EditActionModal"
import moment from "moment"

const BusinessRulesModal = ({
  isOpen,
  toggleModal,
  businessRuleToEdit,
  setBusinessRuleToEdit,
  columns,
  scenarioId,
  scope,
  reload,
  actionFields,
}) => {
  const currentUser = userService.getLoggedInUser()
  const queryBuilderRef = useRef(null)
  let fieldMode = "DropdownTree"
  let separator = "."
  const [actionToEdit, setActionToEdit] = useState({ businessRuleActionID: -1 })
  const { show: showActionModal, toggle: toggleActionModal } = useModal()

  useEffect(() => {
    if (isOpen) {
      loadData()
    }
  }, [isOpen])

  const loadData = () => {}

  function getRuleLabels(obj) {
    let labels = ""

    if (obj.label != undefined) {
      labels += obj.label + ","
    }

    if (obj.rules !== undefined) {
      obj.rules.forEach(r => {
        labels += getRuleLabels(r)
      })
    }

    return labels
  }

  const save = async () => {
    let rules = queryBuilderRef.current.getRules()

    let id
    if (businessRuleToEdit.businessRuleID == -1) {
      id = await api.createBusinessRule({
        businessRuleName: businessRuleToEdit.businessRuleName,
        inactive: false,
        enterpriseID: currentUser.enterpriseID,
        scenarioID: scenarioId,
        scope: scope,
        ruleModel: JSON.stringify(queryBuilderRef.current.getRules()),
        triggerSql: queryBuilderRef.current.getSqlFromRules(),
        triggerFieldLabels: getRuleLabels(rules),
      })
    } else {
      id = businessRuleToEdit.businessRuleID
      await api.updateBusinessRule({
        ...businessRuleToEdit,
        ruleModel: JSON.stringify(queryBuilderRef.current.getRules()),
        triggerSql: queryBuilderRef.current.getSqlFromRules(),
        triggerFieldLabels: getRuleLabels(rules),
      })
    }

    let newActions = businessRuleToEdit.businessRuleActions.filter(
      x => !x.isDeleted && x.businessRuleActionID < 0
    )
    let updatedActions = businessRuleToEdit.businessRuleActions.filter(
      x => !x.isDeleted && x.businessRuleActionID > 0
    )
    let deletedActions = businessRuleToEdit.businessRuleActions.filter(
      x => x.isDeleted && x.businessRuleActionID > 0
    )
    let createTasks = newActions.map(x => async () => {
      return await api.createBusinessRuleAction({
        ...x,
        businessRuleID: id,
        scope: "Alternative",
        actionSQL: "sql",
      })
    })
    let updateTasks = updatedActions.map(x => async () => {
      return await api.updateBusinessRuleAction({
        ...x,
        businessRuleID: id,
        scope: "Alternative",
        actionSQL: "sql",
      })
    })
    let deleteTasks = deletedActions.map(x => async () => {
      return await api.deleteBusinessRuleAction(x.businessRuleActionID)
    })
    let allTasks = [...createTasks, ...updateTasks, ...deleteTasks]
    if (allTasks.length > 0) await Promise.all(allTasks.map(t => t()))

    reload()
    toggleModal()
  }

  const addAction = () => {
    let id = -1
    let ids = businessRuleToEdit.businessRuleActions.map(
      x => x.businessRuleActionID
    )
    if (ids.length > 0) {
      let min = Math.min(...ids)
      if (min < 0) {
        id = min - 1
      }
    }
    console.log(id)
    setActionToEdit({ businessRuleActionID: id, actionField: "-1" })
    toggleActionModal()
  }

  const onActionSave = selectedActionField => {
    let copy = [...businessRuleToEdit.businessRuleActions]
    let existing = copy.find(
      x => x.businessRuleActionID == actionToEdit.businessRuleActionID
    )
    
    const updatedObj = {
      ...actionToEdit,
      actionLabel: selectedActionField.fieldLabel,
    }

    if (
      selectedActionField.fieldType == "date" &&
      (actionToEdit.actionValue == undefined || actionToEdit.actionValue == "")
    ) {
      updatedObj.actionText = "Current date"
    }

    if (existing == undefined) {
      copy.push(updatedObj)
    } else {
      copy[copy.indexOf(existing)] = updatedObj
    }
    setBusinessRuleToEdit({ ...businessRuleToEdit, businessRuleActions: copy })
  }

  const deleteAction = actionToDelete => {
    let copy = { ...businessRuleToEdit }
    let action = copy.businessRuleActions.find(
      x => x.businessRuleActionID == actionToDelete.businessRuleActionID
    )
    if (action !== undefined) {
      action.isDeleted = true
      setBusinessRuleToEdit(copy)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      size="xl"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Business Rule
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <Label className="form-label">Name</Label>
          <Input
            type="text"
            onChange={e =>
              setBusinessRuleToEdit({
                ...businessRuleToEdit,
                businessRuleName: e.target.value,
              })
            }
            value={businessRuleToEdit.businessRuleName}
          />
        </div>
        <div className="form-check mb-3" style={{ marginLeft: "10px" }}>
          <Input
            type="checkbox"
            className="form-check-input"
            id="customCheck7"
            checked={businessRuleToEdit.inactive}
            onClick={e => {
              setBusinessRuleToEdit({
                ...businessRuleToEdit,
                inactive: !businessRuleToEdit.inactive,
              })
            }}
          />
          <Label className="form-check-label" for="customCheck7">
            Inactive
          </Label>
        </div>
        <div className="mb-3">
          <QueryBuilderComponent
            id="querybuilder"
            columns={columns}
            rule={businessRuleToEdit.rule}
            fieldMode={fieldMode}
            separator={separator}
            ref={queryBuilderRef}
          ></QueryBuilderComponent>
        </div>
        <button className="btn btn-primary mb-3" onClick={addAction}>
          Add Action
        </button>
        <table
          className="table table-bordered low-padding-table"
          style={{ backgroundColor: "white" }}
        >
          <thead>
            <tr>
              <th>Action</th>
              <th>Value</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {businessRuleToEdit.businessRuleActions &&
              businessRuleToEdit.businessRuleActions
                .filter(x => !x.isDeleted)
                .map((b, idx) => (
                  <tr key={idx}>
                    <td>
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault()
                          setActionToEdit(b)
                          toggleActionModal()
                        }}
                      >
                        {b.actionLabel}
                      </a>
                    </td>
                    <td>{b.actionText}</td>
                    <td>
                      <i
                        className="fas fa-trash-alt"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => deleteAction(b)}
                      ></i>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
      <EditActionModal
        isOpen={showActionModal}
        toggleModal={toggleActionModal}
        actionToEdit={actionToEdit}
        setActionToEdit={setActionToEdit}
        actionFields={actionFields}
        onSave={onActionSave}
      />
    </Modal>
  )
}

export default BusinessRulesModal
