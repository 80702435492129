import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Container, Row } from "reactstrap"
import api from "services/api.service"
import colors from "utils/colors"
import ConsensusGrid from "./ConsensusGrid"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { userService } from "services/user.service"
import { useDispatch, useSelector } from "react-redux"
import { changeNavbarParams, loadPageItem } from "store/actions"

const PwcConsensus = props => {
  const scenarioId = props.match.params.scenarioId
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  let [objectives, setObjectives] = useState([])
  let [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "pwcConsensus",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        viewName: "pwcConsensus",
      })
    )
    loadData()
  }, [])

  const loadData = async () => {
    try {
      setIsLoading(true)
      let data = await api.getPwcConsensus(scenarioId)
      setObjectives(data)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={pageItem !== undefined ? pageItem.pageSubTitle : ""} />
          <Row>
            <div>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              {!isLoading && objectives.length > 0 && <ConsensusGrid data={objectives} />}
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PwcConsensus
