import NameAvatar from "components/custom/nameAvatar"
import React from "react"

const ObjectiveRatingsGrid = ({
  data,
  dropdownSelection,
  objectives,
  objectivesAndMeasures,
  scenario,
}) => {
  return (
    <table
      className="table table-bordered low-padding-table"
      style={{ backgroundColor: "white" }}
    >
      <thead>
        <tr>
          <th style={{ width: dropdownSelection.value == -1 ? "50%" : "15%" }}>
            Participant
          </th>
          {dropdownSelection.value == -1 && <th>{scenario.scenarioName}</th>}
          {(dropdownSelection.value == 0 || dropdownSelection.value == -2) &&
            objectives.map((o, i) => {
              return <th key={i}>{o.objectiveName}</th>
            })}
          {dropdownSelection.value == -99 &&
            objectivesAndMeasures.map((o, i) => {
              if (o.measureID != undefined) {
                return <th key={i}>{o.measureName}</th>
              }
              return <th key={i}>{o.objectiveName}</th>
            })}
          {dropdownSelection.value > 0 &&
            objectivesAndMeasures
              .filter(
                x =>
                  x.measureID != undefined &&
                  x.objectiveID == dropdownSelection.value
              )
              .map((m, i) => {
                return <th key={i}>{m.measureName}</th>
              })}
        </tr>
      </thead>
      <tbody>
        {data.map((a, idx) => (
          <tr key={idx}>
            <td>
              <NameAvatar fullName={`${a.userName}`} showName={true} base64Image={a.photoBase64} />
            </td>
            {dropdownSelection.value == -1 && (
              <td>
                {
                  a[
                    scenario.scenarioName
                      .toLowerCase()
                      .replaceAll(",", "")
                      .replaceAll('"', "")
                      .replaceAll("'", "")
                  ]
                }
              </td>
            )}

            {dropdownSelection.value == -2 &&
              objectives.map((o, i) => {
                return (
                  <td key={i} style={{ textAlign: "center" }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          a[
                            o.objectiveName
                              .toLowerCase()
                              .replaceAll(",", "")
                              .replaceAll('"', "")
                              .replaceAll("'", "")
                          ],
                      }}
                    ></div>
                  </td>
                )
              })}
            {dropdownSelection.value == 0 &&
              objectives.map((o, i) => {
                return (
                  <td key={i} style={{ textAlign: "center" }}>
                    {
                      a[
                        o.objectiveName
                          .toLowerCase()
                          .replaceAll(",", "")
                          .replaceAll('"', "")
                          .replaceAll("'", "")
                      ]
                    }
                  </td>
                )
              })}
            {dropdownSelection.value == -99 &&
              objectivesAndMeasures.map((o, i) => {
                if (o.measureID != undefined) {
                  return (
                    <td key={i} style={{ textAlign: "center" }}>
                      {
                        a[
                          o.measureName
                            .toLowerCase()
                            .replaceAll(",", "")
                            .replaceAll('"', "")
                            .replaceAll("'", "")
                        ]
                      }
                    </td>
                  )
                }
                return (
                  <td key={i} style={{ textAlign: "center" }}>
                    {
                      a[
                        o.objectiveName
                          .toLowerCase()
                          .replaceAll(",", "")
                          .replaceAll('"', "")
                          .replaceAll("'", "")
                      ]
                    }
                  </td>
                )
              })}
            {dropdownSelection.value > 0 &&
              objectivesAndMeasures
                .filter(
                  x =>
                    x.measureID != undefined &&
                    x.objectiveID == dropdownSelection.value
                )
                .map((m, i) => {
                  return (
                    <td key={i} style={{ textAlign: "center" }}>
                      {
                        a[
                          m.measureName
                            .toLowerCase()
                            .replaceAll(",", "")
                            .replaceAll('"', "")
                            .replaceAll("'", "")
                        ]
                      }
                    </td>
                  )
                })}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default ObjectiveRatingsGrid
