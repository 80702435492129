import { TextareaAutosize } from "@material-ui/core"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const CreateLookbackModal = ({ isOpen, toggleModal, saveCallback }) => {
  const currentUser = userService.getLoggedInUser()
  const [lookback, setLookback] = useState(null)

  useEffect(() => {
    if (isOpen) {
      setLookback({ lookbackID: -1, lookbackDate: new Date(), comments: "" })
    }
  }, [isOpen])

  const save = async () => {
    saveCallback({ ...lookback })
  }

  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Create Lookback
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {lookback != null && (
        <div className="modal-body">
          <div className="mb-3">
            <Label className="form-label">Lookback Date</Label>
            <Input
              name="startDate"
              type="date"
              onChange={e =>
                setLookback({
                  ...lookback,
                  lookbackDate: moment(e.target.value, "YYYY-MM-DD").format(
                    "YYYY-MM-DDThh:mm:ss"
                  ),
                })
              }
              value={moment(lookback.lookbackDate).format("YYYY-MM-DD")}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Lookback Comments</Label>
            <TextareaAutosize
              minRows={2}
              className="form-control"
              onChange={e =>
                setLookback({ ...lookback, comments: e.target.value })
              }
              value={lookback.comments}
            />
          </div>
        </div>
      )}
      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default CreateLookbackModal
