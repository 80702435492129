import api from "./api.service"

class OpenaiService {
  constructor() {
  }

  async askAssistantAsync(prompt, useGeneralAssistant) {
    let callGeneralAssistant = useGeneralAssistant == undefined ? false : useGeneralAssistant
    let response = await api.askOpenAiAssistant(prompt,callGeneralAssistant)
    return response
  }
}

export default OpenaiService
