import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect, useDispatch, useSelector } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"
import { userService } from "services/user.service"
import UserProfileModal from "components/GlobalModals.js/UserProfileModal"
import NotificationSettingsModal from "components/GlobalModals.js/NotificationSettings"
import NameAvatar from "components/custom/nameAvatar"
import api from "services/api.service"
import logo from "../../../assets/images/logo.svg"
import useModal from "hooks/useModalHook"
import VideosModal from "components/custom/VideosModal"
import ChatGPTDrawerGlobal from "components/custom/chatGPTDrawerGlobal"
import { changeChatGptSettings } from "store/chatGpt/actions"
import { changeUserPhoto } from "store/userPhoto/actions"
import InAppNotificationModal from "components/GlobalModals.js/InAppNotificationModal"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const currentUser = userService.getLoggedInUser()
  const [username, setusername] = useState("Admin")
  const dispatch = useDispatch()
  const [showProfileModal, setShowProfileModal] = useState(false)
  const toggleProfileModal = () => setShowProfileModal(!showProfileModal)
  const [showNotificationModal, setShowNotificationModal] = useState(false)
  const toggleNotificationModal = () =>
    setShowNotificationModal(!showNotificationModal)
  const [enterprise, setEnterprise] = useState(null)
  const { show: showInAppNotificationModal, toggle: toggleInAppNotificationModal } = useModal()
  const { show: showVideosModal, toggle: toggleVideosModal } = useModal()
  const { show: showLoginMessageModal, toggle: toggleLoginMessageModal } =
    useModal()
  const { photoBase64 } = useSelector(state => ({
    photoBase64: state.userPhoto.photoBase64,
  }))
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const unreadNotifications = pageItem?.unreadNotifications || 0

  useEffect(() => {
    if (currentUser != undefined && currentUser != null) {
      loadEnterprise()
      loadAvatar()
    }
  }, [])

  const loadAvatar = async () => {
    let p = await api.getUserPhotoBase64(currentUser.userID)
    dispatch(changeUserPhoto(p == undefined || p == null ? "" : p))
  }

  const loadEnterprise = async () => {
    let e = await api.getEnterprise(currentUser.enterpriseID)
    setEnterprise(e)

    let initialLogin = localStorage.getItem("initialLogin")
    if (initialLogin != undefined && initialLogin != null) {
      if (
        e.showLoginMessage &&
        e.loginMessage != null &&
        e.loginMessage != ""
      ) {
        toggleLoginMessageModal()
        localStorage.removeItem("initialLogin")
      }
    }
  }

  const openChatGptDrawer = () => {
    dispatch(changeChatGptSettings(true))
  }

  return (
    <React.Fragment>
      <div
        style={{
          verticalAlign: "middle",
          color: "Red",
          marginRight: "15px",
        }}
      >
        <i className="mdi mdi-new-box font-size-20 align-middle" />
        <a
          href="https://definitivepro.com/about/system-status/"
          target="_blank"
          rel="noreferrer"
        >
          Check out our new features!
        </a>
      </div>
      {enterprise != null && enterprise.logoPath != "" && (
        <img
          src={enterprise.logoPath}
          height="60"
          style={{ marginRight: "15px" }}
        />
      )}
      <div
        className="d-flex-row ai-center"
        style={{ marginRight: "15px", cursor: "pointer" }}
        onClick={e => {
          props.history.push("/myData")
        }}
      >
        <i className="bx bx-bell " style={{ fontSize: "30px" }} />
        {unreadNotifications > 0 && (
          <span
            className="badge bg-danger rounded-pill"
            style={{ marginBottom: "20px" }}
          >
            {unreadNotifications}
          </span>
        )}
      </div>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {currentUser != null && (
              <NameAvatar
                fullName={`${currentUser.firstName} ${currentUser.lastName}`}
                showName={false}
                aiUser={currentUser.aiUser}
                size="lg"
                base64Image={photoBase64}
              />
            )}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                className="ms-2 me-1"
                style={{ textAlign: "left", fontSize: "10px" }}
              >
                {currentUser != null && currentUser.role}
              </span>
              <span
                className="ms-2 me-1"
                style={{ textAlign: "left", fontWeight: "bold" }}
              >
                {currentUser != null &&
                  currentUser.firstName + " " + currentUser.lastName}
              </span>
            </div>
            <i className="mdi mdi-chevron-down d-xl-inline-block" />
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem onClick={toggleProfileModal}>
            <i className="bx bx-user font-size-16 align-middle me-1" />
            Account
          </DropdownItem>

          {currentUser != null &&
            (currentUser.roleID == 110 ||
              (currentUser.roleID >= 10 && currentUser.useAI)) && (
              <DropdownItem onClick={openChatGptDrawer}>
                <i className="mdi mdi-brain font-size-16 align-middle me-1" />
                Ask Oliver
              </DropdownItem>
            )}

          <DropdownItem onClick={toggleInAppNotificationModal}>
            <i className="mdi mdi-email font-size-16 align-middle me-1" />
            Send a message
          </DropdownItem>

          <DropdownItem onClick={toggleNotificationModal}>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            Notification Settings
          </DropdownItem>

          <DropdownItem>
            <i className="mdi mdi-note-text-outline font-size-16 align-middle me-1" />
            <a
              href="https://definitivepro.com/about/system-status/"
              target="_blank"
              rel="noreferrer"
            >
              Release Notes
            </a>
          </DropdownItem>

          <DropdownItem onClick={toggleVideosModal}>
            <i className="mdi mdi-video-outline font-size-16 align-middle me-1" />
            Videos
          </DropdownItem>

          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>

      <UserProfileModal
        isOpen={showProfileModal}
        toggleModal={toggleProfileModal}
      />
      <NotificationSettingsModal
        isOpen={showNotificationModal}
        toggleModal={toggleNotificationModal}
      />

      <Modal
        backdrop="static"
        isOpen={showLoginMessageModal}
        size="lg"
        toggle={() => {
          toggleLoginMessageModal()
        }}
      >
        <div className="modal-header" style={{ minHeight: "40px" }}>
          <h5 className="modal-title mt-0" id="myModalLabel"></h5>
        </div>
        <div className="modal-body">
          {enterprise != undefined && enterprise != null && (
            <div
              dangerouslySetInnerHTML={{
                __html: enterprise.loginMessage,
              }}
            ></div>
          )}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleLoginMessageModal}
          >
            Acknowledge
          </button>
        </div>
      </Modal>
      <VideosModal isOpen={showVideosModal} toggleModal={toggleVideosModal} />
      <InAppNotificationModal isOpen={showInAppNotificationModal} toggleModal={toggleInAppNotificationModal} />
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
