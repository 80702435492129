import React from "react"
import { disableAll, enableAll } from "components/custom/disableActions"

const handlePageChanges = (pageItem, props) => {
  if (
    pageItem == undefined ||
    pageItem == null ||
    pageItem.userRight == undefined ||
    pageItem.userRight == ""
  ) {
    return
  }
  let userRight = pageItem.userRight.toLowerCase()
  if (userRight == "full" || userRight == "full_np") {
    enableAll()
  } else if (userRight == "read") {
    disableAll()
  } else if ("none") {
    props.history.push("/noAccess")
  }
}

export const roleService = {
  handlePageChanges,
}

export const useUserRight = (pageItem, props) => {
  React.useEffect(() => {
    if (pageItem != undefined && pageItem != null && pageItem.userRight != "") {
      setTimeout(() => {
        handlePageChanges(pageItem, props)
      }, 300)
    }
  }, [pageItem])
}
