import { extractInnerTextFromHtml } from "./formatters"

//prop: {property, heading, formatter?}
export const exportTableToExcel = (items, properties, fileName) => {
  let csv = ""
  properties.forEach((p, idx) => {
    csv += p.heading + (idx < properties.length - 1 ? "," : "\r\n")
  })

  for (let row = 0; row < items.length; row++) {
    for (let i = 0; i < properties.length; i++) {
      let propText =
        items[row][properties[i].property] != null &&
        typeof items[row][properties[i].property] === "string" &&
        items[row][properties[i].property].indexOf("</div>") > -1
          ? extractInnerTextFromHtml(items[row][properties[i].property])
          : properties[i].formatter !== undefined
          ? properties[i].formatter(items[row])
          : items[row][properties[i].property] || ""
      propText = propText.toString().replaceAll("\r\n", " ")
      propText = propText.replace(/[^\x00-\x7F]/g, "");

      csv += '"' + propText + '"'
      csv += i < properties.length - 1 ? "," : "\r\n"
    }
  }

  let link = document.createElement("a")
  link.id = "download-csv"
  link.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(csv)
  )
  link.setAttribute("download", fileName)
  document.body.appendChild(link)
  document.querySelector("#download-csv").click()
  document.body.removeChild(link)
}
