import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import { getSum } from "pages/PwcResults/EditPreferenceWeightsModal"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const EditOptModelModal = ({
  isOpen,
  toggleModal,
  optModelId,
  scenarioId,
  reloadData,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [optModel, setOptModel] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [objectivesDropdownList, setObjectivesDropdownList] = useState([])
  const [costDropdownList, setCostDropdownList] = useState([])
  const [objModelList, setObjModelList] = useState([])
  const [finModelList, setFinModelList] = useState([])

  useEffect(() => {
    if (isOpen && optModelId != undefined && optModelId != null) {
      loadData()
    }
  }, [isOpen, optModelId])

  const loadData = async () => {
    setIsLoading(true)
    loadDropdownData()
    let om =
      optModelId == -1
        ? {
            optModelID: -1,
            optModelName: "",
            scenarioID: scenarioId,
            costConstraint: 0,
          }
        : await api.getOptModel(optModelId)
    setOptModel(om)

    setIsLoading(false)
  }

  const loadDropdownData = async () => {
    let scenario = await api.getScenario(scenarioId)
    api.getObjModels(scenarioId).then(data => {
      setObjModelList([
        { objModelID: 0, objModelName: scenario.scenarioName },
        ...data,
      ])
    })
    api.getFinModels(scenarioId).then(data => {
      setFinModelList(data)
    })
    api.getObjectives(scenarioId).then(objs => {
      setObjectivesDropdownList([
        {
          value: 0,
          text: scenario.scenarioName,
        },
        ...objs
          .filter(x => x.measures && x.measures.length > 0)
          .map(x => {
            return {
              value: x.objectiveID,
              text: x.objectiveName,
            }
          }),
      ])
    })
    api.getCostVariables(scenarioId).then(cv => {
      setCostDropdownList([
        {
          text: "Cost",
          value: 0,
        },
        ...cv.map(c => {
          return {
            text: c.costVariableName,
            value: c.costVariableID,
          }
        }),
      ])
    })
  }

  const save = async () => {
    if (optModelId > -1) {
      await api.updateOptModel(currentUser.userID, optModel)
      toast.success("Model updated")
    } else {
      await api.createOptModel(currentUser.userID, optModel)
      toast.success("Model created")
    }
    reloadData()
    toggleModal()
  }

  const deleteOptModel = () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this model?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteOptModel(currentUser.userID, optModelId)
          toast.success("Model deleted")
          reloadData()
          toggleModal()
        }
      })
  }

  const changeOptModelProp = (prop, value) => {
    setOptModel({
      ...optModel,
      [prop]: value,
    })
  }

  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Scenario
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      {!isLoading && optModel != null && (
        <div className="modal-body">
          <div className="mb-3">
            <Label className="form-label">Scenario</Label>
            <Input
              name="note"
              type="text"
              onChange={e => changeOptModelProp("optModelName", e.target.value)}
              value={optModel.optModelName}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Criteria Scenario</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={optModel.objModelID || 0}
              onChange={e => {
                changeOptModelProp("objModelID", e.target.value)
              }}
            >
              {objModelList.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.objModelID}`}>
                    {a.objModelName}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Financial Scenario</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={optModel.finModelID || 0}
              onChange={e => {
                changeOptModelProp("finModelID", e.target.value)
              }}
            >
              {finModelList.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.finModelID}`}>
                    {a.finModelName}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Scope</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={optModel.objectiveID || 0}
              onChange={e => {
                changeOptModelProp("objectiveID", e.target.value)
              }}
            >
              {objectivesDropdownList.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.value}`}>
                    {a.text}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Optimize</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={optModel.optimizeOn}
              onChange={e => {
                changeOptModelProp("optimizeOn", e.target.value)
              }}
            >
              <option value="Score">Score</option>
              <option value="CB">CB</option>
              <option value="Combi">Combi</option>
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Optimization Type</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={optModel.minMax}
              onChange={e => {
                changeOptModelProp("minMax", e.target.value)
              }}
            >
              <option value="Max-Score">Max-Score</option>
              <option value="Min-Score">Min-Score</option>
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Cost Variable</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={optModel.costVariableID || 0}
              onChange={e => {
                changeOptModelProp("costVariableID", e.target.value)
              }}
            >
              {costDropdownList.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.value}`}>
                    {a.text}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Cost Constraint</Label>
            <CurrencyInput
              className="form-control"
              value={optModel.costConstraint}
              onValueChange={(value, name, values) => {
                changeOptModelProp("costConstraint", values.float)
              }}
              prefix={"$"}
              decimalsLimit={0}
            />
          </div>
        </div>
      )}
      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>
          {optModelId > -1 && (
            <button
              className="btn btn-outline-danger save-user"
              onClick={deleteOptModel}
            >
              Delete
            </button>
          )}
        </div>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save Scenario
        </button>
      </div>
    </Modal>
  )
}

export default EditOptModelModal
