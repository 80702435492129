import NameAvatar from "components/custom/nameAvatar"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { createRoot } from "react-dom/client"
import Loader from "react-loaders"
import { ReactTabulator } from "react-tabulator"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import EditTaskModal, { TaskTypes } from "../Modals/EditTaskModal"
import Widgets from "components/Widgets"
import { Tooltip } from "@material-ui/core"
import { loadUsersAvatars } from "utils/avatar"
import { customFormatter } from "utils/tabulator"

const TaskDrawer = ({
  taskTypeId,
  alternative,
  scenarioId,
  renderAsTable,
  showWidgets,
  alternatives,
  props,
}) => {
  return (
    <React.Fragment>
      {taskTypeId != undefined && taskTypeId != null && taskTypeId > -1 && (
        <TaskTable
          taskTypeId={taskTypeId}
          alternative={alternative}
          scenarioId={scenarioId}
          alternatives={alternatives}
          renderAsTable={renderAsTable}
          showWidgets={showWidgets}
          props={props}
        />
      )}
    </React.Fragment>
  )
}

export const TaskTable = ({
  taskTypeId,
  alternative,
  scenarioId,
  alternatives,
  renderAsTable,
  showWidgets,
  props,
}) => {
  const currentUser = userService.getLoggedInUser()
  var tabulatorRef = useRef(null)
  const [tasks, setTasks] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [taskToEdit, setTaskToEdit] = useState(null)
  const [taskType, setTaskType] = useState(null)
  const [columns, setColumns] = useState([])

  let [widgets, setWidgets] = useState([])

  const newTask = {
    taskID: -1,
    scenarioID: scenarioId,
    taskTypeID: taskTypeId,
    taskName: "",
    requestTypeID: -1,
    taskStatusID: -1,
    taskDescription: "",
    taskDisposition: "",
    taskRisk: "",
    calcResult: "TBD",
    taskNum: "TBD",
    createDT: moment(new Date()).format("MM/DD/YYYY"),
    createUser: currentUser.firstName + " " + currentUser.lastName,
    dueDT: null,
    completeDT: null,
    taskStatusID: taskTypeId == 71 ? 16 : taskTypeId == 81 ? 18 : -1,
    alternativeID: alternative ? alternative.alternativeID : 0,
    // alternativeID: alternative
    //   ? alternative.revisionAltID > 0
    //     ? alternative.revisionAltID
    //     : alternative.alternativeID
    //   : 0,
  }

  const toggleEditModal = () => setIsEditModalVisible(!isEditModalVisible)

  useEffect(() => {
    if (taskTypeId !== undefined) {
      loadData()
    }
  }, [taskTypeId])

  const loadData = async () => {
    let tt = await loadTaskType()
    await loadTasks(tt)
  }

  const loadTaskType = async () => {
    let type = await api.getTaskType(
      taskTypeId,
      currentUser.enterpriseID,
      scenarioId
    )
    setTaskType(type)

    let cols = [
      {
        title: "ID",
        field: "taskNum",
        headerFilter: "input",
        width: 100,
      },
      {
        title: "Title",
        field: "taskName",
        minWidth: 250,
        headerFilter: "input",
        formatter: customFormatter(({ rowData, cellValue }) => (
          <a href="#" onClick={e => editTask(e, rowData)}>
            {cellValue}
          </a>
        )),
      },
    ]

    if (type.scope !== "Scenarios" && alternative == null) {
      cols.push({
        title: "Project",
        field: "alternativeName",
        minWidth: 350,
        headerFilter: "input",
        formatter: customFormatter(({ rowData, cellValue }) => (
          <a href="#" onClick={e => handleAltPress(e, rowData)}>
            {cellValue}
          </a>
        )),
      })
    }

    type.taskTypeFields
      .filter(
        x =>
          x.fieldProperty !== "TaskNum" &&
          x.fieldProperty !== "AlternativeName" &&
          x.fieldProperty !== "TaskName"
      )
      .forEach(field => {
        const prop = `${field.fieldProperty[0].toLowerCase()}${field.fieldProperty.slice(
          1
        )}`

        if (field.fieldType == "Date" || field.fieldType == "ReadOnlyDate") {
          cols.push({
            title: field.fieldLabel,
            field: prop,
            minWidth: 150,
            formatter: cell => {
              if (cell.getValue() == null) {
                return ""
              }
              let d = moment(cell.getValue()).format("MM/DD/YYYY")
              return d
            },
          })
        } else if (field.fieldType == "DDL") {
          let col = {
            title: field.fieldLabel,
            field: prop,
            minWidth: 150,
            formatter: cell => {
              let item = field.listItems.find(
                x => x.listItemID == cell.getValue()
              )

              return item == undefined ? "" : item.listItemName
            },
          }
          if (field.fieldProperty.toLowerCase().indexOf("user") > -1) {
            col.formatter = customFormatter(({ rowData, cellValue }) => {
              let item = field.listItems.find(x => x.listItemID == cellValue)
              if (item !== undefined) {
                const photoProp =
                  field.fieldProperty.indexOf("OwnerUser") > -1
                    ? "ownerUserPhotoBase64"
                    : field.fieldProperty.indexOf("CreateUser") > -1
                    ? "createUserPhotoBase64"
                    : ""
                let photoBase64 = ""
                if (photoProp != "") {
                  photoBase64 = rowData[photoProp]
                }

                return (
                  <NameAvatar
                    fullName={cellValue}
                    showName={true}
                    base64Image={photoBase64}
                  />
                )
              }
            })
          } else {
            col.formatter = cell => {
              let item = field.listItems.find(
                x => x.listItemID == cell.getValue()
              )

              return item == undefined ? "" : item.listItemName
            }
          }
          cols.push(col)
        } else if (field.fieldType == "ReadOnly") {
          let col = {
            title: field.fieldLabel,
            field: prop,
            minWidth: 150,
            formatter: cell => {
              return cell.getValue()
            },
          }
          if (field.fieldProperty.toLowerCase().indexOf("user") > -1) {
            col.formatter = customFormatter(({ rowData, cellValue }) => {
              const photoProp =
                field.fieldProperty.indexOf("OwnerUser") > -1
                  ? "ownerUserPhotoBase64"
                  : field.fieldProperty.indexOf("CreateUser") > -1
                  ? "createUserPhotoBase64"
                  : ""
              let photoBase64 = ""
              if (photoProp != "") {
                photoBase64 = rowData[photoProp]
              }

              return (
                <NameAvatar
                  fullName={cellValue}
                  showName={true}
                  base64Image={photoBase64}
                />
              )
            })
          }
          cols.push(col)
        } else if (
          field.fieldType == "ShortText" ||
          field.fieldType == "number"
        ) {
          cols.push({
            title: field.fieldLabel,
            field: prop,
            minWidth: 150,
            formatter: cell => {
              return cell.getValue()
            },
          })
        } else if (field.fieldType == "LongText") {
          cols.push({
            title: field.fieldLabel,
            field: prop,
            minWidth: 250,
            formatter: customFormatter(({ rowData, cellValue }) => (
              <Tooltip title={<h6>{cellValue}</h6>}>
                <span>{cellValue}</span>
              </Tooltip>
            )),
          })
        }
      })

    setColumns(cols)

    return type
  }

  const loadTasks = async taskType => {
    setIsLoading(true)
    let tsks = await api.getGeneralTasks(
      taskTypeId,
      alternative ? "Alternatives" : "Scenario",
      alternative ? alternative.alternativeID : scenarioId,
      // alternative
      //   ? alternative.revisionAltID > 0 && taskTypeId == 1
      //     ? alternative.revisionAltID
      //     : alternative.alternativeID
      //   : scenarioId,
      currentUser.userID
    )
    if (taskType.renderAsWorkflow) {
      tsks = tsks.map(t => {
        let completeDate = null
        if (t.startDT == null) {
          t.daysOpen = null
        } else if (t.completeDT == null) {
          completeDate = moment(new Date())
          t.daysOpen = moment(new Date()).diff(moment(t.startDT), "day")
        } else {
          completeDate = moment(t.completeDT)
          t.daysOpen = moment(t.completeDT).diff(moment(t.startDT), "day")
        }

        if (t.dueDT != null && completeDate != null) {
          if (completeDate > moment(t.dueDT)) t.daysOpenColor = "red"
          else t.daysOpenColor = "green"
        } else if (t.dueDT == null) {
          t.daysOpenColor = "black"
        }

        return t
      })
    }

    tsks = tsks.filter(x => x.userEditRight != "hide")
    tsks = await loadAvatars(tsks, taskType)

    setTasks(tsks)
    setIsLoading(false)
    if (taskType.showWidgets) {
      await loadWidgets()
    }
  }

  const loadAvatars = async (tsks, tskType) => {
    let userIds = []
    if (
      tskType.taskTypeFields.find(
        x => x.fieldProperty.indexOf("OwnerUser") > -1
      ) != undefined
    ) {
      userIds = tsks.map(x => x.ownerUserID)
    }
    if (
      tskType.taskTypeFields.find(
        x => x.fieldProperty.indexOf("CreateUser") > -1
      ) != undefined
    ) {
      userIds = [...userIds, ...tsks.map(x => x.createUserID)]
    }

    if (userIds.length > 0) {
      let avatars = await loadUsersAvatars(userIds)
      tsks.forEach(t => {
        let ownerUserPhoto = avatars.find(x => x.userID == t.ownerUserID)
        t.ownerUserPhotoBase64 =
          ownerUserPhoto == undefined ? "" : ownerUserPhoto.photoBase64
        let createUserPhoto = avatars.find(x => x.userID == t.createUserID)
        t.createUserPhotoBase64 =
          createUserPhoto == undefined ? "" : createUserPhoto.photoBase64
      })
    }

    return tsks
  }

  const loadWidgets = async () => {
    let pv = ""
    if (taskTypeId == TaskTypes.risk || taskTypeId == TaskTypes.portfolioRisk) {
      pv = "ManageRisks"
    }
    if (
      taskTypeId == TaskTypes.issue ||
      taskTypeId == TaskTypes.portfolioIssue
    ) {
      pv = "ManageIssues"
    }
    if (pv !== "") {
      let w = await api.getWidgetsByView(pv, scenarioId)
      setWidgets(w)
    }
  }

  const editTask = (e, task) => {
    e && e.preventDefault()
    setTaskToEdit(task)
    toggleEditModal()
  }

  const addTask = taskTypeId => {
    setTaskToEdit({ ...newTask, taskTypeID: taskTypeId })
    toggleEditModal()
  }

  const handleAltPress = (e, alt) => {
    e && e.preventDefault()
    props.history.push(`/editProject/${alt.scenarioID}/${alt.alternativeID}`)
  }

  const renderTable = (tasksToRender, tabulatorRef) => {
    return (
      <ReactTabulator
        onRef={r => (tabulatorRef.current = r.current)}
        columns={columns}
        data={tasksToRender}
        options={{
          pagination: "local",
          paginationSize: 30,
          paginationSizeSelector: [5, 10, 20, 30, 50, 100, 200, 300],
          layout: columns.length > 6 ? "fitDataStretch" : "fitColumns",
        }}
      />
    )
  }

  const toggleFilters = tabulatorRef => {
    tabulatorRef.current.element.classList.remove("show-filters")
    tabulatorRef.current.element.classList.remove("hide-filters")
    tabulatorRef.current.element.classList.add(
      !showFilters ? "show-filters" : "hide-filters"
    )
    setShowFilters(!showFilters)
  }

  const renderButtons = (addTaskFunc, tabulatorRef, addbuttonText) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {!taskType.renderAsWorkflow && (
            <button
              className="btn btn-sm btn-primary mb-3"
              style={{ marginTop: "10px" }}
              onClick={addTaskFunc}
            >
              {addbuttonText}
            </button>
          )}
        </div>
        <div>
          {renderAsTable && (
            <button
              onClick={() => toggleFilters(tabulatorRef)}
              className="btn btn-sm btn-outline-primary save-user"
            >
              {showFilters ? "Hide" : "Show"} <i className="fas fa-search"></i>{" "}
              <i className="fas fa-filter"></i>
            </button>
          )}
        </div>
      </div>
    )
  }

  const renderTasks = (tasksToRender, tabulatorRef) => {
    if (taskType.renderAsWorkflow) {
      return (
        <>
          {tasksToRender.map((t, idx) => (
            <>
              <div
                // className="box"
                key={idx}
                style={{
                  cursor: "pointer",
                  width: "100%",
                  border: "1px solid lightgray",
                  borderRadius: "10px",
                  // marginTop: "28px",
                  padding: "5px",
                  background:
                    t.userEditRight == "listonly" ||
                    t.userEditRight == "readonly"
                      ? "lightgray"
                      : "initial",
                }}
                onClick={() => editTask(undefined, t)}
              >
                <div
                  style={{
                    marginBottom: "5px",
                  }}
                >
                  <h5 style={{ marginBottom: "0px" }}>
                    {t.userEditRight == null ||
                    t.userEditRight == "full" ||
                    t.userEditRight == "readonly" ? (
                      <b>
                        <a href="#" onClick={e => editTask(e, t)}>
                          {t.taskName}
                        </a>
                      </b>
                    ) : (
                      <b>{t.taskName}</b>
                    )}
                  </h5>
                </div>
                <p style={{ marginBottom: "5px" }}>
                  Owner:{" "}
                  <span style={{ fontWeight: "600" }}>{t.ownerUser}</span>
                </p>
                <div
                  style={{ marginBottom: "5px" }}
                  dangerouslySetInnerHTML={{
                    __html: t.taskStatus,
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p style={{ marginBottom: 0 }}>
                    Due:{" "}
                    <span style={{ fontWeight: "600" }}>
                      {t.dueDT ? moment(t.dueDT).format("MM/DD/YYYY") : ""}
                    </span>{" "}
                    Completed:{" "}
                    <span style={{ fontWeight: "600" }}>
                      {t.completeDT
                        ? moment(t.completeDT).format("MM/DD/YYYY")
                        : ""}
                    </span>
                  </p>
                  {t.daysOpen && (
                    <p
                      style={{
                        fontWeight: 600,
                        fontSize: 14,
                        color: t.daysOpenColor ? t.daysOpenColor : "black",
                      }}
                    >
                      {t.daysOpen} day(s)
                    </p>
                  )}
                </div>
              </div>
              {tasksToRender.length > idx + 1 && (
                <i
                  className="fas fa-arrow-down"
                  style={{ fontSize: "30px", marginLeft: "45%" }}
                ></i>
              )}
            </>
          ))}
        </>
      )
    } else {
      const taskPhaseIdField = taskType.taskTypeFields.find(
        x => x.fieldProperty == "TaskPhaseID"
      )

      return (
        <>
          {!renderAsTable &&
            tasksToRender.length > 0 &&
            tasksToRender.map((t, idx) => {
              return (
                <div
                  key={idx}
                  style={{
                    width: "100%",
                    cursor: "pointer",
                    borderBottom: "1px solid lightgray",
                    marginTop: "15px",
                  }}
                  onClick={() => editTask(undefined, t)}
                >
                  <div
                    style={{
                      marginBottom: "5px",
                    }}
                  >
                    <h5 style={{ marginBottom: "0px" }}>
                      {t.userEditRight == null ||
                      t.userEditRight == "full" ||
                      t.userEditRight == "readonly" ? (
                        <b>
                          <a href="#" onClick={e => editTask(e, t)}>
                            {taskType !== null && taskType.taskNumLabel}{" "}
                            {t.taskNum}
                          </a>
                        </b>
                      ) : (
                        <b>
                          {taskType !== null && taskType.taskNumLabel}{" "}
                          {t.taskNum}
                        </b>
                      )}
                      {/* </h5>
                    <h5 style={{ marginBottom: "0px" }}> */}
                      &nbsp;
                      <b>
                        <a href="#" onClick={e => editTask(e, t)}>
                          {t.taskName}
                        </a>
                      </b>
                    </h5>
                  </div>
                  <p style={{ marginBottom: "5px" }}>
                    {t.createUser != null &&
                      t.createUser != "" &&
                      `Created by: ${t.createUser}`}{" "}
                    {t.ownerUser != null &&
                      t.ownerUser != "" &&
                      `Owner: ${t.ownerUser}`}
                  </p>
                  <div className="d-flex-row ai-center" style={{ gap: "10px" }}>
                    <div
                      style={{ marginBottom: "5px" }}
                      dangerouslySetInnerHTML={{
                        __html: t.taskStatus,
                      }}
                    ></div>
                    {taskPhaseIdField != undefined && (
                      <span>
                        {taskPhaseIdField.fieldLabel} -{" "}
                        {
                          taskPhaseIdField.listItems.find(
                            x => x.listItemID == t.taskPhaseID
                          )?.listItemName
                        }
                      </span>
                    )}
                  </div>
                </div>
              )
            })}
          <div className={showFilters ? "show-filters" : "hide-filters"}>
            {renderAsTable && renderTable(tasksToRender, tabulatorRef)}
          </div>
        </>
      )
    }
  }

  const showTable = () => {
    let addFunc,
      title,
      addText = null

    addFunc = () => addTask(taskTypeId)
    addText = `+ Add ${taskType.taskTypeName}`
    title = `${taskType.taskTypeName}`

    return (
      <Row className="mb-3">
        <Col>
          {/* {!renderAsTable && ( */}
          <h5 className="mt-3">
            <b>{title}</b>
          </h5>
          {/* )} */}
          {scenarioId > 0 && taskType.showWidgets && showWidgets && (
            <Widgets widgets={widgets} marginTop="0px" />
          )}
          {renderButtons(addFunc, tabulatorRef, addText)}
          {tasks.length > 0 && renderTasks(tasks, tabulatorRef)}
        </Col>
      </Row>
    )
  }

  return (
    <React.Fragment>
      {taskTypeId !== undefined &&
        columns.length > 0 &&
        taskType != null &&
        showTable()}
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      <EditTaskModal
        isEditModalVisible={isEditModalVisible}
        toggleEditModal={toggleEditModal}
        taskToEdit={taskToEdit}
        setTaskToEdit={setTaskToEdit}
        alternatives={alternatives}
        renderAsTable={renderAsTable}
        reload={() => loadTasks(taskType)}
        flatTaskList={tasks}
      />
    </React.Fragment>
  )
}

export default TaskDrawer
