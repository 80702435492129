import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Col, Row } from "reactstrap"
import api from "services/api.service"
import { authenticationService } from "services/authentication.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import logo from "../../../assets/images/defprologo1.png"

export const getMode = (scenario, participant, mode) => {
  if (scenario.pairwiseStatusID == 110 && scenario.alternativeStatusID == 110) {
    return {
      showStart: false,
      text: "This model is not open for scoring.  If you have questions, contact your decision manager",
    }
  } else if (participant.pwcTypeID == 0 && participant.altTypeID == 0) {
    return {
      showStart: false,
      text: "Your account does not have rights to score this model.  If you have questions, contract your decision manager",
    }
  } else if (scenario.pairwiseStatusID == 1 && participant.pwcTypeID == 0) {
    return {
      showStart: false,
      text: "Your account does not have rights to score the model at this time. If you have questions, contract your decision manager",
    }
  } else if (
    scenario.pairwiseStatusID == 1 &&
    participant.pwcTypeID == 1 &&
    (!mode || mode == 1)
  ) {
    return {
      showStart: true,
      text: "Please wait for the facilitator to give an instruction to begin.",
    }
  } else if (
    scenario.pairwiseStatusID == 1 &&
    participant.pwcTypeID == 2 &&
    (!mode || mode == 2)
  ) {
    return {
      showStart: true,
      text: "Please click “Start” to begin providing your judgments.<br> You may proceed at your own pace.",
    }
  } else if (scenario.alternativeStatusID == 1 && participant.altTypeID == 0) {
    return {
      showStart: false,
      text: "Your account does not have rights to score the alternatives at this time. If you have questions, contract your decision manager",
    }
  } else if (
    scenario.alternativeStatusID == 1 &&
    participant.altTypeID == 1 &&
    (!mode || mode == 1)
  ) {
    return {
      showStart: true,
      text: "Your account is setup to score the alternatives in ‘Group Mode’  - once you start you will need to wait for the facilitator to move forward",
    }
  } else if (
    scenario.alternativeStatusID == 1 &&
    participant.altTypeID == 2 &&
    (!mode || mode == 2)
  ) {
    return {
      showStart: true,
      text: "Your account is setup to score the alternatives in ‘Individual Mode’  - tab start to continue and evaluate the alternatives.",
    }
  } else {
    return {
      showStart: false,
      text: "You cannot score the model at this time. If you have questions, contract your decision manager.",
    }
  }
}

const MobileLogin = props => {
  const [participant, setParticipant] = useState(null)
  const [scenario, setScenario] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setIsLoading(true)
    let pId = window.location.href.split("?usid=")[1]
    let p = await api.getParticipant(pId)
    setParticipant(p)
    if (p != null && p.user != null) {
      //authenticate
      authenticationService.login(p.user.email, p.user.password).then(
        async user => {
          let s = await api.getScenario(p.scenarioID)
          setScenario(s)
          setIsLoading(false)
        },
        error => {
          setIsLoading(false)
        }
      )
    } else {
      let s = await api.getScenario(p.scenarioID)
      setScenario(s)
      setIsLoading(false)
    }
  }

  const start = () => {
    if (scenario.pairwiseStatusID == 1) {
      props.history.push(
        `/mobile/objective/${scenario.scenarioID}/${participant.pwcTypeID}`
      )
    } else {
      props.history.push(
        `/mobile/alternative/${scenario.scenarioID}/${participant.altTypeID}`
      )
    }
  }

  return (
    <React.Fragment>
      <div style={{ overflow: "hidden", color: "white" }}>
        <Row
          className="background-gradient"
          style={{ height: "100vh", display: "flex", justifyContent: "center" }}
        >
          <Col
            md="6"
            sm="12"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              textAlign: "center",
            }}
          >
            <img src={logo} alt="" style={{ width: "70%", height: "auto" }} />
            {isLoading && (
              <Loader
                type="line-scale-pulse-out"
                color={colors.primary}
                style={{ textAlign: "center" }}
              />
            )}
            {!isLoading && participant != null && scenario != null && (
              <div>
                <div className="mb-4">
                  <p className="mb-0">Decision Management</p>
                  <p>Collaborate. Prioritize. Optimize.</p>
                </div>
                <div className="mb-4">
                  <h3>
                    <b>
                      Definitive Pro welcomes <br></br>
                      {participant &&
                        participant.user &&
                        `${participant.user.firstName} ${participant.user.lastName}`}
                    </b>
                  </h3>
                </div>
                {getMode(scenario, participant).showStart ? (
                  <div className="mb-4">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: getMode(scenario, participant).text,
                      }}
                    >
                      {/* {getMode(scenario, participant).text} */}
                    </p>
                    {/* <p className="mb-0">
                      Please click Start to begin providing your judgments.
                    </p>
                    <p>You may proceed at your own pace.</p> */}
                  </div>
                ) : (
                  <div className="mb-4">
                    <p>{getMode(scenario, participant).text}</p>
                  </div>
                )}
              </div>
            )}
            {participant != null &&
              scenario != null &&
              getMode(scenario, participant).showStart && (
                <div>
                  <button className="btn btn-primary btn-lg" onClick={start}>
                    Start
                  </button>
                </div>
              )}
            <div>
              <p className="mb-0">
                U.S. Patent Nos. 10,268,977; 10,366,361; 10,417,590
              </p>
              <p>All Rights Reserved</p>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default MobileLogin
