import React, { useEffect, useState } from "react"
import { Col, Label, Row } from "reactstrap"
import { ChromePicker } from "react-color"
import api from "services/api.service"

const BrandingSection = ({
  enterprise,
  changeEnterpriseProp,
  shouldSave,
  saveEnterprise,
}) => {
  const [imgSrc, setImgSrc] = useState(null)
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [file, setFile] = useState(null)

  useEffect(() => {
    if (enterprise.logoPath != null && enterprise.logoPath != "") {
      setImgSrc(enterprise.logoPath)
    }
  }, [])

  useEffect(() => {
    if (shouldSave) {
      handleSave()
    }
  }, [shouldSave])

  const handleSave = async () => {
    await saveEnterprise()

    if (file != null) {
      await api.uploadEnterpriseLogo(enterprise.enterpriseID, file)
    }
  }

  const onFileChange = event => {
    let f = event.target.files[0]
    setFile(f)

    var reader = new FileReader()
    var url = reader.readAsDataURL(f)

    reader.onloadend = function (e) {
      setImgSrc([reader.result])
    }
    // if (file) {
    //   let data = new FormData()
    //   data.append("file", file)
    //   // axios.post('/files', data)...
    // }
  }

  const handlePickerButtonClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  }

  const handleClose = () => {
    setDisplayColorPicker(false)
  }

  const handleColorChange = col => {
    changeEnterpriseProp("headerColor", col.hex)
  }

  const removeLogo = () => {
    setFile(null)
    setImgSrc(null)
    changeEnterpriseProp("logoPath", "")
  }

  const popover = {
    position: "absolute",
    zIndex: "2",
  }
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  }

  const colorStyle = {
    width: "100%",
    height: "23px",
    borderRadius: "2px",
    backgroundColor:
      enterprise.headerColor != null && enterprise.headerColor != ""
        ? enterprise.headerColor
        : "white",
  }

  const swatch = {
    padding: "5px",
    background: "#fff",
    borderRadius: "1px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "inline-block",
    cursor: "pointer",
  }

  return (
    <React.Fragment>
      {enterprise != null && (
        <Row>
          <Col sm="6">
            <div
                style={{
                  height: "600px",
                }} >
          
            {/* <div className="mb-3">
              <Label className="form-label">Banner Color</Label>
              <div
                style={swatch}
                className="form-control"
                onClick={handlePickerButtonClick}
              >
                <div style={colorStyle} />
              </div>
              {displayColorPicker ? (
                <div style={popover}>
                  <div style={cover} onClick={handleClose} />
                  <ChromePicker
                    color={enterprise.headerColor}
                    onChangeComplete={handleColorChange}
                  />
                </div>
              ) : null}
            </div> */}

            {imgSrc != null && imgSrc != "" && (
              <div
                style={{
                  width: "100%",
                  height: "200px",
                  backgroundColor: enterprise.headerColor,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={imgSrc} style={{ maxHeight: "200px" }} />
              </div>
            )}
            <div style={{ marginTop: "10px" }}>
              <Label
                className="btn btn-primary"
                style={{ marginBottom: "0px", fontWeight: "bold" }}
              >
                <input
                  type="file"
                  name="myFile"
                  onChange={onFileChange}
                  style={{ display: "none" }}
                />
                <i className="fas fa-image"></i> Change Logo
              </Label>
              <button
                className="btn btn-outline-danger save-user"
                onClick={removeLogo}
              >
                <i className="fas fa-times"></i> Remove Logo
              </button>
            </div>
            </div>
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}

export default BrandingSection
