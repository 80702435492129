import React, { Component, useEffect } from "react"
import { Button, Col, Container, Row } from "reactstrap"
import { mainApiUrl } from "services/api.service"
import ShareTelerikReportModal from "./ShareTelerikReportModal"
import RunTelerikUserReportModal from "components/GlobalModals.js/RunTelerikUserReportModal"

class ReportDesigner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showShareReportModal: false,
    }
  }

  toggleShareReportModal = () => {
    this.setState({ showShareReportModal: !this.state.showShareReportModal })
  }

  UNSAFE_componentWillMount() {
    let data = JSON.parse(localStorage.getItem("currentData"))

    $.ajaxSetup({
      headers: { Authorization: "Bearer " + data.access_token },
    })

    const fetchOverride = window.fetch
    window.fetch = function (url, args) {
      if (!args) {
        args = {
          headers: {
            Authorization: "Bearer " + data.access_token,
          },
        }
      } else if (!args.headers) {
        args.headers = {
          Authorization: "Bearer " + data.access_token,
        }
      } else {
        if (url.indexOf("reportdesigner/reports/save") > -1)
          args.headers.append("Authorization", "Bearer " + data.access_token)
        else
          args.headers = {
            ...args.headers,
            Authorization: "Bearer " + data.access_token,
          }
      }

      // Intercept the parameter here
      return fetchOverride(url, args)
    }
  }

  componentDidMount() {
    function onViewerInitializing(e, args) {
      let data = JSON.parse(localStorage.getItem("currentData"))
      args.reportViewerOptions.authenticationToken = data.access_token
    }

    window
      .jQuery("#reportDesigner1")
      .telerik_WebReportDesigner({
        persistSession: false,
        toolboxArea: {
          layout: "list", //Change to "grid" to display the contents of the Components area in a flow grid layout.
        },
        serviceUrl: `${mainApiUrl}/api/reportdesigner/`,
        // report: "Barcodes report.trdx",
        viewerInitializing: onViewerInitializing,
      })
      .data("telerik_WebDesigner")
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid style={{height:'75vh', textAlign: 'right', padding: '10px'}}>
            <Button color="primary" onClick={this.toggleShareReportModal}>
              <i className="fas fa-share"></i>
              {" "}Share Reports
            </Button>
            <div id="reportDesigner1" className="webReportArea"></div>
          </Container>
        </div>
        <ShareTelerikReportModal
          isOpen={this.state.showShareReportModal}
          toggleModal={this.toggleShareReportModal}
        />
        
      </React.Fragment>
    )
  }
}

export default ReportDesigner
