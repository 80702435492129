import DocumentDrawer from "pages/Projects/EditProject/Drawer/DocumentDrawer"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { changeNavbarParams, loadPageItem } from "store/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import download from "downloadjs"
import useModal from "hooks/useModalHook"
import {
  swalWithConfirmAndCancelButtons,
  swalWithConfirmButton,
} from "components/custom/swal"

const ManageGptFiles = props => {
  const dispatch = useDispatch()
  const currentUser = userService.getLoggedInUser()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const { show: showFileModal, toggle: toggleFileModal } = useModal()
  const [documents, setDocuments] = useState([])

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: 0,
        alternativeId: 0,
        viewName: "manageGptFiles",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: 0,
        viewName: "manageGptFiles",
      })
    )
    loadData()
  }, [])

  const loadData = async () => {
    let docs = await api.getGptDocuments(currentUser.enterpriseID)
    setDocuments(docs)
  }

  const deleteDocument = async doc => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this document?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteGptDocument(doc.documentID)
          loadData()
        }
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <Col>
                      <div className="d-flex-row w-100 jc-space-between ai-center">
                        <button
                          className="btn btn-primary"
                          onClick={toggleFileModal}
                        >
                          Add file
                        </button>
                      
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" xs="12">
                      <table
                        className="table table-bordered low-padding-table"
                        style={{ backgroundColor: "white" }}
                      >
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Type</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {documents.map((d, idx) => (
                            <tr key={idx}>
                              <td>
                                <a
                                  href={d.docPath}
                                  download=""
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <b>{d.docTitle}</b>
                                </a>
                              </td>
                              <td>{d.docType}</td>
                              <td>
                                <i
                                  className="fas fa-trash"
                                  style={{ cursor: "pointer", color: "red" }}
                                  onClick={() => deleteDocument(d)}
                                ></i>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <UploadGptFileModal
        isOpen={showFileModal}
        toggleModal={toggleFileModal}
        reload={loadData}
      />
    </React.Fragment>
  )
}

const UploadGptFileModal = ({ isOpen, toggleModal, reload }) => {
  const currentUser = userService.getLoggedInUser()
  const [file, setFile] = useState(null)

  useEffect(() => {
    if (isOpen) {
      //   setTitle("")
      setFile(null)
    }
  }, [isOpen])

  const onFileChange = event => {
    let f = event.target.files[0]
    setFile(f)
  }

  const save = async () => {
    if (file != null) {
      await api.uploadGptDocument(currentUser.enterpriseID, file)
      toggleModal()
      reload && reload()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Add GPT File
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {/* <div className="mb-3">
          <Label className="form-label">Title</Label>
          <Input
            name="note"
            type="text"
            onChange={e => setTitle(e.target.value)}
            value={title}
          />
        </div> */}
        <div className="mb-3">
          <Label
            className="btn btn-primary"
            style={{ marginBottom: "0px", fontWeight: "bold" }}
          >
            <input
              type="file"
              name="myFile"
              onChange={onFileChange}
              style={{ display: "none" }}
            />
            Upload File
          </Label>
          {file != null && (
            <span style={{ marginLeft: "10px" }}>{file.name}</span>
          )}
        </div>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default ManageGptFiles
