import { TextareaAutosize } from "@material-ui/core"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import { getYears } from "./EditEntityModal"
import UploadDocumentModal from "./UploadDocumentModal"

const EditDocumentsModal = ({ isOpen, toggleModal, entityToEdit }) => {
  const currentUser = userService.getLoggedInUser()
  const [documents, setDocuments] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedYear, setSelectedYear] = useState(0)
  const years = getYears()

  const [showUploadModal, setShowUploadModal] = useState(false)
  const toggleUploadModal = () => setShowUploadModal(!showUploadModal)

  useEffect(() => {
    if (isOpen) {
      setDocuments([])
      setSelectedYear(0)
      setIsLoading(true)
      setTimeout(() => {
        loadData(0)
      }, 1000)
    }
  }, [isOpen])

  const loadData = async year => {
    let data = await api.getEntityDocs(entityToEdit.entityID, year)
    setDocuments(data)
    setIsLoading(false)
  }

  const deleteDocument = doc => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this document?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteEntityDocument(doc.documentID)
          loadData(selectedYear)
        }
      })
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {entityToEdit != null && entityToEdit.entityName} Documents
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <Label className="form-label">Years</Label>
          <select
            className="form-control form-select select2 mb-xxl-0"
            value={selectedYear}
            onChange={e => {
              setSelectedYear(e.target.value)
              loadData(e.target.value)
            }}
          >
            <option value="0">General</option>
            {years.map((x, idx) => (
              <option key={idx} value={x}>
                {x}
              </option>
            ))}
          </select>
        </div>
        <button className="btn btn-primary mb-3" onClick={toggleUploadModal}>
          Upload
        </button>
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        {documents.length > 0 && (
          <table
            className="table table-bordered lower-padding-table"
            style={{ backgroundColor: "white" }}
          >
            <thead>
              <tr>
                <th>Year</th>
                <th>Document</th>
                <th>Document Type</th>
                <th>User</th>
                <th>Date</th>
                <th></th>
              </tr>
            </thead>
            {documents.map((d, idx) => (
              <tr key={idx}>
                <td>{d.yearID}</td>
                <td>
                  <a
                    href={d.docPath}
                    download=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    {d.isLink && (
                      <>
                        <i className="fas fa-external-link-alt"></i>{" "}
                      </>
                    )}{" "}
                    <b>{d.docTitle}</b>
                  </a>
                </td>
                <td>{d.docType}</td>
                <td>
                  {d.firstName} {d.lastName}
                </td>
                <td>
                  {new Date(d.docDT) > new Date(1900, 1, 1, 0, 0, 0, 0)
                    ? moment(d.docDT).format("YYYY-MM-DD")
                    : ""}
                </td>
                <td>
                  <i
                    className="fas fa-trash"
                    style={{ cursor: "pointer", color: "red" }}
                    onClick={() => deleteDocument(d)}
                  ></i>
                </td>
              </tr>
            ))}
          </table>
        )}
      </div>
      {entityToEdit != null && (
        <UploadDocumentModal
          isOpen={showUploadModal}
          toggleModal={toggleUploadModal}
          reloadData={() => loadData(selectedYear)}
          entityId={entityToEdit.entityID}
          yearId={selectedYear}
        />
      )}
    </Modal>
  )
}

export default EditDocumentsModal
