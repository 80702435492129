import { Tooltip } from "@material-ui/core"
import AltGroupConsensusRatingModal from "pages/AltScoreConsensus/AltGroupConsensusRatingModal"
import React, { useEffect, useState } from "react"
import { Input, Row } from "reactstrap"
import AltGroupRatingModal from "../AltScoreResults/AltGroupRatingModal"

const SelectableAlternativeRatingsGrid = ({
  data,
  setData,
  dropdownSelection,
  objectives,
  objectivesAndMeasures,
  ratingModal,
}) => {
  let [allSelected, setAllSelected] = useState(false)
  let [showRagingModal, setShowRatingModal] = useState(false)

  const showAllItems = e => {
    e && e.preventDefault()
    let dataCopy = [...data]
    setData(
      dataCopy.map(x => {
        x.isVisible = true
        return x
      })
    )
  }

  const hideSelectedItems = e => {
    e && e.preventDefault()
    let dataCopy = [...data]
    setData(
      dataCopy.map(x => {
        if (x.isSelected) {
          x.isVisible = false
        } else {
          x.isVisible = true
        }
        return x
      })
    )
  }

  const toggleRatingModal = e => {
    e && e.preventDefault()
    setShowRatingModal(!showRagingModal)
  }

  return (
    <>
      <Row>
        <div>
          <div>
            <a href="#" onClick={e => showAllItems(e)}>
              <i className="fas fa-eye"></i> Show all items
            </a>
          </div>
          <div>
            <a href="#" onClick={e => hideSelectedItems(e)}>
              <i className="fas fa-times"></i> Hide selected items
            </a>
          </div>
          <div>
            <a href="#" onClick={e => toggleRatingModal(e)}>
              <i className="fas fa-info-circle"></i>{" "}
              {ratingModal == AltGroupRatingModal
                ? "Objective and Measure Group Ratings"
                : "Consensus Ratings"}
            </a>
          </div>
        </div>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <table
          className="table table-bordered low-padding-table"
          style={{ backgroundColor: "white" }}
        >
          <thead>
            <tr>
              <th style={{ width: "50px" }}>
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id={"check-all"}
                  value={allSelected}
                  onChange={e => {
                    let dataCopy = [...data]
                    dataCopy.forEach(d => {
                      d.isSelected = !allSelected
                    })
                    setData(dataCopy)
                    setAllSelected(!allSelected)
                  }}
                />
              </th>
              <th style={{ width: "70px" }}>ID</th>
              <th>Project</th>
              <th>Version</th>
              {dropdownSelection.value == 0 &&
                objectives.map((o, i) => {
                  return <th key={i}>{o.objectiveName}</th>
                })}
              {dropdownSelection.value == -99 &&
                objectivesAndMeasures.map((o, i) => {
                  if (o.measureID != undefined) {
                    return <th key={i}>{o.measureName}</th>
                  }
                  return <th key={i}>{o.objectiveName}</th>
                })}
              {dropdownSelection.value > 0 &&
                objectivesAndMeasures
                  .filter(
                    x =>
                      x.measureID != undefined &&
                      x.objectiveID == dropdownSelection.value
                  )
                  .map((m, i) => {
                    return <th key={i}>{m.measureName}</th>
                  })}
              <th style={{ width: "150px" }}>Benefit Score</th>
              <th style={{ width: "150px" }}>Group rating</th>
            </tr>
          </thead>
          <tbody>
            {data
              .filter(x => x.isVisible)
              .map((a, idx) => (
                <tr key={idx}>
                  <td>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`check-${idx}`}
                      checked={a.isSelected}
                      onClick={e => {
                        let dataCopy = [...data]
                        let alt = dataCopy.find(
                          x => x.alternativeNum == a.alternativeNum
                        )
                        alt.isSelected = !alt.isSelected
                        setData(dataCopy)
                      }}
                    />
                  </td>
                  <td>{a.alternativeNum}</td>
                  <td className="one-line-elipsis">
                    <Tooltip title={<h6>{a.alternative}</h6>}>
                      <span>
                        {" "}
                        <b>{a.alternative}</b>
                      </span>
                    </Tooltip>
                  </td>
                  <td>
                    <div style={{textAlign:'center'}}
                      dangerouslySetInnerHTML={{
                        __html: a.version,
                      }}
                    ></div>
                  </td>
                  {dropdownSelection.value == 0 &&
                    objectives.map((o, i) => {
                      return (
                        <td key={i} style={{ textAlign: "center" }}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                a[
                                  o.objectiveName
                                    .toLowerCase()
                                    .replaceAll(",", "")
                                    .replaceAll('"', "")
                                    .replaceAll("'", "")
                                ],
                            }}
                          ></div>
                        </td>
                      )
                    })}
                  {dropdownSelection.value == -99 &&
                    objectivesAndMeasures.map((o, i) => {
                      if (o.measureID != undefined) {
                        return (
                          <td key={i} style={{ textAlign: "center" }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  a[
                                    o.measureName
                                      .toLowerCase()
                                      .replaceAll(",", "")
                                      .replaceAll('"', "")
                                      .replaceAll("'", "")
                                  ],
                              }}
                            ></div>
                          </td>
                        )
                      }
                      return (
                        <td key={i} style={{ textAlign: "center" }}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                a[
                                  o.objectiveName
                                    .toLowerCase()
                                    .replaceAll(",", "")
                                    .replaceAll('"', "")
                                    .replaceAll("'", "")
                                ],
                            }}
                          ></div>
                        </td>
                      )
                    })}
                  {dropdownSelection.value > 0 &&
                    objectivesAndMeasures
                      .filter(
                        x =>
                          x.measureID != undefined &&
                          x.objectiveID == dropdownSelection.value
                      )
                      .map((m, i) => {
                        return (
                          <td key={i} style={{ textAlign: "center" }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  a[
                                    m.measureName
                                      .toLowerCase()
                                      .replaceAll(",", "")
                                      .replaceAll('"', "")
                                      .replaceAll("'", "")
                                  ],
                              }}
                            ></div>
                          </td>
                        )
                      })}
                  <td>
                    <div dangerouslySetInnerHTML={{ __html: a.saaScore }}></div>
                  </td>
                  <td>
                    <div
                      dangerouslySetInnerHTML={{ __html: a.saaRating }}
                    ></div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </Row>
      {ratingModal == AltGroupRatingModal && (
        <AltGroupRatingModal
          isOpen={showRagingModal}
          toggleModal={toggleRatingModal}
        />
      )}
      {ratingModal == AltGroupConsensusRatingModal && (
        <AltGroupConsensusRatingModal
          isOpen={showRagingModal}
          toggleModal={toggleRatingModal}
        />
      )}
    </>
  )
}

export default SelectableAlternativeRatingsGrid
