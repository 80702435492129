import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const CopyProjectsModal = ({
  isOpen,
  toggleModal,
  scenario,
  selectedAlternatives,
  reload,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [otherScenarios, setOtherScenarios] = useState([])
  const [showOtherScenario, setShowOtherScenario] = useState(false)
  const [selectedScenarioId, setSelectedScenarioId] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [checkboxes, setCheckboxes] = useState([])

  const defaultCheckboxes = {
    scores: true,
    resourceEstimatesAndPlans: true,
    tasks: true,
    documents: true,
    locations: true,
    lookbacks: true,
    forecast: true,
    contracts: true,
    newNumber: false,
  }

  useEffect(() => {
    if (isOpen) {
      setCheckboxes({ ...defaultCheckboxes })
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    setSelectedScenarioId(
      scenario !== undefined && scenario !== null ? scenario.scenarioID : 0
    )
    let sc = await api.getScenariosByUser(
      currentUser.userID,
      scenario.scenarioTypeID
    )
    setOtherScenarios(sc)
  }

  const save = async () => {
    setIsSubmitting(true)
    let tasks = selectedAlternatives.map(x => async () => {
      return await api.copyAlternative({
        alternativeID: x.alternativeid,
        newScenarioID: selectedScenarioId,
        userID: currentUser.userID,
        copyScores: checkboxes.scores,
        copyResources: checkboxes.resourceEstimatesAndPlans,
        copyTasks: checkboxes.tasks,
        copyDocs: checkboxes.documents,
        copyLocations: checkboxes.locations,
        copyLookbacks: checkboxes.lookbacks,
        copyForecast: checkboxes.forecast,
        copyContracts: checkboxes.contracts,
        newNumber: checkboxes.newNumber,
        isRevision: false,
      })
    })
    await Promise.all(tasks.map(t => t()))
    toast.success("Projects copied successfuly")
    setIsSubmitting(false)
    toggleModal()
    reload && reload()
  }

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Copy {scenario && scenario.alternativeName}(s)
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <div>
            <input
              className="form-check-input"
              type="radio"
              name="newnumb1"
              id="newnumb1"
              checked={checkboxes.newNumber == true}
              onClick={e => {
                setCheckboxes({ ...checkboxes, newNumber: true })
              }}
            />
            <label
              className="form-check-label"
              htmlFor="newnumb1"
              style={{ marginLeft: "10px" }}
            >
              Create New Number
            </label>
          </div>
          <div>
            <input
              className="form-check-input"
              type="radio"
              name="newnumb2"
              id="newnumb2"
              checked={checkboxes.newNumber == false}
              onClick={e => {
                setCheckboxes({ ...checkboxes, newNumber: false })
              }}
            />
            <label
              className="form-check-label"
              htmlFor="newnumb2"
              style={{ marginLeft: "10px" }}
            >
              Keep Number
            </label>
          </div>
        </div>
        <div className="mb-3">
          <div>
            <input
              className="form-check-input"
              type="radio"
              name="anon1"
              id="anon1"
              checked={!showOtherScenario}
              onClick={e => {
                setShowOtherScenario(false)
                setSelectedScenarioId(
                  scenario !== undefined && scenario !== null
                    ? scenario.scenarioID
                    : 0
                )
              }}
            />
            <label
              className="form-check-label"
              htmlFor="anon1"
              style={{ marginLeft: "10px" }}
            >
              {scenario && scenario.scenarioName}
            </label>
          </div>
          <div>
            <input
              className="form-check-input"
              type="radio"
              name="anon2"
              id="anon2"
              checked={showOtherScenario}
              onClick={e => {
                setShowOtherScenario(true)
                setCheckboxes({
                  ...checkboxes,
                  scores: false,
                  resourceEstimatesAndPlans: false,
                })
              }}
            />
            <label
              className="form-check-label"
              htmlFor="anon2"
              style={{ marginLeft: "10px" }}
            >
              Other
            </label>
          </div>
        </div>
        {showOtherScenario && (
          <div className="mb-3">
            <Label className="form-label">Scenario</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={selectedScenarioId}
              onChange={e => {
                setSelectedScenarioId(e.target.value)
              }}
            >
              <option value="0">Select ...</option>
              {otherScenarios.map((s, i) => (
                <option key={i} value={s.scenarioID}>
                  {s.scenarioName}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="form-check" style={{ marginLeft: "10px" }}>
          <Input
            type="checkbox"
            className="form-check-input"
            id="documents"
            checked={checkboxes.documents}
            onClick={e => {
              setCheckboxes({
                ...checkboxes,
                documents: !checkboxes.documents,
              })
            }}
          />
          <Label className="form-check-label" for="documents">
            Documents
          </Label>
        </div>

        <div className="form-check" style={{ marginLeft: "10px" }}>
          <Input
            type="checkbox"
            className="form-check-input"
            id="forecast"
            checked={checkboxes.forecast}
            onClick={e => {
              setCheckboxes({
                ...checkboxes,
                forecast: !checkboxes.forecast,
                contracts:
                  !checkboxes.forecast == false ? false : checkboxes.contracts,
              })
            }}
          />
          <Label className="form-check-label" for="forecast">
            Forecast
          </Label>
        </div>
        {checkboxes.forecast && (
          <div className="form-check" style={{ marginLeft: "40px" }}>
            <Input
              type="checkbox"
              className="form-check-input"
              id="contracts"
              checked={checkboxes.contracts}
              onClick={e => {
                setCheckboxes({
                  ...checkboxes,
                  contracts: !checkboxes.contracts,
                })
              }}
            />
            <Label className="form-check-label" for="contracts">
              Contracts
            </Label>
          </div>
        )}

        <div className="form-check" style={{ marginLeft: "10px" }}>
          <Input
            type="checkbox"
            className="form-check-input"
            id="locations"
            checked={checkboxes.locations}
            onClick={e => {
              setCheckboxes({
                ...checkboxes,
                locations: !checkboxes.locations,
              })
            }}
          />
          <Label className="form-check-label" for="locations">
            Locations
          </Label>
        </div>

        <div className="form-check" style={{ marginLeft: "10px" }}>
          <Input
            type="checkbox"
            className="form-check-input"
            id="lookbacks"
            checked={checkboxes.lookbacks}
            onClick={e => {
              setCheckboxes({
                ...checkboxes,
                lookbacks: !checkboxes.lookbacks,
              })
            }}
          />
          <Label className="form-check-label" for="lookbacks">
            Lookbacks
          </Label>
        </div>

        {scenario && scenario.scenarioID == selectedScenarioId && (
          <>
            <div className="form-check" style={{ marginLeft: "10px" }}>
              <Input
                type="checkbox"
                className="form-check-input"
                id="resestimatesplans"
                checked={checkboxes.resourceEstimatesAndPlans}
                onClick={e => {
                  setCheckboxes({
                    ...checkboxes,
                    resourceEstimatesAndPlans:
                      !checkboxes.resourceEstimatesAndPlans,
                  })
                }}
              />
              <Label className="form-check-label" for="resestimatesplans">
                Resource Estimates and Plans
              </Label>
            </div>

            <div className="form-check" style={{ marginLeft: "10px" }}>
              <Input
                type="checkbox"
                className="form-check-input"
                id="scores"
                checked={checkboxes.scores}
                onClick={e => {
                  setCheckboxes({ ...checkboxes, scores: !checkboxes.scores })
                }}
              />
              <Label className="form-check-label" for="scores">
                Scores
              </Label>
            </div>
          </>
        )}

        <div className="form-check" style={{ marginLeft: "10px" }}>
          <Input
            type="checkbox"
            className="form-check-input"
            id="tasks"
            checked={checkboxes.tasks}
            onClick={e => {
              setCheckboxes({
                ...checkboxes,
                tasks: !checkboxes.tasks,
              })
            }}
          />
          <Label className="form-check-label" for="tasks">
            Tasks, Change Requests, Issues and Risks
          </Label>
        </div>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        {isSubmitting && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
          disabled={isSubmitting}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default CopyProjectsModal
