import React, { useEffect, useRef, useState } from "react"
import api from "services/api.service"
import { userService } from "services/user.service"
import useModal from "hooks/useModalHook"
import BusinessRulesModal from "./Modals/BusinessRulesModal"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import Loader from "react-loaders"
import colors from "utils/colors"
import { toast } from "react-toastify"

const BusinessRulesSection = ({ activeTab, scenarioId }) => {
  const currentUser = userService.getLoggedInUser()
  const [columns, setColumns] = useState([])
  const [actionFields, setActionFields] = useState({})
  const [businessRuleToEdit, setBusinessRuleToEdit] = useState({
    businessRuleID: -1,
    ruleModel: "",
    businessRuleName: "",
    rule: {},
    businessRuleActions: [],
  })
  const [busienssRules, setBusinessRules] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const { show: showBusinessRuleModal, toggle: toggleBusinessRuleModal } =
    useModal()

  useEffect(() => {
    if (activeTab == "3") loadData()
  }, [activeTab])

  const loadBusinessRules = async () => {
    let br = await api.getBusinessRules(scenarioId)
    setBusinessRules(br)
  }

  const loadData = async () => {
    setIsLoading(true)
    await loadBusinessRules()
    await loadActionFields()
    let fields = await api.getBusinessRuleTriggerOptions("Alternative")

    let fieldsMap = []
    for (const f of fields) {
      let field = {
        field: f.fieldName,
        label: f.fieldLabel,
      }

      if (f.sourceList == "" && f.fieldType == "int") {
        field.type = "number"
      } else if (f.sourceList !== "") {
        field.type = "string"
      } else if (f.fieldType == "bool") {
        field.type = "boolean"
        field.values = ["Yes", "No"]
      } else {
        field.type = f.fieldType
      }

      if (f.sourceList != "") {
        let parts = f.sourceList.split("/")
        if (parts[0] == "Scenario") {
          let options = await api.getScenarioList(scenarioId, parts[1])
          field.values = options.map(x => x.listItemName)
        } else if (parts[0] == "General") {
          let options = await api.getList(currentUser.userID, parts[1])
          field.values = options.map(x => x.listItemName)
        }
        field.operators = [
          { key: "In", value: "in" },
          { key: "Not in", value: "notin" },
        ]
      }
      fieldsMap.push(field)
    }

    setColumns(fieldsMap)
    setIsLoading(false)
  }

  const loadActionFields = async () => {
    let fields = await api.getBusinessRuleActionFields("Alternative")
    for (const f of fields) {
      if (f.sourceList != "") {
        let parts = f.sourceList.split("/")
        if (parts[0] == "Scenario") {
          let options = await api.getScenarioList(scenarioId, parts[1])
          f.options = options
        } else if (parts[0] == "General") {
          let options = await api.getList(currentUser.userID, parts[1])
          f.options = options
        }
      }
    }

    setActionFields(fields)
  }

  const addBusinessRule = () => {
    setBusinessRuleToEdit({
      businessRuleID: -1,
      ruleModel: "",
      businessRuleName: "",
      rule: {},
      businessRuleActions: [],
    })
    toggleBusinessRuleModal()
  }

  const deleteBusinessRule = businessRule => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this business rule?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteBusinessRule(businessRule.businessRuleID)
          loadBusinessRules()
        }
      })
  }

  const processRules = async () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Running this process will evaluate all items in the portfolio and will make updates per the rules shown below.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Continue",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.processBusinessRulesForScenario(scenarioId)
          toast.success("Business rules processed.")
        }
      })
  }

  return (
    <>
      <div className="d-flex-row jc-space-between ai-center">
        <button className="btn btn-primary mb-3" onClick={addBusinessRule}>
          Add New
        </button>
        <button className="btn btn-primary mb-3" onClick={processRules}>
          Process Rules
        </button>
      </div>
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      {!isLoading && (
        <table
          className="table table-bordered low-padding-table"
          style={{ backgroundColor: "white" }}
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Trigger</th>
              <th>Actions</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {busienssRules.map((b, idx) => (
              <tr key={idx}>
                <td>
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      setBusinessRuleToEdit({
                        ...b,
                        rule: JSON.parse(b.ruleModel),
                      })
                      toggleBusinessRuleModal()
                    }}
                  >
                    {b.businessRuleName}
                  </a>
                </td>
                <td>{b.triggerSQL}</td>
                <td>{b.actionList}</td>
                <td>
                  <i
                    className="fas fa-trash-alt"
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={() => deleteBusinessRule(b)}
                  ></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <BusinessRulesModal
        toggleModal={toggleBusinessRuleModal}
        isOpen={showBusinessRuleModal}
        businessRuleToEdit={businessRuleToEdit}
        setBusinessRuleToEdit={setBusinessRuleToEdit}
        columns={columns}
        scenarioId={scenarioId}
        scope="Alternative"
        reload={loadBusinessRules}
        actionFields={actionFields}
      />
    </>
  )
}

export default BusinessRulesSection
