import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import {
  Breadcrumb,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { changeNavbarParams, loadPageItem } from "store/actions"
import colors from "utils/colors"
import EditFinModelModal from "./Modals/EditFinModelModal"
import EditObjModelModal from "./Modals/EditObjModelModal"
import OptModelSection from "./OptModelSection"

const ObjFin = props => {
  const scenarioId = props.match.params.scenarioId
  const currentUser = userService.getLoggedInUser()
  const [activeTab, setActiveTab] = useState("Criteria Scenarios")
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [isLoading, setIsLoading] = useState(false)

  const [objModels, setObjModels] = useState([])
  const [objModelId, setObjModelId] = useState(-1)

  const [showObjModelModal, setShowObjModelModal] = useState(false)
  const toggleEditObjModelModal = () => setShowObjModelModal(!showObjModelModal)

  const [finModels, setFinModels] = useState([])
  const [finModelId, setFinModelId] = useState(-1)

  const [showFinModelModal, setShowFinModelModal] = useState(false)
  const toggleEditFinModelModal = () => setShowFinModelModal(!showFinModelModal)

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "objFin",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        viewName: "objFin",
      })
    )

    loadData()
  }, [])

  const loadData = async () => {
    setIsLoading(true)
    await loadObjModels()
    await loadFinModels()
    setIsLoading(false)
  }

  const loadObjModels = async () => {
    let om = await api.getObjModels(scenarioId)
    setObjModels(om)
  }

  const loadFinModels = async () => {
    let fm = await api.getFinModels(scenarioId)
    setFinModels(fm)
  }

  const tabs = [
    { text: "Criteria Scenarios" },
    { text: "Financial Scenarios" },
    { text: "Optimization Scenarios" },
  ]

  const editObjModel = async objModelId => {
    setObjModelId(objModelId)
    toggleEditObjModelModal()
  }

  const editFinModel = async finModelId => {
    setFinModelId(finModelId)
    toggleEditFinModelModal()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Nav tabs>
                        {tabs.map((t, idx) => (
                          <NavItem key={idx}>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={activeTab === t.text ? "active" : ""}
                              onClick={() => {
                                setActiveTab(t.text)
                              }}
                            >
                              <b>{t.text}</b>
                            </NavLink>
                          </NavItem>
                        ))}
                      </Nav>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {isLoading && (
                        <Loader
                          type="line-scale-pulse-out"
                          color={colors.primary}
                          style={{ textAlign: "center" }}
                        />
                      )}
                      <TabContent
                        activeTab={activeTab}
                        className="p-3 text-muted"
                      >
                        <TabPane tabId={tabs[0].text}>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="btn btn-primary"
                                onClick={() => editObjModel(-1)}
                              >
                                Add Scenario
                              </button>
                            </Col>
                          </Row>
                          <table
                            className="table table-bordered low-padding-table"
                            style={{ backgroundColor: "white" }}
                          >
                            <thead>
                              <tr>
                                <th>Scenario</th>
                              </tr>
                            </thead>
                            <tbody>
                              {objModels.map((o, idx) => (
                                <tr key={idx}>
                                  <td>
                                    <a
                                      href="#"
                                      onClick={e => {
                                        e.preventDefault()
                                        editObjModel(o.objModelID)
                                      }}
                                    >
                                      {o.objModelName}
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </TabPane>
                        <TabPane tabId={tabs[1].text}>
                          <Row className="mb-3">
                            <Col>
                              <button
                                className="btn btn-primary"
                                onClick={() => editFinModel(-1)}
                              >
                                Add Scenario
                              </button>
                            </Col>
                          </Row>
                          <table
                            className="table table-bordered low-padding-table"
                            style={{ backgroundColor: "white" }}
                          >
                            <thead>
                              <tr>
                                <th>Scenario</th>
                                <th>Cost Factor</th>
                                <th>Factor Year 1</th>
                                <th>Factor Year 2</th>
                                <th>Factor Year 3</th>
                                <th>Factor Year 4</th>
                              </tr>
                            </thead>
                            <tbody>
                              {finModels.map((f, idx) => (
                                <tr key={idx}>
                                  <td>
                                    <a
                                      href="#"
                                      onClick={e => {
                                        e.preventDefault()
                                        editFinModel(f.finModelID)
                                      }}
                                    >
                                      {f.finModelName}
                                    </a>
                                  </td>
                                  <td>{f.costFactor}</td>
                                  <td>{f.yearFactor1}</td>
                                  <td>{f.yearFactor2}</td>
                                  <td>{f.yearFactor3}</td>
                                  <td>{f.yearFactor4}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </TabPane>
                        <TabPane tabId={tabs[2].text}>
                          <OptModelSection
                            activeTab={activeTab}
                            scenarioId={scenarioId}
                          />
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <EditObjModelModal
        isOpen={showObjModelModal}
        toggleModal={toggleEditObjModelModal}
        objModelId={objModelId}
        scenarioId={scenarioId}
        reloadData={loadObjModels}
      />
      <EditFinModelModal
        isOpen={showFinModelModal}
        toggleModal={toggleEditFinModelModal}
        finModelId={finModelId}
        scenarioId={scenarioId}
        reloadData={loadFinModels}
      />
    </React.Fragment>
  )
}

export default ObjFin
