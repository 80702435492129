import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"
import api from "services/api.service"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { changeNavbarParams, loadPageItem } from "store/actions"
import { userService } from "services/user.service"
import useModal from "hooks/useModalHook"
import PwcScoreModal from "./PwcScoreModal"

const PwcScore = props => {
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const currentUser = userService.getLoggedInUser()
  const scenarioId = props.match.params.scenarioId

  const [pwcScores, setPwcScores] = useState([])

  const [pwcScoreData, setPwcScoreData] = useState(null)
  const { show: showScoreModal, toggle: toggleScoreModal } = useModal()

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "performPWC",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        viewName: "performPWC",
      })
    )
    loadData()
  }, [])

  const loadData = async () => {
    let data = await api.getPwcScoresForUsers(currentUser.userID, scenarioId)
    setPwcScores(data)
  }

  const openPwcScoreModal = (participantId, pwcScoreId, level) => {
    setPwcScoreData({
      participantId: participantId,
      pwcScoreId: pwcScoreId,
      level: level,
    })
    toggleScoreModal()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          {pwcScores.length > 0 && (
            <button
              onClick={() =>
                openPwcScoreModal(
                  pwcScores[0].participantID,
                  undefined,
                  undefined
                )
              }
              className="btn btn-primary"
            >
              Enter Judgments
            </button>

          )}

            <Row style={{marginBottom: "30px"}}>
              
              </Row>

          {pwcScores.map((s, idx) => (
            <Row
              key={idx}
              className="mb-3"
              style={{ cursor: "pointer" }}
              onClick={() =>
                openPwcScoreModal(s.participantID, s.pwcScoreID, s.levelID)
              }
            >
              <Col className="col-8">
                <div className="d-flex-row jc-space-between ai-center">
                  <div
                    className="d-flex-row ai-center"
                    style={{ width: "40%" }}
                  >
                    <h5 style={{ marginBottom: 0, marginRight: '10px' }}>{s.item1} </h5>
                    {s.betterItem == s.itemID1 && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: s.userVerbal,
                        }}
                      ></div>
                    )}
                  </div>

                  <div style={{ width: "20%", textAlign: "center" }}>
                    {s.betterItem == 0 && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: s.userVerbal,
                        }}
                      ></div>
                    )}
                  </div>

                  <div
                    className="d-flex-row ai-center"
                    style={{ width: "40%" }}
                  >
                    <h5 style={{ marginBottom: 0, marginRight: '10px' }}>{s.item2} </h5>
                    {s.betterItem == s.itemID2 && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: s.userVerbal,
                        }}
                      ></div>
                    )}
                  </div>
                  
                </div>
                <div className="divider"></div>
              </Col>
              
            </Row>
          ))}
     
        </Container>
      </div>

      <PwcScoreModal
        isOpen={showScoreModal}
        toggleModal={toggleScoreModal}
        data={pwcScoreData}
        reload={loadData}
      />
    </React.Fragment>
  )
}

export default PwcScore
