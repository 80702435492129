import NameAvatar from "components/custom/nameAvatar"
import Compressor from "compressorjs"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { changeUserPhoto } from "store/userPhoto/actions"
import colors from "utils/colors"

const UserProfileModal = ({ isOpen, toggleModal }) => {
  const currentUser = userService.getLoggedInUser()
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState(null)
  const [passwordsMatch, setPasswordsMatch] = useState(true)
  const [homePages, setHomePages] = useState([])
  const [userPhotoBase64, setUserPhotoBase64] = useState('')
  const hiddenFileInput = React.useRef(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isOpen) {
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    setIsLoading(true)
    let u = await api.getUser(currentUser.userID)
    setUser({
      ...u,
      newPassword: "",
      confirmPassword: "",
    })
    let p = await api.getUserPhotoBase64(currentUser.userID)
    setUserPhotoBase64(p)
    let hp = await api.getList(currentUser.userID, "HomePageOptions")
    setHomePages(hp)
    setIsLoading(false)
  }

  const changeUserProp = (prop, value) => {
    setPasswordsMatch(true)
    setUser({
      ...user,
      [prop]: value,
    })
  }

  const save = async () => {
    if (user.newPassword.length > 0 || user.confirmPassword.length > 0) {
      if (user.newPassword !== user.confirmPassword) {
        setPasswordsMatch(false)
      } else {
        await api.updateUser(currentUser.userID, {
          ...user,
          password: user.newPassword,
        })
        toggleModal()
      }
    } else {
      await api.updateUser(currentUser.userID, {
        ...user,
        password: user.newPassword,
      })
      toggleModal()
    }
  }

  const sendSms = async () => {
    await api.updateUser(currentUser.userID, {
      ...user,
    })
    await api.sendSms(currentUser.userID)
    toast.success("SMS sent")
  }

  const handleAvatarClick = event => {
    hiddenFileInput.current.click()
  }

  const handleImageChange = event => {
    const fileUploaded = event.target.files[0]
    new Compressor(fileUploaded, {
      quality: 0.5,
      maxWidth: 200, // Set max width to resize
      maxHeight: 200, // Set max height to resize
      success: resizedImage => {
        const reader = new FileReader()
        reader.readAsDataURL(resizedImage)
        reader.onloadend = async () => {
          setUserPhotoBase64(reader.result)
          await api.updateUserPhotoBase64(currentUser.userID, reader.result)
          dispatch(changeUserPhoto(reader.result))
        }
        // setUser({ ...user, imageUri: resizedImage[0].data })
        // api.updateUserPhotoBase64(user.userID, resizedImage[0].data).then(r => {
        //   window.location.reload(false)
        // })
      },
    })
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel33">
          Manage Account
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        {!isLoading && user != null && (
          <>
            <div className="mb-3">
              <NameAvatar
                fullName={`${currentUser.firstName} ${currentUser.lastName}`}
                size="xl"
                base64Image={userPhotoBase64}
                onClick={handleAvatarClick}
              />
              <input
                type="file"
                accept="image/*"
                ref={hiddenFileInput}
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </div>

            <div
              className="smallHelp"
              style={{
                marginBottom: "15px",
              }}
            >
              To change your avatar, click on it and select a new image from your computer.
            </div>

            <div
              className="mb-3"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "end",
              }}
            >
              <div>
                <Label className="form-label">Phone</Label>
                <Input
                  name="phone"
                  type="text"
                  onChange={e => changeUserProp("phoneNumber", e.target.value)}
                  value={user.phoneNumber}
                />
              </div>
              <button className="btn btn-primary" onClick={sendSms}>
                Send SMS
              </button>
            </div>
            <div
              className="smallHelp"
              style={{
                marginBottom: "15px",
              }}
            >
              By submitting your phone number you agree to receive a one time
              code from Definitive Business Solutions when you login if you are
              setup for 2 Factor Authentication
            </div>
            <div className="mb-3">
              <Label className="form-label">New Password</Label>
              <Input
                name="password"
                type="password"
                onChange={e => changeUserProp("newPassword", e.target.value)}
                value={user.newPassword}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Confirm Password</Label>
              <Input
                name="confirmpassword"
                type="password"
                onChange={e =>
                  changeUserProp("confirmPassword", e.target.value)
                }
                value={user.confirmPassword}
              />
              {!passwordsMatch && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Password and Confirm Password must match
                </div>
              )}
            </div>
            <div className="mb-3">
              <Label className="form-label">Home Page</Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={user.homePageID}
                onChange={e => {
                  changeUserProp("homePageID", e.target.value)
                }}
              >
                {homePages.map((a, idx) => {
                  return (
                    <option key={idx} value={`${a.listItemID}`}>
                      {a.listItemName}
                    </option>
                  )
                })}
              </select>
            </div>
          </>
        )}
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default UserProfileModal
