import {
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
} from "@material-ui/core"
import NameAvatar from "components/custom/nameAvatar"
import {
  swalWithConfirmAndCancelButtons,
  swalWithConfirmButton,
} from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row, Alert } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { enrichWithPhotoBase64 } from "utils/avatar"
import { currencyFormatter } from "utils/formatters"

const RatesSection = ({ fyYear, resourceGroupId }) => {
  const currentUser = userService.getLoggedInUser()
  const [rates, setRates] = useState([])

  const [rate, setRate] = useState({})
  const [fiscalYears, setFiscalYears] = useState([])
  const [showEditModal, setShowEditModal] = useState(false)

  const toggleEditModal = () => setShowEditModal(!showEditModal)

  useEffect(() => {
    if (fyYear > 0) {
      if (resourceGroupId > 0) {
        loadRates()
      } else {
        setRates([])
      }
      setRate({
        yearID: fyYear,
        rate: 0,
        resourceGroupID: resourceGroupId,
        resourceGroupRateID: -1,
      })
      constructFiscalYears(fyYear)
    }
  }, [fyYear, resourceGroupId])

  const constructFiscalYears = fyYear => {
    let years = []
    let dt = new Date(fyYear, 1, 1)
    let dtYearStart = dt.getFullYear() - 10
    for (let i = 0; i <= 25; i++) {
      years.push(dtYearStart + i)
    }
    setFiscalYears(years)
  }

  const loadRates = async () => {
    let r = await api.getResourceGroupRates(resourceGroupId)
    setRates(r)
  }

  const deleteRate = async rate => {
    await api.deleteResourceGroupRate(
      currentUser.userID,
      rate.resourceGroupRateID
    )
    await loadRates()
  }

  const saveRate = async () => {
    await api.createResourceGroupRate(currentUser.userID, rate)
    await loadRates()
    toggleEditModal()
  }

  const addRate = () => {
    setRate({
      yearID: fyYear,
      rate: 0,
      resourceGroupID: resourceGroupId,
      resourceGroupRateID: -1,
    })
    toggleEditModal()
  }

  return (
    <div>
      <button className="btn btn-primary" onClick={addRate}>
        + Add Rate
      </button>
      <table
        className="table table-bordered low-padding-table"
        style={{ backgroundColor: "white" }}
      >
        <thead>
          <tr>
            <th>Year</th>
            <th style={{ textAlign: "center" }}>Rate</th>
            <th style={{ width: "15%", textAlign: "center" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {rates.map((r, idx) => {
            return (
              <tr key={idx}>
                <td>{r.yearID}</td>
                <td className="text-center">
                  {currencyFormatter.format(r.rate)}
                </td>
                <td style={{ textAlign: "center" }}>
                  <i
                    className="fas fa-trash"
                    style={{
                      color: "red",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                    onClick={() => deleteRate(r)}
                  ></i>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <Modal
        backdrop="static"
        isOpen={showEditModal}
        size="xl"
        toggle={() => {
          toggleEditModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabelContract">
            Add Rate
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleEditModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <Label className="form-label">Year</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={rate.yearID || -1}
              onChange={e => {
                setRate({ ...rate, yearID: e.target.value })
              }}
            >
              {fiscalYears.map((a, idx) => {
                return (
                  <option key={idx} value={`${a}`}>
                    {a}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Rate</Label>
            <CurrencyInput
              id="intContRate"
              name="intContRate"
              className="form-control"
              value={rate.rate}
              onValueChange={(value, name, values) => {
                setRate({ ...rate, rate: values.float })
              }}
              placeholder=""
              prefix={"$"}
            />
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleEditModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={saveRate}
          >
            Save
          </button>
        </div>
      </Modal>
    </div>
  )
}

const UsersSection = ({ users, resourceGroupId }) => {
  const currentUser = userService.getLoggedInUser()
  const [selectedUserID, setSelectedUserID] = useState(-1)
  const [resourceGroupUsers, setResourceGroupUsers] = useState([])
  const [showEditModal, setShowEditModal] = useState(false)

  const toggleEditModal = () => setShowEditModal(!showEditModal)

  useEffect(() => {
    if (resourceGroupId > 0) {
      loadResourceManagers()
    } else {
      setResourceGroupUsers([])
    }
    setSelectedUserID(-1)
  }, [resourceGroupId])

  const loadResourceManagers = async () => {
    let data = await api.getResourceGroupUsers(resourceGroupId)
    data = await enrichWithPhotoBase64(data, 'userID')
    setResourceGroupUsers(data)
  }

  const saveManager = async () => {
    if (selectedUserID > -1) {
      await api.createResourceGroupUser(currentUser.userID, {
        resourceGroupID: resourceGroupId,
        userID: selectedUserID,
      })
      await loadResourceManagers()
      toggleEditModal()
    }
  }

  const deleteManager = async resourceGroupUser => {
    await api.deleteResourceGroupUser(
      currentUser.userID,
      resourceGroupUser.resourceGroupUserID
    )
    await loadResourceManagers()
  }

  const addManager = () => {
    setSelectedUserID(-1)
    toggleEditModal()
  }

  return (
    <div>
      <button className="btn btn-primary" onClick={addManager}>
        + Add Manager
      </button>
      <table
        className="table table-bordered low-padding-table"
        style={{ backgroundColor: "white" }}
      >
        <thead>
          <tr>
            <th>Resource Manager</th>
            <th style={{ width: "15%" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {resourceGroupUsers.map((r, idx) => {
            return (
              <tr key={idx}>
                <td>
                  <NameAvatar
                    fullName={`${r.firstName} ${r.lastName}`}
                    showName={true}
                    base64Image={r.photoBase64}
                  />
                </td>
                <td style={{ textAlign: "center" }}>
                  <i
                    className="fas fa-trash"
                    style={{
                      color: "red",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                    onClick={() => deleteManager(r)}
                  ></i>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <Modal
        backdrop="static"
        isOpen={showEditModal}
        size="xl"
        toggle={() => {
          toggleEditModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabelContract">
            Add Manager
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleEditModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <Label className="form-label">Resource Manager</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={selectedUserID || -1}
              onChange={e => {
                setSelectedUserID(e.target.value)
              }}
            >
              <option value={-1}>Select Manager</option>
              {users.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemID}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleEditModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={saveManager}
          >
            Save
          </button>
        </div>
      </Modal>
    </div>
  )
}

const CostPoolsSection = ({ costPools, resourceGroupId }) => {
  const currentUser = userService.getLoggedInUser()
  const [resourceGroupCostPools, setResourceGroupCostPools] = useState([])
  const [selectedCostPoolId, setSelectedCostPoolId] = useState(-1)
  const [showEditModal, setShowEditModal] = useState(false)

  const toggleEditModal = () => setShowEditModal(!showEditModal)

  useEffect(() => {
    if (resourceGroupId > 0) {
      loadCostPools()
    } else {
      setResourceGroupCostPools([])
    }
    setSelectedCostPoolId(-1)
  }, [resourceGroupId])

  const loadCostPools = async () => {
    let cp = await api.getResourceGroupCostPools(resourceGroupId)
    setResourceGroupCostPools(cp)
  }

  const saveCostPool = async () => {
    if (selectedCostPoolId > -1) {
      let cp = costPools.find(x => x.costPoolID == selectedCostPoolId)
      await api.createResourceGroupCostPool(currentUser.userID, {
        resourceGroupID: resourceGroupId,
        costPoolID: cp == undefined ? 0 : cp.costPoolID,
        resourceTemplateID: cp == undefined ? 0 : cp.costPoolTemplateID,
      })
      await loadCostPools()
      toggleEditModal()
    }
  }

  const deleteCostPool = async costPool => {
    if (
      costPool.usageMessage !== undefined &&
      costPool.usageMessage !== null &&
      costPool.usageMessage !== ""
    ) {
      swalWithConfirmButton.fire({
        title: costPool.usageMessage,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    } else {
      await api.deleteResourceGroupCostPool(
        currentUser.userID,
        costPool.resourceGroupCostPoolID
      )
      await loadCostPools()
    }
  }

  const addCostPool = () => {
    setSelectedCostPoolId(-1)
    toggleEditModal()
  }

  return (
    <div>
      <button className="btn btn-primary" onClick={addCostPool}>
        + Add Cost Pool
      </button>
      <table
        className="table table-bordered low-padding-table"
        style={{ backgroundColor: "white" }}
      >
        <thead>
          <tr>
            <th>Cost Pool</th>
            <th style={{ width: "15%" }}>Flow Through</th>
            <th style={{ width: "15%" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {resourceGroupCostPools.map((r, idx) => {
            return (
              <tr key={idx}>
                <td>
                  {r.costPoolType} - {r.costPoolName}
                </td>
                <td style={{ textAlign: "center" }}>
                  {r.flowThrough ? "Yes" : "No"}
                </td>
                <td style={{ textAlign: "center" }}>
                  <i
                    className="fas fa-trash"
                    style={{
                      color: "red",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                    onClick={() => deleteCostPool(r)}
                  ></i>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <Modal
        backdrop="static"
        isOpen={showEditModal}
        size="xl"
        toggle={() => {
          toggleEditModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabelContract">
            Add Cost Pool
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleEditModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <Label className="form-label">Cost Pool</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={selectedCostPoolId || -1}
              onChange={e => {
                setSelectedCostPoolId(e.target.value)
              }}
            >
              <option value={-1}>Select Cost Pool</option>
              <option value={0}>General</option>
              {costPools
                .filter(
                  x =>
                    resourceGroupCostPools.find(
                      rgcp => rgcp.costPoolType == x.costPoolType
                    ) == undefined
                )
                .map((a, idx) => {
                  return (
                    <option key={idx} value={`${a.costPoolID}`}>
                      {a.costPoolType} - {a.costPoolName}
                    </option>
                  )
                })}
            </select>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleEditModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={saveCostPool}
          >
            Save
          </button>
        </div>
      </Modal>
    </div>
  )
}

const ContractsSection = ({ contractsForScenario, resourceGroupId }) => {
  const currentUser = userService.getLoggedInUser()
  const [resourceGroupContracts, setResourceGroupContracts] = useState([])
  const [showEditModal, setShowEditModal] = useState(false)
  const [selectedContractId, setSelectedContractId] = useState(-1)

  const toggleEditModal = () => setShowEditModal(!showEditModal)

  useEffect(() => {
    if (resourceGroupId > 0) {
      loadContracts()
    } else {
      setResourceGroupContracts([])
    }
    setSelectedContractId(-1)
  }, [resourceGroupId])

  const loadContracts = async () => {
    let cp = await api.getResourceGroupContracts(resourceGroupId)
    setResourceGroupContracts(cp)
  }

  const saveContract = async () => {
    if (selectedContractId > -1) {
      await api.createResourceGroupContract(currentUser.userID, {
        resourceGroupID: resourceGroupId,
        contractID: selectedContractId,
      })
      await loadContracts()
      toggleEditModal()
    }
  }

  const addContract = () => {
    setSelectedContractId(-1)
    toggleEditModal()
  }

  const deleteContract = async contract => {
    await api.deleteResourceGroupContract(
      currentUser.userID,
      contract.resourceGroupContractID
    )
    await loadContracts()
  }

  return (
    <div>
      <button className="btn btn-primary" onClick={addContract}>
        + Add Contract
      </button>
      <table
        className="table table-bordered low-padding-table"
        style={{ backgroundColor: "white" }}
      >
        <thead>
          <tr>
            <th>Contract</th>
            <th style={{ width: "15%" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {resourceGroupContracts.map((r, idx) => {
            return (
              <tr key={idx}>
                <td>{r.contractName}</td>
                <td style={{ textAlign: "center" }}>
                  <i
                    className="fas fa-trash"
                    style={{
                      color: "red",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                    onClick={() => deleteContract(r)}
                  ></i>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <Modal
        backdrop="static"
        isOpen={showEditModal}
        size="xl"
        toggle={() => {
          toggleEditModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabelContract">
            Add Contract
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleEditModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <Label className="form-label">Contract</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={selectedContractId || -1}
              onChange={e => {
                setSelectedContractId(e.target.value)
              }}
            >
              <option value={-1}>Select Contract</option>
              {contractsForScenario.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.contractID}`}>
                    {a.contractName}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleEditModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={saveContract}
          >
            Save
          </button>
        </div>
      </Modal>
    </div>
  )
}

const EditResourceModal = ({
  isOpen,
  toggleModal,
  resourceToEditId,
  scenarioId,
  // templates,
  // strategies,
  // users,
  // scenarioContractEntities,
  reloadResources,
  // costPools,
  // contractsForScenario,
  // resourceUnits,
  // resourceClasses,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [resourceToEdit, setResourceToEdit] = useState(null)
  const [selectedContractEntityType, setSelectedContractEntityType] =
    useState(-1)
  // const [entityTypes, setEntityTypes] = useState([])
  const [resourceGroupClasses, setResourceGroupClasses] = useState([])

  const [selectedGroupClassesIds, setSelectedGroupClassesIds] = useState([])
  // const [tshirtSizes, setTshirtSizes] = useState([])

  const [templates, setTemplates] = useState([])
  const [scenarioContractEntities, setScenarioContractEntities] = useState([])
  const [classes, setClasses] = useState([])
  const [entityTypes, setEntityTypes] = useState([])
  const [entities, setEntities] = useState([])
  const [strategies, setStrategies] = useState([])
  const [users, setUsers] = useState([])
  const [costPools, setCostPools] = useState([])
  const [contractsForScenario, setContractsForScenario] = useState([])
  const [resourceUnits, setResourceUnits] = useState([])

  const [scenario, setScenario] = useState(null)

  useEffect(() => {
    if (scenarioId != undefined) {
      loadData()
    }
  }, [scenarioId])

  useEffect(() => {
    if (isOpen && scenario != null) {
      setSelectedContractEntityType(-1)
      if (resourceToEditId > 0) {
        loadResource(resourceToEditId)
      } else {
        setResourceToEdit({
          resourceGroupID: -1,
          resourceGroupName: "",
          resourceGroupAbb: "",
          active: true,
          description: "",
          resourceTemplateID: -1,
          defaultContractEntityID: -1,
          pmComplete: false,
          defaultStrategyID: 0,
          defaultStart: new Date(),
          defaultEnd: new Date(),
          scenarioID: scenario.scenarioID,
          defaultContractEntityTypeID: -1
        })
        setResourceGroupClasses([])
        setSelectedGroupClassesIds([])
        // setTshirtSizes([])
      }
    }
  }, [isOpen, resourceToEditId, scenario])

  const loadResource = async resourceGroupId => {
    let rsc = await api.getResourceGroup(resourceGroupId)
    setResourceToEdit(rsc)
    let cls = await api.getResourceClassesByGroup(resourceGroupId)
    setResourceGroupClasses(cls.map(x => x.resourceClassName))
    setSelectedGroupClassesIds(cls.map(x => x.resourceClassID + ""))
    // let tshirts = await api.getResourceGroupTShirts(resourceGroupId)
    // setTshirtSizes(tshirts)
  }

  // useEffect(() => {
  //   if (
  //     scenarioContractEntities != null &&
  //     scenarioContractEntities.length > 0
  //   ) {
  //     let lst = []
  //     scenarioContractEntities.forEach(element => {
  //       if (
  //         lst.find(
  //           x => x.contractEntityTypeID == element.contractEntityTypeID
  //         ) == undefined
  //       ) {
  //         lst.push({
  //           contractEntityType: element.contractEntityType,
  //           contractEntityTypeID: element.contractEntityTypeID,
  //         })
  //       }
  //     })
  //     setEntityTypes(lst)
  //   }
  // }, [scenarioContractEntities])

  const loadData = async () => {
    let scen = await api.getScenario(scenarioId)
    setScenario(scen)
    let temps = await api.getList(currentUser.userID, "ResourceTemplates")
    setTemplates(temps)
    let cls = await api.getScenarioList(scen.scenarioID, "ResourceClass")
    setClasses(cls)
    let ctrEntityTypes = await api.getEnterpriseList(
      currentUser.enterpriseID,
      "ContractEntityType"
    )
    setEntityTypes(ctrEntityTypes)
    let ctrEntities = await api.getEnterpriseList(
      currentUser.enterpriseID,
      "ContractEntity"
    )
    setEntities(ctrEntities)

    let scEnt = await api.getScenarioContractEntities(scenarioId)
    setScenarioContractEntities(scEnt)

    let c = await api.getContractsForScenario(scenarioId)
    setContractsForScenario(c)
    let str = await api.getScenarioList(scenarioId, "Strategy")
    setStrategies(str)
    let usr = await api.getScenarioList(scenarioId, "UserList")
    setUsers(usr)
    let cp = await api.getScenarioCostPools(scenarioId, 0)
    setCostPools(cp)
    let ru = await api.getList(currentUser.userID, "ResourceUnit")
    setResourceUnits(ru)
  }

  const changeResourceProp = (prop, value) => {
    setResourceToEdit({
      ...resourceToEdit,
      [prop]: value,
    })
  }

  const saveResource = async () => {
    if (resourceToEdit.resourceGroupID > 0) {
      await api.updateResourceGroupResult(currentUser.userID, resourceToEdit)
      await api.updateResourceGroupClasses(
        currentUser.userID,
        resourceToEdit.resourceGroupID,
        selectedGroupClassesIds.join(",")
      )
      // if (tshirtSizes.find(x => x.isDirty) !== undefined) {
      //   await api.updateResourceGroupTShirts(
      //     currentUser.userID,
      //     resourceToEdit.resourceGroupID,
      //     tshirtSizes
      //   )
      // }
      toast.success("Changes updated successfuly")
      loadResource(resourceToEditId)
      reloadResources()
      toggleModal()
    } else {
      let id = await api.createResourceGroup(currentUser.userID, resourceToEdit)
      await api.updateResourceGroupClasses(
        currentUser.userID,
        id,
        selectedGroupClassesIds.join(",")
      )
      toast.success("Changes updated successfuly")
      loadResource(id)
      reloadResources()
      toggleModal()
    }
  }

  const deleteResource = async () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this resource?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteResourceGroup(currentUser.userID, resourceToEditId)
          reloadResources()
        }
      })
  }

  const handleMultiChange = e => {
    const value = e.target.value
    const values = value === "string" ? value.split(",") : value
    // setResourceGroupClasses(values)
    setSelectedGroupClassesIds(values)
  }

  const getSelectedGroupClassesNames = () => {
    return classes
      .filter(
        x => selectedGroupClassesIds.find(id => id == x.listItemID) != undefined
      )
      .map(x => x.listItemName)
  }

  const hideResourceSections = currentUser.roleID < 10

  return resourceToEdit == null ? (
    <React.Fragment></React.Fragment>
  ) : (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="xl"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {resourceToEdit.resourceGroupID > 0 ? "Edit " : "Add "} Resource
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      {resourceToEdit != null && (
        <div className="modal-body">
          <Row>
            <Col md="6">
              <div className="mb-3">
                <Label className="form-label">Resource Name</Label>
                <Input
                  name="resourceGroupName"
                  type="text"
                  onChange={e =>
                    changeResourceProp("resourceGroupName", e.target.value)
                  }
                  value={resourceToEdit.resourceGroupName}
                  disabled={hideResourceSections}
                />
              </div>
            </Col>
            {!hideResourceSections && (
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">Short Name</Label>
                  <Input
                    name="resourceGroupAbb"
                    type="text"
                    onChange={e =>
                      changeResourceProp("resourceGroupAbb", e.target.value)
                    }
                    value={resourceToEdit.resourceGroupAbb}
                  />
                </div>
              </Col>
            )}
          </Row>
          {!hideResourceSections && (
            <>
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label
                      className="form-label"
                      style={{ marginBottom: "10px" }}
                    >
                      Resource Classes
                    </Label>
                    <Select
                      labelId="mcb-bcstatus"
                      id="mcb-bcstatus"
                      name="bcstatus"
                      multiple
                      value={selectedGroupClassesIds}
                      onChange={handleMultiChange}
                      input={<OutlinedInput />}
                      renderValue={() =>
                        getSelectedGroupClassesNames().join(", ")
                      }
                    >
                      {classes.map(s => (
                        <MenuItem key={s.listItemID} value={s.listItemID}>
                          <Checkbox
                            checked={
                              selectedGroupClassesIds.find(
                                rgc => rgc == s.listItemID
                              ) != undefined
                            }
                            color="primary"
                          />
                          <ListItemText primary={s.listItemName} />
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <br></br>
                    <Input
                      id="active"
                      type="checkbox"
                      checked={resourceToEdit.active}
                      onClick={() =>
                        changeResourceProp("active", !resourceToEdit.active)
                      }
                    />
                    <Label
                      className="form-check-label"
                      for="useMust"
                      style={{ marginLeft: "10px" }}
                    >
                      Active for Optimization
                    </Label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mb-3">
                    <Label className="form-label">Description</Label>
                    <TextareaAutosize
                      className="form-control"
                      minRows={2}
                      onChange={e =>
                        changeResourceProp("description", e.target.value)
                      }
                      value={resourceToEdit.description}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="form-label">Template</Label>
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      value={resourceToEdit.resourceTemplateID || -1}
                      onChange={e => {
                        changeResourceProp("resourceTemplateID", e.target.value)
                      }}
                    >
                      <option value={-1}>Select Template</option>
                      {templates.map((a, idx) => {
                        return (
                          <option key={idx} value={`${a.listItemID}`}>
                            {a.listItemName}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="form-label">Unit</Label>
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      value={resourceToEdit.resourceUnit}
                      onChange={e => {
                        changeResourceProp("resourceUnit", e.target.value)
                      }}
                    >
                      <option value={-1}>Select Unit</option>
                      {resourceUnits.map((a, idx) => {
                        return (
                          <option key={idx} value={`${a.listItemName}`}>
                            {a.listItemName}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="form-label">
                      Contract(s) Default Entity Type
                    </Label>
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      value={resourceToEdit.defaultContractEntityTypeID}
                      onChange={e => {
                        changeResourceProp(
                          "defaultContractEntityTypeID",
                          e.target.value
                        )
                      }}
                    > 
                      <option value={-1}>Select Type</option>
                      {entityTypes.map((a, idx) => {
                        return (
                          // <option key={idx} value={`${a.contractEntityTypeID}`}>
                          //   *{a.contractEntityType}
                          // </option>
                          <option key={idx} value={`${a.listItemID}`}>
                            {a.listItemName}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="form-label">Default Entity</Label>
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      value={resourceToEdit.defaultContractEntityID || -1}
                      onChange={e => {
                        changeResourceProp(
                          "defaultContractEntityID",
                          e.target.value
                        )
                      }}
                    >
                      <option value={-1}>Select Entity</option>
                      {scenarioContractEntities
                        .filter(
                          x =>
                            resourceToEdit.defaultContractEntityTypeID <= 0 ||
                            x.contractEntityTypeID == resourceToEdit.defaultContractEntityTypeID
                        )
                        .map((a, idx) => {
                          return (
                            <option key={idx} value={`${a.contractEntityID}`}>
                              {a.contractEntityName}
                            </option>
                          )
                        })}
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label>Default PoP Start</Label>
                    <Input
                      name="startDate"
                      type="date"
                      onChange={e =>
                        changeResourceProp(
                          "defaultStart",
                          moment(e.target.value, "YYYY-MM-DD").format(
                            "YYYY-MM-DDThh:mm:ss"
                          )
                        )
                      }
                      value={
                        new Date(resourceToEdit.defaultStart) >
                        new Date(1900, 1, 1, 0, 0, 0, 0)
                          ? moment(resourceToEdit.defaultStart).format(
                              "YYYY-MM-DD"
                            )
                          : ""
                      }
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label>Default PoP End</Label>
                    <Input
                      name="endDate"
                      type="date"
                      onChange={e =>
                        changeResourceProp(
                          "defaultEnd",
                          moment(e.target.value, "YYYY-MM-DD").format(
                            "YYYY-MM-DDThh:mm:ss"
                          )
                        )
                      }
                      value={
                        new Date(resourceToEdit.defaultEnd) >
                        new Date(1900, 1, 1, 0, 0, 0, 0)
                          ? moment(resourceToEdit.defaultEnd).format(
                              "YYYY-MM-DD"
                            )
                          : ""
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mb-3">
                    <Label className="form-label">
                      Default Acquisition Approach
                    </Label>
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      value={resourceToEdit.defaultStrategyID || 0}
                      onChange={e => {
                        changeResourceProp("defaultStrategyID", e.target.value)
                      }}
                    >
                      {strategies.map((a, idx) => {
                        return (
                          <option key={idx} value={`${a.listItemID}`}>
                            {a.listItemName}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <div
                    className="mb-3"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Input
                      id="pmComplete"
                      type="checkbox"
                      checked={resourceToEdit.pmComplete}
                      onClick={() =>
                        changeResourceProp(
                          "pmComplete",
                          !resourceToEdit.pmComplete
                        )
                      }
                    />
                    <Label
                      className="form-check-label"
                      for="pmComplete"
                      style={{ marginLeft: "10px" }}
                    >
                      PM and BRM can enter resource estimates
                    </Label>
                  </div>
                </Col>
              </Row>
            </>
          )}
          {/* {tshirtSizes.length > 0 && (
            <Row>
              <Col>
                <div className="divider"></div>
                <h5>
                  <i className="fas fa-tshirt"></i> T-Shirt Sizes
                </h5>
                <Alert
                  id="helpTShirt"
                  variant="light"
                  className="always-enabled"
                  style={{ marginBottom: "10px" }}
                >
                  <div>
                    T-Shirt sizing is a technique to allow resource estimations
                    and provide ballpark estimates for projects that may have
                    limited information initially. Update the hours and a
                    meaningful description for each T-Shirt size to assist
                    individuals doing estimates. The descriptions should allow
                    an estimator to compare them to the current project and
                    select the T-Shirt size that best represents that project.
                  </div>
                </Alert>
                <table
                  className="table table-bordered low-padding-table"
                  style={{ backgroundColor: "white" }}
                >
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}>Size</th>
                      <th style={{ width: "10%" }}>
                        {resourceToEdit.resourceUnit}
                      </th>
                      <th style={{ width: "80%" }}>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tshirtSizes.map((t, idx) => (
                      <tr key={idx}>
                        <td style={{ textAlign: "center" }}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: t.tShirtPill,
                            }}
                          ></div>
                        </td>
                        <td>
                          <Input
                            type="text"
                            value={t.tShirtHours || 0}
                            onChange={e =>
                              changeTshirtSizePropByIndex(
                                idx,
                                "tShirtHours",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <TextareaAutosize
                            className="form-control"
                            style={{ width: "100%" }}
                            placeholder={
                              t.tShirtID == "XS"
                                ? "Example: Working on a minor release for an existing product or platform."
                                : t.tShirtID == "S"
                                ? "Example: Working on a major release to an existing product or platform."
                                : t.tShirtID == "M"
                                ? "Example: Working on a project requiring a proof-of-concept for a new product or platform."
                                : t.tShirtID == "L"
                                ? "Example: Working on a project requiring implementation of a new product/platform for a business unit."
                                : t.tShirtID == "XL"
                                ? "Example: Working on a project requiring implementation of new product/platform for the enterprise."
                                : false
                            }
                            onChange={e =>
                              changeTshirtSizePropByIndex(
                                idx,
                                "description",
                                e.target.value
                              )
                            }
                            value={t.description || ""}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Col>
              <div className="divider"></div>
            </Row>
          )} */}
          {resourceToEdit.resourceGroupID > -1 && !hideResourceSections && (
            <Row className="mt-10">
              <Col md="6">
                <h5>Rates</h5>
                {scenario !== null && (
                  <RatesSection
                    fyYear={scenario.fyYear}
                    resourceGroupId={resourceToEdit.resourceGroupID}
                  />
                )}
              </Col>
              <Col md="6">
                <h5>Resource Managers</h5>
                <UsersSection
                  resourceToEdit={resourceToEdit}
                  users={users}
                  resourceGroupId={resourceToEdit.resourceGroupID}
                />
              </Col>
              <Col md="6">
                <h5>Cost Pools</h5>
                <CostPoolsSection
                  resourceToEdit={resourceToEdit}
                  costPools={costPools}
                  resourceGroupId={resourceToEdit.resourceGroupID}
                />
              </Col>
              <Col md="6">
                <h5>Contracts</h5>
                <ContractsSection
                  resourceToEdit={resourceToEdit}
                  contractsForScenario={contractsForScenario}
                  resourceGroupId={resourceToEdit.resourceGroupID}
                />
              </Col>
            </Row>
          )}
        </div>
      )}
      <div
        className="modal-footer"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          {resourceToEditId > -1 && !hideResourceSections && (
            <button
              className="btn btn-outline-danger"
              onClick={() => deleteResource()}
            >
              Delete
            </button>
          )}
        </div>
        <div>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={saveResource}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default EditResourceModal
