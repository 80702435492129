import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import HelpIconWithModal from "components/custom/helpIconWithModal"

const EditMajorModal = ({
  isOpen,
  toggleModal,
  majorToEdit,
  changeMajorProp,
  nm,
  changeNmProp,
}) => {
  const currentUser = userService.getLoggedInUser()

  useEffect(() => {
    if (isOpen) {
    }
  }, [isOpen])

  const save = async () => {
    if (nm.nmhedMajors == null || nm.nmhedMajors.length == 0) {
      changeNmProp("nmhedMajors", [{ ...majorToEdit }])
    } else {
      let copy = [...nm.nmhedMajors]
      let existing = copy.find(x => x.nmhedMajorID == majorToEdit.nmhedMajorID)
      if (existing == undefined) {
        changeNmProp("nmhedMajors", [...copy, { ...majorToEdit }])
      } else {
        existing = { ...majorToEdit }
        changeNmProp("nmhedMajors", [...copy])
      }
    }
    toggleModal()
  }

  const cancel = () => {}

  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Edit Major
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {majorToEdit != null && (
        <div className="modal-body">
          <div className="mb-3">
            <Label className="form-label">Major</Label>
            <Input
              type="text"
              onChange={e => changeMajorProp("majorName", e.target.value)}
              value={majorToEdit.majorName}
            />
          </div>
          <Row>
            <Col md="6">
              <div className="mb-3">
                <Label className="form-label">Headcount</Label>
                <Input
                  type="text"
                  onChange={e => changeMajorProp("headCount", e.target.value)}
                  value={majorToEdit.headCount}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Growth % last year</Label>
                <Input
                  type="text"
                  onChange={e => changeMajorProp("yearGrowth", e.target.value)}
                  value={majorToEdit.yearGrowth}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <Label className="form-label">FTE</Label>
                <Input
                  type="text"
                  onChange={e => changeMajorProp("fte", e.target.value)}
                  value={majorToEdit.fte}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Average Growth %</Label>
                <HelpIconWithModal itemId={9} />
                <Input
                  type="text"
                  onChange={e => changeMajorProp("avgGrowth", e.target.value)}
                  value={majorToEdit.avgGrowth}
                />
              </div>
            </Col>
          </Row>
        </div>
      )}
      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default EditMajorModal
