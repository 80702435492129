import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const GenerateTasksModal = ({
  isOpen,
  toggleModal,
  scenarioId,
  alternativeId,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [isLoading, setIsLoading] = useState(false)
  const [taskListWorkflows, setTaskListWorkflows] = useState([])

  useEffect(() => {
    if (isOpen) {
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    setIsLoading(true)
    let w = await api.getTaskListWorkflows(scenarioId, alternativeId)
    setTaskListWorkflows(w)
    setIsLoading(false)
  }

  const createAltTaskList = async workflowId => {
    await api.createAlternativeTaskList(alternativeId, workflowId)
    toast.success("Task list created")
    loadData()
  }

  const deleteAltTaskList = async workflowId => {
    await api.deleteAlternativeTaskList(alternativeId, workflowId)
    toast.success("Task list deleted")
    loadData()
  }

  const save = async () => {}

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Generate Tasks
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        {!isLoading && (
          <div className="mb-3">
            {taskListWorkflows.length == 0 && <p>No data.</p>}
            {taskListWorkflows.length > 0 && (
              <table
                className="table table-bordered low-padding-table"
                style={{ backgroundColor: "white" }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "70%" }}>Tasks</th>
                    <th style={{ width: "10%" }}>#</th>
                    <th style={{ width: "20%" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {taskListWorkflows.map((w, idx) => (
                    <tr key={idx}>
                      <td>
                        {w.workflowName} 
                      </td>
                      <td>{w.numTasksInWorkflow > 0 && w.numTasksInWorkflow}</td>
                      <td>
                        {w.numTasksInWorkflow > 0 && (
                          <button
                            className="btn btn-danger"
                            onClick={() => deleteAltTaskList(w.workflowID)}
                          >
                            Remove
                          </button>
                        )}
                        {w.numTasksInWorkflow < 1 && (
                          <button
                            className="btn btn-primary"
                            onClick={() => createAltTaskList(w.workflowID)}
                          >
                            Add
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
        {/* <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button> */}
      </div>
    </Modal>
  )
}

export default GenerateTasksModal
