import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import HelpIconWithModal from "components/custom/helpIconWithModal"

const EditBuildingModal = ({
  isOpen,
  toggleModal,
  buildingToEdit,
  changeBuildingProp,
  nm,
  changeNmProp,
  entitiesByParent,
  reloadData,
}) => {
  const currentUser = userService.getLoggedInUser()

  useEffect(() => {
    if (isOpen) {
    }
  }, [isOpen])

  const save = async () => {
    if (buildingToEdit.entityID > -1) {
      if (nm.nmhedEntities == null || nm.nmhedEntities.length == 0) {
        changeNmProp("nmhedEntities", [{ ...buildingToEdit }])
      } else {
        let copy = [...nm.nmhedEntities]
        let existing = copy.find(
          x => x.nmhedEntityID == buildingToEdit.nmhedEntityID
        )
        if (existing == undefined) {
          changeNmProp("nmhedEntities", [...copy, { ...buildingToEdit }])
        } else {
          existing = { ...buildingToEdit }
          changeNmProp("nmhedEntities", [...copy])
        }
      }
      toggleModal()
    }
  }

  const cancel = () => {}

  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Edit Building
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {buildingToEdit != null && (
        <div className="modal-body">
          <div className="mb-3">
            <Label className="form-label">Building</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={buildingToEdit.entityID}
              onChange={e => {
                if (e.target.value > -1) {
                  changeBuildingProp("entityID.entityName", [
                    e.target.value,
                    entitiesByParent.find(x => x.entityID == e.target.value)
                      .entityName,
                  ])
                } else {
                  changeBuildingProp("entityID", e.target.value)
                }
              }}
            >
              <option value={-1}>Select Building</option>
              {entitiesByParent.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.entityID}`}>
                    {a.entityName}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Age of Facility (in years)</Label>
            <HelpIconWithModal itemId={10} />
            <Input
              type="number"
              onChange={e => changeBuildingProp("entityAge", e.target.value)}
              value={buildingToEdit.entityAge}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">GSF of Facility</Label>
            <Input
              type="number"
              onChange={e => changeBuildingProp("entityGSF", e.target.value)}
              value={buildingToEdit.entityGSF}
            />
          </div>
        </div>
      )}
      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default EditBuildingModal
