import React, { useEffect } from "react"
import { useOktaAuth } from "@okta/okta-react"

const LoginOkta = props => {
  const { oktaAuth, authState } = useOktaAuth()

  useEffect(() => {
    doWork()
  }, [])

  const doWork = async () => {
    await oktaAuth.signInWithRedirect()
  }

  return <div></div>
}

export default LoginOkta
