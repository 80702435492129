import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Modal } from "reactstrap"
import api from "services/api.service"
import colors from "utils/colors"

const RadarChartModal = ({
  isOpen,
  toggleModal,
  scenarioId,
  alternativeList,
  optObjFin,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isOpen) {
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    setIsLoading(true)
    let chartResponse = await api.getObjRadarChart(
      scenarioId,
      optObjFin != null && optObjFin.obj != null ? optObjFin.obj.objModelID : 0,
      true,
      alternativeList.join(",")
    )
    let dataArray1 = JSON.parse(
      "[" + chartResponse.chartArray.replaceAll("'", '"') + "]"
    )
    var dataSet = anychart.data.set(dataArray1)

    var chart = anychart.radar()
    var data1 = dataSet.mapAs({
      x: 0,
      value: 1,
    })
    var data2 = dataSet.mapAs({
      x: 0,
      value: 2,
    })
    var series1 = chart.line(data1)
    series1.normal().stroke("#BE0000")
    series1
      .name(
        chartResponse.chartData.split(",")[0].replace("ChartSeriesName1:", "")
      )
      .markers(true)
    var series2 = chart.line(data2)
    series2
      .name(
        chartResponse.chartData.split(",")[1].replace("ChartSeriesName2:", "")
      )
      .markers(true)

    //  if ( "<%=Session("ZeroChart")%>" == "zero") {
    //      chart.yScale().minimum(0);
    //  }
    chart.yScale().maximum(100)
    chart.tooltip().format("Numeric Ratings: {%Value}{decimalsCount: 2}%")
    chart.title("").legend(true)
    chart.container("chartdiv")
    chart.credits().enabled(false)

    chart.title().fontFamily("Inter")
    chart.legend().fontFamily("Inter")

    chart.draw()
    setIsLoading(false)
  }

  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      fullscreen={true}
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Radar Chart
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        <div
          id="chartdiv"
          style={{ width: "100%", height: "100%", minHeight: "600px" }}
        ></div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
      </div>
    </Modal>
  )
}

export default RadarChartModal
