import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import { Field, Formik, useFormik } from "formik"
import React, { useEffect, useState } from "react"
// import { MultiSelect } from "react-multi-select-component"
import { toast } from "react-toastify"
import { Col, Form, FormFeedback, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { authenticationService } from "services/authentication.service"
import { userService } from "services/user.service"
import * as Yup from "yup"
import Multiselect from "multiselect-react-dropdown"
import { TextareaAutosize } from "@material-ui/core"

const AddEditUserModal = ({
  user,
  setUser,
  isOpen,
  toggleModal,
  roles,
  enterprises,
  reloadGrid,
  props,
}) => {
  const currentUser = userService.getLoggedInUser()
  let [userScenarioListString, setUserScenarioListString] = useState("")
  let [email, setEmail] = useState("")
  let [isEmailSet, setIsEmailSet] = useState(false)
  const [entities, setEntities] = useState([])
  const [entityOptions, setEntityOptions] = useState([])
  const [entitySelectedOptions, setEntitySelectedOptions] = useState([])

  useEffect(() => {
    if (user != undefined && user != null && user.userID > 0) {
      validation.resetForm()
      validation.setFieldValue("aiUser", user.aiUser)
      validation.setFieldValue("inactive", user.inactive)
      loadUserScenarioList()
      loadUserEntities()
      loadEnterpriseEntities(user.enterpriseID)
    } else {
      setUserScenarioListString("")
      validation.resetForm()
      setEmail("")
      setEntitySelectedOptions([])
      setEntityOptions([])
    }
  }, [user])

  useEffect(() => {
    if (isOpen) {
      setIsEmailSet(false)
    }
  }, [isOpen])

  const loadUserEntities = async () => {
    let ue = await api.getUserEntities(user.userID)
    setEntitySelectedOptions(
      ue.map(x => {
        return { name: x.entityName, id: x.entityID }
      })
    )
  }

  const loadUserScenarioList = async () => {
    try {
      setUserScenarioListString("")
      let scenariosString = await api.getUserScenarioListString(user.userID)
      console.log(scenariosString)
      setUserScenarioListString(scenariosString)
    } catch (error) {}
  }

  const getUserByEmail = async email => {
    try {
      var usr = await api.getUserByEmail(email)
      if (usr != null) {
        setUser(usr)
        validation.resetForm()
      } else {
        setUser({ userID: -1, email: email })
        validation.resetForm()
      }
      setIsEmailSet(true)
    } catch (error) {
      console.log(error)
    }
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: (user && user.email) || "",
      firstName: (user && user.firstName) || "",
      lastName: (user && user.lastName) || "",
      password: (user && user.password) || "",
      phoneNumber: (user && user.phoneNumber) || "",
      roleID: (user && user.roleID) || "",
      enterpriseID: (user && user.enterpriseID) || currentUser.enterpriseID,
      inactive: (user && user.inactive) || false,
      aiUser: (user && user.aiUser) || false,
      aiUserPrompt: (user && user.aiUserPrompt) || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      firstName: Yup.string().required("Please Enter Your First name"),
      lastName: Yup.string().required("Please Enter Your Last name"),
      password: Yup.string().required("Please Enter password"),
      inactive: Yup.string().required("Please check or uncheck inactive"),
      roleID: Yup.number().required("Please Enter Your Role").moreThan(0),
      enterpriseID: Yup.number()
        .required("Please Enter Your Enterprise")
        .moreThan(0),
      aiUser: Yup.boolean(),
      aiUserPrompt: Yup.string(),
    }),
    onSubmit: async values => {
      let updatedUser = {
        ...user,
        ...values,
      }
      try {
        let userId = updatedUser.userID
        if (updatedUser.userID > 0) {
          await api.updateUser(currentUser.userID, updatedUser)
          toast.success("User updated successfuly")
        } else {
          userId = await api.createUser(currentUser.userID, updatedUser)
          toast.success("User created successfuly")
        }

        await api.saveUserEntities(
          userId,
          entitySelectedOptions.map(x => x.id).join(",")
        )

        toggleModal()
        reloadGrid(false)
      } catch (err) {
        console.log(err)
        alert(err)
      }
    },
  })

  const deleteUser = async () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: "Are you sure you want to delete this user?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          try {
            await api.deleteUser(currentUser.userID, user.userID)
            toggleModal()
            reloadGrid(false)
          } catch (err) {
            console.log(err)
            alert(err)
          }
        }
      })
  }

  const loginAsUser = () => {
    localStorage.removeItem("currentData")
    localStorage.removeItem("optobjfin")
    authenticationService.login(user.email, user.password).then(u => {
      if (u && u.homePagePath) {
        props.history.push(`/${u.homePagePath}`)
      } else {
        props.history.push("/models")
      }
      window.location.reload()
    })
  }

  const loadEnterpriseEntities = async enterpriseId => {
    if (enterpriseId > 0) {
      let e = await api.getEntitiesByEnterprise(enterpriseId)
      setEntities(e)
      setEntityOptions(
        e.map(x => {
          return { name: x.entityName, id: x.entityID }
        })
      )
    }
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      {user && (
        <>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {user.userID > 0 ? "Edit User" : "Add User"}
            </h5>
            <button
              type="button"
              onClick={() => {
                toggleModal()
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {user && user.userID <= 0 && !isEmailSet && (
              <Row>
                <Label className="form-label">Email</Label>
                <Col xs="8">
                  <Input
                    name="emails"
                    type="text"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                  />
                </Col>
                <Col xs="4">
                  <button
                    className="btn btn-primary save-user"
                    onClick={() => getUserByEmail(email)}
                  >
                    {"Next  >"}
                  </button>
                </Col>
              </Row>
            )}
            {(user.userID > 0 || isEmailSet) && (
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row form="true">
                  <Col xs={8}>
                    <div className="mb-3">
                      <Label className="form-label">Email</Label>
                      <Input
                        name="email"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">First Name</Label>
                      <Input
                        name="firstName"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.firstName || ""}
                        invalid={
                          validation.touched.firstName &&
                          validation.errors.firstName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.firstName &&
                      validation.errors.firstName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.firstName}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Last Name</Label>
                      <Input
                        name="lastName"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.lastName || ""}
                        invalid={
                          validation.touched.lastName &&
                          validation.errors.lastName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.lastName &&
                      validation.errors.lastName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.lastName}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Password</Label>
                      <Input
                        name="password"
                        type="password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ""}
                        invalid={
                          validation.touched.password &&
                          validation.errors.password
                            ? true
                            : false
                        }
                      />
                      {validation.touched.password &&
                      validation.errors.password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.password}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Phone</Label>
                      <Input
                        name="phone"
                        type="text"
                        onBlur={validation.handleBlur}
                        value={validation.values.phoneNumber || ""}
                        onChange={e => {
                          validation.setFieldValue(
                            "phoneNumber",
                            e.target.value
                          )
                        }}
                      />
                    </div>
                    {roles && roles.length > 0 && (
                      <div className="mb-3">
                        <Label className="form-label">Role</Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={validation.values.roleID || ""}
                          onChange={e => {
                            console.log(e.target.value)
                            validation.setFieldValue("roleID", e.target.value)
                          }}
                        >
                          <option value={0}>Select role</option>
                          {roles.map((r, idx) => {
                            return (
                              <option key={idx} value={`${r.listItemID}`}>
                                {r.listItemName}
                              </option>
                            )
                          })}
                        </select>
                        {validation.touched.roleID &&
                        validation.errors.roleID ? (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {validation.errors.roleID}
                          </div>
                        ) : null}
                      </div>
                    )}

                    {enterprises && enterprises.length > 0 && (
                      <div className="mb-3">
                        <Label className="form-label">Enterprise</Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={validation.values.enterpriseID || ""}
                          onChange={e => {
                            validation.setFieldValue(
                              "enterpriseID",
                              e.target.value
                            )
                            loadEnterpriseEntities(e.target.value)
                            setEntitySelectedOptions([])
                          }}
                        >
                          {currentUser.roleID == 110 && (
                            <option value={0}>Select enterprise</option>
                          )}
                          {enterprises.map((r, idx) => {
                            return (
                              <option key={idx} value={`${r.enterpriseID}`}>
                                {r.enterpriseName}
                              </option>
                            )
                          })}
                        </select>
                        {validation.touched.enterpriseID &&
                        validation.errors.enterpriseID ? (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {validation.errors.enterpriseID}
                          </div>
                        ) : null}
                      </div>
                    )}

                    {entityOptions.length > 0 && (
                      <div className="mb-3">
                        <Label className="form-label">Entity</Label>
                        <Multiselect
                          options={entityOptions}
                          selectedValues={entitySelectedOptions}
                          onSelect={setEntitySelectedOptions}
                          onRemove={setEntitySelectedOptions}
                          displayValue="name"
                          showCheckbox={true}
                        />
                      </div>
                    )}

                    <div className="form-check mb-3">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        checked={validation.values.inactive}
                        id="customCheck4"
                        onClick={e => {
                          validation.setFieldValue(
                            "inactive",
                            !validation.values.inactive
                          )
                        }}
                      />
                      <Label className="form-check-label" for="customCheck4">
                        Inactive
                      </Label>
                    </div>
                    {currentUser.roleID == 110 && (
                      <>
                        <div className="form-check mb-3">
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            checked={validation.values.aiUser}
                            id="aiUserCbx"
                            onClick={e => {
                              validation.setFieldValue(
                                "aiUser",
                                !validation.values.aiUser
                              )
                            }}
                          />
                          <Label className="form-check-label" for="aiUserCbx">
                            AI User
                          </Label>
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">AI Persona</Label>
                          <TextareaAutosize
                            className="form-control"
                            placeholder="Prompt..."
                            minRows={3}
                            onChange={e =>
                              validation.setFieldValue(
                                "aiUserPrompt",
                                e.target.value
                              )
                            }
                            value={validation.values.aiUserPrompt}
                          />
                        </div>
                      </>
                    )}
                  </Col>
                  <Col xs={4}>
                    {userScenarioListString != "" && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: userScenarioListString,
                        }}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    <div>
                      {user && user.userID > 0 && (
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={deleteUser}
                        >
                          Delete
                        </button>
                      )}
                      {currentUser.roleID == 110 && (
                        <button
                          type="button"
                          className="btn btn-primary save-user"
                          onClick={loginAsUser}
                        >
                          Login as User
                        </button>
                      )}
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        style={{ marginRight: "10px" }}
                        onClick={toggleModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary save-user"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Row>
              </Form>
            )}
          </div>
        </>
      )}
    </Modal>
  )
}

export default AddEditUserModal
