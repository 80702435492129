import React from "react";
import { Col } from "reactstrap";

export const DynamicFormText = React.memo(function dynamicFormText({
  text, col, fullWidth,
}) {
  return (
    <Col md={fullWidth ? "12" : col}>
      <p>{text}</p>
    </Col>
  );
});
