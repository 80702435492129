import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import { Field, Formik, useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Col, Form, FormFeedback, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import CurrencyInput from "react-currency-input-field"
import { authenticationService } from "services/authentication.service"
import { userService } from "services/user.service"
import * as Yup from "yup"
import moment from "moment"
import EditTaskModal, {
  TaskTypes,
} from "pages/Projects/EditProject/Modals/EditTaskModal"

const AddEditContractModal = ({
  contract,
  setContract,
  isOpen,
  toggleModal,
  funds,
  years,
  reloadGrid,
  props,
  enterprise,
}) => {
  const currentUser = userService.getLoggedInUser()

  const [tasks, setTasks] = useState([])
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const toggleEditModal = () => setIsEditModalVisible(!isEditModalVisible)
  const [taskToEdit, setTaskToEdit] = useState(null)
  const [entities, setEntities] = useState([])

  const newTask = {
    taskID: -1,
    taskTypeID: TaskTypes.contractTask,
    taskStatusID: -1,
    taskName: "",
    requestTypeID: -1,
    taskDescription: "",
    taskDisposition: "",
    taskRisk: "",
    ownerUserID: currentUser.userID,
  }

  const editTask = (e, task) => {
    e && e.preventDefault()
    setTaskToEdit(task)
    toggleEditModal()
  }

  useEffect(() => {
    if (isOpen) {
      loadEntities()
    }
  }, [isOpen])

  useEffect(() => {
    if (contract != undefined && contract != null && contract.contractID > 0) {
      loadTasks()
    } else {
      validation.resetForm()
    }
  }, [contract])

  const loadEntities = async () => {
    let e = await api.getEntitiesByEnterprise(enterprise.enterpriseID)
    setEntities(e)
  }

  const loadTasks = async () => {
    let tsks = await api.getGeneralTasks(
      TaskTypes.contractTask,
      "Contracts",
      contract.contractID,
      currentUser.userID
    )
    setTasks(tsks)
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      contractNumber: (contract && contract.contractNumber) || "",
      contractName: (contract && contract.contractName) || "",
      description: (contract && contract.description) || "",
      contractAmount: (contract && contract.contractAmount) || 0,
      reductionPercent: (contract && contract.reductionPercent) || 0,
      contractAllocation: (contract && contract.contractAllocation) || 0,
      entityID: (contract && contract.entityID) || 0,
      reference: (contract && contract.reference) || "",
      fundID: (contract && contract.fundID) || "",
      contractYearID: (contract && contract.contractYearID) || "",
      reversionDate: (contract && contract.reversionDate) || "",
    },
    validationSchema: Yup.object({
      contractNumber: Yup.string().required(
        "Please enter the contract numbers"
      ),
      contractName: Yup.string().required("Please enter the contract name"),
      contractAmount: Yup.string().required("Please enter an amount"),
      description: Yup.string(),
    }),
    onSubmit: async values => {
      let updatedContract = {
        ...contract,
        ...values,
      }
      try {
        if (updatedContract.contractID > 0) {
          await api.updateContract(currentUser.userID, updatedContract)
          toast.success("Contract updated successfuly")
        } else {
          await api.createContract(currentUser.userID, updatedContract)
          toast.success("Contract created successfuly")
        }
        toggleModal()
        reloadGrid(false)
      } catch (err) {
        console.log(err)
        alert(err)
      }
    },
  })

  const deleteContract = async () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: "Are you sure you want to delete this contract?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          try {
            await api.deleteContract(currentUser.userID, contract.contractID)
            toggleModal()
            reloadGrid(false)
          } catch (err) {
            console.log(err)
            alert(err)
          }
        }
      })
  }

  return (
    <>
      <Modal
        backdrop="static"
        isOpen={isOpen}
        size="lg"
        toggle={() => {
          toggleModal()
        }}
      >
        {contract && (
          <>
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                {contract.contractID > 0 ? "Edit " : "Add "}{" "}
                {enterprise.contractLabel}
              </h5>
              <button
                type="button"
                onClick={() => {
                  toggleModal()
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row form="true">
                  <Col xs={6}>
                    <div className="mb-3">
                      <Label className="form-label">
                        {enterprise.contractLabel} Number
                      </Label>
                      <Input
                        name="contractNumber"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.contractNumber || ""}
                        invalid={
                          validation.touched.contractNumber &&
                          validation.errors.contractNumber
                            ? true
                            : false
                        }
                      />
                      {validation.touched.contractNumber &&
                      validation.errors.contractNumber ? (
                        <FormFeedback type="invalid">
                          {validation.errors.contractNumber}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">
                        {enterprise.contractLabel} Name
                      </Label>
                      <Input
                        name="contractName"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.contractName || ""}
                        invalid={
                          validation.touched.contractName &&
                          validation.errors.contractName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.contractName &&
                      validation.errors.contractName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.contractName}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Appropriation $</Label>
                      <CurrencyInput
                        name="contractAmount"
                        className="form-control align-end"
                        type="text"
                        prefix={"$"}
                        onValueChange={(value, name, values) => {
                          validation.setFieldValue("contractAmount", value)
                          if (
                            value !== undefined &&
                            parseFloat(value) != NaN &&
                            parseFloat(validation.values.reductionPercent) !=
                              NaN
                          ) {
                            validation.setFieldValue(
                              "contractAllocation",
                              parseFloat(
                                parseFloat(value) *
                                  (1 -
                                    parseFloat(
                                      validation.values.reductionPercent
                                    ) /
                                      100)
                              ).toFixed(2)
                            )
                          }
                        }}
                        onBlur={validation.handleBlur}
                        value={validation.values.contractAmount || ""}
                        invalid={
                          validation.touched.contractAmount &&
                          validation.errors.contractAmount
                            ? true
                            : false
                        }
                      />
                      {validation.touched.contractAmount &&
                      validation.errors.contractAmount ? (
                        <FormFeedback type="invalid">
                          {validation.errors.contractAmount}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">AIPP</Label>
                      <CurrencyInput
                        name="contractAllocation"
                        className="form-control"
                        type="text"
                        suffix="%"
                        onBlur={validation.handleBlur}
                        value={validation.values.reductionPercent || ""}
                        onValueChange={(value, name, values) => {
                          validation.setFieldValue("reductionPercent", value)
                          if (
                            value !== undefined &&
                            parseFloat(value) != NaN &&
                            parseFloat(validation.values.contractAmount) != NaN
                          ) {
                            validation.setFieldValue(
                              "contractAllocation",
                              parseFloat(
                                parseFloat(validation.values.contractAmount) *
                                  (1 - parseFloat(value) / 100)
                              ).toFixed(2)
                            )
                          }
                        }}
                      />

                      {validation.touched.reductionPercent &&
                      validation.errors.reductionPercent ? (
                        <FormFeedback type="invalid">
                          {validation.errors.reductionPercent}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs={6}>
                    {funds && funds.length > 0 && (
                      <div className="mb-3">
                        <Label className="form-label">Fund</Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={validation.values.fundID || ""}
                          onChange={e => {
                            console.log(e.target.value)
                            validation.setFieldValue("fundID", e.target.value)
                          }}
                        >
                          <option value={0}>Select Fund</option>
                          {funds.map((r, idx) => {
                            return (
                              <option key={idx} value={`${r.listItemID}`}>
                                {r.listItemName}
                              </option>
                            )
                          })}
                        </select>
                        {validation.touched.fundID &&
                        validation.errors.fundID ? (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {validation.errors.fundID}
                          </div>
                        ) : null}
                      </div>
                    )}

                    {years && years.length > 0 && (
                      <div className="mb-3">
                        <Label className="form-label">Year</Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={validation.values.contractYearID || ""}
                          onChange={e => {
                            validation.setFieldValue(
                              "contractYearID",
                              e.target.value
                            )
                          }}
                        >
                          {<option value={0}>Select Year</option>}
                          {years.map((r, idx) => {
                            return (
                              <option key={idx} value={`${r.listItemName}`}>
                                {r.listItemName}
                              </option>
                            )
                          })}
                        </select>
                        {validation.touched.contractYearID &&
                        validation.errors.contractYearID ? (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {validation.errors.contractYearID}
                          </div>
                        ) : null}
                      </div>
                    )}

                    <div className="mb-3">
                      <Label className="form-label">Reversion Date</Label>
                      <Input
                        name="reversionDate"
                        type="date"
                        onChange={e => {
                          validation.setFieldValue(
                            "reversionDate",
                            moment(e.target.value, "YYYY-MM-DD").format(
                              "YYYY-MM-DDThh:mm:ss"
                            )
                          )
                        }}
                        value={moment(validation.values.reversionDate).format(
                          "YYYY-MM-DD"
                        )}
                      />
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Allocation</Label>
                      <CurrencyInput
                        name="contractAllocation"
                        className="form-control align-end"
                        type="text"
                        prefix={"$"}
                        onBlur={validation.handleBlur}
                        value={validation.values.contractAllocation || ""}
                        onValueChange={(value, name, values) => {
                          validation.setFieldValue("contractAllocation", value)
                        }}
                      />
                    </div>

                    {entities && entities.length > 0 && (
                      <div className="mb-3">
                        <Label className="form-label">Entity</Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={validation.values.entityID || ""}
                          onChange={e => {
                            validation.setFieldValue("entityID", e.target.value)
                          }}
                        >
                          {<option value={0}>Select Entity</option>}
                          {entities.map((r, idx) => {
                            return (
                              <option key={idx} value={`${r.entityID}`}>
                                {r.entityName}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mb-3">
                      <Label className="form-label">Reference</Label>
                      <Input
                        name="reference"
                        type="text"
                        onBlur={validation.handleBlur}
                        value={validation.values.reference || ""}
                        onChange={e => {
                          validation.setFieldValue("reference", e.target.value)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Description</Label>
                      <Input
                        name="description"
                        type="textarea"
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ""}
                        onChange={validation.handleChange}
                        invalid={
                          validation.touched.description &&
                          validation.errors.description
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <table
                      className="table table-bordered low-padding-table"
                      style={{ backgroundColor: "white" }}
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "25%" }}>Item</th>
                          <th style={{ width: "20%" }}>Status</th>
                          <th style={{ width: "15%" }}>Date</th>
                          <th style={{ width: "40%" }}>Notes</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tasks != null &&
                          tasks.map((task, idx) => (
                            <tr key={idx}>
                              <td>
                                <a href="#" onClick={e => editTask(e, task)}>
                                  {task.taskName}
                                </a>
                              </td>
                              <td>
                                <div
                                  style={{ marginLeft: "5px" }}
                                  dangerouslySetInnerHTML={{
                                    __html: task.taskStatus,
                                  }}
                                ></div>
                              </td>
                              <td className="text-right">
                                {}
                                {moment(task.completeDT).isValid()
                                  ? moment(task.completeDT).format("MM/DD/YYYY")
                                  : ""}
                              </td>
                              <td>{task.taskDisposition}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>

                <Row>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    <div>
                      {contract && contract.contractID > 0 && (
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={deleteContract}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        style={{ marginRight: "10px" }}
                        onClick={toggleModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary save-user"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Row>
              </Form>
            </div>
          </>
        )}
      </Modal>

      <EditTaskModal
        isEditModalVisible={isEditModalVisible}
        toggleEditModal={toggleEditModal}
        taskToEdit={taskToEdit}
        setTaskToEdit={setTaskToEdit}
        reload={loadTasks}
      />
    </>
  )
}

export default AddEditContractModal
