import NameAvatar from "components/custom/nameAvatar"
import {
  swalWithConfirmAndCancelButtons,
  swalWithConfirmButton,
} from "components/custom/swal"
import UploadModal from "components/GlobalModals.js/UploadModal"
import useModal from "hooks/useModalHook"
import React, { useEffect, useRef, useState } from "react"
import { createRoot } from "react-dom/client"
import Loader from "react-loaders"
import { ReactTabulator, reactFormatter } from "react-tabulator"
import { toast } from "react-toastify"
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Row,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import ParticipantEmailModal from "./ParticipantEmailModal"
import {
  getSelectHtmlFilterValues,
  getSelectedVisibleRows,
} from "utils/tabulatorFilter"
import { customFormatter } from "utils/tabulator"

const ParticipantsGrid = ({
  participants,
  isLoading,
  setParticipant,
  toggleEditModal,
  reloadData,
  scenarioId,
}) => {
  var tabulatorRef = useRef(null)
  const currentUser = userService.getLoggedInUser()
  let [showFilters, setShowFilters] = useState(true)
  const [showActionsDropdown, setShowActionsDropdown] = useState(false)
  const [showEmailModal, setShowEmailModal] = useState(false)
  const toggleEmailModal = () => setShowEmailModal(!showEmailModal)
  const { show: showUploadUsersModal, toggle: toggleUploadUsersModal } =
    useModal()
  const { show: showBatchDropdown, toggle: toggleBatchDropdown } = useModal()
  const [selectedParticipants, setSelectedParticipants] = useState([])
  const [elementsCount, setElementsCount] = useState(0)

  const handleNamePress = (e, participant) => {
    e && e.preventDefault()
    console.log(participant)
    setParticipant(participant)
    toggleEditModal()
  }

  const toggleFilters = () => {
    if (!showFilters) {
      tabulatorRef.current.updateColumnDefinition("user.fullName", {
        headerFilter: "input",
      })
      tabulatorRef.current.updateColumnDefinition("user.email", {
        headerFilter: "input",
      })
      tabulatorRef.current.updateColumnDefinition("modelRole", {
        headerFilter: "input",
        // headerFilterParams: {
        //   values: true,
        //   sortValuesList: "asc",
        // },
      })
      tabulatorRef.current.updateColumnDefinition("altType", {
        headerFilter: "select",
        headerFilterParams: {
          values: true,
          sortValuesList: "asc",
        },
      })
      tabulatorRef.current.updateColumnDefinition("pwcType", {
        headerFilter: "select",
        headerFilterParams: {
          values: true,
          sortValuesList: "asc",
        },
      })
      tabulatorRef.current.updateColumnDefinition("crApprover", {
        headerFilter: "select",
        headerFilterParams: {
          values: true,
          sortValuesList: "asc",
        },
      })
      tabulatorRef.current.updateColumnDefinition("reviewerRole", {
        headerFilter: "select",
        headerFilterParams: {
          values: true,
          sortValuesList: "asc",
        },
      })
      tabulatorRef.current.updateColumnDefinition("inactive", {
        headerFilter: "select",
        headerFilterParams: {
          values: {
            false: "<span style='color:green'>Active</span>",
            true: "<span style='color:red'><b>Inactive</b></span>",
          },
        },
      })
    } else {
      tabulatorRef.current.updateColumnDefinition("user.fullName", {
        headerFilter: false,
      })
      tabulatorRef.current.updateColumnDefinition("user.email", {
        headerFilter: false,
      })
      tabulatorRef.current.updateColumnDefinition("modelRole", {
        headerFilter: false,
      })
      tabulatorRef.current.updateColumnDefinition("altType", {
        headerFilter: false,
      })
      tabulatorRef.current.updateColumnDefinition("pwcType", {
        headerFilter: false,
      })
      tabulatorRef.current.updateColumnDefinition("crApprover", {
        headerFilter: false,
      })
      tabulatorRef.current.updateColumnDefinition("reviewerRole", {
        headerFilter: false,
      })
      tabulatorRef.current.updateColumnDefinition("inactive", {
        headerFilter: false,
      })
    }
    setShowFilters(!showFilters)
  }

  const columns = [
    {
      formatter: "rowSelection",
      titleFormatter: "rowSelection",
      hozAlign: "center",
      headerSort: false,
      cellClick: function (e, cell) {
        cell.getRow().toggleSelect()
      },
      width: 50,
    },
    {
      title: "Name",
      field: "user.fullName",
      headerFilter: "input",
      minWidth: 150,
      widthGrow: 3,
      formatter: customFormatter(({ rowData, cellValue }) => (
        <a href="#" onClick={e => handleNamePress(e, rowData)}>
          <NameAvatar
            fullName={cellValue}
            showName={true}
            aiUser={rowData.aiUser}
            base64Image={rowData.photoBase64}
          />
        </a>
      ))
    },
    {
      title: "Email",
      field: "user.email",
      headerFilter: "input",
      widthGrow: 3,
      minWidth: 120,
    },
    {
      title: "Decision Role",
      field: "modelRole",
      // headerFilter: "input",
      headerFilter: "select",
      headerFilterFunc: "in",
      headerFilterParams: {
        // values: [...new Set(users.map(u => u.role))],
        values: getSelectHtmlFilterValues(
          new Set(participants.map(p => p.modelRole))
        ),
        sortValuesList: "asc",
        multiselect: true,
      },
      widthGrow: 2,
      minWidth: 100,
      formatter: (cell, formatterParams, onRendered) => {
        return cell.getValue()
      },
    },
    {
      title: "Criteria Prioritization Mode",
      field: "pwcType",
      headerFilter: "select",
      headerFilterParams: {
        values: true,
        sortValuesList: "asc",
      },
      widthGrow: 2,
      minWidth: 100,
    },
    {
      title: "Alternative Scoring Mode",
      field: "altType",
      headerFilter: "select",
      headerFilterParams: {
        values: true,
        sortValuesList: "asc",
      },
      widthGrow: 2,
      minWidth: 100,
    },
    {
      title: "Reviewer Role",
      field: "reviewerRole",
      headerFilter: "select",
      headerFilterParams: {
        values: true,
        sortValuesList: "asc",
      },
      widthGrow: 2,
      minWidth: 100,
    },
    {
      title: "CR Approver Rights",
      field: "crApprover",
      headerFilter: "select",
      headerFilterParams: {
        values: true,
        sortValuesList: "asc",
      },
      widthGrow: 2,
      minWidth: 100,
    },
    {
      title: "Voting Weight",
      field: "votingWeight",
      widthGrow: 2,
      minWidth: 100,
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getValue() * 100 + "%"
      },
    },
    // {
    //   title: "Role",
    //   field: "role",
    //   headerFilter: "select",
    //   headerFilterParams: {
    //     values: true,
    //     sortValuesList: "asc",
    //   },
    //   widthGrow: 2,
    // },
    {
      title: "State",
      field: "inactive",
      minWidth: 100,
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getValue() == 0
          ? "<span style='color:green'>Active</span>"
          : "<span style='color:red'><b>Inactive</b></span>"
      },
      headerFilter: "select",
      headerFilterParams: {
        values: {
          false: "Active",
          true: "Inactive",
        },
      },
      widthGrow: 2,
    },
  ]

  const deleteSelected = () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete the selected participants?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          let selected = [...selectedParticipants]
          if (selected.length > 0) {
            let copy = [...selected]
            let tasks = copy.map(x => async () => {
              return await api.deleteParticipant(
                currentUser.userID,
                x.participantID
              )
            })
            await Promise.all(tasks.map(t => t()))
            reloadData()
            toast.success("Participants removed successfuly")
          }
        }
      })
  }

  const updateSelected = async (prop, value) => {
    let selected = [...selectedParticipants]
    if (selected.length > 0) {
      let tasks = selected.map(x => async () => {
        x[prop] = value
        return await api.updateParticipant(currentUser.userID, x)
      })
      await Promise.all(tasks.map(t => t()))
      reloadData()
      toast.success("Participants updated successfuly")
    }
  }

  const sendCriteriaInvitaions = async () => {
    let selected = [...selectedParticipants]
    if (selected.length > 0) {
      let tasks = selected.map(x => async () => {
        return await api.sendParticipantInvite("objective", x)
      })
      await Promise.all(tasks.map(t => t()))
      toast.success("Invitations sent")
    } else {
      swalWithConfirmButton.fire({
        title: `You must select at least 1 Participant`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    }
  }

  const sendAlternativeInvitaions = async () => {
    let selected = [...selectedParticipants]
    if (selected.length > 0) {
      let tasks = selected.map(x => async () => {
        return await api.sendParticipantInvite("alternative", x)
      })
      await Promise.all(tasks.map(t => t()))
      toast.success("Invitations sent")
    } else {
      swalWithConfirmButton.fire({
        title: `You must select at least 1 Participant`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    }
  }

  const sendParticipantLinks = async email => {
    await api.sendParticipantLinks(scenarioId, email)
    toast.success("Email sent")
    toggleEmailModal()
  }

  const uploadParticipants = async file => {
    return await api.uploadParticipants(currentUser.userID, scenarioId, file)
  }

  return (
    <React.Fragment>
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      {!isLoading && participants.length > 0 && (
        <>
          <Row>
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Dropdown
                isOpen={showBatchDropdown}
                toggle={() => toggleBatchDropdown()}
              >
                <DropdownToggle
                  tag="button"
                  className="btn btn-sm btn-outline-primary"
                >
                  Batch Updates <i className="mdi mdi-chevron-down" />
                </DropdownToggle>

                <DropdownMenu>
                  <DropdownItem onClick={deleteSelected}>
                    <i className="fas fa-trash-alt"></i> Remove
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => updateSelected("pwcTypeID", "1")}
                  >
                    Set Criteria Prioritization Mode - Group
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => updateSelected("pwcTypeID", "2")}
                  >
                    Set Criteria Prioritization Mode - Individual
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => updateSelected("pwcTypeID", "0")}
                  >
                    Set Criteria Prioritization Mode - N/A
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => updateSelected("altTypeID", "1")}
                  >
                    Set Alternative Scoring Mode - Group
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => updateSelected("altTypeID", "2")}
                  >
                    Set Alternative Scoring Mode - Individual
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => updateSelected("altTypeID", "0")}
                  >
                    Set Alternative Scoring Mode - N/A
                  </DropdownItem>
                  <DropdownItem onClick={() => sendCriteriaInvitaions()}>
                    Send Criteria Prioritization Invitation(s)
                  </DropdownItem>
                  <DropdownItem onClick={() => sendAlternativeInvitaions()}>
                    Send Alternative Scoring Invitation(s)
                  </DropdownItem>
                  <DropdownItem onClick={() => toggleEmailModal()}>
                    Send Participant Links To...
                  </DropdownItem>
                  <DropdownItem onClick={() => toggleUploadUsersModal()}>
                    <i className="fas fa-cloud-upload-alt"></i> Upload
                    Participants
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <button
                  onClick={toggleFilters}
                  className="btn btn-sm btn-outline-primary"
                >
                  {showFilters ? "Hide" : "Show"}{" "}
                  <i className="fas fa-search"></i>{" "}
                  <i className="fas fa-filter"></i>
                </button>
                <Dropdown
                  className="save-user"
                  isOpen={showActionsDropdown}
                  toggle={() => setShowActionsDropdown(!showActionsDropdown)}
                >
                  <DropdownToggle
                    tag="button"
                    className="btn btn-sm btn-outline-primary"
                  >
                    Action Menu <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>

                  <DropdownMenu>
                    <DropdownItem onClick={deleteSelected}>Remove</DropdownItem>

                    <DropdownItem onClick={() => toggleUploadUsersModal()}>
                      Upload Participants
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>{elementsCount} Items</Label>
            </Col>
          </Row>
          <ReactTabulator
            onRef={r => (tabulatorRef.current = r.current)}
            columns={columns}
            data={participants}
            options={{
              pagination: "local",
              paginationSize: 30,
              paginationSizeSelector: [5, 10, 20, 30, 50, 100, 200, 300],
              layout: "fitColumns",
              minHeight: 400,
              cssClass: "table-borderless",
            }}
            events={{
              rowSelectionChanged: function (data, rows) {
                let selectedVisibleData = getSelectedVisibleRows(tabulatorRef)

                setSelectedParticipants(selectedVisibleData)
              },
              dataFiltered: function (data, rows) {
                setElementsCount(rows == undefined ? 0 : rows.length)
              },
            }}
          />
        </>
      )}
      <ParticipantEmailModal
        isOpen={showEmailModal}
        toggleModal={toggleEmailModal}
        sendEmail={sendParticipantLinks}
      />
      <UploadModal
        isOpen={showUploadUsersModal}
        toggleModal={toggleUploadUsersModal}
        text="Upload a file containing participants"
        callback={uploadParticipants}
        fileType="Participants"
        reload={reloadData}
      />
    </React.Fragment>
  )
}

export default ParticipantsGrid
