import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

// resourceEstimateLogModel:{
//     alternativeId,
//     costPoolId,
//     resourceGroupId
// }

const ResourceEstimateLogModal = ({
  isOpen,
  toggleModal,
  resourceEstimateLogModel,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [estimateLog, setEstimateLog] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isOpen && resourceEstimateLogModel != null) {
      loadData()
    }
  }, [isOpen, resourceEstimateLogModel])

  const loadData = async () => {
    if (!isLoading) {
      setIsLoading(true)
      try {
        let data = await api.getResourceEstimateLog(
          resourceEstimateLogModel.alternativeId,
          resourceEstimateLogModel.resourceGroupId,
          resourceEstimateLogModel.costPoolId
        )
        setEstimateLog(data)
        setIsLoading(false)
      } catch {
        setIsLoading(false)
      }
    }
  }

  const save = async () => {}

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Resource Estimate Log
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        {!isLoading && estimateLog.length == 0 && <p>No data.</p>}
        {!isLoading && estimateLog.length > 0 && (
          <table
            className="table table-bordered low-padding-table"
            style={{ backgroundColor: "white" }}
          >
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Log Information</th>
              </tr>
            </thead>
            <tbody>
              {estimateLog.map((e, idx) => (
                <tr key={idx}>
                  <td>
                    {e.logDT == null
                      ? ""
                      : moment(e.logDT).format("MM/DD/YYYY")}
                  </td>
                  <td>{e.userName}</td>
                  <td>{e.logInfo}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
      </div>
    </Modal>
  )
}

export default ResourceEstimateLogModal
