import React from "react"
import { Fragment } from "react"
import { Tooltip } from "@material-ui/core"

const ObjectiveInfoContainer = React.memo(function objectiveInfoContainer({
  nextObjectivePwcScore,
  scenario,
  showDefinitions,
  showMeanScore,
  showStandardDev,
}) {
  if (nextObjectivePwcScore == null) {
    return <Fragment></Fragment>
  } else {
    return (
      <div style={{ paddingRight: "0px" }}>
        <div
          style={{
            textAlign: "center",
            marginTop: "30px",
            borderRadius: "10px",
            padding: "15px",
          }}
        >
          <h5>
            Comparison{" "}
            <b>
              <u>{nextObjectivePwcScore.comparison}</u>
            </b>
          </h5>
          <h5>
            With respect to the{" "}
            <Tooltip
              title={
                <h6>
                  <b>{scenario != null && scenario.decisionGoal}</b>
                </h6>
              }
            >
              <span style={{ color: "maroon", fontWeight: "600" }}>
                {scenario != null &&
                  (nextObjectivePwcScore.levelID == 2
                    ? scenario.objectiveName.toLowerCase()
                    : "decision goal")}
              </span>
            </Tooltip>
            , which{" "}
            {scenario != null &&
              (nextObjectivePwcScore.levelID == 2
                ? scenario.subCriteriaName.toLowerCase()
                : scenario.objectiveName.toLowerCase())}{" "}
            is more important?
          </h5>
        </div>
        {nextObjectivePwcScore.levelID == 2 && (
          <div
            style={{
              marginTop: "20px",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                textAlign: "center",
                backgroundColor: "lightyellow",
                borderColor: "black",
                borderWidth: "1px",
                borderStyle: "solid",
                borderRadius: "10px",
                width: "50%",
                padding: "15px",
              }}
            >
              <h5 style={{ color: "maroon", fontWeight: 600 }}>
                {nextObjectivePwcScore.subHeader}
              </h5>
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              backgroundColor:
                nextObjectivePwcScore.levelID == 2
                  ? "lightblue"
                  : "lightyellow",
              padding: "15px",
              borderRadius: "10px",
              borderColor: "gray",
              borderWidth: "1px",
              borderStyle: "solid",
              width: "30%",
              textAlign: "center",
            }}
          >
            <Tooltip
              title={
                <h6>
                  <b>{nextObjectivePwcScore.item1Description}</b>
                </h6>
              }
            >
              <span
                style={{
                  color: "maroon",
                  fontWeight: "600",
                  fontSize: "20px",
                }}
              >
                {nextObjectivePwcScore.item1}
              </span>
            </Tooltip>

            {showDefinitions && (
              <>
                <br />
                <span>{nextObjectivePwcScore.item1Description}</span>
              </>
            )}
          </div>
          {(showMeanScore || showStandardDev) && (
            <div
              style={{
                textAlign: "center",
                backgroundColor: "white",
                borderRadius: "10px",
                border: "1px solid gray",
                padding: "15px",
                alignSelf: "center",
              }}
            >
              {showMeanScore && (
                <p style={{ marginBottom: "0px" }}>
                  <b>
                    The mean score is:{" "}
                    {nextObjectivePwcScore.avgUserRanking < 0
                      ? -1 * nextObjectivePwcScore.avgUserRanking
                      : nextObjectivePwcScore.avgUserRanking}
                  </b>
                </p>
              )}
              {showStandardDev && (
                <p style={{ marginBottom: "0px" }}>
                  <b>
                    The standard deviation is: {nextObjectivePwcScore.standDev}
                  </b>
                </p>
              )}
            </div>
          )}
          <div
            style={{
              backgroundColor:
                nextObjectivePwcScore.levelID == 2
                  ? "lightblue"
                  : "lightyellow",
              padding: "15px",
              borderRadius: "10px",
              borderColor: "gray",
              borderWidth: "1px",
              borderStyle: "solid",
              width: "30%",
              textAlign: "center",
            }}
          >
            <Tooltip
              title={
                <h6>
                  <b>{nextObjectivePwcScore.item2Description}</b>
                </h6>
              }
            >
              <span
                style={{
                  color: "maroon",
                  fontWeight: "600",
                  fontSize: "20px",
                }}
              >
                {nextObjectivePwcScore.item2}
              </span>
            </Tooltip>
            {showDefinitions && (
              <>
                <br />
                <span>{nextObjectivePwcScore.item2Description}</span>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
})

export default ObjectiveInfoContainer
