import React from "react";
import { Col, Label, Input } from "reactstrap";

export const DynamicFormInput = React.memo(function dynamicFormInput({
  formFieldId, label, value, placeholder, changeFormFieldValue, type, //text / number
  col, fullWidth, alwaysEditable, alwaysReadOnly, hasError, requiredMessage,
}) {
  return (
    <Col md={fullWidth ? "12" : col}>
      <div className="mb-3">
        <Label className="form-label">{label}</Label>
        <Input
          type={type}
          onChange={e => changeFormFieldValue(formFieldId, e.target.value)}
          value={value}
          placeholder={placeholder || ""}
          disabled={alwaysReadOnly} />
        {hasError && (
          <div className="invalid-feedback" style={{ display: "block" }}>
            {requiredMessage}
          </div>
        )}
      </div>
    </Col>
  );
});
