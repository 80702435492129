import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Col, Modal, Row } from "reactstrap"
import api from "services/api.service"

const DocumentsModal = ({
  isOpen,
  toggleModal,
  documents,
  docType,
  reloadData,
}) => {
  const deleteDocument = document => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this document?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteDocument(document.alternativeID, document.documentID)
          reloadData()
          toggleModal()
        }
      })
  }
  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {docType} Documents
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {documents.length > 0 &&
          documents
            .filter(x => docType == "" || x.docType == docType)
            .map((d, idx) => {
              return (
                <div
                  key={idx}
                  style={{
                    width: "100%",
                    borderBottom: "1px solid lightgray",
                    marginTop: "15px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <a
                      href={d.docPath}
                      download=""
                      target="_blank"
                      rel="noreferrer"
                    >
                      <b>{d.docTitle}</b>
                    </a>
                    {d.docType != "" && (
                      <p style={{ marginBottom: "0px" }}>{d.docType}</p>
                    )}
                    <p>
                      {moment(d.docDT).format("MM/DD/YYYY")} - {d.firstName}{" "}
                      {d.lastName}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignSelf: "center",
                    }}
                  >
                    {/* <a href="#" onClick={e => editDocument(e, d)}>
                      <i
                        className="fas fa-edit"
                        style={{
                          color: colors.primary,
                        }}
                      ></i>
                    </a> */}
                    <i
                      className="fas fa-trash"
                      style={{
                        color: "red",
                        cursor: "pointer",
                        marginTop: "4px",
                      }}
                      onClick={() => deleteDocument(d)}
                    ></i>
                  </div>
                </div>
              )
            })}
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
      </div>
    </Modal>
  )
}

export default DocumentsModal
