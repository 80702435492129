import moment from "moment"
import React, { useContext, useEffect, useState } from "react"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import ProjectsContext from "./Context/ProjectsContext"
import Multiselect from "multiselect-react-dropdown"

const ConfigModal = ({
  isOpen,
  toggleModal,
  scenarioId,
  configType,
  reloadData,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [configItems, setConfigItems] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (isOpen) {
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    let type =
      configType == undefined
        ? scenarioId == 0
          ? "EnterpriseAlternativeList"
          : "AlternativeList"
        : configType
    let cfg = await api.getConfigItems(currentUser.userID, scenarioId, type)
    setConfigItems(
      cfg
        .sort((a, b) => {
          let fa = a.displayName.toLowerCase(),
            fb = b.displayName.toLowerCase()

          if (fa < fb) {
            return -1
          }
          if (fa > fb) {
            return 1
          }
          return 0
        })
        .map((x, idx) => {
          x.id = idx
          return x
        })
    )
  }

  const handleSaveConfigItems = async () => {
    await saveConfigItems(configItems)
  }

  const resetToDefaults = async () => {
    let listCopy = [...configItems]
    listCopy = listCopy.map(x => {
      x.isDirty = true
      x.configItemValue = x.defaultValue
      return x
    })
    await saveConfigItems(listCopy)
  }

  const saveConfigItems = async allConfigItems => {
    try {
      setIsSubmitting(true)
      let updateTasks = allConfigItems
        .filter(x => x.isDirty)
        .map(x => async () => {
          return await api.updateConfigItem(currentUser.userID, scenarioId, {
            ...x,
            scenarioID: scenarioId,
          })
        })
      if (updateTasks.length > 0) {
        await Promise.all(updateTasks.map(t => t()))
      }
      setIsSubmitting(false)
      toggleModal()
      await reloadData()
    } catch (err) {
      setIsSubmitting(false)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      backdrop="static"
      size="xl"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Edit Columns
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <ConfigSection
          isOpen={isOpen}
          configItems={configItems}
          setConfigItems={setConfigItems}
        />
      </div>
      <div className="modal-footer">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={resetToDefaults}
            disabled={isSubmitting}
          >
            Reset to Defaults
          </button>

          <div>
            <button
              type="button"
              className="btn btn-outline-secondary"
              style={{ marginRight: "10px" }}
              onClick={toggleModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary save-user"
              onClick={handleSaveConfigItems}
              disabled={isSubmitting}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export const ConfigSection = ({
  isOpen,
  configItems,
  setConfigItems,
  isMultiSelect = false,
}) => {
  const currentUser = userService.getLoggedInUser()
  const {
    bcStatusOptions,
    baselineLogicOptions,
    completeOptions,
    brmOptions,
    sponsorOptions,
    ldoOptions,
    ldoListOptions,
    pmOptions,
    deliveryOptions,
    submitterOptions,
    workflowOptions,
    alternativePhaseOptions,
    advancedPlanningOptions,
    cpPhaseOptions,
    planningOptions,
    investmentOptions,
    decisionOptions,
    versionOptions,
    reloadData,
    programs,
    excludedItemsOptions
  } = useContext(ProjectsContext)
  const dropdowns = [
    {
      displayName: "[BCStateCB]",
      sourceList: "General/BCStatus",
      options: bcStatusOptions,
    },
    {
      displayName: "[ShowBRMCB]",
      sourceList: "Scenario/BRMList",
      options: brmOptions,
    },
    {
      displayName: "[ShowCompletedCB]",
      sourceList: "General/Complete",
      options: completeOptions,
    },
    {
      displayName: "[ShowDecisionCB]",
      sourceList: "General/Decisions",
      options: decisionOptions,
    },
    {
      displayName: "[ShowDeliveryPOCCB]",
      sourceList: "Scenario/DeliveryPOCList",
      options: deliveryOptions,
    },
    {
      displayName: "[ShowInvestmentCategoryCB]",
      sourceList: "Scenario/InvestmentCategory",
      options: investmentOptions,
    },
    {
      displayName: "[ShowLeadDeliveryOrgCB]",
      sourceList: "Scenario/LDO",
      options: ldoOptions,
    },
    {
      displayName: "[ShowPMCB]",
      sourceList: "Scenario/PMList",
      options: pmOptions,
    },
    {
      displayName: "[ProjectCategoryCB]",
      sourceList: "Scenario/PlanningCategory",
      options: planningOptions,
    },
    {
      displayName: "[ShowOrgCB]",
      sourceList: "Scenario/SponsorList",
      options: sponsorOptions,
    },
    {
      displayName: "[ShowSubmitterCB]",
      sourceList: "Scenario/SubmitterList",
      options: submitterOptions,
    },
    {
      displayName: "[WorkFlowStatusCB]",
      sourceList: "Scenario/WorkflowStatus",
      options: workflowOptions,
    },
    {
      displayName: "[AlternativePhaseCB]",
      sourceList: "Scenario/AlternativePhase",
      options: alternativePhaseOptions,
    },    
    {
      displayName: "[ShowProgramCB]",
      sourceList: "Enterprise/Programs",
      options: programs,
    },
    {
      displayName: "[AdvancedPlanningCB]",
      sourceList: "Scenario/AdvancedPlanning",
      options: advancedPlanningOptions,
    },
    {
      displayName: "[CPICPhaseCB]",
      sourceList: "General/CPICPhase",
      options: cpPhaseOptions,
    },
    {
      displayName: "[BaselineLogicCB]",
      sourceList: "General/BaselineLogic",
      options: baselineLogicOptions,
    },
    {
      displayName: "[VersionCB]",
      sourceList: "General/BaselineLogic",
      options: baselineLogicOptions,
    },
    {
      displayName: "[Version2CB]",
      sourceList: "General/Versions",
      options: versionOptions,
    },
    {
      displayName: "[ShowExcludeCB]",
      sourceList: "General/YesNo",
      options: excludedItemsOptions,
    },
  ]

  const changeConfigItem = (configItem, value) => {
    let listCopy = [...configItems]
    let original = listCopy.find(x => x.id == configItem.id)
    original.configItemValue = value
    original.isDirty = true
    setConfigItems(listCopy)
  }

  const getElement = (configItem, key) => {
    if (configItem.configType == "Pager") {
      // let val =
      //   configItem.configItemValue !== undefined &&
      //   configItem.configItemValue !== null &&
      //   configItem.configItemValue !== ""
      //     ? configItem.configItemValue
      //     : configItem.defaultValue
      return (
        <div key={key} className="mb-3">
          <Label>Page Size</Label>
          <Input
            type="number"
            value={configItem.configItemValue}
            onChange={e => changeConfigItem(configItem, e.target.value)}
          />
        </div>
      )
    } else if (configItem.configType == "CB") {
      let ddlItem = configItems.find(
        x =>
          x.displayName == `[${configItem.configItemName}]` &&
          x.configType == "DDL"
      )
      
      let options = []
      let useIdForDdl = false
      if (ddlItem != undefined) {
        let ddl = dropdowns.find(x => x.displayName == ddlItem.displayName)
        if (ddl == undefined) {
          return null
        }
        options = ddl.options
        useIdForDdl = options.filter(x => x.listItemID > 0).length > 0
      }

      let dateItem = configItems.find(
        x =>
          x.displayName == `[${configItem.configItemName}]` &&
          x.configType == "Date"
      )

      return (
        <div style={{ width: "100%" }} key={key}>
          <div
            className="mb-3"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <Input
                type="checkbox"
                className="form-check-input"
                id={configItem.displayName + "1"}
                defaultChecked={configItem.configItemValue == "1"}
                onChange={e => {
                  changeConfigItem(
                    configItem,
                    configItem.configItemValue == "1" ? "0" : "1"
                  )
                }}
              />
              <Label
                className="form-check-label"
                for={configItem.displayName + "1"}
                style={{ marginLeft: "10px" }}
              >
                {configItem.displayName}
              </Label>
            </div>
            {ddlItem != undefined && !isMultiSelect && (
              <div style={{ width: "48%" }}>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  style={{ paddingTop: "0px", paddingBottom: "0px" }}
                  value={ddlItem.configItemValue}
                  onChange={e => {
                    changeConfigItem(ddlItem, e.target.value)
                  }}
                >
                  <option value={ddlItem.defaultValue}>
                    {ddlItem.defaultText}
                  </option>
                  {options.map((a, idx) => {
                    return (
                      <option
                        key={idx}
                        value={useIdForDdl ? a.listItemID : a.listItemName}
                      >
                        {a.listItemName}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}
            {ddlItem != undefined && isMultiSelect && (
              <div style={{ width: "48%" }}>
                <Multiselect
                  options={options.map(o => {
                    return {
                      name: o.listItemName,
                      id: useIdForDdl ? o.listItemID : o.listItemName,
                    }
                  })}
                  selectedValues={
                    ddlItem.configItemValue == null ||
                    ddlItem.configItemValue == "0"
                      ? ""
                      : ddlItem.configItemValue.split(",").map(x => {
                          let opt = options
                            .map(o => {
                              return {
                                name: o.listItemName,
                                id: useIdForDdl ? o.listItemID : o.listItemName,
                              }
                            })
                            .find(o => o.id == x)
                          return { ...opt }
                        })
                  }
                  onSelect={data => {
                    changeConfigItem(
                      ddlItem,
                      data == undefined || data == null || data.lenght == 0
                        ? []
                        : data.map(x => x.id).join(",")
                    )
                  }}
                  onRemove={data => {
                    changeConfigItem(
                      ddlItem,
                      data == undefined || data == null || data.lenght == 0
                        ? []
                        : data.map(x => x.id).join(",")
                    )
                  }}
                  displayValue="name"
                  showCheckbox={true}
                />
              </div>
            )}
            {dateItem != undefined && (
              <div style={{ width: "48%" }}>
                <Input
                  name="reviewDate"
                  type="date"
                  //style={{ paddingTop: "0px", paddingBottom: "0px" }}
                  onChange={e =>
                    changeConfigItem(
                      dateItem,
                      moment(e.target.value, "YYYY-MM-DD").format(
                        "YYYY-MM-DDThh:mm:ss"
                      )
                    )
                  }
                  value={moment(dateItem.configItemValue).format("YYYY-MM-DD")}
                />
              </div>
            )}
          </div>
        </div>
      )
    }
  }

  const renderItems = () => {
    const cbs = [...configItems].filter(x => x.configType == "CB")
    const length = cbs.length
    const list1 = cbs.slice(0, Math.round(length / 2))
    const list2 = cbs.slice(Math.round(length / 2), length)
    let pageItemConfig = configItems.find(
      x => x.configItemName == "AlternativesPageNum"
    )
    if (pageItemConfig !== undefined) {
      list2.push({ ...pageItemConfig })
    }

    return (
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
          }}
        >
          {list1.map((x, idx) => {
            return getElement(x, `first${idx}`)
          })}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
          }}
        >
          {list2.map((x, idx) => {
            return getElement(x, `second${idx}`)
          })}
        </div>
        {/* <Col md="6">
          {list1.map((x, idx) => {
            return getElement(x, `first${idx}`)
          })}
        </Col>
        <Col md="6">
          {list2.map((x, idx) => {
            return getElement(x, `second${idx}`)
          })}
        </Col> */}
      </Row>
    )
  }

  return (
    <React.Fragment>
      {isOpen && configItems.length > 0 && renderItems()}
    </React.Fragment>
  )
}

export default ConfigModal
