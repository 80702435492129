import React, { useEffect, useState } from "react"
import FormDesignerModal from "./Manage/FormDesignerModal"
import useModal from "hooks/useModalHook"
import { Col, Container, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import DynamicForm from "./View/DynamicForm"
import DynamicFormModal from "./View/DynamicFormModal"
import { useDispatch, useSelector } from "react-redux"
import { changeNavbarParams, loadPageItem } from "store/actions"
import Breadcrumbs from "../../Common/Breadcrumb"

const ManageForms = props => {
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [forms, setForms] = useState([])
  const { show: showFormModal, toggle: toggleFormModal } = useModal()
  const { show: showDynamicFormModal, toggle: toggleDynamicFormModal } =
    useModal()
  const [formIdToEdit, setFormIdToEdit] = useState(-1)
  const [formIdToEditValues, setFormIdToEditValues] = useState(-1)
  const [formTypes, setFormTypes] = useState([])

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: 0,
        alternativeId: 0,
        viewName: "manageforms",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: 0,
        viewName: "manageforms",
      })
    )

    loadData()
  }, [])

  const loadData = async () => {
    let ft = await api.getFormTypes()
    setFormTypes(ft)
    let f = await api.getForms(currentUser.enterpriseID)
    setForms(f)
  }

  const editForm = form => {
    setFormIdToEdit(form.formId)
    toggleFormModal()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <button
            className="btn btn-primary"
            onClick={() => {
              setFormIdToEdit(-1)
              toggleFormModal()
            }}
          >
            <i className="fas fa-plus"></i> Create Form
          </button>
          
          <Row>
            {forms.map((f, idx) => (
              <FormItem
                key={idx}
                form={f}
                editForm={editForm}
                formTypes={formTypes}
              />
            ))}
          </Row>

          <FormDesignerModal
            isOpen={showFormModal}
            toggleModal={toggleFormModal}
            formId={formIdToEdit}
            reload={loadData}
          />

          <DynamicFormModal
            isOpen={showDynamicFormModal}
            toggleModal={toggleDynamicFormModal}
            itemId={10137}
            formId={formIdToEditValues}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

const FormItem = ({ form, editForm, formTypes }) => {
  return (
    <Col style={{ maxWidth: "450px" }}>
      <div
        style={{
          border: "1px solid lightgray",
          backgroundColor: "white",
          marginTop: "20px",
          marginRight: "10px",
          marginLeft: "10px",
          borderRadius: "8px",
          width: "400px",
          //boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
          cursor: "pointer",
        }}
        onClick={() => editForm(form)}
      >
        <div
          style={{
            padding: "10px",
            backgroundColor: "rgb(18, 52, 86)",
            color: "white",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
        >
          {form.formName}
        </div>
        
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              width: "56%",
              margin: "auto",
              marginTop: "5px",
              marginBottom: "10px",
            }}
          >
            <p>
              Type:{" "}
              <b>
                {formTypes.find(x => x.formTypeId == form.formTypeId)?.type}
              </b>
            </p>
            <p>
              Scope: <b>{form.scope}</b>
            </p>
            <p style={{ color: form.inactive ? "red" : "green" }}>
              <b>{form.inactive ? "Inactive" : "Active"}</b>
            </p>
          </div>
          <div
            style={{
              width: "36%",
              margin: "auto",
              marginTop: "5px",
              marginBottom: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <h4>Controls</h4>
              <h1>
                <b>{form.numFields ?? 0}</b>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </Col>
  )
}

export default ManageForms
