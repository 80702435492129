import React, { useState } from "react"
import {
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  Inject,
  Year,
  ViewsDirective,
  ViewDirective,
  TimelineYear,
} from "@syncfusion/ej2-react-schedule"
import EditTaskModal from "pages/Projects/EditProject/Modals/EditTaskModal"
import useModal from "hooks/useModalHook"
import api from "services/api.service"

const SchedulerTaskTable = ({ tasks, loadTasks, editTask }) => {
 
  const fieldsData = {
    id: "taskNum",
    subject: { name: "taskName" },
    isAllDay: { name: "isAllDay" },
    startTime: { name: "startDT" },
    endTime: { name: "endDT" },
  }

  const eventSettings = { dataSource: tasks, fields: fieldsData }

  const onPopupOpen = async args => {
    if (args.type == "Editor") {
      args.cancel = true
      editTask(undefined, args.data)
      // let task = await api.getTask(args.data.taskID)
      // setTaskToEdit({
      //   ...task,
      //   subtasks: tasks.filter(x => x.parentTaskID == task.taskNum),
      // })
      // toggleEditModal()
    }
  }

  return (
    <React.Fragment>
      <ScheduleComponent
        popupOpen={onPopupOpen}
        height="60vh"
        selectedDate={new Date()}
        eventSettings={eventSettings}
        currentView="Month"
        rowAutoHeight={true}
      >
        <ViewsDirective>
          <ViewDirective option="Day"  />
          <ViewDirective option="Week" />
          <ViewDirective option="Month" />
          <ViewDirective option="Year" />
          <ViewDirective option="TimelineYear" />
          <ViewDirective option="Agenda"/>
        </ViewsDirective>
        <Inject services={[Day, Week, WorkWeek, Month, Agenda, Year, TimelineYear]} />
      </ScheduleComponent>

     
    </React.Fragment>
  )
}

export default SchedulerTaskTable
