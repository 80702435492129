import { swalWithConfirmButton } from "components/custom/swal"
import download from "downloadjs"
import useModal from "hooks/useModalHook"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useSelector } from "react-redux"
import { Col, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const RunViewModal = ({ isOpen, toggleModal, scenarioId, viewId }) => {
  const currentUser = userService.getLoggedInUser()
  const [views, setViews] = useState([])
  const [selectedView, setSelectedView] = useState(-1)
  const [isLoading, setIsLoading] = useState(false)

  const [discount, setDiscount] = useState(3)
  const [show, setShow] = useState(1)

  const [isTableOpen, setIsTableOpen] = useState(false)
  const toggleTableModal = () => setIsTableOpen(!isTableOpen)
  const [tableData, setTableData] = useState([])

  const { alternatives } = useSelector(state => ({
    alternatives: state.gridSelection.alternatives,
  }))

  useEffect(() => {
    if (isOpen) {
      loadData()
    }
  }, [isOpen])

  useEffect(() => {
    if (viewId > 0) {
      setSelectedView(viewId)
    }
  }, [viewId])

  const loadData = async () => {
    let v = await api.getBcReportSets_CanView(currentUser.userID)
    setViews(v)
  }

  const runView = async () => {
    if (selectedView == -1) {
      swalWithConfirmButton.fire({
        title: "Please select a business case view first.",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    } else {
      if (
        alternatives == undefined ||
        alternatives == null ||
        alternatives.length == 0
      ) {
        swalWithConfirmButton.fire({
          title: `You cannot run this option without Projects selected`,
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Ok",
        })
      } else {
        setIsLoading(true)
        if (show == 0) {
          api
            .exportViewReportByAlternatives(
              selectedView,
              alternatives
                .map(
                  x =>
                    x[
                      Object.keys(x).find(
                        key => key.toLowerCase() === "alternativeid"
                      )
                    ]
                )
                .join(","),
              discount,
              show
            )
            .then(blob => {
              setIsLoading(false)
              download(
                blob,
                `${
                  views.find(x => x.bcReportSetID == selectedView).reportSetName
                }.xlsx`
              )
            })
        } else {
          let data = await api.exportViewReportByAlternatives(
            selectedView,
              alternatives
                .map(
                  x =>
                    x[
                      Object.keys(x).find(
                        key => key.toLowerCase() === "alternativeid"
                      )
                    ]
                )
                .join(","),
              discount,
              show
          )
          setTableData(data)
          toggleTableModal()
          setIsLoading(false)
        }
      }
    }
  }

  return (
    <>
      <Modal
      backdrop="static"
        isOpen={isOpen}
        size="md"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Business Case Views
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">View</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={selectedView}
                  onChange={e => {
                    setSelectedView(e.target.value)
                  }}
                >
                  <option value="-1">Select...</option>
                  {views.map((x, idx) => (
                    <option key={idx} value={x.bcReportSetID}>
                      {x.reportSetName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <Label>Discounting</Label>
                <div>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="anon1"
                    id="anon1"
                    checked={discount == 1}
                    onClick={e => {
                      setDiscount(1)
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="anon1"
                    style={{ marginLeft: "10px" }}
                  >
                    Run with discounting "Off"
                  </label>
                </div>
                <div>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="anon2"
                    id="anon2"
                    checked={discount == 2}
                    onClick={e => {
                      setDiscount(2)
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="anon2"
                    style={{ marginLeft: "10px" }}
                  >
                    Run with discounting "On"
                  </label>
                </div>
                <div>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="anon3"
                    id="anon3"
                    checked={discount == 3}
                    onClick={e => {
                      setDiscount(3)
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="anon3"
                    style={{ marginLeft: "10px" }}
                  >
                    Run using the discounting switch for each business case
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <Label>Discounting</Label>
                <div>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="anon4"
                    id="anon4"
                    checked={show == 0}
                    onClick={e => {
                      setShow(0)
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="anon4"
                    style={{ marginLeft: "10px" }}
                  >
                    Output to Excel
                  </label>
                </div>
                <div>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="anon5"
                    id="anon5"
                    checked={show == 1}
                    onClick={e => {
                      setShow(1)
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="anon5"
                    style={{ marginLeft: "10px" }}
                  >
                    Show on Screen
                  </label>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="modal-footer"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <button
            onClick={() => {
              toggleModal()
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <div style={{ display: "flex", alignItems: "center" }}>
            {isLoading && (
              <Loader
                type="line-scale-pulse-out"
                color={colors.primary}
                style={{ textAlign: "center" }}
              />
            )}
            <button
              className="btn btn-primary save-user"
              onClick={runView}
              disabled={isLoading}
            >
              Run View
            </button>
          </div>
        </div>
      </Modal>
      <Modal
      backdrop="static"
        isOpen={isTableOpen}
        fullscreen={true}
        toggle={() => {
          toggleTableModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {views.length > 0 &&
              selectedView > -1 &&
              views.find(x => x.bcReportSetID == selectedView).reportSetName}
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleTableModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {tableData.length > 0 && (
            <table
              className="table table-bordered"
              style={{ backgroundColor: "white" }}
            >
              <thead>
                <tr>
                  {Object.keys(tableData[0]).map((t, idx) => (
                    <th key={idx}>{t.charAt(0).toUpperCase() + t.slice(1)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData.map((t, idx) => (
                  <tr key={idx}>
                    {Object.keys(t).map((tt, idx2) => (
                      <td key={idx2}>{t[tt]}</td>
                    ))}{" "}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <div className="modal-footer">
          <button
            onClick={() => {
              toggleTableModal()
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  )
}

export default RunViewModal
