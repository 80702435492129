import { TextareaAutosize } from "@material-ui/core"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const AddEditModelGroupModal = ({
  isOpen,
  toggleModal,
  modelGroupToEdit,
  setModelGroupToEdit,
  reload
}) => {
  const currentUser = userService.getLoggedInUser()
  const [allModels, setAllModels] = useState([])
  const [selectedModels, setSelectedModels] = useState([])

//   useEffect(() => {
//     loadScenarios()
//   }, [])

  useEffect(() => {
    if (isOpen) {
      loadSelected()
    }
  }, [isOpen])

  const loadScenarios = async () => {
    let scenarios = await api.getScenariosByUser(currentUser.userID, 0)
    setAllModels(scenarios)
    return scenarios
  }

  const loadSelected =  async () => {
    let scenarios = await loadScenarios()
    if (
      modelGroupToEdit != null &&
      modelGroupToEdit.scenarioIds != undefined &&
      modelGroupToEdit.scenarioIds != null &&
      modelGroupToEdit.scenarioIds.length > 0
    ) {
      let selected = []
      let allModelsCopy = [...scenarios]
      modelGroupToEdit.scenarioIds.forEach(sId => {
        let s = allModelsCopy.find(x => x.scenarioID == sId)
        if (s != undefined) {
          selected.push(s)
          allModelsCopy.splice(allModelsCopy.indexOf(s), 1)
        }
      })
      setAllModels(
        allModelsCopy.map(x => {
          x.isSelected = false
          return x
        })
      )
      setSelectedModels(selected)
    } else {
      setSelectedModels([])
    }
  }

  const addSelected = () => {
    let allModelsCopy = [...allModels]
    let selectedModelsCopy = [...selectedModels]

    let selected = allModelsCopy.filter(x => x.isSelected)
    selected.forEach(s => {
      allModelsCopy.splice(allModelsCopy.indexOf(s), 1)
      selectedModelsCopy.push({ ...s, isSelected: false })
    })

    setAllModels(allModelsCopy)
    setSelectedModels(selectedModelsCopy)
  }

  const removeSelected = () => {
    let allModelsCopy = [...allModels]
    let selectedModelsCopy = [...selectedModels]

    let selected = selectedModelsCopy.filter(x => x.isSelected)
    selected.forEach(s => {
      selectedModelsCopy.splice(selectedModelsCopy.indexOf(s), 1)
      allModelsCopy.push({ ...s, isSelected: false })
    })

    setAllModels(allModelsCopy)
    setSelectedModels(selectedModelsCopy)
  }

  const save = async () => {
    let modelGroupToSave = {...modelGroupToEdit}
    modelGroupToSave.scenarioIds = selectedModels.map(x=>x.scenarioID)
    if (modelGroupToSave.scenarioGroupID > 0) {
      await api.updateScenarioGroup(modelGroupToSave)
    } else {
      await api.createScenarioGroup(modelGroupToSave)
    }

    toggleModal()
    reload && reload()
  }

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Add Entity
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <Label className="form-label">Name</Label>
          <Input
            type="text"
            onChange={e =>
              setModelGroupToEdit({
                ...modelGroupToEdit,
                scenarioGroupName: e.target.value,
              })
            }
            value={modelGroupToEdit.scenarioGroupName}
          />
        </div>
        <div className="mb-3">
          <Label className="form-label">Description</Label>
          <TextareaAutosize
            className="form-control"
            minRows={3}
            onChange={e =>
              setModelGroupToEdit({
                ...modelGroupToEdit,
                description: e.target.value,
              })
            }
            value={modelGroupToEdit.description}
          />
        </div>
        <div className="form-check mb-3">
          <Input
            type="checkbox"
            className="form-check-input"
            id="inactivateModelGroup"
            checked={modelGroupToEdit.inactive}
            onClick={e => {
              setModelGroupToEdit({
                ...modelGroupToEdit,
                inactive: !modelGroupToEdit.inactive,
              })
            }}
          />
          <Label className="form-check-label" for="inactivateModelGroup">
            Inactive
          </Label>
        </div>
        <div className="mb-3">
          <Row>
            <Col md="5" style={{ overflow: "auto", height: "300px" }}>
              <table
                className="table table-bordered low-padding-table"
                style={{ backgroundColor: "white" }}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th>Model</th>
                  </tr>
                </thead>
                <tbody>
                  {allModels.map((m, idx) => (
                    <tr key={idx}>
                      <td>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={m.isSelected}
                          onClick={e => {
                            let copy = [...allModels]
                            let item = copy.find(
                              x => x.scenarioID == m.scenarioID
                            )
                            item.isSelected = !item.isSelected
                            setAllModels(copy)
                          }}
                        />
                      </td>
                      <td>{m.scenarioName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
            <Col
              md="2"
              className="d-flex-col jc-center ai-center"
              style={{ gap: "10px" }}
            >
              <button
                className="btn  btn-primary"
                onClick={() => addSelected()}
              >
                Add {">"}
              </button>
              <button
                className="btn  btn-primary"
                onClick={() => removeSelected()}
              >
                {"<"} Remove
              </button>
            </Col>
            <Col md="5" style={{ overflow: "auto", height: "300px" }}>
              <table
                className="table table-bordered low-padding-table"
                style={{ backgroundColor: "white" }}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th>Model</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedModels.map((m, idx) => (
                    <tr key={idx}>
                      <td>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={m.isSelected}
                          onClick={e => {
                            let copy = [...selectedModels]
                            let item = copy.find(
                              x => x.scenarioID == m.scenarioID
                            )
                            item.isSelected = !item.isSelected
                            setSelectedModels(copy)
                          }}
                        />
                      </td>
                      <td>{m.scenarioName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
        </div>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default AddEditModelGroupModal
