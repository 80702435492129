import {
  swalWithConfirmAndCancelButtons,
  swalWithConfirmButton,
} from "components/custom/swal"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Dropdown,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { changeNavbarParams, loadPageItem } from "store/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import BrandingSection from "./BrandingSection"
import ContractEntitiesSection from "./ContractEntitiesSection"
import CostPoolsSection from "./CostPools"
import GeneralSection from "./GeneralSection"
import SimpleListSection from "./SimpleListSection"
import ProgramsSection from "./ProgramsSection"
import FundsSection from "./FundsSection"
import EnterpriseRatesSection from "./EnterpriseRatesSection"

const EditEnterprise = props => {
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [activeTab, setActiveTab] = useState("1")

  let [enterpriseId, setEnterpriseId] = useState(-1)
  let [enterprise, setEnterprise] = useState(null)
  let [isLoading, setIsLoading] = useState(false)
  let [saveBranding, setSaveBranding] = useState(false)

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: 0,
        alternativeId: 0,
        viewName: "editEnterprise",
      })
    )
    setEnterpriseId(props.match.params.enterpriseId)
  }, [])

  useEffect(() => {
    loadEnterprise()
  }, [enterpriseId])

  const loadEnterprise = async () => {
    if (enterpriseId > -1) {
      try {
        setIsLoading(true)
        let e = await api.getEnterprise(enterpriseId)
        setEnterprise(e)
        setIsLoading(false)
      } catch (err) {
        console.log(err)
        setIsLoading(false)
      }
    } else {
      setEnterprise({ enterpriseID: -1 })
    }
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const handleSave = async () => {
    try {
      toast.dismiss()
      if (activeTab == "1") {
        await saveEnterprise()
      }
      if (activeTab == "2") {
        setSaveBranding(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const saveEnterprise = async () => {
    setSaveBranding(false)

    if (
      enterprise.useAI &&
      (enterprise.gptAssistantID == null || enterprise.gptAssistantID == "") &&
      currentUser.roleID > 100
    ) {
      swalWithConfirmButton
        .fire({
          title: `For AI to work, an Assistant must be setup.`,
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Ok",
        })
        .then(async result => {
          await save()
        })
    } else {
      await save()
    }
  }

  const save = async () => {
    if (enterpriseId > -1) {
      await api.updateEnterprise(currentUser.userID, enterprise)
      toast.success(`Enterprise updated successfuly`)
    } else {
      let newEntId = await api.createEnterprise(currentUser.userID, enterprise)
      if (newEntId > -1) {
        setEnterpriseId(newEntId)
        props.history.replace({
          pathname: `/editEnterprise/${newEntId}`,
        })
        toast.success(`Enterprise created successfuly`)
      } else {
        toast.error(`There was a problem saving the enterprise`)
      }
    }
  }

  const deleteEnterprise = () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this enterprise?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          //   await api.deleteScenario(currentUser.userID, scenarioId)
          //   props.history.goBack()
        }
      })
  }

  const cancel = () => {
    toast.dismiss()
    props.history.goBack()
  }

  const changeEnterpriseProp = (enterpriseProp, value) => {
    setEnterprise({
      ...enterprise,
      [enterpriseProp]: value,
    })

    toast.success(
      "You have unsaved changes, don't forget to save them using the button at the top of the page.",
      { toastId: "unsavedChanges", autoClose: false }
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={`${enterpriseId == -1 ? "Add Enterprise" : "Settings"}`}
          />

          <Row>
            <Col>
              {enterprise != null && (
                <Card>
                  <CardBody>
                    <Row>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "end",
                        }}
                      >
                        {/* {activeTab != "3" && ( */}
                        <button
                          type="button"
                          className="btn btn-primary save-user"
                          onClick={handleSave}
                        >
                          <i className="fas fa-save"></i>
                          {enterpriseId > -1 ? " Update" : " Save"}
                        </button>
                        {/* )} */}
                        <button
                          type="button"
                          className="btn btn-outline-secondary save-user"
                          onClick={cancel}
                        >
                          Cancel
                        </button>
                        {enterpriseId > -1 && currentUser.roleID == 110 && (
                          <button
                            type="button"
                            className="btn btn-danger save-user"
                            onClick={deleteEnterprise}
                          >
                            <i className="fas fa-times"></i> Delete
                          </button>
                        )}
                      </div>
                    </Row>
                    {enterpriseId > -1 && (
                      <Nav tabs style={{ marginTop: "15px" }}>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "1" ? "active" : ""}
                            onClick={() => {
                              toggleTab("1")
                            }}
                          >
                            <b>General</b>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "2" ? "active" : ""}
                            onClick={() => {
                              toggleTab("2")
                            }}
                          >
                            <b>Branding</b>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "4" ? "active" : ""}
                            onClick={() => {
                              toggleTab("4")
                            }}
                          >
                            <b>Contracts</b>
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "6" ? "active" : ""}
                            onClick={() => {
                              toggleTab("6")
                            }}
                          >
                            <b>Contract Entities</b>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "10" ? "active" : ""}
                            onClick={() => {
                              toggleTab("10")
                            }}
                          >
                            <b>Rates</b>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "7" ? "active" : ""}
                            onClick={() => {
                              toggleTab("7")
                            }}
                          >
                            <b>Cost Pools</b>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "8" ? "active" : ""}
                            onClick={() => {
                              toggleTab("8")
                            }}
                          >
                            <b>Programs</b>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "3" ? "active" : ""}
                            onClick={() => {
                              toggleTab("3")
                            }}
                          >
                            <b>Resource Classes</b>
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "5" ? "active" : ""}
                            onClick={() => {
                              toggleTab("5")
                            }}
                          >
                            <b>Reviewer Roles</b>
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "9" ? "active" : ""}
                            onClick={() => {
                              toggleTab("9")
                            }}
                          >
                            <b>Funds</b>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    )}
                    <TabContent
                      activeTab={activeTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <GeneralSection
                          enterprise={enterprise}
                          changeEnterpriseProp={changeEnterpriseProp}
                          reload={loadEnterprise}
                        />
                      </TabPane>
                      <TabPane tabId="2">
                        <BrandingSection
                          enterprise={enterprise}
                          changeEnterpriseProp={changeEnterpriseProp}
                          shouldSave={saveBranding}
                          saveEnterprise={saveEnterprise}
                        />
                      </TabPane>
                      <TabPane tabId="3">
                        <SimpleListSection
                          enterpriseId={enterpriseId}
                          listHeader="Resource Class"
                          listName="ResourceClass"
                        />
                      </TabPane>
                      <TabPane tabId="4">
                        <SimpleListSection
                          enterpriseId={enterpriseId}
                          listHeader="Contract"
                          listName="Contract"
                        />
                      </TabPane>
                      <TabPane tabId="5">
                        <SimpleListSection
                          enterpriseId={enterpriseId}
                          listHeader="Reviewer Role"
                          listName="ReviewerRole"
                        />
                      </TabPane>
                      <TabPane tabId="6">
                        <ContractEntitiesSection enterpriseId={enterpriseId} />
                      </TabPane>
                      <TabPane tabId="7">
                        <CostPoolsSection enterpriseId={enterpriseId} activeTab={activeTab} />
                      </TabPane>
                      <TabPane tabId="8">
                        <ProgramsSection
                          enterprise={enterprise}
                          activeTab={activeTab}
                        />
                      </TabPane>
                      <TabPane tabId="9">
                        <FundsSection
                          enterprise={enterprise}
                          activeTab={activeTab}
                        />
                      </TabPane>
                      <TabPane tabId="10">
                        <EnterpriseRatesSection activeTab={activeTab} enterpriseId={enterpriseId} />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditEnterprise
