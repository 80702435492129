import React, { useEffect, useState } from "react"
import { Tooltip } from "@material-ui/core"
import Loader from "react-loaders"
import {
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import api from "services/api.service"
import colors from "utils/colors"
import { currencyFormatter } from "utils/formatters"

const ScenarioComparison = props => {
  const scenarioId = props.match.params.scenarioId
  const [activeTab, setActiveTab] = useState("Criteria")
  const [selectedView, setSelectedView] = useState("All")
  const [altAll, setAltAll] = useState([])
  const [altObj, setAltObj] = useState([])
  const [altOpt, setAltOpt] = useState([])
  const [altFin, setAltFin] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setIsLoading(true)
    let opt = await api.getAltOptModels(scenarioId)
    setAltOpt(opt)
    let fin = await api.getAltFinModels(scenarioId)
    setAltFin(fin)
    await getAltAll(selectedView)
    await getAltObj(selectedView)
    setIsLoading(false)
  }

  const getAltAll = async show => {
    let data = await api.getAltAllModels(scenarioId, show)
    setAltAll(data)
  }

  const getAltObj = async show => {
    let data = await api.getAltObjModels(scenarioId, show)
    setAltObj(data)
  }

  const toggleTab = tab => setActiveTab(tab)

  const renderTable = dataSource => {
    return (
      <table
        className="table table-bordered low-padding-table"
        style={{ backgroundColor: "white" }}
      >
        <thead>
          <tr>
            <th>ID</th>
            <th>Project</th>
            {dataSource.length > 0 &&
              Object.keys(dataSource[0])
                .filter(x => x != "alternative" && x != "alternativeNum")
                .map((p, idx) => (
                  <th
                    key={idx}
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    {p}
                  </th>
                ))}
          </tr>
        </thead>
        <tbody>
          {dataSource.map((a, idx) => (
            <tr key={idx}>
              <td>{a.alternativeNum}</td>
              {/* <td>{a.alternative}</td> */}
              <td className="one-line-elipsis">
                    <Tooltip title={<h6>{a.alternative}</h6>}>
                      <span> <b>{a.alternative}</b></span>
                    </Tooltip>
                  </td>              
              {Object.keys(a)
                .filter(x => x != "alternative" && x != "alternativeNum")
                .map((p, idx) => (
/*                   <td
                    key={idx}
                    className={
                      p.toLowerCase().indexOf("cost") > -1 ? "text-right" : ""
                    }
                  >
                    {p.toLowerCase().indexOf("cost") > -1
                      ? currencyFormatter.format(a[p])
                      : a[p]}
                  </td> */

                  <td
                  key={idx}
                  className={
                    p.toLowerCase().indexOf("cost") > -1 ? "text-right" : ""
                  }
                  >
                   {p.toLowerCase().indexOf("cost") > -1
                      ? currencyFormatter.format(a[p])
                      :
                  <div
                  dangerouslySetInnerHTML={{
                    __html: a[p],
                  }}
                ></div>
  }
                  </td> 
                ))}
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs
          title={alternative != null ? alternative.alternativeName : ""}
        /> */}
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "Criteria" ? "active" : ""}
                            onClick={() => {
                              toggleTab("Criteria")
                            }}
                          >
                            <b>Criteria</b>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={
                              activeTab === "Financial" ? "active" : ""
                            }
                            onClick={() => {
                              toggleTab("Financial")
                            }}
                          >
                            <b>Financial</b>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={
                              activeTab === "Optimization" ? "active" : ""
                            }
                            onClick={() => {
                              toggleTab("Optimization")
                            }}
                          >
                            <b>Optimization</b>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === "All" ? "active" : ""}
                            onClick={() => {
                              toggleTab("All")
                            }}
                          >
                            <b>All</b>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      {isLoading && (
                        <Loader
                          type="line-scale-pulse-out"
                          color={colors.primary}
                          style={{ textAlign: "center" }}
                        />
                      )}
                      {!isLoading && (
                        <TabContent
                          activeTab={activeTab}
                          className="p-3 text-muted"
                        >
                          <TabPane tabId="Criteria">
                            <Row>
                              <Col>
                                <div className="mb-3">
                                  <Label className="form-label">View</Label>
                                  <select
                                    className="form-control form-select select2 mb-xxl-0"
                                    value={selectedView}
                                    style={{ width: "200px" }}
                                    onChange={e => {
                                      setSelectedView(e.target.value)
                                      getAltObj(e.target.value)
                                      getAltAll(e.target.value)
                                    }}
                                  >
                                    <option value="All">All</option>
                                    <option value="Ranking">Ranking</option>
                                  </select>
                                </div>
                                {renderTable(altObj)}
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="Financial">
                            {renderTable(altFin)}
                          </TabPane>
                          <TabPane tabId="Optimization">
                            {renderTable(altOpt)}
                          </TabPane>
                          <TabPane tabId="All">
                            <div className="mb-3">
                              <Label className="form-label">View</Label>
                              <select
                                className="form-control form-select select2 mb-xxl-0"
                                value={selectedView}
                                style={{ width: "200px" }}
                                onChange={e => {
                                  setSelectedView(e.target.value)
                                  getAltObj(e.target.value)
                                  getAltAll(e.target.value)
                                }}
                              >
                                <option value="All">All</option>
                                <option value="Ranking">Ranking</option>
                              </select>
                            </div>
                            {renderTable(altAll)}
                          </TabPane>
                        </TabContent>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ScenarioComparison
