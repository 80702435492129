import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { changeNavbarParams } from "store/actions"
import { loadPageItem } from "store/pageItem/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ModelsGrid from "./ModelsGrid"
import ModelsCards from "./ModelsCards"
import { enrichWithPhotoBase64, loadUsersAvatars } from "utils/avatar"

const Models = props => {
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  let [models, setModels] = useState([])
  let [modelsWithWidgets, setModelsWithWidgets] = useState([])
  let [enterprise, setEnterprise] = useState(null)
  let [isLoading, setIsLoading] = useState(false)
  let [isWidgetsLoading, setIsWidgetsLoading] = useState(false)
  const [scenarioTypes, setScenarioTypes] = useState([])
  const [selectedScenarioTypeId, setSelectedScenarioTypeId] = useState(
    props.match.params.scenarioTypeId == undefined
      ? 0
      : props.match.params.scenarioTypeId
  )
  const [showList, setShowList] = useState(true)
  const [cardList, setCardList] = useState([])

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: 0,
        alternativeId: 0,
        viewName: "models",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: 0,
        viewName: "models",
      })
    )
    loadData()
    loadEnterprise()
  }, [])

  useEffect(() => {
    if (
      props.match.params.scenarioTypeId != undefined &&
      props.match.params.scenarioTypeId != selectedScenarioTypeId
    ) {
      setSelectedScenarioTypeId(props.match.params.scenarioTypeId)
    }
  }, [props.match.params.scenarioTypeId])

  useEffect(() => {
    loadModels()
  }, [selectedScenarioTypeId])

  const loadData = async () => {
    // setIsLoading(true)
    // await loadModels()
    let scTypes = await api.getEnterpriseList(
      currentUser.enterpriseID,
      "ScenarioTypes"
    )
    setScenarioTypes(scTypes)
  }

  const loadEnterprise = async () => {
    let e = await api.getEnterprise(currentUser.enterpriseID)
    setEnterprise(e)
  }

  const loadModels = async () => {
    setIsLoading(true)
    try {
      let cl = await api.getConfigItems(currentUser.userID, 0, "models")
      setCardList(cl)
      let card = cl.find(x => x.configItemName == "CardList")
      setShowList(
        card == undefined ? true : card.configItemValue == "2" ? true : false
      )

      let scenarios = await api.getScenariosByUser(
        currentUser.userID,
        selectedScenarioTypeId
      )
      setModels(scenarios)
      loadModelsWithWidgets(scenarios)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  const addModel = () => {
    props.history.push("/editModel/-1")
  }

  const loadModelsWithWidgets = async scenarios => {
    setIsWidgetsLoading(true)
    try {
      let scenariosCopy = [...scenarios]
      let tasks = scenariosCopy.map(x => async () => {
        return await api.getWidget(4, x.scenarioID)
      })
      let tasks2 = scenariosCopy.map(x => async () => {
        return await api.getWidget(17, x.scenarioID)
      })
      let widgets4 = await Promise.all(tasks.map(t => t()))
      let widgets17 = await Promise.all(tasks2.map(t => t()))

      scenariosCopy = await enrichWithPhotoBase64(scenariosCopy, "portfolioManagerID",false, 'portfolioManagerPhotoBase64')

      for (let i = 0; i < scenariosCopy.length; i++) {
        scenariosCopy[i].teamWidget = widgets17[i]
        scenariosCopy[i].businessWidget = widgets4[i]
      }
      setModelsWithWidgets(scenariosCopy)
      console.log(scenariosCopy)
      setIsWidgetsLoading(false)
    } catch (err) {
      setIsWidgetsLoading(false)
    }
  }

  const updateConfigItem = async value => {
    await api.updateConfigItem(currentUser.userID, 0, {
      configItemName: "CardList",
      scenarioID: 0,
      configItemValue: value,
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Row style={{ marginBottom: "10px" }}>
            <Col xs="12">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {currentUser.roleID >= 10 && (
                    <button className="btn btn-primary" onClick={addModel}>
                      <i className="fas fa-plus"></i> Add Decision
                    </button>
                  )}

                  <div className="mb-3" style={{ marginLeft: "20px" }}>
                    <Label style={{ marginBottom: "0px" }}>Decision Type</Label>
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      value={selectedScenarioTypeId}
                      style={{ width: "250px" }}
                      onChange={e => {
                        setSelectedScenarioTypeId(e.target.value)
                      }}
                    >
                      <option value="0">All</option>
                      {scenarioTypes.map((r, idx) => {
                        return (
                          <option key={idx} value={`${r.listItemID}`}>
                            {r.listItemName}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
                <div>
                  <i
                    className="mdi mdi-format-list-bulleted"
                    style={{
                      fontSize: "20px",
                      marginRight: "10px",
                      color: showList ? colors.primary : "initial",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowList(true)
                      updateConfigItem(2)
                    }}
                  ></i>
                  <i
                    className="mdi mdi-card-bulleted-outline"
                    style={{
                      fontSize: "20px",
                      marginRight: "10px",
                      color: !showList ? colors.primary : "initial",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowList(false)
                      updateConfigItem(1)
                    }}
                  ></i>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {showList && (
                <>
                  {isLoading && (
                    <Loader
                      type="line-scale-pulse-out"
                      color={colors.primary}
                      style={{ textAlign: "center" }}
                    />
                  )}
                  {!isLoading && (
                    <ModelsGrid
                      models={models}
                      props={props}
                      reloadData={loadModels}
                      scenarioTypes={scenarioTypes}
                    />
                  )}
                </>
              )}
              {!showList && (
                <>
                  {isWidgetsLoading && (
                    <Loader
                      type="line-scale-pulse-out"
                      color={colors.primary}
                      style={{ textAlign: "center" }}
                    />
                  )}
                  <ModelsCards models={modelsWithWidgets} props={props} />
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Models
