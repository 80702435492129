import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Row } from "reactstrap"
import api from "services/api.service"
import colors from "utils/colors"

const FieldsSection = ({
  activeTab,
  scenarioId,
  triggerSaveFields,
  setTriggerSaveFields,
}) => {
  const [fieldConfigItems, setFieldConfigItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (activeTab == "5") {
      loadData()
    }
  }, [activeTab])

  useEffect(() => {
    if (triggerSaveFields == true) {
      saveFields()
    }
  }, [triggerSaveFields])

  const loadData = async () => {
    setIsLoading(true)
    let f = await api.getFieldConfigItems(scenarioId)
    setFieldConfigItems(f)
    setIsLoading(false)
  }

  const saveFields = async () => {
    let itemsToSave = fieldConfigItems.filter(x => x.isDirty)
    if (itemsToSave.length > 0) {
      let tasks = itemsToSave.map(x => async () => {
        return await api.updateFieldConfigItem(scenarioId, x)
      })
      await Promise.all(tasks.map(t => t()))
    }
    toast.success("Fields saved successfully")
    setTriggerSaveFields(false)
  }

  const renderItem = (x, idx) => {
    return (
      <div
        key={idx}
        className="mb-3"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 20,
        }}
      >
        <label style={{ width: "200px" }}>{x.fieldLabel}</label>
        <select
          style={{ width: "300px" }}
          className="form-control form-select select2 mb-xxl-0"
          value={x.fieldStatus}
          onChange={e => {
            let copy = [...fieldConfigItems]
            let item = copy.find(c => c.fieldConfigID == x.fieldConfigID)
            item.fieldStatus = e.target.value
            item.isDirty = true
            setFieldConfigItems(copy)

            toast.success(
              "You have unsaved changes, don't forget to save them using the button at the top of the page.",
              { toastId: "unsavedChanges", autoClose: false }
            )
          }}
        >
          <option value="show">Show</option>
          <option value="read only">Read Only</option>
          <option value="hide">Hide</option>
        </select>
      </div>
    )
  }

  return (
    <React.Fragment>
      <Row>
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}

        {fieldConfigItems.length > 0 && (
          <>
            <Col md="6">
              {fieldConfigItems
                .slice(0, Math.round(fieldConfigItems.length / 2))
                .map((x, idx) => renderItem(x, idx))}
            </Col>
            <Col md="6">
              {fieldConfigItems
                .slice(Math.round(fieldConfigItems.length / 2), fieldConfigItems.length)
                .map((x, idx) => renderItem(x, idx))}
            </Col>
          </>
        )}
      </Row>
    </React.Fragment>
  )
}

export default FieldsSection
