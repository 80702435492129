import {
  swalWithConfirmAndCancelButtons,
  swalWithConfirmButton,
} from "components/custom/swal"
import scoringColumns from "pages/AdminObjectiveScore/ScoringColumns"
import ObjectiveRatingTable from "pages/_ObjectiveShared/ObjectiveRatingTable"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Container, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import logo from "../../../assets/images/defprologo1_sm.png"

const ObjectiveOverview = props => {
  const scenarioId = props.match.params.scenarioId
  const mode = props.match.params.mode
  const currentUser = userService.getLoggedInUser()

  const clear = () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to clear your judgments?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deletePwcUserScoresScenario(currentUser.userID, scenarioId)
          toast.success("Judgments cleared")
        }
      })
  }
  const refine = () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to refine your judgments?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          props.history.goBack()
        }
      })
  }

  return (
    <React.Fragment>
      <div
        style={{
          overflow: "hidden",
          color: "white",
        }}
      >
        <Row
          className="background-gradient"
          style={{ height: "100vh", display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              alignSelf: "start",
              height: "70px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "whitesmoke",
              paddingRight: "50px",
              paddingLeft: "50px",
            }}
          >
            <img src={logo} style={{ width: "30px" }}></img>
            <h5 style={{ color: colors.primary }}>
              <b>Comparison Complete</b>
            </h5>
            <i
              className="fas fa-power-off"
              style={{
                cursor: "pointer",
                color: colors.primary,
                fontSize: "16px",
              }}
              onClick={() => props.history.push("/mobile/exit")}
            ></i>
          </div>
          <Col
            md="6"
            sm="12"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              textAlign: "center",
              height: "100%",
            }}
          >
            <Row style={{ marginRight: "0%" }}>
              <button className="btn btn-primary btn-lg mb-3" onClick={refine}>
                Review and Refine My Judgments
              </button>
              <button className="btn btn-primary btn-lg" onClick={clear}>
                Clear My Judgments
              </button>
            </Row>
            <button
              className="btn btn-primary btn-lg"
              style={{ backgroundColor: "white", color: colors.primary }}
              onClick={() => props.history.push("/mobile/exit")}
            >
              Exit
            </button>
            <div>
              <p className="mb-0">
                U.S. Patent Nos. 10,268,977; 10,366,361; 10,417,590
              </p>
              <p>All Rights Reserved</p>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default ObjectiveOverview
