import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { changeNavbarParams, loadPageItem } from "store/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import EditDashboardModal from "./EditDashboardModal"
import useModal from "hooks/useModalHook"
import ViewDashboardModal from "./ViewDashboardModal"

const ManageDashboards = props => {
  const currentUser = userService.getLoggedInUser()
  //   const scenarioId = props.match.params.scenarioId
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [isLoading, setIsLoading] = useState(false)
  const [dashboards, setDashboards] = useState([])
  const [dashboardToEdit, setDashboardToEdit] = useState(null)

  const [showEditModal, setShowEditModal] = useState(false)
  const toggleEditModal = () => setShowEditModal(!showEditModal)

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: 0,
        alternativeId: 0,
        viewName: "ManageDashboards",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: 0,
        viewName: "ManageDashboards",
      })
    )
    loadData()
  }, [])

  const loadData = async () => {
    setIsLoading(true)
    await loadDashboards()
    setIsLoading(false)
  }

  const loadDashboards = async () => {
    let data = await api.getDashboards(currentUser.userID, false)
    setDashboards(data)
  }

  const createDashboard = () => {
    setDashboardToEdit({
      dashboardID: -1,
      inactive: false,
      dashboardName: "",
      layout: "1 Column",
      description: "",
      dashboardItems: [],
      users: [],
    })
    toggleEditModal()
  }

  const editDashboard = async dashboard => {
    // let d = await api.getDashboard(dashboard.dashboardID)
    props.history.push(`/dashboard/${dashboard.dashboardID}`)
    // setDashboardToEdit(d)
    // toggleEditModal()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Card>
            <CardBody>
              <Row className="mb-3">
                <Col>
                  <button className="btn btn-primary" onClick={createDashboard}>
                    <i className="fas fa-plus"></i> Create Dashboard
                  </button>
                </Col>
              </Row>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              {!isLoading && (
                <table
                  className="table table-bordered low-padding-table"
                  style={{ backgroundColor: "white" }}
                >
                  <thead>
                    <tr>
                      <th style={{ width: "20%" }}>Dashboard</th>
                      <th style={{ width: "50%" }}>Description</th>
                      <th>Public</th>
                      <th>Inactive</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboards.map((c, idx) => (
                      <tr key={idx}>
                        <td>
                          <a
                            href="#"
                            onClick={e => {
                              e.preventDefault()
                              editDashboard(c)
                            }}
                          >
                            {c.dashboardName}
                          </a>
                        </td>

                        <td>{c.description}</td>
                        <td>
                          {c.publicDashboard ? (
                            <span style={{ color: "red" }}>
                              <b>Public</b>
                            </span>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>
                          {c.inactive ? (
                            <span style={{ color: "black" }}>
                              <b>Inactive</b>
                            </span>
                          ) : (
                            "-"
                          )}
                        </td>
                        {/*                         <td>
                          <button
                            className="btn btn-primary py-0"
                            onClick={() => editDashboard(c)}
                          >
                            Edit
                          </button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <EditDashboardModal
        isOpen={showEditModal}
        toggleModal={toggleEditModal}
        dashboardToEdit={dashboardToEdit}
        setDashboardToEdit={setDashboardToEdit}
        reloadData={loadDashboards}
        props={props}
      />
    
    </React.Fragment>
  )
}

export default ManageDashboards
