import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { changeNavbarParams, loadPageItem } from "store/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import EditReportSetModal from "./EditReportSetModal"

const ManageViews = props => {
  const currentUser = userService.getLoggedInUser()
  //   const scenarioId = props.match.params.scenarioId
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [isLoading, setIsLoading] = useState(false)
  const [reportSets, setReportSets] = useState([])
  const [reportSetToEdit, setReportSetToEdit] = useState(null)

  const [showEditModal, setShowEditModal] = useState(false)
  const toggleEditModal = () => setShowEditModal(!showEditModal)

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: 0,
        alternativeId: 0,
        viewName: "ManageViews",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: 0,
        viewName: "ManageViews",
      })
    )
    loadData()
  }, [])

  const loadData = async () => {
    setIsLoading(true)
    await loadReportSets()
    setIsLoading(false)
  }

  const loadReportSets = async () => {
    let data = await api.getBcReportSets(currentUser.userID)
    setReportSets(data)
  }

  const createReportSet = () => {
    setReportSetToEdit({
      bcReportSetID: -1,
      inactive: false,
      publicSet: false,
      reportSetName: "",
      userID: currentUser.userID,
      bcReportSetFields: [],
    })
    toggleEditModal()
  }

  const editReportSet = async reportSet => {
    let r = await api.getBcReportSet(reportSet.bcReportSetID)
    if(r.bcReportSetFields != null){
      r.bcReportSetFields = r.bcReportSetFields.map(bc => {
        return {
          ...bc,
          itemID: bc.bcField.bcFieldID,
          fieldName: bc.bcField.fieldName
        }
      })
    }
    setReportSetToEdit(r)
    toggleEditModal()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Card>
            <CardBody>
              <Row className="mb-3">
                <Col>
                  <button className="btn btn-primary" onClick={createReportSet}>
                    <i className="fas fa-plus"></i> Create View
                  </button>
                </Col>
              </Row>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              {!isLoading && (
                <table
                  className="table table-bordered low-padding-table"
                  style={{ backgroundColor: "white" }}
                >
                  <thead>
                    <tr>
                      <th style={{ width: "40%" }}>View</th>
                      <th>Public</th>
                      <th>Inactive</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportSets.map((r, idx) => (
                      <tr key={idx}>
                        <td>
                          <a
                            href="#"
                            onClick={e => {
                              e.preventDefault()
                              editReportSet(r)
                            }}
                          >
                            {r.reportSetName}
                          </a>
                        </td>
{/*                         <td>
                          <div
                            style={{ marginLeft: "10px" }}
                            dangerouslySetInnerHTML={{
                              __html: r.publicSet,
                            }}
                          ></div>
                        </td> */}
                        <td>{r.publicSet ? <span style={{ color: "red" }}><b>Public</b></span> : " - "}</td>
{/*                         <td>
                          <div
                            style={{ marginLeft: "10px" }}
                            dangerouslySetInnerHTML={{
                              __html: r.inactive,
                            }}
                          ></div>
                        </td> */}
                        <td>{r.inactive ? <span style={{ color: "black" }}><b>Inactive</b></span> : " - "}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <EditReportSetModal
        isOpen={showEditModal}
        toggleModal={toggleEditModal}
        reportSetToEdit={reportSetToEdit}
        setReportSetToEdit={setReportSetToEdit}
        reloadData={loadReportSets}
      />
    </React.Fragment>
  )
}

export default ManageViews
