import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import React, { useEffect, useRef, useState } from "react"
import { createRoot } from "react-dom/client"
import Loader from "react-loaders"
import { ReactTabulator } from "react-tabulator"
import { toast } from "react-toastify"
import { Col, FormFeedback, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import colors from "utils/colors"
import { customFormatter } from "utils/tabulator"

const ManageListSection = ({ scenarioId, selectedManageableList }) => {
  const tabulatorRef = useRef()
  const [listItems, setListItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [newItemName, setNewItemName] = useState("")
  const [hasNameError, setHasNameError] = useState(false)

  useEffect(() => {
    if (selectedManageableList.value != -1) {
      loadList(true)
    }
  }, [selectedManageableList])

  const loadList = async toggleLoading => {
    if (toggleLoading) setIsLoading(true)
    let lst = await api.getScenarioList(
      scenarioId,
      selectedManageableList.value
    )
    setListItems(lst)
    setIsLoading(false)
  }

  const addListItem = () => {
    setNewItemName("")
    setHasNameError(false)
    toggleModal()
  }

  const saveNewItem = async () => {
    if (newItemName == "") {
      setHasNameError(true)
    } else {
      await api.createScenarioListItem(
        scenarioId,
        selectedManageableList.value,
        { listItemName: newItemName }
      )
      toggleModal()
      await loadList(true)
    }
  }

  const updateListItem = async item => {
    await api.updateScenarioListItem(scenarioId, selectedManageableList.value, {
      ...item,
      inactive:
        item.inactive == null ||
        item.inactive == "false" ||
        item.inactive == false
          ? false
          : true,
    })
    await loadList()
  }

  const deleteListItem = async item => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this item?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          let ret = await api.deleteScenarioListItem(
            item.listItemID,
            selectedManageableList.value
          )
          toast.success(
            ret == -1
              ? "Item deleted successfully"
              : "The item was inactivated because it is in use"
          )
          let lst = await api.getScenarioList(
            scenarioId,
            selectedManageableList.value
          )
          setListItems(lst)
        }
      })
  }

  const handleReorder = async (originalListItem, newIndex) => {
    let oldIndex = listItems.indexOf(
      listItems.find(x => x.listItemID == originalListItem.listItemID)
    )
    if (newIndex == oldIndex) {
      return
    }

    let itemsToUpdate = []

    let itemsCopy = [...listItems]
    itemsCopy.splice(oldIndex, 1)
    itemsCopy.splice(newIndex, 0, originalListItem)
    if (newIndex < oldIndex) {
      itemsToUpdate.push({
        ...originalListItem,
        orderID: itemsCopy[newIndex + 1].orderID,
      })

      for (let i = newIndex + 1; i <= oldIndex; i++) {
        if (i == oldIndex) {
          itemsCopy[i].orderID = originalListItem.orderID
        } else {
          itemsCopy[i].orderID = itemsCopy[i + 1].orderID
        }
        itemsToUpdate.push(itemsCopy[i])
      }
    } else {
      itemsToUpdate.push({
        ...originalListItem,
        orderID: itemsCopy[newIndex - 1].orderID,
      })
      for (let i = newIndex - 1; i >= oldIndex; i--) {
        if (i == oldIndex) {
          itemsCopy[i].orderID = originalListItem.orderID
        } else {
          itemsCopy[i].orderID = itemsCopy[i - 1].orderID
        }
        itemsToUpdate.push(itemsCopy[i])
      }
    }

    if (itemsToUpdate.length > 0) {
      let itemsToUpdateTasks = itemsToUpdate.map(x => async () => {
        return await api.updateScenarioListItem(
          scenarioId,
          selectedManageableList.value,
          x
        )
      })
      await Promise.all(itemsToUpdateTasks.map(t => t()))
      let lst = await api.getScenarioList(
        scenarioId,
        selectedManageableList.value
      )
      setListItems(lst)
    }
  }

  const columns = [
    {
      rowHandle: true,
      formatter: "handle",
      headerSort: false,
      frozen: true,
      width: 50,
    },
    {
      title: selectedManageableList.text,
      field: "listItemName",
      headerSort: false,
      editor: "input",
    },
    {
      title: "State",
      field: "inactive",
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getValue() == null || cell.getValue() == false
          ? "Active"
          : "Inactive"
      },
      width: 150,
      headerSort: false,
      editor: "select",
      editorParams: {
        values: { false: "Active", true: "Inactive" },
      },
    },
    {
      title: "",
      field: "",
      headerSort: false,
      width: 50,
      formatter: customFormatter(({ rowData, cellValue }) => (
        <i
          className="fas fa-trash-alt"
          style={{ color: "red" }}
          onClick={() => deleteListItem(rowData)}
        ></i>
      ))
    },
  ]

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <React.Fragment>
      {selectedManageableList.value != -1 && (
        <button
          onClick={addListItem}
          className="btn btn-primary"
          style={{ marginBottom: "10px" }}
        >
          + Add {selectedManageableList.text}
        </button>
      )}
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      {!isLoading &&
        listItems.length > 0 &&
        selectedManageableList.value != -1 && (
          <Row>
            <Col sm="12">
              <ReactTabulator
                onRef={r => (tabulatorRef.current = r.current)}
                columns={columns}
                data={listItems}
                options={{
                  pagination: "local",
                  paginationSize: 30,
                  paginationSizeSelector: [5, 10, 20, 30, 50, 100, 200, 300],
                  movableRows: true,
                  layout: "fitColumns",
                  cssClass: "table-borderless",
                }}
                events={{
                  rowMoved: function (row) {
                    handleReorder(
                      listItems.find(
                        x => x.listItemID == row.getData().listItemID
                      ),
                      row.getPosition()
                    )
                  },
                  cellEdited: function (cell) {
                    updateListItem(cell.getData())
                  },
                }}
              />
            </Col>
          </Row>
        )}
      <Modal
        backdrop="static"
        isOpen={isModalOpen}
        size="md"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add {selectedManageableList.text}
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <Label className="form-label">Name</Label>
            <Input
              name="name"
              type="text"
              value={newItemName}
              onChange={e => {
                setNewItemName(e.target.value)
                setHasNameError(false)
              }}
              invalid={hasNameError}
            />
            {hasNameError ? (
              <FormFeedback type="invalid">Name is required</FormFeedback>
            ) : null}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={saveNewItem}
          >
            Save
          </button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ManageListSection
