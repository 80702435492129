import { TextareaAutosize, Tooltip } from "@material-ui/core"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import Constants from "utils/constants"
import { swalWithConfirmAndCancelButtons } from "./swal"
import OpenaiService, { openaiService } from "services/openai.service"

const ChatGPTDrawer = ({ isOpen, toggle, scope, itemId, field }) => {
  const currentUser = userService.getLoggedInUser()
  const [prompt, setPrompt] = useState("")
  const [response, setResponse] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [savedPrompts, setSavedPrompts] = useState([])

  useEffect(() => {
    if (isOpen) {
      setPrompt("")
      setResponse("")
      loadData()
    }
  }, [isOpen])

  const loadData = async e => {
    loadSavedPrompts()
    let r = await api.getAIPrompt(scope, itemId, field)
    setPrompt(r)
  }

  const loadSavedPrompts = async () => {
    let sp = await api.getAiSavedPrompts(scope, itemId, field)
    setSavedPrompts(sp)
  }

  // const { Configuration, OpenAIApi } = require("openai")

  // const configuration = new Configuration({
  //   apiKey: Constants.OPENAI_API_KEY,
  // })

  // const openai = new OpenAIApi(configuration)
  const openaiService = new OpenaiService()

  const processAI = async () => {
    try {
      setIsLoading(true)

      let resp = await openaiService.askAssistantAsync("In 2000 characters or less. " + prompt)
      setResponse(resp)
      // let r = await openai.createCompletion({
      //   model: "gpt-3.5-turbo-instruct",
      //   prompt: "In 2000 characters or less. " + prompt,
      //   temperature: 1,
      //   max_tokens: 625,
      //   top_p: 1,
      //   frequency_penalty: 0,
      //   presence_penalty: 0,
      // })

      // setResponse(r.data.choices[0].text.replace("\n\n", ""))
      setIsLoading(false)
    } catch {
      setIsLoading(false)
    }
  }

  const save = async () => {
    if (prompt != "" && response != "") {
      await api.createAiSavedPrompt(currentUser.userID, {
        scope: scope,
        itemId: itemId,
        fieldName: field,
        prompt: prompt,
        response: response,
        promptDate: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
      })
      toast.success("Response saved successfully")
      await loadSavedPrompts()
    }
  }

  const deleteAiSavedPrompt = aiSavedPromptId => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this prompt?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteAiSavedPrompt(aiSavedPromptId)
          loadSavedPrompts()
        }
      })
  }

  return (
    <div
      style={{
        height: "100%",
        padding: "10px",
        boxShadow: "-5px 0 10px 0px lightgrey",
        borderLeft: "1px solid lightgray",
      }}
    >
      <Row>
        <Col>
          <div className="always-enabled">
            <button
              type="button "
              onClick={() => {
                toggle()
              }}
              className="close"
              aria-label="Close"
              style={{ color: "black", padding: "5px", marginRight: "10px" }}
            >
              <span style={{ color: "black" }} aria-hidden="true">
                &times;
              </span>
            </button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">Prompt</Label>
            <Input
              name="desc"
              type="textarea"
              rows={5}
              value={prompt}
              onChange={e => setPrompt(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <button
              type="button"
              className="btn btn-primary save-user"
              onClick={processAI}
            >
              Ask AI
            </button>
          </div>
        </Col>
      </Row>
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      {response != "" && (
        <Row>
          <Col>
            <div style={{ marginBottom: "5px" }}>
              <Label className="form-label">Response</Label>
              <TextareaAutosize
                className="form-control"
                placeholder="Response..."
                minRows={10}
                // onChange={e =>
                //   changeAlternativeProp("description", e.target.value)
                // }
                value={response}
              />
            </div>
            <div style={{ marginBottom: "15px" }}>
              <button type="button" className="btn btn-primary" onClick={save}>
                Save Prompt and Response
              </button>
            </div>
          </Col>
        </Row>
      )}
      {savedPrompts.length > 0 && (
        <table
          className="table table-bordered low-padding-table"
          style={{ backgroundColor: "white" }}
        >
          <thead>
            <tr>
              <th>Date</th>
              <th>Prompt</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {savedPrompts.map((s, idx) => (
              <tr key={idx}>
                <td>
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      setPrompt(s.prompt)
                      setResponse(s.response)
                    }}
                  >
                    {moment(s.promptDate).format("MM/DD/YYYY")}
                  </a>
                </td>
                <td>
                  <div className="one-line-elipsis">
                    <Tooltip title={<h5>{s.prompt}</h5>}>
                      <span>{s.prompt}</span>
                    </Tooltip>
                  </div>
                </td>
                <td>
                  <i
                    className="fas fa-trash"
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={() => deleteAiSavedPrompt(s.aiSavedPromptID)}
                  ></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default ChatGPTDrawer
