import React from "react"
import { Col, Label, Input } from "reactstrap"
import moment from "moment"
import isNullOrEmpty from "utils/isNullOrEmpty"

export const DynamicFormDateTime = React.memo(function dynamicFormInput({
  formFieldId,
  label,
  value,
  placeholder,
  changeFormFieldValue,
  type, // date / time / datetime
  col,
  fullWidth,
  alwaysEditable,
  alwaysReadOnly,
  hasError,
  requiredMessage,
}) {

  if (type.toLowerCase() == "date") {
    return (
      <Col md={fullWidth ? "12" : col}>
        <div className="mb-3">
          <Label>{label}</Label>
          <Input
            type="date"
            placeholder={placeholder || ""}
            onChange={e =>
              changeFormFieldValue(
                formFieldId,
                moment(e.target.value).format("YYYY-MM-DD")
              )
            }
            value={
              isNullOrEmpty(value) ? '' : moment(value).format("YYYY-MM-DD")
              // new Date(value) > new Date(1900, 1, 1, 0, 0, 0, 0)
              //   ? moment(value).format("YYYY-MM-DD")
              //   : ""
            }
            disabled={alwaysReadOnly}
          />
          {hasError && (
            <div className="invalid-feedback" style={{ display: "block" }}>
              {requiredMessage}
            </div>
          )}
        </div>
      </Col>
    )
  } else if (type.toLowerCase() == "time") {
    return (
      <Col md={fullWidth ? "12" : col}>
        <div className="mb-3">
          <Label>{label}</Label>
          <Input
            type="time"
            placeholder={placeholder || ""}
            onChange={e => {
              changeFormFieldValue(formFieldId, e.target.value)
            }}
            value={isNullOrEmpty(value) ? "" : value}
            disabled={alwaysReadOnly}
          />
          {hasError && (
            <div className="invalid-feedback" style={{ display: "block" }}>
              {requiredMessage}
            </div>
          )}
        </div>
      </Col>
    )
  } else {
    return (
      <Col md={fullWidth ? "12" : col}>
        <div className="mb-3">
          <Label>{label}</Label>
          <Input
            type="datetime-local"
            placeholder={placeholder || ""}
            onChange={e =>
              changeFormFieldValue(
                formFieldId,
                moment(e.target.value).format("YYYY-MM-DDTHH:mm:ss")
              )
            }
            value={
              isNullOrEmpty(value) ? '' : moment(value).format("YYYY-MM-DDTHH:mm:ss")
              // new Date(value) > new Date(1900, 1, 1, 0, 0, 0, 0)
              //   ? moment(value).format("YYYY-MM-DDTHH:mm")
              //   : ""
            }
            disabled={alwaysReadOnly}
          />
          {hasError && (
            <div className="invalid-feedback" style={{ display: "block" }}>
              {requiredMessage}
            </div>
          )}
        </div>
      </Col>
    )
  }
})
