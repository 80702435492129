import NameAvatar from "components/custom/nameAvatar"
import scoringColumns from "pages/AdminObjectiveScore/ScoringColumns"
import React from "react"
import CurrencyInput from "react-currency-input-field"
import api from "services/api.service"
import { userService } from "services/user.service"
import { Tooltip } from "@material-ui/core"

const ObjectiveRatingTable = ({
  scenario,
  userScores,
  setUserScores,
  pwcScore,
  hasWeight,
  canChangeWeight,
  canChangeRating,
  hasParticipants,
  reloadObjScore,
  usersPhotos
}) => {
  const currentUser = userService.getLoggedInUser()

  const onUserScoreClicked = (userScore, scoringColumn) => {
    if (canChangeRating) {
      let pwcUserScoresCopy = [...userScores]
      let pwcUserScore = pwcUserScoresCopy.find(
        x => x.userID == userScore.userID
      )
      pwcUserScore.userScored = true
      pwcUserScore.userRanking = scoringColumn.value
      api.updatePwcUserScore(pwcUserScore.levelID, pwcScore.pwcScoreID, {
        ...pwcUserScore,
      })
      setUserScores(pwcUserScoresCopy)
      reloadObjScore(pwcScore.pwcScoreID, pwcScore.levelID)
    }
  }

  const onUserUnscoreClicked = (userScore, scoringColumn) => {
    if (canChangeRating) {
      let pwcUserScoresCopy = [...userScores]
      let pwcUserScore = pwcUserScoresCopy.find(
        x => x.pwcUserScoreID == userScore.pwcUserScoreID
      )
      pwcUserScore.userScored = false
      api.deletePwcUserScore(
        pwcUserScore.userID,
        pwcUserScore.pwcScoreID,
        pwcUserScore.levelID
      )
      setUserScores(pwcUserScoresCopy)
      reloadObjScore(pwcScore.pwcScoreID, pwcScore.levelID)
    }
  }

  const showWeightInput = userScore => {
    if (canChangeWeight) {
      let userScoreWithInputVisible = userScores.find(x => x.weightInputVisible)
      if (
        userScoreWithInputVisible == undefined ||
        userScoreWithInputVisible == null
      ) {
        let pwcUserScoresCopy = [...userScores]
        let pwcUserScore = pwcUserScoresCopy.find(
          x => x.userID == userScore.userID
        )
        pwcUserScore.weightInputVisible = true
        setUserScores(pwcUserScoresCopy)
      } else {
        let pwcUserScoresCopy = userScores.map(x => {
          x.weightInputVisible = false
          return x
        })
        let pwcUserScore = pwcUserScoresCopy.find(
          x => x.userID == userScore.userID
        )
        pwcUserScore.weightInputVisible = true
        setUserScores(pwcUserScoresCopy)
      }
    }
  }

  const saveWeight = (e, userScore) => {
    if (canChangeWeight) {
      let userId = e.target.parentElement.children[1].innerText
      let pwcUserScoresCopy = [...userScores]
      let pwcUserScore = userScores.find(x => x.userID == userId)
      pwcUserScore.weightInputVisible = false
      setUserScores(pwcUserScoresCopy)
      api.updateParticipant(currentUser.userID, {
        participantID: userScore.participantID,
        votingWeight: userScore.weight,
      })
    }
  }

  const columnsCount =
    (hasParticipants == undefined || hasParticipants ? 1 : 0) +
    (hasWeight ? 1 : 0) +
    scoringColumns.filter(
      x => scenario.ahpScaleID == 2 || x.ahpScaleID == scenario.ahpScaleID
    ).length

  return (
    <table
      className="table table-bordered table-condensed"
      style={{ backgroundColor: "white" }}
    >
      <thead>
        <tr>
          {(hasParticipants == undefined || hasParticipants) && (
            <th style={{ width: "12%", textAlign: "center" }}>Name</th>
          )}
          {hasWeight && (
            <th style={{ width: "5%", textAlign: "center" }}>Weight</th>
          )}
          {scoringColumns
            .filter(
              x =>
                scenario.ahpScaleID == 2 || x.ahpScaleID == scenario.ahpScaleID
            )
            .map((s, idx) => {
              return (
                <th
                  key={idx}
                  style={{
                    backgroundColor: s.color,
                    color: s.textColor,
                    textAlign: "center",
                    textAlign: "center",
                    width:
                      (100 -
                        (hasParticipants == undefined || hasParticipants
                          ? 15
                          : 0) -
                        (hasWeight ? 5 : 0)) /
                        columnsCount +
                      "%",
                  }}
                >
                  {s.name}
                </th>
              )
            })}
        </tr>
      </thead>
      <tbody>
        {userScores.map(u => {
          return (
            <tr key={u.userID}>
              {(hasParticipants == undefined || hasParticipants) && (
                <td>
                  {(u.comment == null || u.comment == "") && (
                    <NameAvatar
                      fullName={`${u.firstName} ${u.lastName}`}
                      showName={true}
                      aiUser={u.aiUser}
                      base64Image={usersPhotos != undefined && usersPhotos.find(x=>x.userID == u.userID)?.photoBase64 || ''}
                    />
                  )}
                  {u.comment !== null && u.comment != "" && (
                    <Tooltip
                      PopperProps={{
                        className: "custom-large-tooltip",
                      }}
                      title={
                        <p
                          style={{
                            fontSize: "medium",
                            margin: "0",
                            padding: "5px",
                            fontWeight: "normal",
                            lineHeight: '1.3'
                          }}
                        >
                          {u.comment}
                        </p>
                      }
                    >
                      <span>
                        <NameAvatar
                          fullName={`${u.firstName} ${u.lastName}`}
                          showName={true}
                          aiUser={u.aiUser}
                          base64Image={usersPhotos != undefined && usersPhotos.find(x=>x.userID == u.userID)?.photoBase64 || ''}
                        />
                      </span>
                    </Tooltip>
                  )}
                </td>
              )}
              {hasWeight && (
                <>
                  {canChangeWeight && u.weightInputVisible ? (
                    <td>
                      <CurrencyInput
                        className="form-control"
                        value={u.weight * 100}
                        onValueChange={(value, name, values) => {
                          if (values.float >= 0 && values.float <= 100) {
                            let pwcUserScoresCopy = [...userScores]
                            let pwcUserScore = pwcUserScoresCopy.find(
                              x => x.userID == u.userID
                            )
                            pwcUserScore.weight = values.float / 100

                            setUserScores(pwcUserScoresCopy)
                          }
                        }}
                        onBlur={e => {
                          saveWeight(e, u)
                        }}
                        placeholder=""
                        suffix={"%"}
                        decimalScale={0}
                        decimalsLimit={0}
                      />
                      <span style={{ display: "none" }}>{u.userID}</span>
                    </td>
                  ) : (
                    <td onClick={() => showWeightInput(u)}>
                      {u.weight * 100}%
                    </td>
                  )}
                </>
              )}
              {scoringColumns
                .filter(
                  x =>
                    scenario.ahpScaleID == 2 ||
                    x.ahpScaleID == scenario.ahpScaleID
                )
                .map((s, idx) => {
                  return u.userScored && u.userRanking == s.value ? (
                    <td
                      key={idx}
                      style={{
                        cursor: canChangeRating ? "pointer" : "default",
                        // backgroundColor: "green",
                      }}
                      onClick={() => onUserUnscoreClicked(u, s)}
                    >
                      <div
                        style={{
                          backgroundColor: "green",
                          // padding: "10px",
                          margin: "0 auto",
                          borderRadius: "10px",
                          textAlign: "center",
                        }}
                      >
                        <i
                          className="fas fa-check"
                          style={{ color: "white" }}
                        ></i>
                      </div>
                    </td>
                  ) : (
                    <td
                      key={idx}
                      style={{
                        cursor: canChangeRating ? "pointer" : "default",
                      }}
                      onClick={() => onUserScoreClicked(u, s)}
                    ></td>
                  )
                })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default ObjectiveRatingTable
