import CustomColorPicker from "components/custom/CustomColorPicker"
import React, { useEffect, useState } from "react"
import { Input, Label, Modal } from "reactstrap"
import { userService } from "services/user.service"

const EditListItemModal = ({
  isOpen,
  toggleModal,
  listItemToEdit,
  setListItemToEdit,
  listName,
  onSave,
}) => {
  const currentUser = userService.getLoggedInUser()

  useEffect(() => {
    if (isOpen) {
    }
  }, [isOpen])

  const save = async () => {
    if (listItemToEdit.listItemName != "") {
      onSave()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Edit List Item
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <Label className="form-label">List Item Name</Label>
          <Input
            name="note"
            type="text"
            onChange={e =>
              setListItemToEdit({
                ...listItemToEdit,
                listItemName: e.target.value,
              })
            }
            value={listItemToEdit.listItemName}
          />
        </div>
        {(listName == "TaskStatus" ||
          listName == "RequestType" ||
          listName == "TaskProbability" ||
          listName == "TaskImpact") && (
          <>
            <CustomColorPicker
              label="Back Color"
              color={listItemToEdit.backColor}
              handleColorChange={colorObj => {
                setListItemToEdit({
                  ...listItemToEdit,
                  backColor: colorObj.hex,
                })
              }}
            />
            <CustomColorPicker
              label="Fore Color"
              color={listItemToEdit.foreColor}
              handleColorChange={colorObj => {
                setListItemToEdit({
                  ...listItemToEdit,
                  foreColor: colorObj.hex,
                })
              }}
            />
            {listName == "TaskStatus" && (
              <div className="form-check mb-3">
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="cb-completed"
                  checked={listItemToEdit.completed}
                  onClick={e => {
                    setListItemToEdit({
                      ...listItemToEdit,
                      completed: !listItemToEdit.completed,
                    })
                  }}
                />
                <Label className="form-check-label" for="cb-completed">
                  Completed
                </Label>
              </div>
            )}
            {(listName == "TaskProbability" || listName == "TaskImpact") && (
              <div className="mb-3">
                <Label className="form-label">Score</Label>
                <Input
                  type="number"
                  onChange={e =>
                    setListItemToEdit({
                      ...listItemToEdit,
                      itemScore: e.target.value,
                    })
                  }
                  value={listItemToEdit.itemScore}
                />
              </div>
            )}
          </>
        )}
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default EditListItemModal
