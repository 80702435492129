import { useFormik, yupToFormErrors } from "formik"
import moment from "moment"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import { toast } from "react-toastify"
import { Col, Form, FormFeedback, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { getAlternativeYears } from "utils/alternativeHelper"
import * as Yup from "yup"

const CreateContractModal = ({
  alternative,
  contractEntityOptions,
  strategyOptions,
  isOpen,
  toggleModal,
  reloadContracts,
  preselectedData,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [costPoolOptions, setCostPoolOptions] = useState([])
  const [selectedCostPoolId, setSelectedCostPoolId] = useState("-1")
  const [resourceGroupOptions, setResourceGroupOptions] = useState([])
  const [selectedResourceGroupId, setSelectedResourceGroupId] = useState("-1")
  const [contractOptions, setContractOptions] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  useEffect(() => {
    if (isOpen && preselectedData != undefined && preselectedData != null) {
      if (preselectedData.businessCaseItemID > -1) {
        validation.resetForm()
        setSelectedCostPoolId(preselectedData.businessCaseItemID)
        setSelectedResourceGroupId(preselectedData.resourceGroupID)
        validation.setFieldValue(
          "bcCostPoolID",
          preselectedData.businessCaseItemID
        )
        validation.setFieldValue(
          "resourceGroupID",
          preselectedData.resourceGroupID
        )
        validation.setFieldValue("contractID", preselectedData.contractID)
        validation.setFieldValue(
          "contractEntityType",
          preselectedData.contractEntityType || "0"
        )
        validation.setFieldValue(
          "contractEntityName",
          preselectedData.contractEntityName || "0"
        )
        validation.setFieldValue("strategyID", preselectedData.strategyID)
        let yearProps = Object.keys(preselectedData).filter(
          x => x.startsWith("c-") && !isNaN(x.replace("c-", ""))
        )
        if (yearProps.length > 0) {
          yearProps.forEach(yp => {
            validation.setFieldValue(yp, preselectedData[yp])
          })
        }

        if (
          preselectedData.defaultStart == null ||
          preselectedData.defaultStart == ""
        ) {
          setStartDate(new Date())
        } else {
          setStartDate(
            moment(preselectedData.defaultStart, "MM/DD/YYYY").format(
              "YYYY-MM-DDThh:mm:ss"
            )
          )
        }
        if (
          preselectedData.defaultEnd == null ||
          preselectedData.defaultEnd == ""
        ) {
          setEndDate(new Date())
        } else {
          setEndDate(
            moment(preselectedData.defaultEnd, "MM/DD/YYYY").format(
              "YYYY-MM-DDThh:mm:ss"
            )
          )
        }
        loadCostPools()
      } else {
        setSelectedCostPoolId("-1")
        setSelectedResourceGroupId("-1")
        loadCostPools()
        loadContracts()
        validation.resetForm()
      }
    }
  }, [isOpen, preselectedData])

  useEffect(() => {
    if (selectedCostPoolId != "-1") {
      loadResourceGroups()
      loadContractAmounts()
    }
  }, [selectedCostPoolId])

  useEffect(() => {
    if (selectedResourceGroupId != "-1") {
      loadContracts()
    }
  }, [selectedResourceGroupId])

  const loadResourceGroups = async () => {
    let rs = await api.getResourceGroupsByBcCostPool(selectedCostPoolId)
    setResourceGroupOptions(rs)
  }

  const loadContracts = async () => {
    let cs = await api.getContractsForResource(
      alternative.scenarioID,
      selectedResourceGroupId
    )
    setContractOptions(cs)
  }

  const loadCostPools = async () => {
    let cp = await api.getContractsCostPools(alternative.alternativeID)
    setCostPoolOptions(cp)
  }

  const loadContractAmounts = async () => {
    if (
      preselectedData == undefined ||
      preselectedData == null ||
      preselectedData.businessCaseItemID == -1
    ) {
      let amounts = await api.getRemainingContractAmounts(selectedCostPoolId)
      if (amounts !== undefined && amounts !== null && amounts.length > 0) {
        alternativeYears.forEach(y => {
          validation.setFieldValue(`c-${y}`, amounts[0][`c-${y}`])
        })
      }
    }
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      bcCostPoolID: -1,
      resourceGroupID: -1,
      contractID: -1,
      contractEntityType: "-1",
      contractEntityName: "-1",
      strategyID: -1,
    },
    validationSchema: Yup.object({
      bcCostPoolID: Yup.number()
        .required("Please select a cost pool")
        .moreThan(-1, "Please select a cost pool"),
      resourceGroupID: Yup.number(),
      contractID: Yup.number()
        .required("Please select a contract")
        .moreThan(0, "Please select a contract"),
      contractEntityType: Yup.string(),
      contractEntityName: Yup.string(),
      strategyID: Yup.number()
        .required("Please select an acquisition approach")
        .notOneOf([-1], "Please select an acquisition approach"),
    }),
    onSubmit: async values => {
      let newContract = { ...values }
      let contractEntity = contractEntityOptions.find(
        x =>
          x.contractEntityName == newContract.contractEntityName &&
          x.contractEntityType == newContract.contractEntityType
      )
      if (contractEntity !== undefined) {
        newContract.contractEntityID = contractEntity.contractEntityID
      }
      newContract.startDate = startDate
      newContract.endDate = endDate

      newContract.contractItemYears = []
      let yearProps = Object.keys(newContract).filter(
        x => x.startsWith("c-") && !isNaN(x.replace("c-", ""))
      )
      if (yearProps.length > 0) {
        yearProps.forEach(yp => {
          newContract.contractItemYears.push({
            yearID: yp.replace("c-", ""),
            amount: newContract[yp],
          })
        })
      }

      try {
        await api.createContractItem(currentUser.userID, newContract)
        toast.success("Contract added successfully")
        reloadContracts()
        toggleModal()
      } catch (err) {
        toast.error("An error occured while saving the contract")
      }
    },
  })

  const alternativeYears = getAlternativeYears(alternative)

  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Contract Item
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="mb-3">
            <Label>Cost Pool</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={validation.values.bcCostPoolID || "-1"}
              onChange={e => {
                console.log(e.target.value)
                validation.setFieldValue("bcCostPoolID", e.target.value)
                setSelectedCostPoolId(e.target.value)
              }}
            >
              <option value={"-1"}>Select Cost Pool</option>
              {costPoolOptions.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.bcCostPoolID}`}>
                    {a.costPoolName}
                  </option>
                )
              })}
            </select>
            {validation.touched.bcCostPoolID &&
            validation.errors.bcCostPoolID ? (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {validation.errors.bcCostPoolID}
              </div>
            ) : null}
          </div>
          <div className="mb-3">
            <Label>Resource</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={validation.values.resourceGroupID || "-1"}
              onChange={e => {
                console.log(e.target.value)
                validation.setFieldValue("resourceGroupID", e.target.value)
                setSelectedResourceGroupId(e.target.value)
              }}
            >
              <option value={"-1"}>Select Resource</option>
              {resourceGroupOptions.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.resourceGroupID}`}>
                    {a.resourceGroupName}
                  </option>
                )
              })}
            </select>
            {validation.touched.resourceGroupID &&
            validation.errors.resourceGroupID ? (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {validation.errors.resourceGroupID}
              </div>
            ) : null}
          </div>
          <div className="mb-3">
            <Label>Contract</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={validation.values.contractID || -1}
              onChange={e => {
                validation.setFieldValue("contractID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {contractOptions.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.contractID}`}>
                    {a.contractName}
                  </option>
                )
              })}
            </select>
            {validation.touched.contractID && validation.errors.contractID ? (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {validation.errors.contractID}
              </div>
            ) : null}
          </div>
          <Row>
            <Col md="6">
              <div className="mb-3">
                <Label>PoP Start</Label>
                <Input
                  name="startDate"
                  type="date"
                  onChange={e =>
                    setStartDate(
                      moment(e.target.value, "YYYY-MM-DD").format(
                        "YYYY-MM-DDThh:mm:ss"
                      )
                    )
                  }
                  value={moment(startDate).format("YYYY-MM-DD")}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <Label>PoP End</Label>
                <Input
                  name="endDate"
                  type="date"
                  onChange={e =>
                    setEndDate(
                      moment(e.target.value, "YYYY-MM-DD").format(
                        "YYYY-MM-DDThh:mm:ss"
                      )
                    )
                  }
                  value={moment(endDate).format("YYYY-MM-DD")}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="mb-3">
                <Label>Entity Type</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={validation.values.contractEntityType || "-1"}
                  onChange={e => {
                    validation.setFieldValue(
                      "contractEntityType",
                      e.target.value
                    )
                  }}
                >
                  <option value={-1}>Select...</option>
                  {[
                    ...new Set(
                      contractEntityOptions.map(item => item.contractEntityType)
                    ),
                  ].map((a, idx) => {
                    return (
                      <option key={idx} value={`${a}`}>
                        {a}
                      </option>
                    )
                  })}
                </select>
                {validation.touched.contractEntityType &&
                validation.errors.contractEntityType ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {validation.errors.contractEntityType}
                  </div>
                ) : null}
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <Label>Entity Name</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={validation.values.contractEntityName || "-1"}
                  onChange={e => {
                    validation.setFieldValue(
                      "contractEntityName",
                      e.target.value
                    )
                  }}
                >
                  <option value={-1}>Select...</option>
                  {contractEntityOptions
                    .filter(
                      x =>
                        x.contractEntityType ==
                        validation.values.contractEntityType
                    )
                    .map((a, idx) => {
                      return (
                        <option key={idx} value={`${a.contractEntityName}`}>
                          {a.contractEntityName}
                        </option>
                      )
                    })}
                </select>
                {validation.touched.contractEntityName &&
                validation.errors.contractEntityName ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {validation.errors.contractEntityName}
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>

          <div className="mb-3">
            <Label>Acquisition Approach</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={validation.values.strategyID}
              onChange={e => {
                validation.setFieldValue("strategyID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {strategyOptions.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemID}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
            {validation.touched.strategyID && validation.errors.strategyID ? (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {validation.errors.strategyID}
              </div>
            ) : null}
          </div>
          {selectedCostPoolId !== "-1" && (
            <div className="flex-row-space">
              {alternativeYears.map((y, idx) => (
                <div key={idx}>
                  <Label>FY {y}</Label>
                  <CurrencyInput
                    className="form-control align-end"
                    value={validation.values[`c-${y}`] || 0}
                    onValueChange={(value, name, values) => {
                      validation.setFieldValue(`c-${y}`, values.float)
                    }}
                    prefix={"$"}
                  />
                </div>
              ))}
              {/* <div>
                <Label>FY {alternative.fyStart}</Label>
                <CurrencyInput
                  id={`year1Amountss`}
                  name={`year1Amountss`}
                  className="form-control align-end"
                  value={validation.values.year1Amount}
                  onValueChange={(value, name, values) => {
                    validation.setFieldValue("year1Amount", values.float)
                  }}
                  prefix={"$"}
                />
                {validation.touched.year1Amount &&
                validation.errors.year1Amount ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {validation.errors.year1Amount}
                  </div>
                ) : null}
              </div>
              <div>
                <Label>FY {alternative.fyStart + 1}</Label>
                <CurrencyInput
                  id={`year2Amountss`}
                  name={`year2Amountss`}
                  className="form-control align-end"
                  value={validation.values.year2Amount}
                  onValueChange={(value, name, values) => {
                    validation.setFieldValue("year2Amount", values.float)
                  }}
                  prefix={"$"}
                />
                 {validation.touched.year2Amount &&
                validation.errors.year2Amount ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {validation.errors.year2Amount}
                  </div>
                ) : null}
              </div>
              <div>
                <Label>FY {alternative.fyStart + 2}</Label>
                <CurrencyInput
                  id={`year3Amountss`}
                  name={`year3Amountss`}
                  className="form-control align-end"
                  value={validation.values.year3Amount}
                  onValueChange={(value, name, values) => {
                    validation.setFieldValue("year3Amount", values.float)
                  }}
                  prefix={"$"}
                />
                 {validation.touched.year3Amount &&
                validation.errors.year3Amount ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {validation.errors.year3Amount}
                  </div>
                ) : null}
              </div>
              <div>
                <Label>FY {alternative.fyStart + 3}</Label>
                <CurrencyInput
                  id={`year4Amountss`}
                  name={`year4Amountss`}
                  className="form-control align-end"
                  value={validation.values.year4Amount}
                  onValueChange={(value, name, values) => {
                    validation.setFieldValue("year4Amount", values.float)
                  }}
                  prefix={"$"}
                />
                 {validation.touched.year4Amount &&
                validation.errors.year4Amount ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {validation.errors.year4Amount}
                  </div>
                ) : null}
              </div>
              <div>
                <Label>FY {alternative.fyStart + 4}</Label>
                <CurrencyInput
                  id={`year5Amountss`}
                  name={`year5Amountss`}
                  className="form-control align-end"
                  value={validation.values.year5Amount}
                  onValueChange={(value, name, values) => {
                    validation.setFieldValue("year5Amount", values.float)
                  }}
                  prefix={"$"}
                />
                 {validation.touched.year5Amount &&
                validation.errors.year5Amount ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {validation.errors.year5Amount}
                  </div>
                ) : null}
              </div> */}
            </div>
          )}
          <div className="modal-footer">
            <div>
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={toggleModal}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary save-user">
                Save
              </button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

export default CreateContractModal
