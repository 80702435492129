import { TextareaAutosize } from "@material-ui/core"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const ResourceEstimateRequestNotesModal = ({
  isOpen,
  toggleModal,
  scenarioId,
  alternative,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [users, setUsers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [allSelected, setAllSelected] = useState(false)
  const [emailNotes, setEmailNotes] = useState("")

  useEffect(() => {
    if (isOpen) {
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    setIsLoading(true)
    let u = await api.getResourceGroupUsersScenario(scenarioId)
    setUsers(
      u.map(x => {
        x.isSelected = false
        return x
      })
    )
    setIsLoading(false)
  }

  const save = async () => {}

  const sendReminder = async () => {
    await api.sendAltResourceBudgetEmail(
      currentUser.userID,
      alternative.alternativeID,
      emailNotes,
      users
        .filter(x => x.isSelected)
        .map(x => x.userID)
        .join(",")
    )
    toast.success("Reminder sent")
    toggleModal()
  }

  const hasUserSelected = users.find(x => x.isSelected == true) !== undefined

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Resource Managers
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        {!isLoading && (
          <table
            className="table table-bordered low-padding-table mb-3"
            style={{ backgroundColor: "white" }}
          >
            <thead>
              <tr>
                <th>
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    checked={allSelected}
                    onClick={e => {
                      let newVal = !allSelected
                      setUsers(
                        users.map(u => {
                          u.isSelected = newVal
                          return u
                        })
                      )
                      setAllSelected(newVal)
                    }}
                  />
                </th>
                <th>Resource</th>
                <th>Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {users.map((u, idx) => (
                <tr key={idx}>
                  <td>
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      checked={u.isSelected}
                      onClick={e => {
                        let copy = [...users]
                        let update = copy.find(x => x.resourceGroupUserID == u.resourceGroupUserID)
                        update.isSelected = !update.isSelected
                        setUsers(copy)
                      }}
                    />
                  </td>
                  <td>{u.resourceGroupName}</td>
                  <td>
                    {u.firstName} {u.lastName}
                  </td>
                  <td>{u.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div className="mb-3">
          <Label className="form-label">Resource Estimate Request Notes</Label>
          <TextareaAutosize
            className="form-control"
            minRows={3}
            onChange={e => setEmailNotes(e.target.value)}
            placeholder="You may want to request that the resource estimate be provided by a specific date, as needed."
            value={emailNotes}
          />
        </div>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={sendReminder}
          disabled={!hasUserSelected}
        >
          Send Request(s)
        </button>
      </div>
    </Modal>
  )
}

export default ResourceEstimateRequestNotesModal
