import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Row,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import { currencyFormatter } from "utils/formatters"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import anychart from "anychart"
import BubbleChart from "./BubbleChart"

const SetBubbleChart = props => {
  const currentUser = userService.getLoggedInUser()
  const scenarioId = props.match.params.scenarioId
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const { optObjFin } = useSelector(state => ({
    optObjFin: state.optObjFin.optObjFin,
  }))
  const [isLoading, setIsLoading] = useState(false)
  const [objectivesDropdownList, setObjectivesDropdownList] = useState([])
  const [selectedObj, setSelectedObj] = useState(0)
  const [showActionsDropdown, setShowActionsDropdown] = useState(false)
  const [gridData, setGridData] = useState([])
  const [altIdsList, setAltIdsList] = useState([])

  const [approved, setApproved] = useState(false)
  const [notApproved, setNotApproved] = useState(false)
  const [onHold, setOnHold] = useState(false)
  const [pending, setPending] = useState(false)
  const [scored, setScored] = useState(false)
  const [withdrawn, setWithdrawn] = useState(false)
  const [chartOption1, setChartOption1] = useState(true)

  const [isBubbleChartModalVisible, setIsBubbleChartModalVisible] =
    useState(false)
  const toggleBubbleChartModal = () =>
    setIsBubbleChartModalVisible(!isBubbleChartModalVisible)

  const [decisions, setDecisions] = useState([])
  const [selectedDecision, setSelectedDecision] = useState("All")

  useEffect(() => {
    anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
  }, [])

  useEffect(() => {
    loadData()
  }, [optObjFin])

  const loadData = async () => {
    try {
      setIsLoading(true)

      let d = await api.getScenarioList(scenarioId, "Decisions")
      setDecisions(d)
      await loadGrid(selectedDecision)
      setIsLoading(false)
    } catch (er) {
      console.log(er)
      setIsLoading(false)
    }
  }

  const loadGrid = async dec => {
    setApproved(false)
    setNotApproved(false)
    setOnHold(false)
    setPending(false)
    setScored(false)
    setWithdrawn(false)
    let data = await api.getAltScoresSecondary(scenarioId, 0, 0, dec)
    setGridData(data)
  }

  const changeSelectionByDecision = (selected, decision) => {
    let copy = [...gridData]
    let items = copy.filter(x => x.finalDecision == decision)
    if (items.length > 0) {
      items.forEach(it => {
        it.isSelected = selected
      })
    }
    setAltIdsList(copy.filter(x => x.isSelected).map(x => x.alternativeID))
    setGridData(copy)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Card>
            <CardBody>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              {!isLoading && (
                <>
                  <Row style={{ marginBottom: "20px" }}>
                    <Col
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div className="mb-3">
                          <Label className="form-label">View</Label>
                          <select
                            className="form-control form-select select2 mb-xxl-0"
                            value={selectedDecision || 0}
                            style={{ width: "400px" }}
                            onChange={e => {
                              setSelectedDecision(e.target.value)
                              loadGrid(e.target.value)
                            }}
                          >
                            <option value="All">All</option>
                            <option value="Scored">Scored</option>
                            {decisions.map((a, idx) => {
                              return (
                                <option key={idx} value={`${a.listItemName}`}>
                                  {a.listItemName}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                        <div
                          className="mb-3"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            style={{ marginRight: "10px" }}
                            id="cb1"
                            checked={approved}
                            onClick={e => {
                              changeSelectionByDecision(!approved, "Approved")
                              setApproved(!approved)
                            }}
                          />
                          <Label className="form-check-label" for="cb1">
                            Approved
                          </Label>
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                            id="cb2"
                            checked={notApproved}
                            onClick={e => {
                              changeSelectionByDecision(
                                !notApproved,
                                "Not Approved"
                              )
                              setNotApproved(!notApproved)
                            }}
                          />
                          <Label className="form-check-label" for="cb2">
                            Not Approved
                          </Label>
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                            id="cb3"
                            checked={onHold}
                            onClick={e => {
                              changeSelectionByDecision(!onHold, "On Hold")
                              setOnHold(!onHold)
                            }}
                          />
                          <Label className="form-check-label" for="cb3">
                            On Hold
                          </Label>
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                            id="cb4"
                            checked={pending}
                            onClick={e => {
                              changeSelectionByDecision(!pending, "Pending")
                              setPending(!pending)
                            }}
                          />
                          <Label className="form-check-label" for="cb4">
                            Pending
                          </Label>
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                            id="cb5"
                            checked={scored}
                            onClick={e => {
                              changeSelectionByDecision(!scored, "Scored")
                              setScored(!scored)
                            }}
                          />
                          <Label className="form-check-label" for="cb5">
                            Scored
                          </Label>
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                            id="cb6"
                            checked={withdrawn}
                            onClick={e => {
                              changeSelectionByDecision(!withdrawn, "Withdrawn")
                              setWithdrawn(!withdrawn)
                            }}
                          />
                          <Label className="form-check-label" for="cb6">
                            Withdrawn
                          </Label>
                        </div>
                        <div>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="anon1"
                            id="anon1"
                            // value="0"
                            checked={chartOption1}
                            onClick={e => {
                              setChartOption1(true)
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="anon1"
                            style={{ marginLeft: "10px" }}
                          >
                            Option 1: Benefit vs Cost, with Risk by Bubble Color
                          </label>
                        </div>
                        <div>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="anon2"
                            id="anon2"
                            // value="1"
                            checked={!chartOption1}
                            onClick={e => {
                              setChartOption1(false)
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="anon2"
                            style={{ marginLeft: "10px" }}
                          >
                            Option 2: Benefit vs Cost, with Risk by Bubble Color
                          </label>
                        </div>
                      </div>
                      <div className="mb-3" style={{ alignSelf: "end" }}>
                        <button
                          className="btn btn-primary"
                          onClick={toggleBubbleChartModal}
                        >
                          View Bubble Chart
                        </button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <table
                        className="table table-bordered low-padding-table"
                        style={{ backgroundColor: "white" }}
                      >
                        <thead>
                          <tr>
                            <th style={{ width: "5%" }}></th>
                            <th style={{ width: "5%" }}>ID</th>
                            <th style={{ width: "25%" }}>Project</th>
                            <th style={{ width: "15%" }}>Benefit Score</th>
                            <th style={{ width: "15%" }}>Running Score</th>
                            <th style={{ width: "10%" }}>Cost</th>
                            {/* <th style={{ width: "15%" }}>Running Cost</th> */}
                            <th style={{ width: "10%" }}>Decision</th>
                          </tr>
                        </thead>
                        <tbody>
                          {gridData.map((a, idx) => (
                            <tr key={idx}>
                              <td>
                                <Input
                                  type="checkbox"
                                  checked={a.isSelected}
                                  onClick={() => {
                                    let copy = [...gridData]
                                    let item = copy.find(
                                      x => x.alternativeID == a.alternativeID
                                    )
                                    item.isSelected = !item.isSelected
                                    setAltIdsList(
                                      copy
                                        .filter(x => x.isSelected)
                                        .map(x => x.alternativeID)
                                    )
                                    setGridData(copy)
                                  }}
                                />
                              </td>
                              <td>{a.alternativeNum}</td>
                              <td>{a.alternative}</td>
                              <td></td>
                              <td></td>
                              <td className="text-right">
                                {currencyFormatter.format(a.cost)}
                              </td>
                              {/* <td className="text-right">
                                {currencyFormatter.format(a.runningCost)}
                              </td> */}
                              <td>{a.finalDecision}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <BubbleChart
        scenarioId={scenarioId}
        alternativeList={altIdsList}
        isOpen={isBubbleChartModalVisible}
        toggleModal={toggleBubbleChartModal}
        chartOption={chartOption1 ? 1 : 2}
        optObjFin={optObjFin}
      />
    </React.Fragment>
  )
}

export default SetBubbleChart
