import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

const PaginatedTable = props => {
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSettings, setCurrentPageSettings] = useState(props.pageSize || 20)
  const pageSettings = [10, 20, 30, 50, 100]

  const goPrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const goNext = () => {
    if (currentPage < Math.ceil(props.items.length / currentPageSettings)) {
      setCurrentPage(currentPage + 1)
    }
  }

  const idxStart = (currentPage - 1) * currentPageSettings
  const idxEnd =
    currentPageSettings * currentPage > props.items.length
      ? props.items.length
      : currentPageSettings * currentPage

  return (
    <React.Fragment>
      <div className="sticky-table-div">
        <table
          className={`table table-bordered low-padding-table ${
            props.isSticky && "sticky-table"
          }`}
          style={{
            backgroundColor: "white",
            borderCollapse: props.isSticky ? "separate" : "collapse",
            borderSpacing: 0,
            width: props.width ? `${props.width}px` : "auto",
          }}
        >
          {props.children}
          <tbody>
            {props.items
              .slice(idxStart, idxEnd)
              .map((item, idx) => props.rowRenderer(item, idx))}
          </tbody>
        </table>
      </div>
      {props.items.length > currentPageSettings && (
        <div className="tabulator" style={{ minHeight: "100px" }}>
          <div className="tabulator-footer">
            <span className="tabulator-paginator">
              <label>Page Size</label>
              <select
                className="tabulator-page-size"
                aria-label="Page Size"
                title="Page Size"
                value={currentPageSettings}
                onChange={e => setCurrentPageSettings(e.target.value)}
              >
                {pageSettings.map((p, idx) => (
                  <option key={idx} value={p}>
                    {p}
                  </option>
                ))}
              </select>
              <button
                className="tabulator-page"
                type="button"
                role="button"
                aria-label="Prev Page"
                title="Prev Page"
                data-page="prev"
                onClick={goPrev}
                disabled={currentPage == 1}
              >
                Prev
              </button>
              <span className="tabulator-pages">
                {Array.from(
                  Array(
                    Math.ceil(props.items.length / currentPageSettings)
                  ).keys()
                ).map((i, idx) => (
                  <button
                    key={idx}
                    className={`tabulator-page ${
                      currentPage == i + 1 ? "active" : ""
                    }`}
                    type="button"
                    role="button"
                    onClick={() => setCurrentPage(i + 1)}
                  >
                    {i + 1}
                  </button>
                ))}
              </span>
              <button
                className="tabulator-page"
                type="button"
                role="button"
                aria-label="Next Page"
                title="Next Page"
                data-page="next"
                onClick={goNext}
                disabled={
                  Math.ceil(props.items.length / currentPageSettings) ==
                  currentPage
                }
              >
                Next
              </button>
            </span>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

PaginatedTable.propTypes = {
  children: PropTypes.any,
  items: PropTypes.array,
  rowRenderer: PropTypes.func,
}

export default PaginatedTable
