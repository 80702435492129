import PaginatedTable from "components/custom/PaginatedTable"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import { sortData } from "utils/sort"

const EditAlternativeModal = ({
  isOpen,
  toggleModal,
  roadmapToEdit,
  alternative,
  saveAlternative,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [mappedAlternative, setMappedAlternative] = useState(null)

  const categories =
    roadmapToEdit == null || roadmapToEdit.roadmapCategories == null
      ? []
      : roadmapToEdit.roadmapCategories.filter(x => x.listItemID > -1)

  useEffect(() => {
    if (isOpen) {
      mapAlternative()
    }
  }, [isOpen])

  const mapAlternative = () => {
    setMappedAlternative(alternative)
  }

  const renderDatesPair = (leftLabel, leftProp, rightLabel, rightProp) => {
    return (
      <Row>
        <Col md="6">
          <div className="mb-3">
            <Label className="form-label">{leftLabel}</Label>
            <Input
              type="date"
              disabled={true}
              onChange={e => {
                setMappedAlternative({
                  ...mappedAlternative,
                  [leftProp]: moment(e.target.value, "YYYY-MM-DD").format(
                    "YYYY-MM-DDThh:mm:ss"
                  ),
                })
              }}
              value={
                new Date(mappedAlternative[leftProp]) >
                new Date(1900, 1, 1, 0, 0, 0, 0)
                  ? moment(mappedAlternative[leftProp]).format("YYYY-MM-DD")
                  : ""
              }
            />
          </div>
        </Col>
        <Col md="6">
          <div className="mb-3">
            <Label className="form-label">{rightLabel}</Label>
            <Input
              type="date"
              onChange={e => {
                setMappedAlternative({
                  ...mappedAlternative,
                  [rightProp]: moment(e.target.value, "YYYY-MM-DD").format(
                    "YYYY-MM-DDThh:mm:ss"
                  ),
                })
              }}
              value={
                new Date(mappedAlternative[rightProp]) >
                new Date(1900, 1, 1, 0, 0, 0, 0)
                  ? moment(mappedAlternative[rightProp]).format("YYYY-MM-DD")
                  : ""
              }
            />
          </div>
        </Col>
      </Row>
    )
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Edit
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {mappedAlternative != null && (
          <>
            <table
              className="table table-bordered low-padding-table"
              style={{
                backgroundColor: "white",
                borderCollapse: "collapse",
              }}
            >
              <thead>
                <tr>
                  <th className="col-1">ID</th>
                  <th className="col-3">Project</th>
                  <th className="col-2">Category</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{mappedAlternative.alternativeNum}</td>
                  <td>{mappedAlternative.alternativeName}</td>
                  <td>
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      value={mappedAlternative.roadmapCategoryID}
                      onChange={e => {
                        let alt = { ...mappedAlternative }
                        alt.roadmapCategoryID = e.target.value
                        setMappedAlternative(alt)
                      }}
                    >
                      <option value="-1">None</option>
                      {categories.map((a, idx) => {
                        return (
                          <option key={idx} value={`${a.listItemID}`}>
                            {a.listItemName}
                          </option>
                        )
                      })}
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="alert alert-success alert-dismissible fade show">The roadmap will display the official planning dates from the governance tab (in the left column) unless roadmap planning dates are entered (in the right column). The roadmap planning dates are for roadmap use only and do not impact the official planning dates.</div>

            <Row style={{
                padding: "10px",
                textAlign: "center",
                textDecoration: "underline"
              }}>
              <Col md="6">
                Governance Dates
                </Col>
                <Col md="6">
                  Roadmap Dates
                </Col>
             </Row>          

            {renderDatesPair(
              "Project Start",
              "desiredStart",
              "Project Start",
              "rM_DesiredStart"
            )}
             {renderDatesPair(
              "Requirements Completion",
              "requirementsDate",
              "Requirements Completion",
              "rM_RequirementsDate"
            )}
            {renderDatesPair(
              "Market Research Completion",
              "marketResearchDate",
              "Market Research Completion",
              "rM_MarketResearchDate"
            )}
            {renderDatesPair(
              "Procurement Package Completion",
              "procurementPackageDate",
              "Procurement Package Completion",
              "rM_ProcurementPackageDate"
            )}
            {renderDatesPair(
              "Procurement Completion",
              "procurementDate",
              "Procurement Completion",
              "rM_ProcurementDate"
            )}
            {renderDatesPair(
              "Project Finish",
              "plannedFinish",
              "Project Finish",
              "rM_PlannedFinish"
            )}            
          </>
        )}
      </div>
      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={() => saveAlternative(mappedAlternative)}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default EditAlternativeModal
