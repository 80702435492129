import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row, Alert } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const TShirtHoursAllocationModal = ({
  isOpen,
  toggleModal,
  resourceGroupBundleId, // {resourceGroupId, tShirtBundleId}
  alternativeYears,
  applyTShirtHoursAllocation,
  alternativeId,
  scenarioId,
  alternativeName,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [tShirts, setTShirts] = useState([])
  const [selectedTShirtId, setSelectedTShirtId] = useState(-1)

  const [years, setYears] = useState([])
  const [totalHours, setTotalHours] = useState(0)
  const [showTotalHours, setShowTotalHours] = useState(true)

  const [resourceGroupCostPools, setResourceGroupCostPools] = useState([])
  const [resourceUnit, setResourceUnit] = useState("")

  useEffect(() => {
    if (isOpen) {
      if (
        resourceGroupBundleId !== undefined &&
        resourceGroupBundleId.tShirtBundleId !== undefined &&
        resourceGroupBundleId.resourceGroupId !== undefined
      ) {
        loadData()
        loadChart()
      }
    }
  }, [isOpen])

  const loadData = async () => {
    let t = await api.getResourceGroupTShirts(
      resourceGroupBundleId.resourceGroupId
    )
    setTShirts(t)

    let resourceGroupsByBundle = await api.getResourceGroupsByTShirtBundleId(
      resourceGroupBundleId.tShirtBundleId
    )
    if (resourceGroupsByBundle.length > 0) {
      setResourceUnit(resourceGroupsByBundle[0].resourceunit)
    }
    resourceGroupsByBundle = resourceGroupsByBundle.filter(
      x => x.projectCostPoolID > 0
    )

    let tasks = resourceGroupsByBundle.map(x => async () => {
      return await api.getResourceGroupCostPool(x.projectCostPoolID)
    })
    let costPools = await Promise.all(tasks.map(t => t()))

    let gridItems = await api.getAlternativeResourceBudgetsGridBundle(
      alternativeId,
      resourceGroupBundleId.tShirtBundleId
    )

    costPools = costPools.map(c => {
      let rg = resourceGroupsByBundle.find(
        x => x.projectCostPoolID == c.costPoolID
      )
      if (rg != undefined) {
        c.resourceGroupName = rg.resourceGroupName
        c.resourceGroupID = rg.resourceGroupID
      }
      let gridItem = gridItems.find(x => x.costPoolID == c.costPoolID)
      let ret = { ...c }
      if (gridItem != undefined) {
        ret = { ...gridItem, ...ret }
      }

      return ret
    })

    let tshirt = getTShirtIdToSelect(costPools, alternativeYears, t)
    if (tshirt != undefined) {
      setSelectedTShirtId(tshirt.tShirtID)
    } else {
      let totalHours = getTotalHours(costPools, alternativeYears)
      setTotalHours(totalHours)
    }

    changeHoursAllocation(t, costPools, alternativeYears, selectedTShirtId)
  }

  const getTotalHours = (costPools, alternativeYears) => {
    return costPools
      .map(x => {
        x.totalAllYears = 0
        alternativeYears.forEach(y => {
          x.totalAllYears += parseFloat(x["c-" + y] || 0)
        })
        return x
      })
      .reduce((acc, curr) => (acc += curr.totalAllYears), 0)
  }

  const getTShirtIdToSelect = (costPools, alternativeYears, tshirts) => {
    let totalHours = getTotalHours(costPools, alternativeYears)
    setTotalHours(totalHours)
    return tshirts.reduce((closest, current) => {
      const currentDifference = Math.abs(current.tShirtHours - totalHours)
      const closestDifference = Math.abs(closest.tShirtHours - totalHours)

      if (currentDifference < closestDifference) {
        return current
      }

      return closest
    }, tshirts[0])
  }

  const changeHoursAllocation = (
    tshirts,
    costPools,
    alternativeYears,
    tshirtId
  ) => {
    let tshirt = tshirts.find(x => x.tShirtID == tshirtId)
    let totalHours = 0
    let cpTotalHours = getTotalHours(costPools, alternativeYears)
    if (cpTotalHours > 0) {
      setShowTotalHours(true)
    }
    if (tshirt != undefined && cpTotalHours == 0) {
      totalHours = tshirt.tShirtHours
    } else {
      totalHours = getTotalHours(costPools, alternativeYears)
    }

    let updatedCostPools = costPools.map(cp => {
      let totalHoursForCp = 0
      alternativeYears.forEach(y => {
        let hours = parseFloat(cp["c-" + y] || 0)
        totalHoursForCp += hours
      })
      cp.hoursAllocationPercent = Math.round(
        totalHoursForCp == 0 ? 0 : (totalHoursForCp * 100) / totalHours
      )
      // parseFloat(
      //   totalHoursForCp == 0 ? 0 : (totalHoursForCp * 100) / totalHours
      // ).toFixed(2)

      return cp
    })
    setResourceGroupCostPools(updatedCostPools)

    let updatedYears = alternativeYears.map(y => {
      let hours = costPools.reduce(
        (acc, curr) => (acc += parseFloat(curr["c-" + y] || 0)),
        0
      )

      return {
        year: y,
        hoursAllocationPercent: Math.round(
          hours == 0 ? 0 : (hours * 100) / totalHours
        ),
        // parseFloat(
        //   hours == 0 ? 0 : (hours * 100) / totalHours
        // ).toFixed(2),
      }
    })
    setYears(updatedYears)
  }

  const changeCostPoolProp = (costPoolId, prop, val) => {
    let copy = [...resourceGroupCostPools]
    let item = copy.find(x => x.costPoolID == costPoolId)
    item[prop] = val
    item.isDirty = true
    setResourceGroupCostPools(copy)
  }

  const changeYearProp = (year, prop, val) => {
    let copy = [...years]
    let item = copy.find(x => x.year == year)
    item[prop] = val
    item.isDirty = true
    setYears(copy)
  }

  const costPoolsTotalHoursAllocationPercent = Math.round(
    resourceGroupCostPools.reduce(
      (acc, curr) => acc + parseFloat(curr.hoursAllocationPercent || 0),
      0
    )
  )
  // parseFloat(costPools.reduce(
  //   (acc, curr) => acc + parseFloat(curr.hoursAllocationPercent || 0),
  //   0
  // )).toFixed(2)

  const yearsTotalHoursAllocationPercent = Math.round(
    years.reduce(
      (acc, curr) => acc + parseFloat(curr.hoursAllocationPercent || 0),
      0
    )
  )
  // parseFloat(years.reduce(
  //   (acc, curr) => acc + parseFloat(curr.hoursAllocationPercent || 0),
  //   0
  // )).toFixed(2)

  const isValid =
    yearsTotalHoursAllocationPercent == 100 &&
    costPoolsTotalHoursAllocationPercent == 100

  const save = async () => {
    let costPoolsCopy = [...resourceGroupCostPools]
    
    costPoolsCopy.forEach(cp => {
      // cp.resourceGroupID = resourceGroupBundleId.resourceGroupId
      let cpHours = (totalHours * parseFloat(cp.hoursAllocationPercent)) / 100
      years.forEach(y => {
        let yearHours = (cpHours * parseFloat(y.hoursAllocationPercent)) / 100
        cp["updated-" + y.year] = yearHours
      })
    })

    applyTShirtHoursAllocation(costPoolsCopy)
    toggleModal()
  }

  const onChange = data => {
    setSelectedTShirtId(data.value)
    setShowTotalHours(false)
  }

  const loadChart = async () => {
    if (alternativeId !== undefined) {
      let altIds = alternativeId
      let scenario = await api.getScenario(scenarioId)
      let chartResp = await api.getAlternativeListChart(
        altIds,
        "BCD_Milestone_Task",
        40
      )
      renderGanttChart(chartResp, scenario, "gantt")
    }
  }

  const renderGanttChart = (response, scenario, divId) => {
    if (
      document.getElementById(divId) !== undefined &&
      document.getElementById(divId) !== null
    ) {
      document.getElementById(divId).innerHTML = ""
    }
    let obj = eval("(" + response.chartArray.replaceAll("'", '"') + ")")
    let json = JSON.stringify(obj)
    var data = JSON.parse(json)
    var treeData = anychart.data.tree(data, "as-tree")
    var chart = anychart.ganttProject()
    chart.data(treeData)
    var ganttDiv = document.getElementById(divId)
    var items = data.length
    //var gh = 100 + 95 * items
    var gh = 200
    ganttDiv.style.height = gh + "px"

    var dataGrid = chart.dataGrid()
    var dgTooltip = dataGrid.tooltip()
    dgTooltip.useHtml(true)

    chart.getTimeline().tooltip().useHtml(true)
    chart.getTimeline().tooltip()

    var header = chart.getTimeline().header()
    var mlevel = header.level(2)

    chart
      .dataGrid()
      .tooltip()
      .format(function () {
        var duration = (this.actualEnd - this.actualStart) / 1000 / 3600 / 24
        var startDate = anychart.format.dateTime(this.actualStart, "dd MMM")
        var endDate = anychart.format.dateTime(this.actualEnd, "dd MMM")
        var progress = Math.round(this.progress * 100 * 100) / 100 + "%"

        var parentText =
          "<span>" +
          startDate +
          " - " +
          endDate +
          "<br><br>" +
          "% Complete: " +
          progress +
          "</span>"

        var milestoneText = "<span>" + startDate + "</span>"

        // identify the task type and display the corresponding text
        if (duration == 0) {
          return milestoneText
        } else {
          return parentText
        }
      })

    chart
      .getTimeline()
      .tooltip()
      .format(function () {
        var duration = (this.actualEnd - this.actualStart) / 1000 / 3600 / 24
        var startDate = anychart.format.dateTime(
          this.actualStart,
          "dd MMM yyyy"
        )
        var endDate = anychart.format.dateTime(this.actualEnd, "dd MMM yyyy")
        var progress = Math.round(this.progress * 100 * 100) / 100 + "%"

        var parentText =
          "<span>" +
          startDate +
          " - " +
          endDate +
          "<br><br>" +
          "% Complete: " +
          progress +
          "</span>"

        var milestoneText = "<span>" + startDate + "</span>"

        // identify the task type and display the corresponding text
        if (duration == 0) {
          return milestoneText
        } else {
          return parentText
        }
      })

    chart.rowStroke("0.2 #64b5f6")
    chart.columnStroke("0.2 #64b5f6")

    chart.dataGrid().column(1).depthPaddingMultiplier(20)
    //mlevel.enabled(false);
    // set zoom levels of the scale
    chart
      .getTimeline()
      .scale()
      .zoomLevels([
        [
          { unit: "month", count: 1 },
          { unit: "quarter", count: 1 },
          { unit: "year", count: 1 },
        ],
      ])
    // header.level(0).format("{%tickValue}{dateTimeFormat:MMM}");

    if (scenario.fyMonth > 6) {
      var header = chart.getTimeline().header()
      header.level(2).format(function () {
        return +this.value + 1
      })
    }

    chart.rowStroke("0.2 #64b5f6")
    chart.columnStroke("0.2 #64b5f6")
    const thisdate = new Date()
    var year = thisdate.getFullYear()
    var month = thisdate.getMonth()
    var day = thisdate.getDate()

    chart.defaultRowHeight(25)
    chart.xScale().fiscalYearStartMonth(scenario.fyMonth)
    chart.container(divId)
    // initiate drawing the chart
    chart.draw()
    chart.credits().enabled(false)
    chart.dataGrid(false)
    chart.fitAll()
    // chart.zoomTo(Date.UTC(year, 1, 1), Date.UTC(year + 2, 11, 30));
  }

  return (
    <Modal
      isOpen={isOpen}
      size="xl"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          T-Shirt Size Estimator
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {alternativeId != undefined && (
          <Row className="mb-3">
            <h5>{alternativeName}</h5>
            <Col>
              <div
                id="gantt"
                style={{
                  width: "100%",
                  height: "200px",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              ></div>
            </Col>
          </Row>
        )}
        <Alert
          id="heltTshirt"
          variant="light"
          className="always-enabled"
          style={{ marginBottom: "10px", paddingBottom: "0px" }}
        >
          <div>
            <b>Instructions for Using the Calculator</b>
            <ol>
              <li>Make the t-shirt selection.</li>
              <li>Revise the resulting estimate (optional).</li>
              <li>
                If there is more than one pool listed, allocate how the estimate
                should be split.
              </li>
              <li>
                Allocate how the estimate should be split across fiscal years,
                consistent with the project timeline.
              </li>
              <li>Click the “Apply” button.</li>
            </ol>
          </div>
        </Alert>
        <div className="mb-3">
          <Label className="form-label" style={{ marginBottom: "10px" }}>
            Select T-Shirt Size
          </Label>

          {tShirts.map((s, idx) => (
            <div
              key={idx}
              style={{
                padding: "5px",
                marginBottom: "5px",
                border:
                  selectedTShirtId == s.tShirtID ? "1px solid #1a3783" : "none",
                borderRadius: "10px",
              }}
            >
              <Input
                type="radio"
                className="form-check-input"
                id={s.tShirtID + idx + 1}
                checked={selectedTShirtId == s.tShirtID}
                onClick={e => {
                  setSelectedTShirtId(s.tShirtID)
                  // setShowTotalHours(false)
                  let tShirt = tShirts.find(x => x.tShirtID == s.tShirtID)
                  setTotalHours(tShirt.tShirtHours)
                }}
              />

              <Label
                className="form-check-label"
                for={s.tShirtID + idx + 1}
                style={{ marginLeft: "5px" }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: s.tShirtPill,
                  }}
                ></div>
              </Label>
              <p style={{ marginLeft: "20px", marginBottom: 0 }}>
                {s.description}
              </p>
            </div>
          ))}
        </div>
        {selectedTShirtId != -1 && (
          <>
            <Row>
              <Col md="5">
                <div className="form-group">
                  <Label>{resourceUnit}</Label>
                  <Input
                    type="number"
                    value={totalHours}
                    onChange={e => setTotalHours(e.target.value)}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Label className="form-label" style={{ marginTop: "10px" }}>
                Distribute across cost pools and years
              </Label>
            </Row>
            <Row>
              <Col md="5">
                <table
                  className="table table-bordered low-padding-table"
                  style={{ backgroundColor: "white" }}
                >
                  <thead>
                    <tr>
                      <th>Resource</th>
                      <th>Allocation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resourceGroupCostPools.map((c, idx) => (
                      <>
                        <tr key={idx}>
                          <td>{c.resourceGroupName}</td>
                          <td>
                            <CurrencyInput
                              className="form-control"
                              value={c.hoursAllocationPercent}
                              onValueChange={(value, name, values) => {
                                changeCostPoolProp(
                                  c.costPoolID,
                                  "hoursAllocationPercent",
                                  value
                                )
                              }}
                              placeholder=""
                              prefix=""
                              suffix="%"
                              decimalsLimit={2}
                            />
                          </td>
                        </tr>
                      </>
                    ))}
                    <tr>
                      <td></td>
                      <td
                        style={{
                          fontWeight: "600",
                          color:
                            costPoolsTotalHoursAllocationPercent == "100.00"
                              ? "green"
                              : "red",
                        }}
                      >
                        {costPoolsTotalHoursAllocationPercent}%
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>

              <Col md="12">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  {years.map((y, idx) => (
                    <div key={idx} className="form-group">
                      <Label>{y.year}</Label>
                      <CurrencyInput
                        className="form-control"
                        value={y.hoursAllocationPercent}
                        onValueChange={(value, name, values) => {
                          changeYearProp(
                            y.year,
                            "hoursAllocationPercent",
                            value
                          )
                        }}
                        placeholder=""
                        prefix=""
                        suffix="%"
                        decimalsLimit={2}
                      />
                    </div>
                  ))}
                  <div className="form-group">
                    <Label>Total</Label>
                    <Label
                      style={{
                        color:
                          yearsTotalHoursAllocationPercent == "100.00"
                            ? "green"
                            : "red",
                      }}
                    >
                      {yearsTotalHoursAllocationPercent}%
                    </Label>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
          disabled={!isValid}
        >
          Apply
        </button>
      </div>
    </Modal>
  )
}

export default TShirtHoursAllocationModal
