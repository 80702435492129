import React from "react";
import { Col } from "reactstrap";

export const DynamicFormDivider = React.memo(function dynamicFormDivider({
  col, fullWidth,
}) {
  return (
    <Col md={fullWidth ? "12" : col}>
      <div className="divider" />
    </Col>
  );
});
