import React, { useEffect, useRef, useState } from "react"
import { createRoot } from "react-dom/client"
import Loader from "react-loaders"
import { ReactTabulator } from "react-tabulator"
import colors from "utils/colors"
import * as ReactDOMClient from "react-dom/client"
import api from "services/api.service"
import { userService } from "services/user.service"
import {
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap"
import { toast } from "react-toastify"
import {
  swalWithConfirmAndCancelButtons,
  swalWithConfirmButton,
} from "components/custom/swal"
import NameAvatar from "components/custom/nameAvatar"
import { getSelectHtmlFilterValues } from "utils/tabulatorFilter"
import { customFormatter } from "utils/tabulator"

const ModelGroupsGrid = ({
  modelGroups,
  props,
  reloadData,
  scenarioTypes,
  editModelGroup,
}) => {
  var tabulatorRef = useRef(null)
  const currentUser = userService.getLoggedInUser()
  let [showFilters, setShowFilters] = useState(false)

  const handleModelPress = (e, model) => {
    e && e.preventDefault()
    editModelGroup(model)
  }

  const handleAltPress = (e, model) => {
    e && e.preventDefault()
    props.history.push(`/projects/-${model.scenarioGroupID}`)
  }

  const handleParticipantsPress = (e, model) => {
    e && e.preventDefault()
    props.history.push(`/participants/${model.scenarioID}`)
  }

  const toggleFilters = () => {
    tabulatorRef.current.element.classList.remove("show-filters")
    tabulatorRef.current.element.classList.remove("hide-filters")
    tabulatorRef.current.element.classList.add(
      !showFilters ? "show-filters" : "hide-filters"
    )
    setShowFilters(!showFilters)
  }

  const columns = [
    // {
    //   formatter: "rowSelection",
    //   titleFormatter: "rowSelection",
    //   hozAlign: "center",
    //   headerSort: false,
    //   cellClick: function (e, cell) {
    //     cell.getRow().toggleSelect()
    //   },
    //   width: 50,
    // },
    {
      title: "Decision Type",
      field: "scenarioGroupID",
      width: 150,
      formatter: (cell, formatterParams, onRendered) => {
        return `<div style="margin: 0; font-size: 12px; text-align: center; background-color: #123456; display: inline-block; padding: 4px 10px; border-radius: 15px; color:  #FFFFFF;">Portfolio Group</div>`
      },
    },
    {
      title: "Decision Manager",
      field: "userName",
      //headerFilter: "input",
      headerFilter: "select",
      headerFilterFunc: "in",
      headerFilterParams: {
        values: [...new Set(modelGroups.map(u => u.userName))],
        // values: getSelectHtmlFilterValues(
        //   new Set(modelGroups.map(m => m.userName))
        // ),
        sortValuesList: "asc",
        multiselect: true,
      },
      width: 200,
      widthGrow: 2,
      formatter: customFormatter(({ rowData, cellValue }) => {
        const base64Image = rowData.photoBase64
        return (
          <NameAvatar
            fullName={cellValue}
            showName={true}
            base64Image={base64Image}
          />
        )
      }),
    },
    {
      title: "Decision",
      field: "scenarioGroupName",
      headerFilter: "input",
      minWidth: 200,
      widthGrow: 3,
      formatter: customFormatter(({ rowData, cellValue }) => (
        <a href="#" onClick={e => handleModelPress(e, rowData)}>
          {cellValue}
        </a>
      )),
    },

    {
      title: `View`,
      field: "scenarioID",
      widthGrow: 3,
      minWidth: 150,
      headerSort: false,
      formatter: customFormatter(({ rowData, cellValue }) => (
        <a href="#" onClick={e => handleAltPress(e, rowData)}>
          Projects
        </a>
      )),
    },
    {
      title: "Active",
      field: "inactive",
      hozAlign: "center",
      minWidth: 100,
      headerHozAlign: "center",
      formatter: function (cell, formatterParams, onRendered) {
        return cell.getValue() == 0
          ? "<span style='color:green'>Yes</span>"
          : "<span style='color:red'><b>No</b></span>"
      },
      // headerFilter: "select",
      // headerFilterParams: {
      //   values: {
      //     false: "Yes",
      //     true: "No",
      //   },
      // },
      widthGrow: 1,
    },
    {
      title: "Last modified",
      field: "updateDT",
      widthGrow: 2,
      minWidth: 100,
      formatter: function (cell, fp, or) {
        let date = new Date(cell.getValue())
        return (
          date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
        )
      },
    },
  ]

  // const deleteSelected = () => {
  //   swalWithConfirmAndCancelButtons
  //     .fire({
  //       title: `Are you sure you want to delete the selected projects?`,
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonText: "Yes",
  //       cancelButtonText: "Cancel",
  //       reverseButtons: true,
  //     })
  //     .then(async result => {
  //       if (result.isConfirmed) {
  //         let selected = tabulatorRef.current.getSelectedData()
  //         if (selected.length > 0) {
  //           let copy = [...selected]
  //           let tasks = copy.map(x => async () => {
  //             return await api.deleteScenario(currentUser.userID, x.scenarioID)
  //           })
  //           await Promise.all(tasks.map(t => t()))
  //           reloadData()
  //           toast.success("Projects deleted successfuly")
  //         }
  //       }
  //     })
  // }

  // const updateSelected = async (prop, value) => {
  //   let selected = tabulatorRef.current.getSelectedData()
  //   if (selected.length > 0) {
  //     let copy = selected.map(x => {
  //       x[prop] = value
  //       return x
  //     })
  //     let tasks = copy.map(x => async () => {
  //       return await api.updateScenario(currentUser.userID, x)
  //     })
  //     await Promise.all(tasks.map(t => t()))
  //     reloadData()
  //     toast.success("Projects saved successfuly")
  //   }
  // }

  return (
    <Card>
      <CardBody>
        {modelGroups.length > 0 && (
          <>
            <Row>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <span></span>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    onClick={toggleFilters}
                    className="btn btn-sm btn-outline-primary"
                  >
                    {showFilters ? "Hide" : "Show"}{" "}
                    <i className="fas fa-search"></i>{" "}
                    <i className="fas fa-filter"></i>
                  </button>
                </div>
              </Col>
            </Row>
            <div className={showFilters ? "show-filters" : "hide-filters"}>
              <ReactTabulator
                onRef={r => (tabulatorRef.current = r.current)}
                columns={columns}
                data={modelGroups}
                options={{
                  pagination: "local",
                  paginationSize: 30,
                  paginationSizeSelector: [5, 10, 20, 30, 50, 100, 200, 300],
                  layout: "fitColumns",
                  cssClass: "table-borderless",
                }}
              />
            </div>
          </>
        )}
      </CardBody>
    </Card>
  )
}

export default ModelGroupsGrid
