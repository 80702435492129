import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import anychart from "anychart"

const HealthTimelineChartsModal = ({ isOpen, toggleModal, alternatives, scenario }) => {
  useEffect(() => {
    anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
  }, [])

  useEffect(() => {
    if (isOpen) {
      loadCharts()
    }
  }, [isOpen])

  const loadCharts = async () => {
    let altIds = alternatives.map(x => x.alternativeid).join(",")
    api.getAlternativeListChart(altIds, "BCD_Gantt").then(resp => {
      renderGanttChart(resp, "gantt")
    })
    api.getAlternativeListChart(altIds, "BCD_RiskScope​").then(resp => {
      renderPieChart(resp, "scope")
    })
    api.getAlternativeListChart(altIds, "BCD_RiskSchedule").then(resp => {
      renderPieChart(resp, "schedule")
    })
    api.getAlternativeListChart(altIds, "BCD_RiskCost").then(resp => {
      renderPieChart(resp, "cost")
    })
    api.getAlternativeListChart(altIds, "BCD_RiskQuality").then(resp => {
      renderPieChart(resp, "quality")
    })
    api.getAlternativeListChart(altIds, "BCD_RiskHealth").then(resp => {
      renderPieChart(resp, "health")
    })
    api.getAlternativeListChart(altIds, "BCD_Pending").then(resp => {
      renderHtml(resp, "pending")
    })
    api.getAlternativeListChart(altIds, "BCD_OnHold").then(resp => {
      renderHtml(resp, "onhold")
    })
    api.getAlternativeListChart(altIds, "BCD_InProgress").then(resp => {
      renderHtml(resp, "progress")
    })
    api.getAlternativeListChart(altIds, "BCD_Complete").then(resp => {
      renderHtml(resp, "complete")
    })
  }

  const renderHtml = (response, divId) => {
    document.getElementById(divId).innerHTML = response.chartArray
  }

  const renderPieChart = (response, divId) => {
    if (
      document.getElementById(divId) !== undefined &&
      document.getElementById(divId) !== null
    ) {
      document.getElementById(divId).innerHTML = ""
    }
    let obj = eval("(" + response.chartArray.replaceAll("'", '"') + ")")
    let json = JSON.stringify(obj)
    let chartData = anychart.data.set(JSON.parse(json))
    chartData.mapAs({ x: 0, value: 1, ToolTip: 2 })
    var chart = anychart.pie(chartData)
    chart.tooltip().useHtml(true)
    var tooltip = chart.tooltip()
    tooltip.titleFormat("{%x}")
    tooltip.format("{%ToolTip}")
    chart.background().stroke(".5 gray")
    chart.animation(true)

    var charttitle = ""
    if (divId == "scope") {charttitle = "Scope"}
    if (divId == "schedule") {charttitle = "Schedule"}
    if (divId == "cost") {charttitle = "Cost"}
    if (divId == "quality") {charttitle = "Quality"}
    if (divId == "health") {charttitle = "Health"}

    chart.title(charttitle).radius("43%").innerRadius("30%")
    chart.container(divId)
    chart.draw()
    chart.credits().enabled(false)
    chart.draw()
  }

  const renderGanttChart = (response, divId) => {
    if (
      document.getElementById(divId) !== undefined &&
      document.getElementById(divId) !== null
    ) {
      document.getElementById(divId).innerHTML = ""
    }
    let obj = eval("(" + response.chartArray.replaceAll("'", '"') + ")")
    let json = JSON.stringify(obj)
    var data = JSON.parse(json)
    var treeData = anychart.data.tree(data, "as-tree")
    var chart = anychart.ganttProject()
    chart.data(treeData)
    var ganttDiv = document.getElementById(divId)
    var items = data.length
    var gh = 100 + 20 * items
    ganttDiv.style.height = gh + "px"
    var newColumn = chart.dataGrid().column(0)
    newColumn.width(60)
    newColumn.title("ID")
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontWeight(600)
    newColumn.labels().useHtml(true)
    newColumn.labels().fontColor("#000000")
    newColumn.labels().format("<div style='text-align: center'>{%id}</div>")
    newColumn.depthPaddingMultiplier(20)
    newColumn.collapseExpandButtons(true)
    var newColumn = chart.dataGrid().column(1)
    newColumn.width(500)
    newColumn.title("Name")
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontWeight(600)
    newColumn.labels().fontColor("#000000")
    newColumn.labels().format("{%name}")
    newColumn.depthPaddingMultiplier(20)
    newColumn.collapseExpandButtons(true)
    var newColumn = chart.dataGrid().column(2)
    newColumn.width(90)
    newColumn.title("Start")
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontWeight(600)
    newColumn.labels().useHtml(true)
    newColumn.labels().fontColor("#000000")
    newColumn
      .labels()
      .format(
        "<div style='text-align: center'>{%actualStart}{dateTimeFormat:MM/dd/yyyy}</div>"
      )
    newColumn.depthPaddingMultiplier(20)
    newColumn.collapseExpandButtons(true)
    var newColumn2 = chart.dataGrid().column(3)
    newColumn2.width(90)
    newColumn2.title("Finish")
    newColumn2.title().fontColor("#64b5f6")
    newColumn2.title().fontWeight(600)
    newColumn2.labels().useHtml(true)
    newColumn2.labels().fontColor("#000000")
    newColumn2
      .labels()
      .format(
        "<div style='text-align: center'>{%actualEnd}{dateTimeFormat:MM/dd/yyyy}</div>"
      )
    newColumn2.depthPaddingMultiplier(20)
    newColumn2.collapseExpandButtons(true)
    var dataGrid = chart.dataGrid()
    var dgTooltip = dataGrid.tooltip()
    dgTooltip.useHtml(true)
    dgTooltip.format(
      "<span>" +
        "{%actualStart}{dateTimeFormat:MM/dd/yyyy} – " +
        "{%actualEnd}{dateTimeFormat:MM/dd/yyyy}</span><br><br>" +
        "% Complete: {%progress}<br><br>"
          + "{%projecthealthcomments}"
    )
    chart.getTimeline().tooltip().useHtml(true)
    chart
      .getTimeline()
      .tooltip()
      .format(
        "<span>" +
          "{%actualStart}{dateTimeFormat:MM/dd/yyyy} – " +
          "{%actualEnd}{dateTimeFormat:MM/dd/yyyy}</span><br><br>" +
          "% Complete: {%progress}<br><br>" 
            + "{%projecthealthcomments}"
      )
    var header = chart.getTimeline().header()
    var mlevel = header.level(2)
    //mlevel.enabled(false);
    // set zoom levels of the scale
    chart
      .getTimeline()
      .scale()
      .zoomLevels([
        [
          { unit: "month", count: 1 },
          { unit: "quarter", count: 1 },
          { unit: "year", count: 1 },
        ],
      ])
    // header.level(0).format("{%tickValue}{dateTimeFormat:MMM}");
    //chart.xScale().fiscalYearStartMonth("1") //(<%=HiddenFYMonth.Value%>);

    if (scenario.fyMonth > 6) {
      var header = chart.getTimeline().header();
      header.level(2).format(function(){return +this.value + 1});
    }

    chart.xScale().fiscalYearStartMonth(scenario.fyMonth)
    chart.container(divId)
    // initiate drawing the chart
    chart.draw()
    chart.credits().enabled(false)
    chart.fitAll()
  }

  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      fullscreen={true}
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Health and Timeline
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row className="mb-3">
          <Col>
            <h5 className="text-center">Timeline</h5>
            <div id="gantt" style={{ width: "100%", height: "500px" }}></div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="3">
            <h5 className="text-center"><b>Pending Start</b></h5>
            <div id="pending" className="text-center"></div>
          </Col>
          <Col md="3">
            <h5 className="text-center"><b>On-Hold</b></h5>
            <div id="onhold" className="text-center"></div>
          </Col>
          <Col md="3">
            <h5 className="text-center"><b>In Progress</b></h5>
            <div id="progress" className="text-center"></div>
          </Col>
          <Col md="3">
            <h5 className="text-center"><b>Complete</b></h5>
            <div id="complete" className="text-center"></div>
          </Col>
        </Row>
        <Row className="mb-3">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div id="scope" style={{ width: "20%", height: "300px" }}></div>
            <div id="schedule" style={{ width: "20%", height: "300px" }}></div>
            <div id="cost" style={{ width: "20%", height: "300px" }}></div>
            <div id="quality" style={{ width: "20%", height: "300px" }}></div>
            <div id="health" style={{ width: "20%", height: "300px" }}></div>
          </div>
        </Row>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
      </div>
    </Modal>
  )
}

export default HealthTimelineChartsModal
