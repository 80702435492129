import AltGroupRatingModal from "pages/AltScoreResults/AltGroupRatingModal"
import SelectableAlternativeRatingsGrid from "pages/_AlternativeShared/SelectableAlternativeRatingsGrid"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { Col, Container, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { loadPageItem } from "store/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AltGroupConsensusRatingModal from "./AltGroupConsensusRatingModal"
import { mapObjPropsToLowercase } from "helpers/objPropsToLowercaseMapper"

const AltScoreConsensus = props => {
  const scenarioId = props.match.params.scenarioId
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  let [objectivesDropdownList, setObjectivesDropdownList] = useState([])
  let [objectives, setObjectives] = useState([])
  let [objectivesAndMeasures, setObjectivesAndMeasures] = useState([])
  let [objectiveDropdownSelection, setObjectiveDropdownSelection] =
    useState(null)
  let [isLoading, setIsLoading] = useState(false)
  let [gridData, setGridData] = useState([])
  let [scenario, setScenario] = useState(null)

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "altScoreConsensus",
      })
    )
    loadData()
  }, [])

  useEffect(() => {
    if (objectiveDropdownSelection != null) {
      loadGrid()
    }
  }, [objectiveDropdownSelection])

  const loadData = async () => {
    try {
      setIsLoading(true)
      let scen = await api.getScenario(scenarioId)
      setScenario(scen)
      let objs = await api.getObjectives(scenarioId)
      setObjectives(objs)

      let objsAndMeasures = []
      objs.forEach(o => {
        objsAndMeasures.push(o)
        if (o.measures && o.measures.length > 0) {
          o.measures.forEach(m => {
            objsAndMeasures.push(m)
          })
        }
      })
      setObjectivesAndMeasures(objsAndMeasures)

      setObjectiveDropdownSelection({
        value: 0,
        text: scen.scenarioName,
      })
      setObjectivesDropdownList([
        {
          value: 0,
          text: scen.scenarioName,
        },
        {
          value: -99,
          text: scen.scenarioName + " with Measures",
        },
        ...objs
          .filter(x => x.measures && x.measures.length > 0)
          .map(x => {
            return {
              value: x.objectiveID,
              text: x.objectiveName,
            }
          }),
      ])
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  const loadGrid = async () => {
    try {
      setIsLoading(true)
      let data = await api.getAltScoreConsensus(
        objectiveDropdownSelection.value,
        scenarioId
      )
      setGridData(
        mapObjPropsToLowercase(
          data.map(d => {
            d.isSelected = false
            d.isVisible = true
            return d
          })
        )
      )
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  const getColorAndBackgroundColor = deviation => {
    let color, backgroundColor
    if (deviation < 1) {
      color = "white"
      backgroundColor = "darkblue"
    } else if (deviation < 2) {
      color = "black"
      backgroundColor = "lightblue"
    } else if (deviation < 3) {
      color = "white"
      backgroundColor = "green"
    } else if (deviation < 4) {
      color = "black"
      backgroundColor = "lightgreen"
    } else if (deviation < 5) {
      color = "black"
      backgroundColor = "yellow"
    } else if (deviation < 6) {
      color = "white"
      backgroundColor = "orange"
    } else {
      color = "white"
      backgroundColor = "red"
    }

    return { color, backgroundColor }
  }

  const getRatingTd = (deviation, optionalText, width) => {
    let colorAndBackground = getColorAndBackgroundColor(deviation)
    return (
      <td
        style={{
          textAlign: "center",
        }}
      >
        <div
          style={{
            fontSize: "13px",
            backgroundColor: colorAndBackground.backgroundColor,
            padding: "4px 20px",
            width: width ? width : "100px",
            margin: "0 auto",
            borderRadius: "15px",
            color: colorAndBackground.color,
          }}
        >
          {optionalText != undefined ? optionalText : deviation}
        </div>
      </td>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />

          {objectiveDropdownSelection != null &&
            objectivesDropdownList.length > 2 && (
              <Row style={{ marginTop: "5px" }}>
                <div style={{ width: "40%" }}>
                  <Label style={{ marginBottom: "0px" }}>View</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={objectiveDropdownSelection.value}
                    onChange={e => {
                      setObjectiveDropdownSelection(
                        objectivesDropdownList.find(
                          x => x.value == e.target.value
                        )
                      )
                    }}
                  >
                    {objectivesDropdownList.map((r, idx) => {
                      return (
                        <option key={idx} value={`${r.value}`}>
                          {r.text}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </Row>
            )}

          <Row style={{ marginTop: "10px" }}>
            <Col>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              {!isLoading &&
                gridData != undefined &&
                gridData != null &&
                gridData.length > 0 && (
                  <SelectableAlternativeRatingsGrid
                    data={gridData}
                    setData={setGridData}
                    dropdownSelection={objectiveDropdownSelection}
                    objectives={objectives}
                    objectivesAndMeasures={objectivesAndMeasures}
                    ratingModal={AltGroupConsensusRatingModal}
                  />
                )}
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <div style={{ width: "30%" }}>
              <table
                className="table table-bordered low-padding-table"
                style={{ backgroundColor: "white" }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "50%" }}>Standard Deviation</th>
                    <th style={{ width: "50%" }}>Consensus Rating</th>
                  </tr>
                </thead>
                <tbody>
                  {[
                    { val: 0.5, comparison: "< 1", text: "High" },
                    { val: 1.5, comparison: "< 2", text: "Medium-High" },
                    { val: 2.5, comparison: "< 3", text: "Medium" },
                    { val: 3.5, comparison: "< 4", text: "Medium-Low" },
                    { val: 4.5, comparison: "< 5", text: "Low" },
                    { val: 5.5, comparison: "< 6", text: "Very Low" },
                    { val: 6.5, comparison: ">= 6", text: "Negligible" },
                  ].map((a, idx) => (
                    <tr key={idx}>
                      <td style={{ textAlign: "center" }}>{a.comparison}</td>
                      {getRatingTd(a.val, a.text, "140px")}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AltScoreConsensus
