import React, { useEffect, useState } from "react"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import DynamicForm from "./DynamicForm"

const DynamicFormModal = ({
  isOpen,
  toggleModal,
  itemId,
  formId,
  scenarioId,
}) => {
  useEffect(() => {
    if (isOpen) {
    }
  }, [isOpen])

  const save = async () => {}

  return (
    <Modal
      isOpen={isOpen}
      size="xl"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Edit Form Values
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <DynamicForm
          formId={formId}
          itemId={itemId}
          scenarioId={scenarioId}
          setTriggerFormSave={() => {}}
        />
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
        {/* <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button> */}
      </div>
    </Modal>
  )
}

export default DynamicFormModal
