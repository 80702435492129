import {
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
} from "@material-ui/core"
import React, { useCallback, useEffect, useRef, useState } from "react"
import Loader from "react-loaders"
import { Button, Col, Container, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import anychart from "anychart"
import { useDispatch, useSelector } from "react-redux"
import { changeNavbarParams, loadPageItem } from "store/actions"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import renderUserChart from "../UserCharts/userChartRenderer"
import Widgets from "components/Widgets"
import { currencyFormatter } from "utils/formatters"
import RoadmapChart, { RoadmapChart2 } from "pages/Roadmap/RoadmapChart"
import { sortData } from "utils/sort"
import {
  DashboardLayoutComponent,
  PanelModel,
  PanelsDirective,
  PanelDirective,
  ResizeArgs,
} from "@syncfusion/ej2-react-layouts"
import { toast } from "react-toastify"
import EditDashboardModal from "../ManageDashboards/EditDashboardModal"
import useModal from "hooks/useModalHook"
import DashboardInfoSectionsModal from "./DashboardInfoSectionsModal"
import generatePDF, { Margin } from "react-to-pdf"
import SmallLoadingIndicator from "components/custom/SmallLoadingIndicator"
import ShareDashboardModal from "../ManageDashboards/ShareDashboardModal"
import ChartDrilldownModal from "../UserCharts/ChartDrilldownModal"
import EditUserChartModal from "../UserCharts/EditUserChartModal"
import isNullOrEmpty from "utils/isNullOrEmpty"

const Dashboard = props => {
  const currentUser = userService.getLoggedInUser()
  const dashboardId = props.dashboardId
    ? props.dashboardId
    : props.match.params.dashboardId
  const scenarioId = props.scenarioId
    ? props.scenarioId
    : props.match.params.scenarioId
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const hideManageButton = props.hideManageButton == true
  const [isLoading, setIsLoading] = useState(false)
  const [dashboard, setDashboard] = useState(null)
  const [widgets, setWidgets] = useState([])
  const [scenario, setScenario] = useState(null)

  const [isEditMode, setIsEditMode] = useState(false)
  const dashboardRef = useRef(null)
  const dashboardContainerRef = useRef()
  const [isExporting, setIsExporting] = useState(false)

  const { show: showEditDashboardModal, toggle: toggleEditDashboardModal } =
    useModal()

  const { show: showInfoSectionsModal, toggle: toggleInfoSectionsModal } =
    useModal()
  const { show: showShareModal, toggle: toggleShareModal } = useModal()

  const [allChartTableRoadmapResponses, setAllChartTableRoadmapResponses] =
    useState([])

  const [drilldownDataTable, setDrilldownDataTable] = useState([])
  const { show: showDrilldownModal, toggle: toggleDrilldownModal } = useModal()

  const [chartToEdit, setChartToEdit] = useState(null)
  const { show: showEditChartModal, toggle: toggleEditChartModal } = useModal()
  const { show: showAddChartModal, toggle: toggleAddChartModal } = useModal()

  useEffect(() => {
    // if (props.dashboardId == undefined) {
    anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId:
          scenarioId != undefined && scenarioId != null ? scenarioId : 0,
        viewName: "dashboard",
      })
    )
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId:
          scenarioId != undefined && scenarioId != null ? scenarioId : 0,
        alternativeId: 0,
        viewName: "dashboard",
      })
    )
    // }
    setDashboard(null)
    loadData()
  }, [props.match.params.dashboardId])

  const loadData = async () => {
    setIsLoading(true)
    let d = null
    if (scenarioId == undefined || scenarioId == null) {
      d = await api.getDashboard(dashboardId)
    } else {
      let s = await api.getScenario(scenarioId)
      setScenario(s)
      d = await api.getDashboardScenario(dashboardId, scenarioId)
      let widgetTasks = d.dashboardItems
        .filter(x => x.itemType == "widget")
        .map(x => async () => {
          return await api.getWidget(x.itemID, d.scopeID)
        })
      if (widgetTasks.length > 0) {
        let w = await Promise.all(widgetTasks.map(t => t()))
        setWidgets(w)
      }
    }
    setDashboard(d)
    if (d.dashboardItems.length == 0) {
      setIsLoading(false)
    }

    await loadDashboardItems(d, d.dashboardItems)

    setIsLoading(false)
  }

  useEffect(() => {
    if (!isLoading && allChartTableRoadmapResponses.length > 0) {
      setTimeout(() => {
        handleChartsRender()
      }, 500)
    }
  }, [allChartTableRoadmapResponses, isLoading])

  const handleChartsRender = () => {
    allChartTableRoadmapResponses
      .filter(c => c.chartType !== "Table" && c.roadmapChartId == undefined)
      .forEach(c => {
        renderUserChart(c, `chartdiv${c.id}`, e =>
          onChartPointClick(e, c.userChartID || c.roadmapChartId)
        )
      })
  }

  const onChartPointClick = async (e, chartId) => {
    if (
      e.point != undefined &&
      e.point.get("x") != undefined &&
      chartId != undefined
    ) {
      let dataTable = await api.getUserChartDrilldown(chartId, e.point.get("x"))
      setDrilldownDataTable(dataTable)
      if (dataTable != undefined && dataTable != null && dataTable.length > 0) {
        toggleDrilldownModal()
      }
    }
  }

  const loadDashboardItems = async (dashboard, dashboardItems) => {
    let tasks = dashboardItems
      .filter(
        x =>
          x.itemType !== "Roadmap" &&
          x.itemType !== "RoadmapSummary" &&
          x.itemType !== "InfoSection" &&
          x.itemType !== "widget"
      )
      .map(c => async () => {
        if (c.itemType == "UserChart") {
          return await api.getUserChart(c.itemID)
        } else {
          return await api.getGeneralChart(c.itemID, dashboard.scopeID)
        }
      })
    let chartResponses = await Promise.all(tasks.map(t => t()))
    let allResponses = []
    dashboardItems.forEach(item => {
      if (item.itemType == "Roadmap" || item.itemType == "RoadmapSummary") {
        allResponses.push({
          roadmapChartId: item.itemID,
          orderID: item.orderID,
          id: `dashboardItemID-${item.dashboardItemID}`,
          sizeX: item.sizeX,
          sizeY: item.sizeY,
          rowID: item.rowID,
          columnID: item.columnID,
          itemType: item.itemType,
        })
      } else if (item.itemType == "InfoSection") {
        allResponses.push({
          ...item,
          id: `dashboardItemID-${item.dashboardItemID}`,
          sizeX: item.sizeX,
          sizeY: item.sizeY,
          rowID: item.rowID,
          columnID: item.columnID,
        })
      } else {
        let t = chartResponses.find(
          x => x.userChartID == item.itemID || x.chartID == item.itemID
        )
        if (t != undefined) {
          allResponses.push({
            ...t,
            orderID: item.orderID,
            id: `dashboardItemID-${item.dashboardItemID}`,
            sizeX: item.sizeX,
            sizeY: item.sizeY,
            rowID: item.rowID,
            columnID: item.columnID,
          })
        }
      }
    })
    console.log(allResponses)
    setAllChartTableRoadmapResponses(allResponses)
  }

  const colMd =
    dashboard == null
      ? ""
      : dashboard.layout == "Widget Row - 3 Column"
      ? "4"
      : dashboard.layout == "1 Column"
      ? "12"
      : dashboard.layout == "2 Column"
      ? "6"
      : "4"

  const saveDashboard = async () => {
    const syncfusionDashboardItems = dashboardRef.current.serialize()
    let dashboardCopy = { ...dashboard }
    dashboardCopy.dashboardItems.forEach(item => {
      const syncfusionDashboardItem = syncfusionDashboardItems.find(
        x => x.id.split("-")[1] == item.dashboardItemID
      )
      if (syncfusionDashboardItem !== undefined) {
        item.sizeX = syncfusionDashboardItem.sizeX
        item.sizeY = syncfusionDashboardItem.sizeY
        item.rowID = syncfusionDashboardItem.row
        item.columnID = syncfusionDashboardItem.col
      }
    })

    await api.updateDashboard(currentUser.userID, dashboardCopy)
    await loadData()
    toast.success("Dashboard saved successfully")
  }

  const exportPageToPdf = () => {
    setIsExporting(true)
    setTimeout(() => {
      generatePDF(dashboardContainerRef, {
        filename: `${
          scenario != null
            ? dashboard.dashboardName.replace(
                "[ScenarioName]",
                scenario.scenarioName
              )
            : dashboard.dashboardName
        }.pdf`,
        page: {
          margin: Margin.MEDIUM,
          format: "A4",
          orientation: "landscape",
        },
      })
      setIsExporting(false)
    }, 100)
  }

  const editChartCallback = useCallback(async chartId => {
    let chart = await api.getUserChart(chartId)
    setChartToEdit(chart)
    toggleEditChartModal()
  }, [])

  const addChart = () => {
    setChartToEdit({
      userID: currentUser.userID,
      chartTitle: "",
      alternativeLogic: "BB*",
      // valueItem: "Cost",
      userChartID: -1,
      scenarioID: 0,
      publicChart: false,
      inactive: false,
      description: "",
      // chartType: "Pie",
      chartName: "",
      chartFilters: [],
      chartTable: [],
      generalChartScope: "Scenario",
      baseColor: colors.primary,
    })
    toggleAddChartModal()
  }

  const addChartSaveCallback = async chart => {
    let d = { ...dashboard }
    d.dashboardItems.push({
      itemID: chart.userChartID,
      dashboardItemName: chart.chartTitle,
      itemType: "UserChart",
    })
    await api.updateDashboard(currentUser.userID, d)
    await loadData()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          {dashboard != null && (
            <>
              {!isLoading && (
                <Row className="mb-3">
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    {(dashboard.userID == currentUser.userID ||
                      (dashboard.dashboardID == 22 &&
                        currentUser.roleID == 110)) && (
                      <>
                        {isEditMode && (
                          <Button
                            color="primary"
                            onClick={() => saveDashboard()}
                          >
                            <i className="mdi mdi-content-save-outline"></i>
                            &nbsp;Save
                          </Button>
                        )}

                        {isEditMode && (
                          <Button
                            color="primary"
                            className="save-user"
                            onClick={addChart}
                          >
                            <i className="fas fa-plus-circle"></i>
                            &nbsp;Add New Chart
                          </Button>
                        )}

                        <Button
                          color="primary"
                          className="save-user"
                          onClick={() => {
                            setIsEditMode(!isEditMode)
                            setTimeout(() => {
                              handleChartsRender()
                            }, 500)
                          }}
                        >
                          <i className="mdi mdi-view-dashboard-outline"></i>
                          &nbsp;
                          {isEditMode ? "Stop Editing" : "Edit"}
                        </Button>
                        {isEditMode && !hideManageButton && (
                          <Button
                            color="primary"
                            className="save-user"
                            onClick={toggleEditDashboardModal}
                          >
                            <i className="mdi mdi-chart-bar"></i>
                            &nbsp;Manage Dashboard
                          </Button>
                        )}
                        {isEditMode && (
                          <Button
                            color="primary"
                            className="save-user"
                            onClick={toggleInfoSectionsModal}
                          >
                            <i className="mdi mdi-format-letter-case"></i>
                            &nbsp;Manage Text Areas
                          </Button>
                        )}
                        {isEditMode && (
                          <Button
                            color="primary"
                            className="save-user"
                            onClick={toggleShareModal}
                          >
                            <i className="fas fa-share"></i>
                            &nbsp; Share Dashboard
                          </Button>
                        )}
                      </>
                    )}
                    <Button
                      color="primary"
                      className="save-user"
                      disabled={isExporting}
                      onClick={() => {
                        exportPageToPdf()
                      }}
                    >
                      <i className="mdi mdi-file-pdf-outline"></i>&nbsp; Export
                      to PDF {isExporting && <SmallLoadingIndicator />}
                    </Button>
                  </div>
                </Row>
              )}
              <div ref={dashboardContainerRef}>
                {!isEditMode && (
                  <Row className="mb-3">
                    <Col style={{ textAlign: "center" }}>
                      <h4 className="mb-sm-0 font-size-18">
                        {" "}
                        <b>
                          {scenario != null
                            ? dashboard.dashboardName.replace(
                                "[ScenarioName]",
                                scenario.scenarioName
                              )
                            : dashboard.dashboardName}
                        </b>
                      </h4>
                      <h5 className="mb-sm-0 font-size-14">
                        {dashboard.description}
                      </h5>
                    </Col>
                  </Row>
                )}
                {isEditMode && (
                  <Row className="mb-3" style={{ width: "40%" }}>
                    <div className="mb-3">
                      <Label className="form-label">Dashboard Name</Label>
                      <Input
                        type="text"
                        onChange={e =>
                          setDashboard({
                            ...dashboard,
                            dashboardName: e.target.value,
                          })
                        }
                        value={dashboard.dashboardName}
                      />
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Description</Label>
                      <TextareaAutosize
                        minRows={2}
                        className="form-control"
                        onChange={e =>
                          setDashboard({
                            ...dashboard,
                            description: e.target.value,
                          })
                        }
                        value={dashboard.description}
                      />
                    </div>
                  </Row>
                )}

                <Widgets widgets={widgets} />

                <DashboardMemo
                  isEditMode={isEditMode}
                  allChartTableRoadmapResponses={allChartTableRoadmapResponses}
                  dashboardRef={dashboardRef}
                  history={props.history}
                  editChart={editChartCallback}
                />
              </div>
            </>
          )}
        </Container>
      </div>
      <EditDashboardModal
        isOpen={showEditDashboardModal}
        toggleModal={toggleEditDashboardModal}
        dashboardToEdit={dashboard}
        setDashboardToEdit={setDashboard}
        reloadData={loadData}
        showOnlyEdit={true}
        props={props}
      />
      <DashboardInfoSectionsModal
        isOpen={showInfoSectionsModal}
        toggleModal={() => {
          toggleInfoSectionsModal()
          loadData()
        }}
        dashboardId={dashboardId}
      />
      {dashboard != null && (
        <ShareDashboardModal
          isOpen={showShareModal}
          toggleModal={toggleShareModal}
          dashboardToEdit={dashboard}
          setDashboardToEdit={setDashboard}
        />
      )}
      <ChartDrilldownModal
        isOpen={showDrilldownModal}
        toggleModal={toggleDrilldownModal}
        dataTable={drilldownDataTable}
      />
      <EditUserChartModal
        isOpen={showEditChartModal}
        toggleModal={toggleEditChartModal}
        chartToEdit={chartToEdit}
        setChartToEdit={setChartToEdit}
        reloadData={loadData}
      />
      <EditUserChartModal
        isOpen={showAddChartModal}
        toggleModal={toggleAddChartModal}
        chartToEdit={chartToEdit}
        setChartToEdit={setChartToEdit}
        reloadData={addChartSaveCallback}
      />
    </React.Fragment>
  )
}

const DashboardMemo = React.memo(function dashobardMemo({
  dashboardRef,
  isEditMode,
  allChartTableRoadmapResponses,
  history,
  editChart,
}) {
  function renderChartTable(tc, key) {
    if (tc !== undefined && tc.chartTable != null && tc.chartTable.length > 0) {
      return (
        <div
          key={key}
          className="template"
          style={{ width: "100%", height: "100%", padding: "5px" }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              overflow: "auto",
              boxShadow: "0 0 0 0.7px #7E7E7E",
            }}
          >
            <div
              style={{
                textAlign: "center",
                fontSize: "16px",
                color: "#7A8997",
                fontFamily: "Inter",
              }}
            >
              <b>{tc.chartTitle}</b>
            </div>
            <table
              className="table table-bordered low-padding-table"
              style={{ backgroundColor: "white" }}
            >
              <thead>
                <tr>
                  {/* {Object.keys(tc.chartTable[0]).map((col, idx2) => (
                    <th key={idx2}></th>
                  ))} */}
                  <tr>
                    <th style={{ width: "80%" }}></th>
                    <th style={{ width: "20%" }}></th>
                  </tr>
                </tr>
              </thead>
              <tbody>
                {tc.chartTable.map((ct, idx2) => (
                  <tr key={idx2}>
                    <td>{ct.itemName}</td>
                    <td>
                      {!isNullOrEmpty(ct.itemValue) && !isNaN(ct.itemValue) && tc.valueItem != "Count" ? (
                        currencyFormatter.format(ct.itemValue)
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: ct.itemValue,
                          }}
                        ></div>
                      )}
                    </td>
                    {/* {Object.keys(tc.chartTable[0]).map((col, idx3) => (
                      <td key={idx3}>
                        {tc.valueItem == "Count" ? (
                          ct[col]
                        ) : (!isNullOrEmpty(ct[col]) &&  !isNaN(ct[col])) ? (
                          currencyFormatter.format(ct[col])
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: ct[col],
                            }}
                          ></div>
                        )}
                      </td>
                    ))} */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )
    }
  }

  return (
    <Row>
      <div className="control-section">
        <DashboardLayoutComponent
          id="defaultLayout"
          ref={s => (dashboardRef.current = s)}
          cellSpacing={[5, 5]}
          allowResizing={isEditMode}
          allowDragging={isEditMode}
          columns={20}
        >
          <PanelsDirective>
            {allChartTableRoadmapResponses.map((r, idx) => {
              return (
                <PanelDirective
                  id={r.id}
                  key={idx}
                  sizeX={r.sizeX}
                  sizeY={r.sizeY}
                  row={r.rowID}
                  col={r.columnID}
                  content={() => {
                    return (
                      <>
                        {isEditMode && r.userChartID != undefined && (
                          <i
                            className="fas fa-edit"
                            style={{
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                              fontSize: "15px",
                              color: colors.primary,
                              zIndex: 99999999,
                              cursor: "pointer",
                            }}
                            onClick={e => {
                              e && e.preventDefault()
                              editChart(r.userChartID)
                            }}
                          ></i>
                        )}
                        {r.chartType == "Table" ? (
                          renderChartTable(r, idx)
                        ) : r.roadmapChartId !== undefined ? (
                          <div
                            key={idx}
                            className="template"
                            style={{ width: "100%", height: "100%" }}
                          >
                            <RoadmapChart2
                              roadmapId={r.roadmapChartId}
                              className="mb-3"
                              showSummary={r.itemType == "RoadmapSummary"}
                              history={history}
                            />
                          </div>
                        ) : r.itemType == "InfoSection" ? (
                          <InfoSection infoSectionId={r.itemID} />
                        ) : (
                          <div
                            className="template"
                            style={{
                              height: "100%",
                              width: "100%",
                              padding: "5px",
                            }}
                          >
                            <div
                              id={`chartdiv${r.id}`}
                              style={{ width: "100%", height: "100%" }}
                            ></div>
                          </div>
                        )}
                      </>
                    )
                  }}
                ></PanelDirective>
              )
            })}
          </PanelsDirective>
        </DashboardLayoutComponent>
      </div>
    </Row>
  )
})

const InfoSection = ({ infoSectionId }) => {
  const [infoSection, setInfoSection] = useState(null)

  useEffect(() => {
    loadData()
  }, [infoSectionId])

  const loadData = async () => {
    let section = await api.getInfoSection(infoSectionId)
    setInfoSection(section)
  }

  if (infoSection == null) {
    return null
  }
  return (
    <div
      className="template"
      style={{
        height: "100%",
        width: "100%",
        padding: "5px",
      }}
    >
      <div
        style={{
          height: "100%",
          overflow: "auto",
          boxShadow: "0 0 0 0.7px #7E7E7E",
        }}
      >
        <div
          style={{
            textAlign: "center",
            fontSize: "16px",
            color: "#7A8997",
            fontFamily: "Inter",
          }}
        >
          <b>{infoSection.sectionName}</b>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: infoSection.sectionText,
          }}
        ></div>
      </div>
    </div>
  )
}

export default Dashboard
