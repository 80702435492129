import React from "react"
import { userService } from "services/user.service"

const canUseDOM = () => {
  if (
    typeof window === "undefined" ||
    !window.document ||
    !window.document.createElement
  ) {
    return false
  }
  return true
}
export const ZendeskAPI = () => {
  if (canUseDOM && window.zE) {
    const currentUser = userService.getLoggedInUser()
    window.zE("webWidget", "updateSettings", {
      webWidget: {
        helpCenter: {
          filter: { section: currentUser ? currentUser.zenDeskCategories : "" },
        },
      },
    })
  } else {
    console.warn("Zendesk is not initialized yet")
  }
}

export class Zendesk extends React.Component {
  constructor(props) {
    super(props)
    this.insertScript = this.insertScript.bind(this)
    this.onScriptLoaded = this.onScriptLoaded.bind(this)
  }

  onScriptLoaded() {
    if (typeof this.props.onLoaded === "function") {
      this.props.onLoaded()
    }
  }

  insertScript(zendeskKey, defer) {
    const script = document.createElement("script")
    if (defer) {
      script.defer = true
    } else {
      script.async = true
    }
    script.id = "ze-snippet"
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`
    script.addEventListener("load", this.onScriptLoaded)
    document.body.appendChild(script)
    this.loadAccessibe()
  }

  componentDidMount() {
    if (canUseDOM && !window.zE) {
      const { defer, zendeskKey, ...other } = this.props
      this.insertScript(zendeskKey, defer)
      window.zESettings = other
    }
  }

  componentWillUnmount() {
    if (!canUseDOM || !window.zE) {
      return
    }
    delete window.zE
    delete window.zESettings
  }

  loadAccessibe() {
    var s = document.createElement("script")
    s.src = "https://acsbapp.com/apps/app/dist/js/app.js"
    s.async = true
    s.onload = function () {
      acsbJS.init({
        statementLink: "",
        footerHtml: "",
        hideMobile: false,
        hideTrigger: false,
        language: "en",
        position: "left",
        leadColor: "#146FF8",
        triggerColor: "#146FF8",
        triggerRadius: "50%",
        triggerPositionX: "left",
        triggerPositionY: "bottom",
        triggerIcon: "people",
        triggerSize: "small",
        triggerOffsetX: 20,
        triggerOffsetY: 20,
        mobile: {
          triggerSize: "small",
          triggerPositionX: "left",
          triggerPositionY: "bottom",
          triggerOffsetX: 10,
          triggerOffsetY: 10,
          triggerRadius: "50%",
        },
      })
    }
    document.body.appendChild(s)
  }

  render() {
    return null
  }
}
