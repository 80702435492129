import {
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
} from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import {
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  Row,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const ShareDashboardModal = ({
  isOpen,
  toggleModal,
  dashboardToEdit,
  setDashboardToEdit,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [users, setUsers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState("")

  useEffect(() => {
    if (isOpen) {
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    setIsLoading(true)
    let u = await api.getUsersByUserId(currentUser.userID, false)
    setUsers(
      u.filter(
        x => dashboardToEdit.users.find(u => u.userID == x.userID) == undefined
      )
    )
    setDashboardToEdit({ ...dashboardToEdit, users: dashboardToEdit.users })
    setIsLoading(false)
  }

  const addSelectedItems = () => {
    let selected = users.filter(x => x.selected)
    if (selected.length > 0) {
      let dashboardUsersCopy = [...dashboardToEdit.users]
      selected.forEach(u => {
        u.selected = false
        dashboardUsersCopy.push(u)
      })
      setDashboardToEdit({ ...dashboardToEdit, users: dashboardUsersCopy })
      setUsers(
        users.filter(x => selected.find(s => s.userID == x.userID) == undefined)
      )
    }
  }
  const removeSelectedItems = () => {
    let selected = dashboardToEdit.users.filter(x => x.selected)
    if (selected.length > 0) {
      let userList = [...users]
      selected.forEach(u => {
        u.selected = false
        userList.push(u)
      })
      setUsers(userList)
      setDashboardToEdit({
        ...dashboardToEdit,
        users: dashboardToEdit.users.filter(
          x => selected.find(s => s.userID == x.userID) == undefined
        ),
      })
    }
  }

  const save = async () => {
    if (dashboardToEdit.dashboardID > -1) {
      await api.updateDashboard(currentUser.userID, dashboardToEdit)
      toggleModal()
    } else {
      await api.createDashboard(currentUser.userID, dashboardToEdit)
      toggleModal()
    }
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Share Dashboard
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        {!isLoading && (
          <>
            <Row className="mb-3" style={{ marginTop: "10px" }}>
              <div
                className="mb-3"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Input
                  type="checkbox"
                  name="publicDashboard"
                  id="publicDashboard"
                  checked={dashboardToEdit.publicDashboard}
                  onClick={() => {
                    setDashboardToEdit({
                      ...dashboardToEdit,
                      publicDashboard: !dashboardToEdit.publicDashboard,
                    })
                  }}
                />
                <Label style={{ marginLeft: "10px" }} htmlFor="publicDashboard">Public</Label>
                <a
                  href="#"
                  style={{ textAlign: "center", marginLeft: "30px" }}
                  onClick={e => {
                    e.preventDefault()
                    let dashboardIdBase64 = btoa(dashboardToEdit.dashboardID)
                    let txt =
                      window.location.href.replace(
                        window.location.pathname,
                        ""
                      ) +
                      "/login.aspx?dash=" +
                      dashboardIdBase64
                    navigator.clipboard.writeText(txt)
                    toast.success("Link copied to clipboard")
                  }}
                >
                  Copy Link
                </a>
              </div>
            </Row>
            <Row className="mb-3">
              <Col md="4">
                <InputGroup>
                  <InputGroupText>
                    <i className="fas fa-search"></i>
                  </InputGroupText>
                  <Input
                    placeholder="Search ..."
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                  />
                </InputGroup>
                <div style={{ overflowX: "auto", height: "350px" }}>
                  <table
                    className="table table-bordered low-padding-table"
                    style={{ backgroundColor: "white" }}
                  >
                    <thead>
                      <th>Users</th>
                    </thead>
                    <tbody>
                      {users
                        .filter(
                          x =>
                            search == null ||
                            search == "" ||
                            x.firstName
                              .toLowerCase()
                              .indexOf(search.toLowerCase()) > -1 ||
                            x.lastName
                              .toLowerCase()
                              .indexOf(search.toLowerCase()) > -1
                        )
                        .map((u, idx) => {
                          return (
                            <tr key={idx}>
                              <td
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: u.selected
                                    ? "#9abcea"
                                    : "unset",
                                }}
                                onClick={() => {
                                  let copy = [...users]
                                  let item = copy.find(
                                    x => x.userID == u.userID
                                  )
                                  item.selected = !item.selected
                                  setUsers(copy)
                                }}
                              >
                                {u.firstName} {u.lastName}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
              </Col>
              <Col md="4">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "25%",
                    marginRight: "25%",
                  }}
                >
                  <button
                    className="btn btn-primary"
                    onClick={addSelectedItems}
                  >
                    <i className="fas fa-plus"></i> Add User(s)
                  </button>
                  <button
                    className="btn btn-outline-danger"
                    style={{ marginTop: "15px" }}
                    onClick={removeSelectedItems}
                  >
                    <i className="fas fa-times"></i> Remove User(s)
                  </button>
                </div>
              </Col>
              <Col md="4">
                <div style={{ overflowX: "auto", height: "360px" }}>
                  <table
                    className="table table-bordered low-padding-table"
                    style={{ backgroundColor: "white" }}
                  >
                    <thead>
                      <th>Access to List</th>
                    </thead>
                    <tbody>
                      {dashboardToEdit.users.map((u, idx) => {
                        return (
                          <tr key={idx}>
                            <td
                              style={{
                                cursor: "pointer",
                                backgroundColor: u.selected
                                  ? "#9abcea"
                                  : "unset",
                              }}
                              onClick={() => {
                                let copy = [...dashboardToEdit.users]
                                let item = copy.find(x => x.userID == u.userID)
                                item.selected = !item.selected
                                setDashboardToEdit({
                                  ...dashboardToEdit,
                                  users: copy,
                                })
                              }}
                            >
                              {u.firstName} {u.lastName}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <button className="btn btn-secondary" onClick={toggleModal}>
          Close
        </button>
        <button className="btn btn-primary" onClick={save}>
          Save
        </button>
      </div>
    </Modal>
  )
}

export default ShareDashboardModal
