import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import {
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  Row,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import { ConfigSection } from "../ConfigModal"

const ShareProjectListModal = ({
  isOpen,
  toggleModal,
  scenarioId,
  alternativeList,
  setAlternativeList,
  reloadData,
  deleteAlternativeList,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [search, setSearch] = useState("")
  const [allUsers, setAllUsers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [configItems, setConfigItems] = useState([])

  useEffect(() => {
    if (isOpen) {
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    setIsLoading(true)
    let su = await api.getAlternativeListUsers(
      alternativeList.alternativeListID
    )
    setSelectedUsers(su)
    let u = await api.getParticipantsByScenarioId(scenarioId, false)
    setAllUsers(u.filter(x => su.find(s => s.userID == x.userID) == undefined))
    if (alternativeList.listTypeID == 2) {
      let cfg = await api.getConfigItems(
        currentUser.userID,
        alternativeList.alternativeListID,
        "ListConfig"
      )
      setConfigItems(
        cfg
          .sort((a, b) => {
            let fa = a.displayName.toLowerCase(),
              fb = b.displayName.toLowerCase()

            if (fa < fb) {
              return -1
            }
            if (fa > fb) {
              return 1
            }
            return 0
          })
          .map((x, idx) => {
            x.id = idx
            return x
          })
      )
    }
    setIsLoading(false)
  }

  const save = async () => {
    let usersToRemove = allUsers.filter(x => x.user.isRemoved).map(x => x.user)
    let usersToAdd = selectedUsers.filter(x => x.isAdded)
    await api.updateAlternativeList(currentUser.userID, alternativeList)
    //filtered list
    if (alternativeList.listTypeID == 2) {
      let updateTasks = configItems
        .filter(x => x.isDirty)
        .map(x => async () => {
          return await api.updateAlternativeListFilter(
            alternativeList.alternativeListID,
            {
              configName: x.configItemName,
              configValues: x.configItemValue,
            }
          )
        })
      if (updateTasks.length > 0) {
        await Promise.all(updateTasks.map(t => t()))
      }
    }
    if (usersToRemove.length > 0) {
      let tasks = usersToRemove.map(x => async () => {
        return await api.deleteAlternativeListUser(
          x.userID,
          alternativeList.alternativeListID
        )
      })
      await Promise.all(tasks.map(t => t()))
    }
    if (usersToAdd.length > 0) {
      let tasks = usersToAdd.map(x => async () => {
        return await api.createAlternativeListUser(
          x.userID,
          alternativeList.alternativeListID
        )
      })
      await Promise.all(tasks.map(t => t()))
    }
    toast.success("Projects updated successfuly")
    reloadData(alternativeList.alternativeListID)
    toggleModal()
  }

  const addSelectedItems = () => {
    let selected = allUsers.filter(x => x.selected)
    if (selected.length > 0) {
      let copy = [...allUsers]
      copy = copy.filter(
        x => selected.find(s => x.userID == s.userID) == undefined
      )
      let altListUsers = [...selectedUsers]
      selected.forEach(s => {
        altListUsers.push({
          ...s.user,
          selected: false,
          isAdded: true,
          isRemoved: false,
        })
      })
      setAllUsers(copy)
      setSelectedUsers(altListUsers)
    }
  }
  const removeSelectedItems = () => {
    let selected = selectedUsers.filter(x => x.selected)
    if (selected.length > 0) {
      let copy = [...selectedUsers]
      copy = copy.filter(
        x => selected.find(s => x.userID == s.userID) == undefined
      )
      let allUsersCopy = [...allUsers]
      selected.forEach(s => {
        allUsersCopy.push({
          user: { ...s, selected: false, isAdded: false, isRemoved: true },
          selected: false,
        })
      })
      setAllUsers(allUsersCopy)
      setSelectedUsers(copy)
    }
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="xl"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Edit / Share List
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      {!isLoading && alternativeList != null && (
        <div className="modal-body">
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">List Name</Label>
                <Input
                  name="listname"
                  type="text"
                  onChange={e =>
                    setAlternativeList({
                      ...alternativeList,
                      alternativeListName: e.target.value,
                    })
                  }
                  value={alternativeList.alternativeListName}
                />
              </div>
            </Col>
          </Row>
          {alternativeList.listTypeID == 2 && configItems.length > 0 && (
            <ConfigSection
              isOpen={true}
              configItems={configItems}
              setConfigItems={setConfigItems}
              isMultiSelect={true}
            />
          )}
          <div className="divider"></div>
          <div className="form-check">
            {currentUser.roleID >= 10 && (
              <>
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="customCheck4"
                  defaultChecked={alternativeList.publicList}
                  onChange={e => {
                    setAlternativeList({
                      ...alternativeList,
                      publicList: !alternativeList.publicList,
                    })
                  }}
                />
                <Label
                  className="form-check-label"
                  for="customCheck4"
                  style={{ marginLeft: "10px" }}
                >
                  Public
                </Label>
              </>
            )}
            <a
              href="#"
              style={{ marginLeft: "30px" }}
              onClick={e => {
                e.preventDefault()
                let altListBase64 = btoa(alternativeList.alternativeListID)
                let txt =
                  window.location.origin + "/login.aspx?list=" + altListBase64
                navigator.clipboard.writeText(txt)
                toast.success("Link copied to clipboard")
              }}
            >
              Copy Link
            </a>
          </div>
          {!alternativeList.publicList && (
            <Row style={{ marginTop: "10px" }}>
              <Col md="4">
                <InputGroup>
                  <InputGroupText>
                    <i className="fas fa-search"></i>
                  </InputGroupText>
                  <Input
                    placeholder="Search ..."
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                  />
                </InputGroup>
                <div style={{ overflowX: "auto", height: "360px" }}>
                  <table
                    className="table table-bordered low-padding-table"
                    style={{ backgroundColor: "white" }}
                  >
                    <thead>
                      <th style={{ width: "100%" }}>Participants</th>
                    </thead>
                    <tbody>
                      {allUsers
                        .filter(
                          x =>
                            search == null ||
                            search == "" ||
                            x.user.firstName
                              .toLowerCase()
                              .indexOf(search.toLowerCase()) > -1 ||
                            x.user.lastName
                              .toLowerCase()
                              .indexOf(search.toLowerCase()) > -1
                        )
                        .map((u, idx) => {
                          return (
                            <tr key={idx}>
                              <td
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: u.selected
                                    ? "#9abcea"
                                    : "unset",
                                }}
                                onClick={() => {
                                  let copy = [...allUsers]
                                  let item = copy.find(
                                    x => x.userID == u.userID
                                  )
                                  item.selected = !item.selected
                                  setAllUsers(copy)
                                }}
                              >
                                {u.user.firstName} {u.user.lastName}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
              </Col>
              <Col md="4">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "25%",
                    marginRight: "25%",
                  }}
                >
                  <button
                    className="btn btn-primary"
                    onClick={addSelectedItems}
                  >
                    <i className="fas fa-plus"></i> Add
                  </button>
                  <button
                    className="btn btn-outline-danger"
                    style={{ marginTop: "15px" }}
                    onClick={removeSelectedItems}
                  >
                    <i className="fas fa-times"></i> Remove
                  </button>
                </div>
              </Col>
              <Col md="4">
                <div style={{ overflowX: "auto", height: "380px" }}>
                  <table
                    className="table table-bordered low-padding-table"
                    style={{ backgroundColor: "white" }}
                  >
                    <thead>
                      <th style={{ width: "100%" }}>Access to List</th>
                    </thead>
                    <tbody>
                      {selectedUsers.map((u, idx) => {
                        return (
                          <tr
                            key={idx}
                            style={{
                              cursor: "pointer",
                              backgroundColor: u.selected ? "#9abcea" : "unset",
                            }}
                            onClick={() => {
                              let copy = [...selectedUsers]
                              let item = copy.find(x => x.userID == u.userID)
                              item.selected = !item.selected
                              setSelectedUsers(copy)
                            }}
                          >
                            <td
                              style={{
                                cursor: "pointer",
                                backgroundColor: u.selected
                                  ? "#9abcea"
                                  : "unset",
                              }}
                            >
                              {u.firstName} {u.lastName}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          )}
        </div>
      )}
      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>
          <button
            onClick={deleteAlternativeList}
            className="btn btn-danger save-user"
          >
            Delete
          </button>
        </div>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default ShareProjectListModal
