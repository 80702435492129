export const sortData = (data, prop, ascDesc) => {
  function compare(a, b) {
    if (ascDesc === undefined || ascDesc === "asc") {
      if (a[prop] < b[prop]) {
        return -1
      }
      if (a[prop] > b[prop]) {
        return 1
      }
      return 0
    } else {
      if (a[prop] > b[prop]) {
        return -1
      }
      if (a[prop] < b[prop]) {
        return 1
      }
      return 0
    }
  }
  return data.sort(compare)
}

export const sortAsc = (data, prop) => {
  function compare(a, b) {
    if (a[prop] < b[prop]) {
      return -1
    }
    if (a[prop] > b[prop]) {
      return 1
    }
    return 0
  }
  let ret = [...data].sort(compare)
  return ret
}
export const sortDesc = (data, prop) => {
  function compare(a, b) {
    if (b[prop] < a[prop]) {
      return -1
    }
    if (b[prop] > a[prop]) {
      return 1
    }
    return 0
  }
  let ret = [...data].sort(compare)
  return ret
}