import { TextareaAutosize } from "@material-ui/core"
import HelpIconWithModal from "components/custom/helpIconWithModal"
import React from "react"
import CurrencyInput from "react-currency-input-field"
import { Col, Input, Label, Row } from "reactstrap"
import { userService } from "services/user.service"
import moment from "moment"

const MinNeedSection = ({
  alternative,
  changeAlternativeProp,
  priorityOptions,
  errorProps,
}) => {
  const currentUser = userService.getLoggedInUser()
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Label className="form-label" hidden={alternative.alternativeID <= 0}>
          ID
        </Label>
        <Col sm="6">
          <Input
            name="alternativeNum"
            type="text"
            onChange={e =>
              changeAlternativeProp("alternativeNum", e.target.value)
            }
            value={alternative.alternativeNum}
            hidden={alternative.alternativeID <= 0}
          />
        </Col>
        <Col
          sm="6"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Label className="form-label">
            <b>
              Submitted by{" "}
              {alternative.alternativeID > 0
                ? alternative.submitter + " - " +  moment(alternative.createdDT).format("MM/DD/YYYY")
                : `${currentUser.firstName} ${currentUser.lastName}`}
            </b>
          </Label>
          {/* <HelpIconWithModal itemId={1} /> */}
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <div className="mb-3">
            <Label className="form-label">Title</Label>
            <Input
              name="alternativeName"
              type="text"
              onChange={e =>
                changeAlternativeProp("alternativeName", e.target.value)
              }
              value={alternative.alternativeName}
            />
            {errorProps.find(x => x == "alternativeName") && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                Required
              </div>
            )}
          </div>
          <div className="mb-3">
            <Label className="form-label">Description</Label>
            <TextareaAutosize
              className="form-control"
              placeholder="Provide an executive summary of the following fields: customer, business problem, and business outcomes."
              minRows={2}
              onChange={e =>
                changeAlternativeProp("description", e.target.value)
              }
              value={alternative.description}
            />
            {errorProps.find(x => x == "description") && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                Required
              </div>
            )}
          </div>
        </Col>
      </Row>      
      <Row>
        <Col md="6">
          <div className="mb-3">
            <Label className="form-label">Cost</Label>
            <CurrencyInput
              className="form-control"
              value={alternative.cost}
              onValueChange={(value, name, values) => {
                changeAlternativeProp("cost", values.float)
              }}
              placeholder=""
              prefix={"$"}
            />
          </div>
        </Col>
        <Col md="6">
          <div className="mb-3">
            <Label className="form-label">Benefit</Label>
            <CurrencyInput
              className="form-control"
              value={alternative.benefit}
              onValueChange={(value, name, values) => {
                changeAlternativeProp("benefit", values.float)
              }}
              placeholder=""
              prefix={"$"}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <div className="mb-3">
            <Label className="form-label">Business Priority</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={alternative.businessPriority || -1}
              onChange={e => {
                changeAlternativeProp("businessPriority", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {priorityOptions.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemName}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
        </Col>
      </Row>

    </React.Fragment>
  )
}

export default MinNeedSection
