import useModal from "hooks/useModalHook"
import EditDocumentModal from "pages/Projects/EditProject/Modals/EditDocumentModal"
import React, { useEffect, useState } from "react"
import { Col, Label } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"

const DynamicFormDocuments = ({
  label,
  itemId,
  scenarioId,
  fullWidth,
  col,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [documents, setDocuments] = useState([])
  const [documentToEdit, setDocumentToEdit] = useState(null)
  const { show: isDocModalVisible, toggle: toggleDocModal } = useModal()

  useEffect(() => {
    loadDocuments()
  }, [])

  const loadDocuments = async () => {
    let docs = await api.getDocuments(itemId ?? 0, scenarioId)
    setDocuments(docs)
  }

  const addDocument = () => {
    setDocumentToEdit({
      documentID: -1,
      alternativeID: itemId ?? 0,
      scenarioID: scenarioId,
      userID: currentUser.userID,
      docType: label,
    })
    toggleDocModal()
  }

  return (
    <React.Fragment>
      <Col md={fullWidth ? "12" : col}>
        <div className="mb-3">
          <Label>{label}</Label>
          <button
            className="btn btn-primary"
            style={{ marginLeft: "20px" }}
            onClick={addDocument}
          >
            <i className="fas fa-plus">&nbsp;</i>Add Document
          </button>
          <div className="d-flex-col">
            {documents
              .filter(x => x.docType == label)
              .map((d, idx) => (
                <a
                  key={idx}
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                    setDocumentToEdit(d)
                    toggleDocModal()
                  }}
                >
                  {d.docTitle}
                </a>
              ))}
          </div>
        </div>
      </Col>
      <EditDocumentModal
        isOpen={isDocModalVisible}
        toggleModal={toggleDocModal}
        documentToEdit={documentToEdit}
        setDocumentToEdit={setDocumentToEdit}
        reloadData={loadDocuments}
        showType={false}
      />
    </React.Fragment>
  )
}

export default DynamicFormDocuments
