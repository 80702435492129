import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import useModal from "hooks/useModalHook"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row, Alert } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import EditTaskTemplateFieldModal from "./EditTaskTemplateFieldModal"
import { ReactTabulator } from "react-tabulator"
import { createRoot } from "react-dom/client"
import isNullOrEmpty, { hasFieldError } from "utils/isNullOrEmpty"
import { customFormatter } from "utils/tabulator"

const EditTaskTemplateModal = ({
  isOpen,
  toggleModal,
  taskTypeIdToEdit,
  reload,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [taskTypeToEdit, setTaskTypeToEdit] = useState(null)
  const [taskTypeFields, setTaskTypeFields] = useState([])

  const [taskScopes, setTaskScopes] = useState([])
  const [modalSizes, setModalSizes] = useState([])
  const [taskDisplayOptions, setTaskDisplayOptions] = useState([])
  const [taskEditRules, setTaskEditRules] = useState([])
  const [taskTypeFieldOptions, setTaskTypeFieldOptions] = useState([])
  const [iconOptions, setIconOptions] = useState([])

  const [fieldToEdit, setFieldToEdit] = useState(null)
  const { show: showFieldModal, toggle: toggleFieldModal } = useModal()
  const [errorFields, setErrorFields] = useState([])

  const newTaskTemplate = {
    taskTypeID: -1,
    taskTypeName: "",
    scope: "",
    docAllowed: false,
    docRequired: false,
    modalSize: "md",
    displayOption: "",
    numColumns: 1,
    editRule: "",
    enableByScenario: "",
    inactive: false,
    showWidgets: false,
    iconClass: "",
    enterpriseID: currentUser.enterpriseID,
  }

  const newFieldTemplate = {
    taskTypeFieldID: -1,
    taskTypeID: -1,
    fieldLabel: "",
    fieldProperty: "",
    fieldType: "",
    listName: "",
    orderID: 0,
    fullRow: false,
    placeholder: "",
    defaultValue: "",
    listItems: [],
  }

  useEffect(() => {
    if (isOpen) {
      setErrorFields([])
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    api.getList(currentUser.userID, "TaskScope").then(r => {
      setTaskScopes(r)
    })
    api.getList(currentUser.userID, "ModalSize").then(r => {
      setModalSizes(r)
    })
    api.getList(currentUser.userID, "TaskDisplayOption").then(r => {
      setTaskDisplayOptions(r)
    })
    api.getList(currentUser.userID, "TaskEditRule").then(r => {
      setTaskEditRules(r)
    })
    api.getTaskTypeFieldOptions().then(r => {
      setTaskTypeFieldOptions(r)
    })
    api.getList(currentUser.userID, "Icon").then(r => {
      setIconOptions(r)
    })

    if (taskTypeIdToEdit == -1) {
      setTaskTypeToEdit({ ...newTaskTemplate })
      setTaskTypeFields([])
    } else {
      let tt = await api.getTaskType(taskTypeIdToEdit)
      setTaskTypeToEdit(tt)
      let tf = await api.getTaskTypeFields(taskTypeIdToEdit, 0, 0)
      setTaskTypeFields(tf)
    }
  }

  const changeTaskProp = (prop, val) =>
    setTaskTypeToEdit({ ...taskTypeToEdit, [prop]: val })

  const addNewField = () => {
    let nextId = -1
    if (taskTypeFields.length != 0) {
      const minValue = Math.min(...taskTypeFields.map(x => x.taskTypeFieldID))
      if (minValue < 0) {
        nextId = minValue - 1
      }
    }
    setFieldToEdit({ ...newFieldTemplate, taskTypeFieldID: nextId })
    toggleFieldModal()
  }

  const editField = (e, field) => {
    e && e.preventDefault()
    setFieldToEdit(field)
    toggleFieldModal()
  }

  const deleteField = field => {
    let copy = [...taskTypeFields]
    let item = copy.find(x => x.taskTypeFieldID == field.taskTypeFieldID)
    item.isDeleted = true
    setTaskTypeFields(copy)
  }

  const onFieldSave = () => {
    let copy = [...taskTypeFields]
    let item = taskTypeFields.find(
      x => x.taskTypeFieldID == fieldToEdit.taskTypeFieldID
    )

    let nextOrder = 1
    if (taskTypeFields.length > 0 && fieldToEdit.orderID == 0) {
      let maxOrder = Math.max(...taskTypeFields.map(x => x.orderID))
      if (maxOrder >= 1) {
        nextOrder = maxOrder + 1
      }
    } else {
      nextOrder = fieldToEdit.orderID
    }

    if (item == undefined) {
      copy.push({ ...fieldToEdit, orderID: nextOrder })
    } else {
      copy.splice(copy.indexOf(item), 1, {
        ...fieldToEdit,
        isDirty: true,
        orderID: nextOrder,
      })
    }
    setTaskTypeFields(copy)
    toggleFieldModal()
  }

  const save = async () => {
    let errors = []
    if (isNullOrEmpty(taskTypeToEdit.taskTypeName)) {
      errors.push("taskTypeName")
    }
    setErrorFields(errors)
    if (errors.length > 0) {
      return
    }

    if (taskTypeToEdit.taskTypeID == -1) {
      let taskTypeId = await api.createTaskType(
        currentUser.userID,
        taskTypeToEdit
      )
      let tasks = taskTypeFields.map(x => async () => {
        return await api.createTaskTypeField(currentUser.userID, {
          ...x,
          taskTypeID: taskTypeId,
        })
      })
      taskTypeFields
        .filter(x => x.fieldType == "DDL")
        .forEach(f => {
          let newListItems = f.listItems.filter(
            x => x.listItemID < 0 && !x.isDeleted
          )
          let newTasks = newListItems.map(x => async () => {
            return await api.createTaskTypeListItem(taskTypeId, f.listName, x)
          })
          tasks = [...tasks, ...newTasks].filter(
            x => x != undefined && x != null
          )
        })
      await Promise.all(tasks.map(t => t()))
      reload && reload()
    } else {
      await api.updateTaskType(currentUser.userID, taskTypeToEdit)
      let tasks = []
      let newFields = taskTypeFields.filter(
        x => x.taskTypeFieldID < 0 && !x.isDeleted
      )
      let updatedFields = taskTypeFields.filter(
        x => x.taskTypeFieldID > 0 && x.isDirty
      )
      let deletedFields = taskTypeFields.filter(
        x => x.taskTypeFieldID > 0 && x.isDeleted
      )
      let delFieldTasks = deletedFields.map(x => async () => {
        return await api.deleteTaskTypeField(
          currentUser.userID,
          x.taskTypeFieldID
        )
      })
      let updateFieldTasks = updatedFields.map(x => async () => {
        return await api.updateTaskTypeField(currentUser.userID, {
          ...x,
          taskTypeID: taskTypeToEdit.taskTypeID,
        })
      })
      let newFieldTasks = newFields.map(x => async () => {
        return await api.createTaskTypeField(currentUser.userID, {
          ...x,
          taskTypeID: taskTypeToEdit.taskTypeID,
        })
      })
      tasks = [...newFieldTasks, ...updateFieldTasks, ...delFieldTasks].filter(
        x => x != undefined && x != null
      )
      // let tasks = taskTypeFields.map(x => async () => {
      //   if (x.taskTypeFieldID > 0) {
      //     return await api.updateTaskTypeField(currentUser.userID, {
      //       ...x,
      //       taskTypeID: taskTypeToEdit.taskTypeID,
      //     })
      //   } else {
      //     return await api.createTaskTypeField(currentUser.userID, {
      //       ...x,
      //       taskTypeID: taskTypeToEdit.taskTypeID,
      //     })
      //   }
      // })
      taskTypeFields
        .filter(x => x.fieldType == "DDL")
        .forEach(f => {
          let deletedListItems = f.listItems.filter(
            x => x.listItemID > 0 && x.isDeleted
          )
          let updatedListItems = f.listItems.filter(
            x => x.listItemID > 0 && !x.isDeleted && x.isDirty
          )
          let newListItems = f.listItems.filter(
            x => x.listItemID < 0 && !x.isDeleted
          )
          let delTasks = deletedListItems.map(x => async () => {
            return await api.deleteTaskTypeListItem(
              taskTypeToEdit.taskTypeID,
              f.listName,
              x.listItemID
            )
          })
          let updateTasks = updatedListItems.map(x => async () => {
            return await api.updateTaskTypeListItem(
              taskTypeToEdit.taskTypeID,
              f.listName,
              x
            )
          })
          let newTasks = newListItems.map(x => async () => {
            return await api.createTaskTypeListItem(
              taskTypeToEdit.taskTypeID,
              f.listName,
              x
            )
          })
          tasks = [...tasks, ...delTasks, ...updateTasks, ...newTasks].filter(
            x => x != undefined && x != null
          )
        })
      await Promise.all(tasks.map(t => t()))
      reload && reload()
    }

    toast.success("Task Template Saved")
    toggleModal()
  }

  const columns = [
    {
      rowHandle: true,
      formatter: "handle",
      headerSort: false,
      frozen: true,
      width: 50,
    },
    {
      title: "Field",
      field: "fieldLabel",
      headerSort: false,
      formatter: customFormatter(({ rowData, cellValue }) => (
        <a href="#" onClick={e => editField(e, rowData)}>
          {cellValue}
        </a>
      )),
    },
    {
      title: "Type",
      field: "fieldType",
      width: 150,
      headerSort: false,
    },
    {
      title: "Order",
      field: "orderID",
      headerSort: false,
    },
    {
      title: "Field Property",
      field: "fieldProperty",
      headerSort: false,
      formatter: customFormatter(({ rowData, cellValue }) => (
        <i
          className="fas fa-trash"
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => deleteField(rowData)}
        ></i>
      )),
    },
  ]

  return (
    <>
      <Modal
        isOpen={isOpen}
        size="lg"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Edit Task Template
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {taskTypeToEdit != null && (
          <div className="modal-body">
            <Row>
              <Alert
                id="helpView"
                variant="light"
                className="always-enabled"
                style={{ marginBottom: "10px", paddingBottom: "0px" }}
              >
                <div>
                  Give the template a name and select the options to determine
                  the layout and size of the popup window that is shown to edit
                  tasks.
                  <ol>
                    <li>
                      The Edit Rule determines who can edit the task generated
                      from this template.
                    </li>
                    <li>
                      The Display option determines if the tasks should show in
                      a list or in a workflow.
                    </li>
                    <li>
                      Add the fields that you want to track for these tasks one
                      by one by clicking "Add Field". If this is going to be
                      used in a workflow, the following fields are required:
                      Owner, Status, Due Date, Complete Date, Name.
                    </li>
                  </ol>
                </div>
              </Alert>
            </Row>

            <div className="mb-3">
              <Label className="form-label">Name</Label>
              <Input
                type="text"
                onChange={e => changeTaskProp("taskTypeName", e.target.value)}
                value={taskTypeToEdit.taskTypeName}
              />
              {hasFieldError(errorFields, "taskTypeName") && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Name is required
                </div>
              )}
            </div>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">Scope</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={taskTypeToEdit.scope}
                    onChange={e => {
                      changeTaskProp("scope", e.target.value)
                    }}
                  >
                    <option value="-1">Select scope...</option>
                    {taskScopes.map((s, i) => (
                      <option key={i} value={s.listItemID}>
                        {s.listItemName}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col md="6">
                {" "}
                <div className="mb-3">
                  <Label className="form-label">Edit Rule</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={taskTypeToEdit.editRule}
                    onChange={e => {
                      changeTaskProp("editRule", e.target.value)
                    }}
                  >
                    <option value="-1">Select edit rule...</option>
                    {taskEditRules.map((s, i) => (
                      <option key={i} value={s.listItemID}>
                        {s.listItemName}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">Modal Size</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={taskTypeToEdit.modalSize}
                    onChange={e => {
                      changeTaskProp("modalSize", e.target.value)
                    }}
                  >
                    <option value="-1">Select modal size...</option>
                    {modalSizes.map((s, i) => (
                      <option key={i} value={s.listItemID}>
                        {s.listItemName}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col md="6">
                {" "}
                <div className="mb-3">
                  <Label className="form-label">Number of Columns</Label>
                  <Input
                    type="number"
                    onChange={e => changeTaskProp("numColumns", e.target.value)}
                    value={taskTypeToEdit.numColumns}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                {" "}
                <div className="mb-3">
                  <Label className="form-label">Display Option</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={taskTypeToEdit.displayOption}
                    onChange={e => {
                      changeTaskProp("displayOption", e.target.value)
                    }}
                  >
                    <option value="-1">Select modal size...</option>
                    {taskDisplayOptions.map((s, i) => (
                      <option key={i} value={s.listItemID}>
                        {s.listItemName}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col md="6">
                {" "}
                <div className="mb-3">
                  <Label className="form-label">Icon Class</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={taskTypeToEdit.iconClass}
                    onChange={e => {
                      changeTaskProp("iconClass", e.target.value)
                    }}
                  >
                    <option value="">Select icon...</option>
                    {iconOptions.map((s, i) => (
                      <option key={i} value={s.listItemID}>
                        {s.listItemName}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="form-check mb-3">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="cv-docallowed"
                    checked={taskTypeToEdit.docAllowed}
                    onClick={e => {
                      changeTaskProp("docAllowed", !taskTypeToEdit.docAllowed)
                    }}
                  />
                  <Label className="form-check-label" for="cv-docallowed">
                    Document Allowed
                  </Label>
                </div>
              </Col>
              <Col md="6">
                {" "}
                <div className="form-check mb-3">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="cv-docRequired"
                    checked={taskTypeToEdit.docRequired}
                    onClick={e => {
                      changeTaskProp("docRequired", !taskTypeToEdit.docRequired)
                    }}
                  />
                  <Label className="form-check-label" for="cv-docRequired">
                    Document Required
                  </Label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="form-check mb-3">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="cv-enableByScenario"
                    checked={taskTypeToEdit.enableByScenario}
                    onClick={e => {
                      changeTaskProp(
                        "enableByScenario",
                        !taskTypeToEdit.enableByScenario
                      )
                    }}
                  />
                  <Label className="form-check-label" for="cv-enableByScenario">
                    Enable by Decision
                  </Label>
                </div>
              </Col>
              <Col md="6">
                <div className="form-check mb-3">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="cv-showWidgets"
                    checked={taskTypeToEdit.showWidgets}
                    onClick={e => {
                      changeTaskProp("showWidgets", !taskTypeToEdit.showWidgets)
                    }}
                  />
                  <Label className="form-check-label" for="cv-inactive">
                    Show Widgets
                  </Label>
                </div>
              </Col>
            </Row>

            <div className="form-check mb-3">
              <Input
                type="checkbox"
                className="form-check-input"
                id="cv-inactive"
                checked={taskTypeToEdit.inactive}
                onClick={e => {
                  changeTaskProp("inactive", !taskTypeToEdit.inactive)
                }}
              />
              <Label className="form-check-label" for="cv-inactive">
                Inactive
              </Label>
            </div>
            <button
              type="button"
              className="btn btn-primary mb-3"
              onClick={addNewField}
            >
              Add Field
            </button>
            <ReactTabulator
              // onRef={r => (tabulatorRef.current = r.current)}
              columns={columns}
              data={taskTypeFields.filter(x => !x.isDeleted)}
              options={{
                pagination: "local",
                paginationSize: 30,
                paginationSizeSelector: [5, 10, 20, 30, 50, 100, 200, 300],
                movableRows: true,
                layout: "fitColumns",
                cssClass: "table-borderless",
              }}
              events={{
                rowMoved: function (row) {
                  let list = row.getTable().getData()
                  list = list.map((l, idx) => {
                    if (l.orderID != idx + 1) {
                      l.orderID = idx + 1
                      l.isDirty = true
                    }
                    return l
                  })
                  setTaskTypeFields(list)
                },
              }}
            />
          </div>
        )}
        <div
          className="modal-footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-primary" onClick={save}>
            Save
          </button>
        </div>
      </Modal>
      <EditTaskTemplateFieldModal
        isOpen={showFieldModal}
        toggleModal={toggleFieldModal}
        fieldToEdit={fieldToEdit}
        setFieldToEdit={setFieldToEdit}
        taskTypeFields={taskTypeFieldOptions}
        onFieldSave={onFieldSave}
        taskTypeId={taskTypeIdToEdit}
      />
    </>
  )
}

export default EditTaskTemplateModal
