import { TextareaAutosize } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"

const CreateRevisionCopyModal = ({
  isOpen,
  toggleModal,
  alternative,
  props,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [revisionDescription, setRevisionDescription] = useState("")
  const [changeRequestTasks, setChangeRequestTasks] = useState([])
  const [selectedTaskId, setSelectedTaskId] = useState(0)

  useEffect(() => {
    if (isOpen) {
      setSelectedTaskId(0)
      setRevisionDescription("")
      loadChangeRequestTasks()
    }
  }, [isOpen])

  const loadChangeRequestTasks = async () => {
    let t = await api.getGeneralTasks(
      1,
      "Alternatives",
      alternative.alternativeID,
      currentUser.userID
    )
    setChangeRequestTasks(t)
  }

  const save = async () => {

    alternative.revisionDescription = revisionDescription
    alternative.revisionTaskID = selectedTaskId
    await api.updateAlternative(currentUser.userID, alternative)

    let newAltId = await api.createAlternativeRevision(
      currentUser.userID,
      alternative.alternativeID
    )
    let newAlt = await api.getAlternative(newAltId)

    //newAlt.revisionDescription = revisionDescription
    //newAlt.revisionTaskID = selectedTaskId

    //await api.updateAlternative(currentUser.userID, newAlt)

    toast.success("Revision created")
    props.history.goBack()
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Describe reason for revision
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {changeRequestTasks.length > 0 && (
          <div className="mb-3">
            <Label className="form-label">Change Request</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={selectedTaskId}
              onChange={e => {
                setSelectedTaskId(e.target.value)
              }}
            >
              <option value="0">NA</option>
              {changeRequestTasks.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.taskID}`}>
                    CR-{a.taskNum} {a.taskName}
                  </option>
                )
              })}
            </select>
          </div>
        )}
        <div className="mb-3">
          <Label className="form-label">Revision Description</Label>
          <TextareaAutosize
            className="form-control"
            placeholder="Description..."
            minRows={2}
            onChange={e => setRevisionDescription(e.target.value)}
            value={revisionDescription}
          />
        </div>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default CreateRevisionCopyModal
