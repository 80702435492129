import React from "react";
import { Col, Label } from "reactstrap";
import {
  HtmlEditor,
  Image,
  Link,
  QuickToolbar,
  Toolbar,
  RichTextEditorComponent
} from "@syncfusion/ej2-react-richtexteditor";
import { Inject } from "@syncfusion/ej2-react-gantt";

export const DynamicFormRichText = React.memo(function dynamicFormRichText({
  formFieldId, label, value, placeholder, changeFormFieldValue, col, fullWidth, alwaysEditable, alwaysReadOnly, hasError, requiredMessage,
}) {
  const toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "StrikeThrough",
      "FontName",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "LowerCase",
      "UpperCase",
      "|",
      "Formats",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Outdent",
      "Indent",
      "|",
      "CreateLink",
      "Image",
      "|",
      "ClearFormat",
      "Print",
      "SourceCode",
      "FullScreen",
      "|",
      "Undo",
      "Redo",
    ],
    type: "Expand",
  };
  return (
    <Col md={fullWidth ? "12" : col}>
      <div className="mb-3">
        <Label className="form-label">{label}</Label>
        <RichTextEditorComponent
          toolbarSettings={toolbarSettings}
          value={value}
          change={e => {
            changeFormFieldValue(formFieldId, e.value);
          }}
          placeholder={placeholder || ""}
        >
          <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
        </RichTextEditorComponent>
        {hasError && (
          <div className="invalid-feedback" style={{ display: "block" }}>
            {requiredMessage}
          </div>
        )}
      </div>
    </Col>
  );
});
