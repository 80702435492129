import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import useModal from "hooks/useModalHook"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const EditFormFieldListItemModal = ({
  isOpen,
  toggleModal,
  listItemToEdit,
  changeListItemToEditProp,
  onSave,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [hasNameError, setHasNameError] = useState(false)
  const [hasValueError, setHasValueError] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setHasNameError(false)
      setHasValueError(false)
    }
  }, [isOpen])

  const save = async () => {
    if (
      listItemToEdit.listItemName == undefined ||
      listItemToEdit.listItemName == null ||
      listItemToEdit.listItemName == ""
    ) {
      setHasNameError(true)
      return
    }
    if (
      listItemToEdit.listItemValue == undefined ||
      listItemToEdit.listItemValue == null ||
      listItemToEdit.listItemValue == ""
    ) {
      setHasValueError(true)
      return
    }

    setHasNameError(false)
    setHasValueError(false)

    onSave && onSave()
    toggleModal()
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        size="md"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Edit List Item
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {listItemToEdit && (
          <div className="modal-body">
            <div className="mb-3">
              <Label className="form-label">List Item Name</Label>
              <Input
                type="text"
                onChange={e =>
                  changeListItemToEditProp("listItemName", e.target.value)
                }
                value={listItemToEdit.listItemName}
              />
              {hasNameError && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Name is required
                </div>
              )}
            </div>
            <div className="mb-3">
              <Label className="form-label">List Item Value</Label>
              <Input
                type="text"
                onChange={e =>
                  changeListItemToEditProp("listItemValue", e.target.value)
                }
                value={listItemToEdit.listItemValue}
              />
              {hasValueError && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Value is required
                </div>
              )}
            </div>
          </div>
        )}

        <div
          className="modal-footer"
          style={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
          }}
        >
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={save}
          >
            Save
          </button>
        </div>
      </Modal>
    </>
  )
}

export default EditFormFieldListItemModal
