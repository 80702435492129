import { swalWithConfirmButton } from "components/custom/swal"
import useModal from "hooks/useModalHook"
import React, { useEffect, useState } from "react"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import { userService } from "services/user.service"

const CreateFieldModal = ({
  isOpen,
  toggleModal,
  scope,
  formFieldTypes,
  onFieldSave,
  selectedDataType,
  existingFields,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [fieldToEdit, setFieldToEdit] = useState(null)

  useEffect(() => {
    if (isOpen) {
      setFieldToEdit({
        fieldId: -1,
        fieldName: "",
        dataType: selectedDataType,
        enterpriseId: currentUser.enterpriseID,
        scope: scope,
        fieldTypeID: 2,
      })
    }
  }, [isOpen])

  const save = async () => {
    if (
      existingFields.find(
        x => x.fieldName.toLowerCase() == fieldToEdit.fieldName.toLowerCase()
      ) != undefined
    ) {
      swalWithConfirmButton.fire({
        title: "A field with this name already exists.",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    } else {
      await onFieldSave(fieldToEdit)
      toggleModal()
    }
  }

  const changeFieldProp = (prop, val) =>
    setFieldToEdit({ ...fieldToEdit, [prop]: val })

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Create Field
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {fieldToEdit != null && (
        <div className="modal-body">
          <div className="mb-3">
            <Label className="form-label">Field</Label>
            <Input
              type="text"
              onChange={e => changeFieldProp("fieldName", e.target.value)}
              value={fieldToEdit.fieldName}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Data Type</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={fieldToEdit.dataType}
              onChange={e => {
                changeFieldProp("dataType", e.target.value)
              }}
            >
              <option value="-1">Select type...</option>
              {/* {[...new Set(formFieldTypes.map(x => x.dataType))].map((s, i) => (
                <option key={i} value={s}>
                  {s}
                </option>
              ))} */}
              {[
                ...new Set(
                  formFieldTypes
                    .filter(x => x.dataType == selectedDataType)
                    .map(x => x.dataType)
                ),
              ].map((s, i) => (
                <option key={i} value={s}>
                  {s}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default CreateFieldModal
