import CustomTable from "components/custom/customTable"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import React, { useEffect, useState } from "react"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const ProjectDependencies = ({ activeTab, scenario }) => {
  const currentUser = userService.getLoggedInUser()
  const [dependencies, setDependencies] = useState([])
  const [alternatives, setAlternatives] = useState([])
  const [dependencyToEdit, setDependencyToEdit] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)

  const dependencyTypes = [
    { text: "Is mutually exclusive with", value: "Cant" },
    { text: "Is mutually inclusive with", value: "Must" },
    { text: "Requires", value: "AreqB" },
    { text: "Requires completion of", value: "AcompB" },
  ]
  const tableDependencyTypes = [
    { text: "Mutually Exclusive", value: "Cant" },
    { text: "Mutually Inclusive", value: "Must" },
    { text: "A Requires B", value: "AreqB" },
    { text: "A Requires completion of B", value: "AcompB" },
  ]

  const toggleEditModal = () => setShowEditModal(!showEditModal)

  useEffect(() => {
    if (activeTab == "ProjectDependencies" && scenario != null) {
      loadData(true)
    }
  }, [activeTab, scenario])

  const loadData = async showLoading => {
    try {
      if (showLoading) setIsLoading(true)
      await loadDependencies()
      let alts = await api.getAlternatives(
        currentUser.userID,
        scenario.scenarioID
      )
      setAlternatives(alts)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  const loadDependencies = async () => {
    let data = await api.getDependencies(scenario.currentOptModelID)
    setDependencies(
      data.map(d => {
        d.mustCantDisplayValue = mustCantDisplayValues[d.mustCant]
        return d
      })
    )
  }

  const editDependency = dependency => {
    setDependencyToEdit(dependency)
    toggleEditModal()
  }

  const addNewDependency = () => {
    let dependency = {
      alternativeID1: -1,
      alternativeID2: -1,
      active: true,
      mustCant: -1,
      optModelDependencyID: -1,
      optModelID: scenario.currentOptModelID,
    }
    setDependencyToEdit(dependency)
    toggleEditModal()
  }

  const saveDependency = async () => {
    if (
      dependencyToEdit.alternativeID1 !== -1 &&
      dependencyToEdit.alternativeID2 !== -1 &&
      dependencyToEdit.mustCant !== -1
    ) {
      if (dependencyToEdit.optModelDependencyID > -1) {
        await api.updateDependency(currentUser.userID, dependencyToEdit)
        toggleEditModal()
        loadDependencies()
      } else {
        await api.createDependency(currentUser.userID, dependencyToEdit)
        toggleEditModal()
        loadDependencies()
      }
    }
  }

  const deleteDependency = dependency => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this dependency?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteDependency(
            currentUser.userID,
            dependency.optModelDependencyID
          )
          loadData()
        }
      })
  }

  const changeDependencyProp = (prop, value) => {
    setDependencyToEdit({
      ...dependencyToEdit,
      [prop]: value,
    })
  }

  const mustCantDisplayValues = {
    AreqB: "Requires",
    AcompB: "Requires completion of",
    Cant: "Is mutually exclusive with",
    Must: "Is mutually inclusive with",
  }

  const columns = [
    {
      title: "Project A",
      field: "alternativeName1",
      thStyle: { width: "34%" },
      sort: true,
    },
    {
      title: "Dependency",
      field: "mustCantDisplayValue",
      thStyle: { width: "15%" },
      sort: true,
    },
    {
      title: "Project B",
      field: "alternativeName2",
      thStyle: { width: "34%" },
      sort: true,
    },
    {
      title: "Active",
      field: "alternativeName1",
      thStyle: { width: "7%" },
      formatter: row => {
        return <Input type="checkbox" readOnly="true" checked={row.active} />
      },
    },
    {
      title: "",
      field: "alternativeName1",
      thStyle: { width: "7%" },
      formatter: row => {
        return (
          <i
            className="fas fa-edit"
            style={{ color: colors.primary, cursor: "pointer" }}
            onClick={() => editDependency(row)}
          ></i>
        )
      },
    },
    {
      title: "",
      field: "alternativeName1",
      thStyle: { width: "7%" },
      formatter: row => {
        return (
          <i
            className="fas fa-trash"
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => deleteDependency(row)}
          ></i>
        )
      },
    },
  ]

  return (
    <React.Fragment>
      <Row>
        <Col>
          <button className="btn btn-primary" onClick={addNewDependency}>
            + Add Dependency
          </button>
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col>
          <CustomTable
            columns={columns}
            items={dependencies}
            setItems={setDependencies}
          />
        </Col>
      </Row>
      <Modal
      backdrop="static"
        isOpen={showEditModal}
        size="md"
        toggle={() => {
          toggleEditModal()
        }}
      >
        {dependencyToEdit !== null && (
          <>
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                {dependencyToEdit.optModelDependencyID == -1 ? "Add" : "Edit"}{" "}
                Dependency
              </h5>
              <button
                type="button"
                onClick={() => {
                  toggleEditModal()
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <Label className="form-label">Project A</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={dependencyToEdit.alternativeID1 || -1}
                  onChange={e => {
                    changeDependencyProp("alternativeID1", e.target.value)
                  }}
                >
                  <option value={-1}>Select Project</option>
                  {alternatives.map((a, idx) => {
                    return (
                      <option key={idx} value={`${a.alternativeID}`}>
                        {a.alternativeNum} - {a.alternativeName}  - <span dangerouslySetInnerHTML={{ __html: a.version }} />
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className="mb-3">
                <Label className="form-label">Dependency</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={dependencyToEdit.mustCant || -1}
                  onChange={e => {
                    changeDependencyProp("mustCant", e.target.value)
                  }}
                >
                  <option value={-1}>Select Dependency</option>
                  {dependencyTypes.map((a, idx) => {
                    return (
                      <option key={idx} value={`${a.value}`}>
                        {a.text}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className="mb-3">
                <Label className="form-label">Project B</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={dependencyToEdit.alternativeID2 || -1}
                  onChange={e => {
                    changeDependencyProp("alternativeID2", e.target.value)
                  }}
                >
                  <option value={-1}>Select Project</option>
                  {alternatives.map((a, idx) => {
                    return (
                      <option key={idx} value={`${a.alternativeID}`}>
                        {a.alternativeNum} - {a.alternativeName}  - <span dangerouslySetInnerHTML={{ __html: a.version }} />
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className="mb-3">
                <Input
                  id="activeNotActive"
                  type="checkbox"
                  checked={dependencyToEdit.active}
                  onClick={() =>
                    changeDependencyProp("active", !dependencyToEdit.active)
                  }
                />
                <Label
                  className="form-check-label"
                  for="activeNotActive"
                  style={{ marginLeft: "10px" }}
                >
                  Active
                </Label>
              </div>
            </div>
            <div className="modal-footer">
              <div>
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={toggleEditModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary save-user"
                  onClick={saveDependency}
                >
                  Save
                </button>
              </div>
            </div>
          </>
        )}
      </Modal>
    </React.Fragment>
  )
}

export default ProjectDependencies
