import scoringColumns from "pages/AdminObjectiveScore/ScoringColumns"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Container, Input, Label, Row } from "reactstrap"
import api from "services/api.service"
import colors from "utils/colors"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ReactApexChart from "react-apexcharts"
import ObjectiveRatingTable from "pages/_ObjectiveShared/ObjectiveRatingTable"
import { getObjectiveScoresChartOptionsSeries } from "pages/_ObjectiveShared/objectiveChartFunctions"
import { userService } from "services/user.service"
import { useDispatch, useSelector } from "react-redux"
import { changeNavbarParams, loadPageItem } from "store/actions"
import { loadUsersAvatars } from "utils/avatar"

const PwcGroupResponses = props => {
  const scenarioId = props.match.params.scenarioId
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  let [objScores, setObjScores] = useState([])
  let [isLoading, setIsLoading] = useState(false)
  let [showGrids, setShowGrids] = useState(false)
  let [showCharts, setShowCharts] = useState(true)
  let [scenario, setScenario] = useState(null)
  const [usersPhotos, setUsersPhotos] = useState([])

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "pwcGroupResponses",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        viewName: "pwcGroupResponses",
      })
    )
    loadData()
  }, [])

  const loadData = async () => {
    try {
      setIsLoading(true)
      let scen = await loadScenario()
      let scores = await api.getPwcScores(scenarioId, 1)
      let userScoreTasks = scores.map(s => async () => {
        return await api.getPwcUserScores(s.pwcScoreID, s.levelID)
      })
      let userScores = await Promise.all(userScoreTasks.map(t => t()))

      let flatUserScores = userScores.flat()
      let avatars = await loadUsersAvatars(flatUserScores.map(x => x.userID))
      setUsersPhotos(avatars)

      for (var i = 0; i < scores.length; i++) {
        scores[i].userScores = userScores[i]
        scores[i].chartData = getObjectiveScoresChartOptionsSeries(
          userScores[i],
          scen
        )
      }
      setObjScores(scores)
      setIsLoading(false)
      console.log(scores)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  const loadScenario = async () => {
    try {
      var sc = await api.getScenario(scenarioId)
      setScenario(sc)
      return sc
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Row>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
              }}
            >
              <div className="form-check">
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="customCheck1"
                  defaultChecked={showGrids}
                  onChange={e => {
                    setShowGrids(!showGrids)
                  }}
                />
                <Label className="form-check-label" for="customCheck1">
                  Show responses grids
                </Label>
              </div>
              <div className="form-check" style={{ marginLeft: "20px" }}>
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="customCheck5"
                  defaultChecked={showCharts}
                  onChange={e => {
                    setShowCharts(!showCharts)
                  }}
                />
                <Label className="form-check-label" for="customCheck5">
                  Show responses charts
                </Label>
              </div>
            </div>
          </Row>
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          {!isLoading &&
            objScores.length > 0 &&
            objScores.map((o, i) => (
              <Row key={i} style={{}}>
                {o != null && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "20px",
                        marginLeft: "15%",
                        marginRight: "15%",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "lightyellow",
                          padding: "15px",
                          borderRadius: "10px",
                          color: "maroon",
                          width: "30%",
                          textAlign: "center",
                          borderColor: "gray",
                        }}
                      >
                        <h5>{o.item1}</h5>
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                          backgroundColor: "white",
                          borderRadius: "10px",
                          border: "1px solid gray",
                          padding: "15px",
                        }}
                      >
                        <p style={{ marginBottom: "0px" }}>
                          <b>The mean score is: {o.avgUserRanking}</b>
                        </p>
                        <p style={{ marginBottom: "0px" }}>
                          <b>The standard deviation is: {o.standDev}</b>
                        </p>
                      </div>

                      <div
                        style={{
                          backgroundColor: "lightyellow",
                          padding: "15px",
                          borderRadius: "10px",
                          color: "maroon",
                          width: "30%",
                          textAlign: "center",
                          borderColor: "gray",
                        }}
                      >
                        <h5>{o.item2}</h5>
                      </div>
                    </div>
                    {showCharts && (
                      <div style={{ marginTop: "20px", marginLeft: "12%" }}>
                        <ReactApexChart
                          options={o.chartData.options}
                          series={o.chartData.series}
                          style={{ backgroundColor: "white" }}
                          type="bar"
                          height="250"
                        />
                      </div>
                    )}
                    {showGrids && (
                      <div style={{ marginTop: "20px" }}>
                        <ObjectiveRatingTable
                          scenario={scenario}
                          userScores={o.userScores}
                          usersPhotos={usersPhotos}
                        />
                      </div>
                    )}
                  </div>
                )}
              </Row>
            ))}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PwcGroupResponses
