import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import colors from "utils/colors"
import anychart from "anychart"
import { userService } from "services/user.service"

const FinancialChartModal = ({
  isOpen,
  toggleModal,
  scenarioId,
  objectiveId,
  alternativeList,
  optObjFin,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [isLoading, setIsLoading] = useState(false)
  const [years, setYears] = useState([])

  const chartTypes = ["None", "Column", "Line"]

  const [showOptions, setShowOptions] = useState([
    {
      label: "Cost",
      show: "cost",
      selectedOption: "None",
      options: [...chartTypes],
    },
    {
      label: "Benefit",
      show: "benefit",
      selectedOption: "None",
      options: [...chartTypes],
    },
    {
      label: "Return",
      show: "return",
      selectedOption: "None",
      options: [...chartTypes],
    },
    {
      label: "On Contract",
      show: "onContract",
      selectedOption: "None",
      options: [...chartTypes],
    },
    {
      label: "Paid",
      show: "paid",
      selectedOption: "None",
      options: [...chartTypes],
    },
    {
      label: "Pre-Project / Project / Post-Project",
      show: "preProjectProjectPostProject",
      selectedOption: "None",
      options: [...chartTypes, "Stacked"],
    },
    {
      label: "Revenue / Cost Reduction / Cost Avoidance",
      show: "revenueCostReductionCostAvoidance",
      selectedOption: "None",
      options: [...chartTypes, "Stacked"],
    },
  ])

  const [chartType, setChartType] = useState("Line Chart")
  const [showCumulative, setShowCumulative] = useState(false)
  const [fromYear, setFromYear] = useState(-1)
  const [toYear, setToYear] = useState(-1)

  const [chartResponseData, setChartResponseData] = useState(null)

  useEffect(() => {
    if (isOpen) {
      setShowOptions([
        {
          label: "Cost",
          show: "cost",
          selectedOption: "Line",
          options: [...chartTypes],
        },
        {
          label: "Benefit",
          show: "benefit",
          selectedOption: "Line",
          options: [...chartTypes],
        },
        {
          label: "Return",
          show: "return",
          selectedOption: "None",
          options: [...chartTypes],
        },
        {
          label: "On Contract",
          show: "onContract",
          selectedOption: "None",
          options: [...chartTypes],
        },
        {
          label: "Paid",
          show: "paid",
          selectedOption: "None",
          options: [...chartTypes],
        },
        {
          label: "Pre-Project / Project / Post-Project",
          show: "preProjectProjectPostProject",
          selectedOption: "None",
          options: [...chartTypes, "Stacked"],
        },
        {
          label: "Revenue / Cost Reduction / Cost Avoidance",
          show: "revenueCostReductionCostAvoidance",
          selectedOption: "None",
          options: [...chartTypes, "Stacked"],
        },
      ])
      loadData()
    }
  }, [isOpen])

  useEffect(() => {
    if (chartResponseData !== undefined && chartResponseData !== null) {
      renderChart(chartResponseData)
    }
  }, [chartResponseData, showOptions])

  const loadData = async () => {
    setIsLoading(true)

    let y = await api.getYears(currentUser.userID)
    setYears(y)

    let start = new Date().getFullYear()
    let end = new Date().getFullYear()
    if (y !== undefined && y.length >= 2) {
      start = y[0].listItemName
      end = y[y.length - 1].listItemName
      setFromYear(start)
      setToYear(end)
    }

    await loadChart(start, end, showCumulative)

    setIsLoading(false)
  }

  const getSelectedOptionByProp = prop => {
    return showOptions.find(x => x.show == prop)?.selectedOption
  }

  const loadChart = async (startYear, endYear, cumulative) => {
    setIsLoading(true)
    let chartResponse = await api.getAlternativesFinancialChart({
      scenarioID: scenarioId,
      alternativeList: alternativeList.join(","),
      finModelID: -1,
      preProject: true,
      postProject: true,
      project: true,
      revenue: true,
      costReduction: true,
      costAvoidance: true,
      cost: true,
      benefit: true,
      return: true,
      onContract: true,
      paid: true,
      chartType: chartType,
      cumulative: cumulative,
      startYear: startYear,
      endYear: endYear,
    })

    let dataArray1 = JSON.parse(chartResponse.chartArray.replaceAll("'", '"'))

    setChartResponseData(dataArray1)
    setIsLoading(false)
  }

  const renderChart = dataArray => {
    if (
      document.getElementById("chartdiv") !== undefined &&
      document.getElementById("chartdiv") !== null
    ) {
      document.getElementById("chartdiv").innerHTML = ""
    }

    var dataSet = anychart.data.set(dataArray)

    var seriesData_cost = dataSet.mapAs({ x: 0, value: 1 })
    var seriesData_benefit = dataSet.mapAs({ x: 0, value: 2 })
    var seriesData_return = dataSet.mapAs({ x: 0, value: 3 })
    var seriesData_contract = dataSet.mapAs({ x: 0, value: 4 })
    var seriesData_paid = dataSet.mapAs({ x: 0, value: 5 })

    var seriesData_preproject = dataSet.mapAs({ x: 0, value: 6 })
    var seriesData_project = dataSet.mapAs({ x: 0, value: 7 })
    var seriesData_postproject = dataSet.mapAs({ x: 0, value: 8 })

    var seriesData_revenue = dataSet.mapAs({ x: 0, value: 9 })
    var seriesData_costreduction = dataSet.mapAs({ x: 0, value: 10 })
    var seriesData_costavoidance = dataSet.mapAs({ x: 0, value: 11 })

    var chart = undefined
    var series_cost = undefined
    var series_benefit = undefined
    var series_return = undefined
    var series_contract = undefined
    var series_paid = undefined

    var series_preproject = undefined
    var series_project = undefined
    var series_postproject = undefined
    var series_revenue = undefined
    var series_costreduction = undefined
    var series_costavoidance = undefined

    let costOption = getSelectedOptionByProp("cost")
    let benefitOption = getSelectedOptionByProp("benefit")
    let returnOption = getSelectedOptionByProp("return")
    let onContractOption = getSelectedOptionByProp("onContract")
    let paidOption = getSelectedOptionByProp("paid")
    let preProjectProjectPostProjectOption = getSelectedOptionByProp(
      "preProjectProjectPostProject"
    )
    let revenueCostReductionCostAvoidanceOption = getSelectedOptionByProp(
      "revenueCostReductionCostAvoidance"
    )

    chart = anychart.column()
    // if (chartType == "Column Chart") {
    // } else {
    //   chart = anychart.line()
    // }

    if (costOption == "Column") {
      series_cost = chart.column(seriesData_cost)
    } else if (costOption == "Line") {
      series_cost = chart.line(seriesData_cost)
    }

    if (benefitOption == "Column") {
      series_benefit = chart.column(seriesData_benefit)
    } else if (benefitOption == "Line") {
      series_benefit = chart.line(seriesData_benefit)
    }

    if (returnOption == "Column") {
      series_return = chart.column(seriesData_return)
    } else if (returnOption == "Line") {
      series_return = chart.line(seriesData_return)
    }

    if (onContractOption == "Column") {
      series_contract = chart.column(seriesData_contract)
    } else if (onContractOption == "Line") {
      series_contract = chart.line(seriesData_contract)
    }

    if (paidOption == "Column") {
      series_paid = chart.column(seriesData_paid)
    } else if (paidOption == "Line") {
      series_paid = chart.line(seriesData_paid)
    }

    if (preProjectProjectPostProjectOption == "Column") {
      series_preproject = chart.column(seriesData_preproject)
      series_project = chart.column(seriesData_project)
      series_postproject = chart.column(seriesData_postproject)
    } else if (preProjectProjectPostProjectOption == "Line") {
      series_preproject = chart.line(seriesData_preproject)
      series_project = chart.line(seriesData_project)
      series_postproject = chart.line(seriesData_postproject)
    } else if (preProjectProjectPostProjectOption == "Stacked") {
      var yScale1 = anychart.scales.linear()
      yScale1.stackMode("value")
      chart.yAxis().scale(yScale1)

      series_preproject = chart.column(seriesData_preproject).yScale(yScale1)
      series_project = chart.column(seriesData_project).yScale(yScale1)
      series_postproject = chart.column(seriesData_postproject).yScale(yScale1)
    }

    if (revenueCostReductionCostAvoidanceOption == "Column") {
      series_revenue = chart.column(seriesData_revenue)
      series_costreduction = chart.column(seriesData_costreduction)
      series_costavoidance = chart.column(seriesData_costavoidance)
    } else if (revenueCostReductionCostAvoidanceOption == "Line") {
      series_revenue = chart.line(seriesData_revenue)
      series_costreduction = chart.line(seriesData_costreduction)
      series_costavoidance = chart.line(seriesData_costavoidance)
    } else if (revenueCostReductionCostAvoidanceOption == "Stacked") {
      var yScale2 = anychart.scales.linear()
      yScale2.stackMode("value")

      series_revenue = chart.column(seriesData_revenue).yScale(yScale2)
      series_costreduction = chart
        .column(seriesData_costreduction)
        .yScale(yScale2)
      series_costavoidance = chart
        .column(seriesData_costavoidance)
        .yScale(yScale2)
    }

    if (series_cost) {
      series_cost.name("Cost")
      series_cost.normal().stroke("green")
      series_cost.normal().fill("green")
    }
    if (series_benefit) {
      series_benefit.name("Benefit")
      series_benefit.normal().stroke("blue")
      series_benefit.normal().fill("blue")
    }
    if (series_return) {
      series_return.name("Return")
      series_return.normal().stroke("red")
      series_return.normal().fill("red")
    }
    if (series_contract) {
      series_contract.name("Contract")
      series_contract.normal().stroke("gold")
      series_contract.normal().fill("gold")
    }
    if (series_paid) {
      series_paid.name("Paid")
      series_paid.normal().stroke("purple")
      series_paid.normal().fill("purple")
    }
    if (series_preproject) {
      series_preproject.name("Pre-Project")
      series_preproject.normal().stroke("#006601")
      series_preproject.normal().fill("#006601")
    }
    if (series_project) {
      series_project.name("Project")
      series_project.normal().stroke("#339969")
      series_project.normal().fill("#339969")
    }
    if (series_postproject) {
      series_postproject.name("Post-Project")
      series_postproject.normal().stroke("#CDFCCF")
      series_postproject.normal().fill("#CDFCCF")
    }
    if (series_revenue) {
      series_revenue.name("Revenue")
      series_revenue.normal().stroke("#010362")
      series_revenue.normal().fill("#010362")
    }
    if (series_costreduction) {
      series_costreduction.name("Cost Reduction")
      series_costreduction.normal().stroke("#0098CD")
      series_costreduction.normal().fill("#0098CD")
    }
    if (series_costavoidance) {
      series_costavoidance.name("Cost Avoidance")
      series_costavoidance.normal().stroke("#CEEAFF")
      series_costavoidance.normal().fill("#CEEAFF")
    }

    var yLabels = chart.yAxis().labels()
    yLabels.format(function () {
      return "$" + anychart.format.number(this.value, 0, ".", ",")
    })
    chart
      .tooltip()
      .title(false)
      .separator(false)
      .format(function () {
        return "$" + anychart.format.number(this.value, 0, ".", ",")
      })

    if (showCumulative) {
      chart.title("Financials Cumulative")
    } else {
      chart.title("Financials By Year")
    }

    var line = chart.lineMarker()
    line.value(0)
    line.stroke("2 gray")

    chart.legend(true)

    chart.credits().enabled(false)

    chart.title().fontFamily("Inter")
    chart.legend().fontFamily("Inter")

    chart.container("chartdiv")
    chart.draw()
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      fullscreen={true}
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Financial Chart
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div style={{ height: "20%" }}>
          <Row>
            <Col>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  // width: "50%",
                }}
              >
                <div
                  className="mb-3"
                  style={{ width: "200px", marginRight: "20px" }}
                >
                  <Label className="form-label">Year Start</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={fromYear || -1}
                    onChange={e => {
                      setFromYear(e.target.value)
                      loadChart(e.target.value, toYear, showCumulative)
                    }}
                  >
                    <option value="-1">Select year...</option>
                    {years.map((x, idx) => (
                      <option key={idx} value={x.listItemName}>
                        {x.listItemName}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  className="mb-3"
                  style={{
                    width: "200px",
                    marginLeft: "",
                    marginRight: "20px",
                  }}
                >
                  <Label className="form-label">Year End</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={toYear || -1}
                    onChange={e => {
                      setToYear(e.target.value)
                      loadChart(fromYear, e.target.value, showCumulative)
                    }}
                  >
                    <option value="-1">Select year...</option>
                    {years.map((x, idx) => (
                      <option key={idx} value={x.listItemName}>
                        {x.listItemName}
                      </option>
                    ))}
                  </select>
                </div>
                <Input
                  id="show-Cumulative"
                  type="checkbox"
                  checked={showCumulative}
                  onClick={() => {
                    let newVal = !showCumulative
                    setShowCumulative(newVal)
                    loadChart(fromYear, toYear, newVal)
                  }}
                  style={{
                    marginBottom: 0,
                    marginRight: "10px",
                    marginLeft: "50px",
                  }}
                />
                <Label htmlFor="include-Cumulative">Show Cumulative</Label>
              </div>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {showOptions.map((s, idx) => (
                  <div
                    key={idx}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <label className="form-label">{s.label}</label>
                    <select
                      style={{
                        width: "100px",
                        marginLeft: "5px",
                        marginRight: "20px",
                      }}
                      className="form-control form-select select2 mb-xxl-0"
                      value={s.selectedOption}
                      onChange={e => {
                        let copy = [...showOptions]
                        let item = copy.find(x => x.show == s.show)
                        item.selectedOption = e.target.value
                        setShowOptions(copy)
                      }}
                    >
                      {s.options.map((o, i) => (
                        <option key={i} value={o}>
                          {o}
                        </option>
                      ))}
                    </select>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="divider"></div>
            </Col>
          </Row>
        </div>
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        <div
          id="chartdiv"
          style={{
            width: "100%",
            minHeight: "300px",
            height: "80%",
          }}
        ></div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
      </div>
    </Modal>
  )
}

export default FinancialChartModal
