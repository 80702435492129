import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Col, Row } from "reactstrap"
import api from "services/api.service"
import colors from "utils/colors"
import { currencyFormatter } from "utils/formatters"
import EditOptModelModal from "./Modals/EditOptModelModal"

const OptModelSection = ({ activeTab, scenarioId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [optModels, setOptModels] = useState([])
  const [optModelId, setOptModelId] = useState(-1)

  const [showOptModelModal, setShowOptModelModal] = useState(false)
  const toggleEditOptModelModal = () => setShowOptModelModal(!showOptModelModal)
  useEffect(() => {
    if (activeTab == "Optimization Scenarios") {
      loadData()
    }
  }, [activeTab])

  const loadData = async () => {
    setIsLoading(true)
    let om = await api.getOptModels(scenarioId)
    setOptModels(om)
    setIsLoading(false)
  }

  const editOptModel = optModelId => {
    setOptModelId(optModelId)
    toggleEditOptModelModal()
  }

  return (
    <>
      <Row className="mb-3">
        <Col>
          <button className="btn btn-primary" onClick={() => editOptModel(-1)}>
            Add Scenario
          </button>
        </Col>
      </Row>
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      <table
        className="table table-bordered low-padding-table"
        style={{ backgroundColor: "white" }}
      >
        <thead>
          <tr>
            <th>Scenario</th>
            <th>Criteria Scenario</th>
            <th>Financial Scenario</th>
            <th>Scope</th>
            <th>Optimize On</th>
            <th>Optimization Type</th>
            <th>Cost Variable</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {optModels.map((o, idx) => (
            <tr key={idx}>
              <td>
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                    editOptModel(o.optModelID)
                  }}
                >
                  {o.optModelName}
                </a>
              </td>
              <td>{o.objModelName}</td>
              <td>{o.finModelName}</td>
              <td>{o.scope}</td>
              <td>{o.optimizeOn}</td>
              <td>{o.minMax}</td>
              <td>{o.costVariableName}</td>
              <td className="text-right">
                {currencyFormatter.format(o.costConstraint)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <EditOptModelModal
        isOpen={showOptModelModal}
        toggleModal={toggleEditOptModelModal}
        optModelId={optModelId}
        reloadData={loadData}
        scenarioId={scenarioId}
      />
    </>
  )
}

export default OptModelSection
