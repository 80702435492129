import React, { useEffect, useRef, useState } from "react"
import Loader from "react-loaders"
import {
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  Row,
  Alert,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import { ReactTabulator } from "react-tabulator"
import colors from "utils/colors"
import { sortData } from "utils/sort"

const EditCalculatedFieldModal = ({
  isOpen,
  toggleModal,
  calculatedFieldToEdit,
  setCalculatedFieldToEdit,
  reloadData,
}) => {
  const currentUser = userService.getLoggedInUser()
  const selectedFieldType = 10
  const [isLoading, setIsLoading] = useState(false)

  const [search, setSearch] = useState("")
  const [allSelected, setAllSelected] = useState(false)

  const [templates, setTemplates] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState(0)
  const [years, setYears] = useState([])
  const [selectedYear, setSelectedYear] = useState(-1)
  const [makeModes, setMakeModes] = useState([])
  const [selectedMakeMode, setSelectedMakeMode] = useState(-1)

  const [allFields, setAllFields] = useState([])

  const [hasNameError, setHasNameError] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setAllFields([])
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    setIsLoading(true)

    setSelectedMakeMode(-1)
    setSelectedYear(-1)

    let temp = await api.getList(currentUser.userID, "CostPoolTemplates")
    setTemplates(temp)
    if (temp.length > 0) {
      setSelectedTemplate(temp[0].listItemID)
    }

    let mm = await api.getList(currentUser.userID, "ViewItemForecastMode")
    setMakeModes(mm)
    // if (mm.length > 0) {
    //   setSelectedMakeMode(mm[0].listItemName)
    // }

    let y = await api.getYears(currentUser.userID)
    setYears(y)
    // if (y.length > 0) {
    //   setSelectedYear(y[0].listItemName)
    // }

    await loadItems(temp.length > 0 ? temp[0].listItemID : 0)

    setIsLoading(false)
  }

  const loadItems = async template => {
    setSearch("")
    let cp = await api.getCostPools(currentUser.enterpriseID, template)
    let summaryCp = await api.getList(currentUser.userID, "SummaryCostPool")

    let list = [
      ...cp.map(f => {
        return {
          fieldName: f.costPoolType + " - " + f.costPoolName,
          itemID: f.costPoolID,
          type: "CostPool",
        }
      }),
      ...summaryCp.map(s => {
        return {
          fieldName: s.listItemName,
          itemID: s.listItemID,
          type: "CostPool",
        }
      }),
    ]
    setAllFields(sortData(list, "fieldName"))
  }

  const save = async () => {
    if (
      calculatedFieldToEdit.calcFieldName == undefined ||
      calculatedFieldToEdit.calcFieldName == null ||
      calculatedFieldToEdit.calcFieldName == ""
    ) {
      setHasNameError(true)
      return
    }
    setHasNameError(false)
    if (calculatedFieldToEdit.calcFieldID > -1) {
      await api.updateCalcField(currentUser.userID, calculatedFieldToEdit)
      toggleModal()
      reloadData()
    } else {
      await api.createCalcField(currentUser.userID, calculatedFieldToEdit)
      toggleModal()
      reloadData()
    }
  }

  const deleteView = async () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this field?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteCalcField(calculatedFieldToEdit.calcFieldID)
          toggleModal()
          reloadData()
        }
      })
  }

  const changeCalculatedFieldProp = (prop, value) => {
    setCalculatedFieldToEdit({ ...calculatedFieldToEdit, [prop]: value })
  }

  const addSelectedItems = addSubtract => {
    let selected = allFields.filter(x => x.selected)
    if (selected.length > 0) {
      let reportFields = [...calculatedFieldToEdit.calcFieldItems]

      selected.forEach(s => {
        s.selected = false
        reportFields.push({
          ...s,
          mode: selectedMakeMode,
          itemYearID: selectedMakeMode == "Total" ? 0 : selectedYear,
          calcFieldID: s.calcFieldID,
          addSubtract: addSubtract,
          bcFieldID: s.itemID,
          calcFieldItemName: s.fieldName,
        })
      })

      setCalculatedFieldToEdit({
        ...calculatedFieldToEdit,
        calcFieldItems: reportFields,
      })
    }
  }
  const removeSelectedItems = () => {
    let selected = [
      ...calculatedFieldToEdit.calcFieldItems.filter(x => x.selected),
    ]

    if (selected.length > 0) {
      let reportFields = [...calculatedFieldToEdit.calcFieldItems]
      setCalculatedFieldToEdit({
        ...calculatedFieldToEdit,
        calcFieldItems: reportFields.filter(
          d =>
            selected.find(
              x =>
                x.bcFieldID == d.bcFieldID &&
                x.mode == d.mode &&
                x.itemYearID == d.itemYearID
            ) == undefined
        ),
      })
    }
  }

  const canAddItems =
    selectedMakeMode == "Total" ||
    (selectedMakeMode != "-1" && selectedYear != -1)

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      //fullscreen={true}
      size="xl"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5>Calculated Field</h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {calculatedFieldToEdit != null && (
        <div className="modal-body">
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          {!isLoading && (
            <>
              <Row className="mb-3">
                <Col md="12">
                  <div className="mb-3">
                    <Label className="form-label">Name *</Label>
                    <Input
                      name="calcname"
                      type="text"
                      onChange={e =>
                        changeCalculatedFieldProp(
                          "calcFieldName",
                          e.target.value
                        )
                      }
                      value={calculatedFieldToEdit.calcFieldName}
                    />
                    {hasNameError && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        Name is required
                      </div>
                    )}
                  </div>
                  <div className="divider"></div>
                  <Alert
                    id="helpView"
                    variant="light"
                    className="always-enabled"
                    style={{ marginBottom: "10px", paddingBottom: "0px" }}
                  >
                    <div>
                      <b>Add additions and subtractions to the calculation</b>
                      <ol>
                        <li>
                          Select the Type of Field to add and then select from
                          the list.
                        </li>
                        <li>
                          If required, select the mode or option for the value
                          from that field and the year.
                        </li>
                        <li>
                          {" "}
                          Click add to "Add As Addition" if you want the field
                          Added to the result.
                        </li>
                        <li>
                          {" "}
                          Click add to "Add As Subtraction" if you want the
                          field Subtracted from the result.
                        </li>
                        <li>Click the "Save" button.</li>
                      </ol>
                    </div>
                  </Alert>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md="6">
                  <div className="mb-3">
                    <Label className="form-label">Field Type</Label>
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      disabled={true}
                    >
                      <option value="1">Forecast Data</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Template</Label>
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      value={selectedTemplate}
                      onChange={e => {
                        setSelectedTemplate(e.target.value)
                        loadItems(e.target.value)
                      }}
                    >
                      {templates.map((x, idx) => (
                        <option key={idx} value={x.listItemID}>
                          {x.listItemName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Mode</Label>
                    <select
                      className="form-control form-select select2 mb-xxl-0"
                      value={selectedMakeMode}
                      onChange={e => {
                        setSelectedMakeMode(e.target.value)
                        setSelectedYear(-1)
                      }}
                    >
                      <option value="-1">Select...</option>
                      {makeModes.map((x, idx) => (
                        <option key={idx} value={x.listItemName}>
                          {x.listItemName}
                        </option>
                      ))}
                    </select>
                  </div>
                  {selectedMakeMode !== "Total" && (
                    <div className="mb-3">
                      <Label className="form-label">Year</Label>
                      <select
                        className="form-control form-select select2 mb-xxl-0"
                        value={selectedYear}
                        onChange={e => {
                          setSelectedYear(e.target.value)
                        }}
                      >
                        <option value="0">Select...</option>

                        {selectedMakeMode == "Year Number" ||
                        selectedMakeMode == "Cumulative Through Year Number" ? (
                          <>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((x, idx) => (
                              <option key={idx} value={x}>
                                {x}
                              </option>
                            ))}
                          </>
                        ) : (
                          <>
                            {years.map((x, idx) => (
                              <option key={idx} value={x.listItemName}>
                                {x.listItemName}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                  )}
                </Col>
                <Col md="6">
                  <InputGroup>
                    <InputGroupText>
                      <i className="fas fa-search"></i>
                    </InputGroupText>
                    <Input
                      placeholder="Search ..."
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                    />
                  </InputGroup>
                  <div style={{ overflowX: "auto", height: "350px" }}>
                    <table
                      className="table table-bordered low-padding-table"
                      style={{ backgroundColor: "white" }}
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "5%" }}>
                            <Input
                              type="checkbox"
                              checked={allSelected}
                              onClick={() => {
                                let copy = [...allFields].map(x => {
                                  if (
                                    search == null ||
                                    search == "" ||
                                    x.fieldName
                                      .toLowerCase()
                                      .indexOf(search.toLowerCase()) > -1
                                  ) {
                                    x.selected = !allSelected
                                  } else {
                                    x.selected = false
                                  }
                                  return x
                                })
                                setAllSelected(!allSelected)
                                setAllFields(copy)
                              }}
                            />
                          </th>
                          <th style={{ width: "95%" }}>Field</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allFields
                          .filter(
                            x =>
                              search == null ||
                              search == "" ||
                              x.fieldName
                                .toLowerCase()
                                .indexOf(search.toLowerCase()) > -1
                          )
                          .map((c, idx) => {
                            return (
                              <tr key={idx}>
                                <td>
                                  <Input
                                    type="checkbox"
                                    checked={c.selected}
                                    onClick={() => {
                                      let copy = [...allFields]
                                      let item = copy.find(
                                        x => x.fieldName == c.fieldName
                                      )
                                      item.selected = !item.selected
                                      setAllFields(copy)
                                    }}
                                  />
                                </td>
                                <td>{c.fieldName}</td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md="12">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <button
                      className="btn btn-primary"
                      disabled={!canAddItems}
                      onClick={() => addSelectedItems("add")}
                    >
                      <i className="fas fa-plus"></i> Add as Addition
                    </button>
                    &nbsp;&nbsp;
                    <button
                      className="btn btn-primary"
                      disabled={!canAddItems}
                      onClick={() => addSelectedItems("subtract")}
                    >
                      <i className="fas fa-plus"></i> Add as Subtraction
                    </button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col id="dashboard-tabulator-style">
                  <div style={{ overflowX: "auto", maxHeight: "180px" }}>
                    <table
                      className="table table-bordered low-padding-table"
                      style={{ backgroundColor: "white" }}
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "5%" }}></th>
                          <th style={{ width: "5%" }}>+/-</th>
                          <th style={{ width: "90%" }}>Field</th>
                        </tr>
                      </thead>
                      <tbody>
                        {calculatedFieldToEdit.calcFieldItems
                          // .filter(x => x.addSubtract == "add")
                          .map((c, idx) => {
                            return (
                              <tr key={idx}>
                                <td>
                                  <Input
                                    type="checkbox"
                                    checked={c.selected}
                                    onClick={() => {
                                      let copy = [
                                        ...calculatedFieldToEdit.calcFieldItems,
                                      ]

                                      let item = copy.find(
                                        x =>
                                          // x.calcFieldItemName ==
                                          // c.calcFieldItemName
                                          x.bcFieldID == c.bcFieldID &&
                                          x.mode == c.mode &&
                                          x.itemYearID == c.itemYearID
                                      )
                                      item.selected = !item.selected
                                      setCalculatedFieldToEdit({
                                        ...calculatedFieldToEdit,
                                        calcFieldItems: copy,
                                      })
                                    }}
                                  />
                                </td>
                                <td>
                                  {c.addSubtract == "add" ? (
                                    <i
                                      className="fas fa-plus"
                                      style={{ color: "green" }}
                                    ></i>
                                  ) : (
                                    <i
                                      className="fas fa-minus"
                                      style={{ color: "red" }}
                                    ></i>
                                  )}
                                </td>
                                <td>
                                  {c.calcFieldItemName}{" "}
                                  {c.mode !== undefined &&
                                    c.mode !== null &&
                                    `(${c.mode})`}{" "}
                                  {c.itemYearID !== undefined && c.itemYearID > 0 && c.itemYearID}
                                </td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      className="btn btn-outline-danger"
                      style={{ marginTop: "15px" }}
                      onClick={removeSelectedItems}
                    >
                      <i className="fas fa-times"></i> Remove Field(s)
                    </button>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>
      )}
      <div
        className="modal-footer"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <button
            onClick={() => {
              toggleModal()
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          {calculatedFieldToEdit != null &&
            calculatedFieldToEdit.calcFieldID > -1 && (
              <button
                onClick={() => {
                  deleteView()
                }}
                className="btn btn-danger save-user"
                data-dismiss="modal"
              >
                Delete
              </button>
            )}
        </div>
        <div>
          {calculatedFieldToEdit != null && (
            <>
              <button className="btn btn-primary save-user" onClick={save}>
                Save
              </button>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default EditCalculatedFieldModal
