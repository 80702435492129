import CustomTable from "components/custom/customTable"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const LogModal = ({ isOpen, toggleModal, enterpriseId, alternative }) => {
  const currentUser = userService.getLoggedInUser()

  const [itemType, setItemType] = useState("All")
  const [user, setUser] = useState(0)
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DDThh:mm:ss")
  )
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DDThh:mm:ss")
  )

  const [itemTypeOptions, setItemTypeOptions] = useState([])
  const [userOptions, setUserOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [logs, setLogs] = useState([])

  useEffect(() => {
    if (isOpen) {
      setItemType("All")
      setUser(0)
      setItemTypeOptions([])
      setUserOptions([])
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    var users = await api.getUsersByEnterprise(enterpriseId)
    setUserOptions(
      users.map(x => {
        return {
          listItemID: x.userID,
          listItemName: x.firstName + " " + x.lastName,
        }
      })
    )
    var itemTypes = await api.getList(
      currentUser.userID,
      "AlternativeLogItemType"
    )
    setItemTypeOptions(itemTypes)
  }

  const save = async () => {
    setIsLoading(true)
    let logs = await api.getLog(currentUser.userID, {
      startDate: moment(startDate).format("YYYY-MM-DDThh:mm:ss"),
      endDate: moment(endDate).format("YYYY-MM-DDThh:mm:ss"),
      logItemType: itemType,
      userID: user,
      scope: "Alternative",
      itemID: alternative.alternativeID,
    })
    setLogs(logs)
    setIsLoading(false)
  }

  const columns = [
    {
      title: "User",
      field: "userName",
      thStyle: { width: "15%" },
      sort: true,
    },
    {
      title: "Date",
      field: "logDT",
      thStyle: { width: "10%" },
      sort: true,
      formatter: row => {
        return row.logDT == null ? "" : moment(row.logDT).format("YYYY-MM-DD")
      },
    },
    {
      title: "Type",
      field: "itemType",
      thStyle: { width: "10%" },
      sort: true,
    },
    {
      title: "Log Entry",
      field: "logInfo",
      thStyle: { textAlign: "center", width: "65%" },
      formatter: "stringAsHtml",
      sort: true,
    },
  ]

  return (
    <Modal
      isOpen={isOpen}
      size="xl"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Logs
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div
          className="mb-3"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="mb-3">
              <Label className="form-label">Start Date</Label>
              <Input
                name="startDate"
                type="date"
                onChange={e => {
                  setStartDate(
                    moment(e.target.value, "YYYY-MM-DD").format(
                      "YYYY-MM-DDThh:mm:ss"
                    )
                  )
                }}
                value={moment(startDate).format("YYYY-MM-DD")}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">User</Label>
              <select
                style={{ width: "230px" }}
                className="form-control form-select select2 mb-xxl-0"
                value={user}
                onChange={e => {
                  setUser(e.target.value)
                }}
              >
                <option value="0">All Users</option>
                {userOptions.map((s, i) => (
                  <option key={i} value={s.listItemID}>
                    {s.listItemName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="mb-3">
              <Label className="form-label">End Date</Label>
              <Input
                name="endDate"
                type="date"
                onChange={e => {
                  setEndDate(
                    moment(e.target.value, "YYYY-MM-DD").format(
                      "YYYY-MM-DDThh:mm:ss"
                    )
                  )
                }}
                value={moment(endDate).format("YYYY-MM-DD")}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Item Type</Label>
              <select
                style={{ width: "230px" }}
                className="form-control form-select select2 mb-xxl-0"
                value={itemType}
                onChange={e => {
                  setItemType(e.target.value)
                }}
              >
                {itemTypeOptions.map((s, i) => (
                  <option key={i} value={s.listItemID}>
                    {s.listItemName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={save}
          >
            Submit
          </button>
        </div>
        <div className="mb-3">
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          {!isLoading && (
            <CustomTable columns={columns} items={logs} setItems={setLogs} />
          )}
        </div>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
      </div>
    </Modal>
  )
}

export default LogModal
