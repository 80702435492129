import React from "react"
import { Alert } from "reactstrap"

export const ObjFinAlert = ({ optObjFin }) => {
  if (
    optObjFin == undefined ||
    optObjFin == null ||
    (optObjFin.obj == null && optObjFin.fin == null)
  ) {
    return null
  } else
    return (
      <Alert color="info">
        {optObjFin.obj != undefined && optObjFin.obj != null && (
          <div>Objective Model: {optObjFin.obj.objModelName}</div>
        )}
        {optObjFin.obj != undefined && optObjFin.fin != null && (
          <div>Financial Model: {optObjFin.fin.finModelName}</div>
        )}
      </Alert>
    )
}
