import React from "react"
import { Modal } from "reactstrap"
import Dashboard from "../Dashboard/index"

const ViewDashboardModal = ({ isOpen, toggleModal, props, dashboardId }) => {
  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      fullscreen={true}
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Dashboard
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {isOpen && <Dashboard {...props} dashboardId={dashboardId} hideManageButton={true} />}
      </div>
      <div
        className="modal-footer"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <button
            onClick={() => {
              toggleModal()
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
        <div></div>
      </div>
    </Modal>
  )
}

export default ViewDashboardModal
