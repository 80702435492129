import React, { useEffect, useState } from "react"
import { Button, Col, Container, Row } from "reactstrap"
import { userService } from "services/user.service"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { changeNavbarParams, loadPageItem } from "store/actions"
import api from "services/api.service"
import Loader from "react-loaders"
import colors from "utils/colors"
import NameAvatar from "components/custom/nameAvatar"
import EditDashboardModal from "../ManageDashboards/EditDashboardModal"
import useModal from "hooks/useModalHook"
import { enrichWithPhotoBase64 } from "utils/avatar"

const DashboardList = props => {
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [dashboards, setDashboards] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { show: showEditModal, toggle: toggleEditModal } = useModal()
  const [dashboardToEdit, setDashboardToEdit] = useState(null)

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: 0,
        alternativeId: 0,
        viewName: "dashboards",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: 0,
        viewName: "dashboards",
      })
    )
    loadData()
  }, [])

  const loadData = async () => {
    setIsLoading(true)
    let d = await api.getDashboards(currentUser.userID, true)
    d = await enrichWithPhotoBase64(d, "userID")
    setDashboards(d)
    setIsLoading(false)
  }

  const goToDashboard = dashboard => {
    props.history.push(`/dashboard/${dashboard.dashboardID}`)
  }

  const createDashboard = () => {
    setDashboardToEdit({
      dashboardID: -1,
      inactive: false,
      dashboardName: "",
      layout: "1 Column",
      description: "",
      dashboardItems: [],
      users: [],
    })
    toggleEditModal()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          {currentUser.roleID == 3 && (
            <div className="helpText">
              You have dashboard access only. For full system access, please
              contact your manager.{" "}
            </div>
          )}

          {currentUser.roleID != 2 && currentUser.roleID != 3 && (
            <Button color="primary" onClick={createDashboard}>
              <i className="fas fa-plus"></i> Add New Dashboard
            </Button>
          )}
          <Row style={{ marginBottom: "10px" }}>
            <Col>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              <Row>
                {!isLoading &&
                  dashboards.map((d, idx) => (
                    <DashboardItem
                      key={idx}
                      dashboard={d}
                      goToDashboard={goToDashboard}
                    />
                  ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <EditDashboardModal
        isOpen={showEditModal}
        toggleModal={toggleEditModal}
        dashboardToEdit={dashboardToEdit}
        setDashboardToEdit={setDashboardToEdit}
        reloadData={loadData}
        props={props}
      />
    </React.Fragment>
  )
}

const DashboardItem = ({ dashboard, goToDashboard }) => {
  return (
    <Col style={{ maxWidth: "450px" }}>
      <div
        style={{
          border: "1px solid lightgray",
          backgroundColor: "white",
          marginTop: "20px",
          marginRight: "10px",
          height: "225px",
          marginLeft: "10px",
          borderRadius: "8px",
          width: "400px",
          //boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
          cursor: "pointer",
        }}
        onClick={() => goToDashboard(dashboard)}
      >
        <div
          style={{
            padding: "10px",
            backgroundColor: "rgb(18, 52, 86)",
            color: "white",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
        >
          {dashboard.dashboardName}
        </div>
        <div
          style={{
            padding: "5px",
            fontWeight: 600,
            width: "100%",
            whiteSpace: "nowrap",
            display: "inline-block",
          }}
        >
          <table>
            <tr>
              <td>Owned by:&nbsp;</td>
              <td>
                <NameAvatar
                  fullName={dashboard.ownerUser}
                  showName={true}
                  base64Image={dashboard.photoBase64}
                />
              </td>
            </tr>
          </table>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              width: "68%",
              margin: "auto",
              marginTop: "5px",
              marginBottom: "10px",
              maxHeight: "120px",
              overflowY: "auto",
            }}
          >
            {dashboard.description}
          </div>
          <div
            style={{
              width: "25%",
              margin: "auto",
              marginTop: "5px",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                color: "gray",
              }}
            >
              <i className="mdi mdi-chart-bar" style={{ fontSize: "30px" }}></i>
              <i className="mdi mdi-chart-pie" style={{ fontSize: "30px" }}></i>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                color: "gray",
              }}
            >
              {dashboard.hasInfoSection && (
                <i
                  className="mdi mdi-text-box-outline"
                  style={{ fontSize: "30px" }}
                ></i>
              )}
              {dashboard.hasRoadmap && (
                <i
                  className="mdi mdi-chart-timeline"
                  style={{ fontSize: "30px" }}
                ></i>
              )}
            </div>
          </div>
        </div>
      </div>
    </Col>
  )
}

export default DashboardList
