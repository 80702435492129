import CustomTable from "components/custom/customTable"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import { currencyFormatter } from "utils/formatters"

const ResourceConstraints = ({
  activeTab,
  scenario,
  triggerSave,
  setTriggerSave,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [isLoading, setIsLoading] = useState(false)
  const [resourceGroups, setResourceGroups] = useState([])
  const [resourceTypes, setResourceTypes] = useState([])
  const [optModel, setOptModel] = useState(null)

  const [sortBy, setSortBy] = useState("")
  const [sortDirection, setSortDirection] = useState("asc")

  useEffect(() => {
    if (activeTab == "ResourceConstraints") {
      loadData()
    }
  }, [activeTab])

  useEffect(() => {
    if (triggerSave) {
      save()
    }
  }, [triggerSave])

  const loadData = async () => {
    setIsLoading(true)
    await loadResourceGroups()
    let om = await api.getOptModel(scenario.currentOptModelID)
    setOptModel(om)
    let rt = await api.getScenarioList(scenario.scenarioID, "OptResourceType")
    setResourceTypes(rt)
    setIsLoading(false)
  }

  const loadResourceGroups = async () => {
    let rg = await api.getOptModelResourceGroups(scenario.currentOptModelID)
    setResourceGroups(rg)
  }

  const changeResourceGroupProp = (resourceGroup, prop, value) => {
    let copy = [...resourceGroups]
    let item = copy.find(
      x => x.resourceGroupID == resourceGroup.resourceGroupID
    )
    item[prop] = value
    item.isDirty = true
    showChangesToast()
    setResourceGroups(copy)
  }

  const save = async () => {
    setTriggerSave(false)

    let itemsToUpdate = resourceGroups.filter(x => x.isDirty)
    if (itemsToUpdate.length > 0) {
      let itemsToUpdateTasks = itemsToUpdate.map(x => async () => {
        return await api.updateOptModelResourceGroup(currentUser.userID, {
          ...x,
          optModelID: scenario.currentOptModelID,
        })
      })
      await Promise.all(itemsToUpdateTasks.map(t => t()))
    }

    toast.success("Changes saved successfuly")
  }

  const showChangesToast = () => {
    toast.success(
      <div>
        <p className="mb-0">
          You have unsaved changes, don't forget to save them.
        </p>
        <button
          className="btn btn-primary"
          style={{ width: "100%" }}
          onClick={() => {
            document.getElementById("saveOptimizations").click()
          }}
        >
          <i className="fas fa-save"></i> Save
        </button>
      </div>,
      {
        toastId: "unsavedChanges",
        autoClose: false,
      }
    )
  }

  const years = Array.from(Array(20).keys()).map(
    k => new Date().getFullYear() - 5 + k
  )

  const months = [
    { text: "January", value: 1 },
    { text: "February", value: 2 },
    { text: "March", value: 3 },
    { text: "April", value: 4 },
    { text: "May", value: 5 },
    { text: "June", value: 6 },
    { text: "July", value: 7 },
    { text: "August", value: 8 },
    { text: "September", value: 9 },
    { text: "October", value: 10 },
    { text: "November", value: 11 },
    { text: "December", value: 12 },
  ]

  const changeOptModelProp = async (prop, val) => {
    let updatedOptModel = { ...optModel, [prop]: val }
    setOptModel(updatedOptModel)
    if (updatedOptModel.resourceType != "-1") {
      await api.updateOptModel(currentUser.userID, { ...updatedOptModel })
      await loadResourceGroups()
    }
  }

  const getSortIcon = prop => {
    let className = ""
    if (sortBy == prop) {
      className =
        sortDirection == "asc" ? "fas fa-chevron-down" : "fas fa-chevron-up"
    } else {
      className = "fas fa-chevron-down"
    }
    return (
      <i
        className={className}
        style={{ padding: "0px", marginLeft: "5px" }}
      ></i>
    )
  }

  const handleSort = prop => {
    let direction = ""
    if (sortBy == prop) {
      direction = sortDirection == "asc" ? "desc" : "asc"
    } else {
      direction = "asc"
      setSortBy(prop)
    }
    setSortDirection(direction)

    setResourceGroups(
      direction == "asc"
        ? sortAsc(resourceGroups, prop)
        : sortDesc(resourceGroups, prop)
    )
  }

  const sortAsc = (data, prop) => {
    function compare(a, b) {
      if (a[prop] < b[prop]) {
        return -1
      }
      if (a[prop] > b[prop]) {
        return 1
      }
      return 0
    }
    let ret = [...data].sort(compare)
    return ret
  }
  const sortDesc = (data, prop) => {
    function compare(a, b) {
      if (b[prop] < a[prop]) {
        return -1
      }
      if (b[prop] > a[prop]) {
        return 1
      }
      return 0
    }
    let ret = [...data].sort(compare)
    return ret
  }

  const columns = [
    {
      title: "Resource",
      field: "resourceGroupAbb",
      thStyle: { width: "20%" },
      sort: true,
    },
    {
      title: "Minimum",
      field: "minAmount",
      thStyle: { width: "12%" },
      sort: true,
      formatter: row => {
        return (
          <CurrencyInput
            className="form-control"
            value={row.minAmount}
            onValueChange={(value, name, values) => {
              changeResourceGroupProp(row, "minAmount", values.value)
            }}
            placeholder=""
            prefix={""}
          />
        )
      },
    },
    {
      title: "Maximum",
      field: "maxAmount",
      thStyle: { width: "12%" },
      sort: true,
      formatter: row => {
        return (
          <CurrencyInput
            className="form-control"
            value={row.maxAmount}
            onValueChange={(value, name, values) => {
              changeResourceGroupProp(row, "maxAmount", values.value)
            }}
            placeholder=""
            prefix={""}
          />
        )
      },
    },
    {
      title: "Total Required",
      field: "totalResources",
      thStyle: { width: "12%" },
      sort: true,
      formatter: row => row.totalResources.toLocaleString(),
    },
    {
      title: "Selected",
      field: "selectedAmount",
      thStyle: { width: "12%" },
      sort: true,
      formatter: row => row.selectedAmount.toLocaleString(),
    },
    {
      title: "% of Max",
      field: "percentOfMax",
      thStyle: { width: "10%" },
      sort: true,
    },
    {
      title: "% of Total Required",
      field: "percentOfTotal",
      thStyle: { width: "10%" },
      sort: true,
    },
    {
      title: "Active",
      field: "selectedAmount",
      thStyle: { width: "10%" },
      sort: true,
      formatter: row => (
        <Input
          type="checkbox"
          checked={row.active}
          onClick={() => {
            changeResourceGroupProp(row, "active", !row.active)
          }}
        />
      ),
    },
  ]

  return (
    <React.Fragment>
      <Row>
        <Col md="6">
          {optModel != null && (
            <>
              <div className="mb-3">
                <Label className="form-label">Resource Type</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={optModel.resourceType}
                  onChange={e => {
                    changeOptModelProp("resourceType", e.target.value)
                  }}
                >
                  <option value="-1">Select...</option>
                  {resourceTypes.map((x, idx) => (
                    <option key={idx} value={x.listItemName}>
                      {x.listItemName}
                    </option>
                  ))}
                </select>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="mb-3"
                  style={{ width: "47%", marginRight: "3%" }}
                >
                  <Label className="form-label">Year Start</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={optModel.resourceStartYear}
                    onChange={e => {
                      changeOptModelProp("resourceStartYear", e.target.value)
                    }}
                  >
                    {years.map((x, idx) => (
                      <option key={idx} value={x}>
                        {x}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  className="mb-3"
                  style={{ width: "47%", marginleft: "3%" }}
                >
                  <Label className="form-label">Year End</Label>
                  <select
                    className="form-control form-select select2 mb-xxl-0"
                    value={optModel.resourceEndYear}
                    onChange={e => {
                      changeOptModelProp("resourceEndYear", e.target.value)
                    }}
                  >
                    {years.map((x, idx) => (
                      <option key={idx} value={x}>
                        {x}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {optModel.resourceType != "-1" &&
                optModel.resourceType.toLowerCase() !== "estimate" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="mb-3"
                      style={{ width: "47%", marginRight: "3%" }}
                    >
                      <Label className="form-label">Month Start</Label>
                      <select
                        className="form-control form-select select2 mb-xxl-0"
                        value={optModel.resourceStartMonth}
                        onChange={e => {
                          changeOptModelProp(
                            "resourceStartMonth",
                            e.target.value
                          )
                        }}
                      >
                        {months.map((x, idx) => (
                          <option key={idx} value={x.value}>
                            {x.text}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div
                      className="mb-3"
                      style={{ width: "47%", marginLeft: "3%" }}
                    >
                      <Label className="form-label">Month End</Label>
                      <select
                        className="form-control form-select select2 mb-xxl-0"
                        value={optModel.resourceEndMonth}
                        onChange={e => {
                          changeOptModelProp("resourceEndMonth", e.target.value)
                        }}
                      >
                        {months.map((x, idx) => (
                          <option key={idx} value={x.value}>
                            {x.text}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
            </>
          )}
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col>
          <CustomTable
            items={resourceGroups}
            setItems={setResourceGroups}
            columns={columns}
          />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ResourceConstraints
