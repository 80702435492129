import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import { toast } from "react-toastify"
import { Col, Input, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"

const ForecastSummarySection = ({
  activeTab,
  alternative,
  alternativeId,
  changeAlternativeProp,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [fiscalYears, setFiscalYears] = useState([])
  const [expenseOptions, setExpenseOptions] = useState([])

  useEffect(() => {
    if (activeTab == "Forecast_Min") {
      constructFiscalYears()
      loadData()
    }
  }, [activeTab, alternativeId])

  const loadData = async () => {
    await loadExpenseOptions()
  }

  const constructFiscalYears = () => {
    let years = []
    let dt = new Date()
    let dtYearStart = dt.getFullYear() - 10
    for (let i = 0; i <= 25; i++) {
      years.push(dtYearStart + i)
    }
    setFiscalYears(years)
  }

  const loadExpenseOptions = async () => {
    let exp = await api.getList(currentUser.userID, "ExpenseType")
    setExpenseOptions(exp)
  }

  const clearForecast = () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to clear the forecast for this project?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.clearAlternativeForecast(alternativeId)
          toast.success("Forecast cleared successfully")
        }
      })
  }

  return (
    <React.Fragment>
      <Row>
        <Col md="4">
          <div className="mb-3">
            <Label className="form-label">Starting Fiscal Year</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={alternative.fyStart || -1}
              onChange={e => {
                changeAlternativeProp("fyStart", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {fiscalYears.map((a, idx) => {
                return (
                  <option key={idx} value={a}>
                    FY {a}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Cost</Label>
            <CurrencyInput
              id={`assesment`}
              name={`assesment`}
              className="form-control align-end"
              value={alternative.cost}
              onValueChange={(value, name, values) => {
                changeAlternativeProp("cost", values.float)
              }}
              prefix={"$"}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Return on Investment (%)</Label>
            <Input
              className="form-control align-end"
              type="number"
              value={alternative.roi}
              onChange={e => {
                changeAlternativeProp("roi", e.target.value)
              }}
            />
          </div>
        </Col>
        <Col md="4">
          <div className="mb-3">
            <Label className="form-label">Expense Type</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={alternative.colorOfMoney || -1}
              onChange={e => {
                changeAlternativeProp("colorOfMoney", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {expenseOptions.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemName}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Benefit</Label>
            <CurrencyInput
              id={`benefit`}
              name={`benefit`}
              className="form-control align-end"
              value={alternative.benefit}
              onValueChange={(value, name, values) => {
                changeAlternativeProp("benefit", values.float)
              }}
              prefix={"$"}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Internal Rate of Return (%)</Label>
            <Input
              className="form-control align-end"
              type="number"
              value={alternative.irr}
              onChange={e => {
                changeAlternativeProp("irr", e.target.value)
              }}
            />
          </div>
        </Col>
        <Col
          md="4"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
          }}
        >
          <div className="mb-3">
            <Label></Label>
            <button
              type="button"
              className="btn btn-outline-danger"
              style={{ float: "right" }}
              onClick={clearForecast}
            >
              <i className="fas fa-eraser" style={{ color: "red" }}></i> Clear
              Forecast
            </button>
          </div>
          <div className="mb-3">
            <Label className="form-label">Return (in $)</Label>
            <CurrencyInput
              id={`returnAmount`}
              name={`returnAmount`}
              className="form-control align-end"
              value={alternative.returnAmount}
              onValueChange={(value, name, values) => {
                changeAlternativeProp("returnAmount", values.float)
              }}
              prefix={"$"}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Payback Period (in Years)</Label>
            <Input
              className="form-control align-end"
              type="number"
              value={alternative.paybackYears}
              onChange={e => {
                changeAlternativeProp("paybackYears", e.target.value)
              }}
            />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ForecastSummarySection
