import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const StageUncertaintyModal = ({
  isOpen,
  toggleModal,
  alternative,
  scenarioId,
}) => {
  const currentUser = userService.getLoggedInUser()

  useEffect(() => {
    anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
  }, [])

  useEffect(() => {
    if (isOpen) {
      loadPhasesChart()
      loadStageChart()
      loadCostChart()
    }
  }, [isOpen])

  const save = async () => {}

  const loadPhasesChart = async () => {
    if (
      document.getElementById("chartdiv") !== undefined &&
      document.getElementById("chartdiv") !== null
    ) {
      document.getElementById("chartdiv").innerHTML = ""
    }
    let chartResp = await api.getAlternativePhasesChart(
      scenarioId,
      alternative.alternativeID
    )
    var dataStr = chartResp.chartArray.replaceAll("\\", "")
    var data = JSON.parse(dataStr)
    var data2 = JSON.parse(chartResp.chartArray2.replaceAll("\\", ""))
    var data3 = JSON.parse(chartResp.chartData.replaceAll("\\", ""))
    var data4 = JSON.parse(chartResp.generalChartScope.replaceAll("\\", ""))

    var dataLB = JSON.parse(chartResp.chartArray3.replaceAll("\\", ""))
    var dataLB2 = JSON.parse(chartResp.chartArray4.replaceAll("\\", ""))
    //var data4 = chartResp.generalChartScope

    var chart = anychart.area()

    // create a range spline area series and set the data
    var series1 = chart.rangeSplineArea(data)
    series1.name("Uncertainty Range")

    var series2 = chart.line(data2).size(2).hovered({ size: 5 })
    series2.name("Contingency")

    // create third series with mapped data
    var series3 = chart.spline(data3).markers(false)
    series3.name("Current Estimate")
    //series3.stroke("orange")

    // create fourth series with alternativeData
    var series4 = chart.column(data4).markers(false)
    series4.name("Current Stage")
    series4.yScale().stackMode("value")
    series4.fill("orange")


    // create fourth series with alternativeData
    /*       var seriesLB = chart.marker(dataLB).markers(true)
      seriesLB.name("Lookback") 
      seriesLB.normal().type("circle");
      seriesLB.normal().size(10);

      seriesLB
      .labels()
      .fontFamily("Inter")
      .enabled(true)
      .useHtml(true)
      .format(function (e) {
        return "<span style='color:black;'>" + this.getData("label") + "</span>"
      }) */

    // create fourth series with alternativeData
    var seriesLB2 = chart.marker(dataLB2).markers(true)
    seriesLB2.name("Lookback plus Contingency")
    seriesLB2.normal().type("cross")
    seriesLB2.normal().fill("red")
    seriesLB2.normal().size(5)

    seriesLB2
      .labels()
      .fontFamily("Inter")
      .enabled(true)
      .useHtml(true)
      .format(function (e) {
        return "<span style='color:black;'>" + this.getData("label") + "</span>"
      })

    // seriesLB.yScale().stackMode('value');

    chart.legend().enabled(true).fontSize(14).padding([0, 0, 15, 0])

    chart.title("Cone of Uncertainty")
    chart.container("chartdiv")

    let stage = anychart.graphics.create()

    var yLabels = chart.yAxis().labels()
    yLabels.format(function () {
      //return anychart.format.number(this.value, 0, ".", ",") + "%"
      return "$" + anychart.format.number(this.value, 0, ".", ",")
    })

    var xLabels = chart.xAxis().labels()
    xLabels.width(120)
    xLabels.hAlign("center")
    xLabels.wordWrap("break-word")
    //xLabels.wordBreak("break-all")

    chart.tooltip().useHtml(true)
    chart.tooltip().displayMode("single")
    chart
      .tooltip()
      .format(
        function formatTooltip(e){
          var tooltipText = ''
          // tooltipText += `<b>Upper range of Uncertainty:</b> ${e.high}<br>`;

          // if(this.chart.getSeriesAt(1).getPoint(this.index).get('value') != undefined)
          //   tooltipText += `<b>Contingency:</b> $${this.chart.getSeriesAt(1).getPoint(this.index).get('value')}<br>`;

          // if(this.chart.getSeriesAt(2).getPoint(this.index).get('value') != undefined)
          //   tooltipText += `<b>Actual Cost:</b> $${this.chart.getSeriesAt(2).getPoint(this.index).get('value')}<br>`;

          // if(this.chart.getSeriesAt(4).getPoint(this.index).get('value') != undefined){
          //   tooltipText += `<b>Lookback Plus Contingency:</b> $${this.chart.getSeriesAt(4).getPoint(this.index).get('value')}<br>`;
          // }

          // tooltipText += `<b>Lower range of Uncertainty:</b> ${e.low}<br>`;

          if(this.chart.getSeriesAt(4).getPoint(this.index).get('tooltip') != undefined){
            tooltipText += `${this.chart.getSeriesAt(4).getPoint(this.index).get('tooltip')}<br>`;
          }

          return tooltipText
        }
      )

    chart.credits().enabled(false)
    chart.draw()
  }

  const loadCostChart = async () => {
    if (
      document.getElementById("chartdiv3") !== undefined &&
      document.getElementById("chartdiv3") !== null
    ) {
      document.getElementById("chartdiv3").innerHTML = ""
    }

    let chartResp = await api.getAlternativePhasesCostChart(
      scenarioId,
      alternative.alternativeID
    )
    var dataStr = chartResp.chartArray.replaceAll("\\", "")
    var data = JSON.parse(dataStr)

    var chart = anychart.hilo()

    // create a range spline area series and set the data
    //var series1 = chart.scatter(data)
    // series1.name("Cost Estimates")

    // create fourth series with alternativeData
    //var seriesLB = chart.box(data).markers(true)
    var seriesLB = chart.hilo(data).markers(true)
    seriesLB.name("")
    seriesLB.normal().type("circle")
    seriesLB.normal().size(6)

    seriesLB
      .labels()
      .fontFamily("Inter")
      .enabled(true)
      .useHtml(true)
      .format(function (e) {
        return "<span style='color:black;'>" + this.getData("label") + "</span>"
      })

    seriesLB.normal().stroke("#00cc99", 4, "10 5", "round")

    // seriesLB.yScale().stackMode('value');

    //chart.legend().enabled(true).fontSize(14).padding([0, 0, 15, 0])

    chart.title("Project Cost Stage Estimates")
    chart.container("chartdiv3")

    //let stage = anychart.graphics.create()

    var yLabels = chart.yAxis().labels()
    yLabels.format(function () {
      return "$" + anychart.format.number(this.value, 0, ".", ",")
    })

    var xLabels = chart.xAxis().labels()
    xLabels.width(120)
    xLabels.hAlign("center")
    xLabels.wordWrap("break-word")

    chart.tooltip().useHtml(true)
    var tooltip = chart.tooltip()
    //tooltip.titleFormat("{%x}")
    tooltip.format("{%ToolTip}")

    chart.credits().enabled(false)
    chart.draw()
  }

  const loadStageChart = async () => {
    if (
      document.getElementById("chartdiv2") !== undefined &&
      document.getElementById("chartdiv2") !== null
    ) {
      document.getElementById("chartdiv2").innerHTML = ""
    }
    let data = await api.getAlternativeChart(
      alternative.alternativeID,
      "Stage_Costs"
    )

    let dataArray = JSON.parse(data.chartArray.replaceAll("'", '"'))
    let chartData = anychart.data.set(dataArray)
    var chart = anychart.waterfall()

    //chart.background().stroke(".5 gray")
    chart.animation(true)
    chart.interactivity().hoverMode("single")

    var seriesData_1 = chartData.mapAs({ x: 0, value: 1, label: 2, fill: 3 })

    var series1 = chart.waterfall(seriesData_1)

    series1.normal().fill("#ccecff", 1.0)

    series1.normal().stroke("#1b1c1b", 0.3)

    chart.title({ text: data.chartTitle, fontWeight: "bold", padding: "5px" })

    chart.yScale().stackMode("value")
    var yLabels = chart.yAxis().labels()
    yLabels.format(function () {
      return "$" + anychart.format.number(this.value, 2, ".", ",")
    })

    var clabels = chart.labels()
    clabels.format(function () {
      return "$" + anychart.format.number(this.absolute, 0, ".", ",")
    })

    var xLabels = chart.xAxis().labels()
    xLabels.width(200)
    xLabels.hAlign("center")
    xLabels.wordWrap("break-word")

    chart.xAxis().stroke(null)
    //chart.xAxis(1).stroke(null).orientation("top")
    chart.yAxis().title("")

    var zeroMarker = chart.lineMarker(0)
    zeroMarker.stroke("#ddd")
    zeroMarker.scale(chart.yScale())
    zeroMarker.value(0)

    // clabels.offsetX(140)

    chart.labels(true)
    chart
      .tooltip()
      .title(false)
      .separator(false)
      .format(function () {
        return "$" + anychart.format.number(this.value, 0, ".", ",")
      })
    chart.legend().enabled(false)
    chart.xGrid().stroke("#ddd").drawLastLine(false)
    chart.barsPadding(2).barGroupsPadding(2)
    chart.container("chartdiv2")
    chart.credits().enabled(false)

    //chart.labels.offsetX(40);

    yLabels.fontFamily("Inter")
    clabels.fontFamily("Inter")
    chart.title().fontFamily("Inter")
    chart.legend().fontFamily("Inter")

    chart.draw()
  }

  return (
    <Modal
      isOpen={isOpen}
      size="xl"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Cone of Uncertainty
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <div id="chartdiv" style={{ height: "500px" }}></div>
        </div>
        <div className="mb-3">
          <div id="chartdiv2" style={{ height: "300px" }}></div>
        </div>
        <div className="mb-3">
          <div id="chartdiv3" style={{ height: "300px" }}></div>
        </div>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        {/* <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button> */}
      </div>
    </Modal>
  )
}

export default StageUncertaintyModal
