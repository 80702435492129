import React, { useEffect } from "react"
import { Col, Container, Row } from "reactstrap"

const NoAccess = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h3 className="text-center">
                We are sorry, but you don't have rights to access this screen.
              </h3>
              <button
                className="btn btn-primary"
                style={{ width: "200px" }}
                onClick={() => {
                  props.history.go(-3)
                }}
              >
                <i className="fas fa-arrow-left"></i> Go Back
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NoAccess
