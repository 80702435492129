import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Col, Row } from "reactstrap"
import api from "services/api.service"
import colors from "utils/colors"
import logo from "../../../assets/images/defprologo1.png"

const Exit = props => {
  return (
    <React.Fragment>
      <div style={{ overflow: "hidden", color: "white" }}>
        <Row
          className="background-gradient"
          style={{ height: "100vh", display: "flex", justifyContent: "center" }}
        >
          <Col
            md="6"
            sm="12"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              textAlign: "center",
            }}
          >
            <img src={logo} alt="" style={{ width: "70%", height: "auto" }} />
            <div>
              <div className="mb-4">
                <p className="mb-0">
                  <i>Thank you for using Definitive Pro.</i>
                </p>
                <p>
                  <i>Please close your browser.</i>
                </p>
              </div>
              <div className="mb-4">
                <h3>
                  <b>Definitive Business Solution, Inc.</b>
                </h3>
              </div>
            </div>
            <div>
              <p className="mb-0">11921 Freedom Drive, Suite 550</p>
              <p>Reston, Virginia 20190</p>
            </div>
            <div>
              <p className="mb-0">
                U.S. Patent Nos. 10,268,977; 10,366,361; 10,417,590
              </p>
              <p>All Rights Reserved</p>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Exit
