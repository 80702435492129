import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { Col, Container, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { changeNavbarParams, loadPageItem } from "store/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import EnterprisesGrid from "./EnterprisesGrid"

const Enterprises = props => {
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  let [enterprises, setEnterprises] = useState([])
  let [enterprise, setEnterprise] = useState(null)
  let [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: 0,
        alternativeId: 0,
        viewName: "enterprises",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: 0,
        viewName: "enterprises",
      })
    )    
    loadData()
  }, [])

  const loadData = async () => {
    try {
      setIsLoading(true)
      let list = await api.getEnterprises(currentUser.userID)
      setEnterprises(list)
      let ent = await api.getEnterprise(currentUser.enterpriseID)
      setEnterprise(ent)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  const addEnterprise = () => {
    props.history.push("/editEnterprise/-1")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={pageItem !== undefined ? pageItem.pageSubTitle : ""} />
          <Row style={{ marginBottom: "20px" }}>
            <Col xs="12">
              <button className="btn btn-primary" onClick={addEnterprise}>
              <i className="fas fa-plus"></i> Add Enterprise
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              {!isLoading && (
                <EnterprisesGrid
                  enterprises={enterprises}
                  userEnterprise={enterprise}
                  props={props}
                />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Enterprises
