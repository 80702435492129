import React, { useState } from "react"
import { ChromePicker } from "react-color"
import { Label } from "reactstrap"

const CustomColorPicker = ({color, handleColorChange, label}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false)

  const handlePickerButtonClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  }

  const handleClose = () => {
    setDisplayColorPicker(false)
  }

  const popover = {
    position: "absolute",
    zIndex: "2",
  }
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  }
  const colorStyle = {
    width: "100%",
    height: "23px",
    borderRadius: "2px",
    backgroundColor:
      color != undefined &&
      color != null &&
      color != ""
        ? color
        : "white",
  }
  const swatch = {
    padding: "5px",
    background: "#fff",
    borderRadius: "1px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "inline-block",
    cursor: "pointer",
  }

  return (
    <div className="mb-3">
      <Label className="form-label">{label == undefined ? 'Color' : label}</Label>
      <div
        style={swatch}
        className="form-control"
        onClick={handlePickerButtonClick}
      >
        <div style={colorStyle} />
      </div>
      {displayColorPicker ? (
        <div style={popover}>
          <div style={cover} onClick={handleClose} />
          <ChromePicker
            color={color}
            onChangeComplete={handleColorChange}
          />
        </div>
      ) : null}
    </div>
  )
}

export default CustomColorPicker