import { swalWithConfirmButton } from "components/custom/swal"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { Input, Label, Modal } from "reactstrap"
import api from "services/api.service"
import colors from "utils/colors"
import { currencyFormatter } from "utils/formatters"

const OptimizeModal = ({
  scenario,
  isOpen,
  toggleModal,
  optModel,
  isOptModelLoading,
  saveOptimizationSingle,
  changeOptModelProp,
  showInterval,
  reloadData,
}) => {
  const [objectivesDropdownList, setObjectivesDropdownList] = useState([])
  const [costDropdownList, setCostDropdownList] = useState([])
  const [costVariableSummary, setCostVariableSummary] = useState([])
  const [resourceGroupResults, setResourceGroupResults] = useState([])
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    if (scenario !== null) {
      loadData()
    }
  }, [scenario])

  const loadData = async () => {
    try {
      let objs = await api.getObjectives(scenario.scenarioID)
      setObjectivesDropdownList([
        {
          value: 0,
          text: scenario.scenarioName,
        },
        ...objs
          .filter(x => x.measures && x.measures.length > 0)
          .map(x => {
            return {
              value: x.objectiveID,
              text: x.objectiveName,
            }
          }),
      ])
      let cv = await api.getCostVariables(scenario.scenarioID)
      setCostDropdownList([
        {
          text: "Total Cost",
          value: 0,
        },
        {
          text: "NPV Cost",
          value: -5,
        },
        {
          text: "Pre-Project Cost",
          value: -10,
        },
        {
          text: "Project Costs",
          value: -20,
        },
        {
          text: "Pre-Project and Project Cost",
          value: -25,
        },
        {
          text: "Post-Project Cost",
          value: -30,
        },
        {
          text: "Project and Post-Project Cost",
          value: -35,
        },
        ...cv.map(c => {
          return {
            text: c.costVariableName,
            value: c.costVariableID,
          }
        }),
      ])

      let summary = await api.getCostVariableSummary(scenario.currentOptModelID)
      setCostVariableSummary(summary)

      await loadResourceGroupResults(scenario.currentOptModelID)
    } catch (err) {}
  }

  const loadResourceGroupResults = async optModelId => {
    let results = await api.getResourceGroupResults(optModelId)
    setResourceGroupResults(results)
  }

  const save = async () => {
    try {
      if ((optModel.parEnd - optModel.parStart) / optModel.parInterval > 40) {
        swalWithConfirmButton.fire({
          title:
            "You can not run more than 40 increments, please increase the increment.",
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Ok",
        })
      } else {
        setIsSaving(true)
        await saveOptimizationSingle(resourceGroupResults)
        // await loadResourceGroupResults(scenario.currentOptModelID)
        reloadData()
        setIsSaving(false)
      }
    } catch (err) {
      console.log(err)
      toggleModal()
      setIsSaving(false)
    }
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Optimization Settings
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {isOptModelLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      {!isOptModelLoading && optModel != null && (
        <div className="modal-body">
          {scenario != null && scenario.scenarioID > 0 && (
            <div className="mb-3">
              <Label className="form-label">Scope</Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={optModel.objectiveID || 0}
                onChange={e => {
                  changeOptModelProp("objectiveID", e.target.value)
                }}
              >
                {objectivesDropdownList.map((a, idx) => {
                  return (
                    <option key={idx} value={`${a.value}`}>
                      {a.text}
                    </option>
                  )
                })}
              </select>
            </div>
          )}
          <div className="mb-3">
            <Label className="form-label">Optimize</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={optModel.optimizeOn}
              onChange={e => {
                changeOptModelProp("optimizeOn", e.target.value)
              }}
            >
              <option value="Score">Score</option>
              <option value="CB">CB</option>
              <option value="Combi">Combi</option>
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Optimization Type</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={optModel.minMax}
              onChange={e => {
                changeOptModelProp("minMax", e.target.value)
              }}
            >
              <option value="Max-Score">Max-Score</option>
              <option value="Min-Score">Min-Score</option>
            </select>
          </div>
          <div className="mb-3">
            <Label className="form-label">Cost Variable</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={optModel.costVariableID || 0}
              onChange={e => {
                changeOptModelProp("costVariableID", e.target.value)
              }}
            >
              {costDropdownList.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.value}`}>
                    {a.text}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3">
            <Label>Include Constraints</Label>
            <div
              className="mb-3"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Input
                id="useMust"
                type="checkbox"
                checked={optModel.useMust}
                onClick={() => changeOptModelProp("useMust", !optModel.useMust)}
              />
              <Label
                className="form-check-label"
                for="useMust"
                style={{ marginLeft: "10px" }}
              >
                Musts
              </Label>
              <Input
                id="useCant"
                type="checkbox"
                style={{ marginLeft: "10px" }}
                checked={optModel.useCant}
                onClick={() => changeOptModelProp("useCant", !optModel.useCant)}
              />
              <Label
                className="form-check-label"
                for="useCant"
                style={{ marginLeft: "10px" }}
              >
                Must Nots
              </Label>
              <Input
                id="usePartial"
                type="checkbox"
                style={{ marginLeft: "10px" }}
                checked={optModel.usePartial}
                onClick={() =>
                  changeOptModelProp("usePartial", !optModel.usePartial)
                }
              />
              <Label
                className="form-check-label"
                for="usePartial"
                style={{ marginLeft: "10px" }}
              >
                Partials
              </Label>
              <Input
                id="useDependencies"
                type="checkbox"
                style={{ marginLeft: "10px" }}
                checked={optModel.useDependencies}
                onClick={() =>
                  changeOptModelProp(
                    "useDependencies",
                    !optModel.useDependencies
                  )
                }
              />
              <Label
                className="form-check-label"
                for="useDependencies"
                style={{ marginLeft: "10px" }}
              >
                Dependencies
              </Label>
            </div>
            <div
              className="mb-3"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Input
                id="useResourceGroups"
                type="checkbox"
                checked={optModel.useResourceGroups}
                onClick={() =>
                  changeOptModelProp(
                    "useResourceGroups",
                    !optModel.useResourceGroups
                  )
                }
              />
              <Label
                className="form-check-label"
                for="useResourceGroups"
                style={{ marginLeft: "10px" }}
              >
                Resource
              </Label>
              <Input
                id="useResource"
                type="checkbox"
                style={{ marginLeft: "10px" }}
                checked={optModel.useResource}
                onClick={() =>
                  changeOptModelProp("useResource", !optModel.useResource)
                }
              />
              <Label
                className="form-check-label"
                for="useResource"
                style={{ marginLeft: "10px" }}
              >
                Group
              </Label>
              <Input
                id="useCriteria"
                type="checkbox"
                style={{ marginLeft: "10px" }}
                checked={optModel.useCriteria}
                onClick={() =>
                  changeOptModelProp("useCriteria", !optModel.useCriteria)
                }
              />
              <Label
                className="form-check-label"
                for="useCriteria"
                style={{ marginLeft: "10px" }}
              >
                Objective Allocation
              </Label>
            </div>
          </div>
          <div className="mb-3">
            <Label className="form-label">Cost Constraint</Label>
            <CurrencyInput
              id="costConstraint"
              name="costConstraint"
              className="form-control"
              value={optModel.costConstraint}
              onValueChange={(value, name, values) => {
                changeOptModelProp("costConstraint", values.float)
              }}
              placeholder=""
              prefix={"$"}
            />
          </div>
          {showInterval && (
            <div
              className="mb-3"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Label className="form-label">Interval Start Amount</Label>
                <CurrencyInput
                  id="start"
                  name="start"
                  className="form-control"
                  value={optModel.parStart}
                  onValueChange={(value, name, values) => {
                    changeOptModelProp("parStart", values.float)
                  }}
                  placeholder=""
                  prefix={"$"}
                />
              </div>
              <div>
                <Label className="form-label">Interval End Amount</Label>
                <CurrencyInput
                  id="end"
                  name="end"
                  className="form-control"
                  value={optModel.parEnd}
                  onValueChange={(value, name, values) => {
                    changeOptModelProp("parEnd", values.float)
                  }}
                  placeholder=""
                  prefix={"$"}
                />
              </div>
              <div>
                <Label className="form-label">Increment</Label>
                <CurrencyInput
                  id="parInterval"
                  name="parInterval"
                  className="form-control"
                  value={optModel.parInterval}
                  onValueChange={(value, name, values) => {
                    changeOptModelProp("parInterval", values.float)
                  }}
                  placeholder=""
                  prefix={"$"}
                />
              </div>
            </div>
          )}
          {/*           <div className="mb-3">
            <Label>Cost Variables</Label>
            <table
              className="table table-bordered low-padding-table"
              style={{ backgroundColor: "white" }}
            >
              <thead>
                <th style={{ width: "40%" }}>Cost Variable</th>
                <th style={{ width: "15%" }}>Musts</th>
                <th style={{ width: "15%" }}>Selected</th>
                <th style={{ width: "15%" }}>Total</th>
                <th style={{ width: "15%" }}>%</th>
              </thead>
              <tbody>
                {costVariableSummary.map((c, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{c.costVariableName}</td>
                      <td style={{ textAlign: "right" }}>
                        {currencyFormatter.format(c.totalMust)}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {currencyFormatter.format(c.selectedValue)}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {currencyFormatter.format(c.totalValue)}
                      </td>
                      <td>{(c.selectedPercent * 100).toFixed(2)}%</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div> */}
          {/* {optModel.useResourceGroups && resourceGroupResults.length > 0 && (
            <div className="mb-3">
              <Label>Resource Constraints</Label>
              <table
                className="table table-bordered low-padding-table"
                style={{ backgroundColor: "white" }}
              >
                <thead>
                  <th style={{ width: "10%" }}>Resource</th>
                  <th style={{ width: "10%" }}>Minimum</th>
                  <th style={{ width: "10%" }}>Maximum</th>
                  <th style={{ width: "15%" }}>Total Required</th>
                  <th style={{ width: "15%" }}>Selected</th>
                  <th style={{ width: "15%" }}>% of Max</th>
                  <th style={{ width: "15%" }}>% of Total Required</th>
                  <th style={{ width: "10%" }}>Active</th>
                </thead>
                <tbody>
                  {resourceGroupResults.length > 0 &&
                    resourceGroupResults.map((c, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{c.resourceGroupAbb}</td>
                          <td style={{ textAlign: "right" }}>
                            {currencyFormatter.format(c.minAmount)}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {currencyFormatter.format(c.maxAmount)}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {currencyFormatter.format(c.totalResources)}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {currencyFormatter.format(c.selectedAmount)}
                          </td>
                          <td>{c.percentOfMax}</td>
                          <td>{c.percentOfTotal}</td>
                          <td>
                            <Input
                              type="checkbox"
                              checked={c.active}
                              onClick={() => {
                                let copy = [...resourceGroupResults]
                                let item = copy.find(
                                  x => x.resourceGroupID == c.resourceGroupID
                                )
                                item.active = !item.active
                                item.isDirty = true
                                setResourceGroupResults(copy)
                              }}
                            />
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          )} */}
        </div>
      )}
      <div className="modal-footer">
        <div style={{ display: "flex" }}>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={save}
            disabled={isSaving}
          >
            Optimize
          </button>
          {isSaving && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ marginLeft: "10px" }}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}

export default OptimizeModal
