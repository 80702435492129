import React, { useEffect, useState } from "react"
import { Col, Container, Input, Label, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { changeNavbarParams, loadPageItem } from "store/actions"
import Loader from "react-loaders"
import colors from "utils/colors"
import useModal from "hooks/useModalHook"
import isNullOrEmpty from "utils/isNullOrEmpty"
import CopyPlanModal from "./Modals/CopyPlanModal"
import ReactApexChart from "react-apexcharts"
import { currencyFormatter } from "utils/formatters"

const Plans = props => {
  const scenarioId = props.match.params.scenarioId
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [isLoading, setIsLoading] = useState(false)
  const [plans, setPlans] = useState([])

  const { show: showCopyModal, toggle: toggleCopyModal } = useModal()
  const [planToCopy, setPlanToCopy] = useState(null)

  useEffect(() => {
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        viewName: "plans",
      })
    )
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "plans",
      })
    )
    loadData()
  }, [])

  const loadData = async () => {
    setIsLoading(true)
    let p = await api.getPlans(scenarioId)
    setPlans(p)
    setIsLoading(false)
  }

  const addPlan = () => {
    props.history.push(`/editPlan/${scenarioId}/-1`)
  }

  const copyPlan = plan => {
    setPlanToCopy({
      ...plan,
      planName: "",
      description: "",
      scenarioID: scenarioId,
    })
    toggleCopyModal()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          {!isLoading && (
            <>
              <Row className="mb-3">
                <Col>
                  <button className="btn btn-primary" onClick={addPlan}>
                    <i className="fas fa-plus"></i> Add Plan
                  </button>
                </Col>
              </Row>

              <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <Col className="d-flex-row">
                  {plans.length > 0 && (
                    <PlanItem
                      plan={plans[0]}
                      history={props.history}
                      scenarioId={scenarioId}
                      copyPlan={copyPlan}
                    />
                  )}
                  <div className="d-flex-row" style={{ overflowX: "auto" }}>
                    {plans.length > 1 &&
                      plans
                        .slice(1, plans.length)
                        .map((p, idx) => (
                          <PlanItem
                            key={idx}
                            plan={p}
                            history={props.history}
                            scenarioId={scenarioId}
                            copyPlan={copyPlan}
                          />
                        ))}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
      <CopyPlanModal
        isOpen={showCopyModal}
        toggleModal={toggleCopyModal}
        setPlanToCopy={setPlanToCopy}
        planToCopy={planToCopy}
        reload={loadData}
      />
    </React.Fragment>
  )
}

const PlanItem = ({ plan, history, scenarioId, copyPlan }) => {
  const [showChart, setShowChart] = useState(false)
  const [optSeries, setOptSeries] = useState(null)

  const [showSecondaryChart_risk, setShowSecondaryChart_risk] = useState(false)
  const [optSeriesSecondary_risk, setOptSeriesSecondary_risk] = useState(null)

  const [showSecondaryChart_benefit, setShowSecondaryChart_benefit] =
    useState(false)
  const [optSeriesSecondary_benefit, setOptSeriesSecondary_benefit] =
    useState(null)

  const [showSecondaryChart_outcome, setShowSecondaryChart_outcome] =
    useState(false)
  const [optSeriesSecondary_outcome, setOptSeriesSecondary_outcome] =
    useState(null)

  const loadChart = async () => {
    if (optSeries == null) {
      let chart = await api.getMixChartPlan(plan.planID, scenarioId, "primary")
      let opts = {
        series: chart.chartTable.map(c => c.costContribution),
        options: {
          chart: {
            width: "100%",
            type: "pie",
            donut: {
              size: "65%",
            },
          },
          labels: chart.chartTable.map(c => c.objective),
          tooltip: {
            enabled: true,
            y: {
              formatter: function (val) {
                return currencyFormatter.format(val)
              },
            },
          },
          legend: {
            position: "bottom",
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 600,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      }
      setOptSeries(opts)
    }
  }

  const loadSecondaryChart = async (chartName, setter) => {
    let chart = await api.getMixChartPlan(plan.planID, scenarioId, chartName)
    let opts = {
      series: chart.chartTable.map(c => c.costContribution),
      options: {
        chart: {
          width: "100%",
          type: "pie",
          donut: {
            size: "65%",
          },
        },
        labels: chart.chartTable.map(c => c.objective),
        tooltip: {
          enabled: true,
          y: {
            formatter: function (val) {
              return currencyFormatter.format(val)
            },
          },
        },
        legend: {
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 600,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    }
    // setOptSeriesSecondary(opts)
    setter(opts)
  }

  const redirect = () => {
    if (plan.planID != 0) history.push(`/editPlan/${scenarioId}/${plan.planID}`)
  }

  const width = 400

  return (
    <div
      // className="col-md-3"
      style={{
        margin: "10px 10px 10px 0px",
        border: "1px solid gray",
        borderRadius: "15px",
        padding: "15px",
        cursor: plan.planID == 0 ? "unset" : "pointer",
        boxShadow: "5px 5px 5px 0px lightgrey",
        width: `${width}px`,
        flexShrink: 0,
      }}
    >
      <div onClick={redirect}>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <b>
            <h2>{plan.planName}</h2>
          </b>
          <div className="divider"></div>
        </div>
        <span>Criteria Model: {plan.objModelName}</span>
        <br />
        <span>Financial Model: {plan.finModelName}</span>
        <br />
        <br />
        {!isNullOrEmpty(plan.alternativeSection) && (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: plan.alternativeSection,
              }}
            ></div>
            <div className="divider"></div>
          </>
        )}
        {!isNullOrEmpty(plan.financialSection) && (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: plan.financialSection,
              }}
            ></div>
            <div className="divider"></div>
          </>
        )}
        {!isNullOrEmpty(plan.benefitSection) && (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: plan.benefitSection,
              }}
            ></div>
            <div className="form-check mb-3 mt-3">
              <Input
                type="checkbox"
                className="form-check-input"
                id={plan.planID + "-showChart"}
                checked={showChart}
                onClick={e => {
                  e && e.stopPropagation()
                  if (!showChart) {
                    loadChart()
                  }
                  setShowChart(!showChart)
                }}
              />
              <Label
                className="form-check-label"
                for={plan.planID + "-showChart"}
              >
                Show Chart
              </Label>
            </div>
            {showChart && optSeries != null && (
              <div className="mb-3">
                <ReactApexChart
                  options={optSeries.options}
                  series={optSeries.series}
                  type="pie"
                  width="90%"
                  height="600px"
                />
              </div>
            )}
            <div className="divider"></div>
          </>
        )}
        {!isNullOrEmpty(plan.secondarySection_Risk) && (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: plan.secondarySection_Risk,
              }}
            ></div>
            <div className="form-check mb-3 mt-3">
              <Input
                type="checkbox"
                className="form-check-input"
                id={plan.planID + "-riskcb"}
                checked={showSecondaryChart_risk}
                onClick={e => {
                  e && e.stopPropagation()
                  if (!showSecondaryChart_risk) {
                    loadSecondaryChart(
                      "secondary_risk",
                      setOptSeriesSecondary_risk
                    )
                  }
                  setShowSecondaryChart_risk(!showSecondaryChart_risk)
                }}
              />
              <Label className="form-check-label" for={plan.planID + "-riskcb"}>
                Show Chart
              </Label>
            </div>
            {showSecondaryChart_risk && optSeriesSecondary_risk != null && (
              <div className="mb-3">
                <ReactApexChart
                  options={optSeriesSecondary_risk.options}
                  series={optSeriesSecondary_risk.series}
                  type="pie"
                  width="90%"
                  height="600px"
                />
              </div>
            )}
            <div className="divider"></div>
          </>
        )}
        {!isNullOrEmpty(plan.secondarySection_Benefit) && (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: plan.secondarySection_Benefit,
              }}
            ></div>
            <div className="form-check mb-3 mt-3">
              <Input
                type="checkbox"
                className="form-check-input"
                id={plan.planID + "-benefitcb"}
                checked={showSecondaryChart_benefit}
                onClick={e => {
                  e && e.stopPropagation()
                  if (!showSecondaryChart_benefit) {
                    loadSecondaryChart(
                      "secondary_benefit",
                      setOptSeriesSecondary_benefit
                    )
                  }
                  setShowSecondaryChart_benefit(!showSecondaryChart_benefit)
                }}
              />
              <Label
                className="form-check-label"
                for={plan.planID + "-benefitcb"}
              >
                Show Chart
              </Label>
            </div>
            {showSecondaryChart_benefit && optSeriesSecondary_benefit != null && (
              <div className="mb-3">
                <ReactApexChart
                  options={optSeriesSecondary_benefit.options}
                  series={optSeriesSecondary_benefit.series}
                  type="pie"
                  width="90%"
                  height="600px"
                />
              </div>
            )}
            <div className="divider"></div>
          </>
        )}
        {!isNullOrEmpty(plan.secondarySection_Outcome) && (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: plan.secondarySection_Outcome,
              }}
            ></div>
            <div className="form-check mb-3 mt-3">
              <Input
                type="checkbox"
                className="form-check-input"
                id={plan.planID + "-outcome"}
                checked={showSecondaryChart_outcome}
                onClick={e => {
                  e && e.stopPropagation()
                  if (!showSecondaryChart_outcome) {
                    loadSecondaryChart(
                      "secondary_outcome",
                      setOptSeriesSecondary_outcome
                    )
                  }
                  setShowSecondaryChart_outcome(!showSecondaryChart_outcome)
                }}
              />
              <Label
                className="form-check-label"
                for={plan.planID + "-outcome"}
              >
                Show Chart
              </Label>
            </div>
            {showSecondaryChart_outcome && optSeriesSecondary_outcome != null && (
              <div className="mb-3">
                <ReactApexChart
                  options={optSeriesSecondary_outcome.options}
                  series={optSeriesSecondary_outcome.series}
                  type="pie"
                  width="90%"
                  height="600px"
                />
              </div>
            )}
            <div className="divider"></div>
          </>
        )}
        {/* {!isNullOrEmpty(plan.secondarySection) && (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html: plan.secondarySection,
              }}
            ></div>
            <div className="form-check mb-3 mt-3">
              <Input
                type="checkbox"
                className="form-check-input"
                id={plan.planID + "-showChartSec"}
                checked={showSecondaryChart}
                onClick={e => {
                  e && e.stopPropagation()
                  if (!showSecondaryChart) {
                    loadSecondaryChart()
                  }
                  setShowSecondaryChart(!showSecondaryChart)
                }}
              />
              <Label
                className="form-check-label"
                for={plan.planID + "-showChartSec"}
              >
                Show Chart
              </Label>
            </div>
            {showSecondaryChart && optSeriesSecondary != null && (
              <div className="mb-3">
                <ReactApexChart
                  options={optSeriesSecondary.options}
                  series={optSeriesSecondary.series}
                  type="pie"
                  width="90%"
                  height="600px"
                />
              </div>
            )}
            <div className="divider"></div>
          </>
        )} */}
        {!isNullOrEmpty(plan.resourceSection) && (
          <div
            dangerouslySetInnerHTML={{
              __html: plan.resourceSection,
            }}
          ></div>
        )}
        {!isNullOrEmpty(plan.programSection) && (
          <div>
            <div className="divider"></div>
            <div
              dangerouslySetInnerHTML={{
                __html: plan.programSection,
              }}
            ></div>
          </div>
        )}
      </div>
      {plan.planID != 0 && (
        <button
          className="btn btn-primary"
          style={{ float: "right" }}
          onClick={() => copyPlan(plan)}
        >
          Copy Plan
        </button>
      )}
    </div>
  )
}

export default Plans
