import { TextareaAutosize } from "@material-ui/core"
import React, { useState } from "react"
import Loader from "react-loaders"
import { useDispatch } from "react-redux"
import { FormGroup, Input, Label } from "reactstrap"
import api from "services/api.service"
import OpenaiService from "services/openai.service"
import { changeChatGptSettings } from "store/actions"
import colors from "utils/colors"

const ChatGPTDrawerGlobal = () => {
  const [messages, setMessages] = useState([])
  const [newMessage, setNewMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [useGeneralAssistant, setUseGeneralAssistant] = useState(false)
  const dispatch = useDispatch()

  var openAiService = new OpenaiService()

  const onSend = () => {
    if (newMessage != "") {
      let copy = [...messages]
      copy.push({ type: "sent", message: newMessage })

      sendMessage(newMessage, copy)

      setNewMessage("")
      setMessages(copy)
      scrollToBottom()
    }
  }

  const sendMessage = async (message, allMessages) => {
    setIsLoading(true)
    scrollToBottom()
    let resp = await openAiService.askAssistantAsync(message, useGeneralAssistant)
    if (resp != null) {
      let copy = [...allMessages]
      copy.push({ type: "received", message: resp })
      setMessages(copy)
      scrollToBottom()
    }
    setIsLoading(false)
  }

  const scrollToBottom = () => {
    setTimeout(() => {
      document.getElementById("messages-container").scrollTop =
        document.getElementById("messages-container").scrollHeight
    }, 300)
  }

  const toggleModal = () => {
    dispatch(changeChatGptSettings(false))
  }

  return (
    <div
      style={{
        background: "lightgray",
        paddingBottom: "90px",
        height: "100vh",
        paddingTop: "80px",
      }}
    >
      <div className="d-flex-col w-100 h-100 jc-end">
        <div className="d-flex-row jc-space-between">
          <FormGroup
            switch
            style={{
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <Input
              type="switch"
              checked={useGeneralAssistant}
              style={{
                backgroundColor: useGeneralAssistant ? "green" : "transparent"
              }}
              onClick={() => setUseGeneralAssistant(!useGeneralAssistant)}
            />
            <Label check style={{ marginLeft: "10px" }}>
              {useGeneralAssistant
                ? "I am asking questions about Definitive Pro (how to do something) or general questions about portfolio management or decision making"
                : "I am asking questions about my data and portfolio(s)"}
            </Label>
          </FormGroup>
          <i
            className="fas fa-times"
            style={{
              padding: "15px",
              fontSize: "20px",
              cursor: "pointer",
              alignSelf: "end",
            }}
            onClick={toggleModal}
          ></i>
        </div>

        <div
          id="messages-container"
          className="h-100"
          style={{
            background: "white",
            borderRadius: "10px",
            margin: "10px",
            overflow: "auto",
          }}
        >
          {messages.map((m, idx) => (
            <div
              key={idx}
              style={{
                margin: "10px",
                borderRadius: "10px",
                border: m.type == "sent" ? "1px solid gray" : "none",
                maxWidth: "80%",
                padding: "5px 10px",
                alignSelf: m.type == "sent" ? "end" : "start",
                float: m.type == "sent" ? "right" : "left",
                whiteSpace: "pre-line",
              }}
            >
              {m.message}
            </div>
          ))}
          {isLoading && (
            <div className="d-flex-row jc-center w-100">
              <Loader
                type="line-scale-pulse-out"
                color={colors.primary}
                style={{ textAlign: "center" }}
              />
            </div>
          )}
        </div>
        <div
          className="d-flex-row jc-space-between ai-center"
          style={{ padding: "10px" }}
        >
          <TextareaAutosize
            className="form-control"
            onChange={e => setNewMessage(e.target.value)}
            value={newMessage}
          />
          <i
            className="mdi mdi-send"
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              fontSize: "20px",
              cursor: "pointer",
            }}
            onClick={onSend}
          ></i>
        </div>
      </div>
    </div>
  )
}

export default ChatGPTDrawerGlobal
