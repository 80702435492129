import { TextareaAutosize } from "@material-ui/core"
import useModal from "hooks/useModalHook"
import React, { useEffect, useRef, useState } from "react"
import { Input, Label, Modal } from "reactstrap"
import { userService } from "services/user.service"
import EditCategoryModal from "./EditCategoryModal"
import {
  swalWithConfirmAndCancelButtons,
  swalWithConfirmButton,
} from "components/custom/swal"
import api from "services/api.service"
import SelectAlternativesModal from "./SelectAlternativesModal"
import { toast } from "react-toastify"
import EditTaskModal, {
  TaskTypes,
} from "pages/Projects/EditProject/Modals/EditTaskModal"
import moment from "moment"
import RoadmapChartModal from "./RoadmapChartModal"
import { ReactTabulator } from "react-tabulator"
import { createRoot } from "react-dom/client"
import EditAlternativeModal from "./EditAlternativeModal"
import { customFormatter } from "utils/tabulator"

const EditRoadmapModal = ({
  isOpen,
  toggleModal,
  roadmapToEdit,
  setRoadmapToEdit,
  reload,
  showChart,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [isLoading, setIsLoading] = useState(false)
  const [roadmapTypes, setRoadmapTypes] = useState([])
  const [timelineSettings, setTimelineSettings] = useState([])
  const { show: showCategoryModal, toggle: toggleCategoryModal } = useModal()
  const { show: showTaskModal, toggle: toggleTaskModal } = useModal()
  const {
    show: showEditAlternativeModal,
    toggle: toggleShowEditAlternativeModal,
  } = useModal()
  const { show: showAlternativesModal, toggle: toggleAlternativesModal } =
    useModal()
  const [categoryToEdit, setCategoryToEdit] = useState(null)
  const [alternatives, setAlternatives] = useState([])
  const [roadmapTasks, setRoadmapTasks] = useState([])
  const [taskToEdit, setTaskToEdit] = useState(null)
  const [alternativeToEdit, setAlternativeToEdit] = useState(null)

  const tabulatorRef = useRef()
  const tabulatorRefAlts = useRef()

  const newTask = {
    taskID: -1,
    taskTypeID: 41,
    taskStatusID: -1,
    taskName: "",
    requestTypeID: -1,
    taskDescription: "",
    taskDisposition: "",
    taskRisk: "",
    ownerUserID: currentUser.userID,
  }

  const newCategory = { color: "", listItemID: -1, listItemName: "" }

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    if (isOpen) {
      loadTasks(roadmapToEdit.roadmapID)
    }
  }, [isOpen])

  const loadData = async () => {
    let rt = await api.getList(currentUser.userID, "RoadmapType")
    setRoadmapTypes(rt)
    let ts = await api.getList(currentUser.userID, "TimelineSetting")
    setTimelineSettings(ts)
    let a = await api.getAlternativesByUserParams(currentUser.userID, {})
    let i = await api.getIdeasByUser(currentUser.userID, true)
    setAlternatives([...a, ...i])
  }

  const changeRoadmapProp = (prop, val) => {
    setRoadmapToEdit({ ...roadmapToEdit, [prop]: val })
  }

  const addCategory = () => {
    let nextId = -1
    if (
      roadmapToEdit.roadmapCategories != undefined &&
      roadmapToEdit.roadmapCategories != null
    ) {
      roadmapToEdit.roadmapCategories.forEach(rc => {
        if (parseInt(rc.listItemID) <= parseInt(nextId)) {
          nextId = parseInt(rc.listItemID) + -1
        }
      })
    }
    setCategoryToEdit({ ...newCategory, listItemID: nextId })
    toggleCategoryModal()
  }

  const editCategory = category => {
    setCategoryToEdit(category)
    toggleCategoryModal()
  }

  const saveCategory = () => {
    let r = { ...roadmapToEdit }
    let cats =
      r.roadmapCategories == undefined || r.roadmapCategories == null
        ? []
        : [...r.roadmapCategories]

    let found = cats.find(x => x.listItemID == categoryToEdit.listItemID)
    if (found !== undefined) {
      found.listItemName = categoryToEdit.listItemName
      found.color = categoryToEdit.color
    } else {
      cats.push({ ...categoryToEdit })
    }
    const updated = { ...roadmapToEdit, roadmapCategories: cats }
    setRoadmapToEdit(updated)
    saveRoadmap(updated, false)
  }

  const addAlternative = () => {
    if (roadmapToEdit.roadmapID <= 0) {
      swalWithConfirmButton.fire({
        title: `Please save the roadmap before adding projects or ideas.`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    } else {
      if (
        roadmapToEdit.roadmapCategories == null ||
        roadmapToEdit.roadmapCategories.length == 0 ||
        roadmapToEdit.roadmapCategories.find(x => x.listItemID == -1) ==
          undefined
      ) {
        toggleAlternativesModal()
      } else {
        swalWithConfirmButton.fire({
          title: `If you edited categories, please save the roadmap before adding projects or ideas.`,
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Ok",
        })
      }
    }
  }

  const saveAlternatives = async alternativesWithCategories => {
    let tasks = alternativesWithCategories
      .filter(
        x =>
          roadmapToEdit.roadmapAlternatives.find(
            a => a.alternativeID == x.alternativeID
          ) == undefined
      )
      .map(ac => async () => {
        return await api.createRoadmapAlternative(currentUser.userID, {
          roadmapID: roadmapToEdit.roadmapID,
          alternativeID: ac.alternativeID,
          roadmapCategoryID: ac.roadmapCategoryID,
          alternativeName: ac.alternativeName,
          alternativeNum: ac.alternativeNum,
        })
      })
    if (tasks.length > 0) {
      await Promise.all(tasks.map(t => t()))
      let r = await api.getRoadmap(roadmapToEdit.roadmapID)
      setRoadmapToEdit(r)
    }
    toggleAlternativesModal()
  }

  const saveAlternative = async alt => {
    await api.updateRoadmapAlternative(currentUser.userID, alt)
    toggleShowEditAlternativeModal()
    let r = await api.getRoadmap(roadmapToEdit.roadmapID)
    setRoadmapToEdit(r)
  }

  const saveRoadmap = async (roadmap, showToast = true) => {
    if (roadmap.roadmapID > 0) {
      await api.updateRoadmap(currentUser.userID, roadmap)
      if (roadmap.roadmapAlternatives.find(x => x.isDirty) !== undefined) {
        let tasks = roadmap.roadmapAlternatives
          .filter(x => x.isDirty)
          .map(x => async () => {
            return await api.updateRoadmapAlternative(currentUser.userID, x)
          })
        await Promise.all(tasks.map(t => t()))
      }
      let r = await api.getRoadmap(roadmap.roadmapID)
      setRoadmapToEdit(r)
      reload && reload()
      loadTasks(roadmap.roadmapID)
    } else {
      let rid = await api.createRoadmap(currentUser.userID, roadmap)
      let r = await api.getRoadmap(rid)
      setRoadmapToEdit(r)
      reload && reload()
      loadTasks(rid)
    }
    if (showToast === undefined || showToast === true)
      toast.success("Roadmap saved successfuly")
  }

  const deleteRoadmap = async () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this roadmap?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteRoadmap(currentUser.userID, roadmapToEdit.roadmapID)
          toggleModal()
          reload()
        }
      })
  }

  const loadTasks = async roadmapId => {
    if (roadmapId > -1) {
      let tsks = await api.getGeneralTasks(
        TaskTypes.roadmapMilestone,
        "Roadmaps",
        roadmapId,
        currentUser.userID
      )
      setRoadmapTasks(tsks)
    }
  }

  const deleteRoadmapCategory = category => {
    if (
      roadmapToEdit.roadmapAlternatives !== undefined &&
      roadmapToEdit.roadmapAlternatives !== null
    ) {
      let found = roadmapToEdit.roadmapAlternatives.find(
        x => x.roadmapCategoryID == category.listItemID
      )
      if (found !== undefined) {
        swalWithConfirmButton.fire({
          title: `You cannot remove this category because it is being used by some Projects`,
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Ok",
        })
        return
      }
    }

    let cats = [...roadmapToEdit.roadmapCategories]
    let catToRemove = cats.find(x => x.listItemID == category.listItemID)
    if (catToRemove !== undefined) {
      cats.splice(cats.indexOf(catToRemove), 1)
      const updated = {
        ...roadmapToEdit,
        roadmapCategories: cats,
      }
      setRoadmapToEdit(updated)
      saveRoadmap(updated, false)
    }
  }

  const deleteRoadmapAlternative = async alternative => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this alternative?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteRoadmapAlternative(
            currentUser.userID,
            alternative.roadmapAlternativeID
          )
          let r = await api.getRoadmap(roadmapToEdit.roadmapID)
          setRoadmapToEdit(r)
        }
      })
  }

  const editTask = task => {
    if (roadmapToEdit.roadmapID > -1) {
      setTaskToEdit({ ...task, alternativeID: roadmapToEdit.roadmapID })
      toggleTaskModal()
    } else {
      swalWithConfirmButton.fire({
        title: `Please save the roadmap before adding any Tasks`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    }
  }

  const deleteRoadmapTask = async task => {
    await api.deleteTask(currentUser.userID, task.taskID)
    await loadTasks(roadmapToEdit.roadmapID)
  }

  const renderAlternativeRow = (alt, idx) => {
    let category =
      roadmapToEdit.roadmapCategories !== null
        ? roadmapToEdit.roadmapCategories.find(
            rc => rc.listItemID == alt.roadmapCategoryID
          )
        : undefined

    return (
      <tr key={idx}>
        <td>{alt.alternativeNum == "0" ? "IDEA" : alt.alternativeNum}</td>
        <td>
          <a
            href="#"
            onClick={e => {
              e && e.preventDefault()
              setAlternativeToEdit(alt)
              toggleShowEditAlternativeModal()
            }}
          >
            {alt.alternativeName}
          </a>
        </td>
        <td
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "15px",
              width: "15px",
              marginRight: "5px",
              backgroundColor: category ? category.color : "initial",
            }}
          ></div>
          <span>{category && category.listItemName}</span>
        </td>
        <td>
          <i
            className="fas fa-trash"
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => deleteRoadmapAlternative(alt)}
          ></i>
        </td>
      </tr>
    )
  }

  const handleCategoriesReorder = (originalCategory, newIndex) => {
    let oldIndex = roadmapToEdit.roadmapCategories.indexOf(
      roadmapToEdit.roadmapCategories.find(
        x => x.listItemID == originalCategory.listItemID
      )
    )
    if (newIndex == oldIndex) {
      return
    }

    let itemsCopy = [...roadmapToEdit.roadmapCategories]
    itemsCopy.splice(oldIndex, 1)
    itemsCopy.splice(newIndex, 0, originalCategory)
    itemsCopy.forEach((ic, idx) => {
      ic.orderID = idx
    })

    setRoadmapToEdit({ ...roadmapToEdit, roadmapCategories: itemsCopy })
    console.log(itemsCopy)
  }

  const handleAlternativesReorder = (originalAlternative, newIndex) => {
    let oldIndex = roadmapToEdit.roadmapAlternatives.indexOf(
      roadmapToEdit.roadmapAlternatives.find(
        x => x.roadmapAlternativeID == originalAlternative.roadmapAlternativeID
      )
    )
    if (newIndex == oldIndex) {
      return
    }

    let itemsCopy = [...roadmapToEdit.roadmapAlternatives]
    itemsCopy.splice(oldIndex, 1)
    itemsCopy.splice(newIndex, 0, originalAlternative)
    itemsCopy.forEach((ic, idx) => {
      ic.orderID = idx
      ic.isDirty = true
    })

    setRoadmapToEdit({ ...roadmapToEdit, roadmapAlternatives: itemsCopy })
  }

  const categoryColumns = [
    {
      rowHandle: true,
      formatter: "handle",
      headerSort: false,
      frozen: true,
      width: 50,
    },
    {
      title: "Category",
      field: "listItemName",
      headerSort: false,
      formatter: customFormatter(({ rowData, cellValue }) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "15px",
              width: "15px",
              marginRight: "5px",
              backgroundColor:
                rowData.color !== undefined &&
                rowData.color !== null &&
                rowData.color !== ""
                  ? rowData.color
                  : "initial",
            }}
          ></div>
          <a
            href="#"
            onClick={e => {
              e && e.preventDefault()
              editCategory(rowData)
            }}
          >
            {cellValue}
          </a>
        </div>
      )),
    },
    {
      title: "",
      field: "listItemID",
      width: 50,
      headerSort: false,
      formatter: customFormatter(({ rowData, cellValue }) => (
        <i
          className="fas fa-trash"
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => deleteRoadmapCategory(rowData)}
        ></i>
      )),
    },
  ]

  const alternativeColumns = [
    {
      rowHandle: true,
      formatter: "handle",
      headerSort: false,
      frozen: true,
      width: 50,
    },
    {
      title: "ID",
      field: "alternativeNum",
      headerSort: false,
      width: 50,
    },
    {
      title: "Project",
      field: "alternativeName",
      headerSort: false,
      formatter: customFormatter(({ rowData, cellValue }) => (
        <a
          href="#"
          onClick={e => {
            e && e.preventDefault()
            setAlternativeToEdit(rowData)
            toggleShowEditAlternativeModal()
          }}
        >
          {cellValue}
        </a>
      )),
    },
    {
      title: "Version",
      field: "version",
      headerSort: false,
      width: 100,
      formatter: (cell, formatterParams, onRendered) => {
        return cell.getValue()
      },
    },
    {
      title: "Category",
      field: "listItemName",
      headerSort: false,
      width: 150,
      formatter: customFormatter(({ rowData, cellValue }) => {
        let alt = rowData
        let category =
          roadmapToEdit.roadmapCategories !== null
            ? roadmapToEdit.roadmapCategories.find(
                rc => rc.listItemID == alt.roadmapCategoryID
              )
            : undefined
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: "15px",
                width: "15px",
                marginRight: "5px",
                backgroundColor: category ? category.color : "initial",
              }}
            ></div>
            <span>{category && category.listItemName}</span>
          </div>
        )
      }),
    },
    {
      title: "",
      field: "listItemID",
      width: 50,
      headerSort: false,
      formatter: customFormatter(({ rowData, cellValue }) => (
        <i
          className="fas fa-trash"
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => deleteRoadmapAlternative(rowData)}
        ></i>
      )),
    },
  ]

  return (
    <>
      <Modal
        backdrop="static"
        isOpen={isOpen}
        size="xl"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Roadmap
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {roadmapToEdit != null && (
          <div className="modal-body">
            <div className="mb-3">
              <Label className="form-label">Title</Label>
              {showChart != undefined && roadmapToEdit.roadmapID > 0 && (
                <button
                  type="button"
                  className="btn btn-primary save-user"
                  style={{ marginBottom: "5px" }}
                  onClick={() => showChart(roadmapToEdit.roadmapID)}
                >
                  <i className="mdi mdi-chart-timeline"></i>
                  &nbsp; View
                </button>
              )}
              <Input
                name="roadmapname"
                type="text"
                onChange={e => changeRoadmapProp("roadmapName", e.target.value)}
                value={roadmapToEdit.roadmapName}
              />
            </div>

            <div className="mb-3">
              <Label className="form-label">Description</Label>
              <TextareaAutosize
                className="form-control"
                minRows={3}
                onChange={e => changeRoadmapProp("description", e.target.value)}
                value={roadmapToEdit.description}
              />
            </div>

            <div className="mb-3">
              <Label className="form-label">Milestone Display</Label>
              <div>
                Note: All options include the Planned Start and Planned Finish
                Dates
              </div>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={roadmapToEdit.roadmapTypeID}
                onChange={e => {
                  changeRoadmapProp("roadmapTypeID", e.target.value)
                }}
              >
                <option value="-1">Select Type...</option>
                {roadmapTypes.map((a, idx) => {
                  return (
                    <option key={idx} value={`${a.listItemID}`}>
                      {a.listItemName}
                    </option>
                  )
                })}
              </select>
            </div>

            <div className="mb-3">
              <Label className="form-label">Timescale</Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={roadmapToEdit.timelineSetting}
                onChange={e => {
                  changeRoadmapProp("timelineSetting", e.target.value)
                }}
              >
                <option value="-1">Select Setting...</option>
                {timelineSettings.map((a, idx) => {
                  return (
                    <option key={idx} value={`${a.listItemID}`}>
                      {a.listItemName}
                    </option>
                  )
                })}
              </select>
            </div>

            {roadmapToEdit.roadmapCategories !== null &&
              roadmapToEdit.roadmapCategories.length > 0 && (
                <ReactTabulator
                  onRef={r => (tabulatorRef.current = r.current)}
                  columns={categoryColumns}
                  data={roadmapToEdit.roadmapCategories}
                  options={{
                    // pagination: "local",
                    // paginationSize: 30,
                    // paginationSizeSelector: [5, 10, 20, 30, 50, 100, 200, 300],
                    movableRows: true,
                    layout: "fitColumns",
                    cssClass: "table-borderless",
                  }}
                  events={{
                    rowMoved: function (row) {
                      handleCategoriesReorder(
                        roadmapToEdit.roadmapCategories.find(
                          x => x.listItemID == row.getData().listItemID
                        ),
                        row.getPosition()
                      )
                    },
                  }}
                />
              )}
            {/* <table
              className="table table-bordered low-padding-table"
              style={{
                backgroundColor: "white",
                borderCollapse: "collapse",
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: "85%" }}>Categories</th>
                  <th style={{ width: "15%" }}></th>
                </tr>
              </thead>
              <tbody>
                {roadmapToEdit.roadmapCategories !== null &&
                  roadmapToEdit.roadmapCategories.length > 0 &&
                  roadmapToEdit.roadmapCategories.map((r, idx) => (
                    <tr key={idx}>
                      <td>
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault()
                            editCategory(r)
                          }}
                        >
                          {r.listItemName}
                        </a>
                      </td>
                      <td>
                        <i
                          className="fas fa-trash"
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => deleteRoadmapCategory(r)}
                        ></i>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table> */}

            <div className="mb-3 mt-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={addCategory}
              >
                <i className="fas fa-plus"></i>
                &nbsp;Add Category
              </button>
            </div>

            <table
              className="table table-bordered low-padding-table"
              style={{ backgroundColor: "white" }}
            >
              <thead>
                <tr>
                  <th style={{ width: "30%" }}>Milestone</th>
                  <th style={{ width: "30%", textAlign: "center" }}>Date</th>
                  <th style={{ width: "30%", textAlign: "center" }}>Status</th>
                  <th style={{ width: "10%" }}></th>
                </tr>
              </thead>
              <tbody>
                {roadmapTasks != null &&
                  roadmapTasks.map((task, idx) => (
                    <tr key={idx}>
                      <td>
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault()
                            editTask(task)
                          }}
                        >
                          {task.taskName}
                        </a>
                      </td>
                      <td className="text-center">
                        {}
                        {moment(task.completeDT).isValid()
                          ? moment(task.completeDT).format("MM/DD/YYYY")
                          : ""}
                      </td>
                      <td>
                        <div
                          style={{ marginLeft: "5px", textAlign: "center" }}
                          dangerouslySetInnerHTML={{
                            __html: task.taskStatus,
                          }}
                        ></div>
                      </td>
                      <td>
                        <i
                          className="fas fa-trash"
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => deleteRoadmapTask(task)}
                        ></i>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <div className="mb-3 mt-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => editTask({ ...newTask })}
              >
                <i className="fas fa-plus"></i>
                &nbsp;Add Milestone
              </button>
            </div>

            {roadmapToEdit.roadmapAlternatives !== null &&
              roadmapToEdit.roadmapAlternatives.length > 0 && (
                <ReactTabulator
                  onRef={r => (tabulatorRefAlts.current = r.current)}
                  columns={alternativeColumns}
                  data={roadmapToEdit.roadmapAlternatives}
                  options={{
                    // pagination: "local",
                    // paginationSize: 30,
                    // paginationSizeSelector: [5, 10, 20, 30, 50, 100, 200, 300],
                    movableRows: true,
                    layout: "fitColumns",
                    cssClass: "table-borderless",
                  }}
                  events={{
                    rowMoved: function (row) {
                      handleAlternativesReorder(
                        roadmapToEdit.roadmapAlternatives.find(
                          x =>
                            x.roadmapAlternativeID ==
                            row.getData().roadmapAlternativeID
                        ),
                        row.getPosition()
                      )
                    },
                  }}
                />
              )}

            <div className="mb-3 mt-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={addAlternative}
              >
                <i className="fas fa-plus"></i>
                &nbsp;Add / Update Project or Idea
              </button>
            </div>
          </div>
        )}
        <div
          className="modal-footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={toggleModal}
            >
              Close
            </button>
            {roadmapToEdit != null && roadmapToEdit.roadmapID > -1 && (
              <button
                className="btn btn-outline-danger save-user"
                onClick={deleteRoadmap}
              >
                Delete
              </button>
            )}
          </div>
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={() => saveRoadmap(roadmapToEdit)}
          >
            Save
          </button>
        </div>
      </Modal>
      <EditCategoryModal
        isOpen={showCategoryModal}
        toggleModal={toggleCategoryModal}
        categoryToEdit={categoryToEdit}
        setCategoryToEdit={setCategoryToEdit}
        saveCategory={saveCategory}
      />

      <SelectAlternativesModal
        isOpen={showAlternativesModal}
        toggleModal={toggleAlternativesModal}
        alternatives={alternatives}
        roadmapToEdit={roadmapToEdit}
        saveAlternatives={saveAlternatives}
      />

      <EditTaskModal
        isEditModalVisible={showTaskModal}
        toggleEditModal={toggleTaskModal}
        taskToEdit={taskToEdit}
        setTaskToEdit={setTaskToEdit}
        reload={() => loadTasks(roadmapToEdit.roadmapID)}
      />

      <EditAlternativeModal
        isOpen={showEditAlternativeModal}
        toggleModal={toggleShowEditAlternativeModal}
        roadmapToEdit={roadmapToEdit}
        alternative={alternativeToEdit}
        saveAlternative={saveAlternative}
      />
    </>
  )
}

export default EditRoadmapModal
