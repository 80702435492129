module.exports = {
  okta: {
    //OCC QA
    //clientId: "0oak5ii8f5BavoJgH297",
    //issuer: "https://login.occ.gov/oauth2/default",

    //OCC PROD
     clientId: "0oak9qyflcI9F0m65297",
     issuer: "https://login.occ.gov/oauth2/default",

    // DEV
   // clientId: "0oad7ze1moaGFalq7697",
    //issuer: "https://trial-5292440.okta.com/oauth2/default",

    

    redirectUri: `${window.location.origin}/loginokta/redirect`,
    scopes: ["openid", "profile", "email"],
    response_type: "code",
    useInteractionCodeFlow: true,
    pkce: true,
    postLogoutRedirectUri: `${window.location.origin}/login?logout=true`,
  },
}
