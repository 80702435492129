import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Modal } from "reactstrap"
import api from "services/api.service"
import colors from "utils/colors"

const AltH2hChart = ({
  isOpen,
  toggleModal,
  scenarioId,
  alternativeList,
  optObjFin,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isOpen) {
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    setIsLoading(true)
    let chartResponse = await api.getAltH2hChart(
      scenarioId,
      alternativeList[0],
      alternativeList[1],
      optObjFin != null && optObjFin.obj != null ? optObjFin.obj.objModelID : 0
    )
    let dataArray1 = JSON.parse(
      "[" + chartResponse.chartArray.replaceAll("'", '"') + "]"
    )
    let chartDataItems = chartResponse.chartData
      .replace("ScoreName:", "")
      .replace("AlternativeName1:", "")
      .replace("AlternativeName2:", "")
      .split(",")
    var dataSet = anychart.data.set(dataArray1)

    var seriesData_1 = dataSet.mapAs({
      x: 0,
      value: 1,
    })
    var seriesData_2 = dataSet.mapAs({
      x: 0,
      value: 2,
    })

    var chart = anychart.bar()
    chart.animation(true)
    chart.padding([10, 20, 5, 20])
    // force chart to stack values by Y scale.
    chart.yScale().stackMode("value")
    // format y axis labels so they are always positive
    chart
      .yAxis()
      .labels()
      .format(function () {
        return Math.abs(this.value).toLocaleString()
      })
    // set title for Y-axis
    chart.yAxis(0).title(chartDataItems[0])
    chart.yAxis(0).title().fontWeight("bold")
    chart.yAxis(0).title().fontSize(16)
    chart.labels().fontSize(14)
    chart.labels().fontWeight("bold")
    chart.labels().fontFamily("Inter")
    // allow labels to overlap
    chart.xAxis(0).overlapMode("allow-overlap")
    chart.yAxis(0).title().fontSize(14)
    var ylab = chart.yAxis().labels()
    // ylab.fontWeight("bold")
    ylab.fontFamily("Inter")
    ylab.fontSize(14)
    var xlab = chart.xAxis().labels()
    // xlab.fontWeight("bold")
    xlab.fontSize(14)
    xlab.fontFamily("Inter")
    // turn on extra axis for the symmetry
    chart
      .xAxis(1)
      .enabled(true)
      .orientation("right")
      .overlapMode("allow-overlap")
    var x1lab = chart.xAxis(1).labels()
    // x1lab.fontWeight("bold")
    x1lab.fontSize(14)
    x1lab.fontFamily("Inter")
    // set chart title text
    chart.title("Head-to-Head Comparison")
    var ctitle = chart.title()
    ctitle.fontSize(16)
    ctitle.fontWeight("bold")
    chart.interactivity().hoverMode("by-x")
    chart
      .tooltip()
      .title(false)
      .separator(false)
      .displayMode("separated")
      .positionMode("point")
      .useHtml(true)
      .fontSize(16)
      .offsetX(5)
      .offsetY(0)
      .format(function () {
        return (
          '<span style="color: #D9D9D9"></span>' +
          Math.abs(this.value).toLocaleString()
        )
      })

    var series
    series = chart.bar(seriesData_1)
    series.name(chartDataItems[1]).color("#BE0000")
    series.tooltip().position("right").anchor("left-center")

    series = chart.bar(seriesData_2)
    series.name(chartDataItems[2]).color("#1F75D1")
    series.tooltip().position("left").anchor("right-center")
    // turn on legend
    chart
      .legend()
      .enabled(true)
      .inverted(true)
      .fontSize(16)
      .fontFamily("Inter")
      .fontWeight("bold")
      .padding([0, 0, 20, 0])
    chart.container("chartdiv")
    chart.credits().enabled(false)

    chart.title().fontFamily("Inter")
    chart.legend().fontFamily("Inter")

    chart.draw()

    setIsLoading(false)
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      fullscreen={true}
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          H2H Chart
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        <div
          id="chartdiv"
          style={{ width: "100%", minHeight: "600px", height: "100%" }}
        ></div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
      </div>
    </Modal>
  )
}

export default AltH2hChart
