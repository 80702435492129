import React from "react"
import { Modal, Row } from "reactstrap"
import altGroupConsensusRatingList from "./AltGroupConsensusRatingList"

const AltGroupConsensusRatingModal = ({ isOpen, toggleModal }) => {
  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Consensus Ratings
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <table
          className="table table-bordered low-padding-table"
          style={{ backgroundColor: "white" }}
        >
          <thead>
            <tr>
              <th style={{ width: "50%", textAlign: "center" }}>
                Standard deviation
              </th>
              <th style={{ width: "50%", textAlign: "center" }}>
                Consensus rating
              </th>
            </tr>
          </thead>
          <tbody>
            {altGroupConsensusRatingList.map((r, idx) => {
              return (
                <tr key={idx}>
                  <td style={{ textAlign: "center" }}>
                    {r.maxValue != undefined
                      ? `< ${r.maxValue}`
                      : r.minOrEqualValue != undefined
                      ? `>= ${r.minOrEqualValue}`
                      : ""}
                  </td>
                  <td
                    style={{
                      // backgroundColor: r.backgroundColor,
                      // color: r.color,
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "13px",
                        backgroundColor: r.backgroundColor,
                        padding: "4px 20px",
                        width: "150px",
                        margin: "0 auto",
                        borderRadius: "15px",
                        color: r.color,
                      }}
                    >
                      {r.text}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="modal-footer">
        <Row>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
            }}
          >
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={toggleModal}
            >
              Close
            </button>
          </div>
        </Row>
      </div>
    </Modal>
  )
}

export default AltGroupConsensusRatingModal
