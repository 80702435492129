import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import api from "services/api.service"
import { apiError } from "store/actions"
import { loadPageItemSuccess } from "./actions"
import { LOAD_PAGE_ITEM } from "./actionTypes"

function* loadPageItem(pageParams) {
  try {
    const response = yield call(
      api.getPageItem,
      pageParams.payload.userId,
      pageParams.payload.scenarioId,
      pageParams.payload.alternativeId,
      pageParams.payload.viewName
    )
    yield put(loadPageItemSuccess({ ...pageParams.payload, ...response }))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* pageItemSaga() {
  yield takeEvery(LOAD_PAGE_ITEM, loadPageItem)
}

export default pageItemSaga
