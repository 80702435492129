import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import { currencyFormatter } from "utils/formatters"

const LookbackSection = ({ activeTab, alternativeId, businessCaseID, scenario }) => {
  const currentUser = userService.getLoggedInUser()
  const [lookbacks, setLookbacks] = useState([])
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [lookbackToEdit, setLookbackToEdit] = useState(null)

  useEffect(() => {
    if (activeTab == "Lookback") {
      loadData()
    }
  }, [activeTab, alternativeId])

  const loadData = async () => {
    if (alternativeId > 0) {
      let looks = await api.getAlternativeLookbacks(alternativeId)
      setLookbacks(looks)
      await loadCharts()
    }
  }

  const loadCharts = () => {
    api.getAlternativeChart(alternativeId, "Lookback_Cost").then(data => {
      loadcost(
        anychart.data.set(JSON.parse(data.chartArray.replaceAll("'", '"')))
      )
    })
    api.getAlternativeChart(alternativeId, "Lookback_Benefit").then(data => {
      loadbenefit(
        anychart.data.set(JSON.parse(data.chartArray.replaceAll("'", '"')))
      )
    })
    api.getAlternativeChart(alternativeId, "Lookback_Return").then(data => {
      loadreturn(
        anychart.data.set(JSON.parse(data.chartArray.replaceAll("'", '"')))
      )
    })
    api.getAlternativeChart(alternativeId, "Lookback_ROI").then(data => {
      loadroi(
        anychart.data.set(JSON.parse(data.chartArray.replaceAll("'", '"')))
      )
    })
    api.getAlternativeChart(alternativeId, "Lookback_IRR").then(data => {
      loadirr(
        anychart.data.set(JSON.parse(data.chartArray.replaceAll("'", '"')))
      )
    })
    api.getAlternativeChart(alternativeId, "Lookback_Payback").then(data => {
      loadpayback(
        anychart.data.set(JSON.parse(data.chartArray.replaceAll("'", '"')))
      )
    })
    api.getAlternativeChart(alternativeId, "Lookback_Timing").then(data => {
      loadtimelinechart(
        JSON.parse(
          data.chartArray
            .replaceAll("id", '"id"')
            .replaceAll("name", '"name"')
            .replaceAll("progressValue", '"progressValue"')
            .replaceAll("actualStart", '"actualStart"')
            .replaceAll("actualEnd", '"actualEnd"')
            .replaceAll("'", '"')
        )
      )
    })
  }

  const toggleModal = () => setIsEditModalVisible(!isEditModalVisible)

  const editLookback = lookback => {
    setLookbackToEdit(lookback)
    toggleModal()
  }

  const addNewLookback = () => {
    setLookbackToEdit({ lookbackDate: new Date(), comments: "" })
    toggleModal()
  }

  const saveLookback = async () => {
    if (
      lookbackToEdit.lookbackID == undefined ||
      lookbackToEdit.lookbackID < 0
    ) {

      let url = await api.generateBusinessCaseReportBackup(
        businessCaseID
      )

      lookbackToEdit.revisionReportPath = url

      await api.createAlternativeLookback(currentUser.userID, {
        ...lookbackToEdit,
        alternativeID: alternativeId,
      })
      toast.success("Lookback created successfully")
      loadData()
      toggleModal()
    } else {
      await api.updateAlternativeLookback(currentUser.userID, lookbackToEdit)
      toast.success("Lookback updated successfully")
      loadData()
      toggleModal()
    }
  }

  const deleteLookback = lookback => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this lookback?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteAlternativeLookback(
            currentUser.userID,
            lookback.lookbackID
          )
          loadData()
        }
      })
  }

  const loadcost = chartData => {
    // create data set on our data
    anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
    document.getElementById("costchart1").innerHTML = ""
    var chart = anychart.column()

    // var series = chart.column(chartData)
    chart.data(chartData)
    var yLabels = chart.yAxis().labels()
    yLabels.format(function () {
      return "$" + anychart.format.number(this.value, 2, ".", ",")
    })

    chart.tooltip().format(function (e) {
      var value = anychart.format.number(this.value, 2, ".", ",")
      return "Cost: $" + value
    })

    chart.background().stroke(".5 gray")

    chart.animation(true)

    chart.container("costchart1")
    chart.credits().enabled(false)

    chart.title("Cost")

    yLabels.fontFamily("Inter")
    chart.title().fontFamily("Inter")
    chart.legend().fontFamily("Inter")
    chart.xAxis().labels().fontFamily("Inter")

    chart.draw()
  }

  const loadbenefit = chartData => {
    // create data set on our data
    anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
    document.getElementById("benefitchart1").innerHTML = ""
    var chart = anychart.column()

    // var series = chart.column(chartData)
    chart.data(chartData)
    var yLabels = chart.yAxis().labels()
    yLabels.format(function () {
      return "$" + anychart.format.number(this.value, 2, ".", ",")
    })

    chart.tooltip().format(function (e) {
      var value = anychart.format.number(this.value, 2, ".", ",")
      return "Benefit: $" + value
    })

    chart.background().stroke(".5 gray")

    chart.animation(true)

    chart.container("benefitchart1")
    chart.credits().enabled(false)

    chart.title("Benefit")

    yLabels.fontFamily("Inter")
    chart.title().fontFamily("Inter")
    chart.legend().fontFamily("Inter")
    chart.xAxis().labels().fontFamily("Inter")

    chart.draw()
  }

  const loadreturn = chartData => {
    // create data set on our data
    anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
    document.getElementById("returnchart1").innerHTML = ""
    var chart = anychart.column()

    // var series = chart.column(chartData)
    chart.data(chartData)
    var yLabels = chart.yAxis().labels()
    yLabels.format(function () {
      return "$" + anychart.format.number(this.value, 2, ".", ",")
    })

    chart.tooltip().format(function (e) {
      var value = anychart.format.number(this.value, 2, ".", ",")
      return "Return: $" + value
    })

    // set lowest line marker
    // var lineMarker = chart.lineMarker();
    // lineMarker.value(<%=HiddenHurdleReturn.Value%>);
    // lineMarker.stroke({
    //     // set stroke thickness
    //     thickness: 2,
    //     // set stroke color
    //     color: "#FF0000",
    //     // set dashes and gaps
    //     // dash: "2 4"
    // });

    chart.background().stroke(".5 gray")

    chart.animation(true)

    chart.container("returnchart1")
    chart.credits().enabled(false)

    chart.title("Return")

    yLabels.fontFamily("Inter")
    chart.title().fontFamily("Inter")
    chart.legend().fontFamily("Inter")
    chart.xAxis().labels().fontFamily("Inter")

    chart.draw()
  }

  const loadroi = chartData => {
    // create data set on our data
    anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
    document.getElementById("roichart").innerHTML = ""
    var chart = anychart.column()

    // var series = chart.column(chartData)
    chart.data(chartData)
    var yLabels = chart.yAxis().labels()
    yLabels.format(function () {
      return anychart.format.number(this.value, 2, ".", ",") + "%"
    })

    chart.tooltip().format(function (e) {
      var value = anychart.format.number(this.value, 2, ".", ",")
      return "ROI: " + value + "%"
    })

    // set lowest line marker
    // var lineMarker = chart.lineMarker();
    // lineMarker.value(<%=HiddenHurdleROI.Value%>);
    // lineMarker.stroke({
    //     // set stroke thickness
    //     thickness: 2,
    //     // set stroke color
    //     color: "#FF0000",
    //     // set dashes and gaps
    //     // dash: "2 4"
    // });

    chart.background().stroke(".5 gray")

    chart.animation(true)

    chart.container("roichart")
    chart.credits().enabled(false)

    chart.title("ROI")

    yLabels.fontFamily("Inter")
    chart.title().fontFamily("Inter")
    chart.legend().fontFamily("Inter")
    chart.xAxis().labels().fontFamily("Inter")

    chart.draw()
  }

  const loadirr = chartData => {
    // create data set on our data
    anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
    document.getElementById("irrchart").innerHTML = ""
    var chart = anychart.column()

    // var series = chart.column(chartData)
    chart.data(chartData)
    var yLabels = chart.yAxis().labels()
    yLabels.format(function () {
      return anychart.format.number(this.value, 2, ".", ",") + "%"
    })

    chart.tooltip().format(function (e) {
      var value = anychart.format.number(this.value, 2, ".", ",")
      return "IRR: " + value + "%"
    })

    // set lowest line marker
    // var lineMarker = chart.lineMarker();
    // lineMarker.value(<%=HiddenHurdleIRR.Value%>);
    // lineMarker.stroke({
    //     // set stroke thickness
    //     thickness: 2,
    //     // set stroke color
    //     color: "#FF0000",
    //     // set dashes and gaps
    //     // dash: "2 4"
    // });

    chart.background().stroke(".5 gray")

    chart.animation(true)

    chart.container("irrchart")
    chart.credits().enabled(false)

    chart.title("IRR")

    yLabels.fontFamily("Inter")
    chart.title().fontFamily("Inter")
    chart.legend().fontFamily("Inter")
    chart.xAxis().labels().fontFamily("Inter")

    chart.draw()
  }

  const loadpayback = chartData => {
    // create data set on our data
    anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
    document.getElementById("paybackchart1").innerHTML = ""
    var chart = anychart.column()

    // var series = chart.column(chartData)
    chart.data(chartData)
    chart.tooltip().format(function (e) {
      var value = anychart.format.number(this.value, 2, ".", ",")
      return "Payback Period: " + value
    })

    // set lowest line marker
    // var lineMarker = chart.lineMarker();
    // lineMarker.value(<%=HiddenHurdlePayback.Value%>);
    // lineMarker.stroke({
    //     // set stroke thickness
    //     thickness: 2,
    //     // set stroke color
    //     color: "#FF0000",
    //     // set dashes and gaps
    //     // dash: "2 4"
    // });

    chart.background().stroke(".5 gray")

    chart.animation(true)

    chart.container("paybackchart1")
    chart.credits().enabled(false)

    chart.title("Payback Period")

    chart.title().fontFamily("Inter")
    chart.legend().fontFamily("Inter")
    chart.xAxis().labels().fontFamily("Inter")

    chart.draw()
  }

  const loadtimelinechart = data => {
    document.getElementById("timelinechart").innerHTML = ""
    // create a data tree
    var treeData = anychart.data.tree(data, "as-tree")
    // create a chart
    var chart = anychart.ganttProject()
    // set the data
    chart.data(treeData)

    var ganttDiv = document.getElementById("timelinechart")
    var items = data.length
    var gh = 160 + 20 * items
    ganttDiv.style.height = gh + "px"

    var newColumn = chart.dataGrid().column(0)
    newColumn.width(220)
    newColumn.title("Lookback")
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontWeight(600)
    newColumn.labels().useHtml(true)
    newColumn.labels().fontColor("#000000")
    newColumn.labels().format("<div style='text-align: center'>{%id}</div>")
    newColumn.depthPaddingMultiplier(20)
    newColumn.collapseExpandButtons(true)

    var newColumn = chart.dataGrid().column(1)
    newColumn.width(110)
    newColumn.title("Start")
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontWeight(600)
    newColumn.labels().useHtml(true)
    newColumn.labels().fontColor("#000000")
    newColumn
      .labels()
      .format(
        "<div style='text-align: center'>{%actualStart}{dateTimeFormat:MM/dd/yyyy}</div>"
      )
    newColumn.depthPaddingMultiplier(20)
    newColumn.collapseExpandButtons(true)
    // newColumn.tooltip("hide");

    var newColumn2 = chart.dataGrid().column(2)
    newColumn2.width(110)
    newColumn2.title("Finish")
    newColumn2.title().fontColor("#64b5f6")
    newColumn2.title().fontWeight(600)
    newColumn2.labels().useHtml(true)
    newColumn2.labels().fontColor("#000000")
    newColumn2
      .labels()
      .format(
        "<div style='text-align: center'>{%actualEnd}{dateTimeFormat:MM/dd/yyyy}</div>"
      )
    newColumn2.depthPaddingMultiplier(20)
    newColumn2.collapseExpandButtons(true)
    //newColumn2.tooltip("hide");

    chart.dataGrid().tooltip(false)

    chart.splitterPosition(440)

    chart.getTimeline().tooltip().useHtml(true)
    chart
      .getTimeline()
      .tooltip()
      .format(
        "<span>" +
          "{%actualStart}{dateTimeFormat:MM/dd/yyyy} - " +
          "{%actualEnd}{dateTimeFormat:MM/dd/yyyy}</span><br><br>" +
          "% Complete: {%progress}"
      )

    var header = chart.getTimeline().header()
    var mlevel = header.level(2)
    //mlevel.enabled(false);

    // set zoom levels of the scale
    chart
      .getTimeline()
      .scale()
      .zoomLevels([
        [
          { unit: "month", count: 1 },
          { unit: "quarter", count: 1 },
          { unit: "year", count: 1 },
        ],
      ])
    // header.level(0).format("{%tickValue}{dateTimeFormat:MMM}");

    if (scenario.fyMonth > 6) {
      var header = chart.getTimeline().header();
      header.level(2).format(function(){return +this.value + 1});
    }

    chart.xScale().fiscalYearStartMonth(scenario.fyMonth);

    // chart.margin('100px');
    chart.background().stroke(".5 gray")
    chart.container("timelinechart")
    // initiate drawing the chart
    chart.title().fontFamily("Inter")
    chart.legend().fontFamily("Inter")
   // chart.xAxis().labels().fontFamily("Inter")

    chart.draw()
    chart.credits().enabled(false)
    // fit elements to the width of the timeline
    chart.fitAll()
  }

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col>
          <button className="btn btn-primary" onClick={addNewLookback}>
            + Add Lookback
          </button>
        </Col>
      </Row>
      <Row>
        <Col>
          {lookbacks.length > 0 && (
            <table
              className="table table-bordered low-padding-table"
              style={{ backgroundColor: "white" }}
            >
              <thead>
                <tr>
                  <th style={{ width: "6%" }}>Date</th>
                  <th style={{ width: "6%" }}>Cost ($)</th>
                  <th style={{ width: "6%" }}>Benefit ($)</th>
                  <th style={{ width: "6%" }}>Return ($)</th>
                  <th style={{ width: "6%" }}>ROI (%)</th>
                  <th style={{ width: "6%" }}>IRR (%)</th>
                  <th style={{ width: "8%" }}>Payback Period</th>
                  <th style={{ width: "8%" }}>Planned Start</th>
                  <th style={{ width: "7%" }}>Planned Finish</th>
                  <th style={{ width: "6%" }}>% Complete</th>
                  <th style={{ width: "6%" }}>Project Cost ($)</th>
                  <th style={{ width: "8%" }}>Project Stage</th>
                  <th style={{ width: "14%" }}>Comments</th>
                  <th style={{ width: "2%" }}></th>
                  <th style={{ width: "2%" }}></th>
                  <th style={{ width: "2%" }}></th>
                </tr>
              </thead>
              <tbody>
                {lookbacks.map((l, idx) => (
                  <tr key={idx}>
                    <td>
                      <b>{moment(l.lookbackDate).format("MM/DD/YYYY")}</b>
                    </td>
                    <td>{currencyFormatter.format(l.cost.toFixed(2))}</td>
                    <td>{currencyFormatter.format(l.benefit.toFixed(2))}</td>
                    <td>
                      {currencyFormatter.format(l.returnAmount.toFixed(2))}
                    </td>
                    <td>{l.roi.toFixed(2)}%</td>
                    <td>{l.irr.toFixed(2)}%</td>
                    <td>{l.paybackyears.toFixed(2)}</td>
                    <td>{moment(l.desiredStart).format("MM/DD/YYYY")}</td>
                    <td>{moment(l.plannedFinish).format("MM/DD/YYYY")}</td>
                    <td>{l.percentComplete.toFixed(2)}%</td>
                    <td>{currencyFormatter.format(l.projectCost.toFixed(2))}</td>
                    <td>{l.alternativePhaseName}</td>
                    <td>{l.comments}</td>
                    <td>
                      {l.revisionReportPath !== undefined &&
                        l.revisionReportPath !== null &&
                        l.revisionReportPath.length > 4 && (
                          <a
                            href={l.revisionReportPath}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i
                              className="fas fa-file-pdf"
                              style={{
                                color: colors.primary,
                                cursor: "pointer",
                              }}
                            ></i>
                          </a>
                        )}
                    </td>
                    <td>
                      <i
                        className="fas fa-edit"
                        style={{ color: colors.primary, cursor: "pointer" }}
                        onClick={() => editLookback(l)}
                      ></i>
                    </td>
                    <td>
                      <i
                        className="fas fa-trash"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => deleteLookback(l)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <div id="costchart1" style={{ height: "500px" }}></div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div id="benefitchart1" style={{ height: "500px" }}></div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div id="returnchart1" style={{ height: "500px" }}></div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div id="roichart" style={{ height: "500px" }}></div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div id="irrchart" style={{ height: "500px" }}></div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div id="paybackchart1" style={{ height: "500px" }}></div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div id="timelinechart" style={{ height: "500px" }}></div>
        </Col>
      </Row>
      <Modal
        backdrop="static"
        isOpen={isEditModalVisible}
        size="md"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Lookback
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {lookbackToEdit != null && (
          <div className="modal-body">
            <div className="mb-3">
              <Label className="form-label">Lookback Date</Label>
              <Input
                className="form-control"
                type="date"
                value={moment(lookbackToEdit.lookbackDate).format("YYYY-MM-DD")}
                onChange={e => {
                  setLookbackToEdit({
                    ...lookbackToEdit,
                    lookbackDate: moment(e.target.value, "YYYY-MM-DD").format(
                      "YYYY-MM-DDThh:mm:ss"
                    ),
                  })
                }}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Comments</Label>
              <Input
                className="form-control"
                type="textarea"
                rows={5}
                value={lookbackToEdit.comments}
                onChange={e => {
                  setLookbackToEdit({
                    ...lookbackToEdit,
                    comments: e.target.value,
                  })
                }}
              />
            </div>
          </div>
        )}
        <div className="modal-footer">
          <div>
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={toggleModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary save-user"
              onClick={saveLookback}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default LookbackSection
