const altGroupConsensusRatingList = [
  {
    text: "High",
    color: "white",
    backgroundColor: "blue",
    maxValue: 0.25,
  },
  {
    text: "Medium-High",
    color: "black",
    backgroundColor: "lightblue",
    maxValue: 0.5,
  },
  {
    text: "Medium",
    color: "white",
    backgroundColor: "green",
    maxValue: 0.75,
  },
  {
    text: "Medium-Low",
    color: "black",
    backgroundColor: "lightgreen",
    maxValue: 1,
  },
  {
    text: "Low",
    color: "black",
    backgroundColor: "yellow",
    maxValue: 1.25,
  },
  {
    text: "Very Low",
    color: "white",
    backgroundColor: "orange",
    maxValue: 1.5,
  },
  {
    text: "Negligible",
    color: "white",
    backgroundColor: "red",
    minOrEqualValue: 1.5,
  },
]

export default altGroupConsensusRatingList
