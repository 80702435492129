import PaginatedTable from "components/custom/PaginatedTable"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Col, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import { sortData } from "utils/sort"

const SelectAlternativesModal = ({
  isOpen,
  toggleModal,
  alternatives,
  roadmapToEdit,
  saveAlternatives,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [mappedAlternatives, setMappedAlternatives] = useState([])
  const [search, setSearch] = useState("")
  const [scenarios, setScenarios] = useState([])
  const [selectedScenarioId, setSelectedScenarioId] = useState(-1) //-2 Ideas, -1 Selected

  const categories =
    roadmapToEdit == null || roadmapToEdit.roadmapCategories == null
      ? []
      : roadmapToEdit.roadmapCategories.filter(x => x.listItemID > -1)

  useEffect(() => {
    if (isOpen) {
      mapAlternatives()
    }
  }, [isOpen])

  const mapAlternatives = () => {
    let scenarioList = []
    let allAlternatives = [...alternatives].map(a => {
      a.roadmapCategoryID = "-1"
      if (scenarioList.find(x => x.scenarioID == a.scenarioID) == undefined) {
        scenarioList.push({
          scenarioID: a.scenarioID,
          scenarioName: a.scenarioName,
        })
      }
      return a
    })
    setScenarios(sortData(scenarioList, "scenarioName"))
    setSelectedScenarioId(
      scenarioList.length > 0 ? scenarioList[0].scenarioID : -1
    )
    if (
      roadmapToEdit != null &&
      roadmapToEdit.roadmapAlternatives != null &&
      roadmapToEdit.roadmapAlternatives.length > 0
    ) {
      roadmapToEdit.roadmapAlternatives.forEach(ra => {
        let alt = allAlternatives.find(x => x.alternativeID == ra.alternativeID)
        if (alt !== undefined) {
          alt.roadmapCategoryID = ra.roadmapCategoryID
        }
      })
    }

    setMappedAlternatives(allAlternatives)
  }

  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      size="xl"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Select Projects and Ideas
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col>
            <div className="mb-3">
              <Label className="form-label">Decision</Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={selectedScenarioId}
                onChange={e => {
                  setSelectedScenarioId(e.target.value)
                }}
              >
                <option value="-2">Ideas</option>
                <option value="-1">Selected</option>
                {scenarios.map((a, idx) => {
                  return (
                    <option key={idx} value={`${a.scenarioID}`}>
                      {a.scenarioName}
                    </option>
                  )
                })}
              </select>
            </div>
          </Col>
        </Row>
        {mappedAlternatives.length > 0 && (
          <Row>
            <Col>
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={search}
                onChange={e => setSearch(e.target.value ?? "")}
              />
              <PaginatedTable
                items={mappedAlternatives.filter(
                  a =>
                    (search == "" ||
                      a.alternativeName
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) > -1 ||
                      a.scenarioName
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) > -1) &&
                    (selectedScenarioId == -2
                      ? a.ideaOnly
                      : selectedScenarioId == -1
                      ? a.roadmapCategoryID > 0
                      : a.scenarioID == selectedScenarioId)
                )}
                rowRenderer={(a, idx) => (
                  <tr key={idx}>
                    <td>{a.alternativeNum}</td>
                    <td>{a.scenarioName}</td>
                    <td>{a.alternativeName}</td>
                    <td><div
                    dangerouslySetInnerHTML={{
                      __html: a.version,
                    }}
                    ></div>
                      </td>
                    <td>
                      <select
                        className="form-control form-select select2 mb-xxl-0"
                        value={a.roadmapCategoryID}
                        onChange={e => {
                          let alts = [...mappedAlternatives]
                          let alt = alts.find(
                            x => x.alternativeID == a.alternativeID
                          )
                          alt.roadmapCategoryID = e.target.value
                          setMappedAlternatives(alts)
                        }}
                      >
                        <option value="-1">None</option>
                        {categories.map((a, idx) => {
                          return (
                            <option key={idx} value={`${a.listItemID}`}>
                              {a.listItemName}
                            </option>
                          )
                        })}
                      </select>
                    </td>
                  </tr>
                )}
              >
                <thead>
                  <tr>
                    <th className="col-1">ID</th>
                    <th className="col-2">Decision</th>
                    <th className="col-3">Project</th>
                    <th className="col-1">Version</th>
                    <th className="col-2">Category</th>
                  </tr>
                </thead>
              </PaginatedTable>
            </Col>
          </Row>
        )}
      </div>
      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={() =>
            saveAlternatives(
              mappedAlternatives.filter(x => x.roadmapCategoryID != "-1")
            )
          }
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default SelectAlternativesModal
