import { TextareaAutosize } from "@material-ui/core"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import ResourceManagerModal from "./Modals/ResourceManagerModal"

const ProjectPlanningSection = ({
  activeTab,
  alternative,
  alternativeId,
  changeAlternativeProp,
  triggerReload,
  setTriggerReload,
  triggerSave,
  setTriggerSave,
  showToastChanges,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [fiscalYears, setFiscalYears] = useState([])
  const [selectedResourceGroupId, setSelectedResourceGroupId] = useState(-99)
  const [resourceGroups, setResourceGroups] = useState([])
  const [resourceTemplates, setResourceTemplates] = useState([])
  const [altResourcePlans, setAltResourcePlans] = useState([])
  const [altResourceGroups, setAltResourceGroups] = useState([])
  const [months, setMonths] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [altResourceGroupToEdit, setAltResourceGroupToEdit] = useState(null)
  const [showManagerModal, setShowManagerModal] = useState(false)
  const toggleManagerModal = () => setShowManagerModal(!showManagerModal)

  useEffect(() => {
    if (activeTab == "ResourcePlan") {
      loadData()
    }
  }, [activeTab,alternativeId])

  useEffect(() => {
    if (triggerReload) {
      loadData()
    }
  }, [triggerReload])

  useEffect(() => {
    if (triggerSave) {
      saveGroups()
    }
  }, [triggerSave])

  const loadResourceGroups = async () => {
    var rg = await api.getResourceGroups(alternative.scenarioID, 0)
    setResourceGroups(rg)
  }

  const loadData = async () => {
    setIsLoading(true)
    await loadResourceGroups()
    var rt = await api.getList(currentUser.userID, "ResourceTemplates")
    setResourceTemplates(rt)
    await loadTableData(selectedResourceGroupId)
    setTriggerReload(false)
    setIsLoading(false)
  }

  const loadTableData = async rgId => {
    let plans = await api.getAlternativeResourcePlans(
      alternativeId,
      rgId
    )
    let groups = await api.getAlternativeResourceGroups(
      alternativeId,
      rgId
    )

    let start = moment(alternative.desiredStart)
    let end = moment(alternative.plannedFinish)
    let monthsNumber = parseInt(end.diff(start, "months", true)) + 1
    let m = []
    // let startMonth = start.month()
    for (let i = 1; i <= monthsNumber; i++) {
      let month = moment(moment(start).add(i - 1, "months")).month()
      let year = moment(start).add(i, "month").year()
      m.push({
        monthNumber: i,
        monthName: monthNames[month],
        yearID: year,
      })
    }
    setMonths(m)

    groups.forEach(g => {
      let gp = plans.filter(x => x.resourceGroupID == g.resourceGroupID)
      g.months = JSON.parse(JSON.stringify(m)).map(mth => {
        let p = gp.find(x => x.monthNumber == mth.monthNumber)
        if (p !== undefined) {
          mth.plan = p
        } else {
          mth.plan = {
            monthNumber: mth.monthNumber,
            yearID: mth.yearID,
            numUnits: 0,
            resourceGroupID: g.resourceGroupID,
          }
        }

        return mth
      })
    })

    console.log(groups)
    let newGroups = []
    groups.forEach(g => {
      if (
        newGroups.find(x => x.resourceGroupID == g.resourceGroupID) == undefined
      ) {
        newGroups.push(g)
      }
    })
    setAltResourceGroups(newGroups)
    setAltResourcePlans(plans)
  }

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const changeNumUnits = (group, plan, value) => {
    let copy = [...altResourceGroups]
    let g = copy.find(
      x => x.alternativeResourceGroupID == group.alternativeResourceGroupID
    )
    let m = g.months.find(x => x.plan.monthNumber == plan.plan.monthNumber)
    m.plan.numUnits = value
    m.plan.isDirty = true
    showToastChanges()
    setAltResourceGroups(copy)
  }

  const saveGroups = async () => {
    let itemsToUpdate = []
    let groupsToUpdate = []
    altResourceGroups.forEach(altGroup => {
      if (altGroup.isDirty) {
        groupsToUpdate.push(altGroup)
      }
      altGroup.months.forEach(mth => {
        if (
          mth.plan.isDirty &&
          mth.plan.numUnits !== undefined &&
          mth.plan.numUnits !== null
        ) {
          itemsToUpdate.push({
            ...mth.plan,
            alternativeID: alternativeId,
          })
        }
      })
    })

    if (groupsToUpdate.length > 0) {
      let groupsToUpdateTasks = groupsToUpdate.map(x => async () => {
        return await api.updateAlternativeResourceGroup(currentUser.userID, x)
      })
      await Promise.all(groupsToUpdateTasks.map(t => t()))
    }

    if (itemsToUpdate.length > 0) {
      let itemsToUpdateTasks = itemsToUpdate.map(x => async () => {
        return await api.updateAlternativeResourcePlan(currentUser.userID, x)
      })
      await Promise.all(itemsToUpdateTasks.map(t => t()))
    }
    await loadTableData(selectedResourceGroupId)

    toast.success("Resources saved successfuly")
    setTriggerSave(false)
  }

  const toggleEditModal = () => setIsEditModalVisible(!isEditModalVisible)

  const editAltResourceGroup = (e, altResourceGroup) => {
    e && e.preventDefault()
    altResourceGroup.plannedFinish = alternative.plannedFinish
    altResourceGroup.desiredStart = alternative.desiredStart
    setAltResourceGroupToEdit(altResourceGroup)
    toggleEditModal()
  }

  const changeAltResourceGroupProp = (prop, value) => {
    setAltResourceGroupToEdit({
      ...altResourceGroupToEdit,
      [prop]: value,
    })
  }

  const saveAltResourceGroup = async () => {
    await api.updateAlternativeResourceGroup(
      currentUser.userID,
      altResourceGroupToEdit
    )
    await api.generateAlternativeResourcePlans(
      alternativeId,
      altResourceGroupToEdit.resourceGroupID,
      altResourceGroupToEdit.resourceTemplateID || 0,
      altResourceGroupToEdit.altRGValue,
      altResourceGroupToEdit.desiredStart,
      altResourceGroupToEdit.plannedFinish
    )
    await loadTableData(selectedResourceGroupId)
    toggleEditModal()
  }

  const clearAltResourceGroupValues = async () => {
    let itemsToUpdate = []
    altResourceGroupToEdit.months.forEach(mth => {
      itemsToUpdate.push({
        ...mth.plan,
        alternativeID: alternativeId,
        numUnits: 0,
      })
    })
    if (itemsToUpdate.length > 0) {
      let itemsToUpdateTasks = itemsToUpdate.map(x => async () => {
        return await api.updateAlternativeResourcePlan(currentUser.userID, x)
      })
      await Promise.all(itemsToUpdateTasks.map(t => t()))
      await loadData()
    }
    await api.updateAlternativeResourceGroup(currentUser.userID, {
      ...altResourceGroupToEdit,
      altRGValue: 0,
    })
  }

  return (
    <React.Fragment>
      <Row>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <div className="mb-3" style={{ width: "30%" }}>
            <Label className="form-label">Resource</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={selectedResourceGroupId}
              onChange={e => {
                setSelectedResourceGroupId(e.target.value)
              }}
            >
              <option value={-99}>All</option>
              {resourceGroups.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.resourceGroupID}`}>
                    {a.resourceGroupName}
                  </option>
                )
              })}
            </select>
          </div>
          <div className="mb-3" style={{ marginLeft: "20px" }}>
            <Label>Planned Project Start</Label>
            <Input
              name="startDate"
              type="date"
              onChange={e =>
                changeAlternativeProp(
                  "desiredStart",
                  moment(e.target.value, "YYYY-MM-DD").format(
                    "YYYY-MM-DDThh:mm:ss"
                  )
                )
              }
              value={
                new Date(alternative.desiredStart) >
                new Date(1900, 1, 1, 0, 0, 0, 0)
                  ? moment(alternative.desiredStart).format("YYYY-MM-DD")
                  : ""
              }
            />
          </div>
          <div className="mb-3" style={{ marginLeft: "20px" }}>
            <Label>Planned Project End</Label>
            <Input
              name="endDate"
              type="date"
              onChange={e =>
                changeAlternativeProp(
                  "plannedFinish",
                  moment(e.target.value, "YYYY-MM-DD").format(
                    "YYYY-MM-DDThh:mm:ss"
                  )
                )
              }
              value={
                new Date(alternative.plannedFinish) >
                new Date(1900, 1, 1, 0, 0, 0, 0)
                  ? moment(alternative.plannedFinish).format("YYYY-MM-DD")
                  : ""
              }
            />
          </div>
          <button
            className="btn btn-primary save-user"
            onClick={() => {
              toggleManagerModal()
            }}
          >
            View Resource Managers
          </button>
        </div>
      </Row>

      <Row style={{ marginTop: "20px" }}>
        <Col style={{ overflowX: "auto" }}>
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          {!isLoading && (
            <table
              className="table table-bordered lower-padding-table"
              style={{ backgroundColor: "white", padding: "0px" }}
            >
              <thead>
                <tr>
                  <th className="sticky-th">Resource</th>
                  <th style={{ minWidth: "150px" }}>Unit</th>
                  {months.map((p, idx) => (
                    <th key={idx} style={{ minWidth: "100px" }}>
                      {p.monthName.substring(0, 3)} {p.yearID}
                    </th>
                  ))}
                  <th>Total</th>
                  <th style={{ minWidth: "300px" }}>Estimation Notes</th>
                </tr>
              </thead>
              <tbody>
                {altResourceGroups
                  .filter(
                    x =>
                      selectedResourceGroupId == -99 ||
                      x.resourceGroupID == selectedResourceGroupId
                  )
                  .map((a, idx) => {
                    return (
                      <tr key={idx}>
                        <td className="sticky-td">
                          <a href="#" onClick={e => editAltResourceGroup(e, a)}>
                            {a.resourceGroupName}
                          </a>
                        </td>
                        <td>{a.resourceUnit}</td>
                        {a.months.map((p, idx2) => (
                          <td key={idx + "-" + idx2}>
                            <CurrencyInput
                              id={`ci-${idx2}`}
                              className="form-control"
                              value={p.plan.numUnits}
                              style={{ textAlign: "right", paddingTop: "0px", paddingBottom: "0px" }}
                              onValueChange={(value, name, values) => {
                                changeNumUnits(a, p, values.float)
                              }}
                              placeholder=""
                              prefix={""}
                            />
                          </td>
                        ))}
                        <td className="text-right">
                          {a.months.reduce(
                            (acc, item) =>
                              acc +
                              (item.plan.numUnits !== undefined &&
                              item.plan.numUnits !== null
                                ? item.plan.numUnits
                                : 0),
                            0
                          )}
                        </td>
                        <td>
                          <Input
                            type="text"
                            style={{ width: "300px" }}
                            value={a.argNotes}
                            onChange={e => {
                              let copy = [...altResourceGroups]
                              let g = copy.find(
                                x =>
                                  x.alternativeResourceGroupID ==
                                  a.alternativeResourceGroupID
                              )
                              g.argNotes = e.target.value
                              g.isDirty = true
                              showToastChanges()
                              setAltResourceGroups(copy)
                            }}
                          />
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          )}
        </Col>
      </Row>
      <Modal
      backdrop="static"
        isOpen={isEditModalVisible}
        size="lg"
        toggle={() => {
          toggleEditModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Distribute Resources
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleEditModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        {altResourceGroupToEdit != null && (
          <div className="modal-body">
            <div className="mb-3">
              <Label>Start Date</Label>
              <Input
                name="rgstartDate"
                type="date"
                onChange={e =>
                  changeAltResourceGroupProp(
                    "desiredStart",
                    moment(e.target.value, "YYYY-MM-DD").format(
                      "YYYY-MM-DDThh:mm:ss"
                    )
                  )
                }
                value={moment(altResourceGroupToEdit.desiredStart).format(
                  "YYYY-MM-DD"
                )}
              />
            </div>
            <div className="mb-3">
              <Label>End Date</Label>
              <Input
                name="altrgendDate"
                type="date"
                onChange={e =>
                  changeAltResourceGroupProp(
                    "plannedFinish",
                    moment(e.target.value, "YYYY-MM-DD").format(
                      "YYYY-MM-DDThh:mm:ss"
                    )
                  )
                }
                value={moment(altResourceGroupToEdit.plannedFinish).format(
                  "YYYY-MM-DD"
                )}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Template</Label>
              <select
                className="form-control form-select select2 mb-xxl-0"
                value={altResourceGroupToEdit.resourceTemplateID || 0}
                onChange={e => {
                  changeAltResourceGroupProp(
                    "resourceTemplateID",
                    e.target.value
                  )
                }}
              >
                {resourceTemplates.map((a, idx) => {
                  return (
                    <option key={idx} value={`${a.listItemID}`}>
                      {a.listItemName}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className="mb-3">
              <Label className="form-label">Total Hours</Label>
              <CurrencyInput
                id="atlcostConstraint"
                name="altcostConstraint"
                className="form-control"
                value={altResourceGroupToEdit.altRGValue}
                onValueChange={(value, name, values) => {
                  changeAltResourceGroupProp("altRGValue", values.float)
                }}
                placeholder=""
                prefix={""}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Notes</Label>
              <TextareaAutosize
                className="form-control"
                minRows={2}
                onChange={e =>
                  changeAltResourceGroupProp("argNotes", e.target.value)
                }
                value={altResourceGroupToEdit.argNotes}
              />
            </div>
          </div>
        )}
        <div className="modal-footer">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={clearAltResourceGroupValues}
            >
              Clear Units
            </button>
            <div>
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={toggleEditModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary save-user"
                onClick={saveAltResourceGroup}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ResourceManagerModal
        isOpen={showManagerModal}
        toggleModal={toggleManagerModal}
        scenarioId={alternative.scenarioID}
      />
    </React.Fragment>
  )
}

export default ProjectPlanningSection
