import React, { useEffect, useState } from "react"
import { Col, Container, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import useModal from "hooks/useModalHook"
import moment from "moment"
import { toast } from "react-toastify"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { loadPageItem } from "store/actions"
import { useDispatch } from "react-redux"

const NotificationEmails = () => {
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const [notificationEmails, setNotificationEmails] = useState([])
  const [selectedNotifiationEmail, setSelectedNotificationEmail] =
    useState(null)
  const { show: showNotificationModal, toggle: toggleNotificationModal } =
    useModal()

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    let n = await api.getNotificationEmails(currentUser.userID)
    setNotificationEmails(n)
  }

  const changeNotificationEmailProp = (id, prop, val) => {
    let copy = [...notificationEmails]
    let item = copy.find(x => x.notificationEmailID == id)
    item[prop] = val
    setNotificationEmails(copy)
  }

  const showModal = async (e, notificationEmail) => {
    e && e.preventDefault()
    setSelectedNotificationEmail(notificationEmail)
    if (notificationEmail.unread) {
      await api.updateNotificationEmail(currentUser.userID, {
        ...notificationEmail,
        unread: false,
      })
      dispatch(
        loadPageItem({
          userId: currentUser.userID,
          scenarioId: 0,
          alternativeId: 0,
          viewName: "myData",
        })
      )
      loadData()
    }
    toggleNotificationModal()
  }

  const archive = async () => {
    let tasks = notificationEmails
      .filter(x => x.inactive)
      .map(x => async () => {
        return await api.updateNotificationEmail(currentUser.userID, x)
      })
    if (tasks.length > 0) {
      await Promise.all(tasks.map(t => t()))
      loadData()
      toast.success("Notifications archived")
    }
  }

  return (
    <React.Fragment>
      <div className="d-flex-col">
        <Breadcrumbs title="Notifications" />

        {notificationEmails.find(x => x.inactive == true) != undefined && (
          <div className="d-flex-row jc-end mb-3">
            <button
              className="btn btn-outline-primary"
              style={{ float: "right" }}
              onClick={archive}
            >
              Archive
            </button>
          </div>
        )}
        <div className="d-flex-col">
          {notificationEmails.length == 0 && <p>No data</p>}
          {notificationEmails.map((n, idx) => (
            <NotificationEmailItem
              key={idx}
              notificationEmail={n}
              showModal={showModal}
              changeNotificationEmailProp={changeNotificationEmailProp}
            />
          ))}
        </div>
      </div>
      <NotificationEmailModal
        isOpen={showNotificationModal}
        toggleModal={toggleNotificationModal}
        notificationEmail={selectedNotifiationEmail}
      />
    </React.Fragment>
  )
}

const NotificationEmailItem = ({
  notificationEmail,
  showModal,
  changeNotificationEmailProp,
}) => {
  return (
    <div className="d-flex-col">
      <p>
        <a href="#" onClick={e => showModal(e, notificationEmail)}>
          {notificationEmail.emailSubject}
        </a>
      </p>
      <div className="d-flex-row jc-space-between ai-center mb-1">
        <span>
          {moment(notificationEmail.notificationEmailDate).format("MM/DD/YYYY")}
        </span>
        <div className="d-flex-row ai-center" style={{ gap: "20px" }}>
          <i
            className={
              notificationEmail.unread
                ? "fas fa-envelope"
                : "fas fa-envelope-open"
            }
            style={{
              color: notificationEmail.unread ? "gray" : "lightgray",
              fontSize: "20px",
            }}
          ></i>
          <div className="form-check">
            <Input
              type="checkbox"
              className="form-check-input"
              id={`ne-${notificationEmail.notificationEmailID}`}
              checked={notificationEmail.inactive}
              onClick={e => {
                changeNotificationEmailProp(
                  notificationEmail.notificationEmailID,
                  "inactive",
                  !notificationEmail.inactive
                )
              }}
            />
            <Label
              className="form-check-label"
              for={`ne-${notificationEmail.notificationEmailID}`}
            >
              Archive
            </Label>
          </div>
        </div>
      </div>

      <div className="divider" style={{marginTop:'8px', marginBottom:'8px'}}></div>
    </div>
  )
}

const NotificationEmailModal = ({ isOpen, toggleModal, notificationEmail }) => {
  if (notificationEmail != undefined && notificationEmail != null)
    return (
      <Modal
        isOpen={isOpen}
        size="xl"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {notificationEmail.emailSubject}
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <div
              dangerouslySetInnerHTML={{
                __html: notificationEmail.emailBody,
              }}
            ></div>
          </div>
        </div>

        <div
          className="modal-footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Close
          </button>
          {/* <button
        type="button"
        className="btn btn-primary save-user"
        onClick={save}
      >
        Save
      </button> */}
        </div>
      </Modal>
    )
}

export default NotificationEmails
