import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Label, Modal } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"

const UploadModal = ({
  isOpen,
  toggleModal,
  callback,
  text,
  fileType,
  reload,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [file, setFile] = useState(null)
  const [error, setError] = useState(null)
  const [fileLink, setFileLink] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setFile(null)
      setError(null)
    }
  }, [isOpen])

  useEffect(() => {
    if (fileType != undefined && fileType != null && fileType != "") {
      loadFileLink()
    }
  }, [fileType])

  const loadFileLink = async () => {
    let link = await api.getUploaderFileLink(fileType)
    setFileLink(link)
  }

  const save = async () => {
    setError(null)
    if (file == null) {
      toast.error("You can not save without uploading a file")
    } else {
      setIsSubmitting(true)
      try {
        let ret = await callback(file)
        if (ret != undefined && ret != null && ret != "" && ret.length > 0) {
          setError(ret)
        } else {
          toggleModal()
          reload()
        }
        setIsSubmitting(false)
      } catch {
        setIsSubmitting(false)
      }
    }
  }

  const onFileChange = event => {
    setError(null)
    let f = event.target.files[0]
    setFile(f)
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Upload
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {fileLink != undefined && fileLink != null && fileLink != "" && (
          <a href={fileLink} download="" target="_blank" rel="noreferrer">
            <i className="mdi mdi-microsoft-excel"></i>&nbsp;
            <b>Download Template</b>
          </a>
        )}
        {text && <p style={{ marginTop: "20px"}}>{text}</p>}
        {error && (
          <div
            dangerouslySetInnerHTML={{
              __html: error,
            }}
          ></div>
        )}
        <div className="mb-3">
          <Label
            className="btn btn-primary"
            style={{ marginBottom: "0px", fontWeight: "bold" }}
          >
            <input
              type="file"
              name="myFile"
              onChange={onFileChange}
              style={{ display: "none" }}
            />
            Upload File
          </Label>
          {file != null && (
            <span style={{ marginLeft: "10px" }}>{file.name}</span>
          )}
        </div>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        {isSubmitting && (
          <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        )}
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
          disabled={isSubmitting}
        >
          Process Import
        </button>
      </div>
    </Modal>
  )
}

export default UploadModal
