import Widgets from "components/Widgets"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Container, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { loadPageItem } from "store/actions"
import { changeNavbarParams } from "store/navbar/actions"

import Loader from "react-loaders"
import colors from "utils/colors"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import IdeasGrid from "./IdeasGrid"

const Ideas = props => {
  const dispatch = useDispatch()
  const currentUser = userService.getLoggedInUser()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [isLoading, setIsLoading] = useState(false)
  const [ideas, setIdeas] = useState([])
  const [widgets, setWidgets] = useState([])

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: 0,
        alternativeId: 0,
        viewName: "IdeasList",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: 0,
        alternativeId: 0,
        viewName: "IdeasList",
      })
    )
    loadData()
  }, [])

  const loadData = async () => {
    setIsLoading(true)
    let w = await api.getWidgetsByView("IdeasList", currentUser.userID)
    setWidgets(w)
    await loadIdeas()
    setIsLoading(false)
  }

  const loadIdeas = async () => {
    let i = await api.getIdeasByUser(currentUser.userID, false)
    setIdeas(i)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Widgets widgets={widgets} marginTop="0px" />

          <Row style={{ marginTop: "10px" }}>
            <Col>
              <div style={{ marginBottom: "20px" }}>
                {ideas.length > 0 && (
                  <IdeasGrid ideas={ideas} props={props} reload={loadIdeas} />
                )}
                {isLoading && (
                  <Loader
                    type="line-scale-pulse-out"
                    color={colors.primary}
                    style={{ textAlign: "center" }}
                  />
                )}
                {!isLoading && ideas.length == 0 && <h6>No ideas yet</h6>}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Ideas
