import { TextareaAutosize } from "@material-ui/core"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const EditPhaseModal = ({
  isOpen,
  toggleModal,
  phaseToEdit,
  changePhaseProp,
  nm,
  changeNmProp,
}) => {
  const currentUser = userService.getLoggedInUser()

  useEffect(() => {
    if (isOpen) {
    }
  }, [isOpen])

  const save = async () => {
    if (nm.nmhedPhases == null || nm.nmhedPhases.length == 0) {
      changeNmProp("nmhedPhases", [{ ...phaseToEdit }])
    } else {
      let copy = [...nm.nmhedPhases]
      let existing = copy.find(
        x => x.nmheD_PhaseID == phaseToEdit.nmheD_PhaseID
      )
      if (existing == undefined) {
        changeNmProp("nmhedPhases", [...copy, { ...phaseToEdit }])
      } else {
        existing = { ...phaseToEdit }
        changeNmProp("nmhedPhases", [...copy])
      }
    }
    toggleModal()
  }

  const cancel = () => {}

  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Edit Phase
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {phaseToEdit != null && (
        <div className="modal-body">
          <Row>
            <Col md="6">
              <div className="mb-3">
                <Label className="form-label">Phase #</Label>
                <Input
                  type="number"
                  onChange={e => changePhaseProp("phaseNumber", e.target.value)}
                  value={phaseToEdit.phaseNumber}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">Start Date</Label>
                <Input
                  name="sd"
                  type="date"
                  onChange={e =>
                    changePhaseProp(
                      "startDate",
                      moment(e.target.value, "YYYY-MM-DD").format(
                        "YYYY-MM-DDThh:mm:ss"
                      )
                    )
                  }
                  value={
                    new Date(phaseToEdit.startDate) >
                    new Date(1900, 1, 1, 0, 0, 0, 0)
                      ? moment(phaseToEdit.startDate).format("YYYY-MM-DD")
                      : ""
                  }
                />
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <Label className="form-label">Amount</Label>
                <CurrencyInput
                  className="form-control"
                  value={phaseToEdit.phaseAmount}
                  onValueChange={(value, name, values) => {
                    changePhaseProp("phaseAmount", values.float)
                  }}
                  placeholder=""
                  prefix={"$"}
                  decimalScale={0}
                  decimalsLimit={0}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">End Date</Label>
                <Input
                  name="sd"
                  type="date"
                  onChange={e =>
                    changePhaseProp(
                      "endDate",
                      moment(e.target.value, "YYYY-MM-DD").format(
                        "YYYY-MM-DDThh:mm:ss"
                      )
                    )
                  }
                  value={
                    new Date(phaseToEdit.endDate) >
                    new Date(1900, 1, 1, 0, 0, 0, 0)
                      ? moment(phaseToEdit.endDate).format("YYYY-MM-DD")
                      : ""
                  }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div
                className="mb-3"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Input
                  type="checkbox"
                  id="partofrequest"
                  checked={phaseToEdit.partOfRequest}
                  onClick={() => {
                    changePhaseProp("partOfRequest", !phaseToEdit.partOfRequest)
                  }}
                />
                <Label style={{ marginLeft: "10px" }} for="partofrequest">
                  Part of Request
                </Label>
              </div>
              <div className="mb-3">
                <Label>Description</Label>
                <TextareaAutosize
                  minRows={2}
                  className="form-control"
                  onChange={e =>
                    changePhaseProp("phaseDescription", e.target.value)
                  }
                  value={phaseToEdit.phaseDescription}
                />
              </div>
            </Col>
          </Row>
        </div>
      )}
      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default EditPhaseModal
