import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const EditActionModal = ({
  isOpen,
  toggleModal,
  actionToEdit,
  setActionToEdit,
  actionFields,
  onSave,
}) => {
  const [selectedActionField, setSelectedActionField] = useState(null)

  useEffect(() => {
    if (isOpen) {
      setSelectedActionField(
        actionFields.find(x => x.fieldName == actionToEdit.actionField)
      )
    }
  }, [isOpen])

  const save = async () => {
    onSave(selectedActionField)
    toggleModal()
  }

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Action
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <Label className="form-label">Action</Label>
          <select
            className="form-control form-select select2 mb-xxl-0"
            value={actionToEdit.actionField}
            onChange={e => {
              const selected = actionFields.find(
                x => x.fieldName == e.target.value
              )
              
              setActionToEdit({
                ...actionToEdit,
                actionField: e.target.value,
                actionValue: selected.fieldType == "bool" ? false : "",
                actionText: selected.fieldType == "bool" ? "false" : "",
              })

              setSelectedActionField(selected)
            }}
          >
            <option value={"-1"}>Select...</option>
            {actionFields.map((a, idx) => {
              return (
                <option key={idx} value={a.fieldName}>
                  {a.fieldLabel}
                </option>
              )
            })}
          </select>
        </div>
        {selectedActionField != undefined && selectedActionField != null && (
          <div className="mb-3">
            {selectedActionField.fieldType !== "bool" && (
              <Label className="form-label">Value</Label>
            )}
            {selectedActionField.fieldType == "int" &&
              selectedActionField.sourceList == "" && (
                <Input
                  type="number"
                  onChange={e =>
                    setActionToEdit({
                      ...actionToEdit,
                      isDirty: true,
                      actionValue: e.target.value,
                      actionText: e.target.value,
                    })
                  }
                  value={actionToEdit.actionValue}
                />
              )}
            {selectedActionField.fieldType == "string" &&
              selectedActionField.sourceList == "" && (
                <Input
                  type="text"
                  onChange={e =>
                    setActionToEdit({
                      ...actionToEdit,
                      isDirty: true,
                      actionValue: e.target.value,
                      actionText: e.target.value,
                    })
                  }
                  value={actionToEdit.actionValue}
                />
              )}
            {(selectedActionField.fieldType == "int" ||
              selectedActionField.fieldType == "string") &&
              selectedActionField.sourceList !== "" && (
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={actionToEdit.actionValue}
                  onChange={e => {
                    let actionText = ""
                    if (e.target.value != "-1") {
                      actionText = selectedActionField.options.find(
                        x => x.listItemID == e.target.value
                      ).listItemName
                    }
                    setActionToEdit({
                      ...actionToEdit,
                      isDirty: true,
                      actionValue: e.target.value,
                      actionText: actionText,
                    })
                  }}
                >
                  <option value={"-1"}>Select...</option>
                  {selectedActionField.options.map((a, idx) => {
                    return (
                      <option key={idx} value={a.listItemID}>
                        {a.listItemName}
                      </option>
                    )
                  })}
                </select>
              )}
            {selectedActionField.fieldType == "bool" && (
              <div className="form-check mb-3" style={{ marginLeft: "10px" }}>
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="customCheck4"
                  checked={actionToEdit.actionValue}
                  onClick={e => {
                    setActionToEdit({
                      ...actionToEdit,
                      isDirty: true,
                      actionValue: !actionToEdit.actionValue,
                      actionText: (!actionToEdit.actionValue).toString(),
                    })
                  }}
                />
                <Label className="form-check-label" for="customCheck4">
                  {selectedActionField.fieldLabel}
                </Label>
              </div>
            )}
            {selectedActionField.fieldType == "date" && (
              <>
                <Input
                  type="date"
                  onChange={e => {
                    setActionToEdit({
                      ...actionToEdit,
                      isDirty: true,
                      actionValue: moment(e.target.value, "YYYY-MM-DD").format(
                        "YYYY-MM-DDThh:mm:ss"
                      ),
                      actionText: moment(e.target.value).format("MM/DD/YYYY"),
                    })
                  }}
                  value={
                    new Date(actionToEdit.actionValue) >
                    new Date(1900, 1, 1, 0, 0, 0, 0)
                      ? moment(actionToEdit.actionValue).format("YYYY-MM-DD")
                      : ""
                  }
                />
                <span>
                  Leave blank and the date will set to the current date of the
                  Action
                </span>
              </>
            )}
          </div>
        )}
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default EditActionModal
