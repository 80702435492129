import { GET_NAVBAR } from "./actionTypes"

const INIT_STATE = {
  navbarParams: {
    userId: 0,
    scenarioId: 0,
    viewName: "",
  },
}

const navbar = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NAVBAR:
      localStorage.removeItem("navbarParams")
      localStorage.setItem("navbarParams", JSON.stringify(action.payload))
      return {
        ...state,
        navbarParams: action.payload,
      }

    default:
      return state
  }
}

export default navbar
