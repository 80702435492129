import PropTypes from "prop-types"
import React, { useEffect } from "react"

import {
  Switch,
  BrowserRouter as Router,
  Route,
  useHistory,
  withRouter,
} from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { authProtectedRoutes, mobileRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"

import { LoginCallback, Security } from "@okta/okta-react"
import Loader from "react-loaders"
import colors from "utils/colors"
import OktaAuth from "@okta/okta-auth-js"
import { okta } from "config"
import api from "services/api.service"
import { authenticationService } from "services/authentication.service"
import { Zendesk, ZendeskAPI } from "utils/Zendesk"
import { ToastContainer } from "react-toastify"

// Import scss
import "./assets/scss/theme.scss"
import "animate.css"
import "react-toastify/dist/ReactToastify.css"
import "react-tabulator/lib/styles.css" // required styles
// import "react-tabulator/lib/css/tabulator.min.css"; // theme
import "react-tabulator/lib/css/tabulator_bootstrap4.min.css" // theme
import "@fontsource/inter"
import "react-tooltip/dist/react-tooltip.css"
import { swalWithConfirmButton } from "components/custom/swal"
import "app.css"

const App = props => {
  const oktaAuth = new OktaAuth(okta)

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    let u = await _oktaAuth.token.getUserInfo()
    let user = await api.getUserByEmail(u.email)
    authenticationService.login(user.email, user.password).then(data => {
      localStorage.setItem("lastUserEmail", user.email)
      let oktaLocationFrom = localStorage.getItem("oktaLocationFrom")
      
      if (oktaLocationFrom !== undefined && oktaLocationFrom !== null) {
        localStorage.removeItem("oktaLocationFrom")
        if (oktaLocationFrom.indexOf("redirectList") > -1) {
          let listId = oktaLocationFrom.split("?list=")[1]
          api
            .checkUserLinkAccess(user.userID, listId, "List")
            .then(accessResponse => {
              if (accessResponse == "OK") {
                api.getAlternativeLists(user.userID, 0).then(
                  listData => {
                    let listObject = listData.find(
                      x => x.alternativeListID == listId
                    )
                    if (listObject != undefined) {
                      window.location.replace(
                        `/projects/${listObject.scenarioID}?list=${listId}`
                      )
                    } else {
                      window.location.replace(`/`)
                    }
                  },
                  err => handleNormalFlow(data)
                )
              } else {
                showUnauthorizedMessage(user, accessResponse)
              }
            })
        } else if (oktaLocationFrom.indexOf("redirectBusinessCase") > -1) {
          let bcId = oktaLocationFrom.split("?bcId=")[1]
          api
            .checkUserLinkAccess(user.userID, bcId, "Alternative")
            .then(accessResponse => {
              if (accessResponse == "OK") {
                api.getAlternativeByBusinessCaseId(bcId).then(
                  alternativeData => {
                    if (alternativeData != undefined) {
                      window.location.replace(
                        `/editProject/${alternativeData.scenarioID}/${alternativeData.alternativeID}`
                      )
                    } else {
                      window.location.replace(`/`)
                    }
                  },
                  err => handleNormalFlow(data)
                )
              } else {
                showUnauthorizedMessage(user, accessResponse)
              }
            })
        } else {
          window.location.replace(oktaLocationFrom)
        }
      } else {
        handleNormalFlow(data)
      }
    })
  }

  const handleNormalFlow = user => {
    if (user && user.homePagePath) {
      window.location.replace(`/${user.homePagePath}`)
    } else {
      window.location.replace("/models")
    }
  }

  const showUnauthorizedMessage = (user, msg) => {
    swalWithConfirmButton
      .fire({
        title: msg,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
      .then(r => {
        handleNormalFlow(user)
      })
  }

  return (
    <React.Fragment>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Route
                key={idx * 100}
                path={route.path}
                exact
                component={route.component}
              />
            ))}
            {mobileRoutes.map((route, idx) => (
              <Route
                path={route.path}
                key={idx}
                exact={route.exact || true}
                component={route.component}
              />
            ))}
            <VerticalLayout>
              <>
                {authProtectedRoutes.map((route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    exact={route.exact || true}
                  />
                ))}
              </>
              <Zendesk
                defer
                zendeskKey={"3e8f43e0-2e6c-45b3-8c04-31458813fcae"}
                onLoaded={ZendeskAPI}
              />
            </VerticalLayout>
          </Switch>
        </Router>
      </Security>
      <ToastContainer />
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
