import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { Col, Container, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { changeNavbarParams, loadPageItem } from "store/actions"
import colors from "utils/colors"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import useModal from "hooks/useModalHook"
import AddEditReportDataSourceModal from "./AddEditReportDataSourceModal"
import isNullOrEmpty from "utils/isNullOrEmpty"

const ReportDataSources = props => {
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [isLoading, setIsLoading] = useState(false)
  const [reportDataSources, setReportDataSources] = useState([])
  const [reportDataSourceToEdit, setReportDataSourceToEdit] = useState(null)

  const {
    show: showReportDataSourceModal,
    toggle: toggleReportDataSourceModal,
  } = useModal()

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: 0,
        alternativeId: 0,
        viewName: "ReportDataSources",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: 0,
        viewName: "ReportDataSources",
      })
    )
    loadData()
  }, [])

  const loadData = async () => {
    try {
      setIsLoading(true)
      let rds = await api.getReportDataSources(currentUser.enterpriseID)
      setReportDataSources(
        rds.map(x => {
          if (!isNullOrEmpty(x.ruleModel)) {
            x.rule = JSON.parse(x.ruleModel)
          }

          return x
        })
      )
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  const addReportDataSource = () => {
    setReportDataSourceToEdit({
      id: -1,
      name: "",
      description: "",
      enterpriseID: currentUser.enterpriseID,
      definition: "",
      ruleModel: "",
      rule: null,
      inactive: false,
    })
    toggleReportDataSourceModal()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Row className="mb-3">
            <Col xs="12">
              <button className="btn btn-primary" onClick={addReportDataSource}>
                <i className="fas fa-plus"></i> Add Report Data Source
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              {!isLoading && (
                <table
                  className="table table-bordered low-padding-table"
                  style={{ backgroundColor: "white" }}
                >
                  <thead>
                    <tr>
                      <th style={{ width: "33%", textAlign: "center" }}>
                        Report Data Source
                      </th>
                      <th style={{ width: "33%", textAlign: "center" }}>
                        Description
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportDataSources.map((r, idx) => (
                      <tr key={idx}>
                        <td>
                          <a
                            href="#"
                            onClick={e => {
                              e.preventDefault()
                              setReportDataSourceToEdit(r)
                              toggleReportDataSourceModal()
                            }}
                          >
                            {r.name}
                          </a>
                        </td>
                        <td>{r.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <AddEditReportDataSourceModal
        isOpen={showReportDataSourceModal}
        toggleModal={toggleReportDataSourceModal}
        reportDataSourceToEdit={reportDataSourceToEdit}
        setReportDataSourceToEdit={setReportDataSourceToEdit}
        reload={loadData}
      />
    </React.Fragment>
  )
}

export default ReportDataSources
