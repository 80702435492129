import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import useModal from "hooks/useModalHook"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import EditFormFieldListItemModal from "./EditFormFieldListItemModal"
import { ReactTabulator } from "react-tabulator"
import { createRoot } from "react-dom/client"
import { customFormatter } from "utils/tabulator"

const ManageFormFieldListItems = ({
  formFieldListItems,
  changeFormFieldProp,
}) => {
  const { show: showModal, toggle: toggleModal } = useModal()

  const [listItemToEdit, setListItemToEdit] = useState(null)
  const { show: showEditListItemModal, toggle: toggleEditListItemModal } =
    useModal()

  const addListItem = () => {
    setListItemToEdit({
      formFieldListItemId: getNextFormFieldListItemId(formFieldListItems),
      formFieldId: -1,
      listItemValue: "",
      listItemName: "",
      inactive: false,
      orderId: 0,
      backColor: "",
      foreColor: "",
    })
    toggleEditListItemModal()
  }

  const editListItem = listItem => {
    setListItemToEdit(listItem)
    toggleEditListItemModal()
  }

  const deleteListItem = listItem => {
    let copy = [...formFieldListItems]
    let item = copy.find(
      x => x.formFieldListItemId == listItem.formFieldListItemId
    )
    copy.splice(copy.indexOf(item), 1)
    changeFormFieldProp("formFieldListItems", copy)
  }

  const changeListItemToEditProp = (prop, val) =>
    setListItemToEdit(prev => {
      return { ...prev, [prop]: val }
    })

  const onListItemSave = () => {
    let copy = [...formFieldListItems]
    let existingListItem = copy.find(
      x => x.formFieldListItemId == listItemToEdit.formFieldListItemId
    )
    if (existingListItem == undefined) {
      copy.push({ ...listItemToEdit })
    } else {
      copy.splice(copy.indexOf(existingListItem), 1, { ...listItemToEdit })
      // existingListItem = { ...existingListItem,...listItemToEdit }
    }
    changeFormFieldProp("formFieldListItems", copy)
  }

  function getNextFormFieldListItemId(formFieldListItems) {
    if (formFieldListItems.length == 0) {
      return -1
    }

    const itemsWithNegativeId = formFieldListItems.filter(
      obj => obj.formFieldListItemId < 0
    )
    if (itemsWithNegativeId.length === 0) {
      return -1
    }
    const minValue = Math.min(
      ...itemsWithNegativeId.map(obj => obj.formFieldListItemId)
    )

    return minValue - 1
  }

  const columns = [
    {
      rowHandle: true,
      formatter: "handle",
      headerSort: false,
      frozen: true,
      width: 50,
    },
    {
      title: "Name",
      field: "listItemName",
      headerSort: false,
      formatter: customFormatter(({ rowData, cellValue }) => (
        <a
          href="#"
          onClick={e => {
            e.preventDefault()
            editListItem(rowData)
          }}
        >
          {cellValue}
        </a>
      )),
    },
    {
      title: "Order",
      field: "orderId",
      headerSort: false,
    },
    {
      field: "",
      headerSort: false,
      width: 50,
      formatter: customFormatter(({ rowData, cellValue }) => (
        <i
          className="fas fa-trash-alt"
          style={{ color: "red" }}
          onClick={() => deleteListItem(rowData)}
        ></i>
      )),
    },
  ]

  return (
    <>
      <button type="button" className="btn btn-primary" onClick={toggleModal}>
        Manage List Items
      </button>
      <table
        className="table table-bordered low-padding-table"
        style={{ backgroundColor: "white" }}
      >
        <thead>
          <tr>
            <th style={{ width: "50%" }}>Name</th>
            <th style={{ width: "50%" }}>Value</th>
          </tr>
        </thead>
        <tbody>
          {formFieldListItems.map((l, idx) => (
            <tr key={idx}>
              <td>{l.listItemName}</td>
              <td>{l.listItemValue}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        isOpen={showModal}
        size="md"
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Manage List Items
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {formFieldListItems && (
          <div className="modal-body">
            <div className="mb-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={addListItem}
              >
                Add New Item
              </button>
            </div>
            <div className="mb-3">
              <ReactTabulator
                //   onRef={r => (tabulatorRef.current = r.current)}
                columns={columns}
                data={formFieldListItems}
                options={{
                  movableRows: true,
                  layout: "fitColumns",
                  cssClass: "table-borderless",
                }}
                events={{
                  rowMoved: function (row) {
                    let list = row.getTable().getData()
                    list = list.map((l, idx) => {
                      l.orderId = idx + 1
                      return l
                    })
                    changeFormFieldProp("formFieldListItems", list)
                  },
                }}
              />
            </div>
          </div>
        )}
        <div
          className="modal-footer"
          style={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
          }}
        >
          {/* <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleModal}
          >
            Cancel
          </button> */}
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={toggleModal}
          >
            Close
          </button>
        </div>
      </Modal>
      <EditFormFieldListItemModal
        isOpen={showEditListItemModal}
        toggleModal={toggleEditListItemModal}
        listItemToEdit={listItemToEdit}
        changeListItemToEditProp={changeListItemToEditProp}
        onSave={onListItemSave}
      />
    </>
  )
}

export default ManageFormFieldListItems
