import { RenderWidget } from "components/Widgets"
import NameAvatar from "components/custom/nameAvatar"
import React from "react"
import { Col, Row } from "reactstrap"
import { userService } from "services/user.service"
import colors from "utils/colors"

const ModelGroupsCards = ({ modelGroups, props, editModelGroup, allModels }) => {
  const currentUser = userService.getLoggedInUser()

  const handleModelPress = (e, model) => {
    e && e.preventDefault()
    // props.history.push(`/editModel/${model.scenarioID}`)
  }

  const handleAltPress = (e, model) => {
    e && e.preventDefault()
    editModelGroup(model)
    // props.history.push(`/projects/${model.scenarioID}`)
  }

  const handleParticipantsPress = (e, model) => {
    e && e.preventDefault()
    props.history.push(`/participants/${model.scenarioID}`)
  }

  const renderCard = (model, idx) => {
    model.businessWidget = {
      title: 'Models:'
    }

    if(model.scenarioIds != undefined && model.scenarioIds != null && model.scenarioIds.length > 0){
      let main = ''
      model.scenarioIds.forEach(id =>{
        let s = allModels.find(x=>x.scenarioID == id)
        if(s != undefined){
          main += `${s.scenarioName} <br>`
        }
      })
      model.businessWidget.main = main
    }

    return (
      <Col
        key={idx}
        /* md={4} sm={6} */
      >
        <div
          style={{
            border: "1px solid lightgray",
            backgroundColor: "white",
            marginTop: "20px",
            marginRight: "10px",
            marginLeft: "10px",
            borderRadius: "8px",
            minWidth: "400px",
            maxWidth: "500px",
            //boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.1)'
          }}
        >
          <div
            style={{
              padding: "10px",
              backgroundColor: 'rgb(18, 52, 86)',
              color: 'white',
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            {/* <i className={model.scenarioGroupName}></i> */}
            <b> {model.scenarioGroupName}</b>
          </div>
          <div style={{ padding: "5px", fontWeight: 600 }}>
            <NameAvatar
              fullName={model.userName}
              showName={true}
              base64Image={model.photoBase64}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {/* <div
              style={{
                width: "46%",
                margin: "auto",
                marginTop: "5px",
                marginBottom: "10px",
                cursor: "pointer",
              }}
              onClick={e => handleParticipantsPress(e, model)}
            >
              {RenderWidget({ ...model.teamWidget, height: "100%" }, 1)}
            </div> */}
            <div
              style={{
                width: "95%",
                margin: "auto",
                marginTop: "5px",
                marginBottom: "10px",
                cursor: "pointer",
              }}
              onClick={e => handleAltPress(e, model)}
            >
              {RenderWidget({ ...model.businessWidget, height: "100%" }, 1)}
            </div>
          </div>
          
        </div>
      </Col>
    )
  }

  return (
    <React.Fragment>
      <Row>{modelGroups.map((m, idx) => renderCard(m, idx))}</Row>
    </React.Fragment>
  )
}

export default ModelGroupsCards
