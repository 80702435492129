import React from "react"
import anychart from "anychart"

const renderUserChart = (userChart, chartdiv, onChartPointClick) => {
  if (
    document.getElementById(chartdiv) != undefined &&
    document.getElementById(chartdiv) != null
  ) {
    document.getElementById(chartdiv).innerHTML = ""
  }

  if (
    userChart.chartArray == undefined ||
    userChart.chartArray == null ||
    userChart.chartArray == ""
  ) {
    userChart.chartArray = "[]"
  }

  let obj = eval("(" + userChart.chartArray.replaceAll("'", '"') + ")")
  let json = JSON.stringify(obj)
  let data = JSON.parse(json)
  let chartData = anychart.data.set(data)

  if (userChart.chartType == "Pie") {
    if (userChart.chartHasColor) {
      var mapping = chartData.mapAs({ x: 0, value: 1, fill: 2 })
    } else {
      var mapping = chartData.mapAs({ x: 0, value: 1 })
    }

    var chart = anychart.pie(mapping)
    chart.labels().offsetY(0).enabled(true)
    /*       .format(function () {
        return userChart.valueItem == "Cost"
          ? "$"
          : "" + anychart.format.number(this.value, 2, ".", ",")
      }) */
    chart.tooltip().format(function (e) {
      var value = anychart.format.number(this.value, 2, ".", ",")
      if (userChart.groupField == "OB.Objective") {
        return value + "%"
      } else {
        return userChart.valueItem == "Cost" ? "$" + value : "" + value
      }

      //var fillh = anychart.format.stringify(this.y)
      //return fillh
    })

    chart.background().stroke(".5 gray")
    chart.animation(true)
    chart.radius("43%").innerRadius("30%")
  } else if (userChart.chartType == "Column") {
    var chart = anychart.column()

    //chart.palette(anychart.palettes.wines);
    //chart.color.darken("#FF0000", 0.2)

    if (userChart.chartHasColor) {
      var mapping = chartData.mapAs({ x: 0, value: 1, fill: 2, stroke: 2 })
    } else {
      var mapping = chartData.mapAs({ x: 0, value: 1 })
    }

    var series = chart.column(mapping)
    series.labels(true)
    series.labels().format(function () {
      return userChart.valueItem == "Cost" || userChart.valueItem == "Benefit"
        ? "$" + anychart.format.number(this.value, 2, ".", ",")
        : "" + anychart.format.number(this.value, 2, ".", ",")
    })

    if (userChart.baseColor !== "") {
      series.fill(userChart.baseColor)
      series.stroke(userChart.baseColor)
    } 

    var yLabels = chart.yAxis().labels()
    yLabels.format(function () {
      return userChart.valueItem == "Cost" || userChart.valueItem == "Benefit"
        ? "$" + anychart.format.number(this.value, 2, ".", ",")
        : "" + anychart.format.number(this.value, 2, ".", ",")
    })
    chart.tooltip().format(function (e) {
      var value = anychart.format.number(this.value, 2, ".", ",")
      return userChart.valueItem == "Cost" ? "$" + value : "" + value
    })
    chart.background().stroke(".5 gray")
    chart.animation(true)
  } else if (userChart.chartType == "Bar") {
    var chart = anychart.bar()
    if (userChart.chartHasColor) {
      var mapping = chartData.mapAs({ x: 0, value: 1, fill: 2, stroke: 2 })
    } else {
      var mapping = chartData.mapAs({ x: 0, value: 1 })
    }
    var series = chart.bar(mapping)

    if (userChart.baseColor !== "") {
      series.fill(userChart.baseColor)
      series.stroke(userChart.baseColor)
    } 

    series.labels(true)
    series.labels().format(function () {
      return userChart.valueItem == "Cost" || userChart.valueItem == "Benefit"
        ? "$" + anychart.format.number(this.value, 2, ".", ",")
        : "" + anychart.format.number(this.value, 2, ".", ",")
    })

    var yLabels = chart.yAxis().labels()
    yLabels.format(function () {
      return userChart.valueItem == "Cost" || userChart.valueItem == "Benefit"
        ? "$" + anychart.format.number(this.value, 2, ".", ",")
        : "" + anychart.format.number(this.value, 2, ".", ",")
    })
    chart.tooltip().format(function (e) {
      var value = anychart.format.number(this.value, 2, ".", ",")
      return userChart.valueItem == "Cost" ? "$" + value : "" + value
    })
    chart.background().stroke(".5 gray")
    chart.animation(true)
  } else if (userChart.chartType == "Table") {
    return
  } else if (userChart.chartType == "Line") {
    var chart = anychart.line()
    var series = chart.line(chartData)

    if (userChart.chartHasColor) {
      var mapping = chartData.mapAs({ x: 0, value: 1, fill: 2 })
    } else {
      var mapping = chartData.mapAs({ x: 0, value: 1 })
    }
    /*     series.labels(true)
    series.labels().format(function () {
      return userChart.valueItem == "Cost" || userChart.valueItem == "Benefit"
        ? "$" + anychart.format.number(this.value, 2, ".", ",")
        : "" + anychart.format.number(this.value, 2, ".", ",")
    }) */

        if (userChart.baseColor !== "") {
          series.fill(userChart.baseColor)
          series.stroke(userChart.baseColor)
        }     

    var yLabels = chart.yAxis().labels()
    yLabels.format(function () {
      return userChart.valueItem == "Cost" || userChart.valueItem == "Benefit"
        ? "$" + anychart.format.number(this.value, 2, ".", ",")
        : "" + anychart.format.number(this.value, 2, ".", ",")
    })
    chart.tooltip().format(function (e) {
      var value = anychart.format.number(this.value, 2, ".", ",")
      return userChart.valueItem == "Cost" ? "$" + value : "" + value
    })
  }

  if (chart) {
    if (
      onChartPointClick != undefined &&
      (userChart.chartType == "Bar" || userChart.chartType == "Column")
    ) {
      chart.listen("pointClick", onChartPointClick)
    }

    chart.title({ text: userChart.chartTitle, fontWeight: "bold" })
    chart.container(chartdiv)

    chart.title().fontFamily("Inter")
    chart.legend().fontFamily("Inter")
    chart.legend().itemsLayout("horizontal-expandable")

    // chart.draw()
    chart.credits().enabled(false)
    chart.draw()
  }
}

export default renderUserChart
