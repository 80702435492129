import React, { useEffect, useState } from "react"
import TaskDrawer from "pages/Projects/EditProject/Drawer/TaskDrawer"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { changeNavbarParams, loadPageItem } from "store/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb"

const ManageTasks = props => {
  const scenarioId = props.match.params.scenarioId
  const taskTypeId = props.match.params.taskTypeId
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [alternatives, setAlternatives] = useState([])

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: props.match.params.scenarioId,
        alternativeId: 0,
        viewName: `ManageTasks-${taskTypeId}`,
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: props.match.params.scenarioId,
        viewName: `ManageTasks-${taskTypeId}`,
      })
    )
    loadData()
  }, [])

  const loadData = async () => {
    let alts = await api.getAlternatives(currentUser.userID, scenarioId)
    setAlternatives(alts)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <TaskDrawer
                    taskTypeId={taskTypeId}
                    scenarioId={scenarioId}
                    alternatives={alternatives}
                    renderAsTable={true}
                    showWidgets={true}
                    props={props}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ManageTasks
