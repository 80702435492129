import React from "react"
import { Row } from "reactstrap"

const ConsensusGrid = ({ data }) => {
  const getColorAndBackgroundColor = deviation => {
    let color, backgroundColor
    if (deviation < 1) {
      color = "white"
      backgroundColor = "darkblue"
    } else if (deviation < 2) {
      color = "black"
      backgroundColor = "lightblue"
    } else if (deviation < 3) {
      color = "white"
      backgroundColor = "green"
    } else if (deviation < 4) {
      color = "black"
      backgroundColor = "lightgreen"
    } else if (deviation < 5) {
      color = "black"
      backgroundColor = "yellow"
    } else if (deviation < 6) {
      color = "white"
      backgroundColor = "orange"
    } else {
      color = "white"
      backgroundColor = "red"
    }

    return { color, backgroundColor }
  }

  const getRatingTd = (deviation, optionalText, width) => {
    let colorAndBackground = getColorAndBackgroundColor(deviation)
    return (
      <td
        style={{
          textAlign: "center",
        }}
      >
        <div
          style={{
            fontSize: "13px",
            backgroundColor: colorAndBackground.backgroundColor,
            padding: "4px 20px",
            width: width ? width : "100px",
            margin: "0 auto",
            borderRadius: "15px",
            color: colorAndBackground.color,
          }}
        >
          {optionalText != undefined ? optionalText : deviation}
        </div>
      </td>
    )
  }

  return (
    <React.Fragment>
      <Row>
        <div>
          <table
            className="table table-bordered low-padding-table"
            style={{ backgroundColor: "white" }}
          >
            <thead>
              <tr>
                <th style={{ width: "85%" }}>Pairwise Comparison</th>
                <th style={{ width: "15%" }}>Consensus Rating</th>
              </tr>
            </thead>
            <tbody>
              {data.map((a, idx) => (
                <tr key={idx}>
                  <td>{a.comparison}</td>
                  {getRatingTd(a.standDev)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <div style={{ width: "30%" }}>
          <table
            className="table table-bordered low-padding-table"
            style={{ backgroundColor: "white" }}
          >
            <thead>
              <tr>
                <th style={{ width: "50%" }}>Standard Deviation</th>
                <th style={{ width: "50%" }}>Consensus Rating</th>
              </tr>
            </thead>
            <tbody>
              {[
                { val: 0.5, comparison: "< 1", text: "High" },
                { val: 1.5, comparison: "< 2", text: "Medium-High" },
                { val: 2.5, comparison: "< 3", text: "Medium" },
                { val: 3.5, comparison: "< 4", text: "Medium-Low" },
                { val: 4.5, comparison: "< 5", text: "Low" },
                { val: 5.5, comparison: "< 6", text: "Very Low" },
                { val: 6.5, comparison: ">= 6", text: "Negligible" },
              ].map((a, idx) => (
                <tr key={idx}>
                  <td style={{ textAlign: "center" }}>{a.comparison}</td>
                  {getRatingTd(a.val, a.text, '140px')}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Row>
    </React.Fragment>
  )
}

export default ConsensusGrid
