import { CHANGE_USERPHOTO } from "store/userPhoto/actionTypes"

const INIT_STATE = {
  photoBase64: "",
}

const userPhoto = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_USERPHOTO:
      return {
        ...state,
        photoBase64: action.payload,
      }

    default:
      return state
  }
}

export default userPhoto
