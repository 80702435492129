import React, { useEffect, useState } from "react"
import { Col, Input, Label, Modal, Row } from "reactstrap"

const ParticipantEmailModal = ({ isOpen, toggleModal, sendEmail }) => {
  const [email, setEmail] = useState("")

  useEffect(() => {
    if (isOpen) {
      setEmail("")
    }
  }, [isOpen])

  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Send Participant Links
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <Label className="form-label">Email</Label>
          <Input
            name="note"
            type="text"
            onChange={e => setEmail(e.target.value)}
            value={email}
          />
        </div>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={() => sendEmail(email)}
        >
          Send Email
        </button>
      </div>
    </Modal>
  )
}

export default ParticipantEmailModal
