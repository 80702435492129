import React from "react"
import { Col, Label } from "reactstrap"

export const DynamicFormRadioButtons = React.memo(
  function dynamicFormRadioButtons({
    formFieldId,
    label,
    value,
    placeholder,
    changeFormFieldValue,
    formFieldListItems,
    col,
    fullWidth,
    alwaysEditable,
    alwaysReadOnly,
    hasError,
    requiredMessage,
  }) {
    return (
      <Col md={fullWidth ? "12" : col}>
        <div className="mb-3">
          <Label className="form-label">{label}</Label>
          {formFieldListItems.map((item, idx) => {
            const id = "id" + Math.random().toString(16).slice(2)
            return (
              <div key={idx}>
                <input
                  className="form-check-input"
                  type="radio"
                  name={`custom-radio-${id}`}
                  id={`custom-radio-${id}`}
                  checked={value == item.listItemValue}
                  value={item.listItemValue}
                  onClick={e => {
                    changeFormFieldValue(formFieldId, item.listItemValue)
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor={`custom-radio-${id}`}
                  style={{ marginLeft: "10px" }}
                >
                  {item.listItemName}
                </label>
              </div>
            )
          })}

          {hasError && (
            <div className="invalid-feedback" style={{ display: "block" }}>
              {requiredMessage}
            </div>
          )}
        </div>
      </Col>
    )
  },
  (prevProps, nextProps) => {
    return (
      JSON.stringify(prevProps.formFieldListItems) ==
        JSON.stringify(nextProps.formFieldListItems) &&
      prevProps.value == nextProps.value &&
      prevProps.label == nextProps.label &&
      prevProps.col == nextProps.col &&
      prevProps.requiredMessage == nextProps.requiredMessage &&
      prevProps.hasError == nextProps.hasError &&
      prevProps.placeholder == nextProps.placeholder &&
      prevProps.formFieldId == nextProps.formFieldId &&
      prevProps.fullWidth == nextProps.fullWidth
    )
  }
)
