import { TextareaAutosize } from "@material-ui/core"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Alert, Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const EditTShirtModal = ({
  isOpen,
  toggleModal,
  scenarioId,
  data, //{resourceGroupId, tShirtBundleId}
  reload,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [tshirtSizes, setTshirtSizes] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [resourceGroups, setResourceGroups] = useState([])
  const [allResourceGroups, setAllResourceGroups] = useState([])

  const [selectedResourceGroupId, setSelectedResourceGroupId] = useState(-1)

  useEffect(() => {
    if (isOpen && data != undefined && data != null) {
      setTshirtSizes([])
      setResourceGroups([])
      loadData()
      //   loadTShirts(data.resourceGroupId)
    }
  }, [isOpen])

  const loadData = async () => {
    setIsLoading(true)
    let tshirts = await api.getResourceGroupTShirts(data.resourceGroupId)
    setTshirtSizes(tshirts)

    let resources = []
    if (data.tShirtBundleId > 0) {
      resources = await api.getResourceGroupsByTShirtBundleId(
        data.tShirtBundleId
      )
    }

    if (
      resources.find(x => x.resourceGroupID == data.resourceGroupId) ==
      undefined
    ) {
      let rg = await api.getResourceGroup(data.resourceGroupId)
      resources.push(rg)
    }

    let rgs = await api.getResourceGroupsByUser(currentUser.userID, scenarioId)
    rgs = rgs.filter(x => x.projectCostPoolID > 0)
    setAllResourceGroups(
      rgs.filter(
        x =>
          resources.find(r => r.resourceGroupID == x.resourceGroupID) ==
          undefined
      )
    )

    setResourceGroups(resources)

    setIsLoading(false)
  }

  const changeTshirtSizePropByIndex = (idx, prop, val) => {
    let copy = [...tshirtSizes]
    let item = copy[idx]
    item[prop] = val
    item.isDirty = true
    setTshirtSizes(copy)
  }

  const addResourceGroup = () => {
    if (selectedResourceGroupId != -1) {
      let rg = allResourceGroups.find(
        x => x.resourceGroupID == selectedResourceGroupId
      )
      if (rg != undefined) {
        let copy = [...resourceGroups]
        copy.push(rg)
        setResourceGroups(copy)
        let copy2 = [...allResourceGroups]
        let itemToRemove = copy2.find(
          x => x.resourceGroupID == selectedResourceGroupId
        )
        copy2.splice(copy2.indexOf(itemToRemove), 1)
        setAllResourceGroups(copy2)
      }

      setSelectedResourceGroupId(-1)
    }
  }

  const save = async () => {
    let tShirtSizesCopy = [...tshirtSizes]
    if (data.tShirtBundleId == 0) {
      let nextTShirtBundleId = await api.getNextTShirtBundleId()
      tShirtSizesCopy = tShirtSizesCopy.map(t => {
        t.tShirtBundleID = nextTShirtBundleId
        return t
      })
    } else {
      tShirtSizesCopy = tShirtSizesCopy.map(t => {
        t.tShirtBundleID = data.tShirtBundleId
        return t
      })
    }

    let tasks = resourceGroups.map(x => async () => {
      return await api.updateResourceGroupTShirts(
        currentUser.userID,
        x.resourceGroupID,
        tShirtSizesCopy.map(t => {
          t.resourceGroupID = x.resourceGroupID
          return t
        })
      )
    })
    await Promise.all(tasks.map(t => t()))
    reload && reload()
    toggleModal()
  }

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          T-Shirt
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        {tshirtSizes.length > 0 && (
          <Row>
            <Col>
              {/* <div className="divider"></div> */}
              <h5>
                <i className="fas fa-tshirt"></i> T-Shirt Sizes
              </h5>
              <Alert
                id="helpTShirt"
                variant="light"
                className="always-enabled"
                style={{ marginBottom: "10px" }}
              >
                <div>
                  T-Shirt sizing is a technique to allow resource estimations
                  and provide ballpark estimates for projects that may have
                  limited information initially. Update the hours and a
                  meaningful description for each T-Shirt size to assist
                  individuals doing estimates. The descriptions should allow an
                  estimator to compare them to the current project and select
                  the T-Shirt size that best represents that project.
                </div>
              </Alert>
              <table
                className="table table-bordered low-padding-table"
                style={{ backgroundColor: "white" }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}>Size</th>
                    <th style={{ width: "80%" }}>Description</th>
                    <th style={{ width: "10%" }}># Units
                            {/* {resourceToEdit.resourceUnit} */}
                    </th>

                  </tr>
                </thead>
                <tbody>
                  {tshirtSizes.map((t, idx) => (
                    <tr key={idx}>
                      <td style={{ textAlign: "center" }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t.tShirtPill,
                          }}
                        ></div>
                      </td>

                      <td>
                        <TextareaAutosize
                          className="form-control"
                          style={{ width: "100%" }}
                          placeholder={
                            t.tShirtID == "XS"
                              ? "Example: Working on a minor release for an existing product or platform."
                              : t.tShirtID == "S"
                              ? "Example: Working on a major release to an existing product or platform."
                              : t.tShirtID == "M"
                              ? "Example: Working on a project requiring a proof-of-concept for a new product or platform."
                              : t.tShirtID == "L"
                              ? "Example: Working on a project requiring implementation of a new product/platform for a business unit."
                              : t.tShirtID == "XL"
                              ? "Example: Working on a project requiring implementation of new product/platform for the enterprise."
                              : false
                          }
                          onChange={e =>
                            changeTshirtSizePropByIndex(
                              idx,
                              "description",
                              e.target.value
                            )
                          }
                          value={t.description || ""}
                        />
                      </td>

                      <td>
                        <Input
                          type="text"
                          value={t.tShirtHours}
                          onChange={e =>
                            changeTshirtSizePropByIndex(
                              idx,
                              "tShirtHours",
                              e.target.value
                            )
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
            <div className="divider"></div>
          </Row>
        )}
        <Row>
          <Col>
            <div className="d-flex-row ai-center">
              <div className="mb-3">
                <Label className="form-label">Resource</Label>
                <select
                  className="form-control form-select select2 mb-xxl-0"
                  value={selectedResourceGroupId}
                  style={{ width: "300px" }}
                  onChange={e => {
                    setSelectedResourceGroupId(e.target.value)
                  }}
                >
                  <option value="-1">Select resource...</option>
                  {allResourceGroups.map((s, i) => (
                    <option key={i} value={s.resourceGroupID}>
                      {s.resourceGroupName}
                    </option>
                  ))}
                </select>
              </div>
              <button
                className="btn btn-primary save-user"
                onClick={addResourceGroup}
              >
                <i className="fas fa-plus"></i> Add Resource
              </button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <table
              className="table table-bordered low-padding-table"
              style={{ backgroundColor: "white" }}
            >
              <thead>
                <tr>
                  <th style={{ width: "90%" }}>Resource</th>
                  <th style={{ width: "10%" }}></th>
                </tr>
              </thead>
              <tbody>
                {resourceGroups.map((r, idx) => (
                  <tr key={idx}>
                    <td>{r.resourceGroupName}</td>
                    <td>
                      <i
                        className="fas fa-trash"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => {
                          let copy = [...resourceGroups]
                          let item = copy.find(
                            x => x.resourceGroupID == r.resourceGroupID
                          )
                          copy.splice(copy.indexOf(item), 1)
                          setResourceGroups(copy)

                          let copy2 = [...allResourceGroups]
                          copy2.push(item)
                          setAllResourceGroups(copy2)

                          api.deleteResourceGroupTShirts(
                            currentUser.userID,
                            r.resourceGroupID,
                            data.tShirtBundleId
                          )
                        }}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default EditTShirtModal
