import { CHANGE_RESOURCEPLANNING } from "./actionTypes"

const INIT_STATE = {
  resourcePlanning:
    localStorage.getItem("resourcePlanningDefaults") != null
      ? JSON.parse(localStorage.getItem("resourcePlanningDefaults"))
      : {
          yearOption: 0,
          showOption: 0,
          showExcluded: false,
          alternativeFilter: "",
          businessCaseFilter: "",
          fyPlannedFilter: "",
          pmFilter: "",
          projectCategoryFilter: "",
        },
}

const resourcePlanning = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_RESOURCEPLANNING:
      localStorage.removeItem("resourcePlanningDefaults")
      localStorage.setItem(
        "resourcePlanningDefaults",
        JSON.stringify({ ...state.resourcePlanning, ...action.payload })
      )
      
      return {
        ...state,
        resourcePlanning: { ...state.resourcePlanning, ...action.payload },
      }

    default:
      return state
  }
}

export default resourcePlanning
